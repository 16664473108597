import { Box, ButtonGroup, Flex, useMediaQuery, Text, Stack, SimpleGrid } from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import { SimpleReportLayout } from "../../../../../layouts/index";
import {
    AppContext,
    PosBreadCrumb,
    PosDataGridTable,
    PosDropDown,
    PosFormButton,
    PosIconButton,
    PosNoDataFound,
    PosProgress,
    PosSearch,
    PosTostMessage,
    PosDatePicker
} from "../../../../../components/index";
import moment from "moment";
import * as Constants from "../../../../../constants/Constants";
import {
    endOfTodaysDay,
    startOfTodaysDay,
    twofix,
    reportDateFormate
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { downloadCustomerStatement } from "./CustomerStatementService";

const CustomerStatement = (props) => {
    const myContext = useContext(AppContext);
    const submit = useSubmit();
    const loaderResponse = useLoaderData();
    const actionResponse = useActionData();
    const [showTable, setShowTable] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [totalcount, setTotalCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const pageRef = useRef(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [loading, setLoading] = useState(true);
    const effectRun = useRef(true);
    const [isMobile] = useMediaQuery("(max-width: 768px)");
    const [isMeduim] = useMediaQuery("(max-width: 1180px)");
    const [isExtraLarges] = useMediaQuery("(min-width: 1365px)");
    const [isLarge] = useMediaQuery("(min-width: 1315px)");
    const [isMeduim1] = useMediaQuery("(min-width: 1260px)");
    const [dateTimeValue, setdateTimeValue] = useState(new Date());
    const [isVisible, setIsVisible] = useState(false);
    const [fromDateFocus, setFromDateFocus] = useState(false);
    const [toDateFocus, setToDateFocus] = useState(false);
    const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
    const [toDateView, setToDateView] = useState(endOfTodaysDay());
    const { error } = PosErrorHook();
    const [locations, setLocations] = useState([]);
    const [customer, setCustomer] = useState([]);
    const calendarRef = useRef(null);
    const toDateCalendarRef = useRef(null);
    const searchInputHideRef = useRef(false);
    const { addToast } = PosTostMessage();
    const [applyClicked, setApplyClicked] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });
    const [sortModel, setSortModel] = useState([
        {
            field: "id",
            sort: "asc",
        },
    ]);
    const sortRef = useRef(sortModel);
    const [listOlColumnVisibilityModel, setOlColumnVisibilityModel] =
        React.useState({
            // id: false,
        });
    const actionColumn = isMobile ? { width: 180 } : { flex: 1 };
    const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
    const actionColumnXS = isMobile ? { width: 150 } : { flex: 0.9 };
    const [timeoutId, setTimeoutId] = useState(null);
    const columnNames = [
        {
            field: "id",
            headerName: "id",
            sortable: true,
            width: 90,
        },
        {
            field: "name",
            headerName: "Customer Name",
            sortable: true,
            ...actionColumn,
        },
        {
            field: "phone",
            headerName: "Phone",
            sortable: true,
            ...actionColumnXS,
        },
        {
            field: "email",
            headerName: "Email",
            sortable: true,
            ...actionColumnXS,
        },
        {
            field: "balance",
            headerName: "Current Balance($)",
            sortable: true,
            ...actionColumnXS,
        },
        {
            field: "pdf",
            headerName: "Download",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                const rowData = params.row;
                const handleView = () => {
                    // const fromDate = reportDateFormate(formik.values.fromDate);
                    // const toDate = reportDateFormate(formik.values.toDate);
                    // handleCustomerStatementReport(rowData.id, fromDate, toDate);
                    handleCustomerStatementReport(rowData.id);
                };
                return (
                    <PosIconButton
                        onClick={handleView}
                        pdfIcon={true}
                        width={"4.625rem"}
                    />
                );
            },
        },
    ];

    // const handleCustomerStatementReport = (id) => {
    //     myContext.handleLoading(true);
    //     try {
    //       downloadCustomerStatement(id)
    //         .then((response) => {
    //           if (
    //             undefined !== response.data.flag &&
    //             null !== response.data.flag &&
    //             response.data.flag == true
    //           ) {
    //             var url = Constants.REACT_APP_API_URL + response.data.data;
    //             window.open(url, "_blank");
    //             setLoading(false);
    //             myContext.handleLoading(false);
    //           } else {
    //             setLoading(false);
    //             let actionData = response;
    //             error({ actionData });
    //             myContext.handleLoading(false);
    //           }
    //         })
    //         .catch((err) => {
    //           setLoading(false);
    //           let actionData = err;
    //           error({ actionData });
    //           myContext.handleLoading(false);
    //         });
    //     } catch (error) {
    //       setLoading(false);
    //       myContext.handleLoading(false);
    //     }
    //   };

    const handleCustomerStatementReport = (id) => {
        myContext.handleLoading(true);
        let data = {
            id: id,
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        if (formik.values.fromDate && formik.values.toDate) {
            data.dateFrom = reportDateFormate(formik.values.fromDate);
            data.dateTo = reportDateFormate(formik.values.toDate);
            data.formatFromDate = reportDateFormate(formik.values.fromDate);
            data.formatToDate = reportDateFormate(formik.values.toDate);
        }
        try {
            downloadCustomerStatement(data)
                .then((response) => {
                    if (
                        undefined !== response.data.flag &&
                        null !== response.data.flag &&
                        response.data.flag == true
                    ) {
                        var url = Constants.REACT_APP_API_URL + response.data.data;
                        // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
                        window.open(url, "_blank");
                        setLoading(false);
                        myContext.handleLoading(false);
                    } else {
                        setLoading(false);
                        let actionData = response;
                        error({ actionData });
                        myContext.handleLoading(false);
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    let actionData = err;
                    error({ actionData });
                    myContext.handleLoading(false);
                });
        } catch (error) {
            setLoading(false);
            myContext.handleLoading(false);
        }
    };

    const modifiedData = tableData.map((data, i) => ({
        id: data.id,
        name: data.name,
        phone: data.phone,
        email: data.email,
        balance: twofix(data.balance)
    }));

    useEffect(() => {
        if (effectRun.current === true) {
            if (
                undefined != loaderResponse &&
                null != loaderResponse &&
                {} != loaderResponse
            ) {
                if (
                    undefined != loaderResponse?.error &&
                    null != loaderResponse?.error &&
                    loaderResponse?.error
                ) {
                    let actionData = loaderResponse;
                    error({ actionData });
                    myContext.handleLoading(false);
                    effectRun.current = false;
                } else if (
                    null !== loaderResponse.data[Constants.FLAGE] &&
                    true === loaderResponse.data[Constants.FLAGE]
                ) {
                    setCustomer(loaderResponse?.data?.data?.customer);
                    myContext.handleLoading(false);
                }
            }
            return () => {
                effectRun.current = false;
            };
        }
    }, []);

    useEffect(() => {
        if (
            undefined != actionResponse &&
            null != actionResponse &&
            {} != actionResponse
        ) {
            if (
                undefined != actionResponse?.error &&
                null != actionResponse?.error &&
                actionResponse?.error
            ) {
                let actionData = actionResponse;
                error({ actionData });
                setTableData([]);
                setTotalCount(0);
                setLoading(false);
                setButtonDisable(false);
                myContext.handleLoading(false);
            } else if (
                actionResponse.data[Constants.FLAGE] !== null &&
                actionResponse.data[Constants.FLAGE] === true
            ) {
                setTableData(actionResponse.data.data.getCustomers);
                setTotalCount(actionResponse.data.data.totalItems);
                setLoading(false);
                setButtonDisable(false);
            } else {
                let actionData = actionResponse?.data;
                error({ actionData });
                setTableData([]);
                setTotalCount(0);
                setLoading(false);
                setButtonDisable(false);
            }
        }
    }, [actionResponse]);

    const formik = useFormik({
        initialValues: {
            selectedCustomers:
                undefined !== loaderResponse?.data?.data?.customer &&
                    null !== loaderResponse?.data?.data?.customer
                    ? loaderResponse?.data?.data?.customer
                    : [],
            fromDate: "",
            toDate: "",
        },
        validateOnChange: true,
        validationSchema: Yup.object({
            selectedCustomers: Yup.array().min(1, Constants.CUSTOMER_CREDIT_SELECT_CUSTOMER),
            fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
            toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
        }),
        onSubmit: async (values) => { },
    });

    const clearFilterDAta = () => {
        effectRun.current = true;
        if (searchQuery.length > 0) {
            setSearchQuery("");
        }
        setShowTable(true);
        formik.setFieldValue("selectedCustomers", []);
        formik.setFieldValue("fromDate", "");
        formik.setFieldValue("toDate", "");
        setSelectedCustomers([]);
        setFromDateView(startOfTodaysDay());
        setToDateView(endOfTodaysDay());
        setApplyClicked(false);
        formik.resetForm();
        setPaginationModel({
            page: 0,
            pageSize: 25,
        });
    };

    // const toggleVisibility = () => {
    //     setIsVisible(!isVisible);
    //     // setTableData([]);
    //     setSelectedCustomers([]);
    //     // setTotalCount(0);
    //     effectRun.current = true;
    //     if (formik.values.selectedCustomers.length > 0) {
    //         // formik.setFieldValue("selectedCustomers", "");
    //         // formik.setFieldTouched("selectedCustomers", false);
    //         formik.setFieldValue("fromDate", "");
    //         formik.setFieldTouched("fromDate", false);
    //         formik.setFieldTouched("toDate", false);
    //         formik.setFieldValue("toDate", "");
    //     }
    // };

    const hadleFromDateSelect = (e) => {
        if (
            null !== formik.values.toDate &&
            null != formik.values.toDate &&
            "" !== formik.values.toDate
        ) {
            if (moment(formik.values.toDate).isBefore(e.value)) {
                formik.setFieldValue("fromDate", e.value);
                formik.setFieldValue("toDate", e.value);
                setFromDateView(e.value);
                setToDateView(e.value);
            } else {
                formik.setFieldValue("fromDate", e.value);
                setFromDateView(e.value);
            }
        } else {
            formik.setFieldValue("fromDate", e.value);
            setFromDateView(e.value);
        }
    };

    const handleOptionSelect = (e) => {
        // effectRun.current = true;
        if (undefined !== e.value) {
            setSelectedCustomers(e.value);
            formik.setFieldValue("selectedCustomers", e.value);
        }
        // setPaginationModel({
        //     page: 0,
        //     pageSize: 25,
        // });
    };

    function reportDateFormat(dateString) {
        if (!dateString) {
            return "";
        }
        const date = new Date(dateString);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getUTCDate();
        const month = monthNames[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;

        const formattedDate = `${month} ${day}, ${year}, ${hours}:${minutes} ${ampm}`;
        return formattedDate;
    }

    const clearTableData = (message) => {
        // clear table data when remove date and location
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
        // addToast({
        //     alertStatus: Constants.TOAST_TYPE_WARNING,
        //     alertTitle: Constants.REPORT_MODULE,
        //     alertDescription: message,
        // });
    };

    useEffect(() => {
        if (effectRun.current === true) {
            CustomerStatement();
        }
    }, [paginationModel, sortModel]);

    const CustomerStatement = async () => {
        if (formik.values.selectedCustomers.length > 0) {
            if (null != formik.values.fromDate && "" != formik.values.fromDate) {
                if (null != formik.values.toDate && "" != formik.values.toDate) {
                    setButtonDisable(true);
                    setLoading(true);
                    setShowTable(false);
                    setApplyClicked(true);
                    let data = {
                        getreport: true,
                        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        query: JSON.stringify({
                            limit: paginationModel.pageSize,
                            page: paginationModel.page + 1,
                            filter: searchQuery,
                            order:
                                sortModel.length > 0
                                    ? sortModel[0].field
                                    : sortRef.current[0].field,
                            order_type:
                                sortModel.length > 0
                                    ? sortModel[0].sort
                                    : sortRef.current[0].sort,
                        }),
                    };
                    if (formik.values.selectedCustomers.length > 0) {
                        data.customer = JSON.stringify(formik.values.selectedCustomers);
                    } else {
                        data.customer = JSON.stringify([]);
                    }
                    if (formik.values.fromDate && formik.values.toDate) {
                        data.dateFrom = reportDateFormate(formik.values.fromDate);
                        data.dateTo = reportDateFormate(formik.values.toDate);
                    }
                    submit(data, {
                        method: Constants.POST,
                        path: Constants.CUSTOMER_CREDIT_LEDGER_ROUTE,
                    });
                } else {
                    clearTableData(Constants.TO_DATE_IS_REQUIRED);
                }
            } else {
                clearTableData(Constants.FORM_DATE_IS_REQUIRED);
            }
        } else {
            clearTableData(Constants.CUSTOMER_IS_REQUIRED);
        }
    };

    // const handleSearchList = () => {
    //     effectRun.current = true;
    //     setPaginationModel({
    //         page: 0,
    //         pageSize: 25,
    //     });
    // };

    // const handleClear = () => {
    //     effectRun.current = true;
    //     setSearchQuery("");
    //     setPaginationModel({
    //         page: 0,
    //         pageSize: 25,
    //     });
    // };

    // const apply = () => {
    //     setPaginationModel({
    //         page: 0,
    //         pageSize: 25,
    //     });
    // };

    // const onChange = (e) => {
    //     let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    //     setSearchQuery(value);
    //     if (timeoutId) {
    //         clearTimeout(timeoutId);
    //     }
    //     const newTimeoutId = setTimeout(() => {
    //         effectRun.current = true;
    //         apply();
    //     }, 250);
    //     setTimeoutId(newTimeoutId);
    // };
    const renderReportLayout = () => {
        return (
            <>
                <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 1, lg: 3, xl: 4 }}
                    mr={4}
                    spacing={{
                        base: "1rem",
                        sm: "1rem",
                        md: "1rem",
                        lg: "1rem",
                        xl: "1.2rem",
                    }}
                >
                    <Box>
                        <PosDropDown
                            id="selectedCustomers"
                            options={customer}
                            value={formik.values.selectedCustomers}
                            resetFilterOnHide={true}
                            onChange={handleOptionSelect}
                            onBlur={(e) => {
                                formik.setFieldTouched("selectedCustomers");
                                formik.handleBlur(e);
                            }}
                            optionLabel="name"
                            placeholder="Select Customers*"
                            width={"100%"}
                            height={"2.5rem"}
                            className="w-full md:w-20rem"
                            multiSelect={true}
                            maxSelectedLabels={2}
                        />
                        <Text color={Colors.errorColor}>
                            {formik.touched.selectedCustomers &&
                                formik.errors.selectedCustomers ? (
                                <span>{formik.errors.selectedCustomers}</span>
                            ) : null}
                        </Text>
                    </Box>
                    <Box>
                        <PosDatePicker
                            id={"fromDate"}
                            ref={calendarRef}
                            value={formik.values.fromDate}
                            label={"From Date (CST)*"}
                            readOnlyInput={true}
                            width={"100%"}
                            onFocus={() => {
                                if (
                                    null !== toDateCalendarRef.current &&
                                    toDateCalendarRef.current
                                ) {
                                    toDateCalendarRef.current.hide();
                                }
                                setToDateFocus(false);
                                setTimeout(() => {
                                    setFromDateFocus(true);
                                }, 1000);
                                formik.setFieldTouched("fromDate", true);
                            }}
                            handleSelectData={hadleFromDateSelect}
                            yearRange={"2000:2030"}
                            maxDate={dateTimeValue}
                            viewDate={fromDateView}
                            showButtonBar={true}
                            showTime={true}
                        />
                        <Text color={Colors.errorColor}>
                            {formik.touched.fromDate && formik.errors.fromDate ? (
                                <span>{formik.errors.fromDate}</span>
                            ) : null}
                        </Text>
                    </Box>
                    <Box>
                        <PosDatePicker
                            id={"toDate"}
                            ref={toDateCalendarRef}
                            value={formik.values.toDate}
                            label={"To Date (CST)*"}
                            width={"100%"}
                            readOnlyInput={true}
                            onFocus={() => {
                                if (null !== calendarRef.current && calendarRef.current) {
                                    calendarRef.current.hide();
                                }
                                setFromDateFocus(false);
                                setTimeout(() => {
                                    setToDateFocus(true);
                                }, 1000);
                                formik.setFieldTouched("toDate", true);
                            }}
                            handleSelectData={(e) => {
                                formik.setFieldValue("toDate", e.value);
                                setToDateView(e.value);
                            }}
                            yearRange={"2000:2030"}
                            minDate={
                                null !== formik.values.fromDate &&
                                    "" !== formik.values.fromDate
                                    ? formik.values.fromDate
                                    : dateTimeValue
                            }
                            maxDate={dateTimeValue}
                            viewDate={toDateView}
                            showButtonBar={true}
                            showTime={true}
                        />
                        <Text color={Colors.errorColor}>
                            {formik.touched.toDate && formik.errors.toDate ? (
                                <span>{formik.errors.toDate}</span>
                            ) : null}
                        </Text>
                    </Box>
                    <Box>
                        <Stack
                            direction={"row"}
                            alignItems={"flex-start"}
                            pr={4}
                        >
                            <PosFormButton
                                isDisabled={applyClicked ? false : true}
                                buttonText={"Reset"}
                                ghostButton={true}
                                onClick={() => {
                                    clearFilterDAta();
                                }}
                            />
                            <PosFormButton
                                buttonsubmit={"Apply"}
                                SubmitButton={true}
                                isDisabled={
                                    formik.values.selectedCustomers?.length === 0 ||
                                    formik.errors.selectedCustomers ||
                                    formik.values.fromDate === "" ||
                                    formik.values.toDate === "" ||
                                    formik.errors.fromDate ||
                                    formik.errors.toDate ||
                                    buttonDisable
                                }
                                onClick={CustomerStatement}
                            />
                        </Stack>
                    </Box>
                </SimpleGrid>
            </>
        );
    };
    return (
        <Box
            padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
            bg={Colors.loginAuthBackground}
        >
            <Flex
                direction={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                justify="space-between"
                pb={{ base: 6, md: 16 }}
                gap={2}
            >
                <PosBreadCrumb
                    handleClick={(i) => {
                        props.navigate(Constants.REPORT_PATH);
                    }}
                    breadCrumNames={["Reports", "Customer Statement"]}
                    breadCrumTitle={"Customer Statement"}
                />
                <ButtonGroup gap="2" alignSelf={"flex-end"}>
                    <PosFormButton
                        onClick={() => {
                            props.navigate(Constants.REPORT_PATH);
                        }}
                        buttonText={"Cancel"}
                        CancelButton={true}
                    />
                </ButtonGroup>
            </Flex>
            <Box
                pb={5}
                pt={1}
                pl={1}
                pr={1}
                borderRadius="0.63rem"
                bg="white"
                boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
            >
                {/* {!searchInputHideRef.current ? (
                    <Flex flexDirection={isMobile ? "column" : "row"} mt={6}
                        mb={3}
                        ml={4}
                        mr={4}>
                        <PosSearch
                            value={searchQuery}
                            onChange={onChange}
                            onSearch={handleSearchList}
                            handleClear={handleClear}
                        />
                        <Box
                            display={"flex"}
                            justifyContent={"center"}
                            w={"3rem"}
                            ml={{ base: 0, md: 4 }}
                            mt={{ base: 4, md: 0 }}
                            mb={{ base: 0 }}
                        >
                            <IconButton
                                onClick={() => {
                                    toggleVisibility();
                                }}
                                style={{
                                    border: "1px solid #ccc",
                                    boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                                    variant: "posoutline",
                                    color: Colors.posTextInfo,
                                    borderColor: Colors.dividerColor,
                                    borderRadius: "5px",
                                    padding: 5,
                                }}
                            >
                                <FilterListIcon style={{ color: Colors.posTextInfo }} />
                            </IconButton>
                        </Box>
                    </Flex>
                ) : null} */}
                {/* {isVisible && ( */}
                <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
                {/* )} */}
                {showTable ? null : loading ? (
                    <PosProgress />
                ) : totalcount > 0 ? (
                    <Box padding={"1rem"}>
                        <PosDataGridTable
                            columns={columnNames}
                            rows={modifiedData}
                            totalcount={totalcount}
                            columnVisibilityModel={listOlColumnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setOlColumnVisibilityModel(newModel)
                            }
                            paginationModel={paginationModel}
                            paginationMode={Constants.SORTING_AND_PAGINATION_MODE}
                            sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                            onPaginationModelChange={(newPageModel) => {
                                effectRun.current = true;
                                setPaginationModel(newPageModel);
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => {
                                effectRun.current = true;
                                sortRef.current = sortModel;
                                setSortModel(newSortModel);
                            }}
                            slots={{
                                toolbar: GridToolbar,
                            }}
                        />
                    </Box>
                ) : (
                    <Box padding={"1rem"}>
                        <PosNoDataFound
                            title={Constants.CUSTOMER_STATEMENT_NOT_FOUND}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
export default WithRouter(CustomerStatement);
