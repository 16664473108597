import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  ButtonGroup,
  useMediaQuery
} from "@chakra-ui/react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse21.svg";
import {
  AppContext,
  PosDataGridTable,
  PosFormButton,
  PosInput,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
  PosIconButton
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix, twofixs } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllCustomer, getAllDeactiveCustomer } from "./CustomerService";
const CustomerList = (props) => {
  const { addToast } = PosTostMessage();
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const [showTable, setShowTable] = useState(true);
  const actionResponse = useActionData();
  const [customerData, setCustomerSetData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [CustomerDeactiveData, setCustomerDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [totalcount, setTotalCount] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [curruntBalence, setCurruntBalence] = useState(0);
  const effectRun = useRef(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editID, setEditID] = useState(null);
  const { id } = useParams();

  const [paginationModel, setPaginationModel] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);
  const [deactiveItem, setDeactiveItem] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await formik.handleSubmit();
        onClose();
        setLoading(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "balance",
      headerName: "Balance",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "address1",
      headerName: "Address",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 200,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          // pass backtraking relate all data
          props.navigate(`${Constants.CUSTOMERS_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleViewPage = (item) => {
          // backtracking
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.CUSTOMER_VIEW_PATH + item.id}`, {
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          setDeactiveItem(item);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.CUSTOMERS_PATH,
          });
        };
        const options = {
          buttonName: "Add Credit",
          buttonIcon: <AddCircleIcon />,
          handleClick: (item) => handleaddPage(item),
          subButtons: [
            {
              name: "Edit",
              icon: (
                <EditIcon
                  style={{ color: Colors.posViewTextColor, height: "1.25rem" }}
                />
              ),
              onClick: (item) => handleEditPage(item),
            },
            // {
            //   name: "View",
            //   icon: (
            //     <VisibilityIcon
            //       style={{ color: Colors.posViewTextColor, height: "1.25rem" }}
            //     />
            //   ),
            //   onClick: (item) => handleViewPage(item),
            // },
            {
              name: "Deactive",
              disabledButton:buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];

  const columnNamesDeactive = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "balance",
      headerName: "Balance",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "address1",
      headerName: "Address",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisable(true);
          if (
            deactiveCustomerData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          setDeactiveItem(rowData);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.CUSTOMERS_PATH,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? Colors.posNavbarLink : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const closepop = () => {
    onClose();
    setCurruntBalence(null);
    setEditID(null);
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
    fetchData();
  };

  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getAllCustomer(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCustomerSetData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCustomerSetData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setCustomerSetData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCustomerSetData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchDeActiveCustomerData = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].field : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].sort : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getAllDeactiveCustomer(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCustomerDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCustomerDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          setCustomerDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCustomerDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          if(modifiedData.length === 1 && paginationModel?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CUSTOMERS_TITLE,
            alertDescription: deactiveItem.name + " " + Constants.CUSTOMER_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else if (actionResponse.data.message.trim() === Constants.CUSTOMER_CREADIT_SUCCESS){
          fetchData(id);
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CUSTOMERS_TITLE,
            alertDescription: Constants.CUSTOMER_CREADIT_SUCCESS,
          });
        } else {
          setLoading(true);
          if(deactiveCustomerData.length === 1 && deactiveCustomerData?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchDeActiveCustomerData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.CUSTOMERS_TITLE,
            alertDescription: deactiveItem.name + " " + Constants.CUSTOMER_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);

  const handleaddPage = (item) => {
    myContext.handleLoading(true);
    const customer = customerData.find((customer) => customer.id === item.id);
    setCurruntBalence(customer.balance);
    setEditID(item.id);
    onOpen(item.id);
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
    myContext.handleLoading(false);
    formik.setFieldTouched("amount", false);
    formik.setFieldTouched("external_reference", false);
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(true);
      // fetchData();
    } else {
      searchInputHideRef.current = false;
      setSearchQuery("");
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(true);
      // fetchDeActiveCustomerData();
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData(id);
      } else {
        fetchDeActiveCustomerData();
      }
      effectRun.current = false;
    }
  }, [
    searchQuery,
    paginationModel,
    sortModel,
    loading,
    curruntTab,
    paginationModelDeactive,
    sortModelDeactive
  ]);

  const handleSearchList = () => {
    paginationModel.page = 0;
    props.navigate(Constants.CUSTOMERS_PATH); 
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveCustomerData();
    }
  };

  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.CUSTOMERS_PATH); 
  };

  const modifiedData = customerData.map((customer) => ({
    id: customer.id,
    name: customer.name,
    email: customer.email,
    phone: customer.phone,
    balance: twofixs(customer.balance),
    address1: customer.address1,
  }));

  const deactiveCustomerData = CustomerDeactiveData.map((customer) => ({
    id: customer.id,
    name: customer.name,
    email: customer.email,
    phone: customer.phone,
    balance: twofixs(customer.balance),
    address1: customer.address1,
  }));

  const formik = useFormik({
    initialValues: {
      id: editID,
      amount: "",
      external_reference: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
      .typeError("Please enter a valid amount.")
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_AMOUNT)
        .max(999999.99, Constants.CUSTOMER_AMOUNT_MAX_VALUE)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      external_reference: Yup.string().max(
        191,
        Constants.CUSTOMER_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191
      ),
    }),
    onSubmit: async (values) => {
      try {
        myContext.handleLoading(true);
        const payload = {
          ...values,
        };
        const { amount, external_reference } = payload;

        let data = {
          id: editID,
          amount: amount,
          external_reference: external_reference,
          type: Constants.ADDCREDIT,
        };
        submit(data, {
          method: Constants.POST,
          path: Constants.CUSTOMERS_LIST_CREADIT_API_PATH,
        });
        myContext.handleLoading(false);
      } catch (error) {
        myContext.handleLoading(false);
      }
    },
  });

  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Customers"}
          discription={"Manage your customers information seamlessly"}
        />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
        <PosIconButton
            name="Create"
            display={"flex"}
            width={"8.5rem"}
            height={"2.81rem"}
            padding={"0.63rem"}
            justify-content={"center"}
            align-items={"center"}
            gap={"0.31rem"}
            flex-shrink={"0"}
            onClick={() => {
              myContext.handleLoading(true);
              // backtracking
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                    filter:curruntTab == 1 ? "":searchQuery ,
              };
              // pass all backtraking ralated all in create modifier set route
              props.navigate(Constants.CUSTOMERS_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <>
        <Modal
          isOpen={isOpen}
          onClose={() => {
            formik.setFieldValue("amount", "");
            setUserBalance(0);
            onClose();
          }}
          isCentered
          size="xl"
          width="35.63rem"
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              mt={{ base: "0", md: "1.56rem" }}
              style={{ display: "inline-flex", justifyContent: "center" }}
            >
              Add Customer Credit
            </ModalHeader>
            <Divider />
            <ModalBody
              pb={{ base: 4, md: "1.38rem" }}
              pl={{ base: 4, lg: "1.06rem" }}
              pr={{ base: 4, md: "1.75rem" }}
            >
              <Stack mt="0.87rem">
                <Flex
                  bg={Colors.modifierTable}
                  style={{
                    display: "flex",
                    padding: "0.63rem",
                    alignItems: "center",
                    gap: "0.63rem",
                    flexShrink: 0,
                    borderRadius: "0.63rem",
                  }}
                >
                  <Image
                    src={warnignIcon}
                    alt="Warning Icon"
                    boxSize="0.81rem"
                    mr="0.5rem"
                  />
                  <Text>
                    Your Available Credit Balance is: $
                    <Text as="span" fontWeight="bold">
                      {twofix(curruntBalence)}
                    </Text>
                  </Text>
                </Flex>
                <Text mt="2rem">* Amount:</Text>
                <PosInput
                  id={"amount"}
                  placeholder={"Enter Amount"}
                  handleInputChange={(e) => {
                    let newBalance = 0;
                    if (
                      undefined != e.target.value &&
                      null != e.target.value &&
                      "" != e.target.value
                    ) {
                      newBalance =
                        parseFloat(e.target.value) + parseFloat(curruntBalence);
                      setUserBalance(newBalance);
                      formik.handleChange(e);
                    } else {
                      formik.handleChange(e);
                      setUserBalance(parseFloat(curruntBalence));
                    }
                  }}
                  inputValue={formik.values.amount}
                  handleBlur={(e) => {
                    formik.handleBlur(e);
                    formik.setFieldValue(
                      "amount",
                      twofix(formik.values.amount) || ""
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  inputType={"number"}
                  posNumberInput={true}
                />
                <Text color={Colors.posCancleButtonRed}>
                  {formik.touched.amount && formik.errors.amount ? (
                    <span>{formik.errors.amount}</span>
                  ) : null}
                </Text>
              </Stack>
              <Stack pt={"0.88rem"}>
                <Text>External Reference:</Text>
                <PosInput
                  id="external_reference"
                  // mt={"1rem"}
                  placeholder={"Enter a External Reference"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.external_reference}
                  handleBlur={formik.handleBlur}
                  posInput={true}
                  inputType={"text"}
                />
                <Text color={Colors.posCancleButtonRed}>
                  {formik.touched.external_reference &&
                  formik.errors.external_reference ? (
                    <span>{formik.errors.external_reference}</span>
                  ) : null}
                </Text>
                {formik.values.amount > 0 && (
                  <Text style={{ color: "#008000" }}>
                    After this transaction, the new Credit Balance for this
                    customer will be $
                    <Text as="span" fontWeight="bold">
                      {twofix(userBalance)}
                    </Text>{" "}
                    .
                  </Text>
                )}
              </Stack>
            </ModalBody>

            <ModalFooter
              flexDirection={"column"}
              alignItems={"end"}
              style={{ paddingInlineEnd: "var(--chakra-space-4" }}
            >
              <Flex mb={"1.69rem"} >
                <PosFormButton
                  buttonText={"Cancel"}
                  CancelButton={true}
                  onClick={() => {
                    closepop();
                  }}
                  isDisabled={buttonDisable || isSubmitting}
                />
              <Text mr={{ base: "0.5rem"}}></Text>
                <PosFormButton
                  isDisabled={
                    !formik.values.amount ||
                    formik.values.amount?.length <= 0 ||
                    formik.errors.amount ||
                    formik.errors.external_reference ||
                    buttonDisable
                      ? true
                      : false ||
                      isSubmitting
                  }
                  buttonsubmit={"Submit"}
                  SubmitButton={true}
                  onClick={handleSubmit}
                />
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =
                  searchQuery.length > 0
                    ? e.target.value
                    : e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Spacer />
          </Flex>
) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
  <Flex
    direction={{ base: "column", md: "row" }}
    alignItems={{ base: "stretch", md: "center" }}
    gap={{ base: 2, md: 0 }}
    mt={6}
    mb={3}
    ml={4}
    mr={4}
  >
    <PosSearch
      value={searchQuery}
      onChange={(e) => {
        let value =
          searchQuery.length > 0
            ? e.target.value
            : e.target.value.trim();
        setSearchQuery(value);
      }}
      onSearch={handleSearchList}
      handleClear={handleClear} // clear input value and get all data
    />
    <Spacer />
  </Flex>
) : null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.ACTIVE_CUSTOMER_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.CUSTOMER_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNamesDeactive}
                    rows={deactiveCustomerData}
                    totalcount={deactiveTotalCount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModelDeactive}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModelDeactive) => {
                      effectRun.current = true;
                      setPaginationModelDeactive(newPaginationModelDeactive);
                    }}
                    // sortModel={sortModelDeactive}
                    // onSortModelChange={(newSortModelDeactive) => {
                    //   sortRefDeactive.current = sortModelDeactive;
                    //   effectRun.current = true;
                    //   setSortModel(newSortModelDeactive);
                    // }}
                    sortModel={sortModelDeactive}
                    onSortModelChange={(newSortModelDeactive) => {
                      effectRun.current = true
                      sortRef.current = sortModelDeactive;
                      setSortModelDeactive(newSortModelDeactive);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.DEACTIVE_CUSTOMER_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_CUSTOMER_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(CustomerList);
