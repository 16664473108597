import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Grid,
  IconButton,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  ButtonGroup
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import LanguageIcon from "@mui/icons-material/Language";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useFormik } from "formik";
import { Badge } from "primereact/badge";
import { Tree } from "primereact/tree";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import './Role.css'
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosTextArea,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const RoleCreate = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  // useEffect(() => {
  //   fetchData();
  // }, []);
  const [state, setState] = useState({
    formData: {},
    firstLayout: true,
    show: false,
  });

  const [webAppTitle, setWebAppTitle] = useState("");
  const [webAppSubTitle, setWebAppSubTitle] = useState("");

  const [mobileAppTitle, setMobileAppTitle] = useState("");
  const [mobileAppSubTitle, setMobileAppSubTitle] = useState("");

  const [posAppTitle, setPosAppTitle] = useState("");
  const [posAppSubTitle, setPosAppSubTitle] = useState("");

  const [webAppPermission, setWebAppPermission] = useState({});
  const [webAppClickedPanel, setWebAppClickedPanel] = useState();
  const [octoYardPermission, setOctoYardPermission] = useState({});
  const [octoYardClickedPanel, setOctoYardClickedPanel] = useState(null);
  const [octoPosAppPermission, setOctoPosAppPermission] = useState({});
  const [octoPosAppclickedPanel, setOctoPosAppClickedPanel] = useState(null);

  const [webAppNodes, setWebAppNodes] = useState([]);
  const [mobileAppNodes, setMobileAppNodes] = useState([]);
  const [posAppNodes, setPosAppNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState({});
  const [loginUserPermissionKeys, setLoginUserPermissionKeys] = useState({});

  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state

  const [hideWebPanel, setHideWebPanel] = useState(false);
  const [hideYardPanel, setHideYardPanel] = useState(false);
  const [hidePosPanel, setHidePosPanel] = useState(false);
  const [isBase] = useMediaQuery("(max-width: 30em)");

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setWebAppPermission(loaderResponse?.data?.data?.group);
          setOctoYardPermission(loaderResponse?.data?.data?.group["Octoyard"]);
          setOctoPosAppPermission(
            loaderResponse?.data?.data?.group["OctoposApp"]
          );
          setLoginUserPermissionKeys(
            loaderResponse?.data?.data?.admin_role_permission
          );

          const permissions = loaderResponse?.data?.data?.group;
          if ("Settings" in permissions) {
            delete permissions.Settings;
          }
          if ("Customer" in permissions) {
            delete permissions.Customer;
          }
          if (process.env.REACT_APP_DOMAIN == "vori") {
            Constants.VORI_RESTRICTED_PERMISSIONS.forEach((key) => {
              if (permissions.hasOwnProperty(key)) {
                delete permissions[key];
              }
            });
          }

          const defaultPermissionsKeys = ["Profile", "profile-edit"];

          const selectedPermissionObject = {};
          Object.values(defaultPermissionsKeys).forEach((item) => {
            selectedPermissionObject[item] = {
              checked: true,
              partialChecked: true,
            };
          });
          setSelectedKeys(selectedPermissionObject);
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }

    // if (actionRun.current === true) {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.HR_ROLE_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: "Role Created",
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
      // else {
      //   let actionData = actionResponse;
      //   action({ actionData });
      // }
    }
    // return () => {
    //   actionRun.current = false;
    // };
    // }
  }, [loaderResponse, actionResponse]);

  const formik = useFormik({
    initialValues: {
      name: "",
      display_name: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.ROLE_NAME_REQUIRED)
        .max(191, Constants.ROLE_NAME_LENGTH_GREATE_THEN_191),
      display_name: Yup.string()
        .trim()
        .required(Constants.DISPLAY_NAME_REQUIRED)
        .max(191, Constants.DISPLAY_NAME_LENGTH_GREATE_THEN_191),
      description: Yup.string()
        .trim()
        .max(5000, Constants.DESCRIPTION_LENGTH_GREATE_THEN_5000),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      let permissionArray = [];
      Object.keys(selectedKeys).map((key) => {
        if (key !== "Dashboard") {
          permissionArray.push(key);
        }
      });
      if (permissionArray.length == 0) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.FIELD_IS_REQUIRED,
          alertDescription: Constants.ROLE_PERMISSION_EMPTY,
        });
      } else {
        const payload = {
          role: JSON.stringify(values),
          permission: JSON.stringify(permissionArray),
        };
        // setButtonDisable(true);
        let path = Constants.ROLE_CREATE;
        actionRun.current = true;
        submit(payload, {
          method: Constants.POST,
          path: path,
        });
      }
    },
  });

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(!formik.isValid || !formik.dirty || buttonDisable)
    ) {
      // formik.handleSubmit();
      saveAndContinue();
    }
  };

  const saveAndContinue = () => {
    setState({ ...state, firstLayout: false });
  };
  const previous = () => {
    setState({ ...state, firstLayout: true });
  };
  const hidePanel = () => {
    setHideWebPanel(false);
  };

  const handleHideYardPanel = () => {
    setHideYardPanel(false);
  };

  const handleHidePOSAppPanel = () => {
    setHidePosPanel(false);
  };

  const handleWebAppPanelClick = (payload, name) => {
    if (isBase) {
      setHideWebPanel(true);
    }
    // Constants.WebAppTitleDtls.map((item) => {
    //   if (item.title == name) {
    //     setWebAppTitle(item.title);
    //     setWebAppSubTitle(item.subtitle);
    //   }
    // });
    Object.keys(webAppPermission).map((key) =>{
      if (key == name) {
        setWebAppTitle(key);
      }
    });
    setWebAppClickedPanel(name === webAppClickedPanel ? null : name);
    if (payload.length > 0) {
      let count = 0;
      const dynamicChildren = payload.map((item) => {
        if (item["name"] == "profile-edit") {
          return {
            key: item.name,
            label: item.display_name,
            data: item,
            selectable: false,
          };
        } else {
          const isLoginUserPermission = Object.values(
            loginUserPermissionKeys
          ).includes(item["name"]);
          if (isLoginUserPermission == true) {
            count++;
          }
          return {
            key: item.name,
            label: item.display_name,
            data: item,
            selectable: isLoginUserPermission,
          };
        }
      });

      let isLoginUserParentPermission;
      if (count == payload.length) {
        isLoginUserParentPermission = true;
      } else {
        isLoginUserParentPermission = false;
      }

      const webAppNodes = [
        {
          key: name,
          label: name,
          children: dynamicChildren,
          expanded: true,
          selectable: isLoginUserParentPermission,
        },
      ];
      // if (webAppNodes.length > 0) {
      //   setWebAppNodes(webAppNodes);
      // }
      if (dynamicChildren?.length == 1) {
        const webAppNodes = [
          {
            key: name,
            label: "",
            children: dynamicChildren,
            expanded: true,
            selectable: false,
            // className:
          },
        ];
        setWebAppNodes(webAppNodes);
      } else if (webAppNodes.length > 0) {
        setWebAppNodes(webAppNodes);
      }
    } else {
      const webAppNodes = [
        {
          key: name,
          label: name,
          children: payload,
          expanded: true,
        },
      ];
      if (webAppNodes.length > 0) {
        setWebAppNodes(webAppNodes);
      }
    }
  };

  const handleMobileAppPanelClick = (payload, name) => {
    if (isBase) {
      setHideYardPanel(true);
    }
    // Constants.MobileAppTitleDtls.map((item) => {
    //   if (item.title == name) {
    //     setMobileAppTitle(item.title);
    //     setMobileAppSubTitle(item.subtitle);
    //   }
    // });
    octoYardPermission.map((value) => {
      if (value.display_name == name) {
        setMobileAppTitle(value.display_name);
      }
    });
    setOctoYardClickedPanel(name === octoYardClickedPanel ? null : name);
    const isLoginUserPermission = Object.values(
      loginUserPermissionKeys
    ).includes(payload.name);
    const mobileAppNodes = [
      {
        key: payload.name,
        label: payload.display_name,
        children: payload,
        selectable: isLoginUserPermission,
      },
    ];
    if (mobileAppNodes.length > 0) {
      setMobileAppNodes(mobileAppNodes);
    }
  };

  const handlePosAppPanelClick = (payload, name) => {
    if (isBase) {
      setHidePosPanel(true);
    }
    // Constants.PosAppTitleDtls.map((item) => {
    //   if (item.title == name) {
    //     setPosAppTitle(item.title);
    //     setPosAppSubTitle(item.subtitle);
    //   }
    // });
    octoPosAppPermission.map((value) => {
      if (value.display_name == name) {
        setPosAppTitle(value.display_name);
      }
    });
    setOctoPosAppClickedPanel(name === octoPosAppclickedPanel ? null : name);
    const isLoginUserPermission = Object.values(
      loginUserPermissionKeys
    ).includes(payload.name);
    const posAppNodes = [
      {
        key: payload.name,
        label: payload.display_name,
        children: payload,
        selectable: isLoginUserPermission,
      },
    ];
    if (posAppNodes.length > 0) {
      setPosAppNodes(posAppNodes);
    }
  };

  const handleSelectkeys = (e) => {
    const selected = e.value;
    setSelectedKeys(selected);
  };

  return (
    // <MainLayout loading={buttonDisable}>
    <Flex bg={Colors.loginAuthBackground}>
      {state.firstLayout ? (
        <Box flex="1">
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={"flex-start"}
            pt={{ base: "1.5rem", md: "3.06rem" }}
            pb={{ base: "1.5rem", md: "0" }}
            /* pl={"1.88rem"} */
            pl={{ base: "1rem", lg: "1.88rem" }}
            pr={{ base: "0.5rem", md: "1.88rem" }}
            gap={2}
          >
            <PosBreadCrumb
              handleClick={(i) => {
                props.navigate(Constants.HR_ROLE_PATH, {
                  state: stateData.state,
                });
              }}
              breadCrumNames={["Roles", "Create a Role"]}
              breadCrumTitle={"Create a Role"}
            />
          </Flex>
          <Box
            mt={{ base: 4, lg: 12 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
            p="8"
          >
            {/* <Text
                fontSize="1.5rem"
                w="10.5rem"
                color={Colors.posTextInfo}
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.27px",
                }}
              >
                Tax Details
              </Text> */}
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
              mt="1.15rem"
              spacing={1}
            >

            <Stack spacing={3} mt="0.13rem" w={{ base: "100%", md: "45%" }}>
              <PosLable
                fontWeight={"500"}
                requiredLabel={true}
                name={"Role Name"}
                mt="0.31rem"
              />
              <PosInput
                id="name"
                alt="Imput Image"
                placeholder={"Role Name"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.name}
                handleBlur={formik.handleBlur}
                onKeyDown={handleKeyDown}
                posInputGroup={true}
                inputType={"text"}
                inputError={formik.touched.name && formik.errors.name}
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.name && formik.errors.name ? (
                  <span>{formik.errors.name}</span>
                ) : null}
              </Text>
            </Stack>
            <Stack spacing={3} mt="0.13rem" w={{ base: "100%", md: "50%" }}>
              <PosLable
                fontWeight={"500"}
                requiredLabel={true}
                name={"Display Name"}
                mt="0.31rem"
              />
              <PosInput
                id="display_name"
                alt="Imput Image"
                placeholder={"Display Name"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.display_name}
                handleBlur={formik.handleBlur}
                onKeyDown={handleKeyDown}
                posInputGroup={true}
                inputType={"text"}
                inputError={
                  formik.touched.display_name && formik.errors.display_name
                }
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.errorColor}>
                {formik.touched.display_name && formik.errors.display_name ? (
                  <span>{formik.errors.display_name}</span>
                ) : null}
              </Text>
            </Stack>
            </Flex>
            {/* <Text mt="0.13rem" mb={"2rem"} color={Colors.errorColor}>
                {formik.touched.display_name && formik.errors.display_name ? (
                  <span>{formik.errors.display_name}</span>
                ) : null}
              </Text> */}
            <Stack spacing={3} mt="0.13rem">
              <PosLable
                fontWeight={"500"}
                label={true}
                name={"Description"}
                mt="0.31rem"
              />
              {/* <Textarea
                id="description"
                placeholder="Description"
                borderRadius={"0.63rem"}
                border={Colors.posTextAreaBorder}
                background={Colors.posInputBackground}
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onKeyDown={handleKeyDown}
                inputerror={
                  formik.touched.description && formik.errors.description
                }
              /> */}
              <PosTextArea
                id="description"
                placeholder="Description..."
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onKeyDown={handleKeyDown}
                inputerror={
                  formik.touched.description && formik.errors.description
                }
              />
              <Text color={Colors.errorColor}>
                {formik.touched.description && formik.errors.description ? (
                  <span>{formik.errors.description}</span>
                ) : null}
              </Text>
            </Stack>
            {null != formik.values.description &&
            "" != formik.values.description &&
            5000 - formik.values.description.length > 0 ? (
              <Text
                pt={"0.13rem"}
                style={{ color: "#0100484d" }}
                fontSize={"0.94rem"}
                fontStyle={"normal"}
                fontWeight={300}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
              >
                {5000 - formik.values.description.length} characters left for
                description
              </Text>
            ) : (
              ""
            )}
            {/* <Text color={Colors.errorColor}>
                {formik.touched.description && formik.errors.description ? (
                  <span>{formik.errors.description}</span>
                ) : null}
              </Text> */}

            <Box pt={"2rem"} pb={"2rem"}>
              <Divider h={"0.05rem"} />
            </Box>

            <Flex gap={3} justifyContent={"end"}>
              <PosFormButton
                onClick={() => {
                  props.navigate(Constants.HR_ROLE_PATH, {
                    state: stateData.state,
                  });
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              />
              <PosFormButton
                isDisabled={
                  !formik.isValid || !formik.dirty || buttonDisable
                    ? true
                    : false
                }
                buttonsubmit={"Continue"}
                SubmitButton={true}
                onClick={saveAndContinue}
              />
            </Flex>
          </Box>
        </Box>
      ) : (
        <Box flex="1">
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={"flex-start"}
            pt={{ base: "1.5rem", md: "3.06rem" }}
            pb={{ base: "1.5rem", md: "0" }}
            /* pl={"1.88rem"} */
            pl={{ base: "1rem", lg: "1.88rem" }}
            pr={{ base: "0.5rem", md: "1.88rem" }}
            gap={2}
          >
            <PosBreadCrumb
              handleClick={(i) => {
                setState({ ...state, firstLayout: true });
              }}
              breadCrumNames={["Create a Role", "Customize Permissions"]}
              breadCrumTitle={"Customize Permissions"}
            />
            <Spacer />
          </Flex>
          <Box
            mt={{ base: 4, lg: 12 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
            p="8"
          >
            {/* <Text
                fontSize="1.5rem"
                w="10.5rem"
                color={Colors.posTextInfo}
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.27px",
                }}
              >
                Select Permissions*              
              </Text> */}
            <Stack>
              <Text
                // pt={"4.13rem"}
                color={"#010048"}
                fontSize={"1.13rem"}
                fontStyle={"normal"}
                fontWeight={500}
                lineHeight={"normal"}
                letterSpacing={"-0.02rem"}
              >
                Select Permissions
                <Text as="span" color={"#FB2A61"}>
                  *
                </Text>
              </Text>
              <Text
                color={"var(--Primary-color, #010048)"}
                fontSize={"0.94rem"}
                fontStyle={"normal"}
                fontWeight={300}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
              >
                Customize the permissions below to manage team members actions.
              </Text>
            </Stack>

            <Stack>
              <Grid pt={"0.88rem"} templateColumns="100%" gap={4}>
                <Accordion allowMultiple>
                  <AccordionItem>
                    <AccordionButton
                      h={"4.06rem"}
                      style={{
                        background: "#F1F0FD",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        style={{
                          color: "var(--Primary-color, #010048)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <LanguageIcon
                            style={{
                              color: "#F1F0FD",
                              // height:"1.06rem",
                              // width:"1.06rem",
                              padding: "0.20rem",
                              borderRadius: "0.31rem",
                              border: "0.06rem solid #D6B5FF",
                              background: "#B582F4",
                              boxShadow: "0rem 0.63rem 0.25rem 0rem #dbdbdb2e",
                              marginRight: "0.5rem",
                            }}
                          />
                        </Box>
                        <span
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={500}
                          // lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          Web App Permission
                        </span>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={"0rem"}>
                      <Flex
                        flexDirection={{
                          base: "column",
                          sm: "row",
                          md: "row",
                          lg: "row",
                        }}
                      >
                        {!hideWebPanel && !isBase ? (
                          <>
                            <Box
                              w={{
                                base: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "55%",
                              }}
                              h={{ base: "auto", lg: "44.18rem" }}
                              maxHeight={"40rem"}
                              // style={{ overflowY: "auto"}}
                              style={{
                                overflowY: "auto",
                                overflowX: "auto",
                              }}
                            >
                              {Object.keys(webAppPermission).map((key) =>
                                key == "OctoposApp" ||
                                key == "Octoyard" ||
                                key == "Profile" ? null : (
                                  <Box
                                    key={key}
                                    display={"flex"}
                                    h={"2.81rem"}
                                    pt={"0.63rem"}
                                    // pr={{ base: 0, sm: 0, md: "0.63rem", lg: "0.63rem" }}
                                    pb={"0.63rem"}
                                    // pl={{ base: 0, sm: 0, md: "1.81rem", lg: "1.81rem" }}
                                    // justifyContent={"center"}
                                    justifyContent={{
                                      base: "flex-start",
                                      sm: "flex-start",
                                      md: "flex-start",
                                      lg: "flex-start",
                                    }}
                                    alignItems={"flex-start"}
                                    gap={"0.63rem"}
                                    flexShrink={"0"}
                                    style={{
                                      paddingLeft: "0.63rem",
                                      cursor: "pointer",
                                      background:
                                        webAppClickedPanel === key
                                          ? "var(--Accent-Color-1, #5881FE)"
                                          : "#FBFBFB",
                                      color:
                                        webAppClickedPanel === key
                                          ? "var(--Secondary-color, #F4F4F4)"
                                          : "var(--Primary-color, #010048)",
                                    }}
                                    onClick={() =>
                                      handleWebAppPanelClick(
                                        webAppPermission[key],
                                        key
                                      )
                                    }
                                  >
                                    <Flex
                                    w={{ base: "100%", md: "13rem" }} alignSelf={"flex-start"}
                                  >
                                    <Text
                                      key={key}
                                      // fontFamily={"Lexend"}
                                      // w={{ base: "100%", sm: "9rem", md: "9rem", lg: "9rem" }}
                                      flexShrink={"0"}
                                      style={{
                                        fontSize: "0.94rem",
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {key}
                                    </Text>
                                    </Flex>
                                    <Box>
                                      {selectedKeys[key]?.checked !=
                                      undefined ? (
                                        <Badge
                                          value={
                                            selectedKeys[key].checked ==
                                              false &&
                                            selectedKeys[key].partialChecked ==
                                              false
                                              ? "OFF"
                                              : "ACTIVE"
                                          }
                                          style={{
                                            paddingTop: "0.33rem",
                                            marginRight: "0.63rem",
                                            borderRadius: "0.63rem",
                                            background:
                                              selectedKeys[key].checked ==
                                                false &&
                                              selectedKeys[key]
                                                .partialChecked == false
                                                ? "#E5EBFC"
                                                : "#e5ebfcfc",
                                            color:
                                              selectedKeys[key].checked ==
                                                false &&
                                              selectedKeys[key]
                                                .partialChecked == false
                                                ? "#01004854"
                                                : "var(--Accent-Color-1, #5881FE)",
                                            fontSize: "0.75rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                            letterSpacing: "-0.01rem",
                                            paddingLeft: "0.63rem",
                                          }}
                                        ></Badge>
                                      ) : (
                                        <Badge
                                          value={"OFF"}
                                          style={{
                                            paddingTop: "0.33rem",
                                            marginRight: "0.63rem",
                                            borderRadius: "0.63rem",
                                            background: "#E5EBFC",
                                            color: "#01004854",
                                            fontSize: "0.75rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                            letterSpacing: "-0.01rem",
                                          }}
                                        ></Badge>
                                      )}
                                    </Box>
                                  </Box>
                                )
                              )}
                            </Box>
                            <Box
                              w="100%"
                              maxHeight={"40rem"}
                              style={{
                                overflowY: "auto",
                                overflowX: "auto",
                              }}
                            >
                              <Box
                                pt={"2.18rem"}
                                pl={"3.56rem"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                                gap={"0.13rem"}
                              >
                                <Text
                                  style={{
                                    color: "var(--Primary-color, #010048)",
                                    // fontFamily: "Lexend",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {isBase && (
                                    <IconButton
                                      bg={Colors.forgotBackButtone}
                                      mr={2}
                                      // mt={"3.25rem"}
                                      onClick={() => {
                                        setHideWebPanel(false);
                                      }}
                                      icon={
                                        <ArrowBackIcon
                                          color={Colors.blackIcon}
                                        />
                                      }
                                    />
                                  )}
                                  {webAppTitle}
                                </Text>
                              </Box>
                              <Box pt={"0.81rem"}>
                                <Divider h={"0.05rem"} bg={"#E6E6E6"} />
                              </Box>
                              <Tree
                                className="custom-tree-container"
                                value={webAppNodes}
                                emptyMessage="Please Select Permission from the list"
                                selectionMode="checkbox"
                                selectionKeys={selectedKeys}
                                onSelectionChange={(e) => handleSelectkeys(e)}
                              />
                            </Box>
                          </>
                        ) : !hideWebPanel && isBase ? (
                          <Box
                            w={{
                              base: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "55%",
                            }}
                            h={{ base: "auto", lg: "44.18rem" }}
                            maxHeight={"40rem"}
                            // style={{ overflowY: "auto"}}
                            style={{ overflowY: "auto", overflowX: "auto" }}
                          >
                            {Object.keys(webAppPermission).map((key) =>
                              key == "OctoposApp" ||
                              key == "Octoyard" ||
                              key == "Profile" ? null : (
                                <Box
                                  key={key}
                                  display={"flex"}
                                  h={"2.81rem"}
                                  pt={"0.63rem"}
                                  // pr={{ base: 0, sm: 0, md: "0.63rem", lg: "0.63rem" }}
                                  pb={"0.63rem"}
                                  // pl={{ base: 0, sm: 0, md: "1.81rem", lg: "1.81rem" }}
                                  // justifyContent={"center"}
                                  justifyContent={{
                                    base: "flex-start",
                                    sm: "flex-start",
                                    md: "flex-start",
                                    lg: "flex-start",
                                  }}
                                  alignItems={"flex-start"}
                                  gap={"0.63rem"}
                                  flexShrink={"0"}
                                  style={{
                                    paddingLeft: "0.63rem",
                                    cursor: "pointer",
                                    background:
                                      webAppClickedPanel === key
                                        ? "var(--Accent-Color-1, #5881FE)"
                                        : "#FBFBFB",
                                    color:
                                      webAppClickedPanel === key
                                        ? "var(--Secondary-color, #F4F4F4)"
                                        : "var(--Primary-color, #010048)",
                                  }}
                                  onClick={() =>
                                    handleWebAppPanelClick(
                                      webAppPermission[key],
                                      key
                                    )
                                  }
                                >
                                  <Flex
                                    w={{ base: "100%", md: "13rem" }} alignSelf={"flex-start"}
                                  >
                                  <Text
                                    key={key}
                                    // fontFamily={"Lexend"}
                                    // w={{ base: "100%", sm: "9rem", md: "9rem", lg: "9rem" }}
                                    flexShrink={"0"}
                                    style={{
                                      fontSize: "0.94rem",
                                      fontStyle: "normal",
                                      fontWeight: "300",
                                      lineHeight: "normal",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {key}
                                  </Text>
                                  </Flex>
                                  <Box>
                                    {selectedKeys[key]?.checked != undefined ? (
                                      <Badge
                                        value={
                                          selectedKeys[key].checked == false &&
                                          selectedKeys[key].partialChecked ==
                                            false
                                            ? "OFF"
                                            : "ACTIVE"
                                        }
                                        style={{
                                          paddingTop: "0.33rem",
                                          marginRight: "0.63rem",
                                          borderRadius: "0.63rem",
                                          background:
                                            selectedKeys[key].checked ==
                                              false &&
                                            selectedKeys[key].partialChecked ==
                                              false
                                              ? "#E5EBFC"
                                              : "#e5ebfcfc",
                                          color:
                                            selectedKeys[key].checked ==
                                              false &&
                                            selectedKeys[key].partialChecked ==
                                              false
                                              ? "#01004854"
                                              : "var(--Accent-Color-1, #5881FE)",
                                          fontSize: "0.75rem",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          paddingLeft: "0.63rem",
                                        }}
                                      ></Badge>
                                    ) : (
                                      <Badge
                                        value={"OFF"}
                                        style={{
                                          paddingTop: "0.33rem",
                                          marginRight: "0.63rem",
                                          borderRadius: "0.63rem",
                                          background: "#E5EBFC",
                                          color: "#01004854",
                                          fontSize: "0.75rem",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                        }}
                                      ></Badge>
                                    )}
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                        ) : (
                          <Box
                            w="100%"
                            maxHeight={"40rem"}
                            style={{ overflowY: "auto", overflowX: "auto" }}
                          >
                            <Box
                              pt={"2.18rem"}
                              pl={"3.56rem"}
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"flex-start"}
                              gap={"0.13rem"}
                            >
                              <Text
                                style={{
                                  color: "var(--Primary-color, #010048)",
                                  // fontFamily: "Lexend",
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                <IconButton
                                  bg={Colors.forgotBackButtone}
                                  mr={2}
                                  // mt={"3.25rem"}
                                  onClick={() => {
                                    hidePanel();
                                  }}
                                  icon={
                                    <ArrowBackIcon color={Colors.blackIcon} />
                                  }
                                />
                                {webAppTitle}
                              </Text>
                              {/* <Text
                              style={{
                                color: "#01004899",
                                // fontFamily: "Lexend",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                              }}
                            >
                              {webAppSubTitle}
                            </Text> */}
                            </Box>
                            <Box pt={"0.81rem"}>
                              <Divider h={"0.05rem"} bg={"#E6E6E6"} />
                            </Box>
                            <Tree
                              className="custom-tree-container"
                              value={webAppNodes}
                              selectionMode="checkbox"
                              selectionKeys={selectedKeys}
                              onSelectionChange={(e) => handleSelectkeys(e)}
                            />
                          </Box>
                        )}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionButton
                      h={"4.06rem"}
                      style={{
                        background: "#F1F0FD",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        style={{
                          color: "var(--Primary-color, #010048)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <PhoneAndroidIcon
                            style={{
                              color: "#F1F0FD",
                              // height:"1.06rem",
                              // width:"1.06rem",
                              padding: "0.20rem",
                              borderRadius: "0.31rem",
                              border: "0.06rem solid #D6B5FF",
                              background: "#B582F4",
                              boxShadow: "0rem 0.63rem 0.25rem 0rem #dbdbdb2e",
                              marginRight: "0.5rem",
                            }}
                          />
                        </Box>
                        <span
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={500}
                          // lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          Mobile App Permission
                        </span>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={"0rem"}>
                      <Flex
                        flexDirection={{
                          base: "column",
                          sm: "row",
                          md: "row",
                          lg: "row",
                        }}
                      >
                        {!hideYardPanel && !isBase ? (
                          <>
                            <Box
                              w={{
                                base: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "55%",
                              }}
                              h={{ base: "auto", lg: "auto" }}
                              maxHeight={"40rem"}
                              style={{ overflowY: "auto", overflowX: "auto" }}
                            >
                              {Object.keys(octoYardPermission).map(
                                (
                                  key,
                                  value // Corrected map usage
                                ) => (
                                  <Box
                                    key={key}
                                    display={"flex"}
                                    h={"2.81rem"}
                                    pt={"0.63rem"}
                                    // pr={"0.63rem"}
                                    pb={"0.63rem"}
                                    // pl={"1.81rem"}
                                    justifyContent={{
                                      base: "flex-start",
                                      sm: "flex-start",
                                      md: "flex-start",
                                      lg: "flex-start",
                                    }}
                                    alignItems={"flex-start"}
                                    gap={"0.63rem"}
                                    flexShrink={"0"}
                                    style={{
                                      paddingLeft: "0.63rem",
                                      cursor: "pointer",
                                      background:
                                        octoYardClickedPanel ===
                                        octoYardPermission[value][
                                          "display_name"
                                        ]
                                          ? "var(--Accent-Color-1, #5881FE)"
                                          : "#FBFBFB",
                                      color:
                                        octoYardClickedPanel ===
                                        octoYardPermission[value][
                                          "display_name"
                                        ]
                                          ? "var(--Secondary-color, #F4F4F4)"
                                          : "var(--Primary-color, #010048)",
                                    }}
                                    onClick={() =>
                                      handleMobileAppPanelClick(
                                        octoYardPermission[value],
                                        octoYardPermission[value][
                                          "display_name"
                                        ]
                                      )
                                    }
                                  >
                                    <Flex
                                    w={{ base: "100%", md: "13rem" }} alignSelf={"flex-start"}
                                  >
                                    <Text
                                      key={
                                        octoYardPermission[value][
                                          "display_name"
                                        ]
                                      }
                                      // fontFamily={"Lexend"}
                                      // w={"8.88rem"}
                                      flexShrink={"0"}
                                      style={{
                                        fontSize: "0.94rem",
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {
                                        octoYardPermission[value][
                                          "display_name"
                                        ]
                                      }
                                    </Text>
                                    </Flex>
                                    <Box>
                                      {selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ]?.checked != undefined ? (
                                        <Badge
                                          value={
                                            selectedKeys[
                                              octoYardPermission[value]["name"]
                                            ].checked == false &&
                                            selectedKeys[
                                              octoYardPermission[value]["name"]
                                            ].partialChecked == false
                                              ? "OFF"
                                              : "ACTIVE"
                                          }
                                          style={{
                                            paddingTop: "0.33rem",
                                            marginRight: "0.63rem",
                                            borderRadius: "0.63rem",
                                            background:
                                              selectedKeys[
                                                octoYardPermission[value][
                                                  "name"
                                                ]
                                              ].checked == false &&
                                              selectedKeys[
                                                octoYardPermission[value][
                                                  "name"
                                                ]
                                              ].partialChecked == false
                                                ? "#E5EBFC"
                                                : "#e5ebfcfc",
                                            color:
                                              selectedKeys[
                                                octoYardPermission[value][
                                                  "name"
                                                ]
                                              ].checked == false &&
                                              selectedKeys[
                                                octoYardPermission[value][
                                                  "name"
                                                ]
                                              ].partialChecked == false
                                                ? "#01004854"
                                                : "var(--Accent-Color-1, #5881FE)",
                                            fontSize: "0.75rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                            letterSpacing: "-0.01rem",
                                            paddingLeft: "0.63rem",
                                          }}
                                        ></Badge>
                                      ) : (
                                        <Badge
                                          value={"OFF"}
                                          style={{
                                            paddingTop: "0.33rem",
                                            marginRight: "0.63rem",
                                            borderRadius: "0.63rem",
                                            background: "#E5EBFC",
                                            color: "#01004854",
                                            fontSize: "0.75rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                            letterSpacing: "-0.01rem",
                                          }}
                                        ></Badge>
                                      )}
                                      {/* {selectedKeys[octoYardPermission[value]["name"]]
                                ?.checked != undefined ? (
                                <Text
                                  display={"flex"}
                                  w={"2.81rem"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  gap={"0.63rem"}
                                  flexShrink={"0"}
                                  style={{
                                    borderRadius: "0.63rem",
                                    background:
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].checked == false &&
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].partialChecked == false
                                        ? "#E5EBFC"
                                        : "#e5ebfcfc",
                                    color:
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].checked == false &&
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].partialChecked == false
                                        ? "#01004854"
                                        : "var(--Accent-Color-1, #5881FE)",
                                    fontSize: "0.75rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {selectedKeys[
                                    octoYardPermission[value]["name"]
                                  ].checked == false &&
                                  selectedKeys[
                                    octoYardPermission[value]["name"]
                                  ].partialChecked == false
                                    ? "OFF"
                                    : "ACTIVE"}
                                </Text>
                              ) : (
                                <Text
                                  display={"flex"}
                                  w={"2.81rem"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  gap={"0.63rem"}
                                  flexShrink={"0"}
                                  style={{
                                    borderRadius: "0.63rem",
                                    background: "#E5EBFC",
                                    color: "#01004854",
                                    fontSize: "0.75rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {"OFF"}
                                </Text>
                              )} */}
                                    </Box>
                                  </Box>
                                )
                              )}
                            </Box>
                            <Box
                              w="100%"
                              maxHeight={"40rem"}
                              style={{ overflowY: "auto", overflowX: "auto" }}
                            >
                              <Box
                                pt={"2.18rem"}
                                pl={"3.56rem"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                                gap={"0.13rem"}
                              >
                                <Text
                                  style={{
                                    color: "var(--Primary-color, #010048)",
                                    // fontFamily: "Lexend",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {isBase && (
                                    <IconButton
                                      bg={Colors.forgotBackButtone}
                                      mr={2}
                                      onClick={() => {
                                        handleHideYardPanel();
                                      }}
                                      icon={
                                        <ArrowBackIcon
                                          color={Colors.blackIcon}
                                        />
                                      }
                                    />
                                  )}
                                  {mobileAppTitle}
                                </Text>
                              </Box>
                              <Box pt={"0.81rem"}>
                                <Divider h={"0.05rem"} bg={"#E6E6E6"} />
                              </Box>
                              <Tree
                                className="custom-tree-container"
                                value={mobileAppNodes}
                                emptyMessage="Please Select Permission from the list"
                                selectionMode="checkbox"
                                selectionKeys={selectedKeys}
                                onSelectionChange={(e) => handleSelectkeys(e)}
                              />
                            </Box>
                          </>
                        ) : !hideYardPanel && isBase ? (
                          <Box
                            w={{
                              base: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "55%",
                            }}
                            h={{ base: "auto", lg: "auto" }}
                            maxHeight={"40rem"}
                            style={{ overflowY: "auto", overflowX: "auto" }}
                          >
                            {Object.keys(octoYardPermission).map(
                              (
                                key,
                                value // Corrected map usage
                              ) => (
                                <Box
                                  key={key}
                                  display={"flex"}
                                  h={"2.81rem"}
                                  pt={"0.63rem"}
                                  // pr={"0.63rem"}
                                  pb={"0.63rem"}
                                  // pl={"1.81rem"}
                                  justifyContent={{
                                    base: "flex-start",
                                    sm: "flex-start",
                                    md: "flex-start",
                                    lg: "flex-start",
                                  }}
                                  alignItems={"flex-start"}
                                  gap={"0.63rem"}
                                  flexShrink={"0"}
                                  style={{
                                    paddingLeft: "0.63rem",
                                    cursor: "pointer",
                                    background:
                                      octoYardClickedPanel ===
                                      octoYardPermission[value]["display_name"]
                                        ? "var(--Accent-Color-1, #5881FE)"
                                        : "#FBFBFB",
                                    color:
                                      octoYardClickedPanel ===
                                      octoYardPermission[value]["display_name"]
                                        ? "var(--Secondary-color, #F4F4F4)"
                                        : "var(--Primary-color, #010048)",
                                  }}
                                  onClick={() =>
                                    handleMobileAppPanelClick(
                                      octoYardPermission[value],
                                      octoYardPermission[value]["display_name"]
                                    )
                                  }
                                >
                                  <Flex
                                    w={{ base: "100%", md: "13rem" }} alignSelf={"flex-start"}
                                  >
                                  <Text
                                    key={
                                      octoYardPermission[value]["display_name"]
                                    }
                                    // fontFamily={"Lexend"}
                                    // w={"8.88rem"}
                                    flexShrink={"0"}
                                    style={{
                                      fontSize: "0.94rem",
                                      fontStyle: "normal",
                                      fontWeight: "300",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    {octoYardPermission[value]["display_name"]}
                                  </Text>
                                  </Flex>
                                  <Box>
                                    {selectedKeys[
                                      octoYardPermission[value]["name"]
                                    ]?.checked != undefined ? (
                                      <Badge
                                        value={
                                          selectedKeys[
                                            octoYardPermission[value]["name"]
                                          ].checked == false &&
                                          selectedKeys[
                                            octoYardPermission[value]["name"]
                                          ].partialChecked == false
                                            ? "OFF"
                                            : "ACTIVE"
                                        }
                                        style={{
                                          paddingTop: "0.33rem",
                                          marginRight: "0.63rem",
                                          borderRadius: "0.63rem",
                                          background:
                                            selectedKeys[
                                              octoYardPermission[value]["name"]
                                            ].checked == false &&
                                            selectedKeys[
                                              octoYardPermission[value]["name"]
                                            ].partialChecked == false
                                              ? "#E5EBFC"
                                              : "#e5ebfcfc",
                                          color:
                                            selectedKeys[
                                              octoYardPermission[value]["name"]
                                            ].checked == false &&
                                            selectedKeys[
                                              octoYardPermission[value]["name"]
                                            ].partialChecked == false
                                              ? "#01004854"
                                              : "var(--Accent-Color-1, #5881FE)",
                                          fontSize: "0.75rem",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          paddingLeft: "0.63rem",
                                        }}
                                      ></Badge>
                                    ) : (
                                      <Badge
                                        value={"OFF"}
                                        style={{
                                          paddingTop: "0.33rem",
                                          marginRight: "0.63rem",
                                          borderRadius: "0.63rem",
                                          background: "#E5EBFC",
                                          color: "#01004854",
                                          fontSize: "0.75rem",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                        }}
                                      ></Badge>
                                    )}
                                    {/* {selectedKeys[octoYardPermission[value]["name"]]
                                ?.checked != undefined ? (
                                <Text
                                  display={"flex"}
                                  w={"2.81rem"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  gap={"0.63rem"}
                                  flexShrink={"0"}
                                  style={{
                                    borderRadius: "0.63rem",
                                    background:
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].checked == false &&
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].partialChecked == false
                                        ? "#E5EBFC"
                                        : "#e5ebfcfc",
                                    color:
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].checked == false &&
                                      selectedKeys[
                                        octoYardPermission[value]["name"]
                                      ].partialChecked == false
                                        ? "#01004854"
                                        : "var(--Accent-Color-1, #5881FE)",
                                    fontSize: "0.75rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {selectedKeys[
                                    octoYardPermission[value]["name"]
                                  ].checked == false &&
                                  selectedKeys[
                                    octoYardPermission[value]["name"]
                                  ].partialChecked == false
                                    ? "OFF"
                                    : "ACTIVE"}
                                </Text>
                              ) : (
                                <Text
                                  display={"flex"}
                                  w={"2.81rem"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  gap={"0.63rem"}
                                  flexShrink={"0"}
                                  style={{
                                    borderRadius: "0.63rem",
                                    background: "#E5EBFC",
                                    color: "#01004854",
                                    fontSize: "0.75rem",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {"OFF"}
                                </Text>
                              )} */}
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                        ) : (
                          <Box
                            w="100%"
                            maxHeight={"40rem"}
                            style={{ overflowY: "auto", overflowX: "auto" }}
                          >
                            <Box
                              pt={"2.18rem"}
                              pl={"3.56rem"}
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"flex-start"}
                              gap={"0.13rem"}
                            >
                              <Text
                                style={{
                                  color: "var(--Primary-color, #010048)",
                                  // fontFamily: "Lexend",
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                <IconButton
                                  bg={Colors.forgotBackButtone}
                                  mr={2}
                                  // mt={"3.25rem"}
                                  onClick={() => {
                                    handleHideYardPanel();
                                  }}
                                  icon={
                                    <ArrowBackIcon color={Colors.blackIcon} />
                                  }
                                />
                                {mobileAppTitle}
                              </Text>
                              {/* <Text
                            style={{
                              color: "#01004899",
                              // fontFamily: "Lexend",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {mobileAppSubTitle}
                          </Text> */}
                            </Box>
                            <Box pt={"0.81rem"}>
                              <Divider h={"0.05rem"} bg={"#E6E6E6"} />
                            </Box>
                            <Tree
                              className="custom-tree-container"
                              value={mobileAppNodes}
                              emptyMessage="Please Select Permission from the list"
                              selectionMode="checkbox"
                              selectionKeys={selectedKeys}
                              onSelectionChange={(e) => handleSelectkeys(e)}
                            />
                          </Box>
                        )}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <AccordionButton
                      h={"4.06rem"}
                      style={{
                        background: "#F1F0FD",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        flex="1"
                        textAlign="left"
                        style={{
                          color: "var(--Primary-color, #010048)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <ShoppingCartIcon
                            style={{
                              color: "#F1F0FD",
                              // height:"1.06rem",
                              // width:"1.06rem",
                              padding: "0.20rem",
                              borderRadius: "0.31rem",
                              border: "0.06rem solid #D6B5FF",
                              background: "#B582F4",
                              boxShadow: "0rem 0.63rem 0.25rem 0rem #dbdbdb2e",
                              marginRight: "0.5rem",
                            }}
                          />
                        </Box>
                        <span
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={500}
                          // lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          Pos App Permission
                        </span>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={"0rem"}>
                      <Flex
                        flexDirection={{
                          base: "column",
                          sm: "row",
                          md: "row",
                          lg: "row",
                        }}
                      >
                        {!hidePosPanel && !isBase ? (
                          <>
                            <Box
                              w={{
                                base: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "55%",
                              }}
                              style={{ overflowY: "auto", overflowX: "auto" }}
                            >
                              {Object.keys(octoPosAppPermission).map(
                                (
                                  key,
                                  value // Corrected map usage
                                ) => (
                                  <Box
                                    key={key}
                                    display={"flex"}
                                    h={"2.81rem"}
                                    pt={"0.63rem"}
                                    // pr={"0.63rem"}
                                    pb={"0.63rem"}
                                    // pl={"1.81rem"}
                                    justifyContent={{
                                      base: "flex-start",
                                      sm: "flex-start",
                                      md: "flex-start",
                                      lg: "flex-start",
                                    }}
                                    alignItems={"flex-start"}
                                    gap={"0.63rem"}
                                    flexShrink={"0"}
                                    style={{
                                      paddingLeft: "0.63rem",
                                      cursor: "pointer",
                                      background:
                                        octoPosAppclickedPanel ===
                                        octoPosAppPermission[value][
                                          "display_name"
                                        ]
                                          ? "var(--Accent-Color-1, #5881FE)"
                                          : "#FBFBFB",
                                      color:
                                        octoPosAppclickedPanel ===
                                        octoPosAppPermission[value][
                                          "display_name"
                                        ]
                                          ? "var(--Secondary-color, #F4F4F4)"
                                          : "var(--Primary-color, #010048)",
                                    }}
                                    onClick={() =>
                                      handlePosAppPanelClick(
                                        octoPosAppPermission[value],
                                        octoPosAppPermission[value][
                                          "display_name"
                                        ]
                                      )
                                    }
                                  >
                                    <Flex
                                    w={{ base: "100%", md: "13rem" }} alignSelf={"flex-start"}
                                  >
                                    <Text
                                      key={
                                        octoPosAppPermission[value][
                                          "display_name"
                                        ]
                                      }
                                      // fontFamily={"Lexend"}
                                      // w={"8.88rem"}
                                      flexShrink={"0"}
                                      style={{
                                        fontSize: "0.94rem",
                                        fontStyle: "normal",
                                        fontWeight: "300",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      {
                                        octoPosAppPermission[value][
                                          "display_name"
                                        ]
                                      }
                                    </Text>
                                    </Flex>
                                    <Box>
                                      {selectedKeys[
                                        octoPosAppPermission[value]["name"]
                                      ]?.checked != undefined ? (
                                        <Badge
                                          value={
                                            selectedKeys[
                                              octoPosAppPermission[value][
                                                "name"
                                              ]
                                            ].checked == false &&
                                            selectedKeys[
                                              octoPosAppPermission[value][
                                                "name"
                                              ]
                                            ].partialChecked == false
                                              ? "OFF"
                                              : "ACTIVE"
                                          }
                                          style={{
                                            paddingTop: "0.33rem",
                                            marginRight: "0.63rem",
                                            borderRadius: "0.63rem",
                                            background:
                                              selectedKeys[
                                                octoPosAppPermission[value][
                                                  "name"
                                                ]
                                              ].checked == false &&
                                              selectedKeys[
                                                octoPosAppPermission[value][
                                                  "name"
                                                ]
                                              ].partialChecked == false
                                                ? "#E5EBFC"
                                                : "#e5ebfcfc",
                                            color:
                                              selectedKeys[
                                                octoPosAppPermission[value][
                                                  "name"
                                                ]
                                              ].checked == false &&
                                              selectedKeys[
                                                octoPosAppPermission[value][
                                                  "name"
                                                ]
                                              ].partialChecked == false
                                                ? "#01004854"
                                                : "var(--Accent-Color-1, #5881FE)",
                                            fontSize: "0.75rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                            letterSpacing: "-0.01rem",
                                          }}
                                        ></Badge>
                                      ) : (
                                        <Badge
                                          value={"OFF"}
                                          style={{
                                            paddingTop: "0.33rem",
                                            marginRight: "0.63rem",
                                            borderRadius: "0.63rem",
                                            background: "#E5EBFC",
                                            color: "#01004854",
                                            fontSize: "0.75rem",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                            letterSpacing: "-0.01rem",
                                          }}
                                        ></Badge>
                                      )}
                                      {/* {selectedKeys[
                                  octoPosAppPermission[value]["name"]
                                ]?.checked != undefined ? (
                                  <Text
                                    display={"flex"}
                                    w={"2.81rem"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    gap={"0.63rem"}
                                    flexShrink={"0"}
                                    style={{
                                      borderRadius: "0.63rem",
                                      background:
                                        selectedKeys[
                                          octoPosAppPermission[value]["name"]
                                        ].checked == false &&
                                        selectedKeys[
                                          octoPosAppPermission[value]["name"]
                                        ].partialChecked == false
                                          ? "#E5EBFC"
                                          : "#e5ebfcfc",
                                      color:
                                        selectedKeys[
                                          octoPosAppPermission[value]["name"]
                                        ].checked == false &&
                                        selectedKeys[
                                          octoPosAppPermission[value]["name"]
                                        ].partialChecked == false
                                          ? "#01004854"
                                          : "var(--Accent-Color-1, #5881FE)",
                                      fontSize: "0.75rem",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      lineHeight: "normal",
                                      letterSpacing: "-0.01rem",
                                    }}
                                  >
                                    {selectedKeys[
                                      octoPosAppPermission[value]["name"]
                                    ].checked == false &&
                                    selectedKeys[
                                      octoPosAppPermission[value]["name"]
                                    ].partialChecked == false
                                      ? "OFF"
                                      : "ACTIVE"}
                                  </Text>
                                ) : (
                                  <Text
                                    display={"flex"}
                                    w={"2.81rem"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    gap={"0.63rem"}
                                    flexShrink={"0"}
                                    style={{
                                      borderRadius: "0.63rem",
                                      background: "#E5EBFC",
                                      color: "#01004854",
                                      fontSize: "0.75rem",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      lineHeight: "normal",
                                      letterSpacing: "-0.01rem",
                                    }}
                                  >
                                    {"OFF"}
                                  </Text>
                                )} */}
                                    </Box>
                                  </Box>
                                )
                              )}
                            </Box>
                            <Box
                              w="100%"
                              maxHeight={"40rem"}
                              style={{ overflowY: "auto", overflowX: "auto" }}
                            >
                              <Box
                                pt={"2.18rem"}
                                pl={"3.56rem"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"flex-start"}
                                gap={"0.13rem"}
                              >
                                <Text
                                  style={{
                                    color: "var(--Primary-color, #010048)",
                                    // fontFamily: "Lexend",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {isBase && (
                                    <IconButton
                                      bg={Colors.forgotBackButtone}
                                      mr={2}
                                      onClick={() => {
                                        handleHidePOSAppPanel();
                                      }}
                                      icon={
                                        <ArrowBackIcon
                                          color={Colors.blackIcon}
                                        />
                                      }
                                    />
                                  )}
                                  {posAppTitle}
                                </Text>
                                {/* <Text
                              style={{
                                color: "#01004899",
                                // fontFamily: "Lexend",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                              }}
                            >
                              {posAppSubTitle}
                            </Text> */}
                              </Box>
                              <Box pt={"0.81rem"}>
                                <Divider h={"0.05rem"} bg={"#E6E6E6"} />
                              </Box>
                              <Tree
                                className="custom-tree-container"
                                value={posAppNodes}
                                emptyMessage="Please Select Permission from the list"
                                selectionMode="checkbox"
                                selectionKeys={selectedKeys}
                                onSelectionChange={(e) => handleSelectkeys(e)}
                              />
                            </Box>
                          </>
                        ) : !hidePosPanel && isBase ? (
                          <Box
                            w={{
                              base: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "55%",
                            }}
                            style={{ overflowY: "auto", overflowX: "auto" }}
                          >
                            {Object.keys(octoPosAppPermission).map(
                              (
                                key,
                                value // Corrected map usage
                              ) => (
                                <Box
                                  key={key}
                                  display={"flex"}
                                  h={"2.81rem"}
                                  pt={"0.63rem"}
                                  // pr={"0.63rem"}
                                  pb={"0.63rem"}
                                  // pl={"1.81rem"}
                                  justifyContent={{
                                    base: "flex-start",
                                    sm: "flex-start",
                                    md: "flex-start",
                                    lg: "flex-start",
                                  }}
                                  alignItems={"flex-start"}
                                  gap={"0.63rem"}
                                  flexShrink={"0"}
                                  style={{
                                    paddingLeft: "0.63rem",
                                    cursor: "pointer",
                                    background:
                                      octoPosAppclickedPanel ===
                                      octoPosAppPermission[value][
                                        "display_name"
                                      ]
                                        ? "var(--Accent-Color-1, #5881FE)"
                                        : "#FBFBFB",
                                    color:
                                      octoPosAppclickedPanel ===
                                      octoPosAppPermission[value][
                                        "display_name"
                                      ]
                                        ? "var(--Secondary-color, #F4F4F4)"
                                        : "var(--Primary-color, #010048)",
                                  }}
                                  onClick={() =>
                                    handlePosAppPanelClick(
                                      octoPosAppPermission[value],
                                      octoPosAppPermission[value][
                                        "display_name"
                                      ]
                                    )
                                  }
                                >
                                  <Flex
                                    w={{ base: "100%", md: "13rem" }} alignSelf={"flex-start"}
                                  >
                                  <Text
                                    key={
                                      octoPosAppPermission[value][
                                        "display_name"
                                      ]
                                    }
                                    // fontFamily={"Lexend"}
                                    // w={"8.88rem"}
                                    flexShrink={"0"}
                                    style={{
                                      fontSize: "0.94rem",
                                      fontStyle: "normal",
                                      fontWeight: "300",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    {
                                      octoPosAppPermission[value][
                                        "display_name"
                                      ]
                                    }
                                  </Text>
                                  </Flex>
                                  <Box>
                                    {selectedKeys[
                                      octoPosAppPermission[value]["name"]
                                    ]?.checked != undefined ? (
                                      <Badge
                                        value={
                                          selectedKeys[
                                            octoPosAppPermission[value]["name"]
                                          ].checked == false &&
                                          selectedKeys[
                                            octoPosAppPermission[value]["name"]
                                          ].partialChecked == false
                                            ? "OFF"
                                            : "ACTIVE"
                                        }
                                        style={{
                                          paddingTop: "0.33rem",
                                          marginRight: "0.63rem",
                                          borderRadius: "0.63rem",
                                          background:
                                            selectedKeys[
                                              octoPosAppPermission[value][
                                                "name"
                                              ]
                                            ].checked == false &&
                                            selectedKeys[
                                              octoPosAppPermission[value][
                                                "name"
                                              ]
                                            ].partialChecked == false
                                              ? "#E5EBFC"
                                              : "#e5ebfcfc",
                                          color:
                                            selectedKeys[
                                              octoPosAppPermission[value][
                                                "name"
                                              ]
                                            ].checked == false &&
                                            selectedKeys[
                                              octoPosAppPermission[value][
                                                "name"
                                              ]
                                            ].partialChecked == false
                                              ? "#01004854"
                                              : "var(--Accent-Color-1, #5881FE)",
                                          fontSize: "0.75rem",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                        }}
                                      ></Badge>
                                    ) : (
                                      <Badge
                                        value={"OFF"}
                                        style={{
                                          paddingTop: "0.33rem",
                                          marginRight: "0.63rem",
                                          borderRadius: "0.63rem",
                                          background: "#E5EBFC",
                                          color: "#01004854",
                                          fontSize: "0.75rem",
                                          fontStyle: "normal",
                                          fontWeight: "400",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                        }}
                                      ></Badge>
                                    )}
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                        ) : (
                          <Box
                            w="100%"
                            maxHeight={"40rem"}
                            style={{ overflowY: "auto", overflowX: "auto" }}
                          >
                            <Box
                              pt={"2.18rem"}
                              pl={"3.56rem"}
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"flex-start"}
                              gap={"0.13rem"}
                            >
                              <Text
                                style={{
                                  color: "var(--Primary-color, #010048)",
                                  // fontFamily: "Lexend",
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                <IconButton
                                  bg={Colors.forgotBackButtone}
                                  mr={2}
                                  onClick={() => {
                                    handleHidePOSAppPanel();
                                  }}
                                  icon={
                                    <ArrowBackIcon color={Colors.blackIcon} />
                                  }
                                />
                                {posAppTitle}
                              </Text>
                            </Box>
                            <Box pt={"0.81rem"}>
                              <Divider h={"0.05rem"} bg={"#E6E6E6"} />
                            </Box>
                            <Tree
                              className="custom-tree-container"
                              value={posAppNodes}
                              emptyMessage="Please Select Permission from the list"
                              selectionMode="checkbox"
                              selectionKeys={selectedKeys}
                              onSelectionChange={(e) => handleSelectkeys(e)}
                            />
                          </Box>
                        )}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Grid>
            </Stack>

            <Flex
              direction={{base:"column",md: "row"}}
              // direction={"row"}
              // justifyContent={"end"}
              // alignSelf={"flex-end"}
              gap={2}
              pt={"3rem"}
            >

              <PosFormButton
                onClick={() => {
                  props.navigate(Constants.HR_ROLE_PATH, {
                    state: paramData,
                  });
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              />
              <Spacer />
              <ButtonGroup gap="2" alignSelf={"flex-end"}>
              <Flex gap={2}
              direction={{base:"row",sm:"row",md: "row",lg:"row" }}
            >
            <PosFormButton
              onClick={() => {
                previous();
              }}
              buttonText={"Previous"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                (formik.errors.name && formik.touched.name) ||
                (formik.errors.display_name && formik.touched.display_name) ||
                formik.errors.description ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            /></Flex>
              </ButtonGroup>
              {/* </Flex> */}
            </Flex>
          </Box>
        </Box>
      )}
    </Flex>
    // </MainLayout>
  );
};

export default WithRouter(RoleCreate);
