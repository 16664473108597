import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState, useRef } from "react";
import {
  AppContext,
  PosIconButton,
  PosListHeader,
  PosProgress,
  PosSearch,
  PosTab,
  PosTable,
  PosTostMessage,
  PosNoDataFound,
  PosDataGridTable,
  PosSpliteButton,
  PosDialogAlert
} from "../../../components/index";
import { useActionData, useSubmit,useLocation } from "react-router-dom";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllDeactiveVendor, getAllVendors } from "./VendorService";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Colors from "../../../assets/colors/Colors";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { GridToolbar } from "@mui/x-data-grid";

const VendorList = (props) => {
  const {
    isOpen: isDeactiveConfirmOpen,
    onOpen: onDeactiveConfirmOpen,
    onClose: onDeactiveConfirmClose,
  } = useDisclosure();

  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH);
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [vendorData, setVendorData] = useState([]);
  const [vendorDeactiveData, setVendorDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [sortType, setSortType] = useState("desc");
  // const [sortColumn, setSortColumn] = useState("id");
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
  });
  const cancelRef = React.useRef();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [deactiveItem, setDeactiveItem] = useState({});
  const [isColumnChange] = useMediaQuery("(max-width: 1714px)");
  // const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const [isExtraLarge] = useMediaQuery("(min-width: 1880px)");
  // const columnNames = [
  //   {
  //     columnNames: "ID",
  //     sortName: "id",
  //   },
  //   {
  //     columnNames: "Name",
  //     sortName: "name",
  //   },
  //   {
  //     columnNames: "Email",
  //     sortName: "email",
  //   },
  //   {
  //     columnNames: "Address",
  //     sortName: "address",
  //   },
  //   {
  //     columnNames: "Contact Person",
  //     sortName: "contact_person",
  //   },
  //   {
  //     columnNames: "City",
  //     sortName: "city",
  //   },
  //   {
  //     columnNames: "State",
  //     sortName: "state",
  //   },
  //   {
  //     columnNames: "Country",
  //     sortName: "",
  //   },
  //   {
  //     columnNames: "Phone Number",
  //     sortName: "",
  //   },
  //   {
  //     columnNames: "Actions",
  //     sortName: "",
  //   },
  // ];
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    { field: "name", headerName: "Vendor Name", sortable: true, ...actionColumnFlexDouble },
    {
      field: "email",
      headerName: "Vendor Email",
      sortable: true,
      ...actionColumnFlexDouble,
      valueGetter: (value, row) => `${row.email || 'N.A.'}`,
    },
    {
      field: "contact_person",
      headerName: "Vendor Contact",
      sortable: true,
      ...actionColumnXL,
      valueGetter: (value, row) => `${row.contact_person || 'N.A.'}`,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      ...actionColumnMD,
      valueGetter: (value, row) => `${row.phone || 'N.A.'}`,
    },
    {
      field: "city",
      headerName: "City",
      sortable: true,
      ...actionColumnSM,
      valueGetter: (value, row) => `${row.city || 'N.A.'}`,
    },
    {
      field: "country",
      headerName: "Country",
      sortable: true,
      ...actionColumnLG,
      valueGetter: (value, row) =>  `${row.state != 'N.A.' && row.state != "" && row.state != null ? row.state+", " : ""|| ''} ${row.country || 'N.A.'}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 125,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            filter: searchQuery,
          }
          props.navigate(`${Constants.VENDOR_EDIT + item.id}`,{
            state: data,
          });
        };
        const handleViewPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            filter: searchQuery,
          }
          props.navigate(`${Constants.VENDOR_VIEW + item.id}`,{
            state: data,
          });
        };
        // const handleDeactive = (item) => {
        //   setButtonDisable(true);
        //   if(modifiedData.length == 1 && paginationModel.page != 0){
        //     paginationModel.page = paginationModel.page - 1;
        //   }
        //   myContext.handleLoading(true);
        //   let data = {
        //     id: deactiveItem.id,
        //     type: Constants.DEACTIVE,
        //   };
        //   submit(data, {
        //     method: Constants.DELETE,
        //     path: Constants.VENDOR_LIST,
        //   });
        // };
        const handleModelOpen = (item) => {
          onDeactiveConfirmOpen();
          setDeactiveItem(item);
          // handleDeactive(item);
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            // {
            //   name: "View",
            //   icon: (
            //     <RemoveRedEyeOutlinedIcon
            //       style={{ color: Colors.posViewTextColor }}
            //     />
            //   ),
            //   onClick: (item) => handleViewPage(item),
            // },
            {
              name: "Deactive",
              disabledButton:buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleModelOpen(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const deactivatedColumnNames = [
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    { field: "name", headerName: "Vendor Name", sortable: true, ...actionColumnFlexDouble},
    {
      field: "email",
      headerName: "Vendor Email",
      sortable: true,
      ...actionColumnFlexDouble,
      valueGetter: (value, row) => `${row.email || 'N.A.'}`,
    },    {
      field: "contact_person",
      headerName: "Vendor Contact",
      sortable: true,
      ...actionColumnXL,
      valueGetter: (value, row) => `${row.contact_person || 'N.A.'}`,
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: true,
      ...actionColumnMD,
      valueGetter: (value, row) => `${row.phone || 'N.A.'}`,
    },
    {
      field: "city",
      headerName: "City",
      sortable: true,
      ...actionColumnSM,
      valueGetter: (value, row) => `${row.city || 'N.A.'}`,
    },
    {
      field: "country",
      headerName: "Country",
      sortable: true,
      ...actionColumnLG,
      valueGetter: (value, row) =>  `${row.state != 'N.A.' && row.state != "" && row.state != null ? row.state+", " : ""|| ''} ${row.country || 'N.A.'}`,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          if(deactiveVendorData.length == 1 && paginationModelDeactive.page != 0){
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          setButtonDisable(true);
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.VENDOR_LIST,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            // textDecoration="underline"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];
  const stateData = useLocation(); // get backraking data as param
  const [paginationModel, setPaginationModel] = useState({
    page: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page: stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:  undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "id"
  );
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const effectRun = useRef(true);
  const handleDeactive = (item) => {
    if(modifiedData.length == 1 && paginationModel.page != 0){
      paginationModel.page = paginationModel.page - 1;
    }
    setButtonDisable(true);
    let data = {
      id: deactiveItem.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.VENDOR_LIST,
    });
    onDeactiveConfirmClose();
  };

  const handlePageChange = (newPage) => {
    effectRun.current = true;
    setPage(newPage);
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.VENDOR_LIST);
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    effectRun.current = true;
    setRowsPerPage(newRowsPerPage);
  };
  const handleSortColumn = (column, sort) => {
    setLoading(true);
    setSortColumn(column.sortName);
    effectRun.current = true;
    setSortType(sort);
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllVendors(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setVendorData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
          } else {
            myContext.handleLoading(false);
            setVendorData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          myContext.handleLoading(false);
          setVendorData([]);
          setLoading(false);
          setTotalCount(0);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      myContext.handleLoading(false);
      setVendorData([]);
      setTotalCount(0);
    }
  };
  const fetchDeActiveVendorData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].field : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].sort : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveVendor(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setVendorDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
          } else {
            myContext.handleLoading(false);
            setVendorDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          myContext.handleLoading(false);
          setVendorDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      myContext.handleLoading(false);
      setVendorDeactiveData([]);
      setDetactiveTotaCount(0);
    }
  };
  // useEffect(() => {
  //   if (effectRun.current === true) {
  //     if (curruntTab === 0) {
  //       fetchData();
  //     } else {
  //       fetchDeActiveVendorData();
  //     }
  //   }
  //   effectRun.current = false;
  // }, [ searchQuery, rowsPerPage, page, loading,paginationModel,sortModel]);

  useEffect(() => {
    if (effectRun.current === true) {
    if (curruntTab === 0) {
      fetchData();
    } else {
      fetchDeActiveVendorData();
    }
    effectRun.current = false;
  }
  } ,[paginationModel,sortModel,page,loading,searchQuery,paginationModelDeactive,sortModelDeactive]);


  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        myContext.handleLoading(false);
        setButtonDisable(false);
        let actionData = actionResponse;
        error({ actionData });
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          myContext.handleLoading(false);
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.VENDOR_MODULE,
            alertDescription: Constants.VENDOR_DEACTIVE_SUCCESS,
          });
          setButtonDisable(false);
        } else {
          myContext.handleLoading(false);
          setLoading(true);
          fetchDeActiveVendorData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.VENDOR_MODULE,
            alertDescription: Constants.VENDOR_REACTIVE_SUCCESS,
          });
          setButtonDisable(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      // }
    }
  }, [actionResponse]);

  // const handleEditPage = (item) => {
  //   //TODO
  //   setTopLoading(true);
  //   let data = {
  //     limit: paginationModel.pageSize,
  //     page: paginationModel.page + 1,
  //     order:
  //       sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
  //     order_type:
  //       sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
  //     filter: searchQuery,
  //   }
  //   props.navigate(`${Constants.VENDOR_EDIT + item.id}`,{
  //     state: data,
  //   });
  // };
  const handleReactive = (id) => {
    let data = {
      id: id,
      type: Constants.REACTIVE,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.VENDOR_LIST,
    });
  };
  const handleSortType = (order) => {
    setSortType(order);
  };
  const handleTabChange = (index) => {
    setCurruntTab(index);
    setLoading(true);
    setSearchQuery("");
    setPaginationModelDeactive({
      page: 0,
      pageSize:25,
    });
    setSortModelDeactive([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize:25,
    });
    setSortModel([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    effectRun.current = true;
    if(index == 0){
      deactiveSearchHideRef.current = false;
    }else{
      searchInputHideRef.current = false;
    }
  };
  const handleSearchList = () => {
    props.navigate(Constants.VENDOR_LIST);
    // search time get active and deactive list
    if (curruntTab == 0) {
      paginationModel.page = 0;
      fetchData();
    } else {
      fetchDeActiveVendorData();
    }
  };
  const modifiedData = vendorData.map((vendor) => ({
    // ...vendor,
    id:vendor.id,
    name:vendor.name,
    email:vendor.email != null && '' != vendor.email ? vendor.email : "N.A.",
    contact_person:vendor.contact_person != null ? vendor.contact_person : "",
    phone: vendor.phone != null ? vendor.phone : "",
    city: vendor.city != null ? vendor.city : "",
    state: vendor.state != null ? vendor.state : "",
    country: vendor.country != null ? vendor.country : "",
  }));

  const deactiveVendorData = vendorDeactiveData.map((vendor) => ({
    ...vendor,
    email:vendor.email != null && '' != vendor.email ? vendor.email : "N.A.",
    address: vendor.address != null ? vendor.address : "N.A.",
    contact_person:vendor.contact_person != null ? vendor.contact_person : "N.A.",
    city: vendor.city != null ? vendor.city : "N.A.",
    state: vendor.state != null ? vendor.state : "N.A.",
    country: vendor.country != null ? vendor.country : "N.A.",
    phone: vendor.phone != null ? vendor.phone : "N.A.",
  }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        isOpen={isDeactiveConfirmOpen}
        onClose={onDeactiveConfirmClose}
        title={"Deactivate Vendor"}
        discription={
          "Are you sure you want to deactivate the vendor!?"
        }
        onClick={handleDeactive}
        buttonNames={["Cancel", "Deactivate"]}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Vendors"}
          discription={"Manage Vendors for your products"}
        />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
                  filter: curruntTab == 1 ? "" : searchQuery,
              }
              props.navigate(Constants.VENDOR_CREATE,{
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =searchQuery.length > 0 ? 
                e.target.value: e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear} 
            />
            <Spacer />
          </Flex> */}
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                // <PosTable
                //   columnNames={columnNames}
                //   rawdata={modifiedData}
                //   totalcount={totalcount}
                //   spliteButton={true}
                //   spliteOptions={options}
                //   DectivatedId={handleDeactive}
                //   onPageChange={handlePageChange}
                //   onRowsPerPageChange={handleRowsPerPageChange}
                //   handleSortColumn={handleSortColumn}
                //   sortColumnName={sortColumn}
                //   order={sortType}
                //   page={page}
                //   rowsPerPage={rowsPerPage}
                //   goEdit={(id) => handleEditPage(id)}
                // />
                <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPaginationModel) => {
                  effectRun.current = true;
                  setPaginationModel(newPaginationModel);
                }}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                slots={{
                  toolbar: GridToolbar,
                }}
                columnVisibilityModel={olColumnVisibilityModel}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  sortRef.current = sortModel;
                  effectRun.current = true;
                  setSortModel(newSortModel);
                }}
                fileName={Constants.ACTIVE_VENDORS_EXPORT_FILENAME}
              />
              ) : (
                <PosNoDataFound title={Constants.VENDOR_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                // <PosTable
                //   columnNames={columnNames}
                //   rawdata={deactiveVendorData}
                //   totalcount={deactiveTotalCount}
                //   DectivatedId={handleDeactive}
                //   onPageChange={handlePageChange}
                //   onRowsPerPageChange={handleRowsPerPageChange}
                //   goEdit={(id) => handleEditPage(id)}
                //   goReactive={(id) => handleReactive(id)}
                //   handleSortColumn={handleSortColumn}
                //   sortColumnName={sortColumn}
                //   page={page}
                //   rowsPerPage={rowsPerPage}
                // />
                <PosDataGridTable
                columns={deactivatedColumnNames}
                rows={deactiveVendorData}
                totalcount={deactiveTotalCount}
                paginationModel={paginationModelDeactive}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPaginationModelDeactive) => {
                  effectRun.current = true;
                  setPaginationModelDeactive(newPaginationModelDeactive);
                }}
                sortModel={sortModelDeactive}
                onSortModelChange={(newSortModelDeactive) => {
                  sortRefDeactive.current = sortModelDeactive;
                  effectRun.current = true;
                  setSortModelDeactive(newSortModelDeactive);
                }}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                slots={{
                  toolbar: GridToolbar,
                }}
                columnVisibilityModel={olColumnVisibilityModel}
                fileName={Constants.DEACTIVE_VENDORS_EXPORT_FILENAME}
              />
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_VENDOR_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(VendorList);
