import {
  Box,
  Card,
  CardBody,
  Flex,
  Heading,
  Image,
  Spacer,
  Tabs,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import Avatar from "@mui/material/Avatar";
import React, { useContext, useEffect, useState } from "react";
import Colors from "../../assets/colors/Colors";
import { PosErrorHook } from "../../hooks/index";
import { WithRouter } from "../../navigators/WithRouter";
import { viewProductData } from "../../pages/core/product/ProductService";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosLable,
  PosTab,
} from "../index";

const ProductView = (props) => {
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();

  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const tabNames = ["In-Store", "Online Ordering"];
  const [inStore, setInStore] = useState(true);

  const [oldImageBase64, setOldImageBase64] = useState("");
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");

  const [product, setProduct] = useState([]);
  const [categories, setCategories] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [modifierSets, setModifierSets] = useState([]);
  const [tareContainers, setTareContainers] = useState([]);
  const [additionalBarcodes, setAdditionalBarcodes] = useState([]);
  const [vendorscard, setVendorsCard] = useState([]);
  const [productSales, setProductSales] = useState([]);
  const [productRefund, setProductRefund] = useState([]);
  const [productView, setProductView] = useState(false);

  useEffect(() => {
    if (props.isViewOpen) {
      let ProdId = Number(props.id);
      // Call fetchData only when isOpen becomes true and effectRun is already true
      fetchData(ProdId);
      setInStore(true);
    }
    return () => {};
  }, [props.isViewOpen]);

  const fetchData = async (id) => {
    try {
      viewProductData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            // myContext.handleLoading(false);
            setProduct(response?.data?.data?.product);

            const catName = response?.data?.data?.product?.categories.map(
              (category) => category.name
            );
            setCategories(catName?.join(", "));

            const addiBarcode = response?.data?.data?.product_barcode.map(
              (barcode) => barcode.barcode
            );
            setAdditionalBarcodes(addiBarcode?.join(", "));

            const taxName = response?.data?.data?.selected_tax.map(
              (tax) => tax.name
            );
            setTaxes(taxName?.join(", "));

            const modifierSetName = response?.data?.data?.selected_modifierSet.map(
              (modifierSet) => modifierSet.name
            );
            setModifierSets(modifierSetName?.join(", "));

            const tareContainersName = response?.data?.data?.product?.product_tare_container.map(
              (tareContainers) => tareContainers.name
            );
            setTareContainers(tareContainersName?.join(", "));

            setVendorsCard(response.data?.data?.selected_vendors);

            setProductSales(response?.data?.data?.sales);
            setProductRefund(response?.data?.data?.refund);

            //   background_color: response?.data?.data?.product?.background_color,
            //   border_color: response?.data?.data?.product?.border_color,
            //   text_color: response?.data?.data?.product?.text_color,
            //   selectedVendors: response.data.data.selected_vendors,
            //   item_code: "",
            //   // field2: response.data.field2,
            //   // field3: response.data.field3,
            //   // // Set initial values for other fields
            //   // field20: response.data.field20,
            // });
            // const cat = response?.data?.data?.role;
            // const loc = response?.data?.data?.location_list;
            // const tax = response?.data?.data?.tax_list;
            // const modifierSet = response?.data?.data?.modifierSet_list;
            // const tareContainers = response?.data?.data?.tare_containers_list;
            // const categoriesData = cat.map((cat) => ({
            //   label: cat.name,
            //   value: { name: cat.name, id: cat.id },
            // }));
            // setCategories(categoriesData);

            // setAdditionalBarcodes(
            //   response?.data?.data?.product_barcode.map(
            //     (option) => option.barcode
            //   )
            // );

            // const locationsData = loc.map((loc) => ({
            //   label: loc.name,
            //   value: { name: loc.name, id: loc.id },
            // }));
            // setLocations(locationsData);

            // const taxesData = tax.map((tax) => ({
            //   label: tax.name + " : " + tax.percentage,
            //   value: { name: tax.name, percentage: tax.percentage, id: tax.id },
            // }));
            // setTaxes(taxesData);

            // const modifierSetData = modifierSet.map((modifierSet) => ({
            //   label: modifierSet.name,
            //   value: { name: modifierSet.name, id: modifierSet.id },
            // }));
            // setModifierSets(modifierSetData);

            // const tareContainerData = tareContainers.map((tareContainers) => ({
            //   label: tareContainers.name,
            //   value: { name: tareContainers.name, id: tareContainers.id },
            // }));
            // setTareContainers(tareContainerData);

            // if (
            //   null != response?.data?.data?.product?.background_color &&
            //   "" != response?.data?.data?.product?.background_color
            // ) {
            //   setBackgroundColor(
            //     response?.data?.data?.product?.background_color
            //   );
            // }
            // if (
            //   null != response?.data?.data?.product?.border_color &&
            //   "" != response?.data?.data?.product?.border_color
            // ) {
            //   setBorderColor(response?.data?.data?.product?.border_color);
            // }
            // if (
            //   null != response?.data?.data?.product?.text_color &&
            //   "" != response?.data?.data?.product?.text_color
            // ) {
            //   setTextColor(response?.data?.data?.product?.text_color);
            // }
            // if (
            //   null != response?.data?.data?.product?.image_base64 &&
            //   "" != response?.data?.data?.product?.image_base64
            // ) {
            //   var explodedImageName =
            //     response?.data?.data?.product?.image.split(".");
            //   var imageType = explodedImageName[explodedImageName.length - 1];
            //   var actualBase64 =
            //     "data:" +
            //     imageType +
            //     ";base64," +
            //     response?.data?.data?.product?.image_base64;
            //   setOldImageBase64(actualBase64);
            //   setBase64OfUploadedImg(actualBase64);
            // } else {
            //   setBase64OfUploadedImg("");
            //   setOldImageBase64("");
            //   setImageUploadedFlag(0);
            // }
            myContext.handleLoading(false);
            setProductView(true);
          } else {
            // let actionData = response?.data;
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
            setProductView(false);
          }
        })
        .catch((err) => {
          // let actionData = err?.response?.data;
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
          setProductView(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
      setProductView(false);
    }
  };

  const handleTabChange = (index) => {
    if (index == 0) {
      setInStore(true);
      //   setLoading(true);
      //   fetchData();
    } else {
      setInStore(false);
      //   setLoading(true);
      //   fetchDeActiveTaxData();
    }
  };

  const handleClose = () => {
    // formik.resetForm();
    setProductView(false);
    if (props.onViewClose) {
      props.onViewClose();
    }
    // setSelectedValue("");
    // setSelectedVendorList([]);
    // setVendorNext(false);
    // setSearchText("");
    // setBase64OfUploadedImg("");
    // setOldImageBase64("");
    // setImageUploadedFlag(0);
  };

  const formatProductName = (name) => {
    if (!name) return "Product Name";
    const firstLetter = name.charAt(0).toUpperCase();
    const secondLetter = name.charAt(1).toUpperCase();
    return firstLetter + secondLetter;
  };

  // const capitalizeWords = (str) => {
  //   return str.replace(/\b\w/g, char => char.toUpperCase());
  // };

  const capitalizeWords = (str) => {
    if (!str) {
      return ""; // Return an empty string or handle the error as needed
    }
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const formatedProductName = (name) => {
    const capitalized = capitalizeWords(name);
    return truncateText(capitalized, 30);
  };
  const formattingName = formatedProductName(product.name);
  const formattedName = formatProductName(product.name);
  return (
    <PosDrawer
      visible={productView}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
          pb={"0.62rem"}
        >
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={"flex-start"}
            pl={{ base: "1rem", lg: "1.88rem" }}
            pr={{ base: "1rem", md: "1.88rem" }}
            gap={2}
          >
            <PosBreadCrumb
              handleClick={(i) => {
                handleClose();
              }}
              breadCrumNames={["Products", "View Product"]}
              breadCrumTitle={"View Product"}
            />
            <Spacer />
            <Stack direction={"row"} alignSelf={"flex-end"}>
              <PosFormButton
                onClick={() => {
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
              />
            </Stack>
          </Flex> */}
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 5, md: 0 }}>
              {!props.hideBreadCrumb && (
                <PosBreadCrumb
                  handleClick={(i) => {
                    // props.navigate(Constants.PRODUCT_LIST_PATH);
                    handleClose();
                  }}
                  breadCrumNames={["Products", "View Product"]}
                  breadCrumTitle={"View Product"}
                />
              )}
            </Box>
            <Spacer />
            {/* <ButtonGroup gap="2" alignSelf={"flex-end"}>
              <PosFormButton
                onClick={() => {
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
              />
            </ButtonGroup> */}
          </Flex>
        </Box>
        <Flex
          gap={"0.63rem"}
          flexDirection={!isMobile ? "row" : "column"}
          mt={!isMobile ? "1rem" : "1rem"}
          ml={!isMobile ? "2rem" : "1rem"}
          mr={!isMobile ? "2rem" : "1rem"}
          mb={!isMobile ? "1rem" : "1rem"}
        >
          <Tabs onChange={(index) => handleTabChange(index)}>
            <PosTab tabNames={tabNames} />
          </Tabs>
        </Flex>
        <Flex
          gap={"0.63rem"}
          flexDirection={!isMobile ? "row" : "column"}
          mt={!isMobile ? "1rem" : "1rem"}
          ml={!isMobile ? "2rem" : "1rem"}
          mr={!isMobile ? "2rem" : "1rem"}
          mb={!isMobile ? "1rem" : "1rem"}
        >
          {inStore ? (
            <>
              <Box flex={1} flexShrink={"0"} borderRadius={"0.31rem"}>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Basic Details
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Primary Barcode (ISBN, UPC, GTIN)"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.barcode}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Product Name"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.name}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Product Category"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {categories}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Pricing
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Cost Per Case"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.cost ? product.cost : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Additional Cost"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.additional_cost
                          ? product.additional_cost
                          : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Sellable Unit in a Case"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.no_of_eaches ? product.no_of_eaches : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Sale Price"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.sale_price ? product.sale_price : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Reward Points"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.reward_points ? product.reward_points : "0"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Taxes"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {taxes ? taxes : "N.A."}
                      </Text>
                    </Box>
                  </VStack>
                </Box>

                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Customize Point of Sale Button
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Product Image"}
                        marginTop={"1rem"}
                      />
                      {product.image_url ? (
                        <Box
                          // h="150px"
                          // mt={{ base: "1.69rem", md: "1.69rem" }}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Image
                            // p="4"
                            src={product.image_url}
                            width={"225px"}
                            height={"193px"}
                          />
                        </Box>
                      ) : (
                        <Text
                          display="inline-flex"
                          alignItems="center"
                          color={Colors.posTextInfo}
                          fontWeight="300"
                        >
                          Image Not Uploaded.
                        </Text>
                      )}
                    </Box>

                    <PosLable
                      label={true}
                      name={"POS Button Preview"}
                      marginTop={"1rem"}
                    />
                    <Box
                      position="relative"
                      // h="14rem"
                      // w="16.37rem"
                      mt={{ base: "1.69rem", md: "1.69rem" }}
                    >
                      {!product.image_url ? (
                        <>
                          <Box
                            style={{ position: "relative" }}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            {/* Avatar view */}
                            <Box
                              // color={product.text_color}
                              style={{
                                width: "225px",
                                height: "180px",
                                backgroundColor: product.background_color, // Semi-transparent background

                                justifyContent: "center",
                                display: "flex",
                                alignSelf: "center",
                                alignContent: "center",
                                alignItems: "center",
                                borderRadius: "7px",
                              }}
                            >
                              <Text
                                style={{
                                  marginBottom: "30px",
                                  color: product.text_color,
                                  fontSize: "xl",

                                  textAlign: "center",
                                }}
                              >
                                {formattedName}
                              </Text>
                            </Box>

                            <Box
                              style={{
                                display: "flex",
                                position: "absolute",
                                bottom: 0,
                                backgroundColor: "#D9D9D9",
                                borderBottomLeftRadius: "7px",
                                borderBottomRightRadius: "7px",
                                alignItems: "center",
                                justifyContent: "center",
                                alignSelf: "center",
                                width: "225px",
                                height: "55px",
                              }}
                            >
                              <Text
                                fontSize={"sm"}
                                fontWeight={
                                  formattingName.length > 27 ? "thin" : "500"
                                }
                              >
                                {formattingName || "Product Name"}
                              </Text>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box
                          style={{ position: "relative" }}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <Image
                            style={{
                              width: "225px",
                              height: "180px",
                              borderTopLeftRadius: "7px",
                              borderTopRightRadius: "7px",
                              justifyContent: "center",

                              borderRadius: "7px",
                            }}
                            src={product.image_url}
                          />
                          <Box
                            style={{
                              display: "flex",
                              position: "absolute",
                              bottom: 0,
                              backgroundColor: "rgba(217, 217, 217, 0.85)",
                              borderBottomLeftRadius: "7px",
                              borderBottomRightRadius: "7px",
                              alignItems: "center",
                              justifyContent: "center",
                              alignSelf: "center",
                              width: "225px",
                              height: "55px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: "sm",
                                padding: "0.5rem",
                                // textAlign: "center",
                                color: "#010048",
                              }}
                              fontWeight={
                                formattingName.length > 27 ? "thin" : "500"
                              }
                            >
                              {formattingName || "Product Name"}
                            </Text>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </VStack>
                </Box>

                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Product Sales
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Last 7 Days"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {productSales?.seven_day
                          ? productSales.seven_day
                          : "0.00"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Last 30 Days"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {productSales?.thirty_day
                          ? productSales.thirty_day
                          : "0.00"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Life Time Sales"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {productSales?.life_time
                          ? productSales.life_time
                          : "0.00"}
                      </Text>
                    </Box>
                  </VStack>
                </Box>

                <Box
                  bg={Colors.posPageDataBackground}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Product Refunds
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Last 7 Days"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {productRefund?.seven_day
                          ? productRefund.seven_day
                          : "0.00"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Last 30 Days"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {productRefund?.thirty_day
                          ? productRefund.thirty_day
                          : "0.00"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Life Time Refunds"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {productRefund?.life_time
                          ? productRefund.life_time
                          : "0.00"}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              </Box>
              <Box flex={1} flexShrink={"0"} borderRadius={"0.31rem"}>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Inventory Management
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Location for this Product"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.location?.name
                          ? product?.location?.name
                          : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable label={true} name={"SKU"} marginTop={"1rem"} />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.sku ? product.sku : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"In Stock Qty"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.in_stock_qty ? product.in_stock_qty : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Threshold"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.threshold ? product.threshold : "N.A."}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Additional Attributes
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Additional Barcode"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {additionalBarcodes ? additionalBarcodes : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Modifier Sets"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {modifierSets ? modifierSets : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Age Verification"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.age_verification &&
                        product?.age_verification == 1
                          ? "Yes"
                          : "No"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Min Age Verification"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.min_age_verification
                          ? product?.min_age_verification
                          : "0"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Is Sold By Weight"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.sold_by_weight && product?.sold_by_weight == 1
                          ? "Yes"
                          : "No"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Tare Containers"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {tareContainers ? tareContainers : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Exclude EBT"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.exclude_ebt && product?.exclude_ebt == 1
                          ? "Yes"
                          : "No"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Is Prompt Quantity"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.prompt_qty && product?.prompt_qty == 1
                          ? "Yes"
                          : "No"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Is Variable Price"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.is_variable_price &&
                        product?.is_variable_price == 1
                          ? "Yes"
                          : "No"}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Exclude Kitchen Printing / KDS"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.is_exclude_kds && product?.is_exclude_kds == 1
                          ? "Yes"
                          : "No"}
                      </Text>
                    </Box>
                  </VStack>
                </Box>

                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Vendors
                      </Heading>
                    </Box>
                    <Box
                      style={{
                        maxHeight: "18.75rem",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                      }}
                    >
                      {vendorscard.length > 0 ? (
                        vendorscard.map((item, index) => {
                          return (
                            <Card
                              position="relative"
                              bg={"#F4F4F4"}
                              borderRadius={5}
                              margin={"0.5rem"}
                              key={index}
                            >
                              <Flex align="center">
                                <CardBody>
                                  <Avatar
                                    sx={{
                                      bgcolor: "rgba(88, 129, 254, 0.1)",
                                      color: "rgba(1, 0, 72, 0.80)",
                                      fontSize: "15px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.name.substring(0, 2).toUpperCase()}
                                  </Avatar>
                                  <Text pt={4} fontSize={"md"} fontWeight={500}>
                                    {item.name}{" "}
                                    <span
                                      style={{
                                        fontWeight: 300,
                                        color: "rgba(1, 0, 72, 0.60)",
                                      }}
                                    >
                                      {item.state ? item.state + "," : null}{" "}
                                      {item.country ? item.country : null}{" "}
                                    </span>
                                  </Text>
                                  {item?.email?.length > 0 ? (
                                    <Text
                                      color={"#5881FE"}
                                      fontSize={"xs"}
                                      fontWeight={300}
                                    >
                                      {item.email}
                                    </Text>
                                  ) : null}

                                  {item?.item_code?.length > 0 ? (
                                    <Text
                                      fontSize={"xs"}
                                      fontWeight={400}
                                      color={"rgba(1, 0, 72, 0.80)"}
                                      pt={4}
                                      style={{ wordWrap: "break-word" }}
                                    >
                                      Item code: {item.item_code}
                                    </Text>
                                  ) : null}
                                </CardBody>
                              </Flex>
                            </Card>
                          );
                        })
                      ) : (
                        <Box>
                          <PosLable label={true} name={""} marginTop={"1rem"} />
                          <Text
                            display="inline-flex"
                            alignItems="center"
                            color={Colors.posTextInfo}
                            fontWeight="300"
                          >
                            No Vendors Available
                          </Text>
                        </Box>
                      )}
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box flex={1} flexShrink={"0"} borderRadius={"0.31rem"}>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Online Order Product Attributes
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Online Ordering Price"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.online_ordering_price
                          ? product.online_ordering_price
                          : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Online Product Description"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product.description ? product.description : "N.A."}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Is Featured Product"}
                        marginTop={"1rem"}
                      />
                      <Text
                        display="inline-flex"
                        alignItems="center"
                        color={Colors.posTextInfo}
                        fontWeight="300"
                      >
                        {product?.online_is_featured_product &&
                        product?.online_is_featured_product == 1
                          ? "Yes"
                          : "No"}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              </Box>
              {/* <Box flex={1} flexShrink={"0"} borderRadius={"0.31rem"}>
                <Box
                    bg={Colors.posPageDataBackground}
                    mb={0.5}
                    boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                  >
                    <VStack
                      align={"stretch"}
                      pl={{ base: "1.69rem", md: "1.69rem" }}
                      pt={{ base: "2.31rem", md: "2.31rem" }}
                      pr={{ base: "1.69rem", md: "1.69rem" }}
                      pb={{ base: "4.38rem", md: "4.38rem" }}
                      gap={"0rem"}
                    >
                      <Box>
                        <Heading
                          as={"h3"}
                          color={Colors.moduleHeading}
                          fontSize={"1.13rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.02rem"}
                        >
                          Online Visibility
                        </Heading>
                      </Box>
                    </VStack>
                  </Box>
              </Box> */}
            </>
          )}
        </Flex>
      </Box>
    </PosDrawer>
  );
};

export default WithRouter(ProductView);
