import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getAllGiftCard(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GIFT_CARD_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GIFT_CARD_LIST_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllDeactiveGiftCard(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GIFT_CARD_DEACTIVE_CARD_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GIFT_CARD_REACTIVATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getGiftCardAuditTrailDetails(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GIFT_CARD_AUDIT_TRAILS_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GIFT_CARD_VIEWS,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GiftCardDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GIFT_CARD_DEACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.GIFT_CARD_DEACTIVATE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GIFT_CARD_REACTIVE_API_ROUTE +
            "/" +
            id,
          {},
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.GIFT_CARD_REACTIVATE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getSingleGiftCrads({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.GIFT_CARD_VIEWS + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GIFT_CARD_LIST_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getAllAddGiftCardBalance({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = JSON.stringify({
      id: Object.fromEntries(formData).id,
      amount: Object.fromEntries(formData).amount,
      external_reference: Object.fromEntries(formData).external_reference,
      reason: Object.fromEntries(formData).reason,
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GIFT_CARD_ADD_CREDIT_API_ROUTE +
          id,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GIFT_CARD_LIST_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}
