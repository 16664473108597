import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosFormButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  SingleOrderView,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  twofix,
  commonDateFormate
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { downloadPDF } from "./OnlineGiftCardSalesService";
import { SimpleReportLayout } from "../../../../../layouts/index";

const OnlineGiftCardSales = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const stateData = useLocation();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [locations, setLocations] = useState([]);
  const [OnlineGiftCardSales, setOnlineGiftCardSales] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [loading, setLoading] = useState(true);
  const pageRef = useRef(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "created_at",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const sortRef = useRef(sortModel);

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLoading(true);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getOnlineGiftCardSales();
    }
  }, [paginationModel, sortModel]);

  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1 };
  const columnNames = [
    { field: "id", headerName: "Sr No.", sortable: false, width: 90 },
    { field: "code", headerName: "Code", sortable: false, ...actionColumn },
    {
      field: "initial_amount",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Created At(UTC)",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "",
      headerName: "Order View",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => {
        const rowData = params.row;
        const handleOrderDetailsClick = () => {
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.order_id);
        };
        return (
          <IconButton
            style={{
              backgroundColor: "#F4F4F4",
              color: "#010048",
              transition: "background-color 0.3s, color 0.3s",
            }}
            onClick={handleOrderDetailsClick}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        );
      },
    },
  ];
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );

  function reportDateFormates(dateString) {
    if (!dateString) {
      return "";
    }
    const date = moment(dateString);
    const formattedDate = date.format("MM/DD/YYYY HH:mm:ss");
    return formattedDate;
  }

  const modifiedData = tableData.map((data) => ({
    id: data.sr_no,
    code: data.code,
    initial_amount: data.initial_amount,
    created_at: commonDateFormate(data.created_at),
    order_id: data.order_id,
  }));

  const handlePdfReport = () => {
    if (
      null != formik.values.fromDate &&
      "" != formik.values.fromDate){
      if(null != formik.values.toDate &&
      "" != formik.values.toDate
    ) {
    myContext.handleLoading(true);
    let data = {
      getreport: true,
      //   location: JSON.stringify(formik.values.selectedLoactions),
      dateFrom: reportDateFormate(formik.values.fromDate),
      dateTo: reportDateFormate(formik.values.toDate),
      formatFromDate: reportDateFormate(formik.values.fromDate),
      formatToDate: reportDateFormate(formik.values.toDate),
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      }),
    };
    try {
      downloadPDF(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var url = Constants.REACT_APP_API_URL + response.data.data;
            // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  } else {
    clearTableData(Constants.TO_DATE_IS_REQUIRED);
  }
} else {
  clearTableData(Constants.FORM_DATE_IS_REQUIRED);
}
  };

  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);

  const formik = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      formatFromDate: "",
      formatToDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Online Gift Card Sales Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "OnlineGiftCardSales.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.onlineGiftCardSalesDetails);
          setTotalCount(actionResponse.data.data.totalItems);
          let data = [
            "Total Balance($)",
            twofix(actionResponse.data.data.totalGiftCardAmount),
          ];
          setOnlineGiftCardSales(data);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  const handleExportReport = () => {
    if (
      null != formik.values.fromDate &&
      "" != formik.values.fromDate){
      if(null != formik.values.toDate &&
      "" != formik.values.toDate
    ) {
    myContext.handleLoading(true);
    let data = {
      exportReport: true,
      dateFrom: reportDateFormate(formik.values.fromDate),
      dateTo: reportDateFormate(formik.values.toDate),
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      }),
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.ONLINE_GIFT_CARD_SALE,
    });
  } else {
    clearTableData(Constants.TO_DATE_IS_REQUIRED);
  }
} else {
  clearTableData(Constants.FORM_DATE_IS_REQUIRED);
}
  };
  const clearTableData = (message) => {  // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getOnlineGiftCardSales = () => {
    if (
      null != formik.values.fromDate &&
      "" != formik.values.fromDate){
      if(null != formik.values.toDate &&
      "" != formik.values.toDate
    ) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      setApplyClicked(true);
      let data = {
        getreport: true,
        dateFrom: reportDateFormate(formik.values.fromDate),
        dateTo: reportDateFormate(formik.values.toDate),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.ONLINE_GIFT_CARD_SALE,
      });
    } else {
      clearTableData(Constants.TO_DATE_IS_REQUIRED);
    }
  } else {
    clearTableData(Constants.FORM_DATE_IS_REQUIRED);
  }
  };

  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "created_at",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
    setApplyClicked(false);
  };

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderId("");
  };
  const renderReportLayout = () => {
    return (
      <>
        {/* <Box className="card flex justify-content-center">
          <PosDropDown
            options={locations}
            resetFilterOnHide={true}
            value={formik.values.selectedLoactions}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box> */}
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
          <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date(UTC)*"}
                readOnlyInput={true}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
          </Box>
          <Box width={"100% !important"}>
          <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date(UTC)*"}
                readOnlyInput={true}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
          </Box>
        </Stack>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderId}
        breadCrumNames={[]}
        handleClick={(i) => {
          // if (i == 0) {
          //   props.navigate(Constants.REPORT_PATH);
          // }
        }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Online Gift Card Sales"]}
          breadCrumTitle={"Online Gift Card Sales"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Export
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleExportReport}>Export Excel</MenuItem>
                <MenuItem onClick={handlePdfReport}>Export PDF</MenuItem>
              </MenuList>
            </Menu>
          )}
          {/* <PosIconButton
                name={Constants.EXPORT_BUTTON}
                onClick={handleExportReport}
                exportIcon={true}
                width={"7.5rem"}
              /> */}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
<Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
           <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
           <Stack
             direction={"row"}
             justifyContent={isMeduim ? "flex-start" : "flex-end"}
             alignItems={"flex-start"}
             pl={isMeduim ? 4 : 0}
             pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              isDisabled={applyClicked ? false : true}
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getOnlineGiftCardSales}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                // noAction={true}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                // onPaginationModelChange={setPaginationModel}
                // sortModel={sortModel}
                // onSortModelChange={(newSortModel) => {
                //   sortRef.current = sortModel;
                //   setSortModel(newSortModel);
                // }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.ONLINE_GIFT_CARD_REPORT_CSV}
              />
              <Table>
                <TableHead sx={{ backgroundColor: Colors.modifierTable }}>
                  <TableRow>
                    {OnlineGiftCardSales.map((column, index) => {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-1.5%",
                            color: Colors.posTextInfo,
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {column}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
            </Flex>
          ) : (
            <PosNoDataFound
              title={Constants.ONLINE_GIFT_CARD_NO_DATA}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(OnlineGiftCardSales);
