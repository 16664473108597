import { Textarea } from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";

const PosTextArea = (props) => {
  return (
    <Textarea
      id={props.id}
      variant={"posTextArea"}
      placeholder={props.placeholder}
      borderRadius={"0.63rem"}
      border={Colors.posTextAreaBorder}
      background={Colors.posInputBackground}
      onChange={props.onChange}
      value={props.value}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      inputerror={props.inputerror}
    />
  );
};

export default PosTextArea;
