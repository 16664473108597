import {
  Box,
  Divider,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,ButtonGroup,
  useMediaQuery
} from "@chakra-ui/react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import React, {
  useCallback, useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useActionData, useLoaderData, useParams,useLocation } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosFormButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTable,
  SingleOrderView
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getOrderData } from "./RewardCardService";

const RewardCardView = (props) => {
  const { error } = PosErrorHook();
  const [userData, setUserData] = useState([]);
  const loaderResponse = useLoaderData();
  const [rewardsData, setRewardsData] = useState([]);
  const [isBoxVisible, setIsBoxVisible] = useState(true);
  const [isSecondTabVisible, setIsSecondTabVisible] = useState(false);
  const stateData = useLocation();
  const [orderId, setOrderId] = useState(""); // set order display id
  const effectRun = useRef(true);
  const actionResponse = useActionData();
  const myContext = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [totalordercount, setOrderCount] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const [searchQuery, setSearchQuery] = useState();
  const [curruntTab, setCurruntTab] = useState(0);
  // const tabNames = ["REWARD CARD DETAILS", "ORDER DETAILS"];
  const tabNames = isSecondTabVisible
    ? ["REWARD CUSTOMER DETAILS", "ORDER DETAILS"]
    : ["REWARD CUSTOMER DETAILS"];
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();  // order display open and close
  const sortRef = useRef(sortModel);
  const handleSearchList = () => {
    if (curruntTab == 1) {
      fetchOrderData();
    }
  };
  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      setSearchQuery("");
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      effectRun.current = true;
      setLoading(true);
    } else {
      setSearchQuery("");
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      // fetchOrderData();
      effectRun.current = true;
      setLoading(true);
    }
  };
  useEffect(() => {
    if (curruntTab == 1) {
      fetchOrderData();
    }
  }, [paginationModel, sortModel]);
  const fetchOrderData = async () => {
    let data = {
      id: id,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter2: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getOrderData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setOrderData(response.data.data.data);
            setOrderCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setOrderData([]);
            setOrderCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setOrderData([]);
          setOrderCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setOrderData([]);
      setOrderCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchOrderDataAfterSearchClear = async () => {
    let data = {
      id: id,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter2: "",
    };
    setLoading(true);
    setShowTable(false);
    try {
      getOrderData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setOrderData(response.data.data.data);
            setOrderCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setOrderData([]);
            setOrderCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setOrderData([]);
          setOrderCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setOrderData([]);
      setOrderCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  useEffect(() => {
    if (
      undefined != loaderResponse &&
      null != loaderResponse &&
      {} != loaderResponse
    ) {
      if (
        undefined != loaderResponse?.error &&
        null != loaderResponse?.error &&
        loaderResponse?.error
      ) {
        let actionData = loaderResponse;
        error({ actionData });
      } else if (
        null !== loaderResponse.data[Constants.FLAGE] &&
        true === loaderResponse.data[Constants.FLAGE]
      ) {
        setRewardsData(loaderResponse.data.data.allRewardsData);
        setUserData(loaderResponse.data.data);
        const data = loaderResponse.data.data.allRewardsData;
        if (data && data.length > 0) {
          setRewardsData(data);
          setIsBoxVisible(true);
          setIsSecondTabVisible(true);
        } else {
          setIsBoxVisible(false);
          setIsSecondTabVisible(false);
        }
        myContext.handleLoading(false);
      } else {
        let actionData = loaderResponse;
        error({ actionData });
      }
    }
  }, [loaderResponse]);
  const columnNames = [
    {
      columnNames: "Reward Point Calculation Type",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Reward Name	",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Required Reward Points	",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Current Reward Points	",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Eligible",
      sortName: "",
      width: "16.66%",
    },
  ];

  const rewardRawData = rewardsData.map((rewards) => ({
    reward_point_calculation_type: rewards.reward_point_calculation_type,
    name: rewards.name,
    required_points: rewards.required_points,
    current_points: rewards.current_points,
    eligible: rewards.eligible,
  }));
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const orderColumnNames = [
    { field: "id", headerName: "Order Id ", sortable: true, width:120 },
    {
      field: "local_id",
      headerName: "Local Id/Order No",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "balance",
      headerName: "Total",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "paid",
      headerName: "Payment Status",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Order Date",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "view_order",
      headerName: "View Order",
      sortable: false,
      disableExport: true,
      resizable: false,
      filterable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleView = () => {  // click on order view button
          myContext.handleLoading(true);
          handleDisplayOrderData(rowData.id);
          // handleViewPage(rowData.id);
        };
        return (
          <IconButton
            aria-label="view"
            onClick={handleView}
            sx={{ color: Colors.posNavbarLink }}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
  ];
  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );  // order id set then open drawer
  const modifiedOrderData = orderData.map((data) => ({
    id: data.id,
    local_id: data.local_id,
    balance: data.balance,
    paid: data.paid === 1 ? "Paid" : "Unpaid",
    created_at: moment(data.created_at).format("MM/DD/YYYY HH:mm:ss"),
  }));
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    fetchOrderDataAfterSearchClear();
  };
  const onOrderDrawerClose = () => {
   setOrderId("")
   onDrawerClose()
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderId}
        breadCrumNames={[]}
        handleClick={(i) => {
          // if (i == 0) {
          //   props.navigate(Constants.REPORT_PATH);
          // }
        }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.REWARD_CARDS_LIST, {
                state: stateData.state,
              });
            }}
          breadCrumNames={["Reward Customers ", "View Reward Customer Details"]}
          breadCrumTitle={"View Reward Customer Details"}
        />
         <ButtonGroup gap="2" mt={2} alignSelf={"flex-end"}>
        <PosFormButton
         onClick={() => {
          props.navigate(Constants.REWARD_CARDS_LIST, {
            state: stateData.state,
          });
        }}
          buttonText={"Cancel"}
          
          CancelButton={true}
        />
        </ButtonGroup>
      </Flex>
      <Tabs onChange={(index) => handleTabChange(index)}>
        {/* <TabList>
              <Tab>REWARD CARD DETAILS</Tab>
              <Tab>ORDER DETAILS</Tab>
            </TabList> */}
        <PosTab tabNames={tabNames} />
        <TabPanels >
          <TabPanel>
            <Box
              // mt={{ base: 4, md: 2 }}
              // ml={{ base: 4, lg: 0 }}
              // mr={{ base: 4, lg: 0 }}
              mb={{ base: 4, md: 4 }}
              borderRadius="0.63rem"
              bg={Colors.posPageDataBackground}
              boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
              p="4"
            >
              <PosListHeader
                header={"Reward Customer Details"}
                discription={
                  "Your personal details for your OctoPos Reward Customer"
                }
              ></PosListHeader>
              <Flex
                mt={8}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <DriveFileRenameOutlineRoundedIcon
                  style={{ fill: Colors.primeTaxInfo }}
                />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  ID
                </Text>
                <Spacer />
                <Text color={Colors.posTextInfo}>{userData.id}</Text>
              </Flex>
              <Flex
                mt={1.5}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <DriveFileRenameOutlineRoundedIcon
                  style={{ fill: Colors.primeTaxInfo }}
                />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  First Name
                </Text>
                <Spacer />
                <Text color={Colors.posTextInfo} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                  {userData.first_name !== null && userData.first_name !== ""
                    ? userData.first_name
                    : "N.A."}
                </Text>
              </Flex>
              <Flex
                mt={1.5}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <DriveFileRenameOutlineRoundedIcon
                  style={{ fill: Colors.primeTaxInfo }}
                />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  Last Name
                </Text>
                <Spacer />
                <Text color={Colors.posTextInfo} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                  {userData.last_name !== null && userData.last_name !== ""
                    ? userData.last_name
                    : "N.A."}
                </Text>
              </Flex>
              <Flex
                mt={1.5}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <EmailIcon style={{ fill: Colors.primeTaxInfo }} />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  Email Address
                </Text>
                <Spacer />
                <Text color={Colors.posTextInfo} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                  {userData.email !== null && userData.email !== ""
                    ? userData.email
                    : "N.A."}
                </Text>
              </Flex>
              <Flex
                mt={1.5}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <LocalPhoneIcon style={{ fill: Colors.primeTaxInfo }} />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  Phone Number
                </Text>
                <Spacer />
                <Text color={Colors.posTextInfo} >{userData.phone}</Text>
              </Flex>
              <Flex
                mt={1.5}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <EmailIcon style={{ fill: Colors.primeTaxInfo }} />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  Email Subscription
                </Text>
                <Spacer />
                <Text color={Colors.posTextInfo}>
                  {userData.is_unsubscribe === 0 ? "No" : "Yes"}
                </Text>
              </Flex>
              <Flex
                mt={1.5}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <LocalPhoneIcon style={{ fill: Colors.primeTaxInfo }} />
                <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                  SMS Subscription
                </Text>
                <Spacer />
                <Text color={Colors.posTextInfo}>
                  {userData.is_sms_unsubscribe === 0 ? "No" : "Yes"}
                </Text>
              </Flex>
              <Flex
                mt={1.5}
                p={2.5}
                borderRadius={"0.63rem"}
                h={{md:24}}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                <Text color={Colors.posViewTextColor} fontWeight={300}>
                  Notes
                </Text>
                <Spacer />
                <Text ml={4} color={Colors.posTextInfo} style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                  {userData.notes !== null && userData.notes !== ""
                    ? userData.notes
                    : "N.A."}
                </Text>
              </Flex>
            </Box>
            {isBoxVisible && (
              <Box
              maxHeight={"31.25rem"}
              overflow={"auto"}
                mt={{ base: 4, md: 4 }}
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                p="8"
              >
                <PosListHeader
                  mb={2}
                  header={"Rewards Details"}
                ></PosListHeader>
                <PosTable
                  columnNames={columnNames}
                  rawdata={rewardRawData}
                  footerHide={true}
                  noAction={true}
                />
              </Box>
            )}
          </TabPanel>
          {isSecondTabVisible && (
            <TabPanel>
              <Box
                mt={{ base: 4, md: 4 }}
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                p="8"
                // pt={8}
                // pr={8}
              >
                <PosSearch
                  value={searchQuery || ""}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onSearch={handleSearchList}
                  handleClear={handleClear}
                />
                <Divider
                  mt={4}
                  mb={4}
                  h={"0.05rem"}
                  borderColor={Colors.listShadow}
                />
                {showTable ? null : loading ? (
                  <PosProgress />
                ) : totalordercount > 0 ? (
                  <PosDataGridTable
                    columns={orderColumnNames}
                    rows={modifiedOrderData}
                    totalcount={totalordercount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={setPaginationModel}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.RC_ORDERS_EXPORT_FILENAME}
                  />
                ) : (
                  <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                )}
              </Box>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
};
export default WithRouter(RewardCardView);
