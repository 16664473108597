import { Chips } from "primereact/chips";
import React from "react";
const PosChipsInput = (props) => {
  return (
    <Chips
      addOnTab={true}
      onAdd={props.onAdd}
      onRemove={props.onRemove}
      addOnBlur={true}
      allowDuplicate={false}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
};

export default PosChipsInput;
