import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDialogAlert,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllDeactiveTax, getAllTaxes } from "./TaxService";

const TaxList = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const { addToast } = PosTostMessage();
  const stateData = useLocation(); // get backraking data as param
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isMobile] = useMediaQuery("(max-width:768px)");
  // const [isColumnChange] = useMediaQuery("(max-width: 1253px)");
  const [taxData, setTaxData] = useState([]);
  const [taxDeactiveData, setTaxDeactiveData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );

  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.columnModel
      : {}
  );
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "percentage",
      headerName: "Rate",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "exempt_ebt",
      headerName: "Exempt EBT",
      sortable: true,
      ...actionColumn,
      renderCell: (params) => (
        <span
          style={{
            padding: "0.150rem 0.625rem",
            borderRadius: "0.63rem",
            backgroundColor: params.value === "No" ? "#FCE6E5" : "#E4FEE2",
            color: params.value === "No" ? "#CD1919" : "#0B8C20B5",
            fontWeight: "300",
            justifyContent: "center",
            alignItems: "center",
            width: "2.5rem",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "default_tax",
      headerName: "Default on Product Creation",
      sortable: true,
      ...actionColumn,
      renderCell: (params) => (
        <span
          style={{
            padding: "0.150rem 0.625rem",
            borderRadius: "0.63rem",
            backgroundColor: params.value === "No" ? "#FCE6E5" : "#E4FEE2",
            color: params.value === "No" ? "#CD1919" : "#0B8C20B5",
            fontWeight: "300",
            justifyContent: "center",
            alignItems: "center",
            width: "2.5rem",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 125,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
            columnModel: olColumnVisibilityModel,
          };
          myContext.handleLoading(true);
          // pass backtraking relate all data
          props.navigate(`${Constants.TAX_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleModelOpen = (item) => {
          onOpen();
          setDeactiveItem(item);
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <EditIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton: buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleModelOpen(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
      pinned: true,
    },
  ];
  const columnNamesDeactive = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "percentage",
      headerName: "Rate",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "exempt_ebt",
      headerName: "Exempt EBT",
      sortable: true,
      ...actionColumn,
      renderCell: (params) => (
        <span
          style={{
            padding: "0.150rem 0.625rem",
            borderRadius: "0.63rem",
            backgroundColor: params.value === "No" ? "#FCE6E5" : "#E4FEE2",
            color: params.value === "No" ? "#CD1919" : "#0B8C20B5",
            fontWeight: "300",
            justifyContent: "center",
            alignItems: "center",
            width: "2.5rem",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "default_tax",
      headerName: "Default on Product Creation",
      sortable: true,
      ...actionColumn,
      renderCell: (params) => (
        <span
          style={{
            padding: "0.150rem 0.625rem",
            borderRadius: "0.63rem",
            backgroundColor: params.value === "No" ? "#FCE6E5" : "#E4FEE2",
            color: params.value === "No" ? "#CD1919" : "#0B8C20B5",
            fontWeight: "300",
            justifyContent: "center",
            alignItems: "center",
            width: "2.5rem",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisable(true);
          if (
            deactiveTaxData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          setDeactiveItem(rowData);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.TAX_LIST,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? Colors.posNavbarLink : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [deactiveItem, setDeactiveItem] = useState({});
  const [page, setPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.limit
      : 25
  );

  const [paginationModel, setPaginationModel] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });

  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });

  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);
  const effectRun = useRef(true);

  const handleDeactive = (item) => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    let data = {
      id: deactiveItem.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.TAX_LIST,
    });
    onClose();
  };

  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getAllTaxes(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTaxData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setTaxData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setTaxData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setTaxData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveTaxData = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getAllDeactiveTax(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setTaxDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setTaxDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setTaxDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setTaxDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveTaxData();
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [
    searchQuery,
    paginationModel,
    sortModel,
    loading,
    curruntTab,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        // props.navigate(Constants.TAX_LIST);
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          if (modifiedData.length === 1 && paginationModel?.page !== 0) {
            effectRun.current = true;
            setPaginationModel((prevPaginationMod) => {
              return {
                page: prevPaginationMod?.page - 1,
                pageSize: prevPaginationMod?.pageSize,
              };
            });
          } else {
            fetchData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.TAX_MODULE,
            alertDescription:
              deactiveItem.name + " " + Constants.TAX_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          if (deactiveTaxData.length === 1 && deactiveTaxData?.page !== 0) {
            effectRun.current = true;
            setPaginationModel((prevPaginationMod) => {
              return {
                page: prevPaginationMod?.page - 1,
                pageSize: prevPaginationMod?.pageSize,
              };
            });
          } else {
            fetchDeActiveTaxData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.TAX_MODULE,
            alertDescription:
              deactiveItem.name + " " + Constants.TAX_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      // }
    }
  }, [actionResponse]);
  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      effectRun.current = true;
      setLoading(true);
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
    } else {
      searchInputHideRef.current = false;
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      setSearchQuery("");
      effectRun.current = true;
      setLoading(true);
    }
  };
  const handleSearchList = () => {
    // search time get active and deactive list
    paginationModel.page = 0;
    props.navigate(Constants.TAX_LIST);
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveTaxData();
    }
    // setLoading(true);
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.TAX_LIST); // refresh page time param data clear
  };
  const modifiedData = taxData.map((tax) => ({
    ...tax,
    exempt_ebt: tax.exempt_ebt == 0 ? "No" : "Yes",
    default_tax: tax.default_tax == 0 ? "No" : "Yes",
    type:
      tax.type == "per"
        ? Constants.TAX_TYPE_PERCENTAGE
        : Constants.TAX_TYPE_AMOUNT,
  }));

  const deactiveTaxData = taxDeactiveData.map((tax) => ({
    ...tax,
    exempt_ebt: tax.exempt_ebt == 0 ? "No" : "Yes",
    default_tax: tax.default_tax == 0 ? "No" : "Yes",
    type:
      tax.type == "per"
        ? Constants.TAX_TYPE_PERCENTAGE
        : Constants.TAX_TYPE_AMOUNT,
  }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        title={Constants.DEACTIVE_TITLE}
        discription={Constants.DEACTIVE_DISCRIPTION}
        onClick={handleDeactive}
        buttonNames={["Cancel", "O   k"]}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={Constants.PRODUCT_TAX_TITLE}
          discription={Constants.TAX_DISCRIPTION}
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true); // loading start
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: searchQuery,
                columnModel: olColumnVisibilityModel,
              };
              // pass all backtraking ralated all in create tax route
              props.navigate(Constants.TAX_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels p={0}>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? ( // MUI X: useResizeContainer - error solve
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode={Constants.SORTING_AND_PAGINATION_MODE}
                    sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.ACTIVE_TAX_EXPORT_FILENAME}
                    // whiteSpace={isColumnChange ? "nowrap" : "normal"}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.TAX_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? ( // MUI X: useResizeContainer - error solve
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNamesDeactive}
                    rows={deactiveTaxData}
                    totalcount={deactiveTotalCount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModelDeactive}
                    paginationMode={Constants.SORTING_AND_PAGINATION_MODE}
                    sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                    onPaginationModelChange={(newPaginationModelDeactive) => {
                      effectRun.current = true;
                      setPaginationModelDeactive(newPaginationModelDeactive);
                    }}
                    sortModel={sortModelDeactive}
                    onSortModelChange={(newSortModelDeactive) => {
                      effectRun.current = true;
                      sortRef.current = sortModelDeactive;
                      setSortModelDeactive(newSortModelDeactive);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.DEACTIVE_TAX_EXPORT_FILENAME}
                    // whiteSpace={isColumnChange ? "nowrap" : "normal"}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_TAX_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(TaxList);
