import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosDialogAlert,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllDeactiveSegment, getAllSegments } from "./SegmentService";

const SegmentList = (props) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeactiveConfirmOpen,
    onOpen: onDeactiveConfirmOpen,
    onClose: onDeactiveConfirmClose,
  } = useDisclosure();
  const effectRun = useRef(true);
  const cancelRef = React.useRef();
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [segmentData, setSegmentData] = useState([]);
  const [segmentDeactiveData, setSegmentDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const stateData = useLocation(); // get backraking data as param
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  // backtracking
  const [paginationModel, setPaginationModel] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  // backtracking
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);

  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);

  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [deactiveItem, setDeactiveItem] = useState({});

  const handleDeactive = (item) => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    let data = {
      id: deactiveItem.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.SEGMENT_LIST,
    });
    onDeactiveConfirmClose();
  };

  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.SEGMENT_LIST); // refresh page time param data clear
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    paginationModel.page = 0;
    props.navigate(Constants.SEGMENT_LIST);
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveSegmentData();
    }
    // setLoading(true);
  };

  const fetchData = async () => {
    setLoading(true);
    // backtracking
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllSegments(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setSegmentData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setSegmentData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setSegmentData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setSegmentData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveSegmentData = async () => {
    setLoading(true);
    // backtracking
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveSegment(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setSegmentDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setSegmentDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setSegmentDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setSegmentDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          // backtracking
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          props.navigate(`${Constants.SEGMENT_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleViewPage = (item) => {
          // backtracking
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.SEGMENT_VIEW + item.id}`, {
            state: data,
          });
        };
        const handleModelOpen = (item) => {
          onDeactiveConfirmOpen();
          setDeactiveItem(item);
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            // {
            //   name: "View",
            //   icon: (
            //     <RemoveRedEyeOutlinedIcon
            //       style={{ color: Colors.posViewTextColor }}
            //     />
            //   ),
            //   onClick: (item) => handleViewPage(item),
            // },
            {
              name: "Deactive",
              disabledButton: buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleModelOpen(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];

  const deactiveColumnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      width: 150,
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          // backtracking
          setButtonDisable(true);
          if (
            modifiedDeactiveSegmentData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.SEGMENT_LIST,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            // textDecoration="underline"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveSegmentData();
      }
      effectRun.current = false;
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      effectRun.current = true;
      setLoading(true);
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "id",
          sort: "desc",
        },
      ]);
    } else {
      searchInputHideRef.current = false;
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      setSearchQuery("");
      effectRun.current = true;
      setLoading(true);
    }
  };

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          // fetchData();
          if(modifiedSegmentData?.length === 1 && paginationModel?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.SEGMENT_MODULE,
            alertDescription: Constants.SEGMENT_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          // fetchDeActiveSegmentData();
          if(modifiedDeactiveSegmentData?.length === 1 && modifiedDeactiveSegmentData?.page !== 0){
            effectRun.current = true
              setPaginationModel((prevPaginationMod) => {
                return {
                  page:prevPaginationMod?.page - 1,
                  pageSize:prevPaginationMod?.pageSize,
                }
              })
          } else {
            fetchDeActiveSegmentData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.SEGMENT_MODULE,
            alertDescription: Constants.SEGMENT_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      // }
    }
  }, [actionResponse]);

  const modifiedSegmentData = segmentData?.map((segment) => ({
    ...segment,
    type: segment.type == "GROUP" ? "Select Smart Group" : "Select Manual",
    location: segment.location?.name,
  }));

  const modifiedDeactiveSegmentData = segmentDeactiveData?.map((segment) => ({
    ...segment,
    type: segment.type == "GROUP" ? "Select Smart Group" : "Select Manual",
    location: segment.location?.name,
  }));

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        isOpen={isDeactiveConfirmOpen}
        onClose={onDeactiveConfirmClose}
        title={"Deactivate Segment"}
        discription={
          "Are you sure you want to deactivate “" + deactiveItem.name + "”?"
        }
        onClick={handleDeactive}
        buttonNames={["Cancel", "Deactivate"]}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Segments"}
          discription={"Manage segment for your products"}
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              // backtracking
              myContext.handleLoading(true);  // loading start
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: curruntTab == 1 ? "" : searchQuery,
              };
              // pass all backtraking ralated all in create tax route
              props.navigate(Constants.SEGMENT_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery?.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery?.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <TabPanels p={0}>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedSegmentData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.ACTIVE_SEGMENT_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.SEGMENT_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={deactiveColumnNames}
                    rows={modifiedDeactiveSegmentData}
                    totalcount={deactiveTotalCount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModelDeactive}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModelDeactive) => {
                      effectRun.current = true;
                      setPaginationModelDeactive(newPaginationModelDeactive);
                    }}
                    sortModel={sortModelDeactive}
                    onSortModelChange={(newSortModelDeactive) => {
                      sortRefDeactive.current = sortModelDeactive;
                      effectRun.current = true;
                      setSortModelDeactive(newSortModelDeactive);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.DEACTIVE_SEGMENT_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_SEGMENT_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(SegmentList);
