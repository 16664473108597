import { Box, Flex, Stack } from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import React from "react";

const PosCountryStateDropDown = (props) => {
  if (props.countriesSelect) {
    return (
      <Flex gap="9">
        <Box flex="1">
          <Stack spacing={3}>
            <Box className="card flex justify-content-center">
              <Dropdown
                value={props.value}
                onChange={(e) => props.onChange(e)}
                options={props.options}
                optionLabel={props.optionLabel}
                placeholder={props.placeholder}
                style={{
                  width: props.width,
                  maxWidth: props.maxWidth,
                  backgroundColor: "#5881fe0d",
                  borderRadius: "0.625rem",
                  fontSize: "0.94rem",
                  fontWeight: 300,
                  color: "#010048",
                  height: props.height,
                  border: "1px solid #b7b7b71f",
                }}
                panelStyle={{
                  backgroundColor: "#F4F4F4",
                  width: "20.625rem",
                  borderRadius: "0.625rem",
                  border: "1px solid #b7b7b71f",
                }}
                filterInputAutoFocus={false}
                filterPlaceholder={"Search"}
                onBlur={props.onBlur}
                className={props.className}
                disabled={props.disabled}
                resetFilterOnHide={true}
                filter
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
    );
  } else {
    return (
      <Flex gap="9">
        <Box flex="1">
          <Stack spacing={3}>
            <Box className="card flex justify-content-center">
              <Dropdown
                value={props.value}
                onChange={(e) => props.onChange(e)}
                options={props.options}
                optionLabel={props.optionLabel}
                placeholder={props.placeholder}
                style={{
                  width: props.width,
                  maxWidth: props.maxWidth,
                  backgroundColor: "#5881fe0d",
                  borderRadius: "0.625rem",
                  fontSize: "0.94rem",
                  fontWeight: 300,
                  color: "#010048",
                  height: props.height,
                  border: "1px solid #b7b7b71f",
                }}
                panelStyle={{
                  backgroundColor: "#F4F4F4",
                  width: "20.625rem",
                  borderRadius: "0.625rem",
                  border: "1px solid #b7b7b71f",
                }}
                filterInputAutoFocus={false}
                filterPlaceholder={"Search"}
                onBlur={props.onBlur}
                className={props.className}
                disabled={props.disabled}
                resetFilterOnHide={true}
                filter
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
    );
  }
};

export default PosCountryStateDropDown;
