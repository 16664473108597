import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { GridToolbar } from "@mui/x-data-grid";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { saveAs } from "file-saver";
import PdfIcon from "../../../assets/images/coupon_pdf.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLocation, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import { toPng } from "html-to-image";
import RectangleImage from "../../../assets/images/Rectangle 5590.svg";
import giftImage from "../../../assets/images/public_gift.png";
import AmountIcon from "../../../assets/images/coupon_amount.svg";
import PercentageIcon from "../../../assets/images/icon_percentage.svg";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  AppContext,
  PosDataGridTable,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  downloadCouponBarcodeImage,
  downloadPDF,
  getAllCoupons,
  getDeactiveCoupons,
} from "./CouponService";
import QRCode from "qrcode.react";

// import { CouponImage } from "./CouponImage"
import CouponImageDownload from "./CouponImage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CouponImage from "./CouponImage";

const CouponList = (props) => {
  const stateData = useLocation();
  const [showTable, setShowTable] = useState(true);
  const pageRef = useRef();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const effectRun = useRef(true);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [curruntTab, setCurruntTab] = useState(0);
  const tabNames = ["Active", "Inactive"];
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isColumnChange] = useMediaQuery("(max-width: 1780px)");
  const [isColumnChanges] = useMediaQuery("(max-width: 1532px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [couponData, setCouponData] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [deactiveCouponData, setDeactiveCouponData] = useState([]);
  const [totalDeactivatecount, setDeactiveTotalCount] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponDataForImage, setCouponDataForImage] = useState({});
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );

  const [loading, setLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page - 1
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const sortRefDeactive = useRef(sortModelDeactive);
  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
    } else {
      searchInputHideRef.current = false;
    }
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setPaginationModelDeactive({
      page: 0,
      pageSize: 25,
    });
    setSortModelDeactive([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setSearchQuery("");
    effectRun.current = true;
    setLoading(true);
  };

  const handleSearchList = () => {
    paginationModel.page = 0;
    props.navigate(Constants.COUPON_LIST);
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveCouponData();
    }
  };
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.COUPON_LIST);
  };
  const fetchData = async () => {
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    setShowTable(false);
    setLoading(true);
    try {
      getAllCoupons(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCouponData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCouponData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setCouponData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCouponData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveCouponData = async () => {
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    setLoading(true);
    setShowTable(false);
    try {
      getDeactiveCoupons(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setDeactiveCouponData(response.data.data.data);
            setDeactiveTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setDeactiveCouponData([]);
            setDeactiveTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setDeactiveCouponData([]);
          setDeactiveTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setDeactiveCouponData([]);
      setDeactiveTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveCouponData();
      }
      effectRun.current = false;
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
  ]);
  // useEffect(() => {

  //   if (curruntTab === 0) {
  //     fetchData();
  //   } else {
  //     fetchDeActiveCouponData();
  //   }
  // }, [paginationModel, sortModel]);
  const handleImageGenerated = () => {
    setCouponDataForImage({}); // Clear the coupon data after image is generated
  };
  
  const columnNames = [
    {
      field: "id",
      headerName: "Id",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "title",
      headerName: "Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "coupon_type",
      headerName: "Coupon Type",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "audiance_type",
      headerName: "Audience Type",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "discount_type",
      headerName: "Discount Type",
      sortable: true,
      ...actionColumnMD,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Box display="flex"
          alignItems="center"
          style={{ paddingTop: "0.95rem", paddingBottom: "0.65rem" }}>
            {rowData.discount_type === "Percentage" ? (
              <Image
              src={PercentageIcon}
              />
            ) : (
              <MonetizationOnIcon style={{ fill: Colors.primeTaxInfo }}/>
            )}
          </Box>
        );
      },
    },
    {
      field: "discount_value",
      headerName: "Discount Value",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "min_amount",
      headerName: "Minimum Amount",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "time_used",
      headerName: "Time Used",
      sortable: false,
      ...actionColumnSM,
    },
    {
      field: "download_pdf",
      headerName: "Download",
      filterable: false,
      sortable: false,
      resizable: false,
      ...actionColumnMD,
      disableExport: true,
      renderCell: (params) => {
        const rowData = params.row;
        if (
          rowData.audiance_type === "Generic" &&
          rowData.is_order_dropdown === 0
        ) {
          return (
            <Box
              display="flex"
              alignItems="center"
              style={{ paddingTop: "0.65rem", paddingBottom: "0.65rem" }}
            >
              {/* <DownloadForOfflineIcon
                onClick={() => handlePDFDownload(rowData)}
                style={{ cursor: "pointer", color: Colors.primeposTextInfo, marginRight: '15px'  }}
              /> */}
              <Image
                onClick={() => handlePDFDownload(rowData)}
                style={{
                  cursor: "pointer",
                  color: Colors.primeposTextInfo,
                  marginRight: "0.94rem",
                }}
                src={PdfIcon}
              />
              <InsertPhotoOutlinedIcon
                onClick={() => handleImageDownload(rowData)}
                style={{ cursor: "pointer", color: Colors.posImageIconColor }}
              />
              {couponDataForImage && (
                <CouponImageDownload
                  data={couponDataForImage}
                  onImageGenerated={handleImageGenerated}
                />
              )}
            </Box>
          );
        } else {
          return <span>N.A.</span>;
        }
      },
    },
    // {
    //   field: "download_image",
    //   headerName: "Download Image",
    //   sortable: false,
    //   width: 150,
    //   disableExport: true,
    //   renderCell: (params) => {
    //     const rowData = params.row;
    //     const handleDownload = async () => {
    //       myContext.handleLoading(true);
    //       try {
    //         downloadCouponBarcodeImage(rowData.id)
    //           .then(async (response) => {
    //             if (response.data.flag) {
    //               const couponName = rowData.title;
    //               const couponCode = response.data.data.coupon_code;
    //               const base64 = response.data.data.image_base64;
    //               setCouponDataForImage({
    //                 title: couponName,
    //                 couponCode: couponCode,
    //                 base64: base64,
    //                 type: rowData.coupon_type,
    //                 discount_type :rowData.discount_type,
    //                 discount_value : rowData.discount_value,
    //                 time_used : rowData.time_used,
    //                 min_amount : rowData.min_amount
    //               });
    //               setTimeout(async () => {
    //                 const canvas = await html2canvas(
    //                   pageRef.current.getValue()
    //                 );
    //                 const link = document.createElement("a");
    //                 link.href = canvas.toDataURL("image/png");
    //                 link.download = `${couponName}.png`;
    //                 link.click();
    //               }, 1000);
    //               addToast({
    //                 alertStatus: Constants.TOAST_TYPE_SUCESS,
    //                 alertTitle: Constants.COUPON_MODULE,
    //                 alertDescription: "Image downloaded successfully.",
    //               });
    //               setLoading(false);
    //               myContext.handleLoading(false);
    //             } else {
    //               setLoading(false);
    //               let actionData = response;
    //               error({ actionData });
    //               myContext.handleLoading(false);
    //             }
    //           })
    //           .catch((err) => {
    //             setLoading(false);
    //             let actionData = err;
    //             error({ actionData });
    //             myContext.handleLoading(false);
    //           });
    //       } catch (error) {
    //         setLoading(false);
    //         myContext.handleLoading(false);
    //       }
    //     };
    //     if (
    //       rowData.audiance_type === "Generic" &&
    //       rowData.is_order_dropdown === 0
    //     ) {
    //       return (
    //         <>
    //           <InsertPhotoOutlinedIcon
    //             onClick={handleDownload}
    //             style={{ cursor: "pointer", color: Colors.posImageIconColor }}
    //           />
    //           {couponDataForImage && (
    //             <CouponImageDownload
    //               data={couponDataForImage}
    //               onImageGenerated={handleImageGenerated}
    //             />
    //           )}
    //         </>
    //       );
    //     } else {
    //       return <span>N.A.</span>;
    //     }
    //   },
    // },

    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          props.navigate(`${Constants.COUPON_EDIT + item.id}`, {
            state: data,
          });
        };
        const handleViewPage = (item) => {
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
            filter: searchQuery,
          };
          myContext.handleLoading(true);
          props.navigate(`${Constants.COUPON_VIEW + item.id}`, {
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.COUPON_LIST,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            // {
            //   name: "View",
            //   disabledButton: buttonDisable,
            //   icon: (
            //     <RemoveRedEyeOutlinedIcon
            //       style={{ color: Colors.posViewTextColor }}
            //     />
            //   ),
            //   onClick: (item) => handleViewPage(item),
            // },
            {
              name: "Deactive",
              disabledButton: buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const handleImageDownload = async (rowData) => {
    myContext.handleLoading(true);
    try {
      downloadCouponBarcodeImage(rowData.id)
        .then(async (response) => {
          if (response.data.flag) {
            const couponName = rowData.title;
            const couponCode = response.data.data.coupon_code;
            const base64 = response.data.data.image_base64;
            setCouponDataForImage({
              title: couponName,
              couponCode: couponCode,
              base64: base64,
              type: rowData.coupon_type,
              discount_type: rowData.discount_type,
              discount_value: rowData.discount_value,
              time_used: rowData.time_used,
              min_amount: rowData.min_amount,
            });
            setTimeout(async () => {
              const canvas = await html2canvas(pageRef.current.getValue());
              const link = document.createElement("a");
              link.href = canvas.toDataURL("image/png");
              link.download = `${couponName}.png`;
              link.click();
            }, 1000);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.COUPON_MODULE,
              alertDescription: "Image downloaded successfully.",
            });
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const handlePDFDownload = (rowData) => {
    try {
      downloadPDF(rowData.id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var url = Constants.REACT_APP_API_URL + response.data.data;
            window.open(url, "_blank");
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.COUPON_MODULE,
              alertDescription: response.data.message,
            });
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const deactiveColumnNames = [
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    { field: "title", headerName: "Name", sortable: true, ...actionColumnFlexDouble  },
    {
      field: "coupon_type",
      headerName: "Coupon Type",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "discount_type",
      headerName: "Discount Type",
      sortable: true,
      ...actionColumnMD,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Box display="flex"
          alignItems="center"
          style={{ paddingTop: "0.95rem", paddingBottom: "0.65rem" }}>
            {rowData.discount_type === "Percentage" ? (
              <Image
              src={PercentageIcon}
              />
            ) : (
              <MonetizationOnIcon style={{ fill: Colors.primeTaxInfo }}/>
            )}
          </Box>
        );
      },
    },
    {
      field: "discount_value",
      headerName: "Discount Value",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "min_amount",
      headerName: "Minimum Amount",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisable(true);
          myContext.handleLoading(true);
          if (
            modifiedDeactiveData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.COUPON_LIST,
          });
        };
        return (
          <Text
            // onClick={handleReactive}
            // cursor="pointer"
            color={Colors.posNavbarLink}
            // fontSize="1rem"
            // letterSpacing="-1.5%"
            // textDecoration="underline"
            // fontWeight={600}
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            // color={buttonDisable ? "gray" : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }}
          >
            Activate
          </Text>
        );
      },
    },
  ];
  const modifiedData = couponData.map((data) => ({
    id: data.id,
    title: data.title,
    coupon_type:
      data.coupon_type === "prod"
        ? "Product"
        : data.coupon_type === "ord"
        ? "Order"
        : data.coupon_type,
    audiance_type:
      data.audiance_type === "TAR"
        ? "Targeted"
        : data.audiance_type === "GEN"
        ? "Generic"
        : data.audiance_type,
    discount_type:
      data.discount_type === "amou"
        ? "Amount"
        : data.discount_type === "per"
        ? "Percentage"
        : data.discount_type,
    discount_value: data.discount_value,
    min_amount:
      data.min_amount === null ||
      data.min_amount === "" ||
      data.min_amount === undefined
        ? "N.A."
        : data.min_amount,
    time_used:
      data.time_used === null ||
      data.time_used === "" ||
      data.time_used === undefined
        ? "N.A."
        : data.time_used,
    is_order_dropdown: data.is_order_dropdown,
  }));
  const modifiedDeactiveData = deactiveCouponData.map((data) => ({
    id: data.id,
    title: data.title,
    coupon_type:
      data.coupon_type === "prod"
        ? "Product"
        : data.coupon_type === "ord"
        ? "Order"
        : data.coupon_type,

    discount_type:
      data.discount_type === "amou"
        ? "Amount"
        : data.discount_type === "per"
        ? "Percentage"
        : data.discount_type,
    discount_value: data.discount_value,
    min_amount:
      data.min_amount === null ||
      data.min_amount === "" ||
      data.min_amount === undefined
        ? "N.A."
        : data.min_amount,
  }));
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });

  const sortRef = useRef(sortModel);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.COUPON_MODULE,
            alertDescription: Constants.Coupon_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveCouponData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.COUPON_MODULE,
            alertDescription: Constants.COUPON_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <CouponImage ref={pageRef} couponData={couponDataForImage} />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Coupons"}
          discription={
            "Manage coupons for your customers, increase product sales and help them save"
          }
        />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
                filter: curruntTab == 1 ? "" : searchQuery,
              };
              props.navigate(Constants.COUPON_CREATE, {
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Divider h={"0.05rem"} borderColor={Colors.listShadow} />
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={8}
              mb={8}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                // onChange={(e) => setSearchQuery(e.target.value)}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          <Divider h={"0.05rem"} borderColor={Colors.listShadow} />
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab == 0 ? (
                <Box>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.ACTIVE_COUPON_EXPORT_FILENAME}
                  />
                </Box>
              ) : (
                <PosNoDataFound title={Constants.COUPON_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalDeactivatecount > 0 && curruntTab == 1 ? (
                <PosDataGridTable
                  columns={deactiveColumnNames}
                  rows={modifiedDeactiveData}
                  totalcount={totalDeactivatecount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.DEACTIVE_COUPON_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_COUPON_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(CouponList);
