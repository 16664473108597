import { Box, Switch } from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";

const PosSwitchButton = (props) => {
  const { switchValue, onChange, id,disabled } = props;

  return (
    <Box>
      <Switch
        id={id}
        isChecked={switchValue}
        isDisabled={props.disabled}
        onChange={onChange}
        mb={props.mb}
        mt={props.mt}
        ml={props.ml}
        sx={{
          ".chakra-switch__track[data-checked]:not([data-theme])": {
            backgroundColor: Colors.switch,
          },
        }}
      />
    </Box>
  );
};

export default PosSwitchButton;
