import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getStateListForSelectedCountry(countryISO) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_COUNTRY_STATE + countryISO,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllLocationsForPOS(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.LOCATION_DATA_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllLocationsCreateData() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.LOCATION_DATA_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getLocationEditData({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.LOCATION_DATA_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_UPDATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getAllLocationData(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.LOCATION_DATA_FOR_LIST_API_ROUTE +
            "/" +
            payload.location_id
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function locationBasicCreate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = JSON.stringify({
      name: Object.fromEntries(formData).name,
      address1: Object.fromEntries(formData).address,
      zipcode: Object.fromEntries(formData).zipcode,
      city: Object.fromEntries(formData).city,
      state: Object.fromEntries(formData).state,
      country: Object.fromEntries(formData).country,
      stateNotAvailable: Object.fromEntries(formData).stateNotAvailable,
      time_zone: Object.fromEntries(formData).timezone,
      location_type: Object.fromEntries(formData).location_type,
      // online_order: Object.fromEntries(formData).online_order,
      phone: Object.fromEntries(formData).phone,
      locationImage:Object.fromEntries(formData).hasOwnProperty("locationImage") ? JSON.parse(
        Object.fromEntries(formData).locationImage
      ) : "",
      kds: Object.fromEntries(formData).hasOwnProperty("kds") ? 
        Object.fromEntries(formData).kds : "",
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.LOCATION_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}
export function locationBasicUpdate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = JSON.stringify({
      name: Object.fromEntries(formData).name,
      address1: Object.fromEntries(formData).address,
      zipcode: Object.fromEntries(formData).zipcode,
      city: Object.fromEntries(formData).city,
      state: Object.fromEntries(formData).state,
      country: Object.fromEntries(formData).country,
      stateNotAvailable: Object.fromEntries(formData).stateNotAvailable,
      time_zone: Object.fromEntries(formData).timezone,
      location_type: Object.fromEntries(formData).location_type,
      // online_order: Object.fromEntries(formData).online_order,
      phone: Object.fromEntries(formData).phone,
      location_id: id,
      locationImage:Object.fromEntries(formData).hasOwnProperty("locationImage") ? JSON.parse(
        Object.fromEntries(formData).locationImage
      ) : "",
      image_base64:Object.fromEntries(formData).hasOwnProperty("image_base64") ? JSON.parse(
        Object.fromEntries(formData).image_base64
      ): "",
      kds: Object.fromEntries(formData).hasOwnProperty("kds") ? 
        Object.fromEntries(formData).kds : "",
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.LOCATION_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_UPDATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function locationDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.LOCATION_DEACTIVATE_API +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.LOCATION_DEACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.LOCATION_REACTIVATE_API +
            "/" +
            id,
          {},
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.LOCATION_REACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllDeactiveLocation(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.LOCATION_DEACTIVATED_LOCATION_LIST_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_DEACTIVATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllLocationsAdditionalSettingData(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          "admin_locations_show_additional_settings/" +
          id,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_UPDATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createAccountSettings(request) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.LOCATION_ACCOUNT_SETTINGS_CREATE_API_ROUTE,
        request,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.LOCATION_UPDATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}