import { useEffect } from "react";
import { WithRouter } from "../../../navigators/WithRouter";

const Logout = (props) => {
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    props.navigate("/");
  });

  return null;
};

export default WithRouter(Logout);
