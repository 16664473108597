import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { twofix } from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const TillCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [posStation, setposStationData] = useState([]);
  const [selectedPosStation, setSelectedPosStation] = useState([]);
  const [users, setUserData] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setposStationData(loaderResponse?.data?.data?.stations);
          setUserData(loaderResponse?.data?.data?.users);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.TILL_REPORT_ROUTE, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TILLS_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedPosStation(e.value);
      formik.setFieldValue("selectedPosStation", e.value.name);
    }
  };

  const formik = useFormik({
    initialValues: {
      selectedPosStation: "",
      selectedUsers: "",
      starting_amount: "",
    },
    validationSchema: Yup.object({
      selectedPosStation: Yup.string().required(
        Constants.TILLS_SELECT_POS_STATION
      ),
      selectedUsers: Yup.string().required(Constants.TILLS_SELECT_OPEN_BY),
      starting_amount: Yup.number()
        .required(Constants.TILLS_ENTER_STARING_AMOUNT)
        .max(999999.99, Constants.TILLS_STARTING_AMOUNT_MAX_VALUE)
        .min(0.01, Constants.TILLS_STARTING_AMOUNT_MIN_VALUE)
        .typeError(Constants.PAYOUT_PLEASE_ENTER_A_VALID_AMOUNT),
    }),
    onSubmit: async () => {},
  });

  const handleSubmitButton = () => {
    myContext.handleLoading(true);
    const payload = {
      opened_by: selectedUsers.id,
      opened_by_dropdown: JSON.stringify({
        value: {
          id: selectedUsers.id,
          name: selectedUsers.name,
        },
      }),
      pos_station_id: selectedPosStation.id,
      pos_station_id_posStation: JSON.stringify({
        value: {
          id: selectedPosStation.id,
          name: selectedPosStation.name,
        },
      }),
      starting_amount: formik.values.starting_amount,
    };
    actionRun.current = true;
    setButtonDisable(true);
    submit(payload, {
      method: Constants.POST,
      path: Constants.TILL_REPORT_ROUTE,
    });
  };

  const handleUserSelect = (e) => {
    if (undefined !== e.value) {
      setselectedUsers(e.value);
      formik.setFieldValue("selectedUsers", e.value.name);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.selectedPosStation.length <= 0 ||
        (formik.errors.selectedPosStation ||
        formik.errors.selectedUsers ||
        formik.errors.starting_amount ||
        buttonDisable
          ? true
          : false)
      )
    ) {
      formik.handleSubmit();
    }
  };

  return (
    <Box flex="1" pb="2rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              if (i === 0) {
                props.navigate(Constants.REPORT_PATH, {
                  state: stateData.state,
                });
              } else if (i === 1) {
                props.navigate(Constants.TILL_REPORT_ROUTE, {
                  state: stateData.state,
                });
              }
            }}
            breadCrumNames={["Report", "Tills", "Create a Till"]}
            breadCrumTitle={"Create a Till"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              props.navigate(Constants.TILL_REPORT_ROUTE, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={
              formik.values.selectedPosStation.length <= 0 ||
              formik.errors.selectedPosStation ||
              formik.errors.selectedUsers ||
              formik.errors.starting_amount ||
              buttonDisable
                ? true
                : false
            }
            buttonsubmit={"Submit"}
            SubmitButton={true}
            onClick={handleSubmitButton}
          />
        </Stack>
      </Flex>

      <Box
        mt={{ base: 2, md: "2rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="8"
        onKeyDown={handleKeyDown}
      >
        <Text
          fontSize="1.23rem"
          pt={"1.56rem"}
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Create a Till
        </Text>
        <Stack spacing={2}>
          <PosLable
            name={"Pos Station"}
            requiredLabel={true}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosDropDown
            id="selectedPosStation"
            options={posStation}
            value={selectedPosStation}
            onChange={handleOptionSelect}
            className="w-full md:w-20rem"
            width={"100%"}
            onBlur={(e) => {
              formik.setFieldTouched("selectedPosStation");
              formik.handleBlur(e);
            }}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select A Pos Station"
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedPosStation &&
            formik.errors.selectedPosStation ? (
              <span>{formik.errors.selectedPosStation}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2}>
          <PosLable
            name={"Opened By"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosDropDown
            id="selectedUsers"
            options={users}
            value={selectedUsers}
            onChange={handleUserSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedUsers");
              formik.handleBlur(e);
            }}
            className="w-full md:w-20rem"
            width={"100%"}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select A User"
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedUsers && formik.errors.selectedUsers ? (
              <span>{formik.errors.selectedUsers}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2}>
          <PosLable
            name={"Starting Amount"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="starting_amount"
            mt={"1rem"}
            placeholder={"Enter a Starting Amount"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.starting_amount}
            handleBlur={(e) => {
              formik.handleBlur(e);
              formik.setFieldValue(
                "starting_amount",
                twofix(formik.values.starting_amount) || ""
              );
            }}
            onKeyDown={(e) => {
              if (
                e.key === "-" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown"
              ) {
                e.preventDefault();
              }
            }}
            posNumberInput={true}
            inputType={"number"}
            inputError={
              formik.touched.starting_amount && formik.errors.starting_amount
            }
          />
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.starting_amount && formik.errors.starting_amount ? (
              <span>{formik.errors.starting_amount}</span>
            ) : null}
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

export default WithRouter(TillCreate);
