import {
    Box, Progress
} from "@chakra-ui/react";
import React from "react";
import LinearProgress from '@mui/material/LinearProgress';

const PosProgress = (props) => {

  return (
    <Box>
      <LinearProgress />
    </Box>
  );
};

export default PosProgress;
