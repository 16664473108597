import { PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Card,
  CardHeader,
  Checkbox,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Avatar from "@mui/material/Avatar";
import { GridToolbar } from "@mui/x-data-grid";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import AmericanExpress from "../../../assets/images/americanExpress.png";
import Diners_Club from "../../../assets/images/dinersClub.png";
import Discover from "../../../assets/images/discover.png";
import JCB from "../../../assets/images/jcb.png";
import MasterCard from "../../../assets/images/masterCard.png";
import image from "../../../assets/images/newcropped.png";
import UnionPay from "../../../assets/images/unionPay.png";
import visa from "../../../assets/images/visa.png";
import {
  AppContext,
  PosDataGridTable,
  PosDialogAlert,
  PosFormButton,
  PosInput,
  PosLable,
  PosListHeader,
  PosNoDataFound,
  PosOutlineButton,
  PosProgress,
  PosTab,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  changePlan,
  createStripe,
  deleteCustomerCard,
  downloadPDF,
  getAllCountriesState,
  getBillingInformation,
  getStateListForSelectedCountry,
  makeCardAsDefault,
  updateSmsPlan,
  viewBillingDetails,
} from "./BillingService";
import CheckoutForm from "./CheckoutForm";

// const stripePromise = loadStripe("pk_test_I13T8G510oLu1URs90VpVEqt");
const BillingList = (props) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const actionResponse = useActionData();
  const [loading, setLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [curruntTab, setCurruntTab] = useState(0);
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const submit = useSubmit();
  const [noData, setNoData] = useState(false);

  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [listOfSmsPlans, setListOfSmsPlans] = useState([]);
  const [perSMSCharge, setPerSMSCharge] = useState("");
  const [cancelPlan, setCancelPlan] = useState(0);
  const [noBillingSMSDetailsExist, setNoBillingSMSDetailsExist] = useState(0);
  const [
    noBillingSMSForNextMonthDetailsExist,
    setNoBillingSMSForNextMonthDetailsExist,
  ] = useState(0);
  const [noBillingDetailsExist, setNoBillingDetailsExist] = useState(0);

  const {
    isOpen: isSmsPlanModelOpen,
    onOpen: onSmsPlanModelOpen,
    onClose: onSmsPlanModelClose,
  } = useDisclosure();

  const [selectedPlanIndex, setSelectedPlanIndex] = useState("");
  const [selectedSmsPlan, setSelectedSmsPlan] = useState([]);
  const [
    initiallyLoadSelectedSmsPlan,
    setInitiallyLoadSelectedSmsPlan,
  ] = useState([]);

  const [
    selectedSMSPlanForNextMonth,
    setSelectedSMSPlanForNextMonth,
  ] = useState([]);

  const [smsLessPlanSelected, setSmsLessPlanSelected] = useState(false);
  const [smsGreaterPlanSelected, setSmsGreaterPlanSelected] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("id");
  const [rewardCardData, setRewardCardSetData] = useState([]);
  const [selectedRewardType, setSelectedRewardType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [stripeApiKey, setStripeApiKey] = useState("");
  // const stripePromise = loadStripe(stripeApiKey);
  const [customerCards, setCustomerCards] = useState([]);
  const [
    displayHostNameAndEmailField,
    setDisplayHostNameAndEmailField,
  ] = useState(1);

  const {
    isOpen: isDeleteCardConfirmOpen,
    onOpen: onDeleteCardConfirmOpen,
    onClose: onDeleteCardConfirmClose,
  } = useDisclosure();

  const {
    isOpen: isCancleSmsPlanConfirmOpen,
    onOpen: onCancleSmsPlanConfirmOpen,
    onClose: onCancleSmsPlanConfirmClose,
  } = useDisclosure();
  const [deleteCard, setDeleteCard] = useState({});
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");
  // const tabStyle = {
  //   fontWeight: "500",
  //   fontSize: "0.94rem",
  //   lineHeight: "1.17rem",
  //   letterSpacing: "-1.5%",
  //   color: Colors.posTextInfo,
  //   width: "17.94rem",
  // };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const paginationRef = useRef(paginationModel);
  const sortRef = useRef(sortModel);
  const [isEditing, setIsEditing] = useState(false);

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    products: false,
  });
  const cancelRef = React.useRef();

  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const loaderResponse = useLoaderData();
  const { error } = PosErrorHook();
  const { addToast } = PosTostMessage();
  const tabNames = ["Billing Settings", "Billing History"];
  const [users, setUsers] = useState();
  // const [billingSettings, setBillingSettings] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isBillingViewOpen,
    onOpen: onBillingViewOpen,
    onClose: onBillingViewClose,
  } = useDisclosure();
  const {
    isOpen: isAddCardOpen,
    onOpen: onAddCardOpen,
    onClose: onAddCardClose,
  } = useDisclosure();
  const [billingDetails, setbillingDetails] = useState([]);
  const [isBillingModalOpen, setIsBillingModalOpen] = useState(false);
  const [selectedBillingDetails, setSelectedBillingDetails] = useState([]);
  const [applyToNextGreaterOrLess, setApplyToNextGreaterOrLess] = useState([]);
  const [applyToNextMonth, setApplyToNextMonth] = useState(false);
  const [newPlanOverride, setNewPlanOverride] = useState([]);

  // const handleaddPage = () => {
  //   myContext.handleLoading(true);
  //   onBillingViewOpen();
  // };

  // const handleViews = (billingDetails) => {
  //   setSelectedBillingDetails();
  //   // props.navigate(Constants.VIEW);
  //   setIsBillingModalOpen(true);
  // };

  const handleCloseModalBill = () => {
    setIsBillingModalOpen(false);
  };

  const handleSingleDelete = (id) => {
    setSelectedItemId(null);
  };
  const handleEditButtonClick = () => {
    setIsEditing(!isEditing); // Toggle the editing state
  };
  const handleSaveButtonClick = () => {
    setIsEditing(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemDetails(null);
  };

  const smsPlanTableColumns = [
    {
      columnNames: "Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Number Of SMS",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Select",
      sortName: "",
      width: "",
    },
  ];

  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames1 = [
    // {
    //   field: "id",
    //   headerName: "id",
    //   sortable: true,
    //   flex: 1,
    // },
    {
      field: "year",
      headerName: "Year",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "month",
      headerName: "Month",
      sortable: true,
      ...actionColumn,
    },

    {
      field: "payment_status",
      headerName: "Status",
      sortable: false,
      ...actionColumn,
      renderCell: (params) => (
        <span
          style={{
            padding: "0.150rem 0.625rem",
            borderRadius: "0.63rem",
            backgroundColor: params.value === "" ? "#E4FEE2" : "#FCE6E5",
            color: params.value === "Paid" ? "#0B8C20B5" : "#CD1919",
            fontWeight:"300",
            justifyContent: "center",
            alignItems: "center",
            width:"2.5rem",
          }}
        >
          {params.value}
        </span>
      ),
      renderCell: (params) => {
        let styles = {
          padding: "0.150rem 0.63rem",
          borderRadius: "0.63rem",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.94rem",
          fontStyle: "normal",
          fontWeight: 300,
          lineHeight: "normal",
          letterSpacing: "-0.01rem",
          hright:"1.5rem",
          width:"5.63rem"
        };
        switch (params.value) {
          case "Paid":
            styles.backgroundColor = "#E4FEE2";
            styles.color = "#0B8C20B5";
            break;
          case "Fail":
            styles.backgroundColor = "#FCE6E5";
            styles.color = "#CD1919"; 
            break;
          case "Partially Paid":
            styles.backgroundColor = "#5881FE1A"; 
            styles.color = "#010048"; 
            break;
          default:
            styles.backgroundColor = "#5881FE1A";
            styles.color = "#010048"; 
            break;
        }
        return (
          <span style={styles}>
            {params.value}
          </span>
        );
      }
    },
    {
      field: "stripe_total_paid_amount",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },

    {
      field: "",
      headerName: "View",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const billingDetails = params.row;

        const payload = {
          month: billingDetails.month,
          year: billingDetails.year,
          user_id: billingDetails.user_id,
        };

        const handleView = () => {
          // Remove the payload argument from here
          myContext.handleLoading(true);
          try {
            viewBillingDetails(payload)
              .then((response) => {
                // handleViews();
                if (
                  undefined !== response.data.flag &&
                  null !== response.data.flag &&
                  response.data.flag == true
                ) {
                  // var url = Constants.REACT_APP_API_URL + Constants.BILLING_VIEW_DETAIL_API_ROUTE;
                  // window.open(url, "_blank");
                  myContext.handleLoading(false);
                  setSelectedBillingDetails(response.data.data);

                  let model = response.data.data;
                  let company_logo = model.company_logo;
                  // Set card logos based on brand
                  model.customer_cards_details.forEach((element) => {
                    switch (element.brand) {
                      case Constants.CARD_BRAND_VISA:
                        element.cardLogo = visa;
                        break;
                      case Constants.CARD_BRAND_MASTERCARD:
                        element.cardLogo = MasterCard;
                        break;
                      case Constants.CARD_BRAND_AMERICAN_EXPRESS:
                        element.cardLogo = AmericanExpress;
                        break;
                      case Constants.CARD_BRAND_DISCOVER:
                        element.cardLogo = Discover;
                        break;
                      case Constants.CARD_BRAND_DINERS_CLUB:
                        element.cardLogo = Diners_Club;
                        break;
                      case Constants.CARD_BRAND_JCB:
                        element.cardLogo = JCB;
                        break;
                      case Constants.CARD_BRAND_UNION_PAY:
                        element.cardLogo = UnionPay;
                        break;
                      default:
                        element.cardLogo =
                          Constants.CARD_LOGO_DEFAULT_CARD_PATH;
                        break;
                    }
                  });
                  setLoading(false);
                  setIsBillingModalOpen(response.data.data);
                  onBillingViewOpen();
                } else {
                  setLoading(false);
                  let actionData = response;
                  error({ actionData });
                  myContext.handleLoading(false);
                }
              })
              .catch((err) => {
                setLoading(false);
                let actionData = err;
                error({ actionData });
                myContext.handleLoading(false);
              });
          } catch (error) {
            setLoading(false);
            myContext.handleLoading(false);
          }
        };

        return (
          <RemoveRedEyeOutlinedIcon
            onClick={handleView} // Pass the handleDownload function
            // onClick={() => handleaddPage()}

            style={{ cursor: "pointer", color: Colors.primeposTextInfo }}
          />
        );
      },
    },

    {
      field: "download_pdf",
      headerName: "Download PDF",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 150,
      renderCell: (params) => {
        const billingDetails = params.row; // Assuming billingDetails contains your data
        const getMonthName = (monthNumber) => {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          return months[monthNumber - 1]; // Adjust monthNumber to be zero-based index
        };
        const payload = {
          id: billingDetails.id,
          payment_status: billingDetails.payment_status,
          stripe_total_paid_amount: billingDetails.stripe_total_paid_amount,
          // Date: billingDetails.year,
          month: getMonthName(billingDetails.month),
          year: billingDetails.year,
          user_id: billingDetails.user_id,
        };

        const handleDownload = () => {
          // Remove the payload argument from here
          try {
            downloadPDF(payload)
              .then((response) => {
                if (
                  undefined !== response.data.flag &&
                  null !== response.data.flag &&
                  response.data.flag == true
                ) {
                  var url = Constants.REACT_APP_API_URL + response.data.data;
                  window.open(url, "_blank");
                  setLoading(false);
                  myContext.handleLoading(false);
                } else {
                  setLoading(false);
                  let actionData = response;
                  error({ actionData });
                  myContext.handleLoading(false);
                }
              })
              .catch((err) => {
                setLoading(false);
                let actionData = err;
                error({ actionData });
                myContext.handleLoading(false);
              });
          } catch (error) {
            setLoading(false);
            myContext.handleLoading(false);
          }
        };

        return (
          <DownloadForOfflineIcon
            onClick={handleDownload} // Pass the handleDownload function directly
            style={{ cursor: "pointer", color: Colors.primeposTextInfo }}
          />
        );
      },
    },
  ];

  const [selectedItemData, setSelectedItemData] = useState([]);

  const modifiedData = listOfSmsPlans.map((smsPlan, index) => ({
    title: smsPlan.name,
    number_of_sms: smsPlan.no_of_sms,
    price: `$${smsPlan.price}`,
    select: (
      <Radio
        isChecked={smsPlan.id === selectedPlanIndex}
        onChange={() => handleSmsPlanRadioChange(index, smsPlan)}
      />
    ),
  }));

  function handleSmsPlanRadioChange(index, smsPlan) {
    let selectedSmsPlanArray = [];
    setSelectedPlanIndex(smsPlan.id);
    setApplyToNextMonth(false);
    selectedSmsPlanArray.push(smsPlan);
    setApplyToNextGreaterOrLess(selectedSmsPlanArray);
    if (smsPlan.price < parseFloat(selectedSmsPlan[0]?.price)) {
      //49<99
      setSmsLessPlanSelected(true);
      setSmsGreaterPlanSelected(false);
      setApplyToNextMonth(true);
      // selectedSmsPlanArray.push(smsPlan)
      // setApplyToNextGreaterOrLess(selectedSmsPlanArray);
    } else if (parseFloat(selectedSmsPlan[0]?.price) < smsPlan.price) {
      setApplyToNextMonth(false);
      setSmsLessPlanSelected(false);
      setSmsGreaterPlanSelected(true);
      // if(applyToNextMonth)
      // setApplyToNextMonth(applyToNextMonth ? true : false)
      // selectedSmsPlanArray.push(smsPlan)
      // setApplyToNextGreaterOrLess(selectedSmsPlanArray);
    } else {
      setSmsLessPlanSelected(false);
      setSmsGreaterPlanSelected(false);
    }

    // if(smsPlan.price <= selectedSmsPlan?.price)
    // setInitiallyLoadSelectedSmsPlan(smsPlan);
    // if(null != smsPlan && '' != smsPlan){
    //   //Initially User have plan then both checkboxes(Greater and Less) hide.
    //   setSmsLessPlanSelected(false);
    //   setSmsGreaterPlanSelected(false);
    // }
  }
  const handleCheckboxChange = (flag) => {
    setApplyToNextMonth(flag);
  };
  const modifiedViewData = selectedItemId
    ? [selectedItemId].map((item) => ({
        // id: item.id,
        title: item.title,
        number_of_sms: item.number_of_sms,
        price: `$${item.price}`,
      }))
    : [];

  const handleRadioItemsChange = (params) => {
    const selectedRowData = params.row;
    onClose();
    setSelectedItemId(selectedRowData);
    const products = selectedRowData.products;

    const productMap = new Map();

    const mergedProducts = Array.from(productMap.values());
    setSelectedItemData(mergedProducts);
  };
  const billingHistoryModifiedData = billingDetails.map((items) => ({
    id: items.id,
    payment_status: items.payment_status,
    stripe_total_paid_amount: `$${items.stripe_total_paid_amount}`,
    year: items.year,
    month: items.month,
    // month: new Date(Date.UTC(items.year, items.month - 1, 1)).toLocaleString('default', { month: 'long' }),
    user_id: items.user_id,
  }));

  const Billingviewcolumns = [
    {
      field: "SMS_Email_Plan_Charges",
      headerName: "Description",
      sortable: true,
      flex: 1,
    },
    {
      field: "stripe_total_paid_amount",
      headerName: "Amount($)",
      sortable: true,
      flex: 1,
    },
  ];

  const modifiedbillingviewdatas = billingDetails.map((items) => ({
    id: items.id,
    SMS_Email_Plan_Charges: "SMS/Email Plan Charges",
    stripe_total_paid_amount: `$${items.stripe_total_paid_amount}`,
  }));

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse?.response?.data;
          if (actionData?.code == 404) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.BILLING_MODULE,
              alertDescription: actionData?.message,
            });
          } else {
            let actionData = loaderResponse;
            error({ actionData });
          }
          setNoData(true);
          // setTotalCount(0);
          // setLoading(false);
          // setShowTable(false);
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(true);
          setUsers(loaderResponse.data.data.ms_cust_id);
          fetchData(loaderResponse.data.data.ms_cust_id);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse?.response?.data;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        // props.navigate(Constants.REWARDS_LIST_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.BILLING_MODULE,
          alertDescription: actionResponse.data.message,
        });
        setIsEditing(false);
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  useEffect(() => {
    if (paginationRef.current !== paginationModel) {
      fetchData(users);
      paginationRef.current = paginationModel;
    } else if (sortRef.current !== sortModel) {
      if (users !== undefined) {
        fetchData(users);
      }
      sortModel.current = sortModel;
    }
  }, [paginationModel, sortModel, users]);

  const fetchData = async (userData) => {
    // myContext.handleLoading(true);
    setLoading(true);
    let data = {
      id: userData,
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getBillingInformation(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setNoData(false);
            setStripeApiKey(response?.data?.data?.stripe_api_key);
            formik.setValues({
              id: response?.data?.data.billing_settings.id,
              businessname: response?.data?.data.billing_settings.business_name,
              businessphone:
                response?.data?.data.billing_settings.business_phone,
              businesscity: response?.data?.data.billing_settings.business_city,
              businessaddress:
                response?.data?.data.billing_settings.business_address,
              businesscountry:
                response?.data?.data.billing_settings.business_country,
              businessstate:
                response?.data?.data.billing_settings.business_state,
              businesszip: response?.data?.data.billing_settings.business_zip,
              cardholderemail:
                response?.data?.data.billing_settings.card_holder_email,
              cardholdername:
                response?.data?.data.billing_settings.card_holder_name,
              cancelplan: response?.data?.data.billing_settings.cancel_plan,
              smsplanid: response?.data?.data.billing_settings.sms_plan_id,
              smsplans: response?.data?.data.billing_settings.sms_plans,
              businesssuite:
                response?.data?.data.billing_settings.business_suite,

              cardnumber: response?.data?.data.customer_cards[0]?.last4,
              cardexpmonth:
                response?.data?.data.customer_cards[0]?.card_exp_month,
              cardexpyear:
                response?.data?.data.customer_cards[0]?.card_exp_year,
            });
            setCancelPlan(response?.data?.data.billing_settings.cancel_plan);
            setListOfSmsPlans(
              response?.data?.data?.allSmsPlans.filter((s) => s?.no_of_sms > 0)
            );
            // setListOfSmsPlans(response?.data?.data?.allSmsPlans);
            response?.data?.data?.allSmsPlans.forEach((element) => {
              if (0 >= element.no_of_sms) {
                setPerSMSCharge(element.price);
              }
            });
            // setSelectedSmsPlan([...response?.data?.data?.billing_settings.sms_plans]);

            // setSelectedSmsPlan(response?.data?.data?.billing_settings.sms_plans);
            //When user have not any sms plan.
            var selectedSmsPlanArray = [];
            if (
              null == response?.data?.data?.billing_settings.sms_plans ||
              "" == response?.data?.data?.billing_settings.sms_plans ||
              response?.data?.data?.billing_settings.sms_plans.length <= 0
            ) {
              setNoBillingSMSDetailsExist(1);
            } else {
              selectedSmsPlanArray.push(
                response?.data?.data?.billing_settings?.sms_plans
              );
              setSelectedSmsPlan(selectedSmsPlanArray);
              setSelectedPlanIndex(
                response?.data?.data?.billing_settings?.sms_plans?.id
              );
            }

            //When user have not any sms plan for next month.
            var selectedSmsNextPlanArray = [];

            if (
              null ==
                response?.data?.data?.billing_settings.next_month_sms_data ||
              "" ==
                response?.data?.data?.billing_settings.next_month_sms_data ||
              response?.data?.data?.billing_settings.next_month_sms_data
                .length <= 0
            ) {
              setNoBillingSMSForNextMonthDetailsExist(1);
            } else {
              selectedSmsNextPlanArray.push(
                response?.data?.data?.billing_settings.next_month_sms_data
              );
              setSelectedSMSPlanForNextMonth(selectedSmsNextPlanArray);
            }
            //When user have no billing settings details.
            if (
              null == response?.data?.data?.billing_details ||
              "" == response?.data?.data?.billing_details ||
              response?.data?.data?.billing_details.length <= 0
            ) {
              setNoBillingDetailsExist(1);
            }

            setbillingDetails(response?.data?.data?.billing_details);
            setTotalCount(response?.data?.data?.totalItems);
            response?.data?.data?.customer_cards.forEach((element) => {
              switch (element.brand) {
                case Constants.CARD_BRAND_VISA:
                  element.cardLogo = visa;
                  break;
                case Constants.CARD_BRAND_MASTERCARD:
                  element.cardLogo = MasterCard;
                  break;
                case Constants.CARD_BRAND_AMERICAN_EXPRESS:
                  element.cardLogo = AmericanExpress;
                  break;
                case Constants.CARD_BRAND_DISCOVER:
                  element.cardLogo = Discover;
                  break;
                case Constants.CARD_BRAND_DINERS_CLUB:
                  element.cardLogo = Diners_Club;
                  break;
                case Constants.CARD_BRAND_JCB:
                  element.cardLogo = JCB;
                  break;
                case Constants.CARD_BRAND_UNION_PAY:
                  element.cardLogo = UnionPay;
                  break;
                default:
                  element.cardLogo = Constants.CARD_LOGO_DEFAULT_CARD_PATH;
                  break;
              }
            });
            setCustomerCards(response?.data?.data?.customer_cards);
            if (
              response?.data?.data?.billing_settings?.card_holder_email
                ?.length > 0 &&
              response?.data?.data?.billing_settings?.card_holder_name?.length >
                0
            ) {
              setDisplayHostNameAndEmailField(0);
            }
            setLoading(false);
            setCardLoading(false);
            myContext.handleLoading(false);
          } else {
            //no data
            setNoData(true);
            setTotalCount(0);
            setLoading(false);
            setCardLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }

          setLoading(false);
          setCardLoading(false);
        })
        .catch((err) => {
          setNoData(true);
          setTotalCount(0);
          setLoading(false);
          setCardLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setNoData(true);
      setTotalCount(0);
      setLoading(false);
      setCardLoading(false);
      setShowTable(false);
      myContext.handleLoading(false);
    }
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      effectRun.current = true;
      setLoading(false);
      setCardLoading(false);
    } else {
      effectRun.current = true;
      setLoading(false);
      setCardLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      // id: loaderResponse.data.data.employee.id,
      // businessname:
      //   undefined !== loaderResponse?.data?.data?.billing_settings?.business_name
      //    &&
      //     null != loaderResponse?.data?.data?.billing_settings?.business_name
      //     ? loaderResponse?.data?.data?.billing_settings?.business_name
      //     : "",
      // businessname: billingSettings?.business_name || "",

      businessphone:
        undefined !==
          loaderResponse?.data?.data?.billing_settings?.business_phone &&
        null != loaderResponse?.data?.data?.billing_settings?.business_phone
          ? loaderResponse?.data?.data?.billing_settings?.business_phone
          : "",

      businessaddress:
        undefined !==
          loaderResponse?.data?.data?.billing_settings?.business_address &&
        null != loaderResponse?.data?.data?.billing_settings?.business_address
          ? loaderResponse?.data?.data?.billing_settings?.business_address
          : "",

      businesscity:
        undefined !==
          loaderResponse?.data?.data?.billing_settings?.business_city &&
        null != loaderResponse?.data?.data?.billing_settings?.business_city
          ? loaderResponse?.data?.data?.billing_settings?.business_city
          : "",

      businesscountry:
        undefined !==
          loaderResponse?.data?.data?.billing_settings?.business_country &&
        null != loaderResponse?.data?.data?.billing_settings?.business_country
          ? loaderResponse?.data?.data?.billing_settings?.business_country
          : "",

      businessstate:
        undefined !==
          loaderResponse?.data?.data?.billing_settings?.business_state &&
        null != loaderResponse?.data?.data?.billing_settings?.business_state
          ? loaderResponse?.data?.data?.billing_settings?.business_state
          : "",

      businesszip:
        undefined !==
          loaderResponse?.data?.data?.billing_settings?.business_zip &&
        null != loaderResponse?.data?.data?.billing_settings?.business_zip
          ? loaderResponse?.data?.data?.billing_settings?.business_zip
          : "",
    },
    validationSchema: Yup.object({
      businessname: Yup.string()
        .trim()
        .required(Constants.USER_BUSINESS_NAME_REQUIRED)
        .max(191, Constants.USER_BUSINESS_NAME_LENGTH_GREATER_THAN_191),

      businessaddress: Yup.string()
        .trim()
        .required(Constants.USER_ADDRESS_NAME_REQUIRED)
        .max(5000, Constants.USER_ADDRESS_LENGTH_GREATE_THEN_5000),

      businesscity: Yup.string()
        .trim()
        .required(Constants.USER_PLEASE_ENTER_A_CITY)
        .max(191, Constants.USER_CITY_LENGTH_GREATE_THEN_191),

      businessphone: Yup.string()
        .trim()
        .required(Constants.USER_PLEASE_ENTER_A_PHONE_NUMBER)
        .max(15, Constants.USER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG)
        .min(2, Constants.USER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG)
        .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_PHONE_NUMBER),

      businesszip: Yup.string()
        .trim()
        .required(Constants.USER_ZIP_CODE__REQUIRED)
        .min(2, Constants.USER_ZIP_CODE_MUST_BE_GREATER_THAN_2_DIGITS_LONG)
        .max(11, Constants.USER_ZIP_CODE_MUST_BE_LESS_THAN_11_DIGITS_LONG),
    }),

    onSubmit: (values) => {
      setLoading(true);
      myContext.handleLoading(true);
      const payload = {
        ...values,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.UPDATE_BILLING_SETTINGS,
      });
    },
  });

  const handleSelectedCountry = (value) => {
    setSelectedCountry(value);
    getStateListForSelectedCountry(value)
      .then((response) => {
        if (
          response.data[Constants.FLAGE] !== null &&
          response.data[Constants.FLAGE] === true
        ) {
          if (undefined != response.data.data.states) {
            const arrayStates = Object.keys(response.data.data.states).map(
              (key) => ({
                label: response.data.data.states[key],
                value: key,
              })
            );
            setStates(arrayStates);
            setStatesAvailability(1);
            if (
              loaderResponse?.data?.data?.employee?.state &&
              null != loaderResponse?.data?.data?.employee?.state &&
              "N.A." != loaderResponse?.data?.data?.employee?.state
            ) {
              setStatesAvailability(0);
              setSelectedState(loaderResponse?.data?.data?.employee?.state);
            } else {
              setSelectedState("N.A.");
            }
          } else {
            setStates([]);
            setStatesAvailability(1);
            setSelectedState("N.A.");
          }
        } else {
          if (response.data[Constants.CODE] === Constants.REQUEST_DENIED_CODE) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.TOKEN_EXPIRTED,
              alertDescription: response.data.message,
            });
            props.navigate(Constants.LOGOUT_PAGE);
          } else if (
            response.data[Constants.CODE] === Constants.UNAUTHORIZE_CODE
          ) {
            props.navigate(Constants.UNAUTHORIZE_PAGE);
          } else if (response.data.validationCheck) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.FIELD_IS_REQUIRED,
              alertDescription: response.data.message,
            });
          } else {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.FIELD_IS_REQUIRED,
              alertDescription: response.data.message,
            });
          }
        }
      })
      .catch((error) => {});
  };

  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A.") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };

  const getCountryStates = async () => {
    getAllCountriesState()
      .then((response) => {
        if (
          response.data[Constants.FLAGE] !== null &&
          response.data[Constants.FLAGE] === true
        ) {
          const arrayCountries = Object.keys(response.data.data.countries).map(
            (key) => ({
              label: response.data.data.countries[key],
              value: key,
            })
          );
          setCountry(arrayCountries);
        } else {
          if (response.data[Constants.CODE] === Constants.REQUEST_DENIED_CODE) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.TOKEN_EXPIRTED,
              alertDescription: response.data.message,
            });
            props.navigate(Constants.LOGOUT_PAGE);
          } else if (
            response.data[Constants.CODE] === Constants.UNAUTHORIZE_CODE
          ) {
            props.navigate(Constants.UNAUTHORIZE_PAGE);
          } else if (response.data.validationCheck) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.FIELD_IS_REQUIRED,
              alertDescription: response.data.message,
            });
          } else {
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.FIELD_IS_REQUIRED,
              alertDescription: response.data.message,
            });
          }
        }
      })
      .catch((error) => {});
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.businessname.length <= 0 ||
      formik.errors.businessname ||
      formik.errors.businessphone ||
      formik.errors.businesszip ||
      formik.errors.businessaddress ||
      formik.errors.businesscity ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
      setIsEditing(false);
    }
  };

  const addCardPopUpOpen = () => {
    onAddCardOpen();
    // setDisplayHostNameAndEmailField(customerCards?.length)
  };

  const handleChange = (data) => {
    setCardLoading(true);
    // data.update_customer = 1;
    try {
      createStripe(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            onAddCardClose();
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.BILLING_MODULE,
              alertDescription: response.data.message,
            });
            response?.data?.data?.cards.forEach((element) => {
              switch (element.brand) {
                case Constants.CARD_BRAND_VISA:
                  element.cardLogo = visa;
                  break;
                case Constants.CARD_BRAND_MASTERCARD:
                  element.cardLogo = MasterCard;
                  break;
                case Constants.CARD_BRAND_AMERICAN_EXPRESS:
                  element.cardLogo = AmericanExpress;
                  break;
                case Constants.CARD_BRAND_DISCOVER:
                  element.cardLogo = Discover;
                  break;
                case Constants.CARD_BRAND_DINERS_CLUB:
                  element.cardLogo = Diners_Club;
                  break;
                case Constants.CARD_BRAND_JCB:
                  element.cardLogo = JCB;
                  break;
                case Constants.CARD_BRAND_UNION_PAY:
                  element.cardLogo = UnionPay;
                  break;
                default:
                  element.cardLogo = Constants.CARD_LOGO_DEFAULT_CARD_PATH;
                  break;
              }
            });
            setCustomerCards(response?.data?.data?.cards);
            if (
              null != response?.data?.data?.card_holder_email &&
              "" != response?.data?.data?.card_holder_email &&
              null != response?.data?.data?.card_holder_name &&
              "" != response?.data?.data?.card_holder_name
            ) {
              setDisplayHostNameAndEmailField(0);
            } else {
              setDisplayHostNameAndEmailField(1);
            }
            setCardLoading(false);
            myContext.handleLoading(false);
          } else {
            // setFormDisable(false);
            setCardLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setCardLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCardLoading(false);
      myContext.handleLoading(false);
    }
  };

  const onRemove = (id, data) => {
    onDeleteCardConfirmOpen();
    setDeleteCard(data);
  };

  const handleDeleteCard = (item) => {
    setCardLoading(true);
    // myContext.handleLoading(true);
    let data = {
      id: deleteCard.id,
    };
    try {
      deleteCustomerCard(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            onDeleteCardConfirmClose();
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.BILLING_MODULE,
              alertDescription: response.data.message,
            });
            response?.data?.data.forEach((element) => {
              switch (element.brand) {
                case Constants.CARD_BRAND_VISA:
                  element.cardLogo = visa;
                  break;
                case Constants.CARD_BRAND_MASTERCARD:
                  element.cardLogo = MasterCard;
                  break;
                case Constants.CARD_BRAND_AMERICAN_EXPRESS:
                  element.cardLogo = AmericanExpress;
                  break;
                case Constants.CARD_BRAND_DISCOVER:
                  element.cardLogo = Discover;
                  break;
                case Constants.CARD_BRAND_DINERS_CLUB:
                  element.cardLogo = Diners_Club;
                  break;
                case Constants.CARD_BRAND_JCB:
                  element.cardLogo = JCB;
                  break;
                case Constants.CARD_BRAND_UNION_PAY:
                  element.cardLogo = UnionPay;
                  break;
                default:
                  element.cardLogo = Constants.CARD_LOGO_DEFAULT_CARD_PATH;
                  break;
              }
            });
            setCustomerCards(response?.data?.data);
            setCardLoading(false);
            myContext.handleLoading(false);
          } else {
            // setFormDisable(false);
            setCardLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setCardLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCardLoading(false);
      myContext.handleLoading(false);
    }
  };

  const cardMakeAsDefault = (id) => {
    setCardLoading(true);
    // myContext.handleLoading(true);
    let data = {
      id: id,
    };
    try {
      makeCardAsDefault(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            onDeleteCardConfirmClose();
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.BILLING_MODULE,
              alertDescription: response.data.message,
            });
            response?.data?.data?.cards.forEach((element) => {
              switch (element.brand) {
                case Constants.CARD_BRAND_VISA:
                  element.cardLogo = visa;
                  break;
                case Constants.CARD_BRAND_MASTERCARD:
                  element.cardLogo = MasterCard;
                  break;
                case Constants.CARD_BRAND_AMERICAN_EXPRESS:
                  element.cardLogo = AmericanExpress;
                  break;
                case Constants.CARD_BRAND_DISCOVER:
                  element.cardLogo = Discover;
                  break;
                case Constants.CARD_BRAND_DINERS_CLUB:
                  element.cardLogo = Diners_Club;
                  break;
                case Constants.CARD_BRAND_JCB:
                  element.cardLogo = JCB;
                  break;
                case Constants.CARD_BRAND_UNION_PAY:
                  element.cardLogo = UnionPay;
                  break;
                default:
                  element.cardLogo = Constants.CARD_LOGO_DEFAULT_CARD_PATH;
                  break;
              }
            });
            setCustomerCards(response?.data?.data?.cards);
            setCardLoading(false);
            myContext.handleLoading(false);
          } else {
            // setFormDisable(false);
            setCardLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setCardLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCardLoading(false);
      myContext.handleLoading(false);
    }
  };

  const updateSmsPlanPopUp = () => {
    if (selectedSmsPlan?.length <= 0) {
      setSelectedPlanIndex("");
    } else {
      setSelectedPlanIndex(selectedSmsPlan[0].id);
    }
    setSmsLessPlanSelected(false);
    setSmsGreaterPlanSelected(false);
    if (customerCards?.length > 0) {
      onSmsPlanModelOpen();
    } else {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.BILLING_MODULE,
        alertDescription: Constants.CARD_DETAILS_NOT_FOUND,
      });
      addCardPopUpOpen();
    }
  };

  function closeSmsPlanModel() {
    // setSelectedUpgradePlanOption('');
    // setSelectedPlanIndex('');
    // setSelectedSmsPlan([]);
    onSmsPlanModelClose();
  }

  function closecardModel() {
    // setSelectedUpgradePlanOption('');
    // setSelectedPlanIndex('');
    // setSelectedSmsPlan([]);
    onAddCardClose();
  }

  function closebillviewModel() {
    // setSelectedUpgradePlanOption('');
    // setSelectedPlanIndex('');
    // setSelectedSmsPlan([]);
    onBillingViewClose();
  }
  function submitPlan() {
    let newPlan;
    newPlan = applyToNextGreaterOrLess;
    if (selectedPlanIndex == selectedSmsPlan[0]?.id) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.BILLING_MODULE,
        alertDescription:
          "Please select different plan from your current plan.",
      });
    } else {
      setLoading(true);
      var payload = {
        cancel_plan: cancelPlan,
        applyToNextMonth: applyToNextMonth,
        selectedSmsPlan: newPlan[0],
      };
      try {
        updateSmsPlan(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              onSmsPlanModelClose();
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: Constants.BILLING_MODULE,
                alertDescription: response.data.message,
              });
              // setCustomerCards(response?.data?.data?.cards)
              if (response.data.apply_to_next_month == 0) {
                setNoBillingSMSDetailsExist(0);
                setSelectedSmsPlan(response.data.sms_plan_data);
              } else {
                setNoBillingSMSDetailsExist(0);
                setNoBillingSMSForNextMonthDetailsExist(0);
                setSelectedSMSPlanForNextMonth(response.data.sms_plan_data);
              }
              // this.viewAllBillingDetails();
              fetchData(users);
              // setLoading(false);
              myContext.handleLoading(false);
            } else {
              // setFormDisable(false);
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  }

  // onRemoveSmsPlanMethod
  const onRemoveSmsPlanMethod = (data) => {
    onCancleSmsPlanConfirmOpen();
    // setDeleteCard(data);
  };

  function cancelPlanMethod() {
    setLoading(true);
    var msCustId = users;
    var resubscribe_plan = {
      resubscribe_plan: 0,
    };
    try {
      changePlan(msCustId, resubscribe_plan)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            onCancleSmsPlanConfirmClose();
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.BILLING_MODULE,
              alertDescription: response.data.message,
            });
            // this.viewAllBillingDetails();
            setSelectedSmsPlan([]);
            setSelectedPlanIndex("");
            fetchData(users);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            // setFormDisable(false);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  }
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        isOpen={isDeleteCardConfirmOpen}
        onClose={onDeleteCardConfirmClose}
        title={"Remove Card"}
        discription={
          "Are you sure you want to Remove " +
          deleteCard?.brand +
          " ending with " +
          deleteCard?.last4 +
          "?"
        }
        onClick={handleDeleteCard}
        buttonNames={["No", "Yes"]}
      />

      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        isOpen={isCancleSmsPlanConfirmOpen}
        onClose={onCancleSmsPlanConfirmClose}
        title={"Cancle Plan"}
        discription={"Are you sure you want to Cancel your SMS / Email Plan?"}
        onClick={cancelPlanMethod}
        buttonNames={["No", "Yes"]}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={8}
      >
        <PosListHeader
          header={"Billing"}
          discription={"Manage your billing and payment information"}
        />
      </Flex>
      {!noData ? (
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            // mt={"2 rem"}
            mb={"1.31rem"}
            ml={4}
            mr={4}
          ></Flex>
          <Box pt={1}>
            <TabPanels>
              <TabPanel>
                <Box
                  mb={"1.13rem"}
                  p={"2.06rem"}
                  borderRadius="0.63rem"
                  bg={"#FAFAFA"}
                  // boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
                  filter={"drop-shadow(0px 4px 20px rgba(90, 90, 90, 0.04))"}
                >
                  {/* {curruntTab === 0 && (
  <> */}
                  {isEditing ? (
                    <>
                      <Flex
                        direction={{ base: "column", md: "row" }}
                        alignItems={{ base: "flex-start", md: "center" }}
                        justify="space-between"
                        // pb={{ base: 6, md: 16 }}
                        // pb={8}
                      >
                        <PosListHeader
                          header={"Billing Information"}
                          discription={
                            "Personal details that is associated with your payment method"
                          }
                        />
                        <Spacer />
                        {/* <Stack direction={"row"} alignSelf={"flex-end"}> */}
                        <ButtonGroup
                          gap="2"
                          alignSelf={"flex-end"}
                          // flexWrap={"wrap"}
                          // flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}
                        >
                          <PosFormButton
                            onClick={() => {
                              setIsEditing(false);
                            }}
                            buttonText={"Cancel"}
                            CancelButton={true}
                          />
                          <PosFormButton
                            isDisabled={
                              formik.values.businessname?.length <= 0 ||
                              formik.values.businessphone?.length <= 0 ||
                              formik.values.businessaddress?.length <= 0 ||
                              formik.values.businesscity?.length <= 0 ||
                              formik.values.businesszip?.length <= 0 ||
                              formik.errors.businessname ||
                              formik.errors.businessphone ||
                              formik.errors.businessaddress ||
                              formik.errors.businesscity ||
                              formik.errors.businesszip ||
                              buttonDisable
                                ? true
                                : false
                            }
                            buttonsubmit={"Submit"}
                            SubmitButton={true}
                            onClick={formik.handleSubmit}
                          />
                        </ButtonGroup>
                      </Flex>

                      <Flex
                        direction={{ base: "column", md: "row" }}
                        alignItems={{ base: "", md: "center" }}
                        pl={{ base: "", md: "0.5rem" }}
                        pr={{ base: "", md: "0.5rem" }}
                        gap={{ base: "", md: "9" }}
                      >
                        <Box flex="1">
                          <Stack spacing={2}>
                            <PosLable
                              name={"Business Name"}
                              requiredLabel={true}
                              fontSize="0.94rem"
                              fontStyle="normal"
                              fontWeight={500}
                              lineHeight="normal"
                              letterSpacing="-0.02rem"
                            />
                            <PosInput
                              id="businessname"
                              mt={"1rem"}
                              placeholder={"Name"}
                              color={Colors.posCommon}
                              handleInputChange={formik.handleChange}
                              inputValue={formik.values.businessname || ""}
                              // handleBlur={formik.handleBlur}
                              handleBlur={(e) => {
                                formik.setFieldValue(
                                  "businessname",
                                  formik.values.businessname?.trim()
                                );
                                formik.handleBlur(e);
                              }}
                              onKeyDown={handleKeyDown}
                              posInputGroup={true}
                              inputType={"text"}
                              inputError={
                                formik.touched.businessname &&
                                formik.errors.businessname
                              }
                            >
                              <DriveFileRenameOutlineIcon
                                style={{ fill: "#5881FE" }}
                              />
                            </PosInput>
                            <Text color={Colors.posCancleButtonRed}>
                              {formik.touched.businessname &&
                              formik.errors.businessname ? (
                                <span>{formik.errors.businessname}</span>
                              ) : null}
                            </Text>
                          </Stack>
                        </Box>
                        <Box flex="1">
                          <Stack spacing={2}>
                            <PosLable
                              name={"Phone Number"}
                              requiredLabel={true}
                              fontSize="0.94rem"
                              fontStyle="normal"
                              fontWeight={500}
                              lineHeight="normal"
                              letterSpacing="-0.02rem"
                            />
                            <PosInput
                              id="businessphone"
                              mt={"1rem"}
                              placeholder={"Phone"}
                              color={Colors.posCommon}
                              handleInputChange={formik.handleChange}
                              inputValue={formik.values.businessphone}
                              // handleBlur={formik.handleBlur}
                              handleBlur={(e) => {
                                formik.setFieldValue(
                                  "businessphone",
                                  formik.values.businessphone?.trim()
                                );
                                formik.handleBlur(e);
                              }}
                              onKeyDown={handleKeyDown}
                              posInputGroup={true}
                              inputType={"text"}
                              inputError={
                                formik.touched.businessphone &&
                                formik.errors.businessphone
                              }
                            >
                              <PhoneIcon
                                color={Colors.posNavbarLink}
                                style={{ fill: "#5881FE" }}
                              />
                            </PosInput>
                            <Text color={Colors.posCancleButtonRed}>
                              {formik.touched.businessphone &&
                              formik.errors.businessphone ? (
                                <span>{formik.errors.businessphone}</span>
                              ) : null}
                            </Text>
                          </Stack>
                        </Box>
                      </Flex>

                      <Flex
                        pl={{ base: "", md: "0.5rem" }}
                        pr={{ base: "", md: "0.5rem" }}
                        spacing={{ base: "", md: 2 }}
                      >
                        <Box flex="1">
                          <Stack spacing={2}>
                            <PosLable
                              name={"Address Line 1"}
                              requiredLabel={true}
                              fontStyle="normal"
                              fontSize="0.94rem"
                              fontWeight={500}
                              lineHeight="normal"
                              letterSpacing="-0.02rem"
                            />
                            <PosInput
                              id="businessaddress"
                              mt={"1rem"}
                              placeholder={"Enter Address Line 1"}
                              color={Colors.posCommon}
                              handleInputChange={formik.handleChange}
                              inputValue={formik.values.businessaddress}
                              // handleBlur={formik.handleBlur}
                              handleBlur={(e) => {
                                formik.setFieldValue(
                                  "businessaddress",
                                  formik.values.businessaddress?.trim()
                                );
                                formik.handleBlur(e);
                              }}
                              onKeyDown={handleKeyDown}
                              posInputGroup={true}
                              inputType={"text"}
                              inputError={
                                formik.touched.businessaddress &&
                                formik.errors.businessaddress
                              }
                            >
                              <DriveFileRenameOutlineIcon
                                style={{ fill: "#5881FE" }}
                              />
                            </PosInput>
                            <Text color={Colors.posCancleButtonRed}>
                              {formik.touched.businessaddress &&
                              formik.errors.businessaddress ? (
                                <span>{formik.errors.businessaddress}</span>
                              ) : null}
                            </Text>
                          </Stack>
                        </Box>
                      </Flex>

                      <Flex
                        direction={{ base: "column", md: "row" }}
                        alignItems={{ base: "", md: "center" }}
                        pl={{ base: "", md: "0.5rem" }}
                        pr={{ base: "", md: "0.5rem" }}
                        gap={{ base: "", md: "9" }}
                      >
                        <Box flex="1">
                          <Stack spacing={2}>
                            <PosLable
                              name={"City"}
                              requiredLabel={true}
                              fontStyle="normal"
                              fontSize="0.94rem"
                              fontWeight={500}
                              lineHeight="normal"
                              letterSpacing="-0.02rem"
                            />
                            <PosInput
                              id="businesscity"
                              mt={"1rem"}
                              placeholder={"city"}
                              color={Colors.posCommon}
                              handleInputChange={formik.handleChange}
                              inputValue={formik.values.businesscity}
                              // handleBlur={formik.handleBlur}
                              handleBlur={(e) => {
                                formik.setFieldValue(
                                  "businesscity",
                                  formik.values.businesscity?.trim()
                                );
                                formik.handleBlur(e);
                              }}
                              onKeyDown={handleKeyDown}
                              posInputGroup={true}
                              inputType={"text"}
                              inputError={
                                formik.touched.businesscity &&
                                formik.errors.businesscity
                              }
                            >
                              <DriveFileRenameOutlineIcon
                                style={{ fill: "#5881FE" }}
                              />
                            </PosInput>
                            <Text color={Colors.posCancleButtonRed}>
                              {formik.touched.businesscity &&
                              formik.errors.businesscity ? (
                                <span>{formik.errors.businesscity}</span>
                              ) : null}
                            </Text>
                          </Stack>
                        </Box>
                        <Box flex="1">
                          <Stack spacing={2}>
                            <PosLable
                              name={"Zip Code"}
                              requiredLabel={true}
                              fontStyle="normal"
                              fontSize="0.94rem"
                              fontWeight={500}
                              lineHeight="normal"
                              letterSpacing="-0.02rem"
                            />
                            <PosInput
                              id="businesszip"
                              mt={"1rem"}
                              placeholder={"zipCode"}
                              color={Colors.posCommon}
                              handleInputChange={formik.handleChange}
                              inputValue={formik.values.businesszip}
                              // handleBlur={formik.handleBlur}
                              handleBlur={(e) => {
                                formik.setFieldValue(
                                  "businesszip",
                                  formik.values.businesszip?.trim()
                                );
                                formik.handleBlur(e);
                              }}
                              onKeyDown={handleKeyDown}
                              posInputGroup={true}
                              inputType={"text"}
                              inputError={
                                formik.touched.businesszip &&
                                formik.errors.businesszip
                              }
                            >
                              <DriveFileRenameOutlineIcon
                                style={{ fill: "#5881FE" }}
                              />
                            </PosInput>
                            <Text color={Colors.posCancleButtonRed}>
                              {formik.touched.businesszip &&
                              formik.errors.businesszip ? (
                                <span>{formik.errors.businesszip}</span>
                              ) : null}
                            </Text>
                          </Stack>
                        </Box>
                      </Flex>
                    </>
                  ) : (
                    // <>
                    //   {loading ? (
                    //     <PosProgress />
                    //   ) : (
                    <>
                      {" "}
                      {/* <Flex
                        direction={{ base: "column", md: "row" }}
                        alignItems={{ base: "flex-start", md: "center" }}
                        justify="space-between"
                      // pb={8}
                      >

                        <PosListHeader
                          header={"Billing Information"}
                          discription={""}
                        />

                        <Spacer />
                        <ButtonGroup gap="2" alignSelf={"center"}>
                          <PosOutlineButton
                            onClick={() => {
                              handleEditButtonClick();
                            }}
                            name="Edit"
                            width={"4.63rem"}
                          ></PosOutlineButton>
                        </ButtonGroup>
                      </Flex> */}
                      <Flex
                        direction={{
                          base: "column",
                          sm: "column",
                          md: "column",
                          lg: "row",
                          xl: "row",
                        }}
                        alignItems={"flex-start"}
                        justify="space-between"
                        // pb={16}
                      >
                        <PosListHeader
                          header={"Billing Information"}
                          discription={""}
                        />
                        <Spacer />
                        <ButtonGroup
                          direction={"row"}
                          alignSelf={{ base: "flex-end", md: "center" }}
                        >
                          <PosOutlineButton
                            onClick={() => {
                              handleEditButtonClick();
                            }}
                            name="Edit"
                            width={"4.63rem"}
                          />
                        </ButtonGroup>
                      </Flex>
                      <Box
                        p="0.5rem"
                        style={{
                          color: "rgba(1, 0, 72, 0.64)",
                          fontWeight: 300,
                          fontSize: "0.93rem",
                        }}
                      >
                        <Text>
                          {null != formik.values.businessname &&
                          "" != formik.values.businessname
                            ? formik.values.businessname
                            : ""}
                        </Text>
                        <Text>
                          {null != formik.values.businessaddress &&
                          "" != formik.values.businessaddress
                            ? formik.values.businessaddress + " "
                            : ""}
                          {null != formik.values.businesscity &&
                          "" != formik.values.businesscity
                            ? formik.values.businesscity + " "
                            : ""}
                          {null != formik.values.businessstate &&
                          "" != formik.values.businessstate
                            ? formik.values.businessstate + " "
                            : ""}
                          {null != formik.values.businesscountry &&
                          "" != formik.values.businesscountry
                            ? formik.values.businesscountry + " "
                            : ""}
                          {null != formik.values.businesszip &&
                          "" != formik.values.businesszip
                            ? formik.values.businesszip + " "
                            : ""}
                        </Text>
                        <Text>
                          {null != formik.values.businessphone &&
                          "" != formik.values.businessphone
                            ? formik.values.businessphone + " "
                            : ""}
                        </Text>
                      </Box>
                    </>
                    //   )}
                    // </>
                  )}
                </Box>

                <Box
                  mb={"1.13rem"}
                  p={"2.06rem"}
                  borderRadius="0.63rem"
                  bg={"#FAFAFA"}
                  // boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
                  filter={"drop-shadow(0px 4px 20px rgba(90, 90, 90, 0.04))"}
                >
                  <Flex
                    direction={{
                      base: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                      xl: "row",
                    }}
                    alignItems={"flex-start"}
                    justify="space-between"
                    // pb={16}
                  >
                    <PosListHeader
                      header={"Payment Method"}
                      discription={
                        "This Payment method will be used to charge your purchases"
                      }
                    />
                    <Spacer />
                    <ButtonGroup
                      direction={"row"}
                      alignSelf={{ base: "flex-end", md: "center" }}
                    >
                      <PosOutlineButton
                        onClick={() => {
                          addCardPopUpOpen();
                        }}
                        name="Add New Card"
                        width={"10rem"}
                      />
                    </ButtonGroup>
                  </Flex>
                  {cardLoading ? (
                    <PosProgress />
                  ) : (
                    <Stack
                      p={{ base: "0rem", md: "0.5rem" }}
                      mt={{ base: "0.5rem", md: "0rem" }}
                    >
                      {customerCards?.length > 0
                        ? customerCards?.map((item, index) => {
                            return (
                              <Card
                                key={index}
                                // maxW='md'
                                position="relative"
                                bg={"#FAFAFA"}
                                borderRadius={"0.63rem"}
                                boxShadow={
                                  "0px 6px 23.6px 0px rgba(88, 129, 254, 0.10)"
                                }
                                border={"0.7px solid rgba(88, 129, 254, 0.20)"}
                                w={{ base: "100%", md: "22.44rem" }}
                                // height={"4.38rem"}
                                // px={"0.63rem"}
                                // py={"1.31rem"}
                                // flex-direction= {"column"}
                              >
                                <CardHeader>
                                  <Flex spacing="4">
                                    <Flex
                                      flex="1"
                                      gap="4"
                                      alignItems="center"
                                      flexWrap="wrap"
                                    >
                                      <Avatar
                                        name="card logo"
                                        src={item.cardLogo}
                                      />

                                      <Box>
                                        <Heading
                                          color={"#010048"}
                                          fontSize={"0.94rem"}
                                          fontWeight={400}
                                        >
                                          **** ***** **** {item.last4}{" "}
                                        </Heading>
                                        <Text
                                          color={"#010048"}
                                          fontSize={"0.94rem"}
                                          fontWeight={400}
                                        >
                                          Expiry {item.card_exp_month}/
                                          {item.card_exp_year}{" "}
                                        </Text>
                                      </Box>
                                    </Flex>
                                    {customerCards?.length > 1 && index >= 1 ? (
                                      <Box
                                        position={{ base: "", md: "absolute" }}
                                        top="0.5rem"
                                        right="1.5rem"
                                      >
                                        <Menu>
                                          <MenuButton>...</MenuButton>
                                          <MenuList>
                                            <MenuItem
                                              onClick={() =>
                                                cardMakeAsDefault(item.id)
                                              }
                                            >
                                              Set Default Card
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                onRemove(item.id, item)
                                              }
                                            >
                                              {" "}
                                              Remove Card
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </Box>
                                    ) : null}
                                  </Flex>
                                </CardHeader>
                              </Card>
                            );
                          })
                        : null}

                      {customerCards?.length == 1 ? (
                        <Text color={"red"}>
                          Note : At-least one card is required in the system. To
                          remove this card add another card and set it default
                          card.
                        </Text>
                      ) : null}

                      <Modal
                        isOpen={isAddCardOpen}
                        onClose={closecardModel}
                        isCentered
                        size="2xl"
                        width="50rem"
                        height="80vh"
                        closeOnOverlayClick={false}
                      >
                        <ModalOverlay />
                        <ModalContent
                          style={{
                            paddingLeft: "4rem",
                            paddingRight: "4rem",
                            paddingTop: "4rem",
                            paddingBottom: "5.5rem",
                          }}
                        >
                          {null != stripeApiKey && "" != stripeApiKey ? (
                            <Elements stripe={loadStripe(stripeApiKey)}>
                              <CheckoutForm
                                handleChange={(data) => handleChange(data)}
                                showInput={displayHostNameAndEmailField}
                                paymentCard={customerCards.length}
                              />
                              <ModalCloseButton />
                            </Elements>
                          ) : null}
                        </ModalContent>
                      </Modal>
                    </Stack>
                  )}
                </Box>
                <Box
                  mb={"1.13rem"}
                  p={"2.06rem"}
                  borderRadius="0.63rem"
                  bg={"#FAFAFA"}
                  // boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
                  filter={"drop-shadow(0px 4px 20px rgba(90, 90, 90, 0.04))"}
                >
                  <Flex
                    direction={{
                      base: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                      xl: "row",
                    }}
                    alignItems={"flex-start"}
                    justify="space-between"
                  >
                    {selectedSmsPlan?.length > 0 ? (
                      <PosListHeader
                        header={"SMS/ Email Plan"}
                        discription={""}
                      />
                    ) : (
                      <PosListHeader
                        header={"SMS/ Email Plan"}
                        discription={"You have not selected any SMS/Email Plan"}
                      />
                    )}
                    <Spacer />
                    {noBillingSMSDetailsExist == 1 ? (
                      <ButtonGroup
                        direction={"row"}
                        alignSelf={{ base: "flex-end", md: "center" }}
                      >
                        <PosOutlineButton
                          onClick={() => {
                            updateSmsPlanPopUp();
                          }}
                          name="Select SMS / Email Plan"
                          width={"12rem"}
                        ></PosOutlineButton>
                      </ButtonGroup>
                    ) : (
                      <ButtonGroup
                        direction={"row"}
                        alignSelf={{ base: "flex-end", md: "center" }}
                      >
                        <PosOutlineButton
                          onClick={() => {
                            updateSmsPlanPopUp();
                          }}
                          name="Update SMS / Email Plan"
                          width={"12rem"}
                        ></PosOutlineButton>
                      </ButtonGroup>
                    )}
                    {noBillingSMSDetailsExist == 0 && cancelPlan == 0 ? (
                      <ButtonGroup
                        ml={{ base: "0", md: "2" }}
                        mt={{ base: "2", md: "0" }}
                        direction={"row"}
                        alignSelf={{ base: "flex-end", md: "center" }}
                      >
                        <PosOutlineButton
                          onClick={() => {
                            // cancelPlanMethod();
                            onRemoveSmsPlanMethod();
                          }}
                          name="Cancel SMS / Email Plan"
                          width={"12rem"}
                        ></PosOutlineButton>
                      </ButtonGroup>
                    ) : null}
                  </Flex>
                  <Stack
                    p={{ base: "0rem", md: "0.5rem" }}
                    mt={{ base: "0.5rem", md: "0rem" }}
                  >
                    {loading ? (
                      <PosProgress />
                    ) : selectedSmsPlan?.length > 0 ? (
                      <Box>
                        <TableContainer>
                          <Table>
                            <Thead
                              style={{
                                fontSize: "1rem",
                                // height: "4rem",
                                backgroundColor: "#5881fe1a",
                                fontWeight: "300",
                                color: "#38128A",
                              }}
                            >
                              <Tr
                                style={{
                                  backgroundColor: "#5881fe1a",
                                  fontWeight: "500",
                                  color: "#38128A",
                                }}
                              >
                                <Th>
                                  <Text textTransform="capitalize">
                                    Plan Name
                                  </Text>
                                </Th>
                                <Th>
                                  <Text textTransform="capitalize">
                                    No of SMS
                                  </Text>
                                </Th>
                                <Th>
                                  <Text textTransform="capitalize">
                                    Price($)
                                  </Text>
                                </Th>
                                <Th>
                                  <Text textTransform="capitalize">Note</Text>
                                </Th>

                                {/* <Th>No of SMS</Th>
                                <Th>Price</Th>
                                <Th>Note</Th> */}
                              </Tr>
                            </Thead>
                            <Tbody
                              style={{
                                color: "#010048",
                                fontSize: "0.93rem",
                                fontStyle: "normal",
                                fontWeight: "300",
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                width: "30%",
                              }}
                            >
                              <Tr>
                                <Td>{selectedSmsPlan[0].name}</Td>
                                <Td>{selectedSmsPlan[0].no_of_sms}</Td>
                                <Td>${selectedSmsPlan[0].price}</Td>
                                <Td>Current Month Plan</Td>
                              </Tr>
                            </Tbody>
                            {selectedSMSPlanForNextMonth?.length > 0 ? (
                              <Tbody
                                style={{
                                  color: "#010048",
                                  fontSize: "0.93rem",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                  width: "30%",
                                }}
                              >
                                <Tr>
                                  <Td>{selectedSMSPlanForNextMonth[0].name}</Td>
                                  <Td>
                                    {selectedSMSPlanForNextMonth[0].no_of_sms}
                                  </Td>
                                  <Td>
                                    ${selectedSMSPlanForNextMonth[0].price}
                                  </Td>
                                  <Td>
                                    This plan will be applicable from Next Month
                                  </Td>
                                </Tr>
                              </Tbody>
                            ) : null}
                          </Table>
                        </TableContainer>
                        {null != perSMSCharge &&
                        "" != perSMSCharge &&
                        cancelPlan == 0 ? (
                          <Text
                            pt={3}
                            float={"left"}
                            color={"navy"}
                            fontSize={"1rem"}
                          >
                            <b>
                              The additional SMS Charge would be $
                              {perSMSCharge ? perSMSCharge : null}, There are no
                              additional charges for Email.
                            </b>
                          </Text>
                        ) : null}
                      </Box>
                    ) : null}

                    <Flex
                      direction={{ base: "column", md: "row" }}
                      alignItems={{ base: "flex-start", md: "center" }}
                      justify="space-between"
                    >
                      {/* {noBillingSMSDetailsExist == 1 ? (
                        <ButtonGroup gap="2" alignSelf={"flex-end"}>
                          <PosOutlineButton
                            onClick={() => {
                              updateSmsPlanPopUp();
                            }}
                            name="Select SMS / Email Plan"
                            width={"12rem"}
                          ></PosOutlineButton>
                        </ButtonGroup>
                      ) : (
                       
                          <PosOutlineButton
                            onClick={() => {
                              updateSmsPlanPopUp();
                            }}
                            name="update SMS / Email Plan"
                            width={"12rem"}
                          ></PosOutlineButton>
                       
                      )}
                      {cancelPlan == 0 ? (
                    
                          <PosOutlineButton
                            onClick={() => {
                              // cancelPlanMethod();
                              onRemoveSmsPlanMethod();
                            }}
                            name="Cancel SMS / Email Plan"
                            width={"12rem"}
                          ></PosOutlineButton>
                       

                      ) : null} */}
                    </Flex>
                  </Stack>
                  <Modal
                    isOpen={isSmsPlanModelOpen}
                    onClose={closeSmsPlanModel}
                    isCentered
                    size={"2xl"}
                    scrollBehavior={"inside"}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader pt={"2.31rem"}>
                        Choose SMS / Email Plan
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <PosTable
                          columnNames={smsPlanTableColumns}
                          rawdata={modifiedData}
                          totalcount={modifiedData?.length}
                          footerHide={true}
                          noAction={true}
                          sortColumnName={null}
                          page={null}
                          rowsPerPage={null}
                          order={null}
                        />
                        {smsLessPlanSelected == true ? (
                          <Box
                            style={{
                              wordBreak: "break-word !important",
                              color: "green",
                            }}
                            mt={{ base: "2rem", md: "2rem" }}
                            mb={{ base: "0.5rem", md: "0.5rem" }}
                          >
                            <Text fontWeight={600}>
                              Selected plan will be applicable from the next
                              month.
                            </Text>
                            {/* <PosAlert
                                            alertStatus={"success"}
                                            alertTitle={""}
                                            width={"100%"}
                                            alertDescription={"Selected plan will be applicable from the next month."}
                                        /> */}
                          </Box>
                        ) : null}
                        {smsGreaterPlanSelected == true ? (
                          <Box
                            mt={{ base: "2rem", md: "2rem" }}
                            mb={{ base: "0.5rem", md: "0.5rem" }}
                          >
                            <Checkbox
                              onChange={(e) =>
                                handleCheckboxChange(e.target.checked)
                              }
                              fontWeight={600}
                            >
                              <Text
                                color={applyToNextMonth ? "green" : "black"}
                              >
                                Do you want to apply new plan from the next
                                month?
                              </Text>
                            </Checkbox>
                            {/* <PosAlert
                                alertStatus={"success"}setApplyToNextGreaterCheckbox
                                alertTitle={""}
                                width={"100%"}
                                alertDescription={"Do you want to apply new plan from the next month?."}
                              /> */}
                          </Box>
                        ) : null}
                      </ModalBody>
                      <ModalFooter>
                        <PosFormButton
                          isDisabled={selectedPlanIndex > 0 ? false : true}
                          buttonsubmit={"Submit"}
                          SubmitButton={true}
                          onClick={submitPlan}
                        />
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </TabPanel>

              <TabPanel>
                <Box
                  mb={"1.13rem"}
                  p={"2.06rem"}
                  borderRadius="0.63rem"
                  bg={"#FAFAFA"}
                  // boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
                  filter={"drop-shadow(0px 4px 20px rgba(90, 90, 90, 0.04))"}
                >
                  <Flex
                    direction={{ base: "column", md: "row" }}
                    // alignItems={{ base: "flex-start", md: "center" }}
                    justify="space-between"
                    pb={8}
                  >
                    <PosListHeader
                      header={"Billing History"}
                      discription={"Review and track all transactions below"}
                    />
                  </Flex>
                  {loading ? (
                    <PosProgress />
                  ) : totalcount > 0 && curruntTab === 1 ? (
                    <PosDataGridTable
                      columns={columnNames1}
                      rows={billingHistoryModifiedData}
                      totalcount={totalcount}
                      // noAction={true}
                      columnVisibilityModel={olColumnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setOlColumnVisibilityModel(newModel)
                      }
                      paginationModel={paginationModel}
                      paginationMode="server"
                      onPaginationModelChange={setPaginationModel}
                      sortModel={sortModel}
                      onSortModelChange={(newSortModel) => {
                        sortRef.current = sortModel;
                        setSortModel(newSortModel);
                      }}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.BILLING_HISTORY_EXPORT_FILENAME}
                      radioTable={true}
                      sortColumnName={sortColumn}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      order={sortType}
                    />
                  ) : (
                    <PosNoDataFound
                      title={Constants.BILLING_HISTORY_NOT_FOUND}
                    />
                  )}
                  <Modal
                    isOpen={isBillingViewOpen}
                    onClose={closebillviewModel}
                    isCentered
                    size="6xl"
                    // width="50rem"
                    height="80vh"
                    // scrollBehavior={"inside"}
                    // closeOnOverlayClick={false}
                  >
                    <ModalOverlay />
                    <ModalContent
                      // style={{
                      //   paddingLeft: "4rem",
                      //   paddingRight: "4rem",
                      //   paddingTop: "4rem",
                      //   paddingBottom: "5.5rem",
                      // }}
                      p={10} // Adjust padding for mobile and desktop
                    >
                      <ModalCloseButton />
                      <Box style={{ border: "2px solid #ccc" }}>
                        <Flex
                          // style={{
                          //   paddingLeft: "0.64rem",
                          //   paddingRight: "0.64rem",
                          //   paddingTop: "3.44rem",
                          //   paddingBottom: "0.99rem",
                          // }}
                          p={{ base: 2, md: 4 }}
                          direction={{ base: "column", md: "row" }} // Column for mobile, row for desktop
                          justify={{ base: "flex-start", md: "space-between" }}
                          align={{ base: "flex-start", md: "center" }}
                        >
                          <Box
                            // display="flex"
                            // alignItems="left"
                            // justifyContent="left"
                            // flexDir="column"
                            display="flex"
                            flexDir="column"
                            mb={{ base: 4, md: 0 }}
                          >
                            <Image
                              style={{
                                width: "15.31rem",
                              }}
                              src={image}
                              alt="Octopos"
                            />
                          </Box>
                          <Text
                            // style={{
                            //   marginLeft: "auto",
                            //   fontSize: "2.19rem",
                            // }}
                            fontSize={{ base: "1.5rem", md: "2.19rem" }} // Adjust font size for mobile and desktop
                            ml={{ base: 0, md: "auto" }}
                          >
                            INVOICE
                          </Text>
                        </Flex>

                        <Flex
                          // style={{
                          //   display: "inline-flex",
                          //   justifyContent: "right",
                          // }}
                          justifyContent="space-between"
                          p={{ base: 2, md: 4 }}
                          direction={{ base: "column", md: "row" }}
                        >
                          <Stack spacing={2} mb={{ base: 4, md: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                              {selectedBillingDetails.billing_company_name}
                            </Text>
                            <Text>
                              {selectedBillingDetails.billing_company_address}
                            </Text>
                            <br />
                            <Text></Text>
                            <Text>
                              <strong>Billed To,</strong>
                              <br />
                              {selectedBillingDetails.business_name}
                            </Text>
                            <Text>
                              {selectedBillingDetails.business_address}
                            </Text>
                            <Text>{selectedBillingDetails.business_phone}</Text>
                            <Text>{selectedBillingDetails.business_email}</Text>
                          </Stack>

                          <br />
                          <Stack
                            spacing={2}
                            textAlign={{ base: "left", md: "right" }}
                          >
                            <Text>
                              <strong>Invoice#:</strong>{" "}
                              {selectedBillingDetails.invoice_number}
                            </Text>
                            <Text>
                              <strong>Period:</strong>{" "}
                              {selectedBillingDetails.paid_on &&
                                new Date(selectedBillingDetails.paid_on)
                                  .toLocaleDateString("en-US", {
                                    month: "long",
                                    year: "numeric",
                                  })
                                  .replace(/ /g, "-")}
                            </Text>
                            {selectedBillingDetails &&
                              selectedBillingDetails.stripe_paid_amount !==
                                undefined && (
                                <Text>
                                  <strong>Amount:</strong> $
                                  {selectedBillingDetails.stripe_paid_amount.toFixed(
                                    2
                                  )}
                                </Text>
                              )}

                            {selectedBillingDetails.payment_status_success ==
                              1 && (
                              <Text>
                                <strong>Status:</strong>{" "}
                                <span
                                  style={{
                                    color: "green",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {selectedBillingDetails.payment_status}
                                </span>
                              </Text>
                            )}
                            {selectedBillingDetails.payment_status_fail ==
                              1 && (
                              <Text>
                                <strong>Status:</strong>
                                <span
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {selectedBillingDetails.payment_status}
                                </span>
                              </Text>
                            )}
                            {selectedBillingDetails.payment_status_partially ==
                              1 && (
                              <Text>
                                <strong>Status:</strong>
                                <Text
                                  style={{
                                    color: "Orange",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {selectedBillingDetails.payment_status}
                                </Text>
                              </Text>
                            )}
                            {selectedBillingDetails.payment_status_partially !=
                              1 && (
                              <Text>
                                <strong>Paid On:</strong>{" "}
                                {selectedBillingDetails.paid_on}
                              </Text>
                            )}
                          </Stack>
                        </Flex>

                        {/* <Flex
                          direction={{ base: "column", md: "row" }}
                          alignItems={{ base: "flex-start", md: "center" }}
                          justify="space-between"
                          pb={8}
                        ></Flex> */}

                        <Flex
                          direction={{ base: "column", md: "row" }}
                          alignItems={{ base: "flex-start", md: "center" }}
                          justify="space-between"
                          pb={8}
                        >
                          <TableContainer style={{ width: "100%" }}>
                            <Table variant="simple">
                              <Thead>
                                <Tr
                                  sx={{
                                    backgroundColor: Colors.modifierTable,
                                  }}
                                >
                                  <Th style={{ fontWeight: "bold" }}>
                                    Description
                                  </Th>
                                  <Th
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "right",
                                    }}
                                  >
                                    Amount($)
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {selectedBillingDetails.total_plan_charges && (
                                  <Tr>
                                    <Td>
                                      {selectedBillingDetails.numOfTotalPlanArrays ===
                                      1
                                        ? "Plan Charges"
                                        : "Plan Charges(Prorated)"}
                                    </Td>
                                    <Td textAlign="right">
                                      $
                                      {selectedBillingDetails.total_plan_charges.toFixed(
                                        2
                                      )}
                                    </Td>
                                  </Tr>
                                )}
                                {selectedBillingDetails.total_hardware_charges && (
                                  <Tr>
                                    <Td>
                                      {selectedBillingDetails.numOfTotalHardwareArrays ===
                                      1
                                        ? "Hardware Charges"
                                        : "Hardware Charges(Prorated)"}
                                    </Td>
                                    <Td textAlign="right">
                                      $
                                      {selectedBillingDetails.total_hardware_charges.toFixed(
                                        2
                                      )}
                                    </Td>
                                  </Tr>
                                )}
                                {selectedBillingDetails.total_sms_charges && (
                                  <Tr>
                                    <Td>
                                      {selectedBillingDetails.numOfTotalSMSArrays ===
                                      1
                                        ? "SMS / Email Plan Charges"
                                        : "SMS / Email Plan & Additional SMS / Email Plan Charges"}
                                    </Td>
                                    <Td textAlign="right">
                                      $
                                      {selectedBillingDetails.total_sms_charges.toFixed(
                                        2
                                      )}
                                    </Td>
                                  </Tr>
                                )}
                                {selectedBillingDetails.total_per_sms_charges && (
                                  <Tr>
                                    <Td>
                                      Per SMS / Email Plan Charges (No:{" "}
                                      {selectedBillingDetails.total_no_of_sms})
                                    </Td>
                                    <Td textAlign="right">
                                      $
                                      {selectedBillingDetails.total_per_sms_charges.toFixed(
                                        2
                                      )}
                                    </Td>
                                  </Tr>
                                )}
                                {selectedBillingDetails.total_printer_charges && (
                                  <Tr>
                                    <Td>Printer Charges</Td>
                                    <Td className="text-right">
                                      $
                                      {selectedBillingDetails.total_printer_charges.toFixed(
                                        2
                                      )}
                                    </Td>
                                  </Tr>
                                )}

                                {selectedBillingDetails &&
                                  selectedBillingDetails.stripe_paid_amount !==
                                    undefined && (
                                    <Tr
                                      style={{
                                        backgroundColor: Colors.modifierTable,
                                      }}
                                    >
                                      <Th style={{ fontWeight: "bold" }}>
                                        Total
                                      </Th>
                                      <Td
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "right",
                                        }}
                                      >
                                        $
                                        {selectedBillingDetails.stripe_paid_amount.toFixed(
                                          2
                                        )}
                                      </Td>
                                    </Tr>
                                  )}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Flex>

                        <Flex
                          direction={{ base: "column", md: "row" }}
                          alignItems={{ base: "flex-start", md: "center" }}
                          justify="space-between"
                        >
                          <TableContainer style={{ width: "100%" }}>
                            <Table>
                              <Thead
                                sx={{ backgroundColor: Colors.modifierTable }}
                              >
                                <Tr>
                                  <Th style={{ width: "100%" }}>
                                    Payment Details
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                <Tr>
                                  <Td>
                                    {selectedBillingDetails.customer_cards_details &&
                                      selectedBillingDetails.customer_cards_details.map(
                                        (card, index) => (
                                          <React.Fragment key={index}>
                                            <strong
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              ${card.card_stripe_paid_amount}
                                            </strong>{" "}
                                            on{" "}
                                            <Text
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              {new Date(
                                                selectedBillingDetails.paid_on
                                              ).toLocaleDateString()}
                                            </Text>{" "}
                                            via{" "}
                                            <Text
                                              style={{
                                                display: "inline-block",
                                                alignItems: "end",
                                                justifyContent: "end",
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <Image
                                                boxSize="30px"
                                                src={card.cardLogo}
                                                alt="Card Logo"
                                                className="card-logo"
                                              />
                                            </Text>{" "}
                                            ending with{" "}
                                            <Text
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              {card.last4}
                                            </Text>
                                            {index !==
                                              selectedBillingDetails
                                                .customer_cards_details.length -
                                                1 && (
                                              <>
                                                <br />
                                                <br />
                                              </>
                                            )}{" "}
                                          </React.Fragment>
                                        )
                                      )}
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>
                        </Flex>
                      </Box>
                    </ModalContent>
                  </Modal>
                </Box>
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      ) : (
        <PosNoDataFound title={Constants.BILLING_LIST_DATA_NOT_FOUND} />
      )}
    </Box>
  );
};

export default WithRouter(BillingList);
