import {
  Alert, AlertDescription,
  Image
} from "@chakra-ui/react";

const PosAlert = (props) => {
  return (
    <Alert
      width={props.width ? props.width : { base: "21.88rem", lg: "34.2rem" }}
      height={{base:20, md:14}}
      bg={props.bg} //props
      status={props.alertStatus}
    >
      {props.image &&
        <Image boxSize={4} src={props.image} alt="Octopos" />
      }
      <AlertDescription
        fontSize={"1rem"}
        fontWeight={300}
        color={props.color}
        pl={3}
      >
        {props.alertDescription}
      </AlertDescription>
    </Alert>
  );
};

export default PosAlert;
