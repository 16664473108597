import axios from "axios";
import * as Constants from "../../../constants/Constants";
import { refreshMasterAdminToken } from "../MasterAdminService";

export function getAllRolesMasterAdmin(payload) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    await axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllPermissionsMasterAdmin() {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    await axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_GET_ALL_PERMISSION,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function roleCreateMasterAdmin({ request }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    let formData = await request.formData();
    const payload = {
      role: JSON.parse(Object.fromEntries(formData).role),
      permission: JSON.parse(Object.fromEntries(formData).permission),
    };
    await axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function roleUpdateMasterAdmin({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      role: JSON.parse(Object.fromEntries(formData).role),
      permission: JSON.parse(Object.fromEntries(formData).permission),
    };
    await axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_UPDATE_API_ROUTE +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getEditRoleByIdMasterAdmin({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    const id = params.id;
    let token = localStorage.getItem("master-token");
    await axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.ROLE_EDIT_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function roleDeactiveReactiveMasterAdmin({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      await axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.ROLE_DEACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.ROLE_DEACTIVE_LIST_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      await axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.ROLE_REACTIVE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.ROLE_REACTIVE_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllDeactiveRoleMasterAdmin(payload) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    await axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ROLE_DEACTIVE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.ROLE_DEACTIVE_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
