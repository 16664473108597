import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function getLocationes({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_ALL_LOCATIONS_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.REPORT_PRODUCT_NOT_FOUND_BARCODES_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getProductNotFoundBarcodeDetails({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).getreport === "true") {
      const payload = JSON.stringify({
        dateFrom: Object.fromEntries(formData).dateFrom,
        dateTo: Object.fromEntries(formData).dateTo,
        location: JSON.parse(Object.fromEntries(formData).location),
        query: JSON.parse(Object.fromEntries(formData).query),
        zone: Object.fromEntries(formData).zone,
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PRODUCT_NOT_FOUND_BARCODE_DATA_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.REPORT_PRODUCT_NOT_FOUND_BARCODES_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).exportReport === "true") {
      const payload = JSON.stringify({
        location: JSON.parse(Object.fromEntries(formData).location),
        dateFrom: Object.fromEntries(formData).dateFrom,
        dateTo: Object.fromEntries(formData).dateTo,
        query: JSON.parse(Object.fromEntries(formData).query),
        zone: Object.fromEntries(formData).zone,
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PRODUCT_NOT_FOUND_BARCODE_EXPORT_DATA_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.REPORT_PRODUCT_NOT_FOUND_BARCODES_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).delete === "true") {
      const payload = JSON.stringify({
        delete_all: parseInt(Object.fromEntries(formData).delete_all),
        barcode: Object.fromEntries(formData).barcode,
        location_id: Object.fromEntries(formData).location_id,
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PRODUCT_NOT_FOUND_BARCODE_DELETE_DATA,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.REPORT_PRODUCT_NOT_FOUND_BARCODES_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function handleProductDelete(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PRODUCT_NOT_FOUND_BARCODE_DELETE_DATA,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.REPORT_PRODUCT_NOT_FOUND_BARCODES_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
