import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useSubmit, useLocation } from "react-router-dom";
import {
  AppContext,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTable,
  PosTostMessage,
  PosDataGridTable,
  PosSpliteButton
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllDeactiveRoleMasterAdmin, getAllRolesMasterAdmin } from "./MasterAdminRoleService";
import { GridToolbar } from "@mui/x-data-grid";
import Colors from "../../../assets/colors/Colors";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

const MasterAdminRoleList = (props) => {
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH);
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [roleData, setRoleData] = useState([]);
  const [roleDeactiveData, setRoleDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [sortType, setSortType] = useState("desc");
  // const [sortColumn, setSortColumn] = useState("id");
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
  });
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(false);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width:90
    },
    {
      headerName: "Role Name",
      field: "name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      headerName: "Display Name",
      field: "display_name",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "action",
      headerName: "Actions",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width:150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            filter: searchQuery,
          }
          props.navigate(`${"/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_ROLE_EDIT+ item.id}`,{
            state: data,
          });
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if(modifiedData.length == 1 && paginationModel.page != 0){
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.VENDOR_LIST,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton:buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const deactivatedColumnNames = [
    {
      headerName: "ID",
      field: "id",
      sortable: true,
      width: 90
    },
    {
      headerName: "Role Name",
      field: "name",
      sortable: true,
      ...actionColumnFlexDouble
    },
    {
      headerName: "Display Name",
      field: "display_name",
      sortable: true,
      ...actionColumn,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "action",
      headerName: "Actions",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width:150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisable(true);
          if(deactiveRoleData.length == 1 && paginationModelDeactive.page != 0){
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.ROLE_LIST,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            // textDecoration="underline"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    }
  ];
  const stateData = useLocation(); // get backraking data as param
  const [paginationModel, setPaginationModel] = useState({
    page: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page-1:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page: stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:  undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const [sortModel, setSortModel] = useState([
    {
      field: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"id",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"desc",
    },
  ]);
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "id"
  );
  const sortRef = useRef(sortModel);
  const sortRefDeactive = useRef(sortModelDeactive);
  // const columnNames = ["ID", "Name", "Type", "Rate", "Exempt EBT", "Actions"];
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const effectRun = useRef(true);

  // const [deactiveId, setDeactiveId] = useState("");
  // const [rawId, setRawId] = useState("");

  // const handleDeactive = (item) => {
  //   myContext.handleLoading(true);
  //   let data = {
  //     id: item.id,
  //     type: Constants.DEACTIVE,
  //   };
  //   submit(data, {
  //     method: Constants.DELETE,
  //     path: Constants.ROLE_LIST,
  //   });
  // };

  const handlePageChange = (newPage) => {
    effectRun.current = true;
    setPage(newPage);
    setLoading(true);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    effectRun.current = true;
    setRowsPerPage(newRowsPerPage);
    setLoading(true);
  };
  const handleSortColumn = (column, sort) => {
    setLoading(true);
    setSortColumn(column.headerName);
    effectRun.current = true;
    setSortType(sort);
    // fetchData(column.headerName);
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllRolesMasterAdmin(data)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            setRoleData(response?.data.data.data);
            setTotalCount(response?.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setRoleData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setRoleData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setRoleData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveRoleData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].field : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].sort : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveRoleMasterAdmin(data)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            setRoleDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setRoleDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          setRoleDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setRoleDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveRoleData();
        // return () => {
        // };
      }
      effectRun.current = false;
    }
  } ,[paginationModel,sortModel,page,loading,searchQuery,paginationModelDeactive,sortModelDeactive]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.ROLE_MODULE,
            alertDescription: Constants.ROLE_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveRoleData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.ROLE_MODULE,
            alertDescription: Constants.ROLE_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
      // else {
      //   let actionData = actionResponse;
      //   error({ actionData });
      // }
    }
  }, [actionResponse]);

  // const handleEditPage = (item) => {
  //   //TODO
  //   // setTopLoading(true);
  //   myContext.handleLoading(true);
  //   props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_ROLE_EDIT+ item.id );
  // };

  // const handleReactive = (id) => {
  //   myContext.handleLoading(true);
  //   let data = {
  //     id: id,
  //     type: Constants.REACTIVE,
  //   };
  //   submit(data, {
  //     method: Constants.POST,
  //     path: Constants.ROLE_LIST,
  //   });
  // };


  const handleSortType = (order) => {
    setSortType(order);
  };
  const handleTabChange = (index) => {
    // tab change time set currunt tab and pagination related clear all data
    setCurruntTab(index);
    setLoading(true);
    setSearchQuery("");
    setPaginationModelDeactive({
      page: 0,
      pageSize:25,
    });
    setSortModelDeactive([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize:25,
    });
    setSortModel([
      {
        field:"id",
        sort: "desc",
      },
    ]);
    effectRun.current = true;
    if(index == 0){
      deactiveSearchHideRef.current = false;
    }else{
      searchInputHideRef.current = false;
    }
  };

  const handleSearchList = () => {
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_ROLES_PATH);
    paginationModel.page = 0;
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveRoleData();
    }
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("")
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_ROLES_PATH);
  }
  const modifiedData = roleData.map((role) => ({
    ...role,
    description:
      role.description == null || role.description == ""
        ? "N.A"
        : role.description,
  }));

  const deactiveRoleData = roleDeactiveData.map((role) => ({
    ...role,
    description:
      role.description == null || role.description == ""
        ? "N.A"
        : role.description,
  }));
  return (
    // <MainLayout loading={topLoading}>
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Roles"}
          discription={
            "Manage Team members account permissions and roles here"
          }
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
                  filter: curruntTab == 1 ? "" : searchQuery,
              }
              props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_ROLE_CREATE,{
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =searchQuery.length > 0 ? 
                e.target.value: e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Spacer />
          </Flex> */}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPaginationModel) => {
                  effectRun.current = true;
                  setPaginationModel(newPaginationModel);
                }}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                slots={{
                  toolbar: GridToolbar,
                }}
                columnVisibilityModel={olColumnVisibilityModel}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  sortRef.current = sortModel;
                  effectRun.current = true;
                  setSortModel(newSortModel);
                }}
                fileName={Constants.ACTIVE_ROLES_EXPORT_FILENAME}
              />
              ) : (
                <PosNoDataFound title={Constants.ACTIVE_ROLE_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                columns={deactivatedColumnNames}
                rows={deactiveRoleData}
                totalcount={deactiveTotalCount}
                paginationModel={paginationModelDeactive}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPaginationModelDeactive) => {
                  effectRun.current = true;
                  setPaginationModelDeactive(newPaginationModelDeactive);
                }}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                slots={{
                  toolbar: GridToolbar,
                }}
                columnVisibilityModel={olColumnVisibilityModel}
                sortModel={sortModelDeactive}
                onSortModelChange={(newSortModelDeactive) => {
                  sortRefDeactive.current = sortModelDeactive;
                  effectRun.current = true;
                  setSortModelDeactive(newSortModelDeactive);
                }}
                fileName={Constants.DEACTIVE_ROLES_EXPORT_FILENAME}
              />
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_ROLE_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
    // </MainLayout>
  );
};
export default WithRouter(MasterAdminRoleList);
