import {
  Avatar,
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text, useMediaQuery, VStack
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CgDetailsMore } from "react-icons/cg";
import { CiUser } from "react-icons/ci";
import {
  Outlet,
  useLoaderData, useLocation, useNavigate
} from "react-router-dom";
import Colors from "../../assets/colors/Colors";
import billing from "../../assets/images/mdi_thunder.svg";
import logo from "../../assets/images/newcropped.png";
import { AppContext, PosMainProgress, Sidebar } from "../../components/index";
import * as Constants from "../../constants/Constants";
import { PosErrorHook } from "../../hooks/index";

const MainLayout = (props) => {
  const myContext = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userRoles, setUserRoles] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { error } = PosErrorHook();
  const navigate = useNavigate();
  const loaderResponse = useLoaderData();
  const location = useLocation();
  const effectRun = useRef(true);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          if (loaderResponse?.response === Constants.LOGOUT_PAGE) {
            navigate(Constants.LOGOUT_PAGE);
          } else {
            let actionData = loaderResponse;
            error({ actionData });
          }
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          var rolesname = "";
          if (
            undefined !== loaderResponse.data.data.userData &&
            null !== loaderResponse.data.data.userData
          ) {
            if (loaderResponse.data.data.userData.roles.length > 0) {
              loaderResponse.data.data.userData.roles.map((role, i) => {
                if (loaderResponse.data.data.userData.roles.length - 1 === i) {
                  rolesname = rolesname + role.display_name;
                } else {
                  rolesname = rolesname + role.display_name + ",";
                }
              });
            }
            let obj = {
              userName: loaderResponse.data.data.userData.name,
              userRoles: rolesname,
            };
            setUserName(loaderResponse.data.data.userData.name);
            setUserRoles(rolesname);
            myContext.handleUserData(obj);
          }
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, [loaderResponse]);

  const handleClick = (open) => {
    setOpen(!open);
  };
  return (
    <div>
      <Flex
        bg={Colors.loginAuthBackground}
        minHeight={"100vh"}
        flexDirection={"column"}
      >
        <Box
          w={"100%"}
          sx={{
            position: "-webkit-sticky",
            /* Safari */ position: "sticky",
            top: "0",
            zIndex: 999,
          }}
        >
          <Box
            w={"100%"}
            sx={{
              /* Safari */ position: "absolute",
              top: "0",
              zIndex: 99999999,
            }}
          >
            {myContext.loading ? <PosMainProgress /> : null}
          </Box>
          <Flex bg={Colors.headerColor}>
            <HStack
              h={{ base: "3.75rem", md: "4.69rem" }}
              pl={{ base: 4, md: 8 }}
            >
              <Image
                style={{ width: "7.81rem" }}
                src={logo}
                alt="sanepos"
                onClick={() => handleClick(open)}
              />
            </HStack>
            <Spacer />
            <HStack
              h={{ base: "3.75rem", md: "4.75rem" }}
              pr={{ base: 4, md: 8 }}
            >
              <Box zIndex={999} borderRadius={4}>
                <HStack gap={3}>
                  {isMobile ? (
                    <Icon
                      as={CgDetailsMore}
                      boxSize={"2.375rem"}
                      onClick={() => handleClick(open)}
                    />
                  ) : null}
                  {/* <Avatar
                    h={"2.375rem"}
                    w={"2.375rem"}
                    bg={Colors.userAvtarBackground}
                    icon={
                      <Image
                        className="table"
                        h={6}
                        w={6}
                        src={notification}
                        alt="notification"
                        // _hover={{ backgroundColor: Colors.buttonOutlineHover }}
                      />
                    }
                  /> */}
                  <Menu>
                    <MenuButton>
                      <Avatar
                        name={
                          undefined !== myContext.userData.userName &&
                          null !== myContext.userData.userName &&
                          "" !== myContext.userData.userName
                            ? myContext.userData.userName
                            : userName
                        }
                        h={"2.330rem"}
                        ml={4}
                        color={Colors.loginAuthBackground}
                        w={"2.330rem"}
                        fontSize={"0.938rem"}
                        fontWeight={500}
                        bg={Colors.posTextInfo}
                      />
                    </MenuButton>
                    <MenuList
                      w={"20.5rem"}
                      h={"14.625rem"}
                      mt={2}
                      bg={Colors.loginAuthBackground}
                      style={{
                        boxShadow:
                          "0px 25px 50px 0px rgba(143, 143, 143, 0.15)",
                      }}
                    >
                      <MenuItem h={"5rem"} bg={"transparent"}>
                        <Avatar
                          name={
                            undefined !== myContext.userData.userName &&
                            null !== myContext.userData.userName &&
                            "" !== myContext.userData.userName
                              ? myContext.userData.userName
                              : userName
                          }
                          h={"3.125rem"}
                          ml={4}
                          color={Colors.loginAuthBackground}
                          w={"3.125rem"}
                          fontSize={"0.938rem"}
                          fontWeight={500}
                          bg={Colors.posTextInfo}
                        />
                        <VStack
                          ml={4}
                          spacing={0}
                          justifyContent={"flex-start"}
                        >
                          <Text
                            fontSize={"1rem"}
                            fontWeight={500}
                            color={Colors.posTextInfo}
                            alignSelf={"flex-start"}
                          >
                            {undefined !== myContext.userData.userName &&
                            null !== myContext.userData.userName &&
                            "" !== myContext.userData.userName
                              ? myContext.userData.userName
                              : userName}
                          </Text>
                          {/* {userRoles.length > 46 ? (
                            <Tippy
                              content={userRoles}
                              interactive={true}
                              maxWidth={350}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            > */}
                          <Text
                            fontSize={"1rem"}
                            fontWeight={300}
                            color={Colors.posTextInfo}
                            paddingLeft={1}
                            paddingRight={1}
                          >
                            {undefined !== myContext.userData.userRoles &&
                            null !== myContext.userData.userRoles &&
                            "" !== myContext.userData.userRoles
                              ? myContext.userData.userRoles.length > 46
                                ? myContext.userData.userRoles.slice(0, 46) +
                                  "..."
                                : myContext.userData.userRoles
                              : userRoles.length > 46
                              ? userRoles.slice(0, 42) + " ..."
                              : userRoles}
                          </Text>
                          {/* </Tippy>
                          ) : (
                            <Text
                              fontSize={"1rem"}
                              fontWeight={300}
                              color={Colors.posTextInfo}
                              paddingLeft={1}
                              paddingRight={1}
                            >
                              {userRoles}
                            </Text>
                          )} */}
                        </VStack>
                      </MenuItem>
                      {/* <MenuDivider /> */}
                      <MenuItem
                        h={"3rem"}
                        bg={"transparent"}
                        color={Colors.posTextInfo}
                        fontWeight={300}
                        _hover={{
                          backgroundColor: Colors.posNavbarLink,
                          color: Colors.loginAuthBackground,
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          if (Constants.PROFILE_EDIT != location.pathname) {
                            myContext.handleLoading(true);
                            navigate(Constants.PROFILE_EDIT);
                          }
                        }}
                      >
                        <Icon as={CiUser} boxSize={4} ml={"1.2rem"} />
                        <Text ml={4} fontSize={"1rem"}>
                          {"My Account"}
                        </Text>
                      </MenuItem>
                      {/* <MenuDivider /> */}
                      <MenuItem
                        h={"3rem"}
                        bg={"transparent"}
                        color={Colors.posTextInfo}
                        fontWeight={300}
                        _hover={{
                          backgroundColor: Colors.posNavbarLink,
                          color: Colors.loginAuthBackground,
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          if (
                            Constants.BILLINGS_VIEW_DETAILS != location.pathname
                          ) {
                            myContext.handleLoading(true);
                            navigate(Constants.BILLINGS_VIEW_DETAILS);
                          }
                        }}
                      >
                        <Image
                          className="table"
                          h={6}
                          w={6}
                          ml={"1.2rem"}
                          src={billing}
                          alt="billing"
                          // _hover={{ backgroundColor: Colors.buttonOutlineHover }}
                        />
                        <Text ml={4} fontSize={"1rem"}>
                          {"Billing"}
                        </Text>
                      </MenuItem>
                      {/* <MenuDivider /> */}
                      <MenuItem
                        h={"3rem"}
                        bg={"transparent"}
                        fontWeight={300}
                        color={Colors.posTextInfo}
                        _hover={{
                          backgroundColor: Colors.posNavbarLink,
                          color: Colors.loginAuthBackground,
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          navigate(Constants.LOGOUT_PAGE);
                        }}
                      >
                        <Text
                          // ml={4}
                          ml={"1.2rem"}
                          fontSize={"1rem"}
                        >
                          {"Log Out of Account"}
                        </Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              </Box>
            </HStack>
          </Flex>
        </Box>
        <Flex>
          <Sidebar isOpen={open} onClose={() => handleClick(open)} />
          <Box
            ml={isMobile ? 0 : "16.45rem"}
            className={isMobile ? "mobileMainLayout" : "mainLayout"}
            h={"100%"}
          >
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default MainLayout;
