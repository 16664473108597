import { Box, Stack, useMediaQuery } from "@chakra-ui/react";
import React from "react";

const SimpleReportLayout = (props) => {
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");

  if (isMeduim) {
    return (
      <Box
        gap={2}
        flex={1}
        flexShrink={"0"}
        direction={"column"}
        p={4}
        alignItems={"flex-start"}
      >
        {props.children}
      </Box>
    );
  } else {
    return (
      <Stack
        gap={4}
        flex={1}
        flexShrink={"0"}
        direction={isMeduim ? "column" : "row"}
        m={4}
        alignItems={"flex-start"}
      >
        {props.children}
      </Stack>
    );
  }
};
export default SimpleReportLayout;
