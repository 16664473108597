// import { Box, Text } from "@chakra-ui/react";
// import CloseIcon from "@mui/icons-material/Close";
// import FirstPageIcon from "@mui/icons-material/FirstPage";
// import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
// import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import LastPageIcon from "@mui/icons-material/LastPage";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import Button from "@mui/material/Button";
// import ClickAwayListener from "@mui/material/ClickAwayListener";
// import Grow from "@mui/material/Grow";
// import IconButton from "@mui/material/IconButton";
// import MenuItem from "@mui/material/MenuItem";
// import MenuList from "@mui/material/MenuList";
// import Popper from "@mui/material/Popper";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableFooter from "@mui/material/TableFooter";
// import TableHead from "@mui/material/TableHead";
// import TablePagination, {
//   tablePaginationClasses
// } from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@mui/material/TableSortLabel";
// import PropTypes from "prop-types";
// import React from "react";
// import Colors from "../../assets/colors/Colors";
// import { Mtheme } from "../../theme";
// import { PosSpliteButton } from "../index";

// function TablePaginationActions(props) {
//   const theme = createTheme();
//   const { count, page, rowsPerPage, onPageChange } = props;
//   const handleFirstPageButtonClick = (event) => {
//     onPageChange(event, 0);
//   };
//   const handleBackButtonClick = (event) => {
//     onPageChange(event, page - 1);
//   };
//   const handleNextButtonClick = (event) => {
//     onPageChange(event, page + 1);
//   };
//   const handleLastPageButtonClick = (event) => {
//     onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//   };
//   return (
//     <Box sx={{ flexShrink: 0, ml: 2.5 }}>
//       <IconButton
//         style={{ color: Colors.posTextInfo }}
//         onClick={handleFirstPageButtonClick}
//         disabled={page === 0}
//         aria-label="first page"
//       >
//         {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
//       </IconButton>
//       <IconButton
//         style={{ color: Colors.posTextInfo }}
//         onClick={handleBackButtonClick}
//         disabled={page === 0}
//         aria-label="previous page"
//       >
//         {theme.direction === "rtl" ? (
//           <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
//         ) : (
//           <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
//         )}
//       </IconButton>
//       <IconButton
//         style={{ color: Colors.posTextInfo }}
//         onClick={handleNextButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="next page"
//       >
//         {theme.direction === "rtl" ? (
//           <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
//         ) : (
//           <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
//         )}
//       </IconButton>
//       <IconButton
//         style={{ color: Colors.posTextInfo }}
//         onClick={handleLastPageButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="last page"
//       >
//         {theme.direction === "rtl" ? (
//           <FirstPageIcon style={{ color: Colors.posTextInfo }} />
//         ) : (
//           <LastPageIcon style={{ color: Colors.posTextInfo }} />
//         )}
//       </IconButton>
//     </Box>
//   );
// }
// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };
// const options = ["Deactive"];

// const PosTableRow = (props) => {
//   const [open, setOpen] = React.useState(false);
//   const anchorRef = React.useRef(null);
//   const [selectedIndex, setSelectedIndex] = React.useState(0);
//   const { noAction } = props;
//   const { singleButton } = props;
//   const handleViewClick = () => {
//     props.handleView(props.data.id);
//   };
//   const handleReactiveClick = () => {
//     props.handleReactive(props.data.id);
//   };
//   const handleMenuItemClick = (event, index) => {
//     setSelectedIndex(index);
//     setOpen(false);
//     props.onDeactiveIdChange(props.data.id);
//   };

//   const handleClose = (event) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target)) {
//       return;
//     }
//     setOpen(false);
//   };
//   return (
//     <TableRow key={props.data.id}>
//       {Object.values(props.data).map((value, index) => (
//         <TableCell
//           key={index}
//           sx={{
//             fontWeight: index === 1 ? 500 : 300,
//             fontSize: "1rem",
//             lineHeight: "1.18rem",
//             letterSpacing: "-1.5%",
//             color: Colors.posTextInfo,
//           }}
//         >
//           {index == 0 ? "#" + value : value}
//         </TableCell>
//       ))}
//       {!noAction && (
//         <TableCell align="left">
//           <React.Fragment>
//             {props.spliteButton ? (
//               <PosSpliteButton
//                 spliteOptions={props.spliteOptions}
//                 item={props.data}
//               />
//             ) : singleButton ? (
//               <Button
//                 variant="outlined"
//                 size="small"
//                 onClick={handleViewClick}
//                 startIcon={<VisibilityIcon />}
//               >
//                 View
//               </Button>
//             ) : (
//               <Text
//                 onClick={handleReactiveClick}
//                 cursor="pointer"
//                 color={Colors.posNavbarLink}
//                 fontSize="1rem"
//                 lineHeight="0.94rem"
//                 letterSpacing="-1.5%"
//                 textDecoration="underline"
//                 fontWeight={600}
//               >
//                 Activate
//               </Text>
//             )}
//             <Popper
//               sx={{
//                 zIndex: 1,
//               }}
//               open={open}
//               anchorEl={anchorRef.current}
//               role={undefined}
//               transition
//               disablePortal
//             >
//               {({ TransitionProps, placement }) => (
//                 <Grow
//                   {...TransitionProps}
//                   style={{
//                     transformOrigin:
//                       placement === "bottom" ? "center top" : "center bottom",
//                   }}
//                 >
//                   <ThemeProvider theme={Mtheme}>
//                     <ClickAwayListener onClickAway={handleClose}>
//                       <MenuList id="split-button-menu" autoFocusItem>
//                         {options.map((option, index) => (
//                           <MenuItem
//                             style={{
//                               color: Colors.posTextInfo,
//                               fontWeight: 500,
//                               fontSize: "0.94rem",
//                             }}
//                             key={option}
//                             onClick={(event) =>
//                               handleMenuItemClick(event, index)
//                             }
//                           >
//                             <CloseIcon style={{ color: "red" }} /> {option}
//                           </MenuItem>
//                         ))}
//                       </MenuList>
//                     </ClickAwayListener>
//                   </ThemeProvider>
//                 </Grow>
//               )}
//             </Popper>
//           </React.Fragment>
//         </TableCell>
//       )}
//     </TableRow>
//   );
// };

// const PosTable = (props) => {
//   const {
//     columnNames,
//     rawdata,
//     totalcount,
//     onPageChange,
//     onRowsPerPageChange,
//     goEdit,
//     goReactive,
//     DectivatedId,
//     handleSortColumn,
//     sortColumnName,
//     order,
//     spliteButton,
//     noAction,
//     singleButton,
//     goView,
//     spliteOptions,
//     footerHide,
//     headerHide,
//     page,
//     rowsPerPage,
//   } = props;
//   const handleChangePage = (event, newPage) => {
//     onPageChange(newPage);
//   };
//   const handleDeactiveIdChange = (id) => {
//     DectivatedId(id);
//   };
//   const handleColumn = (coulmn) => {
//     if (coulmn.sortName === sortColumnName) {
//       if (order === "asc") {
//         handleSortColumn(coulmn, "desc");
//       } else {
//         handleSortColumn(coulmn, "asc");
//       }
//     } else {
//       handleSortColumn(coulmn, "asc");
//     }
//   };
//   const handleChangeRowsPerPage = (event) => {
//     const newRowsPerPage = parseInt(event.target.value, 10);
//     onRowsPerPageChange(newRowsPerPage);
//     onPageChange(0);
//   };
//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rawdata.length) : 0;
//   return (
//     <TableContainer>
//       <Table>
//         <TableHead
//           hidden={headerHide}
//           sx={{ backgroundColor: Colors.modifierTable }}
//         >
//           <TableRow>
//             {columnNames.map((column, index) => (
//               <TableCell
//                 key={index}
//                 sx={{
//                   fontWeight: 300,
//                   fontSize: "1rem",
//                   lineHeight: "1.18rem",
//                   letterSpacing: "-1.5%",
//                   color: Colors.posTextInfo,
//                   width: column.width == "" ? undefined : column.width,
//                 }}
//               >
//                 {column.columnNames}
//                 {column.sortName.length > 0 ? (
//                   <TableSortLabel
//                     active={column.sortName === sortColumnName}
//                     direction={
//                       column.sortName === sortColumnName ? order : "asc"
//                     }
//                     onClick={() => handleColumn(column)}
//                   />
//                 ) : null}
//               </TableCell>
//             ))}
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {rawdata.map((rawdata) => (
//             <PosTableRow
//               key={rawdata.id}
//               data={rawdata}
//               handleEdit={(id) => goEdit(id)}
//               handleReactive={(id) => goReactive(id)}
//               onDeactiveIdChange={handleDeactiveIdChange}
//               spliteButton={spliteButton}
//               spliteOptions={spliteOptions}
//               noAction={noAction}
//               singleButton={singleButton}
//               handleView={(id) => goView(id)}
//             />
//           ))}
//         </TableBody>
//         <TableFooter hidden={footerHide}>
//           <TableRow sx={{ border: "none" }}>
//             <ThemeProvider theme={Mtheme}>
//               <TablePagination
//                 rowsPerPageOptions={[10, 25, 50, 100]}
//                 sx={{
//                   [`& .${tablePaginationClasses.spacer}`]: {
//                     display: "none",
//                   },
//                   [`& .${tablePaginationClasses.toolbar}`]: {
//                     justifyContent: "center",
//                   },
//                   [`& .${tablePaginationClasses.selectLabel}`]: {
//                     color: Colors.posNavbarLink,
//                     fontWeight: 300,
//                     fontSize: "0.75rem",
//                     lineHeight: "1rem",
//                     letterSpacing: "-1.5%",
//                   },
//                 }}
//                 SelectProps={{
//                   inputProps: { "aria-label": "rows per page" },
//                   native: true,
//                 }}
//                 count={totalcount}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//                 ActionsComponent={TablePaginationActions}
//                 labelRowsPerPage={<span>Items per page</span>}
//               />
//             </ThemeProvider>
//           </TableRow>
//         </TableFooter>
//       </Table>
//     </TableContainer>
//   );
// };
// PosTable.propTypes = {
//   columnNames: PropTypes.arrayOf(PropTypes.object).isRequired,
//   rawdata: PropTypes.arrayOf(PropTypes.object).isRequired,
// };
// export default PosTable;
import { Box, Text } from "@chakra-ui/react";
import {DeleteIcon} from '@chakra-ui/icons';
import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Popper from "@mui/material/Popper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination, {
  tablePaginationClasses,
} from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import React from "react";
import Colors from "../../assets/colors/Colors";
import { Mtheme } from "../../theme";
import { PosSpliteButton } from "../index";
import Checkbox from "@mui/material/Checkbox";
function TablePaginationActions(props) {
  const theme = createTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
        ) : (
          <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
        )}
      </IconButton>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft style={{ color: Colors.posTextInfo }} />
        ) : (
          <KeyboardArrowRight style={{ color: Colors.posTextInfo }} />
        )}
      </IconButton>
      <IconButton
        style={{ color: Colors.posTextInfo }}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon style={{ color: Colors.posTextInfo }} />
        ) : (
          <LastPageIcon style={{ color: Colors.posTextInfo }} />
        )}
      </IconButton>
    </Box>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const options = ["Deactive"];
const PosTableRow = (props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { noAction, checkBoxTable } = props;
  const { singleButton } = props;
  const { singleDeleteButton } = props;
  var id = props.data.id;
  const handleViewClick = () => {
    props.handleView(props.data.id);
  };
  const handleSingleDeleteClick = () => {
    props.handleSingleDelete(props.data.id);
  };
  const handleReactiveClick = () => {
    props.handleReactive(id);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    props.onDeactiveIdChange(props.data.id);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCheckChange = (event) => {
    const isChecked = event.target.checked;
    // var id = props.data.id;
    const updatedCheckedItems = [...props.checkedItems];
    if (isChecked && !updatedCheckedItems.includes(id)) {
      updatedCheckedItems.push(id);
    } else {
      const index = updatedCheckedItems.indexOf(id);
      if (index !== -1) {
        updatedCheckedItems.splice(index, 1);
      }
    }
    props.handleCheckedItemsChange(updatedCheckedItems);
  };
  if(props.removeId) {
    delete props.data.id
  }
// let data = checkBoxTable ? delete props.data.id : props.data
  return (
    <TableRow key={props.index}>
      {checkBoxTable && (
        <TableCell>
          <Checkbox
            checked={
              props.checkedItems && props.checkedItems.includes(id)
            }
            onChange={handleCheckChange}
          />
        </TableCell>
      )}
      {Object.values(props.data).map((value, index) => (
        <TableCell
          key={index}
          sx={{
            fontWeight: index === 1 ? 500 : 300,
            fontSize: "1rem",
            lineHeight: "1.18rem",
            letterSpacing: "-1.5%",
            color: Colors.posTextInfo,
          }}
        >
          {index === 0 && !checkBoxTable ? "#" + value : value}
        </TableCell>
      ))}
      {!noAction && (
        <TableCell align="left">
          <React.Fragment>
            {props.spliteButton ? (
              <PosSpliteButton
                spliteOptions={props.spliteOptions}
                item={props.data}
                id={id}
              />
            ) : singleButton ? (
              <Button
                variant="outlined"
                size="small"
                onClick={handleViewClick}
                startIcon={<VisibilityIcon />}
              >
                View
              </Button>
            ) : singleDeleteButton ? ( // New condition for singleDeleteButton
            <IconButton onClick={handleSingleDeleteClick}>
              <DeleteIcon color={Colors.errorColor} />
            </IconButton>
          ): (
              <Text
                onClick={handleReactiveClick}
                cursor="pointer"
                color={Colors.posNavbarLink}
                fontSize="1rem"
                lineHeight="0.94rem"
                letterSpacing="-1.5%"
                textDecoration="underline"
                fontWeight={600}
              >
                Activate
              </Text>
            )}
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <ThemeProvider theme={Mtheme}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options.map((option, index) => (
                          <MenuItem
                            style={{
                              color: Colors.posTextInfo,
                              fontWeight: 500,
                              fontSize: "0.94rem",
                            }}
                            key={option}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            <CloseIcon style={{ color: "red" }} /> {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </ThemeProvider>
                </Grow>
              )}
            </Popper>
          </React.Fragment>
        </TableCell>
      )}
    </TableRow>
  );
};
const PosTable = (props) => {
  const {
    columnNames,
    rawdata,
    totalcount,
    onPageChange,
    onRowsPerPageChange,
    goEdit,
    goReactive,
    DectivatedId,
    handleSortColumn,
    sortColumnName,
    order,
    spliteButton,
    noAction,
    singleButton,
    singleDeleteButton,
    goView,
    goSingleDelete,
    spliteOptions,
    footerHide,
    headerHide,
    page,
    rowsPerPage,
    checkBoxTable,
    checkedItems,
    handleCheckboxChange,
    removeId
  } = props;
  const handleChangePage = (event, newPage) => {
    onPageChange(newPage);
  };
  const handleDeactiveIdChange = (id) => {
    DectivatedId(id);
  };
  const handleColumn = (coulmn) => {
    if (coulmn.sortName === sortColumnName) {
      if (order === "asc") {
        handleSortColumn(coulmn, "desc");
      } else {
        handleSortColumn(coulmn, "asc");
      }
    } else {
      handleSortColumn(coulmn, "asc");
    }
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(newRowsPerPage);
    onPageChange(0);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rawdata.length) : 0;
  return (
    <TableContainer>
      <Table>
        <TableHead
          hidden={headerHide}
          sx={{ backgroundColor: Colors.modifierTable }}
        >
          <TableRow>
            {columnNames.map((column, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: 300,
                  fontSize: "1rem",
                  lineHeight: "1.18rem",
                  letterSpacing: "-1.5%",
                  color: Colors.posTextInfo,
                  width: column.width == "" ? undefined : column.width,
                }}
              >
                {column.columnNames}
                {column.sortName.length > 0 ? (
                  <TableSortLabel
                    active={column.sortName === sortColumnName}
                    direction={
                      column.sortName === sortColumnName ? order : "asc"
                    }
                    onClick={() => handleColumn(column)}
                  />
                ) : null}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rawdata.map((rawdata,index) => (
            <PosTableRow
              key={undefined != rawdata.id ? rawdata.id : index}
              index={index}
              data={rawdata}
              handleEdit={(id) => goEdit(id)}
              handleReactive={(id) => goReactive(id)}
              onDeactiveIdChange={handleDeactiveIdChange}
              spliteButton={spliteButton}
              spliteOptions={spliteOptions}
              noAction={noAction}
              singleButton={singleButton}
              singleDeleteButton={singleDeleteButton}
              handleView={(id) => goView(id)}
              handleSingleDelete={(id) => goSingleDelete(id)}
              checkedItems={checkedItems}
              handleCheckedItemsChange={(id) => handleCheckboxChange(id)}
              handleCheckboxChange={handleCheckboxChange} // Added this prop
              checkBoxTable={checkBoxTable}
              removeId={removeId}
            />
          ))}
        </TableBody>
        {!footerHide ? 
        <TableFooter hidden={footerHide}>
          <TableRow sx={{ border: "none" }}>
            <ThemeProvider theme={Mtheme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                sx={{
                  [`& .${tablePaginationClasses.spacer}`]: {
                    display: "none",
                  },
                  [`& .${tablePaginationClasses.toolbar}`]: {
                    justifyContent: "center",
                  },
                  [`& .${tablePaginationClasses.selectLabel}`]: {
                    color: Colors.posNavbarLink,
                    fontWeight: 300,
                    fontSize: "0.75rem",
                    lineHeight: "1rem",
                    letterSpacing: "-1.5%",
                  },
                }}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                count={totalcount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={<span>Items per page</span>}
              />
            </ThemeProvider>
          </TableRow>
        </TableFooter> : null}
       
      </Table>
    </TableContainer>
  );
};
PosTable.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  rawdata: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default PosTable;
