import axios from "axios";
import * as Constants from "../../../constants/Constants";


export function getProfileDetails( {request, params} ) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.PROFILE_DETAIL_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROFILE_DETAIL_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
        
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);

      });
  });
}


export function profileEdit({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const id = Object.fromEntries(formData).id;
    const state = Object.fromEntries(formData).states;
    const payload = {
      employee: Object.fromEntries(formData).employee,
      id: id,
      state: state,
    };
    
    payload.employee = JSON.parse(
      Object.fromEntries(formData).employee
    );

    axios
      .put(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        "admin-profile" +
        `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROFILE_DETAIL_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getAllCountriesState() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.GET_ALL_USER,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROFILE_DETAIL_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getStateListForSelectedCountry(countryISO) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.GET_COUNTRY_STATE +
        countryISO,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROFILE_DETAIL_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}