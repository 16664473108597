import {
  Box,
  Flex,
  Img,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import Phone from "../../../assets/images/phone.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosCountryStateDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllCountriesState,
  getStateListForSelectedCountry,
} from "./CustomerService";

const CustomerCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");

  useEffect(() => {
    getCountryStates();
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.CUSTOMERS_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.CUSTOMERS_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  const handleSelectedCountry = (value) => {
    try {
      setSelectedCountry(value);
      setStates("");
      setSelectedState(null);
      setStateError("");
      formik.setFieldValue("selectedState", null);
      myContext.handleLoading(true);
      getStateListForSelectedCountry(value)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const statesData = response.data.data.states;
            const arrayStates = Object.keys(statesData).map((key) => ({
              label: statesData[key],
              value: key,
            }));
            setStates(arrayStates);
            const customerState =
              loaderResponse?.data?.data?.customerData?.state;
            if (
              customerState &&
              customerState !== "N.A" &&
              customerState !== null &&
              statesData[customerState]
            ) {
              setSelectedState(customerState);
              formik.setFieldValue("selectedState", customerState);
              setStatesAvailability(0);
            } else {
              // setSelectedState("N.A");
              // formik.setFieldValue("selectedState", "N.A");
              setStatesAvailability(1);
            }
          } else {
            setStates([]);
            setSelectedState("N.A");
            formik.setFieldValue("selectedState", "N.A");
            setStatesAvailability(1);
          }
          myContext.handleLoading(false);
        })
        .catch((err) => {
          setStates([]);
          setSelectedState("N.A");
          formik.setFieldValue("selectedState", "N.A");
          setStatesAvailability(1);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStates([]);
      setSelectedState("N.A");
      formik.setFieldValue("selectedState", "N.A");
      setStatesAvailability(1);
      let actionData = error;
      error({ actionData });
      myContext.handleLoading(false);
    }
  };

  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };

  const getCountryStates = async () => {
    try {
      getAllCountriesState()
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const arrayCountries = Object.keys(
              response.data.data.countries
            ).map((key) => ({
              label: response.data.data.countries[key],
              value: key,
            }));
            setCountry(arrayCountries);
            myContext.handleLoading(false);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      country: "",
      state: "",
      city: "",
      phone: "",
      address1: "",
      address2: "",
      selectedState: "",
      selectedCountry: "",
      notes: "",
      tax_exempt: false,
      credit_limit: "",
      exclude_from_reward_calculation: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.CUSTOMER_NAME_REQUIRED)
        .max(191, Constants.CUSTOMER_NAME_LENGTH_GREATER_THAN_191),
      address1: Yup.string()
        .trim()
        .required(Constants.CUSTOMER_ADDRESS_NAME_REQUIRED)
        .max(5000, Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000),
      address2: Yup.string().max(
        5000,
        Constants.CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000
      ),
      city: Yup.string()
        .trim()
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_CITY)
        .max(191, Constants.CUSTOMER_CITY_LENGTH_GREATE_THEN_191),
      phone: Yup.string()
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_PHONE_NUMBER)
        .max(
          14,
          Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG
        )
        .min(
          3,
          Constants.CUSTOMER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG
        )
        .matches(/^\d*$/, Constants.THIS_IS_NOT_A_VALID_PHONE_NUMBER),
      email: Yup.string()
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID)
        .max(191, Constants.CUSTOMER_EMAIL_LENGTH_GREATE_THEN_191),
      notes: Yup.string().max(
        191,
        Constants.CUSTOMER_NOTES_LENGTH_GREATE_THEN_191
      ),
      credit_limit: Yup.number()
        .typeError(Constants.CUSTOMER_PLEASE_ENTER_A_VALID_CREDIT_LIMIT)
        .max(999999.99, Constants.CUSTOMER_CREDIT_LIMIT_MAX_ERROR)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      selectedCountry: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      ),
      selectedState: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_STATE
      ),
      // country: Yup.string().required(
      //   Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY
      // ),
      // state: Yup.string().required(Constants.CUSTOMER_PLEASE_SELECT_A_STATE),
      tax_exempt: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_TAX_EXEMPT_OPTION
      ),
      exclude_from_reward_calculation: Yup.string().required(
        Constants.CUSTOMER_PLEASE_SELECT_A_REWARD_CALCULATION_OPTION
      ),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        tax_exempt: values.tax_exempt ? 1 : 0,
        exclude_from_reward_calculation: values.exclude_from_reward_calculation
          ? 1
          : 0,
        country: selectedCountry,
        state: selectedState || "N.A",
        stateNotAvailable: stateAvailable,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.CUSTOMER_CREATE_API_ROUTE,
      });
    },
  });

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.name.length <= 0 ||
      formik.errors.name ||
      formik.errors.phone ||
      formik.errors.email ||
      formik.errors.address1 ||
      formik.errors.city ||
      formik.errors.country ||
      formik.errors.state ||
      formik.values.selectedState ||
      formik.values.selectedCountry ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Box flex="1" pb="2rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              // pass param data in tax list page
              props.navigate(Constants.CUSTOMERS_PATH, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Customer", "Create Customer"]}
            breadCrumTitle={"Create Customer"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              // pass param data in modifier set list page
              props.navigate(Constants.CUSTOMERS_PATH, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={
              formik.values.name.length <= 0 ||
              formik.errors.name ||
              formik.errors.phone ||
              formik.errors.email ||
              formik.errors.address1 ||
              formik.errors.city ||
              formik.errors.credit_limit ||
              formik.errors.country ||
              formik.errors.state ||
              !formik.values.selectedState ||
              stateError.length > 0 ||
              buttonDisable
                ? true
                : false
            }
            buttonsubmit={"Submit"}
            SubmitButton={true}
            //   onClick={handleSubmitButton}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Flex>
      <Box
        mt={{ base: 4, lg: 8 }}
        ml={{ base: 4, lg: 14 }}
        mr={{ base: 4, lg: 14 }}
        // mt={{ base: "0", md: "2rem" }}
        // ml={{ base: "0", md: "3.44rem" }}
        // mr={{ base: "0", md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="8"
        onKeyDown={handleKeyDown}
      >
        <Text
          fontSize="1.23rem"
          w="10.5rem"
          pt={"1.56rem"}
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Basic Details
        </Text>
        <Flex
          p={{ base: "0" }}
          gap={{ base: "0", md: "9" }}
          direction={{ base: "column", md: "row" }}
        >
          <Box flex="1">
            <Stack spacing={2} pt={5}>
              <PosLable
                name={"Name"}
                requiredLabel={true}
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.02rem"
              />
              <PosInput
                id="name"
                mt={"1rem"}
                placeholder={"Name"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.name}
                handleBlur={formik.handleBlur}
                // onKeyDown={handleKeyDown}
                posInputGroup={true}
                inputType={"text"}
                inputError={formik.touched.name && formik.errors.name}
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    // height: "1.21rem",
                    // width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.posCancleButtonRed}>
                {formik.touched.name && formik.errors.name ? (
                  <span>{formik.errors.name}</span>
                ) : null}
              </Text>
            </Stack>
          </Box>
          <Box flex="1">
            <Stack spacing={2} pt={5}>
              <PosLable
                name={"Phone Number"}
                requiredLabel={true}
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.02rem"
              />
              <PosInput
                id="phone"
                mt={"1rem"}
                placeholder={"phone"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.phone}
                handleBlur={formik.handleBlur}
                // onKeyDown={handleKeyDown}
                posInputGroup={true}
                inputType={"number"}
                inputError={formik.touched.phone && formik.errors.phone}
              >
                {/* <PhoneIcon
                  color={Colors.posNavbarLink}
                  style={{ fill: Colors.taxInfo }}
                /> */}
                <Img
                  src={Phone}
                  alt="Phone"
                  sx={{
                    height: "1.5rem",
                    width: "1.5rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.posCancleButtonRed}>
                {formik.touched.phone && formik.errors.phone ? (
                  <span>{formik.errors.phone}</span>
                ) : null}
              </Text>
            </Stack>
          </Box>
        </Flex>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"Email"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="email"
            mt={"1rem"}
            placeholder={"email"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.email}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.email && formik.errors.email}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                // height: "1.21rem",
                // width: "1.21rem",
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.email && formik.errors.email ? (
              <span>{formik.errors.email}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"Address Line 1"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="address1"
            mt={"1rem"}
            placeholder={"address1"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.address1}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.address1 && formik.errors.address1}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                // height: "1.21rem",
                // width: "1.21rem",
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.address1 && formik.errors.address1 ? (
              <span>{formik.errors.address1}</span>
            ) : null}
          </Text>
        </Stack>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"Address Line 2"}
            label={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="address2"
            mt={"1rem"}
            placeholder={"address2"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.address2}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.address2 && formik.errors.address2}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.address2 && formik.errors.address2 ? (
              <span>{formik.errors.address2}</span>
            ) : null}
          </Text>
        </Stack>
        <Flex
          p={{ base: "0" }}
          gap={{ base: "0", md: "9" }}
          direction={{ base: "column", md: "row" }}
        >
          <Box flex="1">
            <PosLable
              name={"Country"}
              requiredLabel={true}
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <PosCountryStateDropDown
              options={countries}
              value={formik.values.selectedCountry}
              countriesSelect={true}
              onChange={(e) => {
                if(undefined != e.value && null != e.value && '' != e.value){
                handleSelectedCountry(e.target.value);
                formik.setFieldValue("selectedCountry", e.target.value);
                }
              }}
              onBlur={(e) => {
                formik.setFieldTouched("selectedCountry", true);
                if (!e.target.value) {
                  setCountryError(Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY);
                } else {
                  setCountryError("");
                }
              }}
              optionLabel="label"
              placeholder="--Select Country--"
              width={"100%"}
              maxWidth={"42rem"}
              height={"2.5rem"}
              resetFilterOnHide={true}
              className="w-full md:w-20rem"
              lableAvailable={true}
              disabled={false}
            />
            <Text color={Colors.errorColor}>
                {formik.touched.selectedCountry &&
                formik.errors.selectedCountry ? (
                  <span>{formik.errors.selectedCountry}</span>
                ) : null}
              </Text>
          </Box>
          <Box flex="1">
            <PosLable
              name={"State"}
              requiredLabel={true}
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
              pb={"0.31rem"}
            />
            {states.length === 0 ? (
              <Select
                id="state"
                value={formik.values.selectedState}
                sx={{
                  color: "gray",
                  fontSize: "0.94rem !important",
                  fontWeight: 300,
                  letterSpacing: "-0.01rem !important",
                  backgroundColor: "#5881fe0d",
                  borderRadius: "0.625rem",
                  height: "2.5rem",
                  border: "1px solid #b7b7b71f",
                  "&[disabled]": {
                    backgroundColor: "#5881fe0d !important",
                    opacity: 1,
                  },
                }}
                // onChange={(e) => {
                //   handleSelectedState(e.target.value);
                //   formik.setFieldValue("selectedState", e.target.value);
                // }}
                isDisabled
                placeholder={states.length > 0 ? "N.A" : "--Select State--"}
              >
                <option value="N.A">N.A</option>
              </Select>
            ) : (
              <PosCountryStateDropDown
                options={states}
                value={formik.values.selectedState}
                onChange={(e) => {
                  if(undefined != e.value && null != e.value && '' != e.value){
                  handleSelectedState(e.target.value);
                  formik.setFieldValue("selectedState", e.target.value);
                  }
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedState", true);
                  if (!e.target.value) {
                    setStateError(Constants.CUSTOMER_PLEASE_SELECT_A_STATE);
                  } else {
                    setStateError("");
                  }
                }}
                optionLabel="label"
                placeholder="--Select State--"
                width={"100%"}
                maxWidth={"42rem"}
                height={"2.5rem"}
                resetFilterOnHide={true}
                className="w-full md:w-20rem"
                lableAvailable={true}
                disabled={false}
              />
            )}
            {states.length > 0 && (
              <Text color={Colors.errorColor}>
                {formik.touched.selectedState && stateError.length > 0 ? (
                  <span>{stateError}</span>
                ) : null}
              </Text>
            )}
          </Box>
        </Flex>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"City"}
            requiredLabel={true}
            fontStyle="normal"
            fontSize="0.94rem"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <PosInput
            id="city"
            mt={"1rem"}
            placeholder={"city"}
            color={Colors.posCommon}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.city}
            handleBlur={formik.handleBlur}
            // onKeyDown={handleKeyDown}
            posInputGroup={true}
            inputType={"text"}
            inputError={formik.touched.city && formik.errors.city}
          >
            <DriveFileRenameOutlineIcon
              sx={{
                // height: "1.21rem",
                // width: "1.21rem",
                background: Colors.posInputGroupBackground,
                borderRadius: "0.31rem",
                color: Colors.posNavbarLink,
              }}
            />
          </PosInput>
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.city && formik.errors.city ? (
              <span>{formik.errors.city}</span>
            ) : null}
          </Text>
        </Stack>
      </Box>
      <Box
        mt={{ base: 2, lg: 2.5 }}
        ml={{ base: 4, lg: 14 }}
        mr={{ base: 4, lg: 14 }}
        // mt={{ base: "0", md: "0.63rem" }}
        // ml={{ base: "0", md: "3.44rem" }}
        // mr={{ base: "0", md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        onKeyDown={handleKeyDown}
      >
        <Box
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
          p={"2.5rem"}
          gap={2}
        >
          <Text
            fontSize="1.13rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Other Details
          </Text>
          <Stack spacing={3} mt="0.13rem">
            <PosLable
              label={true}
              name={"Notes"}
              mt="0.31rem"
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <Textarea
              id="notes"
              placeholder=""
              borderRadius={"0.63rem"}
              border={Colors.posTextAreaBorder}
              background={Colors.posInputBackground}
              onChange={formik.handleChange}
              value={formik.values.notes}
              onBlur={formik.handleBlur}
              // onKeyDown={handleKeyDown}
              inputerror={formik.touched.notes && formik.errors.notes}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.notes && formik.errors.notes ? (
                <span>{formik.errors.notes}</span>
              ) : null}
            </Text>
          </Stack>
          {null != formik.values.notes &&
          "" != formik.values.notes &&
          191 - formik.values.notes.length > 0 ? (
            <Text
              pt={"0.13rem"}
              color={Colors.textareaColor}
              fontSize={"0.94rem"}
              fontStyle={"normal"}
              fontWeight={300}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
            >
              {191 - formik.values.notes.length} characters left for notes
            </Text>
          ) : (
            ""
          )}
          <Stack spacing={3}>
            <PosLable name={"Credit Limit"} label={true} />
            <PosInput
              id={"credit_limit"}
              placeholder={"Credit Limit"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.credit_limit}
              // handleBlur={formik.handleBlur}
              handleBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue(
                  "credit_limit",
                  twofix(formik.values.credit_limit)
                );
              }}
              // onKeyDown={handleKeyDown}
              inputType={"number"}
              posNumberInput={true}
            />
            <Text color={Colors.posCancleButtonRed}>
              {formik.touched.credit_limit && formik.errors.credit_limit ? (
                <span>{formik.errors.credit_limit}</span>
              ) : null}
            </Text>
          </Stack>
          <Text
            mt="2rem"
            w="10.44rem"
            color={Colors.posTextInfo}
            style={{
              flexShrink: "0",
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Tax Exempt
          </Text>
          <Stack
            direction="row"
            mt="0.313rem"
            w="2.75rem"
            height="1.38rem"
            flexShrink="0"
          >
            <PosSwitchButton
              id="tax_exempt"
              switchValue={formik.values.tax_exempt}
              onChange={formik.handleChange}
            />
          </Stack>
          <Text
            mt={"0.88rem"}
            style={{
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
            color={Colors.posShowFields}
          >
            Turn on, to exempt this customer on tax payments
          </Text>
          <Text
            mt="2rem"
            color={Colors.posTextInfo}
            style={{
              flexShrink: "0",
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Exclude from Reward Calculation
          </Text>
          <Stack
            direction="row"
            mt="0.313rem"
            w="2.75rem"
            height="1.38rem"
            flexShrink="0"
          >
            <PosSwitchButton
              id="exclude_from_reward_calculation"
              switchValue={formik.values.exclude_from_reward_calculation}
              onChange={formik.handleChange}
            />
          </Stack>
          <Text
            mt={"0.88rem"}
            style={{
              fontSize: "0.94rem",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
            color={Colors.posShowFields}
          >
            Turn on, to exclude this customer from reward calculations
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default WithRouter(CustomerCreate);
