import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { Chips } from "primereact/chips";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosChipsInput,
  PosDrawer,
  PosFormButton,
  PosOutlineButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./LocationService";

const LocationEmailSettings = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const { id } = useParams();
  const [emailAddressesDsr, setEmailsDsr] = useState([]);
  const [emailAddressesWsr, setEmailsWsr] = useState([]);
  const [emailAddressesMsr, setEmailsMsr] = useState([]);
  const [emailAddressesDlimr, setEmailsDlimr] = useState([]);
  const [emailAddressesWlimr, setEmailsWlimr] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [emailReportingType, setEmailsType] = useState([]);
  const [viewFlag, setViewFlag] = useState(false);

  useEffect(() => {
    if (props.isEmailOpen) {
      myContext.handleLoading(true);
      fetchData(id);
    }
    return () => {};
  }, [props.isEmailOpen]);

  const fetchData = async (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            response.data?.data?.locEmailReportingData.forEach((obj) => {
              const value = obj.email_report_type;
              if (!emailReportingType.includes(value)) {
                emailReportingType.push(value);
              }
            });
            setEmailsType([...emailReportingType]);

            response.data?.data?.locEmailReportingData.forEach((obj) => {
              const value = obj.email;
              if (
                !emailAddressesDsr.includes(value) &&
                obj.email_report_type == "DSR"
              ) {
                emailAddressesDsr.push(value);
              }
              if (
                !emailAddressesWsr.includes(value) &&
                obj.email_report_type == "WSR"
              ) {
                emailAddressesWsr.push(value);
              }
              if (
                !emailAddressesMsr.includes(value) &&
                obj.email_report_type == "MSR"
              ) {
                emailAddressesMsr.push(value);
              }
              if (
                !emailAddressesDlimr.includes(value) &&
                obj.email_report_type == "DLIMR"
              ) {
                emailAddressesDlimr.push(value);
              }
              if (
                !emailAddressesWlimr.includes(value) &&
                obj.email_report_type == "WLIMR"
              ) {
                emailAddressesWlimr.push(value);
              }
            });
            setEmailsDsr(emailAddressesDsr);
            setEmailsWsr([...emailAddressesWsr]);
            setEmailsMsr([...emailAddressesMsr]);
            setEmailsDlimr([...emailAddressesDlimr]);
            setEmailsWlimr([...emailAddressesWlimr]);
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            setEmailsType([]);
            setEmailsDsr([]);
            setEmailsMsr([]);
            setEmailsWsr([]);
            setEmailsDlimr([]);
            setEmailsWlimr([]);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setEmailsType([]);
          setEmailsDsr([]);
          setEmailsMsr([]);
          setEmailsWsr([]);
          setEmailsDlimr([]);
          setEmailsWlimr([]);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setEmailsType([]);
      setEmailsDsr([]);
      setEmailsMsr([]);
      setEmailsWsr([]);
      setEmailsDlimr([]);
      setEmailsWlimr([]);
      myContext.handleLoading(false);
    }
  };
  const emailValue = (email, key) => {
    const re = new RegExp(Constants.EMAIL_REGEX);
    const testResult = re.test(String(email).trim().toLowerCase());
    if (!testResult) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [key]: email + ` is not a valid email address.`,
      }));
    } else {
      if (key == "dsr") {
        emailAddressesDsr.push(email);
        setEmailsDsr([...emailAddressesDsr]);
        setValidationErrors({});
      }
      if (key == "wsr") {
        emailAddressesWsr.push(email);
        setEmailsWsr([...emailAddressesWsr]);
        setValidationErrors({});
      }
      if (key == "msr") {
        emailAddressesMsr.push(email);
        setEmailsMsr([...emailAddressesMsr]);
        setValidationErrors({});
      }
      if (key == "dlimr") {
        emailAddressesDlimr.push(email);
        setEmailsDlimr([...emailAddressesDlimr]);
        setValidationErrors({});
      }
      if (key == "wlimr") {
        emailAddressesWlimr.push(email);
        setEmailsWlimr([...emailAddressesWlimr]);
        setValidationErrors({});
      }
    }
  };

  const handleRemove = (chipToRemove, key) => {
    if (key === "dsr") {
      setEmailsDsr(emailAddressesDsr.filter((chip) => chip != chipToRemove));
    }
    if (key === "wsr") {
      setEmailsWsr(emailAddressesWsr.filter((chip) => chip != chipToRemove));
    }
    if (key === "msr") {
      setEmailsMsr(emailAddressesMsr.filter((chip) => chip != chipToRemove));
    }
    if (key === "dlimr") {
      setEmailsDlimr(
        emailAddressesDlimr.filter((chip) => chip != chipToRemove)
      );
    }
    if (key === "wlimr") {
      setEmailsWlimr(
        emailAddressesWlimr.filter((chip) => chip != chipToRemove)
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      dsr: emailReportingType.includes("DSR"),
      wsr: emailReportingType.includes("WSR"),
      msr: emailReportingType.includes("MSR"),
      dlimr: emailReportingType.includes("DLIMR"),
      wlimr: emailReportingType.includes("WLIMR"),
    },
    validationSchema: Yup.object({
      dsr: Yup.boolean().required(Constants.REPORTING_EMAIL_REQUIRED),
      wsr: Yup.boolean().required(Constants.REPORTING_EMAIL_REQUIRED),
      msr: Yup.boolean().required(Constants.REPORTING_EMAIL_REQUIRED),
      dlimr: Yup.boolean().required(Constants.REPORTING_EMAIL_REQUIRED),
      wlimr: Yup.boolean().required(Constants.REPORTING_EMAIL_REQUIRED),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      let payload = {
        ...values,
        location_id: id,
        email_reporting: true,
        dsr_emails: emailAddressesDsr.length > 0 ? emailAddressesDsr : [],
        wsr_emails: emailAddressesWsr.length > 0 ? emailAddressesWsr : [],
        msr_emails: emailAddressesMsr.length > 0 ? emailAddressesMsr : [],
        dlimr_emails: emailAddressesDlimr.length > 0 ? emailAddressesDlimr : [],
        wlimr_emails: emailAddressesWlimr.length > 0 ? emailAddressesWlimr : [],
      };
      setButtonDisable(true);
      myContext.handleLoading(true);
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const handleClose = () => {
    setViewFlag(false);
    formik.resetForm();
    if (props.onEmailClose) {
      props.onEmailClose();
    }
    setEmailsDsr([]);
    setEmailsWsr([]);
    setEmailsMsr([]);
    setEmailsDlimr([]);
    setEmailsWlimr([]);
    setValidationErrors({});
    setEmailsType([]);
  };

  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        {/* <Card
          sx={{
            boxShadow: "none",
          }}
        > */}
        {/* <CardHeader alignItems={{ base: "flex-start" }} mt={10}> */}
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={4}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
                }}
                breadCrumNames={["Locations", "Emails"]}
                breadCrumTitle={"Emails"}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup gap="2" alignSelf={"flex-end"}>
              {/* <PosFormButton
                onClick={() => {
                  // props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              /> */}
              <PosFormButton
                isDisabled={
                  buttonDisable
                    ? // ||
                      // (formik.values.dsr === true && emailAddressesDsr.length == 0) ||
                      // (formik.values.wsr === true && emailAddressesWsr.length == 0) ||
                      // (formik.values.msr === true && emailAddressesMsr.length == 0) ||
                      // (formik.values.dlimr === true &&
                      //   emailAddressesDlimr.length == 0) ||
                      // (formik.values.wlimr === true &&
                      //   emailAddressesWlimr.length == 0)
                      true
                    : false
                }
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={formik.handleSubmit}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        {/* </CardHeader> */}
        <Divider borderColor="#E6E6E6" mt={5} mb={5} />
        {/* <CardBody> */}
        <Flex
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems={{ base: "flex-start" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            ml={{ base: "0", md: "1.9rem" }}
            // mb={10}
          >
            <Heading
              as="h3"
              size="xs"
              fontWeight="500"
              fontSize="1.125rem"
              lineHeight="1.40625rem"
              letterSpacing="-0.016875rem"
              color="#010048"
            >
              Manage Summary Emails Recipients
            </Heading>
            <Text color="rgba(1, 0, 72, 0.6)" pt="1" fontSize="xs">
              Reporting emails will be sent to the following recipient list.
            </Text>
          </Box>
          <Box mr={"0.9rem"} mt={4}>
            <PosOutlineButton
              onClick={() => {
                props.navigate(Constants.MARKETING_VERIFY_EMAIL_PATH); // change verify email route
              }}
              name="Verify Email"
            ></PosOutlineButton>
          </Box>
        </Flex>
        <Stack divider={<StackDivider />} spacing="2">
          <Box>
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box
                ml={{ base: "0", md: "1.9rem" }}
                w={{ base: "100%", md: "100%" }}
              >
                <Stack direction={{ base: "column", md: "row" }}>
                  {/* <Box>
                        <Chips
                          style={{
                            backgroundColor: Colors.posviewbgcolor,
                            display: "flex",
                            padding: "0.1rem",
                            paddingLeft: "0.1rem",
                            gap: "0.1rem",
                            borderRadius: "0.1rem",
                            border: "1px solid #E3E3E3",
                          }}
                          addOnTab={true}
                          onAdd={(e) => {
                            emailValue(e.value);
                          }}
                          onRemove={(e) => {
                            handleRemove(e.value);
                          }}
                          addOnBlur={true}
                          allowDuplicate={false}
                          placeholder="Add Email Addresses"
                          value={emailAddresses}
                          // onChange={(e) => setEmails(e.value)}
                        />
                        <Text color={Colors.errorColor}>
                          {validationErrors}
                        </Text>
                        <Text color={Colors.errorColor}>
                          {formik.touched.email && formik.errors.email ? (
                            <span>{formik.errors.email}</span>
                          ) : null}
                        </Text>
                      </Box> */}
                </Stack>

                <Stack mt={8} direction={{ base: "column", md: "row" }}>
                  {/* <Box>
                    <PosSwitchButton
                      id="dsr"
                      switchValue={formik.values.dsr}
                      // onChange={(e) => {
                      //   setDailySummary(e.target.checked);
                      // }}
                      onChange={formik.handleChange}
                    />
                  </Box> */}
                  <Box
                    w={{ base: "100%", md: "35%" }}
                    // ml={{ base: "0", md: "1.5rem" }}
                  >
                    <Heading
                      as="h3"
                      size="xs"
                      fontWeight="500"
                      fontSize="1.125rem"
                      lineHeight="1.40625rem"
                      letterSpacing="-0.016875rem"
                      color="#010048"
                    >
                      Daily Summary Report
                    </Heading>
                  </Box>
                  {/* {formik.values.dsr == true ? ( */}
                  <Box>
                    <PosChipsInput
                      style={{
                        backgroundColor: Colors.posviewbgcolor,
                        display: "flex",
                        padding: "0.1rem",
                        paddingLeft: "0.1rem",
                        gap: "0.1rem",
                        borderRadius: "0.1rem",
                        border: "1px solid #E3E3E3",
                      }}
                      addOnTab={true}
                      onAdd={(e) => {
                        emailValue(e.value, "dsr");
                      }}
                      onRemove={(e) => {
                        handleRemove(e.value, "dsr");
                      }}
                      addOnBlur={true}
                      allowDuplicate={false}
                      placeholder="Add Email Addresses"
                      value={emailAddressesDsr}
                    />
                    <Text color={Colors.errorColor}>
                      {validationErrors["dsr"]}
                    </Text>
                    <Text color={Colors.errorColor}>
                      {formik.touched.email && formik.errors.email ? (
                        <span>{formik.errors.email}</span>
                      ) : null}
                    </Text>
                  </Box>
                  {/* // ) : null} */}
                </Stack>
                <Stack mt={8} direction={{ base: "column", md: "row" }}>
                  {/* <Box>
                    <PosSwitchButton
                      id="wsr"
                      switchValue={formik.values.wsr}
                      // onChange={(e) => {
                      //   setWeeklySummary(e.target.checked);
                      // }}
                      onChange={formik.handleChange}
                    />
                  </Box> */}
                  <Box
                    w={{ base: "100%", md: "35%" }}
                    // ml={{ base: "0", md: "1.5rem" }}
                  >
                    <Heading
                      as="h3"
                      size="xs"
                      fontWeight="500"
                      fontSize="1.125rem"
                      lineHeight="1.40625rem"
                      letterSpacing="-0.016875rem"
                      color="#010048"
                    >
                      Weekly Summary Report
                    </Heading>
                  </Box>
                  {/* {formik.values.wsr == true ? ( */}
                  <Box>
                    <PosChipsInput
                      style={{
                        backgroundColor: Colors.posviewbgcolor,
                        display: "flex",
                        padding: "0.1rem",
                        paddingLeft: "0.1rem",
                        gap: "0.1rem",
                        borderRadius: "0.1rem",
                        border: "1px solid #E3E3E3",
                      }}
                      addOnTab={true}
                      onAdd={(e) => {
                        emailValue(e.value, "wsr");
                      }}
                      onRemove={(e) => {
                        handleRemove(e.value, "wsr");
                      }}
                      addOnBlur={true}
                      allowDuplicate={false}
                      placeholder="Add Email Addresses"
                      value={emailAddressesWsr}
                    />
                    <Text color={Colors.errorColor}>
                      {validationErrors["wsr"]}
                    </Text>
                    <Text color={Colors.errorColor}>
                      {formik.touched.email && formik.errors.email ? (
                        <span>{formik.errors.email}</span>
                      ) : null}
                    </Text>
                  </Box>
                  {/* ) : null} */}
                </Stack>
                <Stack mt={8} direction={{ base: "column", md: "row" }}>
                  {/* <Box>
                    <PosSwitchButton
                      id="msr"
                      switchValue={formik.values.msr}
                      // onChange={(e) => {
                      //   setMonthlySummary(e.target.checked);
                      // }}
                      onChange={formik.handleChange}
                    />
                  </Box> */}
                  <Box
                    w={{ base: "100%", md: "35%" }}
                    // ml={{ base: "0", md: "1.5rem" }}
                  >
                    <Heading
                      as="h3"
                      size="xs"
                      fontWeight="500"
                      fontSize="1.125rem"
                      lineHeight="1.40625rem"
                      letterSpacing="-0.016875rem"
                      color="#010048"
                    >
                      Monthly Summary Report
                    </Heading>
                  </Box>
                  {/* {formik.values.msr == true ? ( */}
                  <Box>
                    <PosChipsInput
                      style={{
                        backgroundColor: Colors.posviewbgcolor,
                        display: "flex",
                        padding: "0.1rem",
                        paddingLeft: "0.1rem",
                        gap: "0.1rem",
                        borderRadius: "0.1rem",
                        border: "1px solid #E3E3E3",
                      }}
                      addOnTab={true}
                      onAdd={(e) => {
                        emailValue(e.value, "msr");
                      }}
                      onRemove={(e) => {
                        handleRemove(e.value, "msr");
                      }}
                      addOnBlur={true}
                      allowDuplicate={false}
                      placeholder="Add Email Addresses"
                      value={emailAddressesMsr}
                    />
                    <Text color={Colors.errorColor}>
                      {validationErrors["msr"]}
                    </Text>
                    <Text color={Colors.errorColor}>
                      {formik.touched.email && formik.errors.email ? (
                        <span>{formik.errors.email}</span>
                      ) : null}
                    </Text>
                  </Box>
                  {/* ) : null} */}
                </Stack>
                <Stack mt={8} direction={{ base: "column", md: "row" }}>
                  {/* <Box>
                    <PosSwitchButton
                      id="dlimr"
                      switchValue={formik.values.dlimr}
                      // onChange={(e) => {
                      //   setDailyLow(e.target.checked);
                      // }}
                      onChange={formik.handleChange}
                    />
                  </Box> */}
                  <Box
                    w={{ base: "100%", md: "35%" }}
                    // ml={{ base: "0", md: "1.5rem" }}
                  >
                    <Heading
                      as="h3"
                      size="xs"
                      fontWeight="500"
                      fontSize="1.125rem"
                      lineHeight="1.40625rem"
                      letterSpacing="-0.016875rem"
                      color="#010048"
                    >
                      Daily Low Inventory Movement Report
                    </Heading>
                  </Box>
                  {/* {formik.values.dlimr == true ? ( */}
                  <Box>
                    <PosChipsInput
                      style={{
                        backgroundColor: Colors.posviewbgcolor,
                        display: "flex",
                        padding: "0.1rem",
                        paddingLeft: "0.1rem",
                        gap: "0.1rem",
                        borderRadius: "0.1rem",
                        border: "1px solid #E3E3E3",
                      }}
                      addOnTab={true}
                      onAdd={(e) => {
                        emailValue(e.value, "dlimr");
                      }}
                      onRemove={(e) => {
                        handleRemove(e.value, "dlimr");
                      }}
                      addOnBlur={true}
                      allowDuplicate={false}
                      placeholder="Add Email Addresses"
                      value={emailAddressesDlimr}
                    />
                    <Text color={Colors.errorColor}>
                      {validationErrors["dlimr"]}
                    </Text>
                    <Text color={Colors.errorColor}>
                      {formik.touched.email && formik.errors.email ? (
                        <span>{formik.errors.email}</span>
                      ) : null}
                    </Text>
                  </Box>
                  {/* ) : null} */}
                </Stack>
                <Stack mt={8} direction={{ base: "column", md: "row" }}>
                  {/* <Box>
                    <PosSwitchButton
                      id="wlimr"
                      switchValue={formik.values.wlimr}
                      onChange={formik.handleChange}
                    />
                  </Box> */}
                  <Box
                    w={{ base: "100%", md: "35%" }}
                    // ml={{ base: "0", md: "1.5rem" }}
                  >
                    <Heading
                      as="h3"
                      size="xs"
                      fontWeight="500"
                      fontSize="1.125rem"
                      lineHeight="1.40625rem"
                      letterSpacing="-0.016875rem"
                      color="#010048"
                    >
                      Weekly Low Inventory Movement Report
                    </Heading>
                  </Box>
                  {/* {formik.values.wlimr == true ? ( */}
                  <Box>
                    <PosChipsInput
                      style={{
                        backgroundColor: Colors.posviewbgcolor,
                        display: "flex",
                        padding: "0.1rem",
                        paddingLeft: "0.1rem",
                        gap: "0.1rem",
                        borderRadius: "0.1rem",
                        border: "1px solid #E3E3E3",
                      }}
                      addOnTab={true}
                      onAdd={(e) => {
                        emailValue(e.value, "wlimr");
                      }}
                      onRemove={(e) => {
                        handleRemove(e.value, "wlimr");
                      }}
                      addOnBlur={true}
                      allowDuplicate={false}
                      placeholder="Add Email Addresses"
                      value={emailAddressesWlimr}
                    />
                    <Text color={Colors.errorColor}>
                      {validationErrors["wlimr"]}
                    </Text>
                    <Text color={Colors.errorColor}>
                      {formik.touched.email && formik.errors.email ? (
                        <span>{formik.errors.email}</span>
                      ) : null}
                    </Text>
                  </Box>
                  {/* ) : null} */}
                </Stack>
              </Box>
            </Flex>
          </Box>
        </Stack>
        {/* <Flex
          minWidth="max-content"
          gap="2"
          mt={{ base: "4.72rem", md: "4.72rem" }}
        >
          <Spacer />
          <ButtonGroup>
            <PosFormButton
              onClick={() => {
                // props.navigate(Constants.PRODUCT_LIST_PATH);
                handleClose();
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                buttonDisable
                  ? // ||
                    // (formik.values.dsr === true && emailAddressesDsr.length == 0) ||
                    // (formik.values.wsr === true && emailAddressesWsr.length == 0) ||
                    // (formik.values.msr === true && emailAddressesMsr.length == 0) ||
                    // (formik.values.dlimr === true &&
                    //   emailAddressesDlimr.length == 0) ||
                    // (formik.values.wlimr === true &&
                    //   emailAddressesWlimr.length == 0)
                    true
                  : false
              }
              buttonsubmit={"Save Changes"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </ButtonGroup>
        </Flex> */}
        {/* </CardBody>
        </Card> */}
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(LocationEmailSettings);
