import {
  Alert,
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  VStack
} from "@chakra-ui/react";
import Colors from "../../assets/colors/Colors";
import deactiveImage from "../../assets/images/flat-color-icons_cancel.svg";
const PosDialogAlert = (props) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={props.leastDestructiveRef}
      onClose={props.onClose}
      isOpen={props.isOpen}
      isCentered
      autoFocus={false}
    >
      {/* <AlertDialogOverlay /> */}
      <AlertDialogContent
        height={props.alertDialogHeight ? props.alertDialogHeight : "10.125rem"}
        width={"32rem"}
      >
        <Alert
          variant="left-accent"
          bg={Colors.loginAuthBackground}
          pt={6}
          boxShadow={"0px 4px 30px 0px #4c4c4c40"}
          colorScheme={Colors.posTextInfo}
        >
          <Flex direction={"column"}>
            <HStack justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Image
                boxSize={"2.063rem"}
                src={deactiveImage}
                alt="deactiveImage"
              />
              <VStack
                justifyContent={"flex-start"}
                alignItems="flex-start"
                gap={0}
              >
                <Text
                  fontSize={"1.125rem"}
                  fontWeight={500}
                  letterSpacing={"-0.27px"}
                  color={Colors.posTextInfo}
                >
                  {props.title}
                </Text>
                <Text
                  fontSize={"0.94rem"}
                  fontWeight={300}
                  letterSpacing={"-0.225px"}
                  color={Colors.posTextInfo}
                >
                  {props.discription}
                </Text>
              </VStack>
            </HStack>
            <AlertDialogFooter gap={2} mt={2} ml={"12.5rem"}>
              {props.footerSingleButton == true ? (
                <Button
                  variant="outline"
                  borderColor={Colors.posTextInfo}
                  color={Colors.posTextInfo}
                  onClick={props.onClick}
                  fontSize={"0.75rem"}
                  fontWeight={400}
                  letterSpacing={"-0.18px"}
                  width={"5.32rem"}
                  isDisabled={props.isDisabled}
                >
                  {props.buttonNames}
                </Button>
              ) : (
                <>
                  <Button
                    variant="outline"
                    onClick={props.onClose}
                    width={"5.32rem"}
                    fontSize={"0.75rem"}
                    fontWeight={400}
                    letterSpacing={"-0.18px"}
                    isDisabled={props.isDisabled}
                    isActive={false}
                  >
                    {props.buttonNames[0]}
                  </Button>
                  <Button
                    variant="outline"
                    borderColor={Colors.posTextInfo}
                    color={Colors.posTextInfo}
                    onClick={props.onClick}
                    fontSize={"0.75rem"}
                    fontWeight={400}
                    letterSpacing={"-0.18px"}
                    width={"5.32rem"}
                    isDisabled={props.isDisabled}
                  >
                    {props.buttonNames[1]}
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </Flex>
        </Alert>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default PosDialogAlert;
