import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
  useLocation
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosLable,
  PosTostMessage,
  PosFormButton
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getStateListForSelectedCountry } from "./VendorService";

const VendorView = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { id } = useParams();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedPhone, setPhoneNumber] = useState("");
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state

  const findAndReturnValue = (array, criteria, valueToReturn) => {
    const foundObject = array.find((item) => item.value == criteria);
    if (foundObject) {
      return foundObject[valueToReturn];
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          const arrayCountries = Object.keys(
            loaderResponse?.data?.data?.countries
          )?.map((key) => ({
            label: loaderResponse?.data?.data?.countries[key],
            value: key,
          }));
          setCountry(arrayCountries);
          if (
            loaderResponse?.data?.data?.vendor?.country &&
            null != loaderResponse?.data?.data?.vendor?.country
          ) {
            setSelectedCountry(
              findAndReturnValue(
                arrayCountries,
                loaderResponse?.data?.data?.vendor?.country,
                "label"
              )
            );
            handleSelectedCountry(loaderResponse?.data?.data?.vendor?.country);
          }

          if (
            loaderResponse?.data?.data?.vendor?.state &&
            null != loaderResponse?.data?.data?.vendor?.state &&
            "N.A." != loaderResponse?.data?.data?.vendor?.state
          ) {
            setStatesAvailability(0);
            setSelectedState(loaderResponse?.data?.data?.vendor?.state);
          }
          if (
            null != loaderResponse?.data?.data?.vendor?.phone &&
            "" != loaderResponse?.data?.data?.vendor?.phone
          ) {
            let newVal = loaderResponse?.data?.data?.vendor?.phone
              .toString()
              .replace(/\D/g, "");
            if (
              loaderResponse?.data?.data?.vendor?.phone.toString().length === 0
            ) {
              newVal = "";
            } else if (
              loaderResponse?.data?.data?.vendor?.phone.toString().length <= 3
            ) {
              newVal = newVal.replace(/^(\d{0,3})/, "($1)");
            } else if (
              loaderResponse?.data?.data?.vendor?.phone.toString().length <= 6
            ) {
              newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
            } else if (
              loaderResponse?.data?.data?.vendor?.phone.toString().length <= 10
            ) {
              newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "($1) $2-$3"
              );
            } else {
              newVal = newVal.substring(0, 10);
              newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                "($1) $2-$3"
              );
            }
            setPhoneNumber(newVal);
          }
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
    // if (actionRun.current === true) {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.VENDOR_LIST, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.VENDOR_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
      // else {
      //   let actionData = actionResponse;
      //   myContext.handleLoading(false);
      //   error({ actionData });
      // }
    }
    // return () => {
    //   actionRun.current = false;
    // };
    // }
  }, [loaderResponse, actionResponse]);

  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A.") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };

  const handleSelectedCountry = (value) => {
    getStateListForSelectedCountry(value)
      .then((response) => {
        if (
          response.data[Constants.FLAGE] !== null &&
          response.data[Constants.FLAGE] === true
        ) {
          if (undefined != response.data.data.states) {
            const arrayStates = Object.keys(response.data.data.states).map(
              (key) => ({
                label: response.data.data.states[key],
                value: key,
              })
            );
            setStates(arrayStates);
            setStatesAvailability(1);
            if (
              loaderResponse?.data?.data?.vendor?.state &&
              null != loaderResponse?.data?.data?.vendor?.state &&
              "N.A." != loaderResponse?.data?.data?.vendor?.state
            ) {
              setSelectedState(
                findAndReturnValue(
                  arrayStates,
                  loaderResponse?.data?.data?.vendor?.state,
                  "label"
                )
              );
              setStatesAvailability(0);
            } else {
              setSelectedState("N.A.");
            }
          } else {
            setStates([]);
            setStatesAvailability(1);
            setSelectedState("N.A.");
          }
        } else {
          let actionData = response;
          error({ actionData });
          myContext.handleLoading(false);
        }
      })
      .catch((error) => {
        let actionData = error;
        error({ actionData });
        myContext.handleLoading(false);
        console.error("Error fetching user data:", error);
      });
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined != loaderResponse?.data?.data?.vendor?.name &&
        null != loaderResponse?.data?.data?.vendor?.name
          ? loaderResponse?.data?.data?.vendor?.name
          : "",
      email:
        undefined != loaderResponse?.data?.data?.vendor?.email &&
        null != loaderResponse?.data?.data?.vendor?.email
          ? loaderResponse?.data?.data?.vendor?.email
          : "",
      address:
        undefined != loaderResponse?.data?.data?.vendor?.address &&
        null != loaderResponse?.data?.data?.vendor?.address
          ? loaderResponse?.data?.data?.vendor?.address
          : "",
      contact_person:
        undefined !== loaderResponse?.data?.data?.vendor?.contact_person &&
        null !== loaderResponse?.data?.data?.vendor?.contact_person
          ? loaderResponse?.data?.data?.vendor?.contact_person
          : "",
      country:
        undefined !== loaderResponse?.data?.data?.vendor?.country &&
        null != loaderResponse?.data?.data?.vendor?.country
          ? loaderResponse?.data?.data?.vendor?.country
          : "",
      state:
        undefined !== loaderResponse?.data?.data?.vendor?.state &&
        null != loaderResponse?.data?.data?.vendor?.state
          ? loaderResponse?.data?.data?.vendor?.state
          : "",
      city:
        undefined !== loaderResponse?.data?.data?.vendor?.city &&
        null !== loaderResponse?.data?.data?.vendor?.city
          ? loaderResponse?.data?.data?.vendor?.city
          : "",
      phone:
        undefined !== loaderResponse?.data?.data?.vendor?.phone &&
        null !== loaderResponse?.data?.data?.vendor?.phone
          ? loaderResponse?.data?.data?.vendor?.phone
          : "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(Constants.VENDOR_EMAIL_REQUIRED)
        .email(Constants.VENDOR_EMAIL_NOT_VALID)
        .max(254, Constants.VENDOR_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
      name: Yup.string()
        .trim()
        .required(Constants.VENDOR_NAME_REQUIRED)
        .max(254, Constants.VENDOR_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER),
      address: Yup.string()
        .trim()
        .max(5000, Constants.VENDOR_ADDRESS_LESS_THEN_OR_EQUAL_5000_CHARACTER),
      contact_person: Yup.string()
        .trim()
        .max(254, Constants.VENDOR_CONTACT_LESS_THEN_OR_EQUAL_255_CHARACTER),
      city: Yup.string()
        .trim()
        .max(191, Constants.VENDOR_CITY_LESS_THEN_OR_EQUAL_191_CHARACTER),
      phone: Yup.number()
        .integer(Constants.VENDOR_PHONE_NOT_VALID)
        .typeError(Constants.VENDOR_PHONE_NOT_VALID)
        .min(10000, Constants.VENDOR_PHONE_MUST_BE_5_DIGITS)
        .max(99999999999999, Constants.VENDOR_PHONE_LESS_THAN_15_DIGITS),
    }),
    onSubmit: (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        country: selectedCountry,
        state: selectedState,
        stateNotAvailable: stateAvailable,
      };
      setButtonDisable(true);
      let path = Constants.VENDOR_LIST + id;
      actionRun.current = true;
      submit(payload, {
        method: Constants.PUT,
        path: path,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        formik.errors.email ||
        formik.errors.address ||
        formik.errors.contact_person ||
        formik.errors.city ||
        formik.errors.phone ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "3.5rem" }}
          gap={1}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.VENDOR_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Vendors", "View Vendor"]}
            breadCrumTitle={"View Vendor"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.VENDOR_LIST,{
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
          </Stack>
        </Flex>
        <Box
          onKeyDown={handleKeyDown}
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            fontSize="1.125rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Vendor Details
          </Text>
          <Stack spacing={1} mt="0.31rem">
            <PosLable
              fontSize={"0.9375rem"}
              fontWeight={"500"}
              label={true}
              name={"Name"}
            />
            <Text
              fontSize={"0.9375rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.posTextInfo}
              wordBreak="break-word"
            >
              {null != loaderResponse?.data?.data?.vendor?.name &&
              "" != loaderResponse?.data?.data?.vendor?.name
                ? loaderResponse?.data?.data?.vendor?.name
                : "N.A."}
            </Text>
          </Stack>
          <Stack spacing={1} mt="0.31rem">
            <PosLable fontWeight={"500"} label={true} name={"Email Address"} />
            <Text
              fontSize={"0.9375rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.posTextInfo}
              wordBreak="break-word"
            >
              {null != loaderResponse?.data?.data?.vendor?.email &&
              "" != loaderResponse?.data?.data?.vendor?.email
                ? loaderResponse?.data?.data?.vendor?.email
                : "N.A."}
            </Text>
          </Stack>
          <Stack spacing={1} mt="0.31rem">
            <PosLable
              fontWeight={"500"}
              name={"Address"}
              label={true}
            ></PosLable>
            <Text
              fontSize={"0.9375rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.posTextInfo}
              wordBreak="break-word"
            >
              {null != loaderResponse?.data?.data?.vendor?.address &&
              "" != loaderResponse?.data?.data?.vendor?.address
                ? loaderResponse?.data?.data?.vendor?.address
                : "N.A."}
            </Text>
          </Stack>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
            mt="1.15rem"
            spacing={1}
          >
            <Box w={{ base: "100%", md: "45%" }}>
              <PosLable
                label={true}
                name={"Country"}
                fontWeight={500}
              ></PosLable>
              <Text
                fontSize={"0.9375rem"}
                fontStyle={"normal"}
                fontWeight={"300"}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
                color={Colors.posTextInfo}
              >
                {null != selectedCountry && "" != selectedCountry
                  ? selectedCountry
                  : "N.A."}
              </Text>
              <PosLable
                fontWeight={"500"}
                name={"City"}
                label={true}
              ></PosLable>
              <Text
                fontSize={"0.9375rem"}
                fontStyle={"normal"}
                fontWeight={"300"}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
                color={Colors.posTextInfo}
                wordBreak="break-word"
              >
                {null != loaderResponse?.data?.data?.vendor?.city &&
                "" != loaderResponse?.data?.data?.vendor?.city
                  ? loaderResponse?.data?.data?.vendor?.city
                  : "N.A."}
              </Text>
              <Text
                color="rgba(1, 0, 72, 0.2)"
                fontWeight="300"
                fontSize="0.75rem"
                lineHeight="1.25rem"
                letterSpacing="-0.09px"
              ></Text>
            </Box>
            <Box w={{ base: "100%", md: "50%" }}>
              <PosLable name={"State"} label={true} fontWeight={500}></PosLable>
              <Text
                fontSize={"0.9375rem"}
                fontStyle={"normal"}
                fontWeight={"300"}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
                color={Colors.posTextInfo}
                wordBreak="break-word"
              >
                {null != selectedState && "" != selectedState
                  ? selectedState
                  : "N.A."}
              </Text>

              <PosLable
                fontWeight={"500"}
                name={"Phone Number"}
                label={true}
              ></PosLable>
              <Text
                fontSize={"0.9375rem"}
                fontStyle={"normal"}
                fontWeight={"300"}
                lineHeight={"normal"}
                letterSpacing={"-0.01rem"}
                color={Colors.posTextInfo}
                wordBreak="break-word"
              >
                {null != selectedPhone && "" != selectedPhone
                  ? "+1 " + selectedPhone
                  : "N.A."}
              </Text>
            </Box>
          </Flex>
          <Stack spacing={1}>
            <PosLable
              fontWeight={"500"}
              name={"Contact Person"}
              label={true}
            ></PosLable>
            {/* <Text
              color={Colors.taxCreateExemptEbt}
              style={{
                flexShrink: "0",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.18px",
              }}
            >
              This is vendor representative
            </Text> */}
            <Text
              fontSize={"0.9375rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              color={Colors.posTextInfo}
              wordBreak="break-word"
              mt={2}
            >
              {null != loaderResponse?.data?.data?.vendor?.contact_person &&
              "" != loaderResponse?.data?.data?.vendor?.contact_person
                ? loaderResponse?.data?.data?.vendor?.contact_person
                : "N.A."}
            </Text>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(VendorView);
