import axios from "axios";
import * as Constants from "../../constants/Constants";

export function refreshMasterAdminToken() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("master-token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          "refresh_master_admin_token",
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_LIST_PERISSION,
          },
        }
      )
      .then((res) => {
        if (undefined !== res.data && null !== res.data && "" !== res.data) {
          var data = res?.data;
          if (data.code == 200 && data.flag == true) {
            if (
              data.data.token != null &&
              data.data.token != "" &&
              data.data.ttl != null &&
              data.data.ttl != ""
            ) {
              localStorage.removeItem("master-token");
              localStorage.removeItem("ttl");
              localStorage.setItem("master-token", data.data.token);
              localStorage.setItem("ttl", data.data.ttl);
            }
          }
        }
        resolve(res)
      })
      .catch((error) => {
        resolve(error);
      });
  });
}
