import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function getTotalSalesByPaymentTypeData({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    // if (Object.fromEntries(formData).getreport === "true") {
      const payload = ({
        dateFrom: (Object.fromEntries(formData).dateFrom),
        dateTo:(Object.fromEntries(formData).dateTo),
        employee: JSON.parse(Object.fromEntries(formData).employee),
        location: JSON.parse(Object.fromEntries(formData).location),
        posStations: JSON.parse(Object.fromEntries(formData).posStations),
        paymentTypes: JSON.parse(Object.fromEntries(formData).paymentTypes),
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    // } else {
    //   const payload = JSON.stringify({
    //     location: JSON.parse(Object.fromEntries(formData).location),
    //     vendor: JSON.parse(Object.fromEntries(formData).vendor),
    //     max_in_stock: Object.fromEntries(formData).max_in_stock,
    //     min_in_stock: Object.fromEntries(formData).min_in_stock,
    //     type: Object.fromEntries(formData).type,
    //   });
    //   axios
    //     .post(
    //       Constants.REACT_APP_API_URL +
    //         process.env.REACT_APP_API_VERSION +
    //         Constants.INVENTORY_SUMMARY_REPORT_EXPORT_API_ROUTE,
    //       payload,
    //       {
    //         headers: {
    //           "content-type": Constants.CONTANT_TYPE,
    //           Authorization: "Bearer" + token,
    //           Permission:
    //             Constants.INVENTORY_SUMMARY_REPORT_EXPORT_API_PERMISSION,
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       resolve(res);
    //     })
    //     .catch((error) => {
    //       let errorData = {
    //         error: true,
    //         response: error.response,
    //         code: error.code,
    //       };
    //       resolve(errorData);
    //     });
    // }
  });
}

export function getAllLocationsAndEmp({ request, params }) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.ALL_LOCATION_AND_EMP_API_ROUTE,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.ALL_LOCATION_AND_EMP_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    });
  }


export function getPosStationOfLocation(id){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.GET_POS_STATION_OF_SELECTED_LOCATION + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}  