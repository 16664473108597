import axios from "axios";
import * as Constants from "../../../constants/Constants";
import moment from "moment";

export function getAllPurchaseOrder(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    if (payload.from_filter) {
      payload.from_filter = moment(payload.from_filter).toISOString();
    }
    if (payload.to_filter) {
      payload.to_filter = moment(payload.to_filter).toISOString();
    }
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDeactivePurchaseOrder(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_GET_DEACTIVE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_REACTIVATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function purchaseOrderDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PURCHASING_ORDER_DEACTIVE_API_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PURCHASE_ORDER_DEACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PURCHASING_ORDER_REACTIVE_API_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PURCHASE_ORDER_REACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).exportReport === "true") {
      let token = localStorage.getItem("token");
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            `${Constants.PURCHASE_ORDER_EXCEL_ROUTE + id}`,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getProductListForEdit(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_PAGINATE_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getVendors() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_GET_VENDORS_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getVendorLocationList() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_VENDOR_LOCATION_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getManualProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_MANUAL_PRODUCT_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function customerPurchaseOrder({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = {
      vendors: parseInt(Object.fromEntries(formData).vendors),
      locations: parseInt(Object.fromEntries(formData).locations),
      vendor_invoice_number: Object.fromEntries(formData).vendor_invoice_number,
      payment_due_date: Object.fromEntries(formData).payment_due_date,
      received_date: Object.fromEntries(formData).received_date,
      received: Object.fromEntries(formData).received,
      paid: Object.fromEntries(formData).paid,
      query: Object.fromEntries(formData).query,
      products: JSON.parse(Object.fromEntries(formData).products),
      quantity: Object.fromEntries(formData).quantity,
      quantityUnit: Object.fromEntries(formData).quantityUnit,
      cost: Object.fromEntries(formData).cost,
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_CREATE_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_PRODUCT_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSinglePurchaseOrder({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PURCHASING_ORDER_ROUTE + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function purchaseOrderEdit({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      id: parseInt(Object.fromEntries(formData).id),
      vendor_id: parseInt(Object.fromEntries(formData).vendor_id),
      user_id: parseInt(Object.fromEntries(formData).user_id),
      location_id: parseInt(Object.fromEntries(formData).location_id),
      vendor_invoice_number: Object.fromEntries(formData).vendor_invoice_number,
      status: Object.fromEntries(formData).status,
      payment_due_date: Object.fromEntries(formData).payment_due_date,
      received_date: Object.fromEntries(formData).received_date,
      received: Object.fromEntries(formData).received,
      paid: Object.fromEntries(formData).paid,
      query: Object.fromEntries(formData).query,
      vendors: JSON.parse(Object.fromEntries(formData).vendors),
      users: JSON.parse(Object.fromEntries(formData).users),
      locations: JSON.parse(Object.fromEntries(formData).locations),
      purchas_order_items: JSON.parse(
        Object.fromEntries(formData).purchas_order_items
      ),
      quantity: Object.fromEntries(formData).quantity,
      quantityUnit: Object.fromEntries(formData).quantityUnit,
      cost: Object.fromEntries(formData).cost,
      vendor: parseInt(Object.fromEntries(formData).vendor),
      location: parseInt(Object.fromEntries(formData).location),
      products: JSON.parse(Object.fromEntries(formData).products),
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PURCHASING_ORDER_EDIT_ROUTE + id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getExportPurchaseOrder({ id }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PURCHASE_ORDER_EXCEL_ROUTE + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getPdfPurchaseOrder({ id }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PURCHASE_ORDER_PDF_ROUTE + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function editManualProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_MANUAL_PRODUCT_EDIT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editPurchaseOrderProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_PRODUCT_EDIT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFetchProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASE_ORDER_GET_PRODUCT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductForEdit(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASE_ORDER_GET_PRODUCT_EDIT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
