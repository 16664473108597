import axios from "axios";
import * as Constants from "../../../constants/Constants";
import moment from "moment";

export function getAllTransferOrder(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    if (payload.from_filter) {
      payload.from_filter = moment(payload.from_filter).toISOString();
    }
    if (payload.to_filter) {
      payload.to_filter = moment(payload.to_filter).toISOString();
    }
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TRANSFER_ORDER_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDeactiveTransferOrder(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_GET_DEACTIVE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_REACTIVATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function transferOrderDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PURCHASING_ORDER_DEACTIVE_API_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PURCHASE_ORDER_DEACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PURCHASING_ORDER_REACTIVE_API_ROUTE +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PURCHASE_ORDER_REACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).exportReport === "true") {
      let token = localStorage.getItem("token");
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            `${Constants.PURCHASE_ORDER_EXCEL_ROUTE + id}`,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getProductListForEdit(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_PAGINATE_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getVendors() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_GET_VENDORS_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getLocationListTransferOrder() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_LOCATIONS_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getManualProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TRANSFER_ORDER_PRODUCT_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function customerTransferOrder({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = {
      source_location: parseInt(Object.fromEntries(formData).source_location),
      destination_location: parseInt(Object.fromEntries(formData).destination_location),
      reference_number: Object.fromEntries(formData).reference_number,
      remark: Object.fromEntries(formData).remark,
      query: Object.fromEntries(formData).query,
      products: JSON.parse(Object.fromEntries(formData).products),
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TRANSFER_ORDER_CREATE_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TRANSFER_ORDER_PRODUCT_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSingleTransferOrder({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.TRANSFER_ORDER_ROUTE + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function transferOrderEdit({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      source_location: parseInt(Object.fromEntries(formData).source_location),
      destination_location: parseInt(Object.fromEntries(formData).destination_location),
      reference_number: Object.fromEntries(formData).reference_number,
      status: Object.fromEntries(formData).status,
      remark: Object.fromEntries(formData).remark,
      transfer_order_items: JSON.parse(Object.fromEntries(formData).transfer_order_items),
      products: JSON.parse(Object.fromEntries(formData).products),
    };
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.TRANSFER_ORDER_EDIT_API_ROUTE + id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getExportTransferOrder({ id }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PURCHASE_ORDER_EXCEL_ROUTE + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getPdfTransferOrder({ id }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PURCHASE_ORDER_PDF_ROUTE + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PURCHASE_ORDER_VIEW_ALL_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function editManualProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.TRANSFER_ORDER_MANUAL_PRODUCT_EDIT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editTransferOrderProductList(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASING_ORDER_PRODUCT_EDIT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFetchProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASE_ORDER_GET_PRODUCT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductForEdit(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PURCHASE_ORDER_GET_PRODUCT_EDIT_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TRANSFER_ORDER_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
