import { CopyIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Tippy from "@tippyjs/react";
import { Tooltip } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import "tippy.js/dist/tippy.css";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDialogAlert,
  PosListHeader,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
const UserApiAuthDetails = (props) => {
  const { addToast } = PosTostMessage();
  const [show, setShow] = useState(false);
  const [existsToken, setExistsToken] = useState(null);
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const submit = useSubmit();
  const actionResponse = useActionData();
  const [keyExist, setKeyExist] = useState(null);
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loaderResponse = useLoaderData();
  const { error } = PosErrorHook();
  const myContext = useContext(AppContext);
  let apiToken = "";
  if (keyExist === 1 && existsToken) {
    apiToken = show ? existsToken.client_secret : existsToken.hidden_key;
  }

  // const handleCopyClick = () => {
  //   navigator.clipboard.writeText(existsToken.client_secret);
  //   addToast({
  //     alertStatus: Constants.TOAST_TYPE_SUCESS,
  //     alertTitle: "API",
  //     alertDescription: "API Key Copied Successfully.",
  //   });
  // };
  const handleCopyClick = () => {
    // navigator.clipboard.writeText(rewardLink);
    // addToast({
    //   alertStatus: Constants.TOAST_TYPE_SUCESS,
    //   alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
    //   alertDescription: "Reward card create link copied successfully.",
    // });
    const textArea = document.createElement("textarea");
    textArea.value = existsToken.client_secret;
    textArea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      addToast({
        alertStatus: Constants.TOAST_TYPE_SUCESS,
        alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
        alertDescription: "API Key Copied Successfully.",
      });
    } catch (err) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_SUCESS,
        alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
        alertDescription: "Failed to copy text to clipboard.",
      });
    }
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      // navigator.clipboard.writeText(rewardLink)
      // .then(() => {
      //   addToast({
      //     alertStatus: Constants.TOAST_TYPE_SUCESS,
      //     alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
      //     alertDescription: "Reward card create link copied successfully.",
      //   });
      // })
      // .catch((err) => {
      //   console.error('Failed to copy text to clipboard', err);
      //   addToast({
      //     alertStatus: Constants.TOAST_TYPE_SUCESS,
      //     alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
      //     alertDescription: 'Failed to copy text to clipboard.',
      //   });
      // });
    }
  };
  const handleClick = () => setShow(!show);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setExistsToken(loaderResponse.data.data.existsToken);
          setKeyExist(loaderResponse.data.data.keyExist);
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
        effectRun.current = false;
        // };
      }
    }
    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          actionResponse.data[Constants.FLAGE] !== null &&
          actionResponse.data[Constants.FLAGE] === true
        ) {
          if (actionResponse.data.message == "Token revoked successfully.") {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "API",
              alertDescription: actionResponse.data.message,
            });
            myContext.handleLoading(false);
            onClose();
            effectRun.current = true;
          } else {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: "API",
              alertDescription: "Token generated successfully.",
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            effectRun.current = true;
          }
        }
      }
      actionRun.current = false;
    }
  }, [loaderResponse, actionResponse]);

  const handleRevokeClick = () => {
    myContext.handleLoading(true);
    actionRun.current = true;
    let data = {
      client_secret: existsToken.client_secret,
      type: "Revoke",
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.INTEGRATION_API_PATH,
    });
  };
  const handleModelOpen = () => {
    onOpen();
  };
  const handleGenerateToken = () => {
    setButtonDisable(true);
    myContext.handleLoading(true);
    actionRun.current = true;
    let data = {
      client_secret: existsToken,
      type: "Generate",
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.INTEGRATION_API_PATH,
    });
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <PosDialogAlert
        alertDialogHeight={"auto"}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        title={"Revoke Token"}
        discription={
          "Revoking token will result in unauthorized access for POS terminals and APIs. Are you sure you want to revoke the token?"
        }
        onClick={handleRevokeClick}
        buttonNames={["No", "Yes"]} 
      />
       <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"API"}
          discription={"Manage your access API token for the OctoPos system"}
        /></Flex>
      <Box
        p={4}
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        {existsToken && keyExist === 1 && (
          <PosListHeader header={"API Token"} />
        )}
        {keyExist === 0 && <PosListHeader header={"Generate a API Token"} />}
        {existsToken && (
          <InputGroup size="md" w={{ base: "100%", md: "74%" }}>
            <Input
              disabled
              variant="filled"
              pr="6rem"
              value={apiToken}
              type={"text"}
            />
            <InputRightElement width="6rem" gap={1}>
              <Tooltip
                hasArrow
                label="Copy"
                bg={Colors.toolTipColor}
                borderRadius="md"
                placement="top"
              >
                <Button h="1.75rem" size="sm" onClick={handleCopyClick}>
                  <CopyIcon />
                </Button>
              </Tooltip>
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        )}
        {existsToken && keyExist === 1 && (
          <Button mt={4} onClick={handleModelOpen}>
            Revoke Token
          </Button>
        )}
        {keyExist === 0 && (
          <>
            <Text color={Colors.taxCreateExemptEbt} fontSize="xs" ml={2}>
              No API Token is Exist. To generate an API Token, please click on
              "Generate Token" Button.
            </Text>
            <Button
              isDisabled={
                buttonDisable
                // ? true
                // : false
              }
              mt={4}
              onClick={handleGenerateToken}
              ml={2}
            >
              Generate Token
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(UserApiAuthDetails);
