import { Box, Flex, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import LightModeIcon from "@mui/icons-material/LightMode";
import React, { useContext, useEffect, useState } from "react";
import Colors from "../../../assets/colors/Colors";
import welcome from "../../../assets/images/welcome.png";
import welcome_page from "../../../assets/images/welcome_page.png";
import { AppContext } from "../../../components/index";
import { WithRouter } from "../../../navigators/WithRouter";

const Welcome = (props) => {
  const [businessName, setBusinessName] = useState("");
  const [loginUserName, setLoginUserName] = useState("");
  const myContext = useContext(AppContext);

  const currentHour = new Date().getHours();
  const greetingMessage =
    currentHour >= 4 && currentHour < 12 // after 4:00AM and before 12:00PM
      ? "Good morning"
      : currentHour >= 12 && currentHour <= 19 // after 12:00PM and before 7:00PM
      ? "Good afternoon"
      : currentHour > 19 && currentHour < 22 // after 7:00PM and before 10:00PM
      ? "Good evening"
      : "Good night"; // after 10PM or before 4:00AM

  useEffect(() => {
    if (process.env.REACT_APP_DOMAIN != "vori") {
      setBusinessName("Octopos");
    } else {
      setBusinessName("Vori");
    }
    // if (
    //   undefined !== localStorage.getItem("currentUser") &&
    //   null !== localStorage.getItem("currentUser") &&
    //   "" !== localStorage.getItem("currentUser")
    // ) {
    //   let user = JSON.parse(localStorage.getItem("currentUser"));
    //   setLoginUserName(user.name);
    // }
    // setLoginUserName(myContext.userData.userName);
    myContext.handleLoading(false);
  });

  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Flex
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        alignItems={"flex-start"}
        justify="space-between"
        pb={16}
      >
        <Box>
          <Box display={"flex"} alignItems={"center"} gap={"0.31rem"}>
            {greetingMessage == "Good morning" ||
            greetingMessage == "Good afternoon" ? (
              <LightModeIcon
                color={"#FFAC33"}
                style={{ fill: "#FFAC33" }}
                w={"1.06rem"}
                h={"1.06rem"}
              />
            ) : greetingMessage == "Good evening" ||
              greetingMessage == "Good night" ? (
              <BedtimeIcon
                color={"#010048"}
                style={{ fill: "#010048" }}
                w={"1.06rem"}
                h={"1.06rem"}
              />
            ) : (
              ""
            )}
            <Text color={"#010048"} fontSize={"0.94rem"} fontWeight={"light"}>
              {greetingMessage} <span style={{ textTransform:"capitalize"}}>{myContext.userData?.userName}</span>,
            </Text>
          </Box>
          {/* <Text color={"#010048"} fontSize={"2xl"} fontWeight={"semibold"}>
            Welcome {loginUserName},
          </Text> */}
        </Box>
        <Spacer />
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Stack
          align="center"
          justify={"center"}
          bg={"#FFF"}
          pt={"6.4rem"}
          pb={"7.4rem"}
        >
          <Box>
            <Image w={390} h={280} src={welcome_page} alt="Product Image" />
          </Box>
          <Box pt={8} pb={8} maxW={"35.88rem"} mx="auto">
            <Text
              color={"#010048"}
              align={"center"}
              fontSize={"1.13rem"}
              fontStyle={"normal"}
              fontWeight={"medium"}
              lineHeight={"normal"}
              letterSpacing={"-0.02rem"}
            >
              Welcome to {businessName}
            </Text>
            <Text
              color={"#010048"}
              align={"center"}
              mt={2}
              fontSize={"0.94rem"}
              fontWeight={300}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
            >
              {
                "Welcome to Octopos Point of Sale system! We are thrilled to have you on board and excited to be a part of your business journey."
              }
            </Text>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default WithRouter(Welcome);
