import { Box, Flex, Heading, Image, Text, Spacer,useMediaQuery } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useActionData, useLoaderData, useParams,useLocation } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import defaultCouponImage from "../../../assets/images/coupon-default.png";
import {
  AppContext,
  PosBreadCrumb,
  PosLable,
  PosFormButton,
  PosTable,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
// import moment from "moment";
import moment from 'moment-timezone';
import { commonDateFormate } from "../../../helpers/utils/Utils";
const CouponView = (props) => {
  const { error } = PosErrorHook();
  const [couponData, setCouponData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const stateData = useLocation();
  const [endDate, setEndDate] = useState("");
  const loaderResponse = useLoaderData();
  const myContext = useContext(AppContext);
  const [isMobile] = useMediaQuery(
    Constants.MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH
  );
  useEffect(() => {
    if (
      undefined != loaderResponse &&
      null != loaderResponse &&
      {} != loaderResponse
    ) {
      if (
        undefined != loaderResponse?.error &&
        null != loaderResponse?.error &&
        loaderResponse?.error
      ) {
        let actionData = loaderResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        null !== loaderResponse.data[Constants.FLAGE] &&
        true === loaderResponse.data[Constants.FLAGE]
      ) {
        setCouponData(loaderResponse.data.data.coupon);
        if ("" !== loaderResponse.data.data.coupon.start_date && null !== loaderResponse.data.data.coupon.start_date ){
          const startDate = loaderResponse.data.data.coupon.start_date;
          const localTimeZone = moment.tz.guess();
          const localDate = moment.utc(startDate).tz(localTimeZone).format('MM/DD/YYYY HH:mm:ss');
          const formatedStartDate =  commonDateFormate(localDate)
          setStartDate(formatedStartDate)
        }
        if ("" !== loaderResponse.data.data.coupon.end_date && null !== loaderResponse.data.data.coupon.end_date ){
          const end_date = loaderResponse.data.data.coupon.end_date;
          const localTimeZone = moment.tz.guess();
          const localDate = moment.utc(end_date).tz(localTimeZone).format('MM/DD/YYYY HH:mm:ss');
          const formatedEndDate =  commonDateFormate(localDate)
          setEndDate(formatedEndDate);
        }
        
        setProductData(loaderResponse.data.data.coupon.products);
        let actionData = loaderResponse;
        error({ actionData });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse]);
  const columnNames = [
    {
      columnNames: "ID",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Product name",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "SKU",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Sale Price",
      sortName: "",
      width: "16.66%",
    },
    {
      columnNames: "Location",
      sortName: "",
      width: "16.66%",
    },
  ];
  const productRawData = productData.map((products) => ({
    id: products.id,
    name: products.name,
    barcode: products.barcode,
    sku: products.sku || "N.A.",
    sale_price: products.sale_price,
    location: products.location.name,
  }));
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          // ml={{ base: 5, md: 0 }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.COUPON_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Coupon ", "View Coupon Details "]}
            breadCrumTitle={"Coupon Details"}
          />
        </Flex>
        <Flex
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems={{ base: "flex-start" }}
          flexDirection={{ base: "column", md: "row" }}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          // mb={8}
        >
          <Box
            w={{ base: "100%", md: "50%" }}
            ml={{base:0 , md : 6}}
            mr={6}
            bg={Colors.posPageDataBackground}
            mb={3.5}
            p={8}
            boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
          >
            <Heading
              as={"h3"}
              color={Colors.posTextInfo}
              fontSize={"1.13rem"}
              fontStyle={"normal"}
              fontWeight={"500"}
              lineHeight={"normal"}
              letterSpacing={"-0.02rem"}
            >
              Basic Details
            </Heading>

            <PosLable
              name={"Id"}
              fontWeight={500}
              label={true}
              color={Colors.taxInfo}
            ></PosLable>
            <Text>{couponData.id}</Text>
            <PosLable
              name={"Coupon Name"}
              fontWeight={500}
              label={true}
              color={Colors.taxInfo}
            ></PosLable>
            <Text>{couponData.title}</Text>
            <PosLable
              name={"Coupon Type"}
              fontWeight={500}
              label={true}
              color={Colors.taxInfo}
            ></PosLable>
            <Text>
              {couponData.coupon_type === "ord"
                ? "Order"
                : couponData.coupon_type === "prod"
                ? "Product"
                : "N.A."}
            </Text>
            <PosLable
              name={
                "Do you want to add this Coupon into Predefined Discount Drop-down?"
              }
              fontWeight={500}
              label={true}
              color={Colors.taxInfo}
            ></PosLable>
            <Text>{couponData.is_order_dropdown === 0 ? "NO" : "YES"}</Text>
            <PosLable
              name={"Audience Type"}
              fontWeight={500}
              label={true}
              color={Colors.taxInfo}
            ></PosLable>
            <Text>
              {couponData.audiance_type === "GEN"
                ? "Generic"
                : couponData.audiance_type === "TAR"
                ? "Targeted"
                : "N.A."}
            </Text>
            {couponData.audiance_type == "GEN" &&
              couponData.is_order_dropdown == 0 && (
                <>
                  <PosLable
                    name={"Start Date"}
                    fontWeight={500}
                    label={true}
                    color={Colors.taxInfo}
                  ></PosLable>
                  <Text>{startDate}</Text>
                  <PosLable
                    name={"End Date"}
                    fontWeight={500}
                    label={true}
                    color={Colors.taxInfo}
                  ></PosLable>
                  <Text>{endDate}</Text>
                  <PosLable
                    name={"Add on Every Transaction Receipts"}
                    fontWeight={500}
                    label={true}
                    color={Colors.taxInfo}
                  ></PosLable>
                  <Text>{couponData.is_print_on_ord_rcpt == 1 ?"YES"  : "NO"}</Text>
                </>
              )}
            <PosLable
              name={"Discount Type"}
              fontWeight={500}
              label={true}
              color={Colors.taxInfo}
            ></PosLable>
            <Text>
              {couponData.discount_type === "amou"
                ? "Amount"
                : couponData.discount_type === "per"
                ? "Percentage"
                : "N.A."}
            </Text>
            <PosLable
              name={"Discount Value"}
              fontWeight={500}
              label={true}
              color={Colors.taxInfo}
            ></PosLable>
            <Text>{couponData.discount_value}</Text>
            {couponData.min_amount && (
              <>
                <PosLable
                  name={"Minimum Amount"}
                  fontWeight={500}
                  label={true}
                  color={Colors.taxInfo}
                ></PosLable>
                <Text>
                  {couponData.min_amount ? couponData.min_amount : "N.A."}
                </Text>
              </>
            )}
            {(couponData.min_amount === null ||
              couponData.min_amount === "") &&  (
              <>
                <PosLable
                  name={" Max Quantity of Product Per Coupon"}
                  fontWeight={500}
                  label={true}
                  color={Colors.taxInfo}
                ></PosLable>
                <Text>
                  {couponData.max_qty_per_coupon
                    ? couponData.max_qty_per_coupon
                    : "N.A."}
                </Text>
              </>
            )}
            {couponData.coupon_type === "prod" && (
              <>
                <PosLable
                  name={"Apply Coupon on all Products"}
                  fontWeight={500}
                  label={true}
                  color={Colors.taxInfo}
                ></PosLable>
                <Text>
                  {couponData.apply_on_all_prods === 0 ? "NO" : "YES"}
                </Text>
              </>
            )}
            {couponData.audiance_type === "GEN" &&
              couponData.is_order_dropdown === 0 && (
                <>
                  <PosLable
                    name={" Coupon Time Used"}
                    fontWeight={500}
                    label={true}
                    color={Colors.taxInfo}
                  ></PosLable>
                  <Text>
                    {couponData.time_used }
                  </Text>
                </>
              )}
          </Box>
          <Box
            w={{ base: "100%", md: "50%" }}
            ml={{ base: 0, md: 3 }}
            mr={6}
            bg={Colors.posPageDataBackground}
            mb={3.5}
            p={8}
            boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
          >
            <Heading
              as={"h3"}
              color={Colors.posTextInfo}
              fontSize={"1.13rem"}
              fontStyle={"normal"}
              fontWeight={"500"}
              lineHeight={"normal"}
              letterSpacing={"-0.02rem"}
            >
              Coupon Image
            </Heading>
            <Image
              mt={"2rem"}
              h={56}
              w={56}
              //   src={Constants.S3_BUCKET_COUPON_IMAGE + couponData.image}
              //   src={defaultCouponImage}
              src={
                couponData.image
                  ? `${Constants.S3_BUCKET_COUPON_IMAGE}${couponData.image}`
                  : defaultCouponImage
              }
              alt="Coupon Image"
            />
          </Box>
        </Flex>
        {productData.length > 0 && (
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
            // pt={{ base: "1.5rem", md: "1.5rem" }}
            pb={{ base: "1.5rem", md: "0" }}
            pl={{ base: "1rem", lg: "1.88rem" }}
            pr={{ base: "0.5rem", md: "1.88rem" }}
            mb={4}
          >
            <Box
              w={{ base: "100%", md: "100%" }}
              ml={{base:0 , md : 6}}
              bg={Colors.posPageDataBackground}
              mb={3.5}
              p={8}
              boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
            >
              <Heading
                as={"h3"}
                color={Colors.posTextInfo}
                fontSize={"1.13rem"}
                fontStyle={"normal"}
                fontWeight={"500"}
                lineHeight={"normal"}
                letterSpacing={"-0.02rem"}
                mb={"2rem"}
              >
                Product Details
              </Heading>
              <Box maxHeight={"31.25rem"} overflow={"auto"}>
                <PosTable
                  columnNames={columnNames}
                  rawdata={productRawData}
                  footerHide={true}
                  noAction={true}
                />
              </Box>
            </Box>
          </Flex>
        )}
        <Flex
          mb={"2rem"}
          mr={{base:2 ,md:"2rem"}}
        >
          <Spacer />
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.COUPON_LIST, {
                state: stateData.state,
              });
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
        </Flex>
      </Box>
    </Flex>
  );
};
export default WithRouter(CouponView);
