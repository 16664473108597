// import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import { DeleteIcon as ChakraDeleteIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  Table as ChakraTable,
  TableContainer as ChakraTableContainer,
  Divider,
  Flex,
  Heading,
  Highlight,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
  VStack,
  Alert,
  AlertDescription,
} from "@chakra-ui/react";
import warnignIcon from "../../assets/images/Ellipse3.svg";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import { MuiColorInput } from "mui-color-input";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState } from "react";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import { Mtheme } from "../../../src/theme/index";
import Colors from "../../assets/colors/Colors";
import toolTip from "../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosDropDown,
  PosFormButton,
  PosImageCropper,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosSearch,
  PosSwitchButton,
  PosTab,
  PosTextArea,
  PosTostMessage,
} from "../../components/index";
import * as Constants from "../../constants/Constants";
import {
  calculateDiscountedPrice,
  processExpandedBarcodeFormat,
  reverseCalculateDiscountedPrice,
  twofix,
} from "../../helpers/utils/Utils";
import { PosErrorHook } from "../../hooks";
import { WithRouter } from "../../navigators/WithRouter";
import {
  getVendorList,
  showProductData,
  updateProduct,
} from "../../pages/core/product/ProductService";

export const RenderderData = (props) => {
  return (
    <ListItem>
      <Stack spacing={"0.5rem"}>
        <Text
          fontSize={"0.94rem"}
          fontStyle={"normal"}
          fontWeight={"500"}
          lineHeight={"normal"}
          letterSpacing={"-0.01rem"}
          color={Colors.orderViewLabel}
        >
          {props.title}
        </Text>
        <Text
          fontSize={"0.94rem"}
          fontStyle={"normal"}
          fontWeight={"300"}
          lineHeight={"normal"}
          letterSpacing={"-0.01rem"}
          color={Colors.posTextInfo}
        >
          {props.value}
        </Text>
      </Stack>
    </ListItem>
  );
};
const ProductEditDrawer = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonDisableVendor, setButtonDisableVendor] = useState(false);
  const { error } = PosErrorHook();
  const [isMobile] = useMediaQuery("(max-width: 992px)");
  const [currentTab, setCurrentTab] = useState(0);
  const tabNames = [
    "In-Store",
    "Additional Attributes",
    "Unit of Measures",
    "Price Books",
    "Analytics",
    "Online Ordering",
  ];
  const [backgroundColor, setBackgroundColor] = useState(
    Constants.DEFAULT_BACKGROUND_COLOR
  );
  const [borderColor, setBorderColor] = useState(
    Constants.DEFAULT_BORDER_COLOR
  );
  const [textColor, setTextColor] = useState(Constants.DEFAULT_TEXT_COLOR);
  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [prodImage, setProdImage] = useState("");
  const [oldImageBase64, setOldImageBase64] = useState("");
  const [newImageUploadedFlag, setNewImageUploadedFlag] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const [vendors, setVendor] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [vendorscard, setVendorsCard] = useState([]);
  const [productSales, setProductSales] = useState([]);
  const [productRefund, setProductRefund] = useState([]);
  const [priceBook, setPriceBook] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [selectedModifierSets, setSelectedModifierSets] = useState([]);
  const [selectedTareContainers, setSelectedTareContainers] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [allTouchedField, setAllTouchedField] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [modifierSets, setModifierSets] = useState([]);
  const [tareContainers, setTareContainers] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checked, setChecked] = React.useState([1]);
  const [unitOfMeasures, setUnitOfMeasures] = useState([]);
  const [selectedBaseUnit, setSelectedBaseUnit] = useState([]);
  const [defaultSelectedUnit, setDefaultSelectedUnit] = useState([]);
  const [selectedDefaultSellingUnit, setSelectedDefaultSellingUnit] = useState(
    []
  );
  const [
    selectedDefaultPurchasingUnit,
    setSelectedDefaultPurchasingUnit,
  ] = useState([]);
  const autoCompleteActive = useRef(true);
  const [inputValueAuto, setInputValueAuto] = useState("");
  const [open, setOpen] = useState(false);
  const [autoSelectedUnits, setAutoSelectedUnits] = useState("");
  const [options, setOptions] = useState([]);
  const inputRefs = useRef([]);
  const [prodData, setProdData] = useState([]);
  const [modifiedViewDatas, setModifiedViewDatas] = useState([]);
  const [unit, setUnit] = useState("");
  const [
    disableAdditionalAttributes,
    setDisableAdditionalAttributes,
  ] = useState(false);
  const stateRefLength = useRef(modifiedViewDatas.length);

  const [selectedValue, setSelectedValue] = useState("");
  const [vendorNext, setVendorNext] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedVendorList, setSelectedVendorList] = useState([]);
  const [itemCode, setItemCode] = useState("");
  const [itemCodeError, setItemCodeError] = useState("");
  const [additional_barcodes, setAdditionalBarcodes] = useState([]);
  const [prodCount, setProdCount] = useState(0);
  const [cashDiscountPercentage, setCashDiscountPercentage] = useState(0);
  const [barcodeType, setBarcodeType] = useState("NOR");
  const [cardLabel, setCardLabel] = useState("Retail");
  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 255) {
      setItemCode(inputValue);
      setItemCodeError("");
    } else {
      setItemCodeError(Constants.PRODUCT_VENDOR_ITEM_CODE_MAX_ERROR);
    }
  };
  const handleRadioChange = (value) => () => {
    const checkExistsItems = vendorscard.filter((item) => item.id == value.id);
    if (checkExistsItems.length == 1) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.PRODUCT_VENDOR_ALREADY_EXISTS,
      });
      setSelectedValue("");
    } else {
      setSelectedValue(value.id);
      setSelectedVendorList(value);
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const filteredVendors = vendors.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchText.toLowerCase())
  );
  useEffect(() => {
    if (props.id && props.id != 0 && props.editFlag) {
      // Call fetchData only when isOpen becomes true and effectRun is already true
      fetchData(props.id);
      // setInStore(true);
      setCurrentTab(0);
    }
    return () => {};
  }, [props.editFlag]);
  const fetchData = async (id) => {
    try {
      showProductData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            // myContext.handleLoading(false);
            setProducts(response.data.data);
            setProdCount(response?.data?.data?.prod_count);
            setVendorsCard(response.data.data.selected_vendors);
            setProductSales(response?.data?.data?.sales);
            setProductRefund(response?.data?.data?.refund);
            setPriceBook(response?.data?.data?.PriceBooksData);
            setCashDiscountPercentage(
              response?.data?.data?.cash_discount_percentage
            );
            // Set Card Label Retail Or Card
            setCardLabel(response?.data?.data?.card_label);
            formik.setFieldValue(
              "card_label",
              response?.data?.data?.card_label
            );
            // Set Barcode Type
            setBarcodeType(response?.data?.data?.barcode_type);
            formik.setValues({
              name: response?.data?.data.product.name,
              description: response?.data?.data?.product?.description,
              sku: response?.data?.data?.product?.sku,
              barcode: response?.data?.data?.product?.barcode,
              // selectedLocations: selectedLocations,
              selectedLocations: response?.data?.data?.product?.location,
              // location: products?.product?.location?.name,
              // in_stock_qty: response?.data?.data?.product?.in_stock_qty,
              in_stock_qty:
                null != response?.data?.data?.product?.in_stock_qty &&
                "" != response?.data?.data?.product?.in_stock_qty
                  ? response?.data?.data?.product?.in_stock_qty
                  : "",
              sale_price: response?.data?.data?.product?.sale_price,
              cash_price:
                response?.data?.data?.cash_discount_percentage > 0
                  ? reverseCalculateDiscountedPrice(
                      response?.data?.data?.product?.sale_price,
                      response?.data?.data?.cash_discount_percentage
                    )
                  : response?.data?.data?.product?.sale_price,
              online_ordering_price:
                null != response?.data?.data?.product?.online_ordering_price &&
                "" != response?.data?.data?.product?.online_ordering_price
                  ? response?.data?.data?.product?.online_ordering_price
                  : "",
              cost:
                null != response?.data?.data?.product?.cost &&
                "" != response?.data?.data?.product?.cost &&
                0 != response?.data?.data?.product?.cost
                  ? response?.data?.data?.product?.cost
                  : "",
              cost_per_case:
                null != response?.data?.data?.product?.cost_per_case &&
                "" != response?.data?.data?.product?.cost_per_case &&
                0 != response?.data?.data?.product?.cost_per_case
                  ? response?.data?.data?.product?.cost_per_case
                  : "",
              additional_cost:
                null != response?.data?.data?.product?.additional_cost &&
                "" != response?.data?.data?.product?.additional_cost &&
                0 != response?.data?.data?.product?.additional_cost
                  ? response?.data?.data?.product?.additional_cost
                  : "",
              no_of_eaches:
                null != response?.data?.data?.product?.no_of_eaches &&
                "" != response?.data?.data?.product?.no_of_eaches &&
                0 != response?.data?.data?.product?.no_of_eaches
                  ? response?.data?.data?.product?.no_of_eaches
                  : "",
              custom_per:
                null != response?.data?.data?.product?.custom_per &&
                "" != response?.data?.data?.product?.custom_per &&
                0 != response?.data?.data?.product?.custom_per
                  ? response?.data?.data?.product?.custom_per
                  : "",
              threshold:
                null != response?.data?.data?.product?.threshold &&
                "" != response?.data?.data?.product?.threshold
                  ? response?.data?.data?.product?.threshold
                  : "",
              // modifierset: "",
              selectedModifierSets: response?.data?.data?.selected_modifierSet,
              selectedTaxes: response?.data?.data?.selected_tax,
              // tare_containers: "",
              selectedTareContainers:
                response?.data?.data?.selected_tare_containers,
              selectedBaseUnit: response?.data?.data?.product?.base_unit_id,
              selectedDefaultSellingUnit:
                response?.data?.data?.product?.selling_unit_id,
              selectedDefaultPurchasingUnit:
                response?.data?.data?.product?.purchasing_unit_id,
              reward_points:
                null != response?.data?.data?.product?.reward_points &&
                "" != response?.data?.data?.product?.reward_points
                  ? response?.data?.data?.product?.reward_points
                  : "",
              selectedCategories: response?.data?.data?.role_list,
              sold_by_weight:
                response?.data?.data?.product?.sold_by_weight === 0
                  ? false
                  : true,
              exclude_ebt:
                response?.data?.data?.product?.exclude_ebt === 0 ? false : true,
              prompt_qty:
                response?.data?.data?.product?.prompt_qty === 0 ? false : true,
              is_variable_price:
                response?.data?.data?.product?.is_variable_price === 0
                  ? false
                  : true,
              online_is_featured_product:
                response?.data?.data?.product?.online_is_featured_product === 0
                  ? false
                  : true,
              is_exclude_kds:
                response?.data?.data?.product?.is_exclude_kds === 0
                  ? false
                  : true,
              age_verification:
                response?.data?.data?.product?.age_verification === 0
                  ? false
                  : true,
              min_age_verification:
                null != response?.data?.data?.product?.min_age_verification &&
                "" != response?.data?.data?.product?.min_age_verification
                  ? response?.data?.data?.product?.min_age_verification
                  : "",
              background_color: response?.data?.data?.product?.background_color,
              border_color: response?.data?.data?.product?.border_color,
              text_color: response?.data?.data?.product?.text_color,
              selectedVendors: response.data.data.selected_vendors,
              item_code: "",
              edit_for_all_loc:
                response?.data?.data?.prod_count > 1 ? true : false,
            });
            const cat = response?.data?.data?.role;
            const loc = response?.data?.data?.location_list;
            const tax = response?.data?.data?.tax_list;
            const modifierSet = response?.data?.data?.modifierSet_list;
            const tareContainers = response?.data?.data?.tare_containers_list;
            const unitOfMeasures = response?.data?.data?.unitOfMeasures;
            const categoriesData = cat.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesData);

            setAdditionalBarcodes(
              response?.data?.data?.product_barcode.map(
                (option) => option.barcode
              )
            );
            // formik.setFieldValue(
            //   "selectedLocations",
            //   locationsData?.map((option) => option.value)
            // );
            const locationsData = loc.map((loc) => ({
              label: loc.name,
              value: { name: loc.name, id: loc.id },
            }));
            setLocations(locationsData);
            // formik.setFieldValue(
            //   "selectedLocations",
            //   locationsData?.map((option) => option.value)
            // );
            // setSelectedLocations(locationsData.map(option => option.value));
            // setSelectedLocations(locationsData);

            const taxesData = tax.map((tax) => ({
              label: tax.name + " : " + tax.percentage,
              value: { name: tax.name, percentage: tax.percentage, id: tax.id },
            }));
            setTaxes(taxesData);

            const modifierSetData = modifierSet.map((modifierSet) => ({
              label: modifierSet.name,
              value: { name: modifierSet.name, id: modifierSet.id },
            }));
            setModifierSets(modifierSetData);

            const tareContainerData = tareContainers.map((tareContainers) => ({
              label: tareContainers.name + " : " + tareContainers.weight,
              value: {
                name: tareContainers.name,
                weight: tareContainers.weight,
                id: tareContainers.id,
              },
            }));
            setTareContainers(tareContainerData);

            const unitOfMeasuresData = unitOfMeasures?.map((uom) => ({
              label: uom.name,
              value: { name: uom.name, id: uom.id },
            }));
            setUnitOfMeasures(unitOfMeasuresData);
            let Optionsset = [];

            if (
              null != response?.data?.data?.product?.base_unit_id &&
              "" != response?.data?.data?.product?.base_unit_id
            ) {
              const matchingBaseUnitOfMeasure = unitOfMeasures?.find(
                (uom) => uom.id == response?.data?.data?.product.base_unit_id
              );
              setSelectedBaseUnit(matchingBaseUnitOfMeasure);
              Optionsset.push({
                label: matchingBaseUnitOfMeasure.name,
                value: {
                  name: matchingBaseUnitOfMeasure.name,
                  id: matchingBaseUnitOfMeasure.id,
                },
              });
              formik.setFieldValue(
                "selectedBaseUnit",
                matchingBaseUnitOfMeasure?.name
              );
            }
            if (
              response?.data?.data?.selected_unitOfMeasures != null &&
              "" != response?.data?.data?.selected_unitOfMeasures &&
              response?.data?.data?.selected_unitOfMeasures.length > 0
            ) {
              // Map over selected_unitOfMeasures and create a new array of modified units
              const modifiedUnitsArray = response?.data?.data?.selected_unitOfMeasures?.map(
                (item, index) => {
                  let total;

                  if (
                    item.base_qty > 0 &&
                    item.pricing > 0 &&
                    response?.data?.data?.product?.sale_price > 0
                  ) {
                    const parsedBaseQty = parseFloat(item.base_qty);
                    const parsedPricing = parseFloat(item.pricing);
                    const parsedSalePrice = parseFloat(
                      response?.data?.data?.product?.sale_price
                    );
                    if (
                      !isNaN(parsedBaseQty) &&
                      !isNaN(parsedPricing) &&
                      !isNaN(parsedSalePrice) &&
                      parsedBaseQty > 0 &&
                      parsedPricing > 0 &&
                      parsedSalePrice > 0
                    ) {
                      total = parseFloat(
                        100 -
                          (parsedPricing * 100) /
                            (parsedSalePrice * parsedBaseQty)
                      ).toFixed(2);
                    } else {
                      total = "N.A.";
                    }
                  } else {
                    total = "N.A.";
                  }

                  let inStock;
                  if (
                    item.base_qty > 0 &&
                    response?.data?.data?.product.in_stock_qty > 0
                  ) {
                    // Ensure valid parsing of numbers before division
                    const parsedInStockQty = parseFloat(
                      response?.data?.data?.product.in_stock_qty
                    );
                    const parsedBaseQty = parseFloat(item.base_qty);

                    if (
                      !isNaN(parsedInStockQty) &&
                      !isNaN(parsedBaseQty) &&
                      parsedBaseQty > 0
                    ) {
                      const calculatedInStock =
                        parsedInStockQty / parsedBaseQty;
                      inStock = parseFloat(calculatedInStock).toFixed(2);
                    } else {
                      inStock = "N.A.";
                    }
                  } else {
                    inStock = "N.A.";
                  }

                  const modifiedUnits = {
                    ...item,
                    // main_id: item.id,
                    id: item.unit_of_measure_data.id,
                    label: item.unit_of_measure_data.name,
                    margin: total,
                    in_stock: inStock,
                  };

                  // Push to Optionsset
                  Optionsset.push({
                    label: item.unit_of_measure_data.name,
                    value: {
                      name: item.unit_of_measure_data.name,
                      id: item.unit_of_measure_data.id,
                    },
                  });
                  // Return the modified unit for the new array
                  return modifiedUnits;
                }
              );

              // Push an object with id: '' to the modifiedUnitsArray
              modifiedUnitsArray.push({
                // main_id:"",
                id: "",
                label: "",
                base_qty: "",
                pricing: "",
                margin: "N.A.", //change for double autocomplete
                upc: "",
                in_stock: "N.A.", //change for double autocomplete
              });
              setDisableAdditionalAttributes(true);
              // Set the modified data using the newly created array
              setModifiedViewDatas(modifiedUnitsArray);
            }

            setDefaultSelectedUnit(Optionsset);

            if (
              null != response?.data?.data?.product?.selling_unit_id &&
              "" != response?.data?.data?.product?.selling_unit_id
            ) {
              const matchingSellingUnitOfMeasure = unitOfMeasures?.find(
                (uom) => uom.id == response?.data?.data?.product.selling_unit_id
              );
              setSelectedDefaultSellingUnit(matchingSellingUnitOfMeasure);
              formik.setFieldValue(
                "selectedDefaultSellingUnit",
                matchingSellingUnitOfMeasure?.name
              );
            }

            if (
              null != response?.data?.data?.product?.purchasing_unit_id &&
              "" != response?.data?.data?.product?.purchasing_unit_id
            ) {
              const matchingPurchasingUnitOfMeasure = unitOfMeasures?.find(
                (uom) =>
                  uom.id == response?.data?.data?.product.purchasing_unit_id
              );
              setSelectedDefaultPurchasingUnit(matchingPurchasingUnitOfMeasure);
              formik.setFieldValue(
                "selectedDefaultPurchasingUnit",
                matchingPurchasingUnitOfMeasure?.name
              );
            }

            if (
              null != response?.data?.data?.product?.background_color &&
              "" != response?.data?.data?.product?.background_color
            ) {
              setBackgroundColor(
                response?.data?.data?.product?.background_color
              );
            }
            if (
              null != response?.data?.data?.product?.border_color &&
              "" != response?.data?.data?.product?.border_color
            ) {
              setBorderColor(response?.data?.data?.product?.border_color);
            }
            if (
              null != response?.data?.data?.product?.text_color &&
              "" != response?.data?.data?.product?.text_color
            ) {
              setTextColor(response?.data?.data?.product?.text_color);
            }
            if (
              null != response.data?.data?.product.image_base64 &&
              "" != response.data?.data?.product.image_base64
            ) {
              var explodedImageName = response.data?.data?.product.image.split(
                "."
              );
              var imageType = explodedImageName[explodedImageName.length - 1];
              var actualBase64 =
                "data:" +
                imageType +
                ";base64," +
                response.data?.data?.product.image_base64;
              setOldImageBase64(actualBase64);
              setBase64OfUploadedImg(actualBase64);
              setImageUploadedFlag(1);
              setNewImageUploadedFlag(0);
              setCroppedImgDetails((previousState) => {
                return {
                  ...previousState,
                  filename: response.data?.data?.product?.image,
                  filetype: imageType,
                  filesize: "",
                  base64: response.data?.data?.product?.image_base64,
                };
              });
            }
            props.onEditOpen();
            myContext.handleLoading(false);
          } else {
            // let actionData = response?.data;
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // let actionData = err?.response?.data;
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const columnUnitsViewNames = [
    {
      columnNames: "Related Units",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Base Qty",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Pricing($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Diff(%)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "UPC",
      sortName: "",
      width: "",
    },
    {
      columnNames: "In Stock",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];

  const handleTabChange = (index) => {
    setCurrentTab(index);
  };
  const handleBarcodeClick = () => {
    const newBarcode = formik.values.additional;
    if (
      formik.values.barcode == formik.values.additional ||
      additional_barcodes.includes(formik.values.additional)
    ) {
      formik.setErrors({
        ...formik.errors,
        additional: "Barcode already exists in this product.",
      });
      return;
      // } else if(modifiedViewDatas.some((item)=>item.upc == newBarcode)){
      //   formik.setErrors({
      //     ...formik.errors,
      //     additional: "Barcode already exists in upc.",
      //   });
    } else if (!additional_barcodes.includes(newBarcode)) {
      // If not found, update the state with the new array including the new barcode
      setAdditionalBarcodes([...additional_barcodes, newBarcode]);
      formik.values.additional = "";
    }
  };

  const handleDelete = (index) => {
    const updatedBarcodes = additional_barcodes.filter((_, i) => i !== index);
    // const updatedBarcodes1 = additional_barcodes.filter((_, i) => i == index);

    setAdditionalBarcodes(updatedBarcodes);
    // if(modifiedViewDatas.some((item)=>item.upc == updatedBarcodes1[0])){
    //   modifiedViewDatas.map((item)=>{
    //     if(item.upc == updatedBarcodes1[0]){
    //       item.upcErrors=''
    //     }
    //   })
    //   // formik.setErrors({
    //   //   ...formik.errors,
    //   //   additional: "",
    //   // });
    // }
  };
  const handleChangeBackgroundColor = (bgColor) => {
    bgColor = bgColor.toUpperCase();
    setBackgroundColor(bgColor);
  };
  const handleChangeBorderColor = (borderColor) => {
    borderColor = borderColor.toUpperCase();
    setBorderColor(borderColor);
  };
  const handleChangeTextColor = (textColor) => {
    textColor = textColor.toUpperCase();
    setTextColor(textColor);
  };

  const handleCallback = (base64Details, fileDetails, imgUploadStatus) => {
    if (
      (null == base64Details || "" == base64Details) &&
      (null == fileDetails || "" == fileDetails) &&
      (null == imgUploadStatus || "" == imgUploadStatus)
    ) {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: "",
          filetype: "",
          filesize: "",
          base64: "",
        };
      });
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    } else {
      if (imgUploadStatus.newImgUploadFlag == 1) {
        setCroppedImgDetails((previousState) => {
          return {
            ...previousState,
            filename: fileDetails["name"],
            filetype: fileDetails["type"],
            filesize: fileDetails["size"],
            base64: base64Details.toDataURL().split(",")[1],
          };
        });
        setBase64OfUploadedImg(base64Details.toDataURL());
        setImageUploadedFlag(1);
        setNewImageUploadedFlag(1);
      } else {
        setCroppedImgDetails((previousState) => {
          return {
            ...previousState,
            filename: null,
            filetype: null,
            filesize: null,
            base64: base64Details.toDataURL().split(",")[1],
          };
        });
        setBase64OfUploadedImg(base64Details.toDataURL());
        setImageUploadedFlag(1);
        setNewImageUploadedFlag(0);
      }
    }
  };
  const handleImage = (img) => {
    setProdImage(img);
    setOldImageBase64("");
  };

  useEffect(() => {
    const sortedPriceBooks =
      priceBook?.sort((a, b) => {
        const aValue = a.price_book_sale_price;
        const bValue = b.price_book_sale_price;
        return aValue && !bValue ? -1 : !aValue && bValue ? 1 : 0;
      }) || [];
    formik.setFieldValue(
      "priceBooks",
      sortedPriceBooks.map((item) => ({
        id: item.price_book_id,
        price_book_name: item.price_book_name,
        product_barcode: products?.product?.barcode,
        price_book_sale_price: item.price_book_sale_price,
      }))
    );
  }, [priceBook]);

  const formik = useFormik({
    initialValues: {
      name: products?.product?.name,
      description: products?.product?.description,
      sku: products?.product?.sku,
      barcode: products?.product?.barcode,
      // selectedLocations: selectedLocations,
      selectedLocations: products?.product?.location,
      // location: products?.product?.location?.name,
      // in_stock_qty: products?.product?.in_stock_qty,
      in_stock_qty:
        null != products?.product?.in_stock_qty &&
        "" != products?.product?.in_stock_qty
          ? products?.product?.in_stock_qty
          : "",
      sale_price: products?.product?.sale_price,
      cash_price:
        cashDiscountPercentage > 0
          ? reverseCalculateDiscountedPrice(
              products?.product?.sale_price,
              cashDiscountPercentage
            )
          : products?.product?.sale_price,
      online_ordering_price:
        null != products?.product?.online_ordering_price &&
        "" != products?.product?.online_ordering_price
          ? products?.product?.online_ordering_price
          : "",
      cost:
        null != products?.product?.cost &&
        "" != products?.product?.cost &&
        0 != products?.product?.cost
          ? products?.product?.cost
          : "",
      cost_per_case:
        null != products?.product?.cost_per_case &&
        "" != products?.product?.cost_per_case &&
        0 != products?.product?.cost_per_case
          ? products?.product?.cost_per_case
          : "",
      additional_cost:
        null != products?.product?.additional_cost &&
        "" != products?.product?.additional_cost
          ? products?.product?.additional_cost
          : "",
      no_of_eaches:
        null != products?.product?.no_of_eaches &&
        "" != products?.product?.no_of_eaches
          ? products?.product?.no_of_eaches
          : "",
      custom_per:
        null != products?.product?.custom_per &&
        "" != products?.product?.custom_per
          ? products?.product?.custom_per
          : "",
      threshold:
        null != products?.product?.threshold &&
        "" != products?.product?.threshold
          ? products?.product?.threshold
          : "",
      // modifierset: "",
      selectedModifierSets: products?.selected_modifierSet,
      selectedTaxes: products?.selected_tax,
      // tare_containers: "",
      selectedTareContainers: products?.selected_tare_containers,
      selectedBaseUnit: selectedBaseUnit?.name,
      selectedDefaultSellingUnit: selectedDefaultSellingUnit?.name,
      selectedDefaultPurchasingUnit: selectedDefaultPurchasingUnit?.name,
      reward_points:
        null != products?.product?.reward_points &&
        "" != products?.product?.reward_points
          ? products?.product?.reward_points
          : "",
      selectedCategories: products?.role_list,
      sold_by_weight: products?.product?.sold_by_weight === 0 ? false : true,
      exclude_ebt: products?.product?.exclude_ebt === 0 ? false : true,
      prompt_qty: products?.product?.prompt_qty === 0 ? false : true,
      is_variable_price:
        products?.product?.is_variable_price === 0 ? false : true,
      online_is_featured_product:
        products?.product?.online_is_featured_product === 0 ? false : true,
      is_exclude_kds: products?.product?.is_exclude_kds === 0 ? false : true,
      age_verification:
        products?.product?.age_verification === 0 ? false : true,
      min_age_verification:
        null != products?.product?.min_age_verification &&
        "" != products?.product?.min_age_verification
          ? products?.product?.min_age_verification
          : "",
      background_color: products?.product?.background_color,
      border_color: products?.product?.border_color,
      text_color: products?.product?.text_color,
      selectedVendors: vendorscard,
      item_code: "",
      edit_for_all_loc: products?.prod_count > 1 ? true : false,
      priceBooks: priceBook?.map((item) => ({
        id: item.id,
        price_book_name: item.price_book_name,
        product_barcode: products?.product?.barcode,
        price_book_sale_price: item.price_book_sale_price,
      })),
    },
    // enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.PRODUCT_NAME_REQUIRED)
        .max(191, Constants.PRODUCT_NAME_LENGTH_GREATE_THEN_191),
      description: Yup.string()
        .nullable()
        .trim()
        .max(5000, Constants.PRODUCT_DESCRIPTION_LENGTH_GREATE_THEN_5000),
      sku: Yup.string()
        .nullable()
        .trim()
        .max(191, Constants.PRODUCT_SKU_LENGTH_GREATE_THEN_191),
      barcode: Yup.string()
        .required(Constants.PRODUCT_BARCODE_REQUIRED)
        .max(191, Constants.PRODUCT_BARCODE_LENGTH_GREATE_THEN_191),
      // barcode: Yup.string()
      //   .required(Constants.PRODUCT_BARCODE_REQUIRED)
      //   .max(191, Constants.PRODUCT_BARCODE_LENGTH_GREATE_THEN_191)
      //   .test({
      //     name: 'barcode',
      //     message: 'Barcode is already used in UPC barcode',
      //     test: (value) => {
      //         if (modifiedViewDatas?.length > 0) {
      //         // Check if every item in modifiedViewDatas has the same UPC as the input value
      //         const isUPCConsistent = modifiedViewDatas.some(item => item.upc && item.upc !== '' && item.upc == value);
      //         // If the UPCs are consistent, set the error
      //         if (isUPCConsistent) {
      //           return false;
      //         } else {
      //           return true;
      //         }
      //       }else{
      //         return true;
      //       }
      //     }
      //   }),
      additional: Yup.string()
        .nullable()
        .trim()
        .max(191, Constants.PRODUCT_ADDITIONAL_BARCODE_LENGTH_GREATE_THEN_191),
      // selectedLocations: Yup.string()
      // selectedLocations: Yup.array()
      // .min(1, "Select at least one option")
      // .required(Constants.PRODUCT_LOCATION_REQUIRED),
      selectedCategories: Yup.array().min(
        1,
        Constants.PRODUCT_CATEGORY_REQUIRED
      ),
      selectedBaseUnit: Yup.string().required(
        Constants.PRODUCT_BASE_UNIT_REQUIRED
      ),
      selectedDefaultSellingUnit: Yup.string().required(
        Constants.PRODUCT_SELLING_UNIT_REQUIRED
      ),
      selectedDefaultPurchasingUnit: Yup.string().required(
        Constants.PRODUCT_PURCHASING_UNIT_REQUIRED
      ),
      // .required(Constants.PRODUCT_CATEGORY_REQUIRED),
      selectedTaxes: Yup.array(),
      selectedModifierSets: Yup.array(),
      selectedTareContainers: Yup.array(),
      selectedVendors: Yup.array(),
      in_stock_qty: Yup.number()
        .typeError(Constants.PRODUCT_IN_STOCK_QTY_NOT_VALID_ERROR)
        // .required(Constants.PRODUCT_IN_STOCK_QTY_REQUIRED)
        .min(-999999.99, Constants.PRODUCT_IN_STOCK_QTY_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_IN_STOCK_QTY_MAX_ERROR),
      sale_price: Yup.number()
        .typeError(Constants.PRODUCT_RETAIL_PRICE_NOT_VALID_ERROR)
        .required(Constants.PRODUCT_RETAIL_PRICE_REQUIRED)
        .min(0.01, Constants.PRODUCT_RETAIL_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_RETAIL_PRICE_MAX_ERROR),
      cash_price: Yup.number()
        .typeError(Constants.PRODUCT_CASH_PRICE_NOT_VALID_ERROR)
        // .required(Constants.PRODUCT_CASH_PRICE_REQUIRED)
        .min(0.01, Constants.PRODUCT_CASH_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_CASH_PRICE_MAX_ERROR),
      online_ordering_price: Yup.number()
        .typeError(Constants.PRODUCT_ONLINE_ORDERING_PRICE_NOT_VALID_ERROR)
        .min(0.01, Constants.PRODUCT_ONLINE_ORDERING_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_ONLINE_ORDERING_PRICE_MAX_ERROR),
      cost: Yup.number()
        .typeError(Constants.PRODUCT_BASE_UNIT_COST_PRICE_NOT_VALID_ERROR)
        .min(0.01, Constants.PRODUCT_BASE_UNIT_COST_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_BASE_UNIT_COST_PRICE_MAX_ERROR),
      cost_per_case: Yup.number()
        .typeError(Constants.PRODUCT_COST_PER_CASE_NOT_VALID_ERROR)
        .min(0.01, Constants.PRODUCT_COST_PER_CASE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_COST_PER_CASE_MAX_ERROR),
      additional_cost: Yup.number()
        .typeError(
          Constants.PRODUCT_ADDITIONAL_COST_PER_CASE_PRICE_NOT_VALID_ERROR
        )
        .min(0.01, Constants.PRODUCT_ADDITIONAL_COST_PRICE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_ADDITIONAL_COST_PRICE_MAX_ERROR),
      no_of_eaches: Yup.number()
        .typeError(Constants.PRODUCT_NO_OF_BASE_UNITS_IN_A_CASE_NOT_VALID_ERROR)
        .min(0.01, Constants.PRODUCT_NO_OF_BASE_UNITS_IN_A_CASE_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_NO_OF_BASE_UNITS_IN_A_CASE_MAX_ERROR),
      custom_per: Yup.number()
        .typeError(Constants.PRODUCT_CUSTOM_MARGIN_NOT_VALID_ERROR)
        .min(1, Constants.PRODUCT_CUSTOM_MARGIN_MIN_ERROR)
        .max(100, Constants.PRODUCT_CUSTOM_MARGIN_MAX_ERROR),
      threshold: Yup.number()
        .typeError(Constants.PRODUCT_THRESHOLD_NOT_VALID_ERROR)
        .min(0.0, Constants.PRODUCT_THRESHOLD_MIN_ERROR)
        .max(999999.99, Constants.PRODUCT_THRESHOLD_MAX_ERROR),
      reward_points: Yup.string()
        .nullable()
        .matches(/^[0-9]*$/, Constants.PRODUCT_REWARD_POINTS_REGEX_ERROR)
        .test("max", Constants.PRODUCT_REWARD_POINTS_MAX_ERROR, function(
          value
        ) {
          if (!value || value.trim() === "") return true; // Skip validation if value is empty
          const numericValue = parseInt(value, 10);
          return numericValue <= 2147483647;
        }),
      min_age_verification: Yup.number()
        .typeError(Constants.PRODUCT_MIN_AGE_VERIFICATION_ERROR)
        .when("age_verification", {
          is: true,
          then: (schema) =>
            schema
              .required(Constants.PRODUCT_MIN_AGE_VERIFICATION_REQUIRED)
              .integer(Constants.PRODUCT_MIN_AGE_VERIFICATION_ERROR)
              .min(1, Constants.PRODUCT_MIN_AGE_VERIFICATION_MIN_ERROR)
              .max(100, Constants.PRODUCT_MIN_AGE_VERIFICATION_MAX_ERROR),
          otherwise: (schema) => schema.optional(),
        }),
      item_code: Yup.string()
        .nullable()
        .trim()
        .max(255, Constants.PRODUCT_VENDOR_ITEM_CODE_MAX_ERROR),
      priceBooks: Yup.array().of(
        Yup.object().shape({
          price_book_sale_price: Yup.number()
            .typeError(Constants.PRODUCT_AMOUNT_NOT_VALID_ERROR)
            .min(0.01, Constants.PRODUCT_AMOUNT_MIN_ERROR)
            .max(999999.99, Constants.PRODUCT_AMOUNT_MAX_ERROR),
        })
      ),
      // edit_for_all_loc: false,
    }),
    onSubmit: async (values) => {
      // actionRun.current = true;
      var unitOfMeasuresArray = [];
      var flag = false;
      if (modifiedViewDatas?.length > 0) {
        var removeAdditionalField = modifiedViewDatas.filter((pr) => {
          return pr.id !== undefined && pr.id !== "" && pr.id !== null;
        });
        // Initialize an empty array to store the results
        // Loop through the array and extract the required fields
        var upcSet = new Set(); // Set to track unique UPCs
        removeAdditionalField.forEach((item) => {
          if (null != item && "" != item) {
            if (upcSet.has(item.upc)) {
              flag = true;

              return addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.PRODUCT_MODULE,
                alertDescription: `Duplicate UPC found: ${item.upc}`,
              });
            } else {
              if (
                null != item?.baseQtyErrors &&
                "" != item?.baseQtyErrors &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: `${item?.baseQtyErrors}`,
                });
              } else if (
                null != item?.base_qty &&
                "" != item?.base_qty &&
                item.base_qty <= 0 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_BASE_QTY_MIN_VALUE,
                });
              } else if (
                null != item?.base_qty &&
                "" != item?.base_qty &&
                item.base_qty > 999999.99 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_BASE_QTY_MAX_VALUES,
                });
              } else if (
                null != item?.pricingErrors &&
                "" != item?.pricingErrors &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: `${item?.pricingErrors}`,
                });
              } else if (
                null != item?.pricing &&
                "" != item?.pricing &&
                item.pricing <= 0 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_PRICING_MIN_VALUE,
                });
              } else if (
                null != item?.pricing &&
                "" != item?.pricing &&
                item.pricing > 999999.99 &&
                flag == false
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: Constants.PRODUCT_PRICING_MAX_VALUES,
                });
              } else if (
                null != item?.upcErrors &&
                "" != item?.upcErrors &&
                flag == false &&
                item?.upcErrors ==
                  Constants.PRODUCT_UPC_BARCODE_LENGTH_GREATE_THEN_191
              ) {
                flag = true;
                return addToast({
                  alertStatus: Constants.TOAST_TYPE_WARNING,
                  alertTitle: Constants.PRODUCT_MODULE,
                  alertDescription: `${item?.upcErrors}`,
                });
              } else {
                unitOfMeasuresArray.push({
                  id: item?.main_id || "",
                  unit_of_measure_id: item.id,
                  label: item.label,
                  base_qty: item.base_qty,
                  pricing: item.pricing,
                  upc: item.upc || "",
                });
                if (null != item.upc && "" != item.upc) {
                  upcSet.add(item.upc); // Add UPC to the set after pushing
                }
              }
            }
          }
        });
      }
      const isDuplicate = unitOfMeasuresArray?.some(
        (item) => item?.upc === values?.barcode
      );

      if (isDuplicate && flag == false) {
        flag = true;
        return addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription: `Primary Barcode: ${values.barcode} is located in the UPC.`,
        });
      }
      additional_barcodes?.forEach((barcode) => {
        if (barcode == values.barcode && flag == false) {
          flag = true;
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.PRODUCT_MODULE,
            alertDescription: `Primary barcode: ${barcode} is located in the Additional barcode.`,
          });
        }
      });

      const isDuplicateUOM = additional_barcodes?.some((barcode) => {
        return unitOfMeasuresArray?.some(
          (unit) => unit.upc && unit.upc !== "" && unit.upc === barcode
        );
      });

      if (isDuplicateUOM && flag == false) {
        flag = true;
        return addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription: `Additional Barcode is located in the UPC.`,
        });
      }

      if (flag == false) {
        myContext.handleLoading(true);
        setButtonDisable(true);
        values.background_color = backgroundColor;
        values.border_color = borderColor;
        values.text_color = textColor;
        values.location_id = products?.product?.location.id;

        const additional = additional_barcodes.map((barcode) => ({
          product_id: products.product.id,
          location_id: products.product.location.id,
          barcode: barcode,
        }));
        if (imageUploadedFlag == 1) {
          if (newImageUploadedFlag == 1) {
            var imageDetails = {
              profilepic: croppedImgDetails,
            };
            values.productImage = imageDetails;
          } else {
            values.productImage = {};
            values.image_base64 = croppedImgDetails.base64;
          }
        } else {
          var imageDetails = {
            profilepic: {},
          };
          values.productImage = imageDetails;
          values.image_base64 = null;
        }
        values.additional = additional;
        let payload = {
          ...values,
          location: products.product.location,
          id: products.product.id,
          tax: "",
          age_verification_selected: values.age_verification,
          role: values.selectedCategories,
          selected_tax: values.selectedTaxes,
          selected_modifierSet: values.selectedModifierSets,
          selected_tare_containers: values.selectedTareContainers,
          vendors: vendorscard,
          product_related_uom: unitOfMeasuresArray,
        };
        updateProduct(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setBase64OfUploadedImg("");
              setOldImageBase64("");
              setImageUploadedFlag(0);
              addToast({
                alertStatus: Constants.TOAST_TYPE_SUCESS,
                alertTitle: "Product Updated",
                alertDescription: response.data.message,
              });
              // formik.resetForm();
              myContext.handleLoading(false);
              setButtonDisable(false);
              props.fetchData();
              handleClose();
              //list refresh
            } else {
              formik.setFieldValue("additional", "");
              myContext.handleLoading(false);
              setButtonDisable(false);
              // let actionData = response?.data;
              let actionData = response;
              error({ actionData });
            }
          })
          .catch((err) => {
            formik.setFieldValue("additional", "");
            setButtonDisable(false);
            myContext.handleLoading(false);
            // let actionData = err?.response?.data;
            let actionData = err;
            error({ actionData });
          });
      }
    },
  });

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      const isUPCConsistent = modifiedViewDatas?.some(
        (item) => item.id == e.value.id
      );

      if (isUPCConsistent) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription:
            "A base unit has already been selected in the Related Units.",
        });
      } else {
        if (selectedBaseUnit?.id != e.value.id) {
          if (selectedBaseUnit?.id == selectedDefaultSellingUnit?.id) {
            setSelectedDefaultSellingUnit("");
            formik.setFieldValue("selectedDefaultSellingUnit", "");
            // formik.setFieldTouched("selectedDefaultSellingUnit", true);
            // formik.setErrors({
            //   ...formik.errors,
            //   selectedDefaultSellingUnit: "required.",
            // });
          }
          if (selectedBaseUnit?.id == selectedDefaultPurchasingUnit?.id) {
            setSelectedDefaultPurchasingUnit("");
            formik.setFieldValue("selectedDefaultPurchasingUnit", "");
            // formik.setFieldTouched("selectedDefaultPurchasingUnit", true);
            // formik.setErrors({
            //   ...formik.errors,
            //   selectedDefaultPurchasingUnit: "required.",
            // });
          }
        }
        setSelectedBaseUnit(e.value);
        formik.setFieldValue("selectedBaseUnit", e.value.name);
        if (
          e.value.name !== undefined &&
          e.value.name !== null &&
          e.value.name !== ""
        ) {
          formik.setFieldTouched("selectedBaseUnit", false);
        }

        const unitOfMeasuresData = [
          {
            label: e.value.name,
            value: { name: e.value.name, id: e.value.id },
          },
        ];

        // Map and filter through modifiedViewDatas
        const updatedUnitOfMeasuresData = modifiedViewDatas
          ?.filter((uom) => uom.id !== "") // Filter out falsy or empty id items
          .map((uom) => ({
            label: uom.label,
            value: { name: uom.label, id: uom.id },
          }));

        // Merge unitOfMeasuresData with updatedUnitOfMeasuresData
        const combinedUnitOfMeasuresData = [
          ...updatedUnitOfMeasuresData, // Spread the mapped data from modifiedViewDatas
          ...unitOfMeasuresData, // Add the new data from unitOfMeasuresData
        ];
        // Update the state with the combined data
        setDefaultSelectedUnit(combinedUnitOfMeasuresData);
        if (updatedUnitOfMeasuresData?.length == 0) {
          handleOptionSelectDefaultSellingUnit(e);
          handleOptionSelectDefaultPurchasingUnit(e);
        }
      }
    }
  };

  const handleOptionSelectDefaultSellingUnit = (e) => {
    if (undefined !== e.value) {
      setSelectedDefaultSellingUnit(e.value);
      formik.setFieldValue("selectedDefaultSellingUnit", e.value.name);
      if (
        e.value.name !== undefined &&
        e.value.name !== null &&
        e.value.name !== ""
      ) {
        formik.setFieldTouched("selectedDefaultSellingUnit", false);
      }
    }
  };

  const handleOptionSelectDefaultPurchasingUnit = (e) => {
    if (undefined !== e.value) {
      setSelectedDefaultPurchasingUnit(e.value);
      formik.setFieldValue("selectedDefaultPurchasingUnit", e.value.name);
      if (
        e.value.name !== undefined &&
        e.value.name !== null &&
        e.value.name !== ""
      ) {
        formik.setFieldTouched("selectedDefaultPurchasingUnit", false);
      }
    }
  };

  const onSetUnitofMeasureSingleLine = (value) => {
    if (null != value && "" != value) {
      if (formik?.values?.selectedModifierSets?.length > 0) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription:
            "You can not attach Unit of Measure because there is a Modifier Sets in a Item. Please remove the Modifier Sets in order to attach Unit of Measure.",
        });
      } else if (formik.values?.sold_by_weight == true) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription:
            "You can not attach Unit of Measure because there is a Sold By Weight in a Item. Please turn off the Sold By Weight in order to attach Unit of Measure.",
        });
      } else if (formik.values?.prompt_qty == true) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription:
            "You can not attach Unit of Measure because there is a Prompt Quantity in a Item. Please turn off the Prompt Quantity in order to attach Unit of Measure.",
        });
      } else if (formik.values?.is_variable_price == true) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRODUCT_MODULE,
          alertDescription:
            "You can not attach Unit of Measure because there is a Variable Price in a Item. Please turn off the Variable Price in order to attach Unit of Measure.",
        });
      } else {
        if (value.value?.id == selectedBaseUnit?.id) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.PRODUCT_MODULE,
            alertDescription: `Cannot select ${value.label} as Related Unit because it's a Base Unit.`,
          });
          setAutoSelectedUnits("");
          setInputValueAuto("");
        } else {
          let obj = {
            // main_id:"",
            id: value.value?.id,
            label: value.label,
            base_qty: "",
            pricing: "",
            margin: "N.A.", //change for double autocomplete
            upc: "",
            in_stock: "N.A.", //change for double autocomplete
            baseQtyErrors: Constants.PRODUCT_BASE_QTY_REQUIRED,
            pricingErrors: Constants.PRODUCT_PRICING_REQUIRED,
          };

          let objEmpty = {
            // main_id:"",
            id: "",
            label: "",
            base_qty: "",
            pricing: "",
            margin: "N.A.", //change for double autocomplete
            upc: "",
            in_stock: "N.A.", //change for double autocomplete
          };

          // Check if the same id already exists in the array
          let idExists = modifiedViewDatas.some((item) => item.id === obj.id);

          if (idExists) {
            // Return an error message if the id already exists
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.PRODUCT_MODULE,
              alertDescription: `${value.label} is already selected as a Related Unit.`,
            });
            setAutoSelectedUnits("");
            setInputValueAuto("");
          } else {
            // Proceed with inserting the new object if id does not exist
            let array = [...modifiedViewDatas, obj];

            // Remove objEmpty if it already exists in the array
            array = array.filter(
              (item) => JSON.stringify(item) !== JSON.stringify(objEmpty)
            );
            // Add objEmpty at the last position
            array.push(objEmpty);
            setDisableAdditionalAttributes(true);

            setModifiedViewDatas(array);

            setDefaultSelectedUnit((prevState) => {
              const updatedUnitOfMeasuresData = array
                ?.filter((uom) => uom.id != "") // Filter out items where id is empty or falsy
                .map((uom) => ({
                  label: uom.label,
                  value: { name: uom.label, id: uom.id },
                }));

              // Create a copy of the previous state
              const newState = [...prevState];
              // Loop through the updatedUnitOfMeasuresData and either replace or add
              updatedUnitOfMeasuresData.forEach((newUom) => {
                const index = newState.findIndex(
                  (existingUom) => existingUom.value.id == newUom.value.id
                );
                if (index > -1) {
                  newState[index] = newUom;
                } else {
                  newState.push(newUom);
                }
              });
              return newState;
            });
          }
        }
        // setModifiedViewDatas(unitOfMeasures.map((item) => ({
        //   // id: item.id,
        //   label: value.label,
        //   base_qty: "",
        //   pricing: "",
        //   margin: "",
        //   upc: "",
        //   in_stock:""
        // })));
      }
    }
  };
  useEffect(() => {
    if (stateRefLength.current != modifiedViewDatas.length) {
      if (inputRefs.current[modifiedViewDatas.length - 2]) {
        inputRefs.current[modifiedViewDatas.length - 2].focus();
      }
    }
  }, [modifiedViewDatas.length]);

  const handleInputChangeUOM = (event, id, field_name, blur) => {
    event.preventDefault();
    const original_barcode = processExpandedBarcodeFormat(event.target.value);
    const value =
      field_name == "upc" && barcodeType == "EXP" && blur == "blur"
        ? original_barcode
        : event.target.value;
    let index = modifiedViewDatas.findIndex((x) => x.id === id);
    if (index > -1) {
      let obj = { ...modifiedViewDatas[index] };
      //   let arrayData = Object.assign([],modifiedViewDatas.slice(0,-1))
      // let index = arrayData.findIndex((x) => x.id === id);
      // if (index > -1) {
      //   let obj = { ...arrayData[index] };
      // let arrayOfOthers = [...arrayData.slice(0,index),...arrayData.slice(index+1)]
      // arrayOfOthers.map((unit) => {
      //   if(field_name == "upc"){
      //     if(null != unit?.upc && "" != unit?.upc && "" != value && unit.upc != value && (unit.upcErrors == "UPC is already used.") && formik.values.barcode != unit.upc && !additional_barcodes?.includes(unit.upc)){
      //       let alreadyExitsBarcode = arrayOfOthers.filter(x => x.upc == unit.upc)
      //       let upc_error_find= modifiedViewDatas.findIndex(x => x.id == unit.id);
      //       if(upc_error_find > -1 && alreadyExitsBarcode.length == 1) {
      //         modifiedViewDatas[upc_error_find].upcErrors=""
      //       }
      //     }else if(null != unit?.upc && "" != unit?.upc && additional_barcodes?.includes(unit.upc)){
      //       let upc_error_find= modifiedViewDatas.findIndex(x => x.id == unit.id);
      //       if(upc_error_find > -1) {
      //         modifiedViewDatas[upc_error_find].upcErrors="UPC is already used in Additional Barcode."
      //       }
      //     }else if(null != unit?.upc && "" != unit?.upc && "" != value && value != null && value != "" && unit.upc == value) {
      //       let upc_error_find= modifiedViewDatas.findIndex(x => x.id == unit.id);
      //       if(upc_error_find > -1) {
      //         modifiedViewDatas[upc_error_find].upcErrors="UPC is already used."
      //       }
      //     // }else if(null != unit?.upc && "" != unit?.upc && "" == value) {
      //     //   let upc_error_find= modifiedViewDatas.findIndex(x => x.id == unit.id);
      //     //   if(upc_error_find > -1) {
      //     //     modifiedViewDatas[upc_error_find].upcErrors="UPC is already used."
      //     //   }
      //     }else if (value != null && value != "" && formik.values.barcode == value){
      //       let upc_error_find= modifiedViewDatas.filter(x => x.upc.length > 0).findIndex(x => x.id == unit.id);
      //       if(upc_error_find > -1) {
      //         modifiedViewDatas[upc_error_find].upcErrors="UPC is already used in Primary Barcode123."
      //       }
      //       // let upc_error_find_same= modifiedViewDatas.filter(x => x.upc.length > 0).findIndex(x => x.id == unit.id);
      //       // if(upc_error_find_same > -1) {
      //       //   modifiedViewDatas[upc_error_find_same].upcErrors="UPC is already used 456."
      //       // }
      //     }
      //   }

      // })

      obj[field_name] = value;
      switch (field_name) {
        case "base_qty":
          const parsedBaseQty = parseFloat(value);
          obj.baseQtyErrors =
            value.length === 0
              ? Constants.PRODUCT_BASE_QTY_REQUIRED
              : parseFloat(value) <= 0.0
              ? Constants.PRODUCT_BASE_QTY_MIN_VALUE
              : isNaN(parsedBaseQty) || !/^\d*\.?\d*$/.test(value)
              ? Constants.PRODUCT_PLEASE_ENTER_A_VALID_BASE_QTY
              : parseFloat(value) > 1000000
              ? Constants.PRODUCT_BASE_QTY_MAX_VALUES
              : "";
          break;
        case "pricing":
          const parsedPricing = parseFloat(value);
          obj.pricingErrors =
            value.length === 0
              ? Constants.PRODUCT_PRICING_REQUIRED
              : parseFloat(value) <= 0.0
              ? Constants.PRODUCT_PRICING_MIN_VALUE
              : isNaN(parsedPricing) || !/^\d*\.?\d*$/.test(value)
              ? Constants.PRODUCT_PLEASE_ENTER_A_VALID_PRICING
              : parseFloat(value) > 1000000
              ? Constants.PRODUCT_PRICING_MAX_VALUES
              : "";
          break;
        case "upc":
          obj.upcErrors =
            value.length > 192
              ? Constants.PRODUCT_UPC_BARCODE_LENGTH_GREATE_THEN_191
              : // : (formik.values.barcode && formik.values.barcode === value)
                // ? "UPC is already used in Primary Barcode."
                // : modifiedViewDatas?.some(item =>
                //     item.upc && item.upc !== "" && item.upc === value && item.id !== id
                //   )
                // ? "UPC is already used."
                // : additional_barcodes?.includes(value)
                // ? "UPC is already used in Additional Barcode."
                ""; // No errors if all checks pass.
          break;

        default:
          break;
      }

      const updatedArray = modifiedViewDatas.map((item, i) => {
        if (i === index) {
          // Apply margin calculation only for the updated item
          const updatedObj = { ...obj };

          // if (
          //   (updatedObj.baseQtyErrors === undefined ||
          //     updatedObj.baseQtyErrors === "") &&
          //   (updatedObj.pricingErrors === undefined ||
          //     updatedObj.pricingErrors === "") &&
          //   // formik.errors.sale_price === undefined && // Check for undefined instead of empty string
          //   updatedObj.base_qty > 0 &&
          //   updatedObj.pricing > 0 &&
          //   formik.values.sale_price > 0
          // ) {
          //   const parsedBaseQty = parseFloat(updatedObj.base_qty);
          //   const parsedPricing = parseFloat(updatedObj.pricing);
          //   const parsedSalePrice = parseFloat(formik.values.sale_price);

          //   if (
          //     !isNaN(parsedBaseQty) &&
          //     !isNaN(parsedPricing) &&
          //     !isNaN(parsedSalePrice) &&
          //     parsedBaseQty > 0 &&
          //     parsedPricing > 0 &&
          //     parsedSalePrice > 0
          //   ) {
          //     const total =
          //       100 - (parsedPricing * 100) / (parsedSalePrice * parsedBaseQty);
          //     updatedObj.margin = parseFloat(total).toFixed(2);
          //   } else {
          //     updatedObj.margin = "N.A.";
          //   }
          // } else {
          //   updatedObj.margin = "N.A.";
          // }

          if (
            !updatedObj.baseQtyErrors &&
            !updatedObj.pricingErrors &&
            // !formik.errors.sale_price &&
            updatedObj.base_qty > 0 &&
            updatedObj.pricing > 0 &&
            formik.values.sale_price > 0
          ) {
            const parsedBaseQty = parseFloat(updatedObj.base_qty);
            const parsedPricing = parseFloat(updatedObj.pricing);
            const parsedSalePrice = parseFloat(formik.values.sale_price);

            if (
              [parsedBaseQty, parsedPricing, parsedSalePrice].every(
                (val) => val > 0
              )
            ) {
              const total =
                100 - (parsedPricing * 100) / (parsedSalePrice * parsedBaseQty);
              updatedObj.margin = total.toFixed(2);
            } else {
              updatedObj.margin = "N.A.";
            }
          } else {
            updatedObj.margin = "N.A.";
          }

          if (
            (updatedObj.baseQtyErrors === undefined ||
              updatedObj.baseQtyErrors === "") &&
            formik.errors.in_stock_qty === undefined && // Check for undefined instead of empty string
            updatedObj.base_qty > 0 &&
            formik.values.in_stock_qty > 0
          ) {
            // Ensure valid parsing of numbers before division
            const parsedInStockQty = parseFloat(formik.values.in_stock_qty);
            const parsedBaseQty = parseFloat(updatedObj.base_qty);

            if (
              !isNaN(parsedInStockQty) &&
              !isNaN(parsedBaseQty) &&
              parsedBaseQty > 0
            ) {
              const inStock = parsedInStockQty / parsedBaseQty;
              updatedObj.in_stock = parseFloat(inStock).toFixed(2);
            } else {
              updatedObj.in_stock = "N.A.";
            }
          } else {
            updatedObj.in_stock = "N.A.";
          }

          return updatedObj;
        }
        return item;
      });
      // let flag = 0
      // updatedArray?.map((item, i) => {
      //   if(item.upc == formik.values.barcode) {
      //     flag = 1
      //   }
      //   if(i+1 == updatedArray.length && !flag) {
      //     delete formik.errors.barcode;
      //   }
      // });

      setModifiedViewDatas(updatedArray);
    }
  };

  const deleteById = (idToDelete) => {
    // Filter the array to remove the object with the matching id
    if (selectedDefaultSellingUnit?.id == idToDelete) {
      setSelectedDefaultSellingUnit("");
      formik.setFieldValue("selectedDefaultSellingUnit", "");
    }

    if (selectedDefaultPurchasingUnit?.id == idToDelete) {
      setSelectedDefaultPurchasingUnit("");
      formik.setFieldValue("selectedDefaultPurchasingUnit", "");
    }

    const updatedArray = modifiedViewDatas.filter(
      (item) => item.id !== idToDelete
    );
    setModifiedViewDatas(updatedArray);

    if (updatedArray?.length == 1) {
      setDisableAdditionalAttributes(false);
    }

    setDefaultSelectedUnit((prevState) => {
      // Filter out the item from the dropdown state if its id matches idToDelete
      const updatedUnitOfMeasuresData = prevState.filter(
        (item) => item.value.id !== idToDelete
      );

      // You don’t need to loop through modifiedViewDatas and update dropdown data here,
      // because you’re directly removing the deleted item from dropdown state.
      return updatedUnitOfMeasuresData;
    });
  };

  const handlePriceBookChange = (index, field, value) => {
    const validPriceRegex = /^\d*(\.\d{0,4})?$/;
    const updatedPriceBooks = [...formik.values.priceBooks];
    updatedPriceBooks[index][field] = value;
    if (validPriceRegex.test(value)) {
      formik.setFieldValue("priceBooks", updatedPriceBooks);
    }
  };

  const addVendors = (data) => {
    setButtonDisableVendor(true);
    setTimeout(() => {
      vendorscard.push({
        ...data,
        item_code: itemCode,
      });
      setSelectedVendors(data);
      setVendorNext(false);

      onClose();
      setSelectedValue("");
      setSelectedVendorList([]);
      setSearchText("");
      setButtonDisableVendor(false);
    }, 1000);
  };

  const getVendor = async () => {
    let data = {
      limit: 1000000,
      page: 0 + 1,
      order: "id",
      order_type: "desc",
      filter: "",
    };
    setItemCode("");
    setItemCodeError("");
    setVendorNext(false);
    setSelectedValue("");
    setSelectedVendorList([]);
    setSearchText("");
    setButtonDisableVendor(false);
    try {
      getVendorList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setTotalCount(response.data.data.totalcount);
            setVendor(response.data.data.data);
            onOpen();
          } else {
            // let actionData = response?.data;
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // let actionData = err?.response?.data;
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const onNext = () => {
    if (selectedValue > 0) {
      setVendorNext(true);
      setItemCode("");
      setItemCodeError("");
    }
  };

  const onClosed = () => {
    setSelectedValue("");
    setSelectedVendorList([]);
    setVendorNext(false);
    setSearchText("");
    onClose();
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    // fetchData();
  };

  const handleClear = () => {
    // clear input value and get all data
    setSearchText("");
  };

  const handleClose = () => {
    formik.resetForm();
    setSelectedValue("");
    setSelectedVendorList([]);
    setVendorNext(false);
    setSearchText("");
    setBase64OfUploadedImg("");
    setOldImageBase64("");
    setImageUploadedFlag(0);
    setProdImage("");
    setUnitOfMeasures([]);
    setAdditionalBarcodes([]);
    setModifiedViewDatas([]);
    setUnit("");
    // formik.setFieldTouched("selectedBaseUnit", false);
    setSelectedBaseUnit([]);
    setDefaultSelectedUnit([]);
    setSelectedDefaultSellingUnit([]);
    setSelectedDefaultPurchasingUnit([]);
    setDisableAdditionalAttributes(false);
    setAllTouchedField(false);
    if (props.onEditClose) {
      props.onEditClose();
    }
  };

  const onRemove = (data) => {
    let array = [];
    let index = vendorscard.findIndex((item) => item.id === data.id);
    if (index > -1) {
      array = [...vendorscard.slice(0, index), ...vendorscard.slice(index + 1)];
    }
    formik.values.selectedVendors = array;
    setVendorsCard(array);
  };

  const formatProductName = (name) => {
    if (!name) return "Product Name";
    const firstLetter = name.charAt(0).toUpperCase();
    const secondLetter = name.charAt(1).toUpperCase();
    return firstLetter + secondLetter;
  };

  // const capitalizeWords = (str) => {
  //   return str.replace(/\b\w/g, char => char.toUpperCase());
  // };

  const capitalizeWords = (str) => {
    if (!str) {
      return ""; // Return an empty string or handle the error as needed
    }
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const checkAndUpdateInStock = (inStockQty) => {
    // Iterate over modifiedViewDatas to check base_qty and update in_stock_qty if needed
    const updatedData = modifiedViewDatas.map((item) => {
      if (inStockQty != undefined && inStockQty != null && inStockQty != "") {
        if (item.base_qty > 0 && inStockQty > 0) {
          // Calculate new in_stock_qty based on base_qty or any other rule
          const uomInStock = parseFloat(inStockQty) / item.base_qty;
          item.in_stock = parseFloat(uomInStock).toFixed(2);
          // calculateInStock(item.base_qty, inStockQty);
        }
        return item;
      } else {
        item.in_stock = "N.A.";
        return item;
      }
    });

    // Update the state with the modified array
    setModifiedViewDatas(updatedData);
  };

  const checkAndUpdatePrice = (marginSalePrice) => {
    const updatedData = modifiedViewDatas.map((item) => {
      if (
        marginSalePrice != undefined &&
        marginSalePrice != null &&
        marginSalePrice != ""
      ) {
        if (item.base_qty > 0 && item.pricing > 0 && marginSalePrice > 0) {
          // Calculate new in_stock_qty based on base_qty or any other rule
          const total =
            100 -
            (parseFloat(item.pricing) * 100) /
              (parseFloat(marginSalePrice) * parseFloat(item.base_qty));
          item.margin = parseFloat(total).toFixed(2);
          // calculateInStock(item.base_qty, inStockQty);
        }
        return item;
      } else {
        item.margin = "N.A.";
        return item;
      }
    });

    // Update the state with the modified array
    setModifiedViewDatas(updatedData);
  };

  const formatedProductName = (name) => {
    const capitalized = capitalizeWords(name);
    return truncateText(capitalized, 30);
  };

  const formattingName = formatedProductName(formik.values.name);
  const formattedName = formatProductName(formik.values.name);
  const capitalizedProductName = capitalizeWords(formik.values.name);
  return (
    <PosDrawer
      visible={props.isEditOpen}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      {/* <Flex bg={Colors.loginAuthBackground} p={0}> */}
      {/* <Box flex="1"> */}
      <Box padding={2}>
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
          pb={"0.62rem"}
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 5, md: 0 }}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(Constants.PRODUCT_LIST_PATH);
                  handleClose();
                }}
                breadCrumNames={["Products", "Edit Product"]}
                breadCrumTitle={formik?.values?.name || ""}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup gap="2" alignSelf={"flex-end"}>
              {/* {prodCount > 1 ? (
                <Box>
                  <VStack
                    align={"stretch"}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Text>
                      {
                        // "Do you want to apply these changes for all location products (Edit for all Locations)?"
                        "Apply changes to all locations."
                      }
                    </Text>
                    <PosSwitchButton
                      id="edit_for_all_loc"
                      switchValue={formik.values.edit_for_all_loc}
                      onChange={formik.handleChange}
                    />
                  </VStack>
                </Box>
              ) : null} */}
              <PosFormButton
                isDisabled={buttonDisable ? true : false}
                buttonsubmit={"Submit"}
                SubmitButton={true}
                // onClick={formik.handleSubmit}
                onClick={() => {
                  if (
                    undefined != selectedDefaultSellingUnit?.id &&
                    null != selectedDefaultSellingUnit?.id &&
                    "" != selectedDefaultSellingUnit?.id &&
                    selectedDefaultSellingUnit?.id > 0 &&
                    undefined != selectedDefaultPurchasingUnit?.id &&
                    null != selectedDefaultPurchasingUnit?.id &&
                    "" != selectedDefaultPurchasingUnit?.id &&
                    selectedDefaultPurchasingUnit?.id > 0
                  ) {
                    delete formik.errors.selectedDefaultSellingUnit;
                    delete formik.errors.selectedDefaultPurchasingUnit;
                    formik.isValid = true;
                  }
                  if (!formik.isValid) {
                    if (!allTouchedField) {
                      formik.handleSubmit();
                      setAllTouchedField(true);
                    }
                    addToast({
                      alertStatus: Constants.TOAST_TYPE_ERROR,
                      alertTitle: Constants.SUBMIT_TIME_FORM_NOT_VALID_TITLE,
                      alertDescription: Constants.SUBMIT_TIME_FORM_NOT_VALID,
                    });
                  } else {
                    formik.handleSubmit();
                  }
                }}
              />
            </ButtonGroup>
          </Flex>
          {prodCount > 1 ? (
            <Box mt={"0.2rem"}>
              <Flex
                flexDirection={"row"}
                alignItems={"baseline"}
                justifyContent={"end"}
              >
                <PosSwitchButton
                  id="edit_for_all_loc"
                  switchValue={formik.values.edit_for_all_loc}
                  onChange={formik.handleChange}
                  w={"44px"}
                  height={"22px"}
                  flexShrink={"0"}
                />
                <Text fontSize={"0.94rem"} fontWeight={500} ml={"0.5rem"}>
                  {"Apply changes to all locations."}
                </Text>
              </Flex>
            </Box>
          ) : null}
        </Box>
        {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={"flex-start"}
            // pt={{ base: "1.5rem", md: "3.06rem" }}
            // pb={{ base: "1.5rem", md: "0" }}
            // pl={"1.88rem"}
            pl={{ base: "1rem", lg: "1.88rem" }}
            pr={{ base: "1rem", md: "1.88rem" }}
            gap={2}
          >
            <PosBreadCrumb
              handleClick={(i) => {
                // props.navigate(Constants.PRODUCT_LIST_PATH);
                handleClose();
              }}
              breadCrumNames={["Products", "Edit Product"]}
              breadCrumTitle={"Edit Product"}
            />
            <Spacer />
          </Flex> */}
        <Flex
          gap={"0.63rem"}
          flexDirection={!isMobile ? "row" : "column"}
          mt={!isMobile ? "1rem" : "1rem"}
          ml={!isMobile ? "2rem" : "1rem"}
          mr={!isMobile ? "2rem" : "1rem"}
          mb={!isMobile ? "1rem" : "1rem"}
        >
          <Tabs onChange={(index) => handleTabChange(index)}>
            <PosTab tabNames={tabNames} />
          </Tabs>
        </Flex>
        <Flex
          gap={!isMobile ? "0.63rem" : "0rem"}
          flexDirection={!isMobile ? "row" : "column"}
          mt={!isMobile ? "1rem" : "1rem"}
          ml={!isMobile ? "2rem" : "1rem"}
          mr={!isMobile ? "2rem" : "1rem"}
          mb={!isMobile ? "0.875rem" : "0.875rem"}
        >
          {/* <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} /> */}

          {currentTab === 0 ? (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                //   boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Basic Details
                      </Heading>
                    </Box>
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          requiredLabel={true}
                          name={"Primary Barcode (ISBN, UPC, GTIN)"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="This is the Barcode that will be associated with the specific product. The system will ring up the associated Product when this barcode is ringed up."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                      </Flex>
                      <PosInput
                        id="barcode"
                        // placeholder={"Enter a Primary barcode"}
                        handleInputChange={formik.handleChange}
                        // handleInputChange={(e) => {
                        //   formik.setFieldValue(
                        //     "barcode",
                        //     e?.target?.value
                        //   );
                        // // Set the updated array back into state
                        // // setModifiedViewDatas(updatedViewDatas);
                        // let upc_error_find= modifiedViewDatas?.filter((pr) => pr.id !== undefined && pr.id !== "" && pr.id !== null).findIndex(x => x.upc == e?.target?.value);
                        // if(upc_error_find > -1) {
                        //   modifiedViewDatas[upc_error_find].upcErrors="13245"
                        // }

                        // const upcMap = {};
                        // const duplicates = [];

                        // modifiedViewDatas.forEach(item => {
                        //     const upc = item.upc.trim();  // Trim spaces
                        //     if (upc !== "") {  // Ignore empty UPCs
                        //         if (upcMap[upc]) {
                        //             upcMap[upc].push(item);  // Add duplicate items
                        //         } else {
                        //             upcMap[upc] = [item];  // Initialize with first occurrence
                        //         }
                        //     }
                        // });

                        // // Filter items that have duplicate UPCs
                        // for (const key in upcMap) {
                        //     if (upcMap[key].length > 1) {  // Only push duplicates
                        //         duplicates.push(...upcMap[key]);
                        //     }
                        // }

                        // const updatedData = modifiedViewDatas.map((item) => {
                        //   // Find if this `item` exists in the `duplicates` array based on the `id`
                        //   const duplicate = duplicates.find(dup => dup.id === item.id);

                        //   // If the item is found in `duplicates`, add/update the `upcErrors` field
                        //   if (duplicate) {
                        //     return {
                        //       ...item,  // Spread original item properties
                        //       upcErrors: "UPC is already used."  // Add or update the `upcErrors` field
                        //     };
                        //   }

                        //   // If no match is found, return the original item unchanged
                        //   return item;
                        // });

                        // // Update the state with the new modified data
                        // setModifiedViewDatas(updatedData);

                        //  let upc_error_find_not_same= modifiedViewDatas?.filter((pr) => pr.id !== undefined && pr.id !== "" && pr.id !== null).findIndex(x => x.upc != e?.target?.value);
                        //   if(upc_error_find_not_same > -1) {
                        //     modifiedViewDatas[upc_error_find_not_same].upcErrors="789777777777777777777777"
                        //   }
                        // .map((item,index)=>{
                        //   if (item.upc && item.upc !== "" && item.upc !== null) {
                        //     if (item.upc !== e?.target?.value) {

                        //       // item.upcErrors = '';
                        //     } else {
                        //       item.upcErrors = 'Barcode is same';
                        //     }
                        //   }
                        // })
                        // }}
                        inputValue={formik.values.barcode || ""}
                        // handleBlur={formik.handleBlur}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "barcode",
                            barcodeType == "EXP"
                              ? processExpandedBarcodeFormat(
                                  e?.target?.value.trim()
                                )
                              : e?.target?.value.trim()
                          );
                        }}
                        posInput={true}
                        inputType={"text"}
                        inputError={
                          formik.touched.barcode && formik.errors.barcode
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.barcode && formik.errors.barcode ? (
                          <span>{formik.errors.barcode}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box display={"flex"}>
                      <Box w={"100%"}>
                        <PosLable
                          label={true}
                          name={"Additional Barcode"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="additional"
                          // placeholder={"Enter an Additional Barcode"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.additional || ""}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "additional",
                              barcodeType == "EXP"
                                ? processExpandedBarcodeFormat(
                                    formik.values.additional?.trim()
                                  )
                                : formik.values.additional?.trim()
                            );
                          }}
                          posInput={true}
                          inputType={"text"}
                          inputError={
                            formik.touched.additional &&
                            formik.errors.additional
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.additional &&
                          formik.errors.additional ? (
                            <span>{formik.errors.additional}</span>
                          ) : null}
                        </Text>
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={
                          formik.errors?.additional?.length > 0
                            ? "center"
                            : "flex-end"
                        }
                        justifyContent={"center"}
                        w={"3rem"}
                        ml={"1rem"}
                      >
                        <IconButton
                          onClick={handleBarcodeClick}
                          sx={{
                            marginTop:
                              formik.errors?.additional?.length > 0
                                ? "2rem"
                                : 0,
                          }}
                          isRound={true}
                          isDisabled={
                            formik.values?.additional?.length > 0 &&
                            !formik.errors.additional
                              ? false
                              : true
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    {additional_barcodes.length > 0 ? (
                      <Box mt="2rem">
                        <DataTable value={additional_barcodes}>
                          <Column
                            field="additional_barcodes"
                            header="Additional Barcode"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "80%",
                            }}
                            body={(rowData) => {
                              const barcode = rowData
                                ? rowData.toLocaleString(undefined)
                                : "N.A";
                              return barcode.length > 25
                                ? barcode.match(/.{1,25}/g).join("\n")
                                : barcode;
                            }}
                          />

                          <Column
                            field="delete"
                            header="Delete"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            // bodyStyle={{ padding: "1rem 0.1rem" }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "20%",
                            }}
                            body={(rowData) => (
                              <DeleteIcon
                                bg={Colors.posCancleButtonRed}
                                onClick={() =>
                                  handleDelete(
                                    additional_barcodes.indexOf(rowData)
                                  )
                                }
                                sx={{ color: Colors.posCancleButtonRed }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.31rem",
                                  flexShrink: 0,
                                }}
                              />
                            )}
                          />
                        </DataTable>
                      </Box>
                    ) : null}
                    <Box>
                      <PosLable
                        requiredLabel={true}
                        name={"Product Name"}
                        marginTop={"1rem"}
                      />
                      <PosInput
                        id="name"
                        // placeholder={"Enter a Product Name"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.name || ""}
                        handleBlur={formik.handleBlur}
                        posInput={true}
                        inputType={"text"}
                        inputError={formik.touched.name && formik.errors.name}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.name && formik.errors.name ? (
                          <span>{formik.errors.name}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box className="card flex justify-content-center">
                      <Flex flexDirection={"row"}>
                        <PosLable
                          requiredLabel={true}
                          name={"Product Category"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="New Category can be made from Product-Categories-create. These categories help in classifying and navigating products easily."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                      </Flex>
                      <PosDropDown
                        options={categories}
                        value={formik?.values?.selectedCategories}
                        onChange={(e) =>
                          formik.setFieldValue("selectedCategories", e.value)
                        }
                        onBlur={(e) => {
                          formik.setFieldTouched("selectedCategories", e.value);
                          formik.handleBlur(e);
                        }}
                        multiSelect={true}
                        optionLabel="label"
                        placeholder="--Select Categories--"
                        lableAvailable={true}
                        width={"100%"}
                        height={"2.5rem"}
                        className="w-full md:w-20rem"
                        // display={"chip"}
                        maxSelectedLabels={2}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.selectedCategories &&
                        formik.errors.selectedCategories ? (
                          <span>{formik.errors.selectedCategories}</span>
                        ) : null}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={!isMobile ? "0rem" : 3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Inventory Management
                      </Heading>
                    </Box>

                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          requiredLabel={true}
                          name={"Location for this Product"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="selectedLocations"
                          placeholder={"--Select Locations--"}
                          handleInputChange={formik.handleChange}
                          inputValue={
                            formik.values.selectedLocations?.name || ""
                          }
                          handleBlur={formik.handleBlur}
                          posInput={true}
                          inputType={"text"}
                          // inputError={formik.touched.selectedLocations.name && formik.errors.selectedLocations.name}
                          disabled={true}
                        />
                      </Box>
                      <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          requiredLabel={true}
                          name={"Base Unit"}
                          marginTop={"1rem"}
                        />
                        <PosDropDown
                          id="selectedBaseUnit"
                          options={unitOfMeasures}
                          value={selectedBaseUnit}
                          // onChange={handleOptionSelect}
                          onChange={(e) => {
                            if (
                              undefined != e.target.value &&
                              null != e.target.value &&
                              "" != e.target.value
                            ) {
                              handleOptionSelect(e);
                            }
                          }}
                          // onBlur={(e) => {
                          //   formik.setFieldTouched("selectedBaseUnit");
                          //   formik.handleBlur(e);
                          // }}
                          onBlur={() => formik.handleBlur("selectedBaseUnit")}
                          optionLabel="label"
                          placeholder="Select Base Unit"
                          width={"100%"}
                          className="w-full md:w-20rem"
                        />
                        {/* <PosDropDown
                            id="selectedBaseUnit" 
                            options={unitOfMeasures}
                            value={formik?.values?.selectedBaseUnit}
                            onChange={(e) =>
                              formik.setFieldValue("selectedBaseUnit", e.value)
                            }
                            onBlur={(e) => {
                              formik.setFieldTouched(
                                "selectedBaseUnit",
                                e.value
                              );
                              formik.handleBlur(e);
                            }}
                            // multiSelect={true}
                            optionLabel="label"
                            placeholder="Select Base Unit"
                            lableAvailable={true}
                            width={"100%"}
                            // height={"2.5rem"}
                            className="w-full md:w-20rem"
                            // display={"chip"}
                            // maxSelectedLabels={2}
                          /> */}
                        <Text color={Colors.errorColor}>
                          {formik.touched.selectedBaseUnit &&
                          formik.errors.selectedBaseUnit ? (
                            <span>{formik.errors.selectedBaseUnit}</span>
                          ) : null}
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        {/* <PosLable
                          requiredLabel={true}
                          name={"In Stock Qty"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="in_stock_qty"
                          // placeholder={"Enter an In Stock Qty"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.in_stock_qty || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "in_stock_qty",
                              twofix(formik.values.in_stock_qty)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.in_stock_qty &&
                            formik.errors.in_stock_qty
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.in_stock_qty &&
                          formik.errors.in_stock_qty ? (
                            <span>{formik.errors.in_stock_qty}</span>
                          ) : null}
                        </Text> */}
                        <PosLable
                          label={true}
                          name={"In Stock Qty"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="in_stock_qty"
                          // placeholder={"Enter an In Stock Qty"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.in_stock_qty || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            // formik.setFieldValue(
                            //   "in_stock_qty",
                            //   twofix(formik.values.in_stock_qty)
                            // );
                            // Apply formatting to in_stock_qty
                            const formattedStock = twofix(
                              formik.values.in_stock_qty
                            );
                            formik.setFieldValue(
                              "in_stock_qty",
                              formattedStock
                            );
                            // Check modifiedViewDatas and perform calculation if base_qty > 0
                            checkAndUpdateInStock(formattedStock);
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.in_stock_qty &&
                            formik.errors.in_stock_qty
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.in_stock_qty &&
                          formik.errors.in_stock_qty ? (
                            <span>{formik.errors.in_stock_qty}</span>
                          ) : null}
                        </Text>
                      </Box>
                      <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <Flex flexDirection={"row"}>
                          <PosLable
                            label={true}
                            name={"Threshold"}
                            marginTop={"1rem"}
                          />
                          <Tippy
                            content="Mention the Minimum product level to which when dropped below, you will be notified of it and can also be used to make an automated Purchase order."
                            interactive={true}
                            maxWidth={390}
                            placement="top"
                            animation="fade"
                            inertia={true}
                            moveTransition="transform 0.2s ease-out"
                            theme="tomato"
                          >
                            <Image
                              src={toolTip}
                              alt="Tooltip"
                              mt={"1rem"}
                              ml={"0.3rem"}
                            />
                          </Tippy>
                        </Flex>
                        <PosInput
                          id="threshold"
                          // placeholder={"Enter a Threshold"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.threshold || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "threshold",
                              twofix(formik.values.threshold)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.threshold && formik.errors.threshold
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.threshold &&
                          formik.errors.threshold ? (
                            <span>{formik.errors.threshold}</span>
                          ) : null}
                        </Text>
                      </Box>
                    </Box>
                    <Box>
                      <PosLable label={true} name={"SKU"} marginTop={"1rem"} />
                      <PosInput
                        id="sku"
                        // placeholder={"Enter a SKU"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.sku || ""}
                        handleBlur={formik.handleBlur}
                        posInput={true}
                        inputType={"text"}
                        inputError={formik.touched.sku && formik.errors.sku}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.sku && formik.errors.sku ? (
                          <span>{formik.errors.sku}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Flex
                      direction={{ base: "column", md: "row" }}
                      alignItems={{ base: "flex-start", md: "center" }}
                      justify="space-between"
                      pt={"1rem"}
                      pb={"0.5rem"}
                      // pb={16}
                    >
                      <Flex flexDirection={"row"}>
                        <Heading
                          as={"h3"}
                          color={Colors.moduleHeading}
                          fontSize={"1.13rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.02rem"}
                        >
                          Vendors
                        </Heading>
                        <Tippy
                          content="This lets you associate the product with a vendor, which helps when making a purchase order and tracking the product history."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image src={toolTip} alt="Tooltip" ml={"0.3rem"} />
                        </Tippy>
                      </Flex>
                      <Spacer />

                      <Stack direction={"row"} alignSelf={"flex-end"}>
                        <PosFormButton
                          // isDisabled={
                          // formik.values.name.length <= 0 ||
                          // formik.errors.name ||
                          // formik.errors.type ||
                          // formik.errors.rate ||
                          // buttonDisable
                          //     ? true
                          //     : false
                          // }
                          buttonsubmit={"Add Vendor"}
                          SubmitButton={true}
                          onClick={getVendor}
                        />
                      </Stack>
                    </Flex>
                    <Box
                      style={{
                        maxHeight: "18.75rem",
                        overflow: "auto",
                        scrollbarWidth: "thin",
                      }}
                    >
                      {vendorscard.map((item, index) => {
                        return (
                          <Card
                            position="relative"
                            bg={"#F4F4F4"}
                            borderRadius={5}
                            marginTop={"0.5rem"}
                            key={index}
                            // px={"1rem"}
                            // py={"1.31rem"}
                          >
                            <Flex align="center">
                              <CardBody>
                                <Avatar
                                  sx={{
                                    bgcolor: "rgba(88, 129, 254, 0.1)",
                                    color: "rgba(1, 0, 72, 0.80)",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {item.name.substring(0, 2).toUpperCase()}
                                </Avatar>
                                <Text pt={4} fontSize={"md"} fontWeight={500}>
                                  {item.name}{" "}
                                  <span
                                    style={{
                                      fontWeight: 300,
                                      color: "rgba(1, 0, 72, 0.60)",
                                    }}
                                  >
                                    {item.state ? item.state + "," : null}{" "}
                                    {item.country ? item.country : null}{" "}
                                  </span>
                                </Text>
                                {item?.email?.length > 0 ? (
                                  <Text
                                    color={"#5881FE"}
                                    fontSize={"xs"}
                                    fontWeight={300}
                                  >
                                    {item.email}
                                  </Text>
                                ) : null}

                                {item?.item_code?.length > 0 ? (
                                  <Text
                                    fontSize={"xs"}
                                    fontWeight={400}
                                    color={"rgba(1, 0, 72, 0.80)"}
                                    pt={4}
                                    style={{ wordWrap: "break-word" }}
                                  >
                                    Item code: {item.item_code}
                                  </Text>
                                ) : null}
                              </CardBody>
                              <Box
                                position="absolute"
                                top="0.5rem"
                                right="1rem"
                              >
                                <CloseIcon
                                  color={Colors.posCancleButtonRed}
                                  onClick={() => onRemove(item)}
                                />
                              </Box>
                              {/* <Box
                                  position="absolute"
                                  top="0"
                                  right="0"
                                  // mt="2"
                                  // mr="2"
                                >
                                  <IconButton
                                    icon={<CloseIcon />}
                                    // onClick={onRemove}
                                    onClick={() => onRemove(item)}
                                    colorScheme="red"
                                    variant="ghost"
                                    aria-label="Remove"
                                  />
                                </Box> */}
                            </Flex>
                          </Card>
                        );
                      })}
                    </Box>
                  </VStack>
                  <Modal
                    isCentered
                    isOpen={isOpen}
                    onClose={onClose}
                    size={"lg"}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader
                        color={"#010048"}
                        fontSize={"1.5rem"}
                        fontWeight={600}
                      >
                        Add Vendor
                      </ModalHeader>
                      {/* <ModalCloseButton /> */}
                      <Divider />
                      {!vendorNext ? (
                        <>
                          <ModalBody pt={"1.5rem"}>
                            <PosSearch
                              value={searchText}
                              // onChange={(e) => setSearchText(e.target.value)}
                              onChange={(e) => {
                                let value =
                                  searchText.length > 0
                                    ? e.target.value
                                    : e.target.value.trim();
                                setSearchText(value);
                              }}
                              onSearch={handleSearchList}
                              handleClear={handleClear}
                            />
                            <Spacer />
                            <List
                              style={{
                                maxHeight: "18.75rem",
                                overflow: "auto",
                                scrollbarWidth: "thin",
                              }}
                            >
                              {filteredVendors?.map((value) => {
                                const labelId = `${value.id}`;

                                return (
                                  <ListItem
                                    key={value.id}
                                    disablePadding
                                    onClick={handleRadioChange(value)}
                                  >
                                    <ListItemButton>
                                      <ListItemAvatar>
                                        <Avatar
                                          sx={{
                                            bgcolor: "#EFEFEF",
                                            color: "rgba(1, 0, 72, 0.80)",
                                            fontSize: "15px",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {value.name
                                            .substring(0, 2)
                                            .toUpperCase()}
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        id={labelId}
                                        primary={
                                          <Box display={"flex"}>
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: "#010048",
                                                fontWeight: 500,
                                              }}
                                            >
                                              {value.name}
                                            </Typography>
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: "rgba(1, 0, 72, 0.60)",
                                                fontWeight: 300,
                                              }}
                                              ml="0.3rem"
                                            >
                                              {null != value.state &&
                                              "" != value.state
                                                ? value.state
                                                : ""}
                                            </Typography>
                                            <Typography
                                              variant="body1"
                                              style={{
                                                color: "rgba(1, 0, 72, 0.60)",
                                                fontWeight: 300,
                                              }}
                                            >
                                              {null != value.country &&
                                              "" != value.country
                                                ? ", " + value.country
                                                : ""}
                                            </Typography>
                                          </Box>
                                        }
                                        secondary={
                                          <Typography
                                            variant="body2"
                                            style={{ color: "#5881FE" }}
                                          >
                                            {value.email}
                                          </Typography>
                                        }
                                      />
                                      <Radio
                                        checked={selectedValue === value.id}
                                        inputProps={{
                                          "aria-labelledby": labelId,
                                        }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </ModalBody>
                          <ModalFooter>
                            <ButtonGroup gap="2" alignSelf={"flex-end"}>
                              <PosFormButton
                                onClick={onClose}
                                buttonText={"Cancel"}
                                CancelButton={true}
                              />
                              <PosFormButton
                                isDisabled={
                                  selectedValue?.length == 0 ? true : false
                                }
                                buttonsubmit={"Next"}
                                SubmitButton={true}
                                onClick={onNext}
                              />
                            </ButtonGroup>
                          </ModalFooter>
                        </>
                      ) : (
                        <>
                          <ModalBody>
                            <Box>
                              <PosLable
                                label={true}
                                name={"Item Code"}
                                marginTop={"1rem"}
                              />
                              <PosInput
                                id="item_code"
                                placeholder={"Enter an Item Code"}
                                handleInputChange={handleChange}
                                inputValue={itemCode || ""}
                                posInput={true}
                                inputType={"text"}
                                inputError={
                                  itemCodeError?.length > 0
                                    ? itemCodeError
                                    : null
                                }
                              />
                              <Text color={Colors.errorColor}>
                                {itemCodeError?.length > 0 ? (
                                  <span>{itemCodeError}</span>
                                ) : null}
                              </Text>
                            </Box>
                          </ModalBody>
                          <ModalFooter>
                            <PosFormButton
                              onClick={onClosed}
                              buttonText={"Cancel"}
                              CancelButton={true}
                            />
                            <PosFormButton
                              isDisabled={
                                buttonDisableVendor || itemCodeError?.length > 0
                                  ? true
                                  : false
                              }
                              buttonsubmit={"Add Vendor"}
                              SubmitButton={true}
                              onClick={() => addVendors(selectedVendorList)}
                            />
                          </ModalFooter>
                        </>
                      )}
                      {/* {totalCount > 0 ? ( */}

                      {/* <PosTable
                    columnNames={columnNames}
                    rawdata={vendors}
                    totalcount={totalCount}
                    spliteButton={true}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    handleSortColumn={handleSortColumn}
                    sortColumnName={"id"}
                    order={"asc"}
                  /> */}
                      {/* ) : (
                    <PosNoDataFound title={"no data found"} />
                  )} */}
                    </ModalContent>
                  </Modal>
                </Box>
                {/* {selectedBaseUnit &&selectedBaseUnit?.id > 0? */}

                {/* : null} */}
              </Box>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                //   boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box mb={"1rem"}>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Pricing
                      </Heading>
                    </Box>
                    <Box borderWidth={"0.125rem"} borderRadius={10} padding={3}>
                      <Text
                        color={Colors.posTextInfo}
                        fontWeight="400"
                        fontSize="1rem"
                        lineHeight="normal"
                      >
                        Cost Calculator
                      </Text>
                      <Text color={Colors.taxCreateExemptEbt} fontSize="sm">
                        Use below fields to calculate cost per base unit
                      </Text>

                      <Box
                        flexDirection={!isMobile ? "row" : "column"}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          mr={!isMobile ? 2.5 : 0}
                          w={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                            xl: "50%",
                          }}
                        >
                          <PosLable
                            label={true}
                            name={"Cost Per Case / Packs etc"}
                            marginTop={"1rem"}
                          />
                          <PosInput
                            id="cost_per_case"
                            // placeholder={"Enter a Cost Per Case"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.cost_per_case || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "cost_per_case",
                                twofix(formik.values.cost_per_case)
                              );
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.cost_per_case &&
                              formik.errors.cost_per_case
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.cost_per_case &&
                            formik.errors.cost_per_case ? (
                              <span>{formik.errors.cost_per_case}</span>
                            ) : null}
                          </Text>
                        </Box>
                        <Box
                          w={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                            xl: "50%",
                          }}
                        >
                          <PosLable
                            label={true}
                            name={"Additional Cost"}
                            marginTop={"1rem"}
                          />
                          <PosInput
                            id="additional_cost"
                            // placeholder={"Enter an Additional Cost"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.additional_cost || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "additional_cost",
                                twofix(formik.values.additional_cost)
                              );
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.additional_cost &&
                              formik.errors.additional_cost
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.additional_cost &&
                            formik.errors.additional_cost ? (
                              <span>{formik.errors.additional_cost}</span>
                            ) : null}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "100%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Number of Base Units in a Case"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="no_of_eaches"
                          // placeholder={"Enter a Sellable Unit in a Case"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.no_of_eaches || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "no_of_eaches",
                              twofix(formik.values.no_of_eaches)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.no_of_eaches &&
                            formik.errors.no_of_eaches
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.no_of_eaches &&
                          formik.errors.no_of_eaches ? (
                            <span>{formik.errors.no_of_eaches}</span>
                          ) : null}
                        </Text>
                      </Box>

                      {!formik.errors.cost_per_case &&
                      !formik.errors.no_of_eaches &&
                      !formik.errors.additional_cost &&
                      null != formik.values.cost_per_case &&
                      "" != formik.values.cost_per_case &&
                      null != formik.values.no_of_eaches &&
                      "" != formik.values.no_of_eaches ? (
                        <>
                          <Box pt={"1rem"}>
                            <Text
                              color={"rgba(1, 0, 72, 0.80)"}
                              fontWeight={300}
                              fontSize={"0.94rem"}
                            >
                              Calculated Base Unit Cost:{" "}
                              <span
                                style={{
                                  color: "#5881FE",
                                  fontWeight: 300,
                                  textDecorationLine: "underline",
                                }}
                              >
                                {/* $
                                {(
                                  parseFloat(formik.values.cost_per_case) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2)} */}
                                 $
                                  {(
                                    (parseFloat(formik.values.cost_per_case) +
                                      parseFloat(
                                        formik.values.additional_cost || 0.0
                                      )) /
                                    parseFloat(formik.values.no_of_eaches)
                                  ).toFixed(2)}
                              </span>
                            </Text>
                          </Box>
                        </>
                      ) : null}
                    </Box>

                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "100%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Base Unit Cost (Current)"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="cost"
                          // placeholder={"Enter a Cost Per Case"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.cost || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "cost",
                              twofix(formik.values.cost)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={formik.touched.cost && formik.errors.cost}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.cost && formik.errors.cost ? (
                            <span>{formik.errors.cost}</span>
                          ) : null}
                        </Text>
                      </Box>
                      {/* <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Additional Cost"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="additional_cost"
                          // placeholder={"Enter an Additional Cost"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.additional_cost || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "additional_cost",
                              twofix(formik.values.additional_cost)
                            );
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.additional_cost &&
                            formik.errors.additional_cost
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.additional_cost &&
                          formik.errors.additional_cost ? (
                            <span>{formik.errors.additional_cost}</span>
                          ) : null}
                        </Text>
                      </Box> */}
                    </Box>
                    {/* <Box>
                      <PosLable
                        label={true}
                        name={"Sellable Unit in a Case"}
                        marginTop={"1rem"}
                      />
                      <PosInput
                        id="no_of_eaches"
                        // placeholder={"Enter a Sellable Unit in a Case"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.no_of_eaches || ""}
                        // handleBlur={formik.handleBlur}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "no_of_eaches",
                            twofix(formik.values.no_of_eaches)
                          );
                        }}
                        precision={2}
                        posNumberInput={true}
                        // inputType={"text"}
                        inputError={
                          formik.touched.no_of_eaches &&
                          formik.errors.no_of_eaches
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.no_of_eaches &&
                        formik.errors.no_of_eaches ? (
                          <span>{formik.errors.no_of_eaches}</span>
                        ) : null}
                      </Text>
                    </Box> */}

                    {/* {!formik.errors.cost &&
                    !formik.errors.no_of_eaches &&
                    !formik.errors.additional_cost &&
                    null != formik.values.cost &&
                    "" != formik.values.cost &&
                    null != formik.values.no_of_eaches &&
                    "" != formik.values.no_of_eaches ? (
                      <>
                        <Box>
                          <Text
                            color={"#010048"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            Price of individual Unit:{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                                textDecorationLine: "underline",
                              }}
                            >
                              $
                              {(
                                (parseFloat(formik.values.cost) +
                                  parseFloat(
                                    formik.values.additional_cost || 0.0
                                  )) /
                                parseFloat(formik.values.no_of_eaches)
                              ).toFixed(2)}
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.80)"}
                            fontWeight={300}
                            fontSize={"0.94rem"}
                            mt={{ base: "0.88rem", md: "0.88rem" }}
                            mb={{ base: "0.88rem", md: "0.88rem" }}
                          >
                            *Selling Price Recommendations at various margins*
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            20%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 20 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            25%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 25 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            30%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 30 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                          <Text
                            color={"rgba(1, 0, 72, 0.50)"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            35%{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                              }}
                            >
                              ($
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - 35 / 100)
                              ).toFixed(2)}
                              )
                            </span>
                          </Text>
                        </Box>
                        <Box>
                          <PosLable
                            label={true}
                            name={"Custom Margin"}
                            marginTop={"1rem"}
                          />
                          <PosInput
                            id="custom_per"
                            // placeholder={"Enter a Custom Margin"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.custom_per || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "custom_per",
                                twofix(formik.values.custom_per)
                              );
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.custom_per &&
                              formik.errors.custom_per
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.custom_per &&
                            formik.errors.custom_per ? (
                              <span>{formik.errors.custom_per}</span>
                            ) : null}
                          </Text>
                        </Box>
                        {!formik.errors.custom_per &&
                        null != formik.values.custom_per &&
                        "" != formik.values.custom_per ? (
                          <Box>
                            <Text
                              color={"rgba(1, 0, 72, 0.50)"}
                              fontWeight={300}
                              fontSize={"0.94rem"}
                            >
                              *Suggested Selling Price at{" "}
                              <span style={{ fontWeight: 500 }}>
                                {formik.values.custom_per}%
                              </span>{" "}
                              margin is $
                              {(
                                (
                                  (parseFloat(formik.values.cost) +
                                    parseFloat(
                                      formik.values.additional_cost || 0.0
                                    )) /
                                  parseFloat(formik.values.no_of_eaches)
                                ).toFixed(2) /
                                (1 - parseFloat(formik.values.custom_per) / 100)
                              ).toFixed(2)}{" "}
                            </Text>
                          </Box>
                        ) : null}
                      </>
                    ) : null} */}

                    {!formik.errors.cost &&
                    null != formik.values.cost &&
                    "" != formik.values.cost ? (
                      <>
                        <Text
                          color={"rgba(1, 0, 72, 0.80)"}
                          fontWeight={300}
                          fontSize={"0.94rem"}
                          mt={{ base: "0.88rem", md: "0.88rem" }}
                          mb={{ base: "0.44rem", md: "0.44rem" }}
                        >
                          *Selling Price Recommendations at various margins*
                        </Text>
                        <Box
                          flexDirection={!isMobile ? "row" : "column"}
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Box
                            mr={!isMobile ? 2.5 : 0}
                            w={{
                              base: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                              xl: "50%",
                            }}
                          >
                            {/* <Text
                            color={"#010048"}
                            fontWeight={500}
                            fontSize={"0.94rem"}
                          >
                            Price of individual Unit:{" "}
                            <span
                              style={{
                                color: "#5881FE",
                                fontWeight: 300,
                                textDecorationLine: "underline",
                              }}
                            >
                              $
                              {(parseFloat(formik.values.cost)).toFixed(2)}
                            </span>
                          </Text> */}

                            <Text
                              color={"rgba(1, 0, 72, 0.50)"}
                              fontWeight={500}
                              fontSize={"0.94rem"}
                            >
                              20%{" "}
                              <span
                                style={{
                                  color: "#5881FE",
                                  fontWeight: 300,
                                }}
                              >
                                ($
                                {(
                                  parseFloat(formik.values.cost).toFixed(2) /
                                  (1 - 20 / 100)
                                ).toFixed(2)}
                                )
                              </span>
                            </Text>
                            <Text
                              color={"rgba(1, 0, 72, 0.50)"}
                              fontWeight={500}
                              fontSize={"0.94rem"}
                            >
                              25%{" "}
                              <span
                                style={{
                                  color: "#5881FE",
                                  fontWeight: 300,
                                }}
                              >
                                ($
                                {(
                                  parseFloat(formik.values.cost).toFixed(2) /
                                  (1 - 25 / 100)
                                ).toFixed(2)}
                                )
                              </span>
                            </Text>
                            <Text
                              color={"rgba(1, 0, 72, 0.50)"}
                              fontWeight={500}
                              fontSize={"0.94rem"}
                            >
                              30%{" "}
                              <span
                                style={{
                                  color: "#5881FE",
                                  fontWeight: 300,
                                }}
                              >
                                ($
                                {(
                                  parseFloat(formik.values.cost).toFixed(2) /
                                  (1 - 30 / 100)
                                ).toFixed(2)}
                                )
                              </span>
                            </Text>
                            <Text
                              color={"rgba(1, 0, 72, 0.50)"}
                              fontWeight={500}
                              fontSize={"0.94rem"}
                            >
                              35%{" "}
                              <span
                                style={{
                                  color: "#5881FE",
                                  fontWeight: 300,
                                }}
                              >
                                ($
                                {(
                                  parseFloat(formik.values.cost).toFixed(2) /
                                  (1 - 35 / 100)
                                ).toFixed(2)}
                                )
                              </span>
                            </Text>
                          </Box>

                          <Box
                            w={{
                              base: "100%",
                              sm: "100%",
                              md: "100%",
                              lg: "100%",
                              xl: "50%",
                            }}
                            pt={{
                              base: "2",
                              sm: "2",
                              md: "2",
                              lg: "2",
                              xl: "0",
                            }}
                          >
                            <PosLable
                              label={true}
                              name={"Custom Margin Suggestion"}
                              marginTop={"0rem"}
                            />
                            <PosInput
                              id="custom_per"
                              // placeholder={"Enter a Custom Margin"}
                              handleInputChange={formik.handleChange}
                              inputValue={formik.values.custom_per || ""}
                              // handleBlur={formik.handleBlur}
                              handleBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldValue(
                                  "custom_per",
                                  twofix(formik.values.custom_per)
                                );
                              }}
                              precision={2}
                              posNumberInput={true}
                              // inputType={"text"}
                              inputError={
                                formik.touched.custom_per &&
                                formik.errors.custom_per
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {formik.touched.custom_per &&
                              formik.errors.custom_per ? (
                                <span>{formik.errors.custom_per}</span>
                              ) : null}
                            </Text>

                            {!formik.errors.custom_per &&
                            null != formik.values.custom_per &&
                            "" != formik.values.custom_per ? (
                              <Box>
                                <Text
                                  color={"rgba(1, 0, 72, 0.50)"}
                                  fontWeight={300}
                                  fontSize={"0.94rem"}
                                >
                                  *Suggested Selling Price at{" "}
                                  <span style={{ fontWeight: 500 }}>
                                    {formik.values.custom_per}%
                                  </span>{" "}
                                  margin is $
                                  {(
                                    parseFloat(formik.values.cost).toFixed(2) /
                                    (1 -
                                      parseFloat(formik.values.custom_per) /
                                        100)
                                  ).toFixed(2)}{" "}
                                </Text>
                              </Box>
                            ) : null}
                          </Box>
                        </Box>
                      </>
                    ) : null}

                    {/* <Box pt={"2rem"}>
                      <Divider h={"0.05rem"} />
                    </Box> */}
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                          xl: cashDiscountPercentage > 0 ? "50%" : "100%",
                        }}
                      >
                        <PosLable
                          requiredLabel={true}
                          name={cardLabel+" Price"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="sale_price"
                          // placeholder={"Enter a Sale Price"}
                          // handleInputChange={formik.handleChange}
                          handleInputChange={(e) => {
                            formik.setFieldValue(
                              "sale_price",
                              e?.target?.value
                            );
                            formik.setFieldValue(
                              "cash_price",
                              twofix(
                                reverseCalculateDiscountedPrice(
                                  e?.target?.value,
                                  cashDiscountPercentage
                                )
                              )
                            );
                          }}
                          inputValue={formik.values.sale_price || ""}
                          // handleBlur={formik.handleBlur}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "sale_price",
                              twofix(formik.values.sale_price)
                            );
                            formik.setFieldValue(
                              "cash_price",
                              twofix(
                                reverseCalculateDiscountedPrice(
                                  formik.values?.sale_price,
                                  cashDiscountPercentage
                                )
                              )
                            );
                            const marginSalePrice = twofix(
                              formik.values.sale_price
                            );
                            // Check modifiedViewDatas and perform calculation if base_qty > 0
                            checkAndUpdatePrice(marginSalePrice);
                          }}
                          precision={2}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.sale_price &&
                            formik.errors.sale_price
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.sale_price &&
                          formik.errors.sale_price ? (
                            <span>{cardLabel}{formik.errors.sale_price}</span>
                          ) : null}
                        </Text>
                      </Box>
                      {cashDiscountPercentage > 0 ? (
                        <Box
                          w={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                            xl: cashDiscountPercentage > 0 ? "50%" : "100%",
                          }}
                        >
                          <PosLable
                            label={true}
                            name={"Cash Price"}
                            marginTop={"1rem"}
                          />
                          <PosInput
                            id="cash_price"
                            // placeholder={"Enter a Sale Price"}
                            // handleInputChange={formik.handleChange}
                            handleInputChange={(e) => {
                              formik.setFieldValue(
                                "cash_price",
                                e?.target?.value
                              );
                              formik.setFieldValue(
                                "sale_price",
                                twofix(
                                  calculateDiscountedPrice(
                                    e?.target?.value,
                                    cashDiscountPercentage
                                  )
                                )
                              );
                            }}
                            inputValue={formik.values.cash_price || ""}
                            // handleBlur={formik.handleBlur}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "cash_price",
                                twofix(formik.values.cash_price)
                              );
                              formik.setFieldValue(
                                "sale_price",
                                twofix(
                                  calculateDiscountedPrice(
                                    formik.values?.cash_price,
                                    cashDiscountPercentage
                                  )
                                )
                              );
                              const marginSalePrice = twofix(
                                formik.values.sale_price
                              );
                              // Check modifiedViewDatas and perform calculation if base_qty > 0
                              checkAndUpdatePrice(marginSalePrice);
                            }}
                            precision={2}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.cash_price &&
                              formik.errors.cash_price
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.cash_price &&
                            formik.errors.cash_price ? (
                              <span>{formik.errors.cash_price}</span>
                            ) : null}
                          </Text>
                        </Box>
                      ) : (
                        <Box
                          mr={!isMobile ? 2.5 : 0}
                          w={{
                            base: "100%",
                            sm: "100%",
                            md: "100%",
                            lg: "100%",
                            xl: cashDiscountPercentage > 0 ? "50%" : "100%",
                          }}
                        >
                          <Flex flexDirection={"row"}>
                            <PosLable
                              label={true}
                              name={"Reward Points"}
                              marginTop={"1rem"}
                            />
                            <Tippy
                              content="These are the points a customer will get on purchasing the product."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={"1rem"}
                                ml={"0.3rem"}
                              />
                            </Tippy>
                          </Flex>
                          <PosInput
                            id="reward_points"
                            // placeholder={"Enter a Reward Points"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.reward_points || ""}
                            handleBlur={formik.handleBlur}
                            posNumberInput={true}
                            // inputType={"text"}
                            inputError={
                              formik.touched.reward_points &&
                              formik.errors.reward_points
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.reward_points &&
                            formik.errors.reward_points ? (
                              <span>{formik.errors.reward_points}</span>
                            ) : null}
                          </Text>
                        </Box>
                      )}
                    </Box>
                    {cashDiscountPercentage > 0 ? (
                      <Box className="card flex justify-content-center">
                        <Flex flexDirection={"row"}>
                          <PosLable
                            label={true}
                            name={"Reward Points"}
                            marginTop={"1rem"}
                          />
                          <Tippy
                            content="These are the points a customer will get on purchasing the product."
                            interactive={true}
                            maxWidth={390}
                            placement="top"
                            animation="fade"
                            inertia={true}
                            moveTransition="transform 0.2s ease-out"
                            theme="tomato"
                          >
                            <Image
                              src={toolTip}
                              alt="Tooltip"
                              mt={"1rem"}
                              ml={"0.3rem"}
                            />
                          </Tippy>
                        </Flex>
                        <PosInput
                          id="reward_points"
                          // placeholder={"Enter a Reward Points"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.reward_points || ""}
                          handleBlur={formik.handleBlur}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.reward_points &&
                            formik.errors.reward_points
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.reward_points &&
                          formik.errors.reward_points ? (
                            <span>{formik.errors.reward_points}</span>
                          ) : null}
                        </Text>
                      </Box>
                    ) : null}

                    <Box className="card flex justify-content-center">
                      <PosLable
                        label={true}
                        name={"Taxes"}
                        marginTop={"1rem"}
                      />
                      <PosDropDown
                        options={taxes}
                        value={formik?.values?.selectedTaxes}
                        onChange={(e) =>
                          formik.setFieldValue("selectedTaxes", e.value)
                        }
                        multiSelect={true}
                        optionLabel="label"
                        placeholder="--Select Taxes--"
                        lableAvailable={true}
                        width={"100%"}
                        height={"2.5rem"}
                        className="w-full md:w-20rem"
                        // display={"chip"}
                        maxSelectedLabels={2}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.selectedTaxes &&
                        formik.errors.selectedTaxes ? (
                          <span>{formik.errors.selectedTaxes}</span>
                        ) : null}
                      </Text>
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : currentTab === 1 ? (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Additional Attributes
                      </Heading>
                    </Box>
                    {/* {disableAdditionalAttributes ? (
                      <Heading
                        as={"h4"}
                        lineHeight="normal"
                        fontStyle={"normal"}
                        fontSize={"0.94rem"}
                        color={Colors.posViewTextColor}
                        fontWeight={"400"}
                      >
                        <Highlight
                          query={[
                            "Modifier Sets",
                            "Sold by Weight",
                            "Prompt Quantity",
                            "Variable Price",
                          ]}
                          styles={{
                            px: "0",
                            py: "0",
                            rounded: "full",
                            bg: "teal.100",
                          }}
                        >
                          Note:- Once you add Related Units then you cannot
                          assign Modifier Sets or Sold by Weight or Prompt
                          Quantity or Variable Price.
                        </Highlight>
                      </Heading>
                    ) : (
                      ""
                    )} */}
                    {/* <Box display={"flex"}>
                      <Box w={"90%"}>
                        <PosLable
                          label={true}
                          name={"Additional Barcode"}
                          marginTop={"1rem"}
                        />
                        <PosInput
                          id="additional"
                          // placeholder={"Enter an Additional Barcode"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.additional || ""}
                          handleBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldValue(
                              "additional",
                              formik.values.additional?.trim()
                            );
                          }}
                          posInput={true}
                          inputType={"text"}
                          inputError={
                            formik.touched.additional &&
                            formik.errors.additional
                          }
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.additional &&
                          formik.errors.additional ? (
                            <span>{formik.errors.additional}</span>
                          ) : null}
                        </Text>
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={
                          formik.errors?.additional?.length > 0
                            ? "center"
                            : "flex-end"
                        }
                        justifyContent={"center"}
                        w={"3rem"}
                        ml={"1rem"}
                      >
                        <IconButton
                          onClick={handleBarcodeClick}
                          sx={{
                            marginTop:
                              formik.errors?.additional?.length > 0
                                ? "2rem"
                                : 0,
                          }}
                          isRound={true}
                          isDisabled={
                            formik.values?.additional?.length > 0 &&
                            !formik.errors.additional
                              ? false
                              : true
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    {additional_barcodes.length > 0 ? (
                      <Box mt="2rem">
                        <DataTable value={additional_barcodes}>
                          <Column
                            field="additional_barcodes"
                            header="Additional Barcode"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "80%",
                            }}
                            body={(rowData) => {
                              const barcode = rowData
                                ? rowData.toLocaleString(undefined)
                                : "N.A";
                              return barcode.length > 25
                                ? barcode.match(/.{1,25}/g).join("\n")
                                : barcode;
                            }}
                          />

                          <Column
                            field="delete"
                            header="Delete"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            // bodyStyle={{ padding: "1rem 0.1rem" }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "20%",
                            }}
                            body={(rowData) => (
                              <DeleteIcon
                                bg={Colors.posCancleButtonRed}
                                onClick={() =>
                                  handleDelete(
                                    additional_barcodes.indexOf(rowData)
                                  )
                                }
                                sx={{ color: Colors.posCancleButtonRed }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.31rem",
                                  flexShrink: 0,
                                }}
                              />
                            )}
                          />
                        </DataTable>
                      </Box>
                    ) : null} */}
                    {!formik.values.sold_by_weight &&
                    !formik.values.prompt_qty &&
                    !formik.values.is_variable_price ? (
                      <>
                        <Box className="card flex justify-content-center">
                          <Flex flexDirection={"row"}>
                            <PosLable
                              label={true}
                              name={"Select Modifier Sets"}
                              marginTop={"1rem"}
                            />
                            <Tippy
                              content="This feature allows for customization of the product, which is helpful in ensuring accurate orders for restaurants."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={"1rem"}
                                ml={"0.3rem"}
                              />
                            </Tippy>
                          </Flex>
                          <PosDropDown
                            options={modifierSets}
                            value={formik?.values?.selectedModifierSets}
                            onChange={(e) => {
                              if (disableAdditionalAttributes) {
                                addToast({
                                  alertStatus: Constants.TOAST_TYPE_WARNING,
                                  alertTitle: Constants.TOAST_HEADING_WARNING,
                                  alertDescription:
                                    "You cannot attach Modifier Sets because there is a Unit of Measure assigned to the Item. Please remove the Unit of Measure in order to attach Modifier Sets.",
                                });
                              } else {
                                formik.setFieldValue(
                                  "selectedModifierSets",
                                  e.value
                                );
                              }
                            }}
                            multiSelect={true}
                            optionLabel="label"
                            placeholder="--Select Modifier Sets--"
                            lableAvailable={true}
                            // disabled={disableAdditionalAttributes}
                            width={"100%"}
                            height={"2.5rem"}
                            className="w-full md:w-20rem"
                            // display={"chip"}
                            maxSelectedLabels={2}
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.selectedModifierSets &&
                            formik.errors.selectedModifierSets ? (
                              <span>{formik.errors.selectedModifierSets}</span>
                            ) : null}
                          </Text>
                        </Box>
                        {/* <Box pt={"2rem"}>
                          <Divider h={"0.05rem"} />
                        </Box> */}
                      </>
                    ) : null}
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Age Verification"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="Set a minimum lit that needs to be matched for the sale of the good. Such products when scanned will ring up an age verification page."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                        <Spacer />
                        <PosSwitchButton
                          id="age_verification"
                          switchValue={formik.values.age_verification}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                      </Flex>
                      {/* <Stack
                        direction={"row"}
                        w={"44px"}
                        height={"22px"}
                        flexShrink={"0"}
                      >
                        <PosSwitchButton
                          id="age_verification"
                          switchValue={formik.values.age_verification}
                          onChange={formik.handleChange}
                        />
                      </Stack> */}
                      {/* <Text
                        mt={{ base: "0.94rem", md: "0.94rem" }}
                        color={Colors.taxCreateExemptEbt}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Turn on, to allow minimum age for customers to make
                        purchase of the goods, products when scanned will ring
                        up an age verification page
                      </Text> */}
                    </Box>
                    {formik.values.age_verification ? (
                      <Box pt={"1rem"}>
                        <PosInput
                          id="min_age_verification"
                          placeholder={"Enter minimum age *"}
                          handleInputChange={formik.handleChange}
                          inputValue={formik.values.min_age_verification || ""}
                          handleBlur={formik.handleBlur}
                          posNumberInput={true}
                          // inputType={"text"}
                          inputError={
                            formik.touched.min_age_verification &&
                            formik.errors.min_age_verification
                          }
                          darkPlaceholder={true}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.min_age_verification &&
                          formik.errors.min_age_verification ? (
                            <span>{formik.errors.min_age_verification}</span>
                          ) : null}
                        </Text>
                      </Box>
                    ) : null}
                    {(formik.values.selectedModifierSets != null &&
                      formik.values.selectedModifierSets != undefined &&
                      formik.values.selectedModifierSets.length > 0) ||
                    formik.values.prompt_qty ? null : (
                      <>
                        <Box>
                          <Flex flexDirection={"row"}>
                            <PosLable
                              label={true}
                              name={"Sold By Weight"}
                              marginTop={"1rem"}
                            />
                            <Tippy
                              content="Converts the price into Per pound price. The product gets weighed on the scale and priced accordingly. Mostly used for Produce and Deli."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={"1rem"}
                                ml={"0.3rem"}
                              />
                            </Tippy>
                            <Spacer />
                            <PosSwitchButton
                              id="sold_by_weight"
                              // disabled={disableAdditionalAttributes}
                              switchValue={formik.values.sold_by_weight}
                              // onChange={formik.handleChange}
                              onChange={(value) => {
                                if (disableAdditionalAttributes) {
                                  addToast({
                                    alertStatus: Constants.TOAST_TYPE_WARNING,
                                    alertTitle: Constants.TOAST_HEADING_WARNING,
                                    alertDescription:
                                      "You cannot enable Sold By Weight because there is a Unit of Measure assigned to the Item. Please remove the Unit of Measure in order to turn on Sold By Weight.",
                                  });
                                } else {
                                  formik.handleChange(value);
                                  formik.setFieldValue(
                                    "selectedTareContainers",
                                    []
                                  );
                                }
                              }}
                              mt={"1rem"}
                              ml={"1.5rem"}
                            />
                          </Flex>
                          {/* <Stack
                            direction={"row"}
                            w={"44px"}
                            height={"22px"}
                            flexShrink={"0"}
                          >
                            <PosSwitchButton
                              id="sold_by_weight"
                              switchValue={formik.values.sold_by_weight}
                              // onChange={formik.handleChange}
                              onChange={(value) => {
                                formik.handleChange(value);
                                formik.setFieldValue(
                                  "selectedTareContainers",
                                  []
                                );
                              }}
                            />
                          </Stack> */}
                        </Box>
                        {formik.values.sold_by_weight == true ? (
                          <Box mt={{ base: "0.94rem", md: "0.94rem" }}>
                            <PosDropDown
                              options={tareContainers}
                              value={formik?.values?.selectedTareContainers}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  "selectedTareContainers",
                                  e.value
                                )
                              }
                              multiSelect={true}
                              optionLabel="label"
                              placeholder="--Select Tare Containters--"
                              lableAvailable={true}
                              width={"100%"}
                              height={"2.5rem"}
                              className="w-full md:w-20rem"
                              // display={"chip"}
                              maxSelectedLabels={2}
                            />
                            <Text color={Colors.errorColor}>
                              {formik.touched.selectedTareContainers &&
                              formik.errors.selectedTareContainers ? (
                                <span>
                                  {formik.errors.selectedTareContainers}
                                </span>
                              ) : null}
                            </Text>
                          </Box>
                        ) : null}
                      </>
                    )}

                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Exclude EBT"}
                          marginTop={"1rem"}
                        />
                        <Tippy
                          content="If clicked on, then the item can not be purchased by using EBT."
                          interactive={true}
                          maxWidth={390}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"1rem"}
                            ml={"0.3rem"}
                          />
                        </Tippy>
                        <Spacer />
                        <PosSwitchButton
                          id="exclude_ebt"
                          switchValue={formik.values.exclude_ebt}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                      </Flex>
                      {/* <Stack
                        direction={"row"}
                        w={"44px"}
                        height={"22px"}
                        flexShrink={"0"}
                      >
                        <PosSwitchButton
                          id="exclude_ebt"
                          switchValue={formik.values.exclude_ebt}
                          onChange={formik.handleChange}
                        />
                      </Stack> */}
                      {/* <Text
                        mt={{ base: "0.94rem", md: "0.94rem" }}
                        color={Colors.taxCreateExemptEbt}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        If turned on, item cannot be purchased by using EBT
                      </Text> */}
                    </Box>
                    {formik.values.selectedModifierSets != null &&
                    formik.values.selectedModifierSets != undefined &&
                    formik.values.selectedModifierSets.length > 0 ? null : (
                      <>
                        {!formik.values.sold_by_weight ? (
                          <Box>
                            <Flex flexDirection={"row"}>
                              <PosLable
                                label={true}
                                name={"Prompt Quantity"}
                                marginTop={"1rem"}
                              />
                              <Tippy
                                content="Prompt a quantity to choose from 1 to 10. Helpful for goods that are sold in multiple units at once( lemons, oranges)."
                                interactive={true}
                                maxWidth={390}
                                placement="top"
                                animation="fade"
                                inertia={true}
                                moveTransition="transform 0.2s ease-out"
                                theme="tomato"
                              >
                                <Image
                                  src={toolTip}
                                  alt="Tooltip"
                                  mt={"1rem"}
                                  ml={"0.3rem"}
                                />
                              </Tippy>
                              <Spacer />
                              <PosSwitchButton
                                id="prompt_qty"
                                // disabled={disableAdditionalAttributes}
                                switchValue={formik.values.prompt_qty}
                                onChange={(event) => {
                                  if (disableAdditionalAttributes) {
                                    addToast({
                                      alertStatus: Constants.TOAST_TYPE_WARNING,
                                      alertTitle:
                                        Constants.TOAST_HEADING_WARNING,
                                      alertDescription:
                                        "You can not enable Prompt Quantity because there is a Unit of Measure in a Item. Please remove the Unit of Measure in order to turn on Prompt Quantity.",
                                    });
                                  } else {
                                    formik.handleChange(event);
                                  }
                                }}
                                mt={"1rem"}
                                ml={"1.5rem"}
                              />
                            </Flex>
                            {/* <Stack
                              direction={"row"}
                              w={"44px"}
                              height={"22px"}
                              flexShrink={"0"}
                            >
                              <PosSwitchButton
                                id="prompt_qty"
                                switchValue={formik.values.prompt_qty}
                                onChange={formik.handleChange}
                              />
                            </Stack> */}
                          </Box>
                        ) : null}
                        <Box>
                          <Flex flexDirection={"row"}>
                            <PosLable
                              label={true}
                              name={"Variable Price"}
                              marginTop={"1rem"}
                            />
                            <Tippy
                              content="Asks for a new price every time selected. Useful for products with Variable prices such as handicrafts."
                              interactive={true}
                              maxWidth={390}
                              placement="top"
                              animation="fade"
                              inertia={true}
                              moveTransition="transform 0.2s ease-out"
                              theme="tomato"
                            >
                              <Image
                                src={toolTip}
                                alt="Tooltip"
                                mt={"1rem"}
                                ml={"0.3rem"}
                              />
                            </Tippy>
                            <Spacer />
                            <PosSwitchButton
                              id="is_variable_price"
                              // disabled={disableAdditionalAttributes}
                              switchValue={formik.values.is_variable_price}
                              onChange={(event) => {
                                if (disableAdditionalAttributes) {
                                  addToast({
                                    alertStatus: Constants.TOAST_TYPE_WARNING,
                                    alertTitle: Constants.TOAST_HEADING_WARNING,
                                    alertDescription:
                                      "You cannot enable Variable Price because there is a Unit of Measure assigned to the Item. Please remove the Unit of Measure in order to turn on Variable Price.",
                                  });
                                } else {
                                  formik.handleChange(event);
                                }
                              }}
                              mt={"1rem"}
                              ml={"1.5rem"}
                            />
                          </Flex>
                          {/* <Stack
                            direction={"row"}
                            w={"44px"}
                            height={"22px"}
                            flexShrink={"0"}
                          >
                            <PosSwitchButton
                              id="is_variable_price"
                              switchValue={formik.values.is_variable_price}
                              onChange={formik.handleChange}
                            />
                          </Stack> */}
                        </Box>
                      </>
                    )}
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Exclude Kitchen Printing/ KDS"}
                          marginTop={"1rem"}
                        />
                        <Spacer />
                        <PosSwitchButton
                          id="is_exclude_kds"
                          switchValue={formik.values.is_exclude_kds}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                        {/* <Stack
                        direction={"row"}
                        w={"44px"}
                        height={"22px"}
                        flexShrink={"0"}
                      >
                        <PosSwitchButton
                          id="is_exclude_kds"
                          switchValue={formik.values.is_exclude_kds}
                          onChange={formik.handleChange}
                        />
                      </Stack> */}
                      </Flex>
                    </Box>
                  </VStack>
                </Box>
              </Box>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                //   boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Customize Point of Sale Button
                      </Heading>
                    </Box>
                    <Box mt={{ base: "1rem", md: "1rem" }}>
                      {!oldImageBase64 ? (
                        <PosImageCropper
                          parentCallback={handleCallback}
                          imgUploadLabel="Product Image"
                          canvasWidth="14.75rem"
                          canvasHeight="14.75rem"
                          cropperWidth={236}
                          cropperHeight={193}
                          cropperMinWidth={96} // Minimum width of the crop area Cropper hide na thay ena mate
                          cropperMaxWidth={236} // Maximum width of the crop area when edit open at time full cropper display
                          cropperMinHeight={79} // Minimum height of the crop area Cropper hide na thay ena mate
                          cropperMaxHeight={193} // Maximum height of the crop area when edit open at time full cropper display
                          handleImage={handleImage}
                          image={prodImage}
                        />
                      ) : (
                        <PosImageCropper
                          parentCallback={handleCallback}
                          oldImageBase64={oldImageBase64}
                          imgUploadLabel="Product Image"
                          canvasWidth="14.75rem"
                          canvasHeight="14.75rem"
                          cropperWidth={236}
                          cropperHeight={193}
                          cropperMinWidth={96} // Minimum width of the crop area Cropper hide na thay ena mate
                          cropperMaxWidth={236} // Maximum width of the crop area when edit open at time full cropper display
                          cropperMinHeight={79} // Minimum height of the crop area Cropper hide na thay ena mate
                          cropperMaxHeight={193} // Maximum height of the crop area when edit open at time full cropper display
                          handleImage={handleImage}
                          image={prodImage}
                        />
                      )}
                    </Box>
                    {/* <Box>
                      <PosLable
                        label={true}
                        name={"Choose Background Color"}
                        marginTop={"1rem"}
                      />
                      <MuiColorInput
                        id="background_color"
                        format="hex"
                        value={backgroundColor}
                        style={{
                          width: "100%",
                          border: "1px solid rgba(229, 229, 229, 0.70)",
                          borderRadius: "0.31rem",
                          backgroundColor: "#F4F4F4",
                        }}
                        onChange={handleChangeBackgroundColor}
                      />
                    </Box> */}
                    {/* <Box>
                      <PosLable
                        label={true}
                        name={"Choose Text Color"}
                        marginTop={"1rem"}
                      />
                      <MuiColorInput
                        id="text_color"
                        format="hex"
                        value={textColor}
                        style={{
                          width: "100%",
                          border: "1px solid rgba(229, 229, 229, 0.70)",
                          borderRadius: "0.31rem",
                          backgroundColor: "#F4F4F4",
                        }}
                        onChange={handleChangeTextColor}
                      />
                    </Box> */}
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Choose Background Color"}
                          marginTop={"1rem"}
                        />
                        <MuiColorInput
                          id="background_color"
                          format="hex"
                          value={backgroundColor}
                          style={{
                            width: "100%",
                            border: "1px solid rgba(229, 229, 229, 0.70)",
                            borderRadius: "0.31rem",
                            backgroundColor: "#F4F4F4",
                          }}
                          onChange={handleChangeBackgroundColor}
                        />
                      </Box>
                      <Box
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <PosLable
                          label={true}
                          name={"Choose Text Color"}
                          marginTop={"1rem"}
                        />
                        <MuiColorInput
                          id="text_color"
                          format="hex"
                          value={textColor}
                          style={{
                            width: "100%",
                            border: "1px solid rgba(229, 229, 229, 0.70)",
                            borderRadius: "0.31rem",
                            backgroundColor: "#F4F4F4",
                          }}
                          onChange={handleChangeTextColor}
                        />
                      </Box>
                    </Box>
                    <PosLable
                      label={true}
                      name={"POS Button Preview"}
                      marginTop={"1rem"}
                    />
                    <Box
                      position="relative"
                      // h="14rem"
                      // w="16.37rem"
                      mt={{ base: "1.69rem", md: "1.69rem" }}
                    >
                      {!base64OfUploadedImg ? (
                        <>
                          <Box
                            style={{ position: "relative" }}
                            // display={"flex"}
                            // justifyContent={"center"}
                          >
                            {/* Avatar view */}
                            <Box
                              style={{
                                width: "225px",
                                height: "180px",
                                // backgroundColor: item.backgroundColor_hexcode ? item.backgroundColor_hexcode :  'rgba(217, 217, 217, 0.85)', // Semi-transparent background
                                backgroundColor: backgroundColor,
                                justifyContent: "center",
                                display: "flex",
                                alignSelf: "center",
                                alignContent: "center",
                                alignItems: "center",
                                borderRadius: "7px",
                              }}
                            >
                              <Text
                                style={{
                                  marginBottom: "30px",
                                  color: textColor,
                                  fontSize: "xl",

                                  textAlign: "center",
                                }}
                              >
                                {formattedName}
                              </Text>
                            </Box>

                            <Box
                              style={{
                                display: "flex",
                                position: "absolute",
                                bottom: 0,
                                backgroundColor: "#D9D9D9",
                                borderBottomLeftRadius: "7px",
                                borderBottomRightRadius: "7px",
                                alignItems: "center",
                                justifyContent: "center",
                                alignSelf: "center",
                                width: "225px",
                                height: "55px",
                              }}
                            >
                              <Text
                                fontSize={"sm"}
                                fontWeight={
                                  formattingName.length > 27 ? "thin" : "500"
                                }
                              >
                                {formattingName || "Product Name"}
                              </Text>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box
                          style={{ position: "relative" }}
                          // display={"flex"}
                          // justifyContent={"center"}
                        >
                          <Image
                            style={{
                              width: "225px",
                              height: "180px",
                              borderTopLeftRadius: "7px",
                              borderTopRightRadius: "7px",
                              justifyContent: "center",

                              borderRadius: "7px",
                            }}
                            src={base64OfUploadedImg}
                          />
                          <Box
                            style={{
                              display: "flex",
                              position: "absolute",
                              bottom: 0,
                              backgroundColor: "rgba(217, 217, 217, 0.85)",
                              borderBottomLeftRadius: "7px",
                              borderBottomRightRadius: "7px",
                              alignItems: "center",
                              justifyContent: "center",
                              alignSelf: "center",
                              width: "225px",
                              height: "55px",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: "sm",
                                padding: "0.5rem",
                                // textAlign: "center",
                                color: "#010048",
                              }}
                              fontWeight={
                                formattingName.length > 27 ? "thin" : "500"
                              }
                            >
                              {formattingName || "Product Name"}
                            </Text>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : currentTab === 2 ? (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Unit of Measure
                      </Heading>
                    </Box>
                    {/* Change 1 */}
                    {formik?.values?.selectedModifierSets?.length > 0 ||
                    formik.values?.sold_by_weight == true ||
                    formik.values?.prompt_qty == true ||
                    formik.values?.is_variable_price == true ? (
                      // <Heading
                      //   as={"h4"}
                      //   lineHeight="normal"
                      //   fontStyle={"normal"}
                      //   fontSize={"0.94rem"}
                      //   color={Colors.posViewTextColor}
                      //   fontWeight={"400"}
                      // >
                      //   <Highlight
                      //     query={[
                      //       "Modifier Sets",
                      //       "Sold by Weight",
                      //       "Prompt Quantity",
                      //       "Variable Price",
                      //     ]}
                      //     styles={{
                      //       px: "0",
                      //       py: "0",
                      //       rounded: "full",
                      //       bg: "teal.100",
                      //     }}
                      //   >
                      //     You cannot assign Related Units because Modifier Sets,
                      //     Sold by Weight, Prompt Quantity, or Variable Price are
                      //     assigned to the item. Please remove all of these in
                      //     order to assign Related Units.
                      //   </Highlight>
                      // </Heading>
                      <Box pt={"0.5rem"}>
                        <Alert
                          // width={{ base: "0", md: "1009px" }}
                          h="100%"
                          bg={Colors.errorsign} //props
                          status={"error"}
                          borderRadius={"0.63rem"}
                        >
                          <Image boxSize={4} src={warnignIcon} alt="Octopos" />
                          <AlertDescription
                            fontSize={"0.94rem"}
                            fontWeight={300}
                            color={Colors.errorColor}
                            pl={3}
                          >
                            You cannot assign Related Units because Modifier
                            Sets, Sold by Weight, Prompt Quantity, or Variable
                            Price are assigned to the item. Please remove all of
                            these in order to assign Related Units.
                          </AlertDescription>
                        </Alert>
                      </Box>
                    ) : (
                      ""
                    )}
                    {/* {viewSelectedProducts && viewSelectedProducts.length > 0 && ( */}
                    <Box
                      borderRadius="0.63rem"
                      mt={3}
                      bg={Colors.posPageDataBackground}
                      boxShadow={
                        "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflowX: "auto",
                      }}
                    >
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead
                            alignitems="start"
                            // hidden={headerHide}
                            sx={{ backgroundColor: Colors.modifierTable }}
                          >
                            <TableRow>
                              {columnUnitsViewNames?.map((column, index) => (
                                <TableCell
                                  key={index}
                                  // color={Colors.primeposTextInfo}
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "0.94rem",
                                    lineHeight: "1.18rem",
                                    letterSpacing: "-0.01rem",
                                    fontStyle: "normal",
                                    textAlign: column.textAlign || "center",
                                    maxHeight: "42rem",
                                    color: "#010048",
                                    // pl:"3.56rem",
                                    // width:
                                    //   column.width == "" ? undefined : column.width,
                                  }}
                                >
                                  <Flex
                                    flexDirection={"row"}
                                    style={{
                                      justifyContent:
                                        column?.columnNames !=
                                          "Related Units" && "center",
                                    }}
                                  >
                                    {column.columnNames}
                                  </Flex>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {modifiedViewDatas.length > 0 ? (
                              modifiedViewDatas.map((row, i) => {
                                let isIdEmpty = !row.id;
                                return (
                                  <TableRow
                                    key={i}
                                    // sx={{
                                    //   "&:last-child td, &:last-child th": { border: 0 },
                                    // }}
                                  >
                                    <TableCell>
                                      {isIdEmpty ? (
                                        <Flex flexDirection={"row"}>
                                          {/* <Search2Icon mt={3} mr={3} /> */}
                                          <ThemeProvider theme={Mtheme}>
                                            <Autocomplete
                                              size="small"
                                              filterOptions={(x) => x}
                                              id={`autoselect-${i}`}
                                              sx={{ width: 200 }}
                                              open={open}
                                              onOpen={() => {
                                                setOpen(true);
                                              }}
                                              onClose={() => {
                                                setOpen(false);
                                              }}
                                              value={unit}
                                              onChange={(event, newValue) => {
                                                // Set the unit of measure based on user selection
                                                onSetUnitofMeasureSingleLine(
                                                  newValue
                                                );
                                              }}
                                              onInputChange={(
                                                event,
                                                newInputValue
                                              ) => {
                                                setUnit(newInputValue);
                                              }}
                                              getOptionLabel={(option) =>
                                                option?.label || ""
                                              }
                                              options={unitOfMeasures}
                                              renderOption={(props, option) => (
                                                <li
                                                  {...props}
                                                  key={option?.label}
                                                >
                                                  {option?.label}
                                                </li>
                                              )}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Select"
                                                />
                                              )}
                                              // {/* Change 1 */}
                                              disabled={
                                                formik?.values
                                                  ?.selectedModifierSets
                                                  ?.length > 0 ||
                                                formik.values?.sold_by_weight >
                                                  0 ||
                                                formik.values?.prompt_qty > 0 ||
                                                formik.values
                                                  ?.is_variable_price > 0
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </ThemeProvider>
                                        </Flex>
                                      ) : (
                                        <VStack align="left">
                                          <Box
                                            style={{
                                              fontSize: "0.94rem",
                                              fontStyle: "normal",
                                              fontWeight: 500,
                                              lineHeight: "normal",
                                              letterSpacing: "-0.01rem",
                                              color: "#010048",
                                            }}
                                          >
                                            {row.label}
                                          </Box>
                                        </VStack>
                                      )}
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        id="base_qty"
                                        htmlSize={4}
                                        disabled={isIdEmpty}
                                        ref={(el) =>
                                          (inputRefs.current[i] = el)
                                        }
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "base_qty"
                                          )
                                        }
                                        // disabled={modifiedViewDatas.length - 1 == i}
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value;
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "base_qty"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].base_qty
                                            : row.base_qty) || ""
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="number"
                                        isInvalid={
                                          modifiedViewDatas[i]?.baseQtyErrors
                                            ?.length > 0
                                        }
                                      />
                                      <Text color={Colors.errorColor}>
                                        {modifiedViewDatas[i]?.baseQtyErrors
                                          ?.length > 0 ? (
                                          <span>
                                            {
                                              modifiedViewDatas[i]
                                                ?.baseQtyErrors
                                            }
                                          </span>
                                        ) : null}
                                      </Text>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        id="pricing"
                                        htmlSize={4}
                                        disabled={isIdEmpty}
                                        // ref={(el) => (inputRefs.current[i] = el)}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "pricing"
                                          )
                                        }
                                        // disabled={modifiedViewDatas.length - 1 == i}
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value;
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "pricing"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].pricing
                                            : row.pricing) || ""
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="number"
                                        isInvalid={
                                          modifiedViewDatas[i]?.pricingErrors
                                            ?.length > 0
                                        }
                                      />
                                      <Text color={Colors.errorColor}>
                                        {modifiedViewDatas[i]?.pricingErrors
                                          ?.length > 0 ? (
                                          <span>
                                            {
                                              modifiedViewDatas[i]
                                                ?.pricingErrors
                                            }
                                          </span>
                                        ) : null}
                                      </Text>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        disabled={true}
                                        id="margin"
                                        htmlSize={4}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "margin"
                                          )
                                        }
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value;
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "margin"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].margin
                                            : "N.A.") || "N.A."
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="text"
                                      />
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        id="upc"
                                        htmlSize={4}
                                        disabled={isIdEmpty}
                                        // ref={(el) => (inputRefs.current[i] = el)}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) => {
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "upc"
                                          );
                                        }}
                                        // disabled={modifiedViewDatas.length - 1 == i}
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          // const newValue = e.target.value.trim();
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "upc",
                                            "blur"
                                          );
                                          // Check if the new value causes any duplicate error
                                          // const isUPCConsistent = modifiedViewDatas.some(item => item.upc === newValue && item.id !== row.id);
                                          // if (isUPCConsistent) {
                                          //   modifiedViewDatas[i].upcErrors = "UPC is already used.";
                                          // } else {
                                          //   modifiedViewDatas[i].upcErrors = "";
                                          // }
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].upc
                                            : row.upc) || ""
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="string"
                                        isInvalid={
                                          modifiedViewDatas[i]?.upcErrors
                                            ?.length > 0
                                        }
                                      />
                                      <Text color={Colors.errorColor}>
                                        {modifiedViewDatas[i]?.upcErrors
                                          ?.length > 0 ? (
                                          <span>
                                            {modifiedViewDatas[i]?.upcErrors}
                                          </span>
                                        ) : null}
                                      </Text>
                                    </TableCell>

                                    <TableCell align="center">
                                      <Input
                                        disabled={true}
                                        id="uom_in_stock"
                                        htmlSize={4}
                                        variant={"posBasicInput"}
                                        width="8rem"
                                        onChange={(e) =>
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "in_stock"
                                          )
                                        }
                                        sx={{
                                          color: "#111928",
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          textAlign: "center",
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          border: "3px solid #e5e5e5b3", // Adjust color as needed
                                        }}
                                        onBlur={(e) => {
                                          let originalValue = e.target.value;
                                          let parsedValue = parseFloat(
                                            originalValue
                                          );
                                          if (!isNaN(parsedValue)) {
                                            e.target.value = parsedValue.toFixed(
                                              2
                                            );
                                          } else {
                                            e.target.value = originalValue;
                                          }
                                          handleInputChangeUOM(
                                            e,
                                            row.id,
                                            "in_stock"
                                          );
                                        }}
                                        value={
                                          (i > -1
                                            ? modifiedViewDatas[i].in_stock
                                            : "N.A.") || "N.A."
                                        }
                                        onKeyPress={(e) => {
                                          if (e.key === "-") {
                                            e.preventDefault();
                                          }
                                        }}
                                        type="text"
                                      />
                                    </TableCell>

                                    <TableCell align="center">
                                      {isIdEmpty ? null : (
                                        <ChakraDeleteIcon
                                          color={Colors.posCancleButtonMuiRed}
                                          style={{
                                            width: "1.13rem",
                                            height: "1.13rem",
                                            flexShrink: 0,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => deleteById(row.id)}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell>
                                  <Flex flexDirection={"row"}>
                                    {/* <Search2Icon mt={3} mr={3} /> */}
                                    <ThemeProvider theme={Mtheme}>
                                      <Autocomplete
                                        options={unitOfMeasures}
                                        value={unit}
                                        size="small"
                                        filterOptions={(x) => x}
                                        // id={`autoselect-${i}`}
                                        sx={{ width: 200 }}
                                        open={open}
                                        onOpen={() => {
                                          setOpen(true);
                                        }}
                                        onClose={() => {
                                          setOpen(false);
                                        }}
                                        onChange={(event, newValue) => {
                                          // Set the unit of measure based on user selection
                                          onSetUnitofMeasureSingleLine(
                                            newValue
                                          );
                                        }}
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          setUnit(newInputValue);
                                        }}
                                        getOptionLabel={(option) =>
                                          option?.label || ""
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                          option?.id === value?.id
                                        }
                                        renderOption={(props, option) => (
                                          <li {...props} key={option?.label}>
                                            {option?.label}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Select"
                                          />
                                        )}
                                        // {/* Change 1 */}
                                        disabled={
                                          formik.values?.selectedModifierSets
                                            ?.length > 0 ||
                                          formik.values?.sold_by_weight > 0 ||
                                          formik.values?.prompt_qty > 0 ||
                                          formik.values?.is_variable_price > 0
                                            ? true
                                            : false
                                        }
                                      />
                                    </ThemeProvider>
                                  </Flex>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    {/* )} */}
                    <Box
                      flexDirection={!isMobile ? "row" : "column"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <Box className="card flex justify-content-center">
                          <PosLable
                            requiredLabel={true}
                            name={"Default Selling Unit"}
                            marginTop={"1rem"}
                          />
                          <PosDropDown
                            id="defaultSelectedUnit"
                            options={defaultSelectedUnit}
                            value={selectedDefaultSellingUnit}
                            onChange={handleOptionSelectDefaultSellingUnit}
                            // onBlur={(e) => {
                            //   formik.setFieldTouched("selectedDefaultSellingUnit");
                            //   formik.handleBlur(e);
                            // }}
                            onBlur={() =>
                              formik.handleBlur("selectedDefaultSellingUnit")
                            }
                            lableAvailable={true}
                            optionLabel="label"
                            placeholder="Default Selling Unit"
                            width={"100%"}
                            className="w-full md:w-20rem"
                          />

                          <Text color={Colors.errorColor}>
                            {formik.touched.selectedDefaultSellingUnit &&
                            formik.errors.selectedDefaultSellingUnit ? (
                              <span>
                                {formik.errors.selectedDefaultSellingUnit}
                              </span>
                            ) : null}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        mr={!isMobile ? 2.5 : 0}
                        w={{
                          base: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "50%",
                        }}
                      >
                        <Box className="card flex justify-content-center">
                          <PosLable
                            requiredLabel={true}
                            name={"Default Purchasing Unit"}
                            marginTop={"1rem"}
                          />
                          <PosDropDown
                            id="defaultSelectedPurchasingUnit"
                            options={defaultSelectedUnit}
                            value={selectedDefaultPurchasingUnit}
                            onChange={handleOptionSelectDefaultPurchasingUnit}
                            // onBlur={(e) => {
                            //   formik.setFieldTouched("selectedDefaultPurchasingUnit");
                            //   formik.handleBlur(e);
                            // }}
                            onBlur={() =>
                              formik.handleBlur("selectedDefaultPurchasingUnit")
                            }
                            lableAvailable={true}
                            optionLabel="label"
                            placeholder="Default Purchasing Unit"
                            width={"100%"}
                            className="w-full md:w-20rem"
                          />

                          <Text color={Colors.errorColor}>
                            {formik.touched.selectedDefaultPurchasingUnit &&
                            formik.errors.selectedDefaultPurchasingUnit ? (
                              <span>
                                {formik.errors.selectedDefaultPurchasingUnit}
                              </span>
                            ) : null}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : currentTab === 3 ? (
            <>
              {priceBook ? (
                <Box
                  width={{ base: "100%", md: "50%" }}
                  flexShrink={"0"}
                  borderRadius={"0.31rem"}
                >
                  <Box
                    bg={Colors.posPageDataBackground}
                    // mb={3.5}
                    boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                  >
                    <VStack
                      align={"stretch"}
                      p={{ base: "1.69rem", md: "1.69rem" }}
                      gap={"0rem"}
                    >
                      <Flex>
                        <Heading
                          as={"h3"}
                          color={Colors.moduleHeading}
                          fontSize={"1.13rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.02rem"}
                        >
                          Price Books
                        </Heading>
                      </Flex>
                      <VStack align="start" mt={4} spacing={4}>
                        <ChakraTableContainer>
                          <ChakraTable variant="simple">
                            <Thead bg={Colors.modifierTable}>
                              <Tr>
                                <Th>Name</Th>
                                <Th>Amount($)</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {formik.values.priceBooks.length > 0 &&
                                formik.values.priceBooks.map((item, index) => (
                                  <Tr key={index}>
                                    <Td
                                      whiteSpace="normal"
                                      wordBreak="break-word"
                                      width={{
                                        base: "18rem",
                                        sm: "16rem",
                                        md: "14rem",
                                        lg: "38rem",
                                      }}
                                      overflow="hidden"
                                      textOverflow="ellipsis"
                                      fontSize={{ base: "sm", md: "md" }}
                                      p={{ base: "0.5rem", md: "1rem" }}
                                    >
                                      {item.price_book_name}
                                    </Td>
                                    <Td>
                                      <PosInput
                                        id={`priceBooks.${index}.price_book_sale_price`}
                                        mt={"1rem"}
                                        placeholder={
                                          "Enter a Price Book Amount"
                                        }
                                        color={Colors.posCommon}
                                        handleInputChange={(e) =>
                                          handlePriceBookChange(
                                            index,
                                            "price_book_sale_price",
                                            e.target.value
                                          )
                                        }
                                        inputValue={
                                          formik.values.priceBooks?.[index]
                                            ?.price_book_sale_price || ""
                                        }
                                        handleBlur={(e) => {
                                          formik.handleBlur(e);
                                          handlePriceBookChange(
                                            index,
                                            "price_book_sale_price",
                                            twofix(e.target.value)
                                          );
                                        }}
                                        width="17rem"
                                        posNumberInput={true}
                                        inputType={"text"}
                                        inputError={
                                          formik.touched.priceBooks?.[index]
                                            ?.price_book_sale_price &&
                                          formik.errors.priceBooks?.[index]
                                            ?.price_book_sale_price
                                        }
                                      />
                                      <Text color={Colors.posCancleButtonRed}>
                                        {formik.touched.priceBooks?.[index]
                                          ?.price_book_sale_price &&
                                        formik.errors.priceBooks?.[index]
                                          ?.price_book_sale_price ? (
                                          <span>
                                            {
                                              formik.errors.priceBooks?.[index]
                                                ?.price_book_sale_price
                                            }
                                          </span>
                                        ) : null}
                                      </Text>
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                            <Tfoot></Tfoot>
                          </ChakraTable>
                        </ChakraTableContainer>
                      </VStack>
                    </VStack>
                  </Box>
                </Box>
              ) : (
                <PosNoDataFound title={Constants.PRODUCT_PRICE_NOT_FOUND} />
              )}
            </>
          ) : currentTab === 4 ? (
            <>
              <Box flex={1} flexShrink={"0"} borderRadius={"0.31rem"}>
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Product Sales
                      </Heading>
                    </Box>
                    <Box>
                      <RenderderData
                        title={"Last 7 Days"}
                        value={
                          productSales?.seven_day
                            ? "$" + productSales.seven_day
                            : "$0.00"
                        }
                      />
                    </Box>
                    <Box>
                      <RenderderData
                        title={"Last 30 Days"}
                        value={
                          productSales?.thirty_day
                            ? "$" + productSales.thirty_day
                            : "$0.00"
                        }
                      />
                    </Box>
                    <Box>
                      <RenderderData
                        title={"Life Time Sales"}
                        value={
                          productSales?.life_time
                            ? "$" + productSales.life_time
                            : "$0.00"
                        }
                      />
                    </Box>
                  </VStack>
                </Box>
              </Box>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Product Refunds
                      </Heading>
                    </Box>
                    <Box>
                      <RenderderData
                        title={"Last 7 Days"}
                        value={
                          productRefund?.seven_day
                            ? "$" + productRefund?.seven_day
                            : "$0.00"
                        }
                      />
                    </Box>
                    <Box>
                      <RenderderData
                        title={"Last 30 Days"}
                        value={
                          productRefund?.thirty_day
                            ? "$" + productRefund?.thirty_day
                            : "$0.00"
                        }
                      />
                    </Box>
                    <Box>
                      <RenderderData
                        title={"Life Time Refunds"}
                        value={
                          productRefund?.life_time
                            ? "$" + productRefund?.life_time
                            : "$0.00"
                        }
                      />
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                flex={1}
                flexShrink={"0"}
                // bg={Colors.posPageDataBackground}
                borderRadius={"0.31rem"}
                // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  // mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                >
                  <VStack
                    align={"stretch"}
                    p={{ base: "1.69rem", md: "1.69rem" }}
                    // pl={{ base: "1.69rem", md: "1.69rem" }}
                    // pt={{ base: "2.31rem", md: "2.31rem" }}
                    // pr={{ base: "1.69rem", md: "1.69rem" }}
                    // pb={{ base: "4.38rem", md: "4.38rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Online Order Product Attributes
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Online Ordering Price"}
                        marginTop={"1rem"}
                      />
                      <PosInput
                        id="online_ordering_price"
                        // placeholder={"Enter an Online Ordering Price"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.online_ordering_price || ""}
                        // handleBlur={formik.handleBlur}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "online_ordering_price",
                            twofix(formik.values.online_ordering_price)
                          );
                        }}
                        precision={2}
                        posNumberInput={true}
                        // inputType={"text"}
                        inputError={
                          formik.touched.online_ordering_price &&
                          formik.errors.online_ordering_price
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.online_ordering_price &&
                        formik.errors.online_ordering_price ? (
                          <span>{formik.errors.online_ordering_price}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        label={true}
                        name={"Online Product Description"}
                        // mt="0.31rem"
                        marginTop={"1rem"}
                      />
                      {/* <Textarea
                          id="description"
                          placeholder=""
                          borderRadius={"0.63rem"}
                          border={Colors.posTextAreaBorder}
                          background={Colors.posInputBackground}
                          onChange={formik.handleChange}
                          value={formik.values.description}
                          onBlur={formik.handleBlur}
                          inputerror={
                            formik.touched.description &&
                            formik.errors.description
                          }
                        /> */}
                      <PosTextArea
                        id="description"
                        placeholder=""
                        onChange={formik.handleChange}
                        value={formik.values.description || ""}
                        onBlur={formik.handleBlur}
                        inputerror={
                          formik.touched.description &&
                          formik.errors.description
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <span>{formik.errors.description}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          name={"Featured Product"}
                          marginTop={"1rem"}
                        />
                        <PosSwitchButton
                          id="online_is_featured_product"
                          switchValue={formik.values.online_is_featured_product}
                          onChange={formik.handleChange}
                          mt={"1rem"}
                          ml={"1.5rem"}
                        />
                      </Flex>

                      {/* <Stack
                        direction={"row"}
                        w={"44px"}
                        height={"22px"}
                        flexShrink={"0"}
                      >
                        <PosSwitchButton
                          id="online_is_featured_product"
                          switchValue={formik.values.online_is_featured_product}
                          onChange={formik.handleChange}
                        />
                      </Stack> */}
                      {/* <Text
                        mt={{ base: "0.94rem", md: "0.94rem" }}
                        color={Colors.taxCreateExemptEbt}
                        fontSize={"0.94rem"}
                        fontStyle={"normal"}
                        fontWeight={"300"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.01rem"}
                      >
                        Turn on, to allow product feature on online store page
                      </Text> */}
                    </Box>
                  </VStack>
                </Box>
              </Box>
            </>
          )}
        </Flex>
      </Box>
    </PosDrawer>
  );
};

export default WithRouter(ProductEditDrawer);
