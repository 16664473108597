import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useSubmit, useLocation } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosDataGridTable,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllDeactiveGlobalSettingsMasterAdmin,
  getAllGlobalSettingMasterAdmin,
} from "./MasterAdminGlobalSettingService";

const MasterAdminGlobalSettingList = (props) => {
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH);
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [gsData, setGlobalSettingData] = useState([]);
  const [gsDeactiveData, setGlobalSettingDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [sortType, setSortType] = useState("asc");
  // const [sortColumn, setSortColumn] = useState("id");
  const [totalcount, setTotalCount] = useState(0);
  const [curruntTab, setCurruntTab] = useState(0);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const stateData = useLocation(); // get backraking data as param
  const [paginationModel, setPaginationModel] = useState({
    page: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "asc"
  );
  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page: stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.page:0,
    pageSize: undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state
    ? stateData.state.limit:25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:  undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"setting_display_name",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"asc",
    },
  ]);
  const [sortModel, setSortModel] = useState([
    {
      field: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order:"setting_display_name",
      sort: undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type:"asc",
    },
  ]);
  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "setting_display_name"
  );
  const sortRefDeactive = useRef(sortModelDeactive);
  const [buttonDisable, setButtonDisable] = useState(false);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames = [
    {
      field: "setting_display_name",
      headerName: "Setting Display Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "setting_key",
      headerName: "Setting Key",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "global_settings_category_id",
      headerName: "Setting Category",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "actions",
      headerName: "Actions",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          myContext.handleLoading(true);
          let data = {
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            order:
              sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
            order_type:
              sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            filter: searchQuery,
          }
          if(undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE){
            props.navigate(
                `${Constants.MASTER_ADMIN_GLOBLE_SETTINGS_EDIT_PATH + item.id}`,{
                  state: data,
                }
            );
          }
          else{
          props.navigate(
            "/" +
              process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
              `${Constants.MASTER_ADMIN_GLOBLE_SETTINGS_EDIT_PATH + item.id}`,{
                state: data,
              }
          );
          }
        };
        const handleDeactive = (item) => {
          setButtonDisable(true);
          if(modifiedData.length == 1 && paginationModel.page != 0){
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.DELETE,
            path: Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
          });
        };
        const options = {
          buttonName: "Edit",
          buttonIcon: <CreateOutlinedIcon />,
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton:buttonDisable,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const deactivatedColumnNames = [
    {
      field: "setting_display_name",
      headerName: "Setting Display Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "setting_key",
      headerName: "Setting Key",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "global_settings_category_id",
      headerName: "Setting Category",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisable(true);
          if(deactiveGlobalSettingData.length == 1 && paginationModelDeactive.page != 0){
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisable ? "not-allowed" : "pointer"}
            color={buttonDisable ? "gray" : Colors.posNavbarLink}
            // cursor="pointer"
            // color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            // textDecoration="underline"
            textDecoration={buttonDisable ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisable ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  // const options = {
  //   buttonName: "Edit",
  //   buttonIcon: <EditIcon />,
  //   handleClick: (item) => handleEditPage(item),
  //   subButtons: [
  //     {
  //       name: "Deactive",
  //       icon: <CloseIcon style={{ color: "red" }} />,
  //       onClick: (item) => handleDeactive(item),
  //     },
  //   ],
  // };
  // const [paginationModel, setPaginationModel] = useState({
  //   page: 0,
  //   pageSize: 25,
  // });
  // const [sortModel, setSortModel] = useState([
  //   {
  //     field: "global_settings_category_id",
  //     sort: "asc",
  //   },
  // ]);
  const sortRef = useRef(sortModel);
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const effectRun = useRef(true);
  const handleDeactive = (item) => {
    myContext.handleLoading(true);
    let data = {
      id: item.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
    });
  };

  const handlePageChange = (newPage) => {
    effectRun.current = true;
    setPage(newPage);
    setLoading(true);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    effectRun.current = true;
    setRowsPerPage(newRowsPerPage);
    setLoading(true);
  };
  const handleSortColumn = (column, sort) => {
    setLoading(true);
    setSortColumn(column.sortName);
    effectRun.current = true;
    setSortType(sort);
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllGlobalSettingMasterAdmin(data)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            setGlobalSettingData(response?.data.data.data);
            setTotalCount(response?.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setGlobalSettingData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setGlobalSettingData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setGlobalSettingData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchDeActiveGlobalSettingData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].field : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0 ? sortModelDeactive[0].sort : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getAllDeactiveGlobalSettingsMasterAdmin(data)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            setGlobalSettingDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setGlobalSettingDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          setGlobalSettingDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setGlobalSettingDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActiveGlobalSettingData();
      }
      effectRun.current = false;
    }
  } ,[paginationModel,sortModel,page,loading,searchQuery,paginationModelDeactive,sortModelDeactive]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          fetchData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
            alertDescription: Constants.GS_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        } else {
          setLoading(true);
          fetchDeActiveGlobalSettingData();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
            alertDescription: Constants.GS_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
        }
      }
    }
  }, [actionResponse]);

  // const handleEditPage = (item) => {
  //   myContext.handleLoading(true);
  //   props.navigate(
  //     "/" +
  //       process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
  //       Constants.MASTER_ADMIN_GLOBLE_SETTINGS_EDIT_PATH +
  //       item.id
  //   );
  // };

  const handleSortType = (order) => {
    setSortType(order);
  };
  const handleTabChange = (index) => {
    setCurruntTab(index);
    setLoading(true);
    setSearchQuery("");
    setPaginationModelDeactive({
      page: 0,
      pageSize:25,
    });
    setSortModelDeactive([
      {
        field:"setting_display_name",
        sort: "asc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize:25,
    });
    setSortModel([
      {
        field:"setting_display_name",
        sort: "asc",
      },
    ]);
    effectRun.current = true;
    if(index == 0){
      deactiveSearchHideRef.current = false;
    }else{
      searchInputHideRef.current = false;
    }
  };

  const handleSearchList = () => {
    paginationModel.page = 0;
    if(undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE){
      props.navigate(Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH);
    }
    else{
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH);
    }
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActiveGlobalSettingData();
    }
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    if(undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE){
      props.navigate(Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH);
    }
    else{
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH);
    }
  }
  const modifiedData = gsData.map((gs) => ({
    ...gs,
    global_settings_category_id:
      gs.global_settings_category?.name == null ||
      gs.global_settings_category?.name == ""
        ? "N.A"
        : gs.global_settings_category.name,
  }));

  const deactiveGlobalSettingData = gsDeactiveData.map((gs) => ({
    ...gs,
    global_settings_category_id:
      gs.global_settings_category?.name == null ||
      gs.global_settings_category?.name == ""
        ? "N.A"
        : gs.global_settings_category.name,
  }));
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"Global Settings"}
          discription={"Manage your Global Settings here."}
        />
        <Spacer />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              let data = {
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                order:
                  sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
                  filter:curruntTab == 1 ? "":searchQuery ,
              }
              if(undefined != JSON.parse(localStorage.getItem("currentUser")) && JSON.parse(localStorage.getItem("currentUser")).role==Constants.SUPER_ADMIN_USER_ROLE){
                props.navigate(
                  Constants.MASTER_ADMIN_GLOBLE_SETTINGS_CREATE_PATH,{
                    state: data,
                  }
                );
              }
              else{
              props.navigate(
                "/" +
                  process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                  Constants.MASTER_ADMIN_GLOBLE_SETTINGS_CREATE_PATH,{
                    state: data,
                  }
              );
              }
              myContext.handleLoading(true);
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={(e) => {
                let value =searchQuery.length > 0 ? 
                e.target.value: e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            <Spacer />
          </Flex> */}
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <PosDataGridTable
                  columns={columnNames}
                  columnVisibilityModel={olColumnVisibilityModel}
                  rows={modifiedData}
                  totalcount={totalcount}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    effectRun.current = true;
                    setSortModel(newSortModel);
                  }}
                  fileName={Constants.ACTIVE_GS_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.ACTIVE_GLOBAL_SETTINGS_NOT_FOUND}
                />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <PosDataGridTable
                  columns={deactivatedColumnNames}
                  rows={deactiveGlobalSettingData}
                  totalcount={deactiveTotalCount}
                  paginationModel={paginationModelDeactive}
                  paginationMode="server"
                  sortingMode="server"
                  onPaginationModelChange={(newPaginationModelDeactive) => {
                    effectRun.current = true;
                    setPaginationModelDeactive(newPaginationModelDeactive);
                  }}
                  sortModel={sortModelDeactive}
                  onSortModelChange={(newSortModelDeactive) => {
                    sortRefDeactive.current = sortModelDeactive;
                    effectRun.current = true;
                    setSortModelDeactive(newSortModelDeactive);
                  }}
                  onColumnVisibilityModelChange={(newModelDeactive) =>
                    setOlColumnVisibilityModel(newModelDeactive)
                  }
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  columnVisibilityModel={olColumnVisibilityModel}
                  fileName={Constants.DEACTIVE_GS_EXPORT_FILENAME}
                />
              ) : (
                <PosNoDataFound
                  title={Constants.DEACTIVE_GLOBAL_SETTINGS_NOT_FOUND}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(MasterAdminGlobalSettingList);
