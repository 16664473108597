import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect, useState } from "react";

const PosMainLoader = (props) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 250);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <LinearProgress variant="determinate" value={progress} />;
};

export default PosMainLoader;
