import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function refundWithOrderReportAndExport({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).getreport === "true") {
      const payload = JSON.stringify({
        location: JSON.parse(Object.fromEntries(formData).location),
        dateFrom: Object.fromEntries(formData).dateFrom,
        dateTo: Object.fromEntries(formData).dateTo,
        zone: Object.fromEntries(formData).zone,
        query: JSON.parse(Object.fromEntries(formData).query),
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.REFUND_WITH_ORDER_REPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.REFUND_WITH_ORDER_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    } else {
      const payload = JSON.stringify({
        location: JSON.parse(Object.fromEntries(formData).location),
        dateFrom: Object.fromEntries(formData).dateFrom,
        dateTo: Object.fromEntries(formData).dateTo,
        zone: Object.fromEntries(formData).zone,
        type: Object.fromEntries(formData).type,
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.REFUND_WITH_ORDER_REPORT_EXPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.REFUND_WITH_ORDER_REPORT_EXPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    }
  });
}

export function getRefundWithOrderLineData(payload) {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.REFUND_WITH_ORDER_LINE_REPORT_API_ROUTE,
            payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.REFUND_WITH_ORDER_LINE_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  export function getAllLocationsRefundWithOrderReport({ request, params }) {
    return new Promise(async (resolve, reject) => {
      const id = params.id;
      let token = localStorage.getItem("token");
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GET_ALL_LOCATIONS_API_ROUTE,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.REFUND_WITH_ORDER_LINE_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    });
  }