import {
  Box,
  Flex,
  Grid,
  GridItem,
  List,
  ListItem,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useActionData, useLoaderData } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import { PosBreadCrumb, PosTab } from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const OrderView = (props) => {
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const [orderTotal, setOrderTotal] = useState(0.0);
  const [paymentGateway, setPaymentGateway] = useState("");
  const [promotionAppliedFlag, setPromotionAppliedFlag] = useState(0);
  const [totalTipAmount, setTotalTipAmount] = useState(0.0);
  const [onlineOrderFlag, setOnlineOrderFlag] = useState(0);

  const tabNames = [
    "Basic Details",
    "Lines Details",
    "Payment Details",
    "Refund Details",
    "Reward Details",
  ];

  useEffect(() => {
    if (
      undefined != loaderResponse &&
      null != loaderResponse &&
      {} != loaderResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
      } else if (
        null !== loaderResponse.data[Constants.FLAGE] &&
        true === loaderResponse.data[Constants.FLAGE]
      ) {
        if (
          loaderResponse.data?.data?.all_lines.find(
            (e) => null != e.promotion_id && "" != e.promotion_id
          )
        ) {
          setPromotionAppliedFlag(1);
        }
        if (loaderResponse.data?.data?.payment.length > 0) {
          let lastPaymentObject =
            loaderResponse.data?.data?.payment[
            loaderResponse.data?.data?.payment.length - 1
            ];
          setPaymentGateway(lastPaymentObject.payment_gateway);
          let sumOfOrderTotal = 0.0;
          let sumOfTip = 0.0;
          loaderResponse.data?.data?.payment.forEach((value, key) => {
            // Total payment amount
            sumOfOrderTotal = sumOfOrderTotal * 1 + value["amount"] * 1;
            // Total tip amount
            sumOfTip = sumOfTip * 1 + value["tips_amount"] * 1;
          });
          setOrderTotal(sumOfOrderTotal.toFixed(2));
          setTotalTipAmount(sumOfTip.toFixed(2));
        }
        if (
          loaderResponse.data?.data.hasOwnProperty("online_order_detail") &&
          null != loaderResponse.data?.data?.online_order_detail &&
          "" != loaderResponse.data?.data?.online_order_detail
        ) {
          setOnlineOrderFlag(1);
        }
      } else {
        let actionData = loaderResponse;
        error({ actionData });
      }
    }
  }, [loaderResponse]);

  const handleTabChange = (index) => {
    // if (index == 0) {
    //   setLoading(true);
    //   fetchData();
    // } else {
    //   setLoading(true);
    //   fetchDeActiveCategoryData();
    // }
  };

  return (
    <Box padding={14} bg={Colors.loginAuthBackground}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={"2.94rem"}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Report", "Orders", "View Orders"]}
          breadCrumTitle={"View Orders"}
        />
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          <TabPanels>
            <TabPanel
              pl={{ base: "2.94rem", md: "2.94rem" }}
              pt={{ base: "3rem", md: "3rem" }}
              pr={{ base: "4.86rem", md: "4.86rem" }}
              pb={{ base: "3.13rem", md: "3.13rem" }}
            >
              <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                <GridItem>
                  <List spacing={"2rem"}>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Order Date
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {moment(loaderResponse.data?.data?.created_at).format(
                            "MM/DD/yyyy HH:mm:ss"
                          )}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Order ID
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {loaderResponse.data?.data?.id}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Local ID/Order No
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {loaderResponse.data?.data?.local_id}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Order name
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {null != loaderResponse.data?.data?.order_user_name &&
                            "" != loaderResponse.data?.data?.order_user_name
                            ? loaderResponse.data?.data?.order_user_name
                            : "N.A"}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Balance
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {loaderResponse.data?.data?.balance}
                        </Text>
                      </Stack>
                    </ListItem>
                  </List>
                </GridItem>
                <GridItem>
                  <List spacing={"2rem"}>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Created by
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {null == loaderResponse.data?.data?.order_user ||
                            null == loaderResponse.data?.data?.order_user ||
                            undefined == loaderResponse.data?.data?.order_user
                            ? "N.A."
                            : loaderResponse.data?.data?.order_user.name}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Order Total
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {orderTotal}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Payment Status
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {loaderResponse.data?.data?.paidStatus}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Location name
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {loaderResponse.data?.data?.station[0]?.name}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Cash Discount
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {null !=
                            loaderResponse.data?.data?.dual_pricing_discount &&
                            "" != loaderResponse.data?.data?.dual_pricing_discount
                            ? loaderResponse.data?.data?.dual_pricing_discount
                            : "N.A"}
                        </Text>
                      </Stack>
                    </ListItem>
                  </List>
                </GridItem>
                <GridItem>
                  <List spacing={"2rem"}>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          EBT Tax Exemption
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {loaderResponse.data?.data?.ebt_flag == 1
                            ? "Yes"
                            : "No"}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Payment Gateway
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {null == paymentGateway || "" == paymentGateway
                            ? "N.A."
                            : paymentGateway == "AUTHNET"
                              ? "Authorize.net"
                              : paymentGateway}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Order Discount
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {null != loaderResponse.data?.data?.discount_amount &&
                            "" != loaderResponse.data?.data?.discount_amount
                            ? loaderResponse.data?.data?.discount_amount
                            : "N.A"}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Discount Reason
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {null != loaderResponse.data?.data?.discount_reason &&
                            "" != loaderResponse.data?.data?.discount_reason
                            ? loaderResponse.data?.data?.discount_reason
                            : "N.A"}
                        </Text>
                      </Stack>
                    </ListItem>
                    {onlineOrderFlag == 1 && (
                      <ListItem>
                        <Stack spacing={"0.5rem"}>
                          <Text
                            fontSize={"0.94rem"}
                            fontStyle={"normal"}
                            fontWeight={"500"}
                            lineHeight={"normal"}
                            letterSpacing={"-0.01rem"}
                            color={Colors.orderViewLabel}
                          >
                            Online Order Type
                          </Text>
                          <Text
                            fontSize={"0.94rem"}
                            fontStyle={"normal"}
                            fontWeight={"300"}
                            lineHeight={"normal"}
                            letterSpacing={"-0.01rem"}
                            color={Colors.posTextInfo}
                          >
                            {Constants.PICKUP_CODE ==
                              loaderResponse.data?.data?.online_order_detail
                                .order_dispatch_type
                              ? Constants.PICKUP
                              : Constants.DELIVERY}
                          </Text>
                        </Stack>
                      </ListItem>
                    )}
                  </List>
                </GridItem>
                <GridItem>
                  <List spacing={"2rem"}>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Total Tips Amount
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {totalTipAmount}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Reward Customer Id
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {null != loaderResponse.data?.data?.reward_card_id &&
                            "" != loaderResponse.data?.data?.reward_card_id
                            ? loaderResponse.data?.data?.reward_card_id
                            : "N.A"}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          Promotion Applied
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {promotionAppliedFlag == 1 ? "Yes" : "No"}
                        </Text>
                      </Stack>
                    </ListItem>
                    <ListItem>
                      <Stack spacing={"0.5rem"}>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.orderViewLabel}
                        >
                          POS Station ID
                        </Text>
                        <Text
                          fontSize={"0.94rem"}
                          fontStyle={"normal"}
                          fontWeight={"300"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.01rem"}
                          color={Colors.posTextInfo}
                        >
                          {loaderResponse.data?.data?.pos_station_id}
                        </Text>
                      </Stack>
                    </ListItem>
                  </List>
                </GridItem>
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default WithRouter(OrderView);
