import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getAllLocationForDashboard() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.DASHBOARD_GET_LOCATION_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getTodayDashboardDetails(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_TODAY_DASHBOARD_DETAILS_API_ROUTE +
            payload.id +
            "/" +
            payload.myTodayDate
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPaymentDetails(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_PAYMENT_DTLS_API_ROUTE +
            payload.id +
            "/" +
            payload.myStartDate +
            "/" +
            payload.myEndDate +
            "/" +
            payload.rangeLabel
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function paymentMethod(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_PAYMENT_METHOD_API_ROUTE +
            payload.id +
            "/" +
            payload.myStartDate +
            "/" +
            payload.myEndDate
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function averageBill(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_AVERAGE_BILL_PAYMENT_API_ROUTE +
            payload.id +
            "/" +
            payload.myStartDate +
            "/" +
            payload.myEndDate
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function itemByVolumn(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_ITEM_BY_VOLUMN_API_ROUTE +
            payload.id +
            "/" +
            payload.myStartDate +
            "/" +
            payload.myEndDate
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function itemBySales(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_ITEM_BY_SALES_API_ROUTE +
            payload.id +
            "/" +
            payload.myStartDate +
            "/" +
            payload.myEndDate
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function rewardCustomers(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_REWARD_CUSTOMER_API_ROUTE +
            payload.id +
            "/" +
            payload.myStartDate +
            "/" +
            payload.myEndDate
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function onlineGiftCardSalesLineChart(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${
            Constants.DASHBOARD_GET_ONLINE_GIFT_CARD_SALES_DTLS_API_ROUTE +
            payload.id +
            "/" +
            payload.myStartDate +
            "/" +
            payload.myEndDate
          }`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.DASHBOARD_VIEW_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function itemBySalesReport(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.DASHBOARD_GET_ITEM_BY_SALES_REPORT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.REPORT_ITEMS_BY_SALES_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}

export function itemByVolumeReport(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.DASHBOARD_GET_ITEM_BY_VOLUME_REPORT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.REPORT_ITEMS_BY_VOLUME_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // let errorData = {
        //   error: true,
        //   response: error.response,
        //   code: error.code,
        // };
        // reject(errorData);
        reject(error);
      });
  });
}
