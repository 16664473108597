import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React,{ useContext } from "react";
import { HiBadgeCheck } from "react-icons/hi";
import Colors from "../../../assets/colors/Colors";
import noPageFound from "../../../assets/images/404.svg";
import { WithRouter } from "../../../navigators/WithRouter";
import {AppContext} from "../../../components/index";

const MasterAdminHomePage = (props) => {
  const myContext = useContext(AppContext);
  myContext.handleLoading(false);
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      mt={"8.25rem"}
      bg={Colors.loginAuthBackground}
    >
      <Flex alignItems={"center"} justifyContent={"center"} flexDir={"column"}>
        <Flex
          width={{ base: "25rem", lg: "32.1rem" }}
          height={"24.1rem"}
          flexShrink={0}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundImage={noPageFound}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"contain"}
          gap={10}
        >
          <Icon as={HiBadgeCheck} boxSize={"9.92rem"} />
          <Text
            fontSize={"1.5rem"}
            fontWeight={"600"}
            color={Colors.posTextInfo}
            letterSpacing={"-0.36px"}
          >
            {"Welcome to Octopos Master Admin!"}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default WithRouter(MasterAdminHomePage);
