import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Tag,
  TagLabel,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import LightModeIcon from "@mui/icons-material/LightMode";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { createTheme } from "@mui/material/styles";
import { LineChart } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
import { chartsGridClasses } from "@mui/x-charts/ChartsGrid";
import { lineElementClasses } from "@mui/x-charts/LineChart";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useLoaderData, useLocation, useSubmit } from "react-router-dom";
import {
  AppContext,
  PosDropDown,
  PosFormButton,
  PosOutlineButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  averageBill,
  getPaymentDetails,
  getTodayDashboardDetails,
  itemBySales,
  itemByVolumn,
  onlineGiftCardSalesLineChart,
  paymentMethod,
  rewardCustomers,
} from "./DashboardService";
const moment = require("moment");

const useStyles = (theme) => ({
  chart: {
    // Convert margin to use theme.spacing
    // margin: 8, // Assuming 62px is approximately theme.spacing(8)

    // Style adjustments based on breakpoints
    margin: 0,
    [theme.breakpoints.up("xs")]: {
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      margin: 0,
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
    [theme.breakpoints.up("lg")]: {
      margin: 0,
    },
    [theme.breakpoints.up("xl")]: {
      margin: 0,
    },
  },
  chart1: {
    // Convert margin to use theme.spacing
    // margin: 8, // Assuming 62px is approximately theme.spacing(8)

    // Style adjustments based on breakpoints
    margin: 1,
    // [theme.breakpoints.up('xs')]: {
    //   margin: 0.5,
    // },
    // [theme.breakpoints.up('sm')]: {
    //   margin: 0.5,
    // },
    [theme.breakpoints.up("md")]: {
      margin: 0.5,
    },
    [theme.breakpoints.up("lg")]: {
      margin: 0.5,
    },
    [theme.breakpoints.up("xl")]: {
      margin: 0.5,
    },
  },
  chart2: {
    // Convert margin to use theme.spacing
    // margin: 8, // Assuming 62px is approximately theme.spacing(8)

    // Style adjustments based on breakpoints
    margin: 2,
    [theme.breakpoints.up("xs")]: {
      margin: 2.5,
    },
    [theme.breakpoints.up("sm")]: {
      margin: 2.5,
    },
    [theme.breakpoints.up("md")]: {
      margin: 2.5,
    },
    [theme.breakpoints.up("lg")]: {
      margin: 1.5,
    },
    [theme.breakpoints.up("xl")]: {
      margin: 1.2,
    },
  },
});

const DashBoard = (props) => {
  const stateData = useLocation(); // get backraking data as param
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 400,
        md: 900,
        lg: 1200,
        xl: 1500,
      },
    },
  });
  const classes = useStyles(theme);
  const { addToast } = PosTostMessage();
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
    xl: false,
  });
  const [isExtraLarge] = useMediaQuery("(min-width: 1595px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1365px)");
  const [isMeduim] = useMediaQuery("(min-width: 1280px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isOpen, setIsOpen] = useState(false);
  const effectRun = useRef(true);
  const effectLocationRun = useRef(false);
  const [loginUserName, setLoginUserName] = useState("");
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const myContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    stateData.state != undefined &&
      stateData.state != null &&
      stateData.state != ""
      ? stateData.state.location
      : ""
  );
  const stateRefSelectedLocation = useRef(selectedLocation);
  const [ordersAvailable, setOrdersAvailable] = useState(0);
  // Set All Chart Loading.
  const [todayChartLoading, setTodayChartLoading] = useState(false);
  const [lineChartLoading, setLineChartLoading] = useState(false);
  const [barStackedChartLoading, setBarStackedChartLoading] = useState(false);
  const [barAverageChartLoading, setBarAverageChartLoading] = useState(false);
  const [barHorizontalChartLoading, setBarHorizontalChartLoading] = useState(
    false
  );
  const [
    barHorizontalVolumnChartLoading,
    setBarHorizontalVolumnChartLoading,
  ] = useState(false);
  const [
    barRewardLoyaltyChartLoading,
    setBarRewardLoyaltyChartLoading,
  ] = useState(false);
  const [
    barOnlineGiftCardChartLoading,
    setBarOnlineGiftCardChartLoading,
  ] = useState(false);

  //Total Payments Today
  const [todayTotalPayment, setTodayTotalPayment] = useState("0.00");
  const [
    lastPeriodYesterdayTotalPaymentColor,
    setLastPeriodYesterdayTotalPaymentColor,
  ] = useState("yellow");
  const [
    lastPeriodYesterdayTotalPaymentPercentage,
    setLastPeriodYesterdayTotalPaymentPercentage,
  ] = useState("0%");
  const [
    lastPeriodYesterdayPaymentAmount,
    setLastPeriodYesterdayPaymentAmount,
  ] = useState("$0.00");

  //Total Number of Orders Today
  const [todayTotalOrder, setTodayTotalOrder] = useState("0");
  const [
    lastPeriodYesterdayTotalOrderColor,
    setLastPeriodYesterdayTotalOrderColor,
  ] = useState("yellow");
  const [
    lastPeriodYesterdayTotalOrderPercentage,
    setLastPeriodYesterdayTotalOrderPercentage,
  ] = useState("0%");
  const [
    lastPeriodYesterdayTotalOrder,
    setLastPeriodYesterdayTotalOrder,
  ] = useState("0");

  // Total Reward Customers Today
  const [todayNoOfRewardsTotal, setTodayNoOfRewardsTotal] = useState("0");
  const [
    lastPeriodYesterdayNewRewardColor,
    setLastPeriodYesterdayNewRewardColor,
  ] = useState("yellow");
  const [
    lastPeriodYesterdayNewRewardPercentage,
    setLastPeriodYesterdayNewRewardPercentage,
  ] = useState("0%");
  const [
    lastPeriodYesterdayNewReward,
    setLastPeriodYesterdayNewReward,
  ] = useState("0");

  //Total Gift Card Sales Today
  const [todayGiftCardPaymentTotal, setTodayGiftCardPaymentTotal] = useState(
    "0.00"
  );
  const [
    lastPeriodYesterdayTotalGiftCardSalesColor,
    setLastPeriodYesterdayTotalGiftCardSalesColor,
    ,
  ] = useState("yellow");
  const [
    lastPeriodYesterdayTotalGiftCardSalesPercentage,
    setLastPeriodYesterdayTotalGiftCardSalesPercentage,
  ] = useState("0%");
  const [
    lastPeriodYesterdayTotalGiftCardSalesAmount,
    setLastPeriodYesterdayTotalGiftCardSalesAmount,
  ] = useState("$0.00");

  // Set All Chart Default Display Label.
  const [paymentCollectedReportType, setPaymentCollectedReportType] = useState(
    "Payment Collected"
  );
  const [
    paymentNumberOfOrderReportType,
    setPaymentNumberOfOrderReportType,
  ] = useState("Number of Orders");
  const [
    lastPeriodTotalPaymentPercentage,
    setLastPeriodTotalPaymentPercentage,
  ] = useState("0%");
  const [
    lastPeriodTotalPaymentAmount,
    setLastPeriodTotalPaymentAmount,
  ] = useState("$0.00");
  const [
    lastPeriodTotalPaymentColor,
    setLastPeriodTotalPaymentColor,
  ] = useState("yellow");

  const [lastPeriodTotalOrder, setLastPeriodTotalOrder] = useState("0");
  const [
    lastPeriodTotalOrderPercentage,
    setLastPeriodTotalOrderPercentage,
  ] = useState("0%");
  const [lastPeriodTotalOrderColor, setLastPeriodTotalOrderColor] = useState(
    "yellow"
  );

  const [paymentMethodReportType, setPaymentMethodReportType] = useState(
    "Payment Method"
  );

  const [averageBillReportType, setAverageBillReportType] = useState(
    "Average Bill Payment"
  );
  const [
    lastPeriodTotalAveragePercentage,
    setLastPeriodTotalAveragePercentage,
  ] = useState("0%");
  const [
    lastPeriodTotalAverageAmount,
    setLastPeriodTotalAverageAmount,
  ] = useState("$0.00");
  const [
    lastPeriodTotalAverageColor,
    setLastPeriodTotalAverageColor,
  ] = useState("yellow");

  const [rewardCustomerReportType, setRewardCustomerReportType] = useState(
    "New Reward Customers"
  );
  const [
    lastPeriodTotalRewardCustomerPercentage,
    setLastPeriodTotalRewardCustomerPercentage,
  ] = useState("0%");
  const [
    lastPeriodTotalRewardCustomer,
    setLastPeriodTotalRewardCustomer,
  ] = useState("0");
  const [
    lastPeriodTotalRewardCustomerColor,
    setLastPeriodTotalRewardCustomerColor,
  ] = useState("yellow");

  const [
    onlineGiftCardSalesReportType,
    setOnlineGiftCardSalesReportType,
  ] = useState("Online Gift Card Sales");
  const [
    lastPeriodTotalGiftCardSalesPercentage,
    setLastPeriodTotalGiftCardSalesPercentage,
  ] = useState("0%");
  const [
    lastPeriodTotalGiftCardSalesAmount,
    setLastPeriodTotalGifyCardSalesAmount,
  ] = useState("$0.00");
  const [
    lastPeriodTotalGifyCardSalesColor,
    setLastPeriodTotalGiftCardSalesColor,
  ] = useState("yellow");

  // Payment Collected Graph
  const [totalPayment, setTotalPayment] = useState([]);
  const [day, setDay] = useState([]);
  const [finalTotalPayment, setFinalTotalPayment] = useState(0);
  const [finalTotalOrder, setFinalTotalOrder] = useState("");

  // Payment Method Graph
  const [paymentTypeGraphFontSize, setPaymentTypeGraphFontSize] = useState(0);
  const [dayPaymentMethod, setDayPaymentMethod] = useState([]);
  const [
    paymentMethodBarStackedChartData,
    setPaymentMethodBarStackedChartData,
  ] = useState([]);
  const [
    finalDataWithCardMethodAmountString,
    setFinalDataWithCardMethodAmountString,
  ] = useState("0.00");
  const [
    finalDataWithCardMethodPercentageString,
    setFinalDataWithCardMethodPercentageString,
  ] = useState("0.00%");
  const [
    finalDataWithCashMethodAmountString,
    setFinalDataWithCashMethodAmountString,
  ] = useState("0.00");
  const [
    finalDataWithCashMethodPercentageString,
    setFinalDataWithCashMethodPercentageString,
  ] = useState("0.00%");
  const [
    finalDataWithOtherMethodAmountString,
    setFinalDataWithOtherMethodAmountString,
  ] = useState("0.00");
  const [
    finalDataWithOtherMethodPercentageString,
    setFinalDataWithOtherMethodPercentageString,
  ] = useState("0.00%");
  const [
    finalDataWithEbtMethodAmountString,
    setFinalDataWithEbtMethodAmountString,
  ] = useState("0.00");
  const [
    finalDataWithEbtMethodPercentageString,
    setFinalDataWithEbtMethodPercentageString,
  ] = useState("0.00%");

  // Average Payment Graph
  const [dayAveragePayment, setDayAveragePayment] = useState([]);
  const [averagePaymentBarChartData, setAveragePaymentBarChartData] = useState(
    []
  );
  const [
    finalTotalAveragePaymentAmount,
    setFinalTotalAveragePaymentAmount,
  ] = useState("");

  // Top Items by Sales Graph
  const [itemNameBarChart, setItemNameBarChart] = useState([]);
  const [itemSalesBarChartData, setItemSalesBarChartData] = useState([]);

  // Top Items by Volumn Graph
  const [itemNameVolumnBarChart, setItemNameVolumnBarChart] = useState([]);
  const [itemVolumnBarChartData, setItemVolumnBarChartData] = useState([]);

  // Loyalty Program Graph
  const [dayRewardLotyalty, setDayRewardLotyalty] = useState([]);
  const [rewardLotyaltyChartData, setRewardLotyaltyChartData] = useState([]);
  const [
    finalDayRewardLotyaltyTotal,
    setFinalDayRewardLotyaltyTotal,
  ] = useState("");

  // Online Gift Card Graph
  const [dayOnlineGiftCard, setDayOnlineGiftCard] = useState([]);
  const [onlineGiftCardChartData, setOnlineGiftCardChartData] = useState([]);
  const [
    displayOnlineGiftCardSalesLineChart,
    setDisplayOnlineGiftCardSalesLineChart,
  ] = useState(0);
  const [
    finalOnlineGiftCardSalesTotalPayment,
    setFinalOnlineGiftCardSalesTotalPayment,
  ] = useState("");

  // Calender Default Setting
  const today = new Date();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const todayDay = dayNames[today?.getDay()];
  // set today day when new tenant.
  const [lastPeriodDayName, setLastPeriodDayName] = useState(todayDay);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 6);
  const [dateRange, setDateRange] = useState([
    {
      startDate: sevenDaysAgo,
      endDate: today,
      key: "selection",
    },
  ]);

  // Calender Popover
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  // Select Date from Calender Popover
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  // On Loading all locations, last created order location and tenant order available check.
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          if (loaderResponse?.response?.data?.code == 401) {
            props.navigate(Constants.WELCOME_PATH);
          } else {
            let actionData = loaderResponse;
            error({ actionData });
          }
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          window.history.replaceState({}, "");
          // if (
          //   undefined !== localStorage.getItem("currentUser") &&
          //   null !== localStorage.getItem("currentUser") &&
          //   "" !== localStorage.getItem("currentUser")
          // ) {
          //   let user = JSON.parse(localStorage.getItem("currentUser"));
          //   setLoginUserName(user.name);
          // }
          // setLoginUserName(myContext.userData.userName);
          setLocations(loaderResponse?.data?.data?.locations);
          setSelectedLocation(
            stateData.state != undefined &&
              stateData.state != null &&
              stateData.state != ""
              ? stateData.state.location
              : loaderResponse?.data?.data?.location
          );
          setDateRange([
            {
              startDate:
                stateData.state != undefined &&
                stateData.state != null &&
                stateData.state != ""
                  ? new Date(stateData.state.myStartDate)
                  : dateRange[0]?.startDate,
              endDate:
                stateData.state != undefined &&
                stateData.state != null &&
                stateData.state != ""
                  ? new Date(stateData.state.myEndDate)
                  : dateRange[0]?.endDate,
              key: "selection",
            },
          ]);
          setOrdersAvailable(loaderResponse?.data?.data?.ordersAvailable);
          applyButton(
            stateData.state != undefined &&
              stateData.state != null &&
              stateData.state != ""
              ? stateData.state.location.id
              : loaderResponse?.data?.data?.location.id,
            loaderResponse?.data?.data?.ordersAvailable
          );
          myContext.handleLoading(false);
        } else {
          setLocations([]);
          setSelectedLocation([]);
          setOrdersAvailable(0);
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        // props.navigate(Constants.DASHBOARD_PATH);
        effectRun.current = false;
      };
    }
  });

  // Any change in Location Dropdown and Calender Submit Button and On load method call.
  useEffect(() => {
    if (effectLocationRun.current === true) {
      if (stateRefSelectedLocation != selectedLocation?.id) {
        applyButton(selectedLocation?.id, ordersAvailable);
      }
      stateRefSelectedLocation.current = selectedLocation?.id;
      effectLocationRun.current = false;
    }
  }, [selectedLocation]);

  const applyButton = (id, ordersAvailable) => {
    window.scrollTo(0, 0);
    setIsOpen(false);
    // if(null != id && "" != id && undefined != id){
    getTodayDashboardData(id, ordersAvailable);
    getLineChart(id, ordersAvailable);
    getPaymentMethod(id, ordersAvailable);
    getAverageBill(id, ordersAvailable);
    getItemByVolumn(id, ordersAvailable);
    getItemBySales(id, ordersAvailable);
    getRewardCustomers(id, ordersAvailable);
    if (process.env.REACT_APP_DOMAIN != "vori") {
      setDisplayOnlineGiftCardSalesLineChart(1);
      getOnlineGiftCardSalesLineChart(id, ordersAvailable);
    } else {
      setDisplayOnlineGiftCardSalesLineChart(0);
    }
    // }else{
    //   addToast({
    //     alertStatus: Constants.TOAST_TYPE_WARNING,
    //     alertTitle: Constants.TOAST_HEADING_WARNING,
    //     alertDescription: Constants.DASHBOARD_LOCATION_REQUIRED,
    //   });
    // }
  };

  const getTodayDashboardData = (id, ordersAvailable) => {
    setTodayChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let today = new Date();
      var startMonth = today.getMonth() + 1;
      var myTodayDate =
        today.getFullYear() + "-" + startMonth + "-" + today.getDate();
      let payload = {
        id: id,
        myTodayDate: myTodayDate,
      };
      try {
        getTodayDashboardDetails(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              setLastPeriodDayName(response?.data?.data?.lastPeriodDayName);
              setTodayTotalPayment(response?.data?.data?.todayTotalPayment);
              setLastPeriodYesterdayTotalPaymentPercentage(
                response?.data?.data?.lastPeriodYesterdayTotalPaymentPercentage
              );
              setLastPeriodYesterdayPaymentAmount(
                response?.data?.data?.lastPeriodYesterdayPaymentAmount
              );
              setLastPeriodYesterdayTotalPaymentColor(
                response?.data?.data?.lastPeriodYesterdayTotalPaymentColor
              );

              setTodayTotalOrder(response?.data?.data?.todayTotalOrder);
              setLastPeriodYesterdayTotalOrderPercentage(
                response?.data?.data?.lastPeriodYesterdayTotalOrderPercentage
              );
              setLastPeriodYesterdayTotalOrder(
                response?.data?.data?.lastPeriodYesterdayTotalOrder
              );
              setLastPeriodYesterdayTotalOrderColor(
                response?.data?.data?.lastPeriodYesterdayTotalOrderColor
              );

              setTodayNoOfRewardsTotal(
                response?.data?.data?.todayNoOfRewardsTotal
              );
              setLastPeriodYesterdayNewRewardPercentage(
                response?.data?.data?.lastPeriodYesterdayNewRewardPercentage
              );
              setLastPeriodYesterdayNewReward(
                response?.data?.data?.lastPeriodYesterdayNewReward
              );
              setLastPeriodYesterdayNewRewardColor(
                response?.data?.data?.lastPeriodYesterdayNewRewardColor
              );

              setTodayGiftCardPaymentTotal(
                response?.data?.data?.todayGiftCardPaymentTotal
              );
              setLastPeriodYesterdayTotalGiftCardSalesPercentage(
                response?.data?.data
                  ?.lastPeriodYesterdayTotalGiftCardSalesPercentage
              );
              setLastPeriodYesterdayTotalGiftCardSalesAmount(
                response?.data?.data
                  ?.lastPeriodYesterdayTotalGiftCardSalesAmount
              );
              setLastPeriodYesterdayTotalGiftCardSalesColor(
                response?.data?.data?.lastPeriodYesterdayTotalGiftCardSalesColor
              );
              setTodayChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setTodayChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setTodayChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setTodayChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      setTodayChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  // No orders available default date format set.
  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      weekday: "short", // 'short' for abbreviated day name
      month: "short", // 'short' for abbreviated month name
      day: "numeric", // numeric day of the month
    });
  };

  const getDaysArray = (start, end) => {
    let arr = [];
    let dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  const getLineChart = (id, ordersAvailable) => {
    setLineChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let start_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myStartDate
            : dateRange[0]?.startDate
        )
      );
      let end_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myEndDate
            : dateRange[0]?.endDate
        )
      );
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      var rangeLabel = "Custom range";
      let payload = {
        id: id,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
        rangeLabel: rangeLabel,
      };

      try {
        getPaymentDetails(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var day1 = [];
              var totalPayment = [];
              var finalTotalPayment = 0;
              var finalTotalOrder = 0;
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              setPaymentCollectedReportType(
                response?.data?.data?.paymentCollectedReportType
              );
              setPaymentNumberOfOrderReportType(
                response?.data?.data?.paymentNumberOfOrderReportType
              );
              setLastPeriodTotalPaymentPercentage(
                response?.data?.data?.lastPeriodTotalPaymentPercentage
              );
              setLastPeriodTotalPaymentAmount(
                response?.data?.data?.lastPeriodTotalPaymentAmount
              );
              setLastPeriodTotalPaymentColor(
                response?.data?.data?.lastPeriodTotalPaymentColor
              );
              setLastPeriodTotalOrderPercentage(
                response?.data?.data?.lastPeriodTotalOrderPercentage
              );
              setLastPeriodTotalOrder(
                response?.data?.data?.lastPeriodTotalOrder
              );
              setLastPeriodTotalOrderColor(
                response?.data?.data?.lastPeriodTotalOrderColor
              );
              if (
                (payload?.id == null &&
                  payload?.myStartDate == null &&
                  payload?.myEndDate == null) ||
                (id == location?.id && myStartDate == requestMyDate)
              ) {
                var daywisepayment = response?.data?.data?.daywisepayment;
                if (daywisepayment == "N.A.") {
                  setFinalTotalPayment("0.00");
                  setFinalTotalOrder(0);
                  setDay(["NA", "NA", "NA", "NA", "NA", "NA", "NA"]);
                  setTotalPayment(["0", "0", "0", "0", "0", "0", "0"]);
                } else {
                  daywisepayment.forEach((val) => {
                    finalTotalPayment += Number(val["totalpayment"]);
                    finalTotalOrder += Number(val["totalorders"]);
                    day1.push(val["day"]);
                    totalPayment.push(val["totalpayment"]);
                  });
                  setFinalTotalPayment(finalTotalPayment);
                  setDay(day1);
                  setTotalPayment(totalPayment);
                  setFinalTotalOrder(finalTotalOrder);
                }
              }
              setLineChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setDay([]);
              setTotalPayment([]);
              setFinalTotalPayment("0.00");
              setFinalTotalOrder(0);
              setLineChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setDay([]);
            setTotalPayment([]);
            setFinalTotalPayment("0.00");
            setFinalTotalOrder(0);
            setLineChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setDay([]);
        setTotalPayment([]);
        setFinalTotalPayment("0.00");
        setFinalTotalOrder(0);
        setLineChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      setFinalTotalPayment("0.00");
      setFinalTotalOrder(0);
      // setDay(["NA", "NA", "NA", "NA", "NA", "NA", "NA"]);
      const daysArray = getDaysArray(
        dateRange[0]?.startDate,
        dateRange[0]?.endDate
      );
      const formattedDates = daysArray?.map((date) => formatDate(date));
      setDay(formattedDates);
      setTotalPayment(["0", "0", "0", "0", "0", "0", "0"]);
      setLineChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getPaymentMethod = (id, ordersAvailable) => {
    setBarStackedChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let start_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myStartDate
            : dateRange[0]?.startDate
        )
      );
      let end_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myEndDate
            : dateRange[0]?.endDate
        )
      );
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: id,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
      };

      try {
        paymentMethod(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              setPaymentMethodReportType(
                response?.data?.data?.paymentMethodReportType
              );
              if (
                (payload?.id == null &&
                  payload?.myStartDate == null &&
                  payload?.myEndDate == null) ||
                (id == location?.id && myStartDate == requestMyDate)
              ) {
                var payment_methods = response?.data?.data?.payment_methods;
                var paymentMethodsTypesString =
                  response?.data?.data?.finalDataWithAllMethodString;
                setFinalDataWithCardMethodAmountString(
                  response?.data?.data?.finalDataWithCardMethodAmountString
                );
                setFinalDataWithCardMethodPercentageString(
                  response?.data?.data?.finalDataWithCardMethodPercentageString
                );
                setFinalDataWithCashMethodAmountString(
                  response?.data?.data?.finalDataWithCashMethodAmountString
                );
                setFinalDataWithCashMethodPercentageString(
                  response?.data?.data?.finalDataWithCashMethodPercentageString
                );
                setFinalDataWithOtherMethodAmountString(
                  response?.data?.data?.finalDataWithOtherMethodAmountString
                );
                setFinalDataWithOtherMethodPercentageString(
                  response?.data?.data?.finalDataWithOtherMethodPercentageString
                );
                setFinalDataWithEbtMethodAmountString(
                  response?.data?.data?.finalDataWithEbtMethodAmountString
                );
                setFinalDataWithEbtMethodPercentageString(
                  response?.data?.data?.finalDataWithEbtMethodPercentageString
                );
                if (
                  response?.data?.data?.finalDataWithCardMethodAmountString
                    ?.length > 7 ||
                  response?.data?.data?.finalDataWithCashMethodAmountString
                    ?.length > 7 ||
                  response?.data?.data?.finalDataWithOtherMethodAmountString
                    ?.length > 7 ||
                  response?.data?.data?.finalDataWithEbtMethodAmountString
                    ?.length > 7
                ) {
                  setPaymentTypeGraphFontSize(1);
                }else{
                  setPaymentTypeGraphFontSize(0);
                }
                var day = [];
                var CASH = [];
                var CARD = [];
                var OTHER = [];
                var EBT = [];
                payment_methods?.forEach((val) => {
                  day.push(val["day"]);

                  if ("CASH" in val) {
                    CASH.push(val["CASH"]);
                  } else {
                    CASH.push("0");
                  }

                  if ("CARD" in val) {
                    CARD.push(val["CARD"]);
                  } else {
                    CARD.push("0");
                  }

                  if ("OTHER" in val) {
                    OTHER.push(val["OTHER"]);
                  } else {
                    OTHER.push("0");
                  }

                  if ("EBT" in val) {
                    EBT.push(val["EBT"]);
                  } else {
                    EBT.push("0");
                  }
                });
                setDayPaymentMethod(day);
                setPaymentMethodBarStackedChartData([
                  {
                    data: CARD,
                    stack: "A",
                    label: "CARD",
                    color: "#2D5BFF",
                    valueFormatter: (value) =>
                      // `$${parseFloat(value).toFixed(2)}`,
                      value > 0.0
                        ? `$${Number(
                            parseFloat(value).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00",
                  },
                  {
                    data: CASH,
                    stack: "A",
                    label: "CASH",
                    color: "#42A6F4",
                    valueFormatter: (value) =>
                      // `$${parseFloat(value).toFixed(2)}`,
                      value > 0.0
                        ? `$${Number(
                            parseFloat(value).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00",
                  },
                  {
                    data: OTHER,
                    stack: "A",
                    label: "OTHER",
                    color: "#93AAFD",
                    valueFormatter: (value) =>
                      // `$${parseFloat(value).toFixed(2)}`,
                      value > 0.0
                        ? `$${Number(
                            parseFloat(value).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00",
                  },
                  {
                    data: EBT,
                    stack: "A",
                    label: "EBT",
                    color: "#8258FE",
                    valueFormatter: (value) =>
                      // `$${parseFloat(value).toFixed(2)}`,
                      value > 0.0
                        ? `$${Number(
                            parseFloat(value).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00",
                  },
                ]);
              }
              setBarStackedChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setDayPaymentMethod([]);
              setPaymentMethodBarStackedChartData([
                {
                  data: [],
                  stack: "A",
                  label: "CARD",
                  color: "#2D5BFF",
                },
                {
                  data: [],
                  stack: "A",
                  label: "CASH",
                  color: "#42A6F4",
                },
                {
                  data: [],
                  stack: "A",
                  label: "OTHER",
                  color: "#93AAFD",
                },
                {
                  data: [],
                  stack: "A",
                  label: "EBT",
                  color: "#8258FE",
                },
              ]);
              setBarStackedChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setDayPaymentMethod([]);
            setPaymentMethodBarStackedChartData([
              {
                data: [],
                stack: "A",
                label: "CARD",
                color: "#2D5BFF",
              },
              {
                data: [],
                stack: "A",
                label: "CASH",
                color: "#42A6F4",
              },
              {
                data: [],
                stack: "A",
                label: "OTHER",
                color: "#93AAFD",
              },
              {
                data: [],
                stack: "A",
                label: "EBT",
                color: "#8258FE",
              },
            ]);
            setBarStackedChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setDayPaymentMethod([]);
        setPaymentMethodBarStackedChartData([
          {
            data: [],
            stack: "A",
            label: "CARD",
            color: "#2D5BFF",
          },
          {
            data: [],
            stack: "A",
            label: "CASH",
            color: "#42A6F4",
          },
          {
            data: [],
            stack: "A",
            label: "OTHER",
            color: "#93AAFD",
          },
          {
            data: [],
            stack: "A",
            label: "EBT",
            color: "#8258FE",
          },
        ]);
        setBarStackedChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      // setDayPaymentMethod(["NA", "NA", "NA", "NA", "NA", "NA", "NA"]);
      const daysArray = getDaysArray(
        dateRange[0]?.startDate,
        dateRange[0]?.endDate
      );
      const formattedDates = daysArray?.map((date) => formatDate(date));
      setDayPaymentMethod(formattedDates);
      setPaymentMethodBarStackedChartData([
        {
          data: [""],
          stack: "A",
          label: "CARD",
          color: "#2D5BFF",
        },
        {
          data: [""],
          stack: "A",
          label: "CASH",
          color: "#42A6F4",
        },
        {
          data: [""],
          stack: "A",
          label: "OTHER",
          color: "#93AAFD",
        },
        {
          data: [""],
          stack: "A",
          label: "EBT",
          color: "#8258FE",
        },
      ]);
      setBarStackedChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getAverageBill = (id, ordersAvailable) => {
    setBarAverageChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let start_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myStartDate
            : dateRange[0]?.startDate
        )
      );
      let end_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myEndDate
            : dateRange[0]?.endDate
        )
      );
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: id,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
      };

      try {
        averageBill(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              setAverageBillReportType(
                response?.data?.data?.averageBillReportType
              );
              if (
                (payload?.id == null &&
                  payload?.myStartDate == null &&
                  payload?.myEndDate == null) ||
                (id == location?.id && myStartDate == requestMyDate)
              ) {
                var averageBillSize = response?.data?.data?.averageBillSize;
                setFinalTotalAveragePaymentAmount(
                  response?.data?.data?.totalAveragePaymentAmount
                );
                setLastPeriodTotalAveragePercentage(
                  response?.data?.data?.lastPeriodTotalAveragePercentage
                );
                setLastPeriodTotalAverageAmount(
                  response?.data?.data?.lastPeriodTotalAverageAmount
                );
                setLastPeriodTotalAverageColor(
                  response?.data?.data?.lastPeriodTotalAverageColor
                );
                var day = [];
                var averagePayment = [];
                if ("0.00" != averageBillSize && "0" != averageBillSize) {
                  averageBillSize.forEach((val) => {
                    day.push(val["day"]);
                    averagePayment.push(val["avg"]);
                  });
                }
                setDayAveragePayment(day);
                setAveragePaymentBarChartData(averagePayment);
              }
              setBarAverageChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setFinalTotalAveragePaymentAmount("0.00");
              setDayAveragePayment([]);
              setAveragePaymentBarChartData([]);
              setBarAverageChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setFinalTotalAveragePaymentAmount("0.00");
            setDayAveragePayment([]);
            setAveragePaymentBarChartData([]);
            setBarAverageChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setFinalTotalAveragePaymentAmount("0.00");
        setDayAveragePayment([]);
        setAveragePaymentBarChartData([]);
        setBarAverageChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      setFinalTotalAveragePaymentAmount("0.00");
      // setDayAveragePayment(["NA", "NA", "NA", "NA", "NA", "NA", "NA"]);
      const daysArray = getDaysArray(
        dateRange[0]?.startDate,
        dateRange[0]?.endDate
      );
      const formattedDates = daysArray?.map((date) => formatDate(date));
      setDayAveragePayment(formattedDates);
      setAveragePaymentBarChartData(["0", "0", "0", "0", "0", "0", "0"]);
      setBarAverageChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getItemBySales = (id, ordersAvailable) => {
    setBarHorizontalChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let start_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myStartDate
            : dateRange[0]?.startDate
        )
      );
      let end_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myEndDate
            : dateRange[0]?.endDate
        )
      );
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: id,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
      };

      try {
        itemBySales(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              if (
                (payload?.id == null &&
                  payload?.myStartDate == null &&
                  payload?.myEndDate == null) ||
                (id == location?.id && myStartDate == requestMyDate)
              ) {
                var totalproductsale = response?.data?.data?.totalproductsale;
                var productsforsale = [];
                var sale = [];
                if (totalproductsale == "N.A.") {
                  // Remaning
                } else {
                  // totalproductsale.forEach((val) => {
                  //   productsforsale.push(val["product"]);
                  //   sale.push(val["totalsale"]);
                  // });
                  totalproductsale?.map((val, index) => {
                    const spaces = " ".repeat(index);
                    productsforsale.push(val["product"] + spaces);
                    sale.push(val["totalsale"]);
                  });
                }
                setItemNameBarChart(productsforsale);
                setItemSalesBarChartData(sale);
              }
              setBarHorizontalChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setItemNameBarChart([]);
              setItemSalesBarChartData([]);
              setBarHorizontalChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setItemNameBarChart([]);
            setItemSalesBarChartData([]);
            setBarHorizontalChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setItemNameBarChart([]);
        setItemSalesBarChartData([]);
        setBarHorizontalChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      setItemNameBarChart([]);
      setItemSalesBarChartData([]);
      setBarHorizontalChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getItemByVolumn = (id, ordersAvailable) => {
    setBarHorizontalVolumnChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let start_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myStartDate
            : dateRange[0]?.startDate
        )
      );
      let end_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myEndDate
            : dateRange[0]?.endDate
        )
      );
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: id,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
      };

      try {
        itemByVolumn(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              if (
                (payload?.id == null &&
                  payload?.myStartDate == null &&
                  payload?.myEndDate == null) ||
                (id == location?.id && myStartDate == requestMyDate)
              ) {
                var totalproductqty = response?.data?.data?.totalproductqty;
                var productsforqty = [];
                var qty = [];
                if (totalproductqty == "N.A.") {
                  // Remaning
                } else {
                  // totalproductqty.forEach((val) => {
                  //   productsforqty.push(val["product"]);
                  //   qty.push(val["totalqty"]);
                  // });
                  totalproductqty.map((val, index) => {
                    const spaces = " ".repeat(index);
                    productsforqty.push(val["product"] + spaces);
                    qty.push(val["totalqty"]);
                  });
                }
                setItemNameVolumnBarChart(productsforqty);
                setItemVolumnBarChartData(qty);
              }
              setBarHorizontalVolumnChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setItemNameVolumnBarChart([]);
              setItemVolumnBarChartData([]);
              setBarHorizontalVolumnChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setItemNameVolumnBarChart([]);
            setItemVolumnBarChartData([]);
            setBarHorizontalVolumnChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setItemNameVolumnBarChart([]);
        setItemVolumnBarChartData([]);
        setBarHorizontalVolumnChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      setItemNameVolumnBarChart([]);
      setItemVolumnBarChartData([]);
      setBarHorizontalVolumnChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getRewardCustomers = (id, ordersAvailable) => {
    setBarRewardLoyaltyChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let start_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myStartDate
            : dateRange[0]?.startDate
        )
      );
      let end_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myEndDate
            : dateRange[0]?.endDate
        )
      );
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: id,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
      };

      try {
        rewardCustomers(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              if (
                (payload?.id == null &&
                  payload?.myStartDate == null &&
                  payload?.myEndDate == null) ||
                (id == location?.id && myStartDate == requestMyDate)
              ) {
                setRewardCustomerReportType(
                  response?.data?.data?.rewardCustomerReportType
                );
                setLastPeriodTotalRewardCustomerPercentage(
                  response?.data?.data?.lastPeriodTotalNewRewardPercentage
                );
                setLastPeriodTotalRewardCustomer(
                  response?.data?.data?.lastPeriodTotalNewReward
                );
                setLastPeriodTotalRewardCustomerColor(
                  response?.data?.data?.lastPeriodTotalNewRewardColor
                );
                var day = [];
                var dayWiseRewardCard = [];
                var finalTotalRewardCards = 0;
                response?.data?.data?.new_customers.forEach((val) => {
                  finalTotalRewardCards += Number(val["noOfRewardCards"]);
                  day.push(val["day"]);
                  dayWiseRewardCard.push(val["noOfRewardCards"]);
                });

                setFinalDayRewardLotyaltyTotal(finalTotalRewardCards);
                setDayRewardLotyalty(day);
                setRewardLotyaltyChartData(dayWiseRewardCard);
              }
              setBarRewardLoyaltyChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setFinalDayRewardLotyaltyTotal(0);
              setDayRewardLotyalty([]);
              setRewardLotyaltyChartData([]);
              setBarRewardLoyaltyChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setFinalDayRewardLotyaltyTotal(0);
            setDayRewardLotyalty([]);
            setRewardLotyaltyChartData([]);
            setBarRewardLoyaltyChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setFinalDayRewardLotyaltyTotal(0);
        setDayRewardLotyalty([]);
        setRewardLotyaltyChartData([]);
        setBarRewardLoyaltyChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      setFinalDayRewardLotyaltyTotal(0);
      // setDayRewardLotyalty(["NA", "NA", "NA", "NA", "NA", "NA", "NA"]);
      const daysArray = getDaysArray(
        dateRange[0]?.startDate,
        dateRange[0]?.endDate
      );
      const formattedDates = daysArray?.map((date) => formatDate(date));
      setDayRewardLotyalty(formattedDates);
      setRewardLotyaltyChartData([0, 0, 0, 0, 0, 0, 0]);
      setBarRewardLoyaltyChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const getOnlineGiftCardSalesLineChart = (id, ordersAvailable) => {
    setBarOnlineGiftCardChartLoading(true);
    if (ordersAvailable == 1) {
      var id = id;
      let start_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myStartDate
            : dateRange[0]?.startDate
        )
      );
      let end_date = new Date(
        moment(
          stateData.state != undefined &&
            stateData.state != null &&
            stateData.state != ""
            ? stateData.state.myEndDate
            : dateRange[0]?.endDate
        )
      );
      var startMonth = start_date.getMonth() + 1;
      var endMonth = end_date.getMonth() + 1;
      var myStartDate =
        start_date.getFullYear() +
        "-" +
        startMonth +
        "-" +
        start_date.getDate();
      var myEndDate =
        end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
      let payload = {
        id: id,
        myStartDate: myStartDate,
        myEndDate: myEndDate,
      };

      try {
        onlineGiftCardSalesLineChart(payload)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var requestMyDate = response?.data?.data?.requestMyDate;
              var location = response?.data?.data?.location;
              if (
                (payload?.id == null &&
                  payload?.myStartDate == null &&
                  payload?.myEndDate == null) ||
                (id == location?.id && myStartDate == requestMyDate)
              ) {
                setOnlineGiftCardSalesReportType(
                  response?.data?.data?.onlineGiftCardSalesReportType
                );
                setLastPeriodTotalGiftCardSalesPercentage(
                  response?.data?.data?.lastPeriodTotalGiftCardSalesPercentage
                );
                setLastPeriodTotalGifyCardSalesAmount(
                  response?.data?.data?.lastPeriodTotalGiftCardSalesAmount
                );
                setLastPeriodTotalGiftCardSalesColor(
                  response?.data?.data?.lastPeriodTotalGiftCardSalesColor
                );
                var dayWiseOnlineGiftCardPayment =
                  response?.data?.data?.dayWiseOnlineGiftCardPayment;
                if (dayWiseOnlineGiftCardPayment == "N.A.") {
                  var finalOnlineGiftCardSalesTotalPayment = "0.00";
                  var day1 = ["NA", "NA", "NA", "NA", "NA", "NA", "NA"];
                  var onlineGiftCardSaleTotalPayment = [0, 0, 0, 0, 0, 0, 0];
                } else {
                  var day1 = [];
                  var onlineGiftCardSaleTotalPayment = [];
                  var finalOnlineGiftCardSalesTotalPayment = 0;
                  //When any Online Gift Card Sales payment collected then data display.
                  dayWiseOnlineGiftCardPayment.forEach((val) => {
                    finalOnlineGiftCardSalesTotalPayment += Number(
                      val["totalOnlineGiftCardPayment"]
                    );
                    day1.push(val["day"]);
                    onlineGiftCardSaleTotalPayment.push(
                      twofix(val["totalOnlineGiftCardPayment"])
                    );
                  });
                }
                setFinalOnlineGiftCardSalesTotalPayment(
                  twofix(finalOnlineGiftCardSalesTotalPayment)
                );
                setDayOnlineGiftCard(day1);
                setOnlineGiftCardChartData(onlineGiftCardSaleTotalPayment);
              }
              setBarOnlineGiftCardChartLoading(false);
              myContext.handleLoading(false);
            } else {
              setFinalOnlineGiftCardSalesTotalPayment("0.00");
              setDayOnlineGiftCard([]);
              setOnlineGiftCardChartData([]);
              setBarOnlineGiftCardChartLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setFinalOnlineGiftCardSalesTotalPayment("0.00");
            setDayOnlineGiftCard([]);
            setOnlineGiftCardChartData([]);
            setBarOnlineGiftCardChartLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setFinalOnlineGiftCardSalesTotalPayment("0.00");
        setDayOnlineGiftCard([]);
        setOnlineGiftCardChartData([]);
        setBarRewardLoyaltyChartLoading(false);
        myContext.handleLoading(false);
      }
    } else {
      //Order Not Available
      setFinalOnlineGiftCardSalesTotalPayment("0.00");
      // setDayOnlineGiftCard(["NA", "NA", "NA", "NA", "NA", "NA", "NA"]);
      const daysArray = getDaysArray(
        dateRange[0]?.startDate,
        dateRange[0]?.endDate
      );
      const formattedDates = daysArray?.map((date) => formatDate(date));
      setDayOnlineGiftCard(formattedDates);
      setOnlineGiftCardChartData([0, 0, 0, 0, 0, 0, 0]);
      setBarOnlineGiftCardChartLoading(false);
      myContext.handleLoading(false);
    }
  };

  const topItemBySalesReport = (id) => {
    var id = id;
    let start_date = new Date(
      // moment(
      stateData.state != undefined &&
      stateData.state != null &&
      stateData.state != ""
        ? stateData.state.myStartDate
        : dateRange[0]?.startDate
      // )
    );
    let end_date = new Date(
      // moment(
      stateData.state != undefined &&
      stateData.state != null &&
      stateData.state != ""
        ? stateData.state.myEndDate
        : dateRange[0]?.endDate
      // )
    );
    // var startMonth = start_date.getMonth() + 1;
    // var endMonth = end_date.getMonth() + 1;
    // var myStartDate =
    //   start_date.getFullYear() + "-" + startMonth + "-" + start_date.getDate();
    // var myEndDate =
    //   end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
    if (
      null != selectedLocation &&
      "" != selectedLocation &&
      undefined != selectedLocation
    ) {
      let payload = {
        location: selectedLocation,
        myStartDate: start_date,
        myEndDate: end_date,
        screen: "Dashboard",
      };
      setIsOpen(false);
      myContext.handleLoading(true);
      props.navigate(Constants.TOP_SALE_REPORT, {
        state: payload,
      });
    } else {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.DASHBOARD_LOCATION_REQUIRED,
      });
    }
  };

  const topItemByVolumeReport = (id) => {
    var id = id;
    let start_date = new Date(
      // moment(
      stateData.state != undefined &&
      stateData.state != null &&
      stateData.state != ""
        ? stateData.state.myStartDate
        : dateRange[0]?.startDate
      // )
    );
    let end_date = new Date(
      // moment(
      stateData.state != undefined &&
      stateData.state != null &&
      stateData.state != ""
        ? stateData.state.myEndDate
        : dateRange[0]?.endDate
      // )
    );
    // var startMonth = start_date.getMonth() + 1;
    // var endMonth = end_date.getMonth() + 1;
    // var myStartDate =
    //   start_date.getFullYear() + "-" + startMonth + "-" + start_date.getDate();
    // var myEndDate =
    //   end_date.getFullYear() + "-" + endMonth + "-" + end_date.getDate();
    if (
      null != selectedLocation &&
      "" != selectedLocation &&
      undefined != selectedLocation
    ) {
      let payload = {
        location: selectedLocation,
        myStartDate: start_date,
        myEndDate: end_date,
        screen: "Dashboard",
      };
      setIsOpen(false);
      myContext.handleLoading(true);
      props.navigate(Constants.TOP_VOLUME_REPORT, {
        state: payload,
      });
    } else {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.DASHBOARD_LOCATION_REQUIRED,
      });
    }
  };
  const applyClick = () => {
    stateData.state = null;
    applyButton(selectedLocation?.id, ordersAvailable);
  };
  const customize = {
    legend: { hidden: true },
  };

  // When graph have all days 0.00 then set axis.
  const allZeros = totalPayment.every((value) => value == "0.00");
  const allZeroAveragePaymentBarChartData = averagePaymentBarChartData.every(
    (value) => value == "0.00"
  );
  const allZeroOnlineGiftCardChartData = onlineGiftCardChartData.every(
    (value) => value == "0.00"
  );
  const allZeroRewardLotyaltyChartData = rewardLotyaltyChartData.every(
    (value) => value == "0"
  );
  const allZeroItemSalesBarChartData = itemSalesBarChartData.every(
    (value) => value == "0.00"
  );
  const allZeroItemVolumnBarChartData = itemVolumnBarChartData.every(
    (value) => value == "0"
  );

  // Payment Collected amount length greter than 7 to Less than equals to 8.
  const lengthCheckBetween = totalPayment?.some((value) => {
    const length = parseFloat(value)
      .toFixed(2)
      .toString().length;
    return length > 7 && length <= 8;
  });

  // Payment Collected amount length greter than 8.
  const lengthCheckGreater = totalPayment?.some((value) => {
    const length = parseFloat(value)
      .toFixed(2)
      .toString().length;

    return length > 8;
  });

  // // Payment Collected amount length greter than 7 to Less than equals to 8.
  // const lengthCheckBetweenPaymentAverage = totalPayment?.some((value) => {
  //   const length = parseFloat(value).toFixed(2).toString().length;
  //   return length > 6 && length <=7;
  // });

  // // Payment Collected amount length greter than 8.
  // const lengthCheckGreaterPaymentAverage = totalPayment?.some((value) => {
  //   const length = parseFloat(value).toFixed(2).toString().length;

  //   return length > 7;
  // });

  function allZerosInPaymentType() {
    for (let dataSet of paymentMethodBarStackedChartData) {
      for (let value of dataSet.data) {
        if (value != 0) {
          return false; // Found a non-zero value, return false
        }
      }
    }
    return true; // All values are zero
  }

  // Check if all data sets have all zero values
  const allZeroPaymentMethodBarStackedChartData = allZerosInPaymentType();

  const currentHour = new Date().getHours();
  const greetingMessage =
    currentHour >= 4 && currentHour < 12 // after 4:00AM and before 12:00PM
      ? "Good morning"
      : currentHour >= 12 && currentHour <= 19 // after 12:00PM and before 7:00PM
      ? "Good afternoon"
      : currentHour > 19 && currentHour < 22 // after 7:00PM and before 10:00PM
      ? "Good evening"
      : "Good night"; // after 10PM or before 4:00AM

  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Box>
        <Box display={"flex"} alignItems={"center"} gap={"0.31rem"}>
          {greetingMessage == "Good morning" ||
          greetingMessage == "Good afternoon" ? (
            <LightModeIcon
              color={"#FFAC33"}
              style={{ fill: "#FFAC33" }}
              w={"1.06rem"}
              h={"1.06rem"}
            />
          ) : greetingMessage == "Good evening" ||
            greetingMessage == "Good night" ? (
            <BedtimeIcon
              color={"#010048"}
              style={{ fill: "#010048" }}
              w={"1.06rem"}
              h={"1.06rem"}
            />
          ) : (
            ""
          )}
          <Text color={"#010048"} fontSize={"0.94rem"} fontWeight={"light"}>
            {greetingMessage}{" "}
            <span style={{ textTransform: "capitalize" }}>{myContext.userData?.userName}</span>
            ,
          </Text>
        </Box>
        {/* <Text color={"#010048"} fontSize={"2xl"} fontWeight={"semibold"}>
          Welcome {loginUserName},
        </Text> */}
      </Box>
      <Box pt={{ base: "2rem", md: "0.88rem" }} pb={"1.5rem"}>
        <Divider background={"#D9D9D9"} />
      </Box>
      {/* Top 4 Boxes */}
      {/* <SimpleGrid minChildWidth="250px" spacing="10px"> */}
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 4 }}
        // spacing={{
        //   base: "1rem",
        //   sm: "1rem",
        //   md: "1rem",
        //   lg: "1rem",
        //   xl: "1.2rem",
        // }}
        spacing={
          isExtraLarge
            ? "1.2rem"
            : isLarge
            ? "0.5rem"
            : isMeduim1
            ? "0.3rem"
            : isMeduim
            ? "0.2rem"
            : "1.2rem"
        }
      >
        <Box
          // mb={{ base: 4, md: 2 }}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            // py={"1.44rem"}
            // px={"1.75rem"}
          >
            <Box>
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={
                  isExtraLarge
                    ? "md"
                    : isLarge
                    ? "md"
                    : isMeduim1
                    ? "15px"
                    : isMeduim
                    ? "14px"
                    : "md"
                }
                fontWeight={"light"}
                width={"max-content"}
                display={"contents"}
              >
                Total Payments Today
              </Text>
            </Box>
            {todayChartLoading ? (
              <Box color={"#00126833"} margin={5}>
                <Stack>
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="180" />
                </Stack>
              </Box>
            ) : (
              <>
                {lastPeriodYesterdayTotalPaymentColor == "green" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayTotalPayment} */}$
                      {todayTotalPayment > 0.0
                        ? Number(
                            parseFloat(todayTotalPayment).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#E9FFE8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingUpIcon style={{ color: "#28B625" }} />
                        <TagLabel
                          color={"#28B625"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalPaymentPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayTotalPaymentColor == "red" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayTotalPayment} */}$
                      {todayTotalPayment > 0.0
                        ? Number(
                            parseFloat(todayTotalPayment).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#FFE2E2"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingDownIcon style={{ color: "#B62525" }} />
                        <TagLabel
                          color={"#B62525"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalPaymentPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayTotalPaymentColor == "yellow" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayTotalPayment} */}$
                      {todayTotalPayment > 0.0
                        ? Number(
                            parseFloat(todayTotalPayment).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#feebc8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingFlatIcon style={{ color: "#F59638" }} />
                        <TagLabel
                          color={"#F59638"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalPaymentPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : (
                  <Box display={"flex"} gap={2}>
                    <Text
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayTotalPayment} */}$
                      {todayTotalPayment > 0.0
                        ? Number(
                            parseFloat(todayTotalPayment).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      {lastPeriodYesterdayTotalPaymentPercentage}
                    </Text>
                  </Box>
                )}
                <Text
                  fontSize={"0.93rem"}
                  color={"rgba(1, 0, 72, 0.70)"}
                  fontWeight={"light"}
                  display={"flex"}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                >
                  <span>
                    &nbsp;vs Last {lastPeriodDayName}{" "}
                    <span style={{ fontWeight: "500" }}>
                      {lastPeriodYesterdayPaymentAmount}
                    </span>
                  </span>
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box
          // mb={{ base: 4, md: 2 }}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
            <Box>
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={
                  isExtraLarge
                    ? "md"
                    : isLarge
                    ? "md"
                    : isMeduim1
                    ? "15px"
                    : isMeduim
                    ? "14px"
                    : "md"
                }
                fontWeight={"light"}
                width={"max-content"}
                display={"contents"}
              >
                Total Number of Orders Today
              </Text>
            </Box>
            {todayChartLoading ? (
              <Box color={"#00126833"} margin={5}>
                <Stack>
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="180" />
                </Stack>
              </Box>
            ) : (
              <>
                {lastPeriodYesterdayTotalOrderColor == "green" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayTotalOrder} */}
                      {todayTotalOrder > 0
                        ? Number(todayTotalOrder).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#E9FFE8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingUpIcon style={{ color: "#28B625" }} />
                        <TagLabel
                          color={"#28B625"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalOrderPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayTotalOrderColor == "red" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayTotalOrder} */}
                      {todayTotalOrder > 0
                        ? Number(todayTotalOrder).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#FFE2E2"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingDownIcon style={{ color: "#B62525" }} />
                        <TagLabel
                          color={"#B62525"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalOrderPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayTotalOrderColor == "yellow" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayTotalOrder} */}
                      {todayTotalOrder > 0
                        ? Number(todayTotalOrder).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#feebc8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingFlatIcon style={{ color: "#F59638" }} />
                        <TagLabel
                          color={"#F59638"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalOrderPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayTotalOrder} */}
                      {todayTotalOrder > 0
                        ? Number(todayTotalOrder).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      {lastPeriodYesterdayTotalOrderPercentage}
                    </Text>
                  </Box>
                )}
                <Text
                  fontSize={"0.93rem"}
                  color={"rgba(1, 0, 72, 0.70)"}
                  fontWeight={"light"}
                  display={"flex"}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                >
                  <span>
                    &nbsp;vs Last {lastPeriodDayName}{" "}
                    <span style={{ fontWeight: "500" }}>
                      {lastPeriodYesterdayTotalOrder}
                    </span>
                  </span>
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box
          // mb={{ base: 4, md: 2 }}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
            <Box>
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={
                  isExtraLarge
                    ? "md"
                    : isLarge
                    ? "md"
                    : isMeduim1
                    ? "15px"
                    : isMeduim
                    ? "14px"
                    : "md"
                }
                fontWeight={"light"}
                width={"max-content"}
                display={"contents"}
              >
                Total Gift Card Sales Today
              </Text>
            </Box>
            {todayChartLoading ? (
              <Box color={"#00126833"} margin={5}>
                <Stack>
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="180" />
                </Stack>
              </Box>
            ) : (
              <>
                {lastPeriodYesterdayTotalGiftCardSalesColor == "green" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayGiftCardPaymentTotal} */}$
                      {todayGiftCardPaymentTotal > 0.0
                        ? Number(
                            parseFloat(todayGiftCardPaymentTotal).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#E9FFE8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingUpIcon style={{ color: "#28B625" }} />
                        <TagLabel
                          color={"#28B625"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalGiftCardSalesPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayTotalGiftCardSalesColor == "red" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayGiftCardPaymentTotal} */}$
                      {todayGiftCardPaymentTotal > 0.0
                        ? Number(
                            parseFloat(todayGiftCardPaymentTotal).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#FFE2E2"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingDownIcon style={{ color: "#B62525" }} />
                        <TagLabel
                          color={"#B62525"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalGiftCardSalesPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayTotalGiftCardSalesColor == "yellow" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayGiftCardPaymentTotal} */}$
                      {todayGiftCardPaymentTotal > 0.0
                        ? Number(
                            parseFloat(todayGiftCardPaymentTotal).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#feebc8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingFlatIcon style={{ color: "#F59638" }} />
                        <TagLabel
                          color={"#F59638"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayTotalGiftCardSalesPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* ${todayGiftCardPaymentTotal} */}$
                      {todayGiftCardPaymentTotal > 0.0
                        ? Number(
                            parseFloat(todayGiftCardPaymentTotal).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      {lastPeriodYesterdayTotalGiftCardSalesPercentage}
                    </Text>
                  </Box>
                )}
                <Text
                  fontSize={"0.93rem"}
                  color={"rgba(1, 0, 72, 0.70)"}
                  fontWeight={"light"}
                  display={"flex"}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                >
                  <span>
                    &nbsp;vs Last {lastPeriodDayName}{" "}
                    <span style={{ fontWeight: "500" }}>
                      {lastPeriodYesterdayTotalGiftCardSalesAmount}
                    </span>
                  </span>
                </Text>
              </>
            )}
          </Box>
        </Box>
        <Box
          // mb={{ base: 4, md: 2 }}
          flexShrink={0}
          overflow="hidden"
          bg={"#FEFEFE"}
          borderRadius={"0.625rem"}
          border={"0.06rem solid #F8F7F7"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            display={"flex"}
            justify={"center"}
            flexDir={"column"}
            pt={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pb={{
              base: "1.44rem",
              sm: "1.44rem",
              md: "1.44rem",
              lg: "1.44rem",
              xl: "1.44rem",
            }}
            pl={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
            pr={
              isExtraLarge
                ? "1.75rem"
                : isLarge
                ? "0.75rem"
                : isMeduim1
                ? "0.75rem"
                : isMeduim
                ? "0.50rem"
                : "1.75rem"
            }
          >
            <Box>
              <Text
                color={"rgba(1, 0, 72, 0.50)"}
                fontSize={
                  isExtraLarge
                    ? "md"
                    : isLarge
                    ? "md"
                    : isMeduim1
                    ? "15px"
                    : isMeduim
                    ? "14px"
                    : "md"
                }
                fontWeight={"light"}
                width={"max-content"}
                display={"contents"}
              >
                Total Reward Customers Today
              </Text>
            </Box>
            {todayChartLoading ? (
              <Box color={"#00126833"} margin={5}>
                <Stack>
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="180" />
                </Stack>
              </Box>
            ) : (
              <>
                {lastPeriodYesterdayNewRewardColor == "green" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayNoOfRewardsTotal} */}
                      {todayNoOfRewardsTotal > 0
                        ? Number(todayNoOfRewardsTotal).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#E9FFE8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingUpIcon style={{ color: "#28B625" }} />
                        <TagLabel
                          color={"#28B625"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayNewRewardPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayNewRewardColor == "red" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayNoOfRewardsTotal} */}
                      {todayNoOfRewardsTotal > 0
                        ? Number(todayNoOfRewardsTotal).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#FFE2E2"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingDownIcon style={{ color: "#B62525" }} />
                        <TagLabel
                          color={"#B62525"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayNewRewardPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : lastPeriodYesterdayNewRewardColor == "yellow" ? (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayNoOfRewardsTotal} */}
                      {todayNoOfRewardsTotal > 0
                        ? Number(todayNoOfRewardsTotal).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      <Tag
                        // size="lg"
                        bg="#feebc8"
                        borderRadius="full"
                        w={"fit-content"}
                        // ml={1.5}
                      >
                        <TrendingFlatIcon style={{ color: "#F59638" }} />
                        <TagLabel
                          color={"#F59638"}
                          fontSize={"0.93rem"}
                          fontWeight={"light"}
                        >
                          {lastPeriodYesterdayNewRewardPercentage}
                        </TagLabel>
                      </Tag>
                    </Text>
                  </Box>
                ) : (
                  <Box display={"flex"} gap={2}>
                    <Text
                      // paddingTop={8}
                      color={"#1E1E1E"}
                      fontSize={"2xl"}
                      fontWeight={"semibold"}
                    >
                      {/* {todayNoOfRewardsTotal} */}
                      {todayNoOfRewardsTotal > 0
                        ? Number(todayNoOfRewardsTotal).toLocaleString("en")
                        : "0"}
                    </Text>
                    <Text
                      fontSize={"0.93rem"}
                      color={"rgba(1, 0, 72, 0.70)"}
                      fontWeight={"light"}
                      display={"flex"}
                      flexWrap={"wrap"}
                      alignItems={"center"}
                    >
                      {lastPeriodYesterdayNewRewardPercentage}
                    </Text>
                  </Box>
                )}
                <Text
                  fontSize={"0.93rem"}
                  color={"rgba(1, 0, 72, 0.70)"}
                  fontWeight={"light"}
                  display={"flex"}
                  flexWrap={"wrap"}
                  alignItems={"center"}
                >
                  <span>
                    &nbsp;vs Last {lastPeriodDayName}{" "}
                    <span style={{ fontWeight: "500" }}>
                      {lastPeriodYesterdayNewReward}
                    </span>
                  </span>
                </Text>
              </>
            )}
          </Box>
        </Box>
      </SimpleGrid>
      <Box pt={"1rem"} pb={"1rem"}>
        {/* <Divider background={"#D9D9D9"} /> */}
      </Box>
      {/* Location Dropdown and Datepicker */}
      <Flex
        direction={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        alignItems={"flex-start"}
        justify="space-between"
      >
        <Text color={"#010048"} fontSize={"2xl"} fontWeight={"semibold"}>
          Summary Report
        </Text>
        <Spacer />
        <ButtonGroup
          gap="2"
          alignSelf={"flex-end"}
          justifyContent={"flex-end"}
          flexWrap={"wrap"}
          paddingTop={{ base: "0.63rem", sm: 0, md: 0, lg: 0, xl: 0 }}
        >
          {ordersAvailable == 1 ? (
            <PosDropDown
              id="selectedSize"
              value={selectedLocation}
              onChange={(e) => {
                effectLocationRun.current = true;
                setSelectedLocation(e.value);
              }}
              options={locations}
              optionLabel={"name"}
              className="w-full md:w-20rem"
              width={"100%"}
              placeholder={"Select a Location"}
            ></PosDropDown>
          ) : null}
          <Popover
            isOpen={isOpen}
            onOpen={togglePopover}
            onClose={togglePopover}
          >
            <PopoverTrigger>
              <Button
                rightIcon={<ChevronDownIcon />}
                variant="avtar"
                h="38px"
                // fontSize={"sm"}
                fontWeight={300}
                lineHeight={5}
                color={"#010048"}
                backgroundColor={"rgba(88, 129, 254, 0.05)"}
                // _hover={{ bg: 'skyblue' }}
              >
                {moment(dateRange[0]?.startDate).format("D MMM, YYYY") +
                  " - " +
                  moment(dateRange[0]?.endDate).format("D MMM, YYYY")}
              </Button>
            </PopoverTrigger>
            <PopoverContent width="min-content">
              <PopoverArrow />
              <PopoverBody>
                <Flex direction={isMobile ? "column" : "row"} align="center">
                  <DateRangePicker
                    ranges={dateRange}
                    // presets={customRanges}
                    onChange={handleSelect}
                    // defaultValue={null}
                    rangeColors={["#126BE9"]}
                    months={2}
                    direction={isMobile ? "vertical" : "horizontal"}
                  />
                </Flex>
                <PosFormButton
                  isDisabled={ordersAvailable == 1 ? false : true}
                  buttonsubmit={"Apply"}
                  SubmitButton={true}
                  onClick={applyClick}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </ButtonGroup>
      </Flex>
      {/* Sales Performance */}
      <Flex
        gap={{ base: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "2rem" }}
        flexDir={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        mt={{ base: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "2rem" }}
        mb={{ base: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "2rem" }}
      >
        <Box
          width={{
            base: "100%",
            sm: "100%",
            md: "100%",
            lg: "23rem",
            xl: "23rem",
          }} // Set width in rem for different breakpoints
          borderRadius={"0.625rem"}
          // bg={Colors.posPageDataBackground}
          bg={"var(--Neutral-Colors-White, #FFF)"}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            pl={{
              base: "1rem",
              sm: "2rem",
              md: "2rem",
              lg: "1rem",
              xl: "2rem",
            }}
            pt={{
              base: "1rem",
              sm: "3.30rem",
              md: "3.30rem",
              lg: "3.30rem",
              xl: "3.30rem",
            }}
            pr={{
              base: "1rem",
              sm: "2rem",
              md: "2rem",
              lg: "1rem",
              xl: "2rem",
            }}
            pb={{
              base: "1rem",
              sm: "3.30rem",
              md: "3.30rem",
              lg: "3.30rem",
              xl: "3.30rem",
            }}
            gap={"0rem"}
          >
            <Box color={"#615E83"} fontSize={"1.5rem"} fontWeight={"semibold"}>
              Sales Performance
            </Box>
            {lineChartLoading ? (
              <Box color={"#00126833"} margin={5}>
                <Stack>
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="180" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="280" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                </Stack>
              </Box>
            ) : (
              <>
                <Box paddingTop={"1.56rem"}>
                  <Text
                    color={"#9291A5"}
                    fontSize={"0.94rem"}
                    fontWeight={"light"}
                  >
                    {paymentCollectedReportType}
                  </Text>
                  <Text
                    color={"#1E1B39"}
                    fontSize={"2.19rem"}
                    fontWeight={"semibold"}
                  >
                    {/* ${twofix(finalTotalPayment)} */}$
                    {finalTotalPayment > 0.0
                      ? Number(
                          parseFloat(finalTotalPayment).toFixed(2)
                        ).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0.00"}
                  </Text>
                  {lastPeriodTotalPaymentColor == "green" ? (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Tag
                          // size="lg"
                          bg="#E9FFE8"
                          borderRadius="full"
                          w={"fit-content"}
                          // ml={1.5}
                        >
                          <TrendingUpIcon style={{ color: "#28B625" }} />
                          <TagLabel
                            color={"#28B625"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {lastPeriodTotalPaymentPercentage}
                          </TagLabel>
                        </Tag>
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalPaymentAmount}
                          </span>
                        </span>
                      </Text>
                    </>
                  ) : lastPeriodTotalPaymentColor == "red" ? (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Tag
                          // size="lg"
                          bg="#FFE2E2"
                          borderRadius="full"
                          w={"fit-content"}
                          // ml={1.5}
                        >
                          <TrendingDownIcon style={{ color: "#B62525" }} />
                          <TagLabel
                            color={"#B62525"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {lastPeriodTotalPaymentPercentage}
                          </TagLabel>
                        </Tag>
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalPaymentAmount}
                          </span>
                        </span>
                      </Text>
                    </>
                  ) : lastPeriodTotalPaymentColor == "yellow" ? (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Tag
                          // size="lg"
                          bg="#feebc8"
                          borderRadius="full"
                          w={"fit-content"}
                          // ml={1.5}
                        >
                          <TrendingFlatIcon style={{ color: "#F59638" }} />
                          <TagLabel
                            color={"#F59638"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {lastPeriodTotalPaymentPercentage}
                          </TagLabel>
                        </Tag>
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalPaymentAmount}
                          </span>
                        </span>
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        {lastPeriodTotalPaymentPercentage}
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalPaymentAmount}
                          </span>
                        </span>
                      </Text>
                    </>
                  )}
                </Box>
                <Box pt={"1.87rem"}>
                  <Divider />
                </Box>
                <Box paddingTop={"1.56rem"}>
                  <Text
                    color={"#9291A5"}
                    fontSize={"0.94rem"}
                    fontWeight={"light"}
                  >
                    {/* Daily Number of Orders */}
                    {paymentNumberOfOrderReportType}
                  </Text>
                  <Text
                    color={"#1E1B39"}
                    fontSize={"2.19rem"}
                    fontWeight={"semibold"}
                  >
                    {/* {finalTotalOrder} */}
                    {finalTotalOrder > 0
                      ? Number(finalTotalOrder).toLocaleString("en")
                      : "0"}
                  </Text>
                  {lastPeriodTotalOrderColor == "green" ? (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Tag
                          // size="lg"
                          bg="#E9FFE8"
                          borderRadius="full"
                          w={"fit-content"}
                          // ml={1.5}
                        >
                          <TrendingUpIcon style={{ color: "#28B625" }} />
                          <TagLabel
                            color={"#28B625"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {lastPeriodTotalOrderPercentage}
                          </TagLabel>
                        </Tag>
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalOrder}
                          </span>
                        </span>
                      </Text>
                    </>
                  ) : lastPeriodTotalOrderColor == "red" ? (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Tag
                          // size="lg"
                          bg="#FFE2E2"
                          borderRadius="full"
                          w={"fit-content"}
                          // ml={1.5}
                        >
                          <TrendingDownIcon style={{ color: "#B62525" }} />
                          <TagLabel
                            color={"#B62525"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {lastPeriodTotalOrderPercentage}
                          </TagLabel>
                        </Tag>
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalOrder}
                          </span>
                        </span>
                      </Text>
                    </>
                  ) : lastPeriodTotalOrderColor == "yellow" ? (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        <Tag
                          // size="lg"
                          bg="#feebc8"
                          borderRadius="full"
                          w={"fit-content"}
                          // ml={1.5}
                        >
                          <TrendingFlatIcon style={{ color: "#F59638" }} />
                          <TagLabel
                            color={"#F59638"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {lastPeriodTotalOrderPercentage}
                          </TagLabel>
                        </Tag>
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalOrder}
                          </span>
                        </span>
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text
                        fontSize={"0.93rem"}
                        color={"rgba(1, 0, 72, 0.70)"}
                        fontWeight={"light"}
                        display={"flex"}
                        flexWrap={"wrap"}
                        alignItems={"center"}
                      >
                        {lastPeriodTotalOrderPercentage}
                        <span>
                          &nbsp;vs Last Period{" "}
                          <span style={{ fontWeight: "500" }}>
                            {lastPeriodTotalOrder}
                          </span>
                        </span>
                      </Text>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box
          width={{ base: "100%", sm: "100%", md: "100%", lg: "77%", xl: "77%" }} // Set width in rem for different breakpoints
          borderRadius={"0.625rem"}
          bg={"var(--Neutral-Colors-White, #FFF)"}
          // bg={"#FFF"}
          // mb={2.5}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            pl={{
              base: "0rem",
              sm: "0rem",
              md: "0.50rem",
              lg: "0.50rem",
              xl: "0.50rem",
            }}
            pt={{
              base: "0rem",
              sm: "0rem",
              md: "0.50rem",
              lg: "0.50rem",
              xl: "0.50rem",
            }}
            pr={{
              base: "0rem",
              sm: "0rem",
              md: "0.50rem",
              lg: "0.50rem",
              xl: "0.50rem",
            }}
            pb={{
              base: "0rem",
              sm: "0rem",
              md: "0.50rem",
              lg: "0.50rem",
              xl: "0.50rem",
            }}
            gap={"0rem"}
          >
            <Box>
              {lineChartLoading ? (
                <Box color={"#00126833"} margin={50}>
                  <Stack>
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="180" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="280" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                  </Stack>
                </Box>
              ) : (
                <LineChart
                  xAxis={[{ scaleType: "point", data: day }]}
                  yAxis={[
                    {
                      min: 0,
                      max: ordersAvailable == 1 ? (allZeros ? 10 : null) : 10,
                      valueFormatter: (value) =>
                        `$${value.toLocaleString("en")}`,
                    },
                  ]}
                  series={[
                    {
                      label: "Collected Payments",
                      data: totalPayment,
                      showMark: false,
                      curve: "linear",
                      valueFormatter: (value) =>
                        value > 0.0
                          ? `$${Number(
                              parseFloat(value).toFixed(2)
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                          : "$0.00",
                    },
                  ]}
                  height={396}
                  {...customize}
                  grid={{ vertical: true, horizontal: true }}
                  sx={{
                    ...(lengthCheckGreater
                      ? classes.chart2
                      : lengthCheckBetween
                      ? classes.chart1
                      : classes.chart),
                    //bottom label color.
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      fill: "#615E83",
                      fontSize: "12px",
                      // lineHeight: "20px",
                      fontWeight: 500,
                    },
                    //side label color.
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      fill: "#615E83",
                      fontSize: "12px",
                      // lineHeight: "20px",
                      fontWeight: 500,
                    },
                    //both side label stroke black color remove.
                    "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                      stroke: "none",
                    },
                    //graph both dashed line color.
                    "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1,
                    },
                    //vertical line color and width.
                    "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1.5,
                      strokeDasharray: "8 4", // This makes the line dashed
                    },
                    //bottom and side axis line color change.
                    "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 0,
                    },
                    //graph line color.
                    [`& .${lineElementClasses.root}`]: {
                      stroke: "#6585F7",
                      strokeWidth: 2,
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
      {/* Payment Type Overview and Payment Overview */}
      <Flex
        flexDir={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
        wrap="wrap"
        justify="center"
        align="center"
        // p={4}
      >
        <Flex
          direction="column"
          width={{ base: "100%", md: "100%", lg: "50%", xl: "50%" }} // Set width in rem for different breakpoints
          pr={{ base: 0, sm: 0, md: 0, lg: "0.60rem", xl: "0.60rem" }} // Add marginRight for space between columns
          // gap={"0.63rem"}
        >
          {/* <Box
            flex={1}
            flexShrink={"0"}
            // bg={Colors.posPageDataBackground}
            bg={"var(--Neutral-Colors-White, #FFF)"}
            borderRadius={"0.625rem"}
            mb={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "2rem",
            }}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
          >
            <Box
              align={"stretch"}
              pl={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pt={{
                base: "1rem",
                sm: "3.30rem",
                md: "3.30rem",
                lg: "3.30rem",
                xl: "3.30rem",
              }}
              pr={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pb={{
                base: "3.30rem",
                sm: "3.30rem",
                md: "3.30rem",
                lg: "3.30rem",
                xl: "3.30rem",
              }}
              gap={"0rem"}
            >
              <Box>
                <Text
                  color={"#615E83"}
                  fontSize={{
                    base: "1.5rem",
                    sm: "1.5rem",
                    md: "1.5rem",
                    lg: "1.3rem",
                    xl: "1.5rem",
                  }}
                  fontWeight={"semibold"}
                >
                  Payment Type Overview
                </Text>
              </Box>
              {barStackedChartLoading ? (
                <Box color={"#00126833"} margin={5}>
                  <Stack>
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="180" />
                  </Stack>
                </Box>
              ) : (
                <>
                  <Text
                    paddingTop={8}
                    color={"#9291A5"}
                    fontSize={"0.94rem"}
                    fontWeight={"light"}
                  >
                    {paymentMethodReportType}
                  </Text>
                  <SimpleGrid pt={3} columns={3} spacing={4}>
                    <Box height="3.56rem">
                      <Text
                        color={"#010048"}
                        fontSize={{
                          base: "1rem",
                          sm: "1.5rem",
                          md: "1.5rem",
                          lg: "1.3rem",
                          xl: "1.5rem",
                        }}
                        fontWeight={"semibold"}
                      >
                        {finalDataWithCardMethodAmountString}
                      </Text>
                      <Text
                        color={"rgba(1, 0, 72, 0.85)"}
                        fontSize={"0.93rem"}
                        fontWeight={"medium"}
                        display="flex"
                        flexDirection={{
                          base: "column",
                          sm: "column",
                          md: "column",
                          lg: "column",
                          xl: "row",
                        }}
                      >
                        CARD{" "}
                        <Tag
                          // size="lg"
                          bg="#ECECF6"
                          w={"fit-content"}
                          borderRadius="full"
                          ml={{ base: 0, sm: 0, md: 0, lg: 0, xl: 1.5 }}
                        >
                          <TagLabel
                            color={"#2D5BFF"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {finalDataWithCardMethodPercentageString}
                          </TagLabel>
                        </Tag>
                      </Text>
                    </Box>
                    <Box height="3.56rem">
                      <Text
                        color={"#010048"}
                        fontSize={{
                          base: "1rem",
                          sm: "1.5rem",
                          md: "1.5rem",
                          lg: "1.3rem",
                          xl: "1.5rem",
                        }}
                        fontWeight={"semibold"}
                      >
                        {finalDataWithCashMethodAmountString}
                      </Text>
                      <Text
                        color={"rgba(1, 0, 72, 0.85)"}
                        fontSize={"0.93rem"}
                        fontWeight={"medium"}
                        display="flex"
                        flexDirection={{
                          base: "column",
                          sm: "column",
                          md: "column",
                          lg: "column",
                          xl: "row",
                        }}
                      >
                        CASH{" "}
                        <Tag
                          // size="lg"
                          bg="#ECECF6"
                          w={"fit-content"}
                          borderRadius="full"
                          ml={{ base: 0, sm: 0, md: 0, lg: 0, xl: 1.5 }}
                        >
                          <TagLabel
                            color={"#2D5BFF"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {finalDataWithCashMethodPercentageString}
                          </TagLabel>
                        </Tag>
                      </Text>
                    </Box>
                    <Box height="3.56rem">
                      <Text
                        color={"#010048"}
                        fontSize={{
                          base: "1rem",
                          sm: "1.5rem",
                          md: "1.5rem",
                          lg: "1.3rem",
                          xl: "1.5rem",
                        }}
                        fontWeight={"semibold"}
                      >
                        {finalDataWithOtherMethodAmountString}
                      </Text>
                      <Text
                        color={"rgba(1, 0, 72, 0.85)"}
                        fontSize={"0.93rem"}
                        fontWeight={"medium"}
                        display="flex"
                        flexDirection={{
                          base: "column",
                          sm: "column",
                          md: "column",
                          lg: "column",
                          xl: "row",
                        }}
                      >
                        OTHER{" "}
                        <Tag
                          // size="lg"
                          bg="#ECECF6"
                          w={"fit-content"}
                          borderRadius="full"
                          ml={{ base: 0, sm: 0, md: 0, lg: 0, xl: 1.5 }}
                        >
                          <TagLabel
                            color={"#2D5BFF"}
                            fontSize={"0.93rem"}
                            fontWeight={"light"}
                          >
                            {finalDataWithOtherMethodPercentageString}
                          </TagLabel>
                        </Tag>
                      </Text>
                    </Box>
                  </SimpleGrid>
                </>
              )}
            </Box>
          </Box> */}
          <Box
            flex={1}
            flexShrink={"0"}
            // bg={Colors.posPageDataBackground}
            bg={"var(--Neutral-Colors-White, #FFF)"}
            borderRadius={"0.625rem"}
            mb={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "2rem",
            }}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
          >
            <Box
              align={"stretch"}
              // pl={{
              //   base: "1rem",
              //   sm: "2rem",
              //   md: "2rem",
              //   lg: "1rem",
              //   xl: "2rem",
              // }}
              // pt={{
              //   base: "1rem",
              //   sm: "3.30rem",
              //   md: "3.30rem",
              //   lg: "3.30rem",
              //   xl: "3.30rem",
              // }}
              // pr={{
              //   base: "1rem",
              //   sm: "2rem",
              //   md: "2rem",
              //   lg: "1rem",
              //   xl: "2rem",
              // }}
              pb={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              }}
              gap={"0rem"}
            >
              <Box
                direction="row"
                alignItems={{ base: "flex-start", md: "center" }}
                justify="space-between"
                pl={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "1rem",
                  xl: "2rem",
                }}
                pt={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "2rem",
                }}
                pr={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "1rem",
                  xl: "2rem",
                }}
                // pb={{
                //   base: "1rem",
                //   sm: "3.30rem",
                //   md: "3.30rem",
                //   lg: "3.30rem",
                //   xl: "3.30rem",
                // }}
              >
                <Box>
                  <Text
                    color={"#615E83"}
                    fontSize={{
                      base: "1.5rem",
                      sm: "1.5rem",
                      md: "1.5rem",
                      lg: "1.3rem",
                      xl: "1.5rem",
                    }}
                    fontWeight={"semibold"}
                  >
                    Payment Type Overview
                  </Text>
                  {barStackedChartLoading ? (
                    <Box color={"#00126833"} margin={30}>
                      <Stack>
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="200" />
                        <Skeleton height="10px" width="120" />
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="180" />
                        <Skeleton height="10px" width="200" />
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="200" />
                      </Stack>
                    </Box>
                  ) : (
                    <>
                      <Box mb={"2rem"}>
                        {/* <Text
                        color={"#615E83"}
                        fontSize={{
                          base: "1.5rem",
                          sm: "1.5rem",
                          md: "1.5rem",
                          lg: "1.3rem",
                          xl: "1.5rem",
                        }}
                        fontWeight={"semibold"}
                      >
                        Payment Type Overview
                      </Text> */}
                        <Text
                          // paddingTop={8}
                          color={"#9291A5"}
                          fontSize={"0.94rem"}
                          fontWeight={"light"}
                        >
                          {paymentMethodReportType}
                        </Text>
                        <SimpleGrid
                          pt={3}
                          columns={4}
                          spacing={1}
                          justifyItems={"flex-start"}
                        >
                          <Box height="3.56rem">
                            <Text
                              color={"#010048"}
                              // fontSize={{
                              //   base: "1rem",
                              //   sm: "1.5rem",
                              //   md: "1.5rem",
                              //   lg: "1rem",
                              //   xl: "1.3rem",
                              //   "2xl": "1.5rem",
                              // }}
                              fontSize={
                                paymentTypeGraphFontSize
                                  ? {
                                      base: "0.9rem",
                                      sm: "1rem",
                                      md: "1rem",
                                      lg: "0.8rem",
                                      xl: "1rem",
                                      "2xl": "1.3rem",
                                    }
                                  : {
                                      base: "1rem",
                                      sm: "1.3rem",
                                      md: "1.3rem",
                                      lg: "1rem",
                                      xl: "1.3rem",
                                      "2xl": "1.5rem",
                                    }
                              }
                              fontWeight={"semibold"}
                            >
                              $
                              {parseFloat(
                                finalDataWithCardMethodAmountString
                              ).toFixed(2) > 0.0
                                ? Number(
                                    parseFloat(
                                      finalDataWithCardMethodAmountString
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "0.00"}
                            </Text>
                            <Text
                              color={"rgba(1, 0, 72, 0.85)"}
                              fontSize={"0.93rem"}
                              fontWeight={"medium"}
                              display="flex"
                              flexDirection={{
                                base: "column",
                                sm: "column",
                                md: "column",
                                lg: "column",
                                xl: "column",
                                "2xl": "row",
                              }}
                            >
                              CARD{" "}
                              <Tag
                                // size="lg"
                                bg="#ECECF6"
                                w={"fit-content"}
                                borderRadius="full"
                                ml={{
                                  base: 0,
                                  sm: 0,
                                  md: 0,
                                  lg: 0,
                                  xl: 0,
                                  "2xl": 1.5,
                                }}
                              >
                                <TagLabel
                                  color={"#2D5BFF"}
                                  fontSize={"0.93rem"}
                                  fontWeight={"light"}
                                >
                                  {finalDataWithCardMethodPercentageString}
                                </TagLabel>
                              </Tag>
                            </Text>
                          </Box>
                          <Box height="3.56rem">
                            <Text
                              color={"#010048"}
                              // fontSize={{
                              //   base: "1rem",
                              //   sm: "1.5rem",
                              //   md: "1.5rem",
                              //   lg: "1rem",
                              //   xl: "1.3rem",
                              //   "2xl": "1.5rem",
                              // }}
                              fontSize={
                                paymentTypeGraphFontSize
                                  ? {
                                      base: "0.9rem",
                                      sm: "1rem",
                                      md: "1rem",
                                      lg: "0.8rem",
                                      xl: "1rem",
                                      "2xl": "1.3rem",
                                    }
                                  : {
                                      base: "1rem",
                                      sm: "1.3rem",
                                      md: "1.3rem",
                                      lg: "1rem",
                                      xl: "1.3rem",
                                      "2xl": "1.5rem",
                                    }
                              }
                              fontWeight={"semibold"}
                            >
                              {/* {finalDataWithCashMethodAmountString} */}$
                              {parseFloat(
                                finalDataWithCashMethodAmountString
                              ).toFixed(2) > 0.0
                                ? Number(
                                    parseFloat(
                                      finalDataWithCashMethodAmountString
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "0.00"}
                            </Text>
                            <Text
                              color={"rgba(1, 0, 72, 0.85)"}
                              fontSize={"0.93rem"}
                              fontWeight={"medium"}
                              display="flex"
                              flexDirection={{
                                base: "column",
                                sm: "column",
                                md: "column",
                                lg: "column",
                                xl: "column",
                                "2xl": "row",
                              }}
                            >
                              CASH{" "}
                              <Tag
                                // size="lg"
                                bg="#ECECF6"
                                w={"fit-content"}
                                borderRadius="full"
                                ml={{
                                  base: 0,
                                  sm: 0,
                                  md: 0,
                                  lg: 0,
                                  xl: 0,
                                  "2xl": 1.5,
                                }}
                              >
                                <TagLabel
                                  color={"#2D5BFF"}
                                  fontSize={"0.93rem"}
                                  fontWeight={"light"}
                                >
                                  {finalDataWithCashMethodPercentageString}
                                </TagLabel>
                              </Tag>
                            </Text>
                          </Box>
                          <Box height="3.56rem">
                            <Text
                              color={"#010048"}
                              // fontSize={{
                              //   base: "1rem",
                              //   sm: "1.5rem",
                              //   md: "1.5rem",
                              //   lg: "1rem",
                              //   xl: "1.3rem",
                              //   "2xl": "1.5rem",
                              // }}
                              fontSize={
                                paymentTypeGraphFontSize
                                  ? {
                                      base: "0.9rem",
                                      sm: "1rem",
                                      md: "1rem",
                                      lg: "0.8rem",
                                      xl: "1rem",
                                      "2xl": "1.3rem",
                                    }
                                  : {
                                      base: "1rem",
                                      sm: "1.3rem",
                                      md: "1.3rem",
                                      lg: "1rem",
                                      xl: "1.3rem",
                                      "2xl": "1.5rem",
                                    }
                              }
                              fontWeight={"semibold"}
                            >
                              {/* {finalDataWithOtherMethodAmountString} */}$
                              {parseFloat(
                                finalDataWithOtherMethodAmountString
                              ).toFixed(2) > 0.0
                                ? Number(
                                    parseFloat(
                                      finalDataWithOtherMethodAmountString
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "0.00"}
                            </Text>
                            <Text
                              color={"rgba(1, 0, 72, 0.85)"}
                              fontSize={"0.93rem"}
                              fontWeight={"medium"}
                              display="flex"
                              flexDirection={{
                                base: "column",
                                sm: "column",
                                md: "column",
                                lg: "column",
                                xl: "column",
                                "2xl": "row",
                              }}
                            >
                              OTHER{" "}
                              <Tag
                                // size="lg"
                                bg="#ECECF6"
                                w={"fit-content"}
                                borderRadius="full"
                                ml={{
                                  base: 0,
                                  sm: 0,
                                  md: 0,
                                  lg: 0,
                                  xl: 0,
                                  "2xl": 1.5,
                                }}
                              >
                                <TagLabel
                                  color={"#2D5BFF"}
                                  fontSize={"0.93rem"}
                                  fontWeight={"light"}
                                >
                                  {finalDataWithOtherMethodPercentageString}
                                </TagLabel>
                              </Tag>
                            </Text>
                          </Box>
                          <Box height="3.56rem">
                            <Text
                              color={"#010048"}
                              // fontSize={{
                              //   base: "1rem",
                              //   sm: "1.5rem",
                              //   md: "1.5rem",
                              //   lg: "1rem",
                              //   xl: "1.3rem",
                              //   "2xl": "1.5rem",
                              // }}
                              fontSize={
                                paymentTypeGraphFontSize
                                  ? {
                                      base: "0.9rem",
                                      sm: "1rem",
                                      md: "1rem",
                                      lg: "0.8rem",
                                      xl: "1rem",
                                      "2xl": "1.3rem",
                                    }
                                  : {
                                      base: "1rem",
                                      sm: "1.3rem",
                                      md: "1.3rem",
                                      lg: "1rem",
                                      xl: "1.3rem",
                                      "2xl": "1.5rem",
                                    }
                              }
                              fontWeight={"semibold"}
                            >
                              {/* {finalDataWithEbtMethodAmountString} */}$
                              {parseFloat(
                                finalDataWithEbtMethodAmountString
                              ).toFixed(2) > 0.0
                                ? Number(
                                    parseFloat(
                                      finalDataWithEbtMethodAmountString
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "0.00"}
                            </Text>
                            <Text
                              color={"rgba(1, 0, 72, 0.85)"}
                              fontSize={"0.93rem"}
                              fontWeight={"medium"}
                              display="flex"
                              flexDirection={{
                                base: "column",
                                sm: "column",
                                md: "column",
                                lg: "column",
                                xl: "column",
                                "2xl": "row",
                              }}
                            >
                              EBT{" "}
                              <Tag
                                // size="lg"
                                bg="#ECECF6"
                                w={"fit-content"}
                                borderRadius="full"
                                ml={{
                                  base: 0,
                                  sm: 0,
                                  md: 0,
                                  lg: 0,
                                  xl: 0,
                                  "2xl": 1.5,
                                }}
                              >
                                <TagLabel
                                  color={"#2D5BFF"}
                                  fontSize={"0.93rem"}
                                  fontWeight={"light"}
                                >
                                  {finalDataWithEbtMethodPercentageString}
                                </TagLabel>
                              </Tag>
                            </Text>
                          </Box>
                        </SimpleGrid>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>

              <Box ml={"2rem"} mr={"2rem"}>
                <Divider />
              </Box>
              <Box
                align={"stretch"}
                pl={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                pt={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                pr={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                pb={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                gap={"0rem"}
              >
                {barStackedChartLoading ? (
                  <Box color={"#00126833"} margin={30}>
                    <Stack>
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="280" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <BarChart
                    grid={{ horizontal: true }}
                    // sx={{
                    //   [`& .${chartsGridClasses.line}`]: {
                    //     strokeDasharray: "8 4",
                    //     strokeWidth: 1,
                    //   },
                    // }}
                    sx={{
                      ...(lengthCheckGreater
                        ? classes.chart2
                        : lengthCheckBetween
                        ? classes.chart1
                        : classes.chart),
                      //bottom label color.
                      "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                        fill: "#615E83",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 400,
                      },
                      //side label color.
                      "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                        fill: "#615E83",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 400,
                      },
                      [`& .${chartsGridClasses.line}`]: {
                        stroke: "#E5E5EF",
                        shapeRendering: "crispEdges",
                        strokeWidth: 1.5,
                        strokeDasharray: "8 4", // This makes the line dashed
                      },
                      //both side label stroke black color remove.
                      "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                        stroke: "none",
                      },
                      // //graph both dashed line color.
                      // "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                      //   stroke: "#E5E5EF",
                      //   shapeRendering: "crispEdges",
                      //   strokeWidth: 1
                      // },
                      // //vertical line color and width.
                      // "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                      //   stroke: "#E5E5EF",
                      //   shapeRendering: "crispEdges",
                      //   strokeWidth: 1.5,
                      //   strokeDasharray: "8 4", // This makes the line dashed
                      // },
                      //bottom and side axis line color change.
                      "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                        stroke: "#9291A5",
                        shapeRendering: "crispEdges",
                        strokeWidth: 1,
                      },
                      // //graph line color.
                      // [`& .${lineElementClasses.root}`]: {
                      //   stroke: "#6585F7",
                      //   strokeWidth: 2,
                      // }
                    }}
                    xAxis={[
                      {
                        scaleType: "band",
                        data: dayPaymentMethod,
                        categoryGapRatio: 0.52,
                        // barGapRatio: 2,
                        tickPlacement: "middle",
                        tickLabelStyle: {
                          angle: -32.0,
                          dominantBaseline: "hanging",
                          textAnchor: "end",
                        },
                        // valueFormatter: (dayPaymentMethod, context) => {
                        //   if (context.location === "tick") {
                        //     let parts = dayPaymentMethod.split(", ");
                        //     if (parts.length === 1) {
                        //       parts = dayPaymentMethod.split(" - ");
                        //     }
                        //     const dayOfWeek = parts[0];
                        //     const date = parts[1] ? parts[1] : "";
                        //     return `${dayOfWeek}\n${date}`;
                        //   }
                        //   return `${dayPaymentMethod}`;
                        // },
                        valueFormatter: (dayPaymentMethod, context) => {
                          if (context.location === "tick") {
                            let comma = true;
                            let parts = dayPaymentMethod.split(", ");
                            if (parts.length === 1) {
                              comma = false;
                              parts = dayPaymentMethod.split(" - ");
                            }
                            const dayOfWeek = comma
                              ? parts[0] + ","
                              : parts.length == 2
                              ? parts[0] + " - "
                              : parts[0];
                            const date = parts[1] ? parts[1] : "";
                            return `${dayOfWeek}\n${date}`;
                          }
                          return `${dayPaymentMethod}`;
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0, // Set your desired minimum value
                        max:
                          ordersAvailable == 1
                            ? allZeroPaymentMethodBarStackedChartData
                              ? 10
                              : null
                            : 10, // Set your desired maximum value
                        valueFormatter: (value) =>
                          `$${value.toLocaleString("en")}`,
                      },
                    ]}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "top", horizontal: "right" },
                        padding: 0,
                      },
                    }}
                    series={paymentMethodBarStackedChartData}
                    height={428}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Flex>
        <Flex
          direction="column"
          width={{ base: "100%", md: "100%", lg: "50%", xl: "50%" }} // Set width in rem for different breakpoints
          pl={{ base: 0, sm: 0, md: 0, lg: "0.60rem", xl: "0.60rem" }} // Add marginRight for space between columns
        >
          {/* <Box
            flex={1}
            flexShrink={"0"}
            // bg={Colors.posPageDataBackground}
            bg={"var(--Neutral-Colors-White, #FFF)"}
            borderRadius={"0.625rem"}
            mb={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "2rem",
            }}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
          >
            <Box
              align={"stretch"}
              pl={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pt={{
                base: "1rem",
                sm: "3.30rem",
                md: "3.30rem",
                lg: "3.30rem",
                xl: "3.30rem",
              }}
              pr={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pb={{
                base: "3.30rem",
                sm: "3.30rem",
                md: "3.30rem",
                lg: "3.30rem",
                xl: "3.30rem",
              }}
              gap={"0rem"}
            >
              <Box>
                <Text
                  color={"#615E83"}
                  fontSize={{
                    base: "1.5rem",
                    sm: "1.5rem",
                    md: "1.5rem",
                    lg: "1.3rem",
                    xl: "1.5rem",
                  }}
                  fontWeight={"semibold"}
                >
                  Payment Overview
                </Text>
              </Box>
              {barAverageChartLoading ? (
                <Box color={"#00126833"} margin={5}>
                  <Stack>
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="180" />
                  </Stack>
                </Box>
              ) : (
                <>
                  <Text
                    paddingTop={8}
                    color={"#9291A5"}
                    fontSize={"0.94rem"}
                    fontWeight={"light"}
                  >
                    {averageBillReportType}
                  </Text>
                  <SimpleGrid pt={3}>
                    <Box height="3.56rem">
                      <Text
                        color={"#010048"}
                        fontSize={"1.5rem"}
                        fontWeight={"semibold"}
                      >
                        ${finalTotalAveragePaymentAmount}
                      </Text>
                      {lastPeriodTotalAverageColor == "green" ? (
                        <>
                          <Text
                            fontSize={"0.93rem"}
                            color={"rgba(1, 0, 72, 0.70)"}
                            fontWeight={"light"}
                            display={"flex"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                          >
                            <Tag
                              // size="lg"
                              bg="#E9FFE8"
                              borderRadius="full"
                              w={"fit-content"}
                              // ml={1.5}
                            >
                              <TrendingUpIcon style={{ color: "#28B625" }} />
                              <TagLabel
                                color={"#28B625"}
                                fontSize={"0.93rem"}
                                fontWeight={"light"}
                              >
                                {lastPeriodTotalAveragePercentage}
                              </TagLabel>
                            </Tag>
                            <span>
                              &nbsp;vs Last Period{" "}
                              <span style={{ fontWeight: "500" }}>
                                {lastPeriodTotalAverageAmount}
                              </span>
                            </span>
                          </Text>
                        </>
                      ) : lastPeriodTotalAverageColor == "red" ? (
                        <>
                          <Text
                            fontSize={"0.93rem"}
                            color={"rgba(1, 0, 72, 0.70)"}
                            fontWeight={"light"}
                            display={"flex"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                          >
                            <Tag
                              // size="lg"
                              bg="#FFE2E2"
                              borderRadius="full"
                              w={"fit-content"}
                              // ml={1.5}
                            >
                              <TrendingDownIcon style={{ color: "#B62525" }} />
                              <TagLabel
                                color={"#B62525"}
                                fontSize={"0.93rem"}
                                fontWeight={"light"}
                              >
                                {lastPeriodTotalAveragePercentage}
                              </TagLabel>
                            </Tag>
                            <span>
                              &nbsp;vs Last Period{" "}
                              <span style={{ fontWeight: "500" }}>
                                {lastPeriodTotalAverageAmount}
                              </span>
                            </span>
                          </Text>
                        </>
                      ) : lastPeriodTotalAverageColor == "yellow" ? (
                        <>
                          <Text
                            fontSize={"0.93rem"}
                            color={"rgba(1, 0, 72, 0.70)"}
                            fontWeight={"light"}
                            display={"flex"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                          >
                            <Tag
                              // size="lg"
                              bg="#feebc8"
                              borderRadius="full"
                              w={"fit-content"}
                              // ml={1.5}
                            >
                              <TrendingFlatIcon style={{ color: "#F59638" }} />
                              <TagLabel
                                color={"#F59638"}
                                fontSize={"0.93rem"}
                                fontWeight={"light"}
                              >
                                {lastPeriodTotalAveragePercentage}
                              </TagLabel>
                            </Tag>
                            <span>
                              &nbsp;vs Last Period{" "}
                              <span style={{ fontWeight: "500" }}>
                                {lastPeriodTotalAverageAmount}
                              </span>
                            </span>
                          </Text>
                        </>
                      ) : (
                        <>
                          <Text
                            fontSize={"0.93rem"}
                            color={"rgba(1, 0, 72, 0.70)"}
                            fontWeight={"light"}
                            display={"flex"}
                            flexWrap={"wrap"}
                            alignItems={"center"}
                          >
                            {lastPeriodTotalAveragePercentage}
                            <span>
                              &nbsp;vs Last Period{" "}
                              <span style={{ fontWeight: "500" }}>
                                {lastPeriodTotalAverageAmount}
                              </span>
                            </span>
                          </Text>
                        </>
                      )}
                    </Box>
                  </SimpleGrid>
                </>
              )}
            </Box>
          </Box> */}
          <Box
            flex={1}
            flexShrink={"0"}
            // bg={Colors.posPageDataBackground}
            bg={"var(--Neutral-Colors-White, #FFF)"}
            borderRadius={"0.625rem"}
            mb={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1rem",
              xl: "2rem",
            }}
            boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
          >
            <Box
              align={"stretch"}
              // pl={{
              //   base: "1rem",
              //   sm: "2rem",
              //   md: "2rem",
              //   lg: "1rem",
              //   xl: "2rem",
              // }}
              // pt={{
              //   base: "1rem",
              //   sm: "3.30rem",
              //   md: "3.30rem",
              //   lg: "3.30rem",
              //   xl: "3.30rem",
              // }}
              // pr={{
              //   base: "1rem",
              //   sm: "2rem",
              //   md: "2rem",
              //   lg: "1rem",
              //   xl: "2rem",
              // }}
              pb={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              }}
              gap={"0rem"}
            >
              <Flex
                direction="row"
                alignItems={{ base: "flex-start", md: "center" }}
                justify="space-between"
                pl={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "1rem",
                  xl: "2rem",
                }}
                pt={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "2rem",
                  xl: "2rem",
                }}
                pr={{
                  base: "1rem",
                  sm: "2rem",
                  md: "2rem",
                  lg: "1rem",
                  xl: "2rem",
                }}
                // pb={{
                //   base: "1rem",
                //   sm: "3.30rem",
                //   md: "3.30rem",
                //   lg: "3.30rem",
                //   xl: "3.30rem",
                // }}
              >
                <Box>
                  <Text
                    color={"#615E83"}
                    fontSize={{
                      base: "1.5rem",
                      sm: "1.5rem",
                      md: "1.5rem",
                      lg: "1.3rem",
                      xl: "1.5rem",
                    }}
                    fontWeight={"semibold"}
                  >
                    Payment Overview
                  </Text>
                  {barAverageChartLoading ? (
                    <Box color={"#00126833"} margin={30}>
                      <Stack>
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="200" />
                        <Skeleton height="10px" width="120" />
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="180" />
                        <Skeleton height="10px" width="200" />
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="150" />
                        <Skeleton height="10px" width="200" />
                      </Stack>
                    </Box>
                  ) : (
                    <>
                      <Box mb={"2rem"}>
                        {/* <Text
                        color={"#615E83"}
                        fontSize={{
                          base: "1.5rem",
                          sm: "1.5rem",
                          md: "1.5rem",
                          lg: "1.3rem",
                          xl: "1.5rem",
                        }}
                        fontWeight={"semibold"}
                      >
                        Payment Overview
                      </Text> */}
                        <Text
                          // paddingTop={8}
                          color={"#9291A5"}
                          fontSize={"0.94rem"}
                          fontWeight={"light"}
                        >
                          {averageBillReportType}
                        </Text>
                        <SimpleGrid pt={3}>
                          <Box height="3.56rem">
                            <Text
                              color={"#010048"}
                              fontSize={"1.5rem"}
                              fontWeight={"semibold"}
                            >
                              {/* ${finalTotalAveragePaymentAmount} */}$
                              {finalTotalAveragePaymentAmount > 0.0
                                ? Number(
                                    parseFloat(
                                      finalTotalAveragePaymentAmount
                                    ).toFixed(2)
                                  ).toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "0.00"}
                            </Text>
                            {lastPeriodTotalAverageColor == "green" ? (
                              <>
                                <Text
                                  fontSize={"0.93rem"}
                                  color={"rgba(1, 0, 72, 0.70)"}
                                  fontWeight={"light"}
                                  display={"flex"}
                                  flexWrap={"wrap"}
                                  alignItems={"center"}
                                >
                                  <Tag
                                    // size="lg"
                                    bg="#E9FFE8"
                                    borderRadius="full"
                                    w={"fit-content"}
                                    // ml={1.5}
                                  >
                                    <TrendingUpIcon
                                      style={{ color: "#28B625" }}
                                    />
                                    <TagLabel
                                      color={"#28B625"}
                                      fontSize={"0.93rem"}
                                      fontWeight={"light"}
                                    >
                                      {lastPeriodTotalAveragePercentage}
                                    </TagLabel>
                                  </Tag>
                                  <span>
                                    &nbsp;vs Last Period{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {lastPeriodTotalAverageAmount}
                                    </span>
                                  </span>
                                </Text>
                              </>
                            ) : lastPeriodTotalAverageColor == "red" ? (
                              <>
                                <Text
                                  fontSize={"0.93rem"}
                                  color={"rgba(1, 0, 72, 0.70)"}
                                  fontWeight={"light"}
                                  display={"flex"}
                                  flexWrap={"wrap"}
                                  alignItems={"center"}
                                >
                                  <Tag
                                    // size="lg"
                                    bg="#FFE2E2"
                                    borderRadius="full"
                                    w={"fit-content"}
                                    // ml={1.5}
                                  >
                                    <TrendingDownIcon
                                      style={{ color: "#B62525" }}
                                    />
                                    <TagLabel
                                      color={"#B62525"}
                                      fontSize={"0.93rem"}
                                      fontWeight={"light"}
                                    >
                                      {lastPeriodTotalAveragePercentage}
                                    </TagLabel>
                                  </Tag>
                                  <span>
                                    &nbsp;vs Last Period{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {lastPeriodTotalAverageAmount}
                                    </span>
                                  </span>
                                </Text>
                              </>
                            ) : lastPeriodTotalAverageColor == "yellow" ? (
                              <>
                                <Text
                                  fontSize={"0.93rem"}
                                  color={"rgba(1, 0, 72, 0.70)"}
                                  fontWeight={"light"}
                                  display={"flex"}
                                  flexWrap={"wrap"}
                                  alignItems={"center"}
                                >
                                  <Tag
                                    // size="lg"
                                    bg="#feebc8"
                                    borderRadius="full"
                                    w={"fit-content"}
                                    // ml={1.5}
                                  >
                                    <TrendingFlatIcon
                                      style={{ color: "#F59638" }}
                                    />
                                    <TagLabel
                                      color={"#F59638"}
                                      fontSize={"0.93rem"}
                                      fontWeight={"light"}
                                    >
                                      {lastPeriodTotalAveragePercentage}
                                    </TagLabel>
                                  </Tag>
                                  <span>
                                    &nbsp;vs Last Period{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {lastPeriodTotalAverageAmount}
                                    </span>
                                  </span>
                                </Text>
                              </>
                            ) : (
                              <>
                                <Text
                                  fontSize={"0.93rem"}
                                  color={"rgba(1, 0, 72, 0.70)"}
                                  fontWeight={"light"}
                                  display={"flex"}
                                  flexWrap={"wrap"}
                                  alignItems={"center"}
                                >
                                  {lastPeriodTotalAveragePercentage}
                                  <span>
                                    &nbsp;vs Last Period{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {lastPeriodTotalAverageAmount}
                                    </span>
                                  </span>
                                </Text>
                              </>
                            )}
                          </Box>
                        </SimpleGrid>
                      </Box>
                    </>
                  )}
                </Box>
              </Flex>

              <Box ml={"2rem"} mr={"2rem"}>
                <Divider />
              </Box>
              <Box
                align={"stretch"}
                pl={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                pt={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                pr={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                pb={{
                  base: "0rem",
                  sm: "0rem",
                  md: "0.50rem",
                  lg: "0.50rem",
                  xl: "0.50rem",
                }}
                gap={"0rem"}
              >
                {barAverageChartLoading ? (
                  <Box color={"#00126833"} margin={30}>
                    <Stack>
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="280" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                    </Stack>
                  </Box>
                ) : (
                  <BarChart
                    grid={{ horizontal: true }}
                    // sx={{
                    //   [`& .${chartsGridClasses.line}`]: {
                    //     strokeDasharray: "8 4",
                    //     strokeWidth: 1,
                    //   },
                    // }}
                    sx={{
                      // ...(lengthCheckGreaterPaymentAverage ? classes.chart2 : lengthCheckBetweenPaymentAverage ? classes.chart1 : classes.chart),
                      //bottom label color.
                      "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                        fill: "#615E83",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 400,
                      },
                      //side label color.
                      "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                        fill: "#615E83",
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontWeight: 400,
                      },
                      [`& .${chartsGridClasses.line}`]: {
                        stroke: "#E5E5EF",
                        shapeRendering: "crispEdges",
                        strokeWidth: 1.5,
                        strokeDasharray: "8 4", // This makes the line dashed
                      },
                      //both side label stroke black color remove.
                      "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                        stroke: "none",
                      },
                      // //graph both dashed line color.
                      // "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                      //   stroke: "#E5E5EF",
                      //   shapeRendering: "crispEdges",
                      //   strokeWidth: 1
                      // },
                      // //vertical line color and width.
                      // "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                      //   stroke: "#E5E5EF",
                      //   shapeRendering: "crispEdges",
                      //   strokeWidth: 1.5,
                      //   strokeDasharray: "8 4", // This makes the line dashed
                      // },
                      //bottom and side axis line color change.
                      "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                        stroke: "#9291A5",
                        shapeRendering: "crispEdges",
                        strokeWidth: 1,
                      },
                      // //graph line color.
                      // [`& .${lineElementClasses.root}`]: {
                      //   stroke: "#6585F7",
                      //   strokeWidth: 2,
                      // }
                    }}
                    height={428}
                    series={[
                      {
                        data: averagePaymentBarChartData,
                        label: "Average Payment",
                        id: "pvId",
                        valueFormatter: (value) =>
                          // `$${parseFloat(value).toFixed(2)}`,
                          value > 0.0
                            ? `$${Number(
                                parseFloat(value).toFixed(2)
                              ).toLocaleString("en", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}`
                            : "$0.00",
                      },
                    ]}
                    xAxis={[
                      {
                        data: dayAveragePayment,
                        scaleType: "band",
                        categoryGapRatio: 0.62,
                        // barGapRatio: 0.448,
                        tickPlacement: "middle",
                        tickLabelStyle: {
                          angle: -32.0,
                          dominantBaseline: "hanging",
                          textAnchor: "end",
                        },
                        colorMap: {
                          type: "ordinal",
                          colors: ["#2D5BFF", "#93AAFD", "#F0E5FC", "#E5EAFC"],
                        },
                        // valueFormatter: (dayAveragePayment, context) => {
                        //   if (context.location === "tick") {
                        //     let parts = dayAveragePayment.split(", ");
                        //     if (parts.length === 1) {
                        //       parts = dayAveragePayment.split(" - ");
                        //     }
                        //     const dayOfWeek = parts[0];
                        //     const date = parts[1] ? parts[1] : "";
                        //     return `${dayOfWeek}\n${date}`;
                        //   }
                        //   return `${dayAveragePayment}`;
                        // },
                        valueFormatter: (dayAveragePayment, context) => {
                          if (context.location === "tick") {
                            let comma = true;
                            let parts = dayAveragePayment.split(", ");
                            if (parts.length === 1) {
                              comma = false;
                              parts = dayAveragePayment.split(" - ");
                            }
                            const dayOfWeek = comma
                              ? parts[0] + ","
                              : parts.length == 2
                              ? parts[0] + " - "
                              : parts[0];
                            const date = parts[1] ? parts[1] : "";
                            return `${dayOfWeek}\n${date}`;
                          }
                          return `${dayAveragePayment}`;
                        },
                      },
                    ]}
                    yAxis={[
                      {
                        min: 0, // Set your desired minimum value
                        max:
                          ordersAvailable == 1
                            ? allZeroAveragePaymentBarChartData
                              ? 10
                              : null
                            : 10, // Set your desired maximum value
                        valueFormatter: (value) =>
                          `$${value.toLocaleString("en")}`,
                      },
                    ]}
                    slotProps={{ legend: { hidden: true } }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Flex>
      </Flex>
      {/* Top Items by Sales and Top Items by Volume */}
      <Flex
        gap={{
          base: "0rem",
          sm: "0rem",
          md: "0rem",
          lg: "1.2rem",
          xl: "1.2rem",
        }}
        flexDir={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
      >
        <Box
          flex={1}
          flexShrink={"0"}
          // bg={Colors.posPageDataBackground}
          bg={"var(--Neutral-Colors-White, #FFF)"}
          borderRadius={"0.625rem"}
          mb={{ base: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "2rem" }}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            // pl={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            // pt={{
            //   base: "1rem",
            //   sm: "3.30rem",
            //   md: "3.30rem",
            //   lg: "3.30rem",
            //   xl: "3.30rem",
            // }}
            // pr={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            pb={{
              base: "1rem",
              sm: "2rem",
              md: "2rem",
              lg: "2rem",
              xl: "2rem",
            }}
            gap={"0rem"}
          >
            <Flex
              direction="row"
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              pl={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pt={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              }}
              pr={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              // pb={{
              //   base: "1rem",
              //   sm: "3.30rem",
              //   md: "3.30rem",
              //   lg: "3.30rem",
              //   xl: "3.30rem",
              // }}
            >
              <Box>
                {/* <Text fontSize={"sm"} color={"#9291A5"} fontWeight={"light"}>
                  Statistics
                </Text> */}
                <Text fontSize={"2xl"} color={"#1E1B39"} fontWeight={"bold"}>
                  Top Items by Sales
                </Text>
              </Box>
              {ordersAvailable == 1 ? (
                <PosOutlineButton
                  onClick={() => {
                    topItemBySalesReport(selectedLocation?.id);
                  }}
                  name="View Report"
                ></PosOutlineButton>
              ) : null}
            </Flex>

            <Box pt={"1.66rem"} ml={"2rem"} mr={"2rem"}>
              <Divider />
            </Box>
            {barHorizontalChartLoading ? (
              <Box color={"#00126833"} margin={30}>
                <Stack>
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="180" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="280" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="180" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="150" />
                </Stack>
              </Box>
            ) : (
              <BarChart
                grid={{ vertical: true }}
                borderRadius={4}
                sx={{
                  //bottom label color.
                  "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                    fill: "#615E83",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 400,
                  },
                  //side label color.
                  "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                    // display: "none",
                    fill: "#615E83",
                    fontSize: "14px",
                    // transform: "translateY(-20px) !important" ,
                    lineHeight: "16px",
                    fontWeight: 400,
                  },
                  //both side label stroke black color remove.
                  "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                    stroke: "none",
                  },
                  //graph both dashed line color.
                  "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                    stroke: "#E5E5EF",
                    shapeRendering: "crispEdges",
                    strokeWidth: 1,
                  },
                  //vertical line color and width.
                  "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                    stroke: "#E5E5EF",
                    shapeRendering: "crispEdges",
                    strokeWidth: 1.5,
                    strokeDasharray: "8 4", // This makes the line dashed
                  },
                  //bottom and side axis line color change.
                  "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                    stroke: "#E5E5EF",
                    shapeRendering: "crispEdges",
                    strokeWidth: 0,
                  },
                  //graph line color.
                  [`& .${lineElementClasses.root}`]: {
                    stroke: "#6585F7",
                    strokeWidth: 2,
                  },
                }}
                yAxis={[
                  {
                    scaleType: "band",
                    data: itemNameBarChart,
                    categoryGapRatio: 0.69,
                    colorMap: {
                      type: "ordinal",
                      colors: [
                        "#6D3AFF",
                        "#E642F4",
                        "#FD9393",
                        "#FFDB80",
                        "#F6D1A6",
                      ],
                    },
                    tickLabelStyle: {
                      textAnchor: "start",
                      dominantBaseline: "central",
                      // transform: " translateX(9px) translateY(-20px)",
                      transform:
                        itemNameBarChart.length == 1
                          ? " translateX(9px) translateY(-15%)"
                          : itemNameBarChart.length == 2
                          ? " translateX(9px) translateY(-11%)"
                          : itemNameBarChart.length == 3
                          ? " translateX(9px) translateY(-8%)"
                          : itemNameBarChart.length == 4
                          ? "translateX(9px) translateY(-5%)"
                          : "translateX(9px) translateY(-20px)", // 2 band = 70px // 1 band= 32%
                    },
                  },
                ]}
                slotProps={{
                  bar: {
                    clipPath: `inset(0px round 0px 4px 4px 0px)`,
                  },
                  noDataOverlay: { message: "" },
                }}
                xAxis={[
                  {
                    min: 0,
                    max:
                      ordersAvailable == 1
                        ? allZeroItemSalesBarChartData
                          ? 10
                          : null
                        : 10,
                  },
                ]}
                series={[
                  {
                    data: itemSalesBarChartData,
                    valueFormatter: (value) =>
                      value > 0.0
                        ? `$${Number(
                            parseFloat(value).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "$0.00",
                  },
                ]}
                height={315}
                layout="horizontal"
              />
            )}
          </Box>
        </Box>
        <Box
          flex={1}
          flexShrink={"0"}
          // bg={Colors.posPageDataBackground}
          bg={"var(--Neutral-Colors-White, #FFF)"}
          borderRadius={"0.625rem"}
          mb={{ base: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "2rem" }}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            // pl={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            // pt={{
            //   base: "1rem",
            //   sm: "3.30rem",
            //   md: "3.30rem",
            //   lg: "3.30rem",
            //   xl: "3.30rem",
            // }}
            // pr={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            pb={{
              base: "1rem",
              sm: "2rem",
              md: "2rem",
              lg: "2rem",
              xl: "2rem",
            }}
            gap={"0rem"}
          >
            <Flex
              direction="row"
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              pl={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pt={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              }}
              pr={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              // pb={{
              //   base: "1rem",
              //   sm: "3.30rem",
              //   md: "3.30rem",
              //   lg: "3.30rem",
              //   xl: "3.30rem",
              // }}
            >
              <Box>
                {/* <Text fontSize={"sm"} color={"#9291A5"} fontWeight={"light"}>
                  Statistics
                </Text> */}
                <Text fontSize={"2xl"} color={"#1E1B39"} fontWeight={"bold"}>
                  Top Items by Volume
                </Text>
              </Box>
              {ordersAvailable == 1 ? (
                <PosOutlineButton
                  onClick={() => {
                    topItemByVolumeReport(selectedLocation?.id);
                  }}
                  name="View Report"
                ></PosOutlineButton>
              ) : null}
            </Flex>
            <Box pt={"1.66rem"} ml={"2rem"} mr={"2rem"}>
              <Divider />
            </Box>
            {barHorizontalVolumnChartLoading ? (
              <Box color={"#00126833"} margin={30}>
                <Stack>
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="180" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="280" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="120" />
                  <Skeleton height="10px" width="180" />
                  <Skeleton height="10px" width="200" />
                  <Skeleton height="10px" width="150" />
                  <Skeleton height="10px" width="150" />
                </Stack>
              </Box>
            ) : (
              <BarChart
                grid={{ vertical: true }}
                borderRadius={4}
                // sx={{
                //   [`& .${chartsGridClasses.line}`]: {
                //     strokeDasharray: "8 4",
                //     strokeWidth: 1,
                //   },
                // }}
                sx={{
                  //bottom label color.
                  "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                    fill: "#615E83",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 400,
                  },
                  //side label color.
                  "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                    fill: "#615E83",
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontWeight: 400,
                  },
                  //both side label stroke black color remove.
                  "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                    stroke: "none",
                  },
                  //graph both dashed line color.
                  "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                    stroke: "#E5E5EF",
                    shapeRendering: "crispEdges",
                    strokeWidth: 1,
                  },
                  //vertical line color and width.
                  "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                    stroke: "#E5E5EF",
                    shapeRendering: "crispEdges",
                    strokeWidth: 1.5,
                    strokeDasharray: "8 4", // This makes the line dashed
                  },
                  //bottom and side axis line color change.
                  "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                    stroke: "#E5E5EF",
                    shapeRendering: "crispEdges",
                    strokeWidth: 0,
                  },
                  //graph line color.
                  [`& .${lineElementClasses.root}`]: {
                    stroke: "#6585F7",
                    strokeWidth: 2,
                  },
                }}
                yAxis={[
                  {
                    scaleType: "band",
                    data: itemNameVolumnBarChart,
                    categoryGapRatio: 0.69,
                    // barGapRatio: 0.1,
                    tickPlacement: "middle",
                    colorMap: {
                      type: "ordinal",
                      colors: [
                        "#6D3AFF",
                        "#E642F4",
                        "#FD9393",
                        "#FFDB80",
                        "#F6D1A6",
                      ],
                    },
                    tickLabelStyle: {
                      textAnchor: "start",
                      dominantBaseline: "central",
                      // transform: " translateX(9px) translateY(-20px)",
                      transform:
                        itemNameVolumnBarChart.length == 1
                          ? " translateX(9px) translateY(-15%)"
                          : itemNameVolumnBarChart.length == 2
                          ? " translateX(9px) translateY(-11%)"
                          : itemNameVolumnBarChart.length == 3
                          ? " translateX(9px) translateY(-8%)"
                          : itemNameVolumnBarChart.length == 4
                          ? "translateX(9px) translateY(-5%)"
                          : "translateX(9px) translateY(-20px)", // 2 band = 70px // 1 band= 32%
                    },
                  },
                ]}
                slotProps={{
                  bar: {
                    clipPath: `inset(0px round 0px 4px 4px 0px)`,
                  },
                  noDataOverlay: { message: "" },
                }}
                xAxis={[
                  {
                    min: 0,
                    max:
                      ordersAvailable == 1
                        ? allZeroItemVolumnBarChartData
                          ? 10
                          : null
                        : 10,
                  },
                ]}
                series={[
                  {
                    data: itemVolumnBarChartData,
                    valueFormatter: (value) =>
                      value > 0.0
                        ? `${Number(
                            parseFloat(value).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : "0.00",
                  },
                ]}
                height={315}
                layout="horizontal"
              />
            )}
          </Box>
        </Box>
      </Flex>
      {/* Loyalty Program and Online Gift Card */}
      <Flex
        gap={{
          base: "0rem",
          sm: "0rem",
          md: "0rem",
          lg: "1.2rem",
          xl: "1.2rem",
        }}
        // flexDirection={!isMobile ? "row" : "column"}
        flexDir={{
          base: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
      >
        <Box
          flex={1}
          flexShrink={"0"}
          // bg={Colors.posPageDataBackground}
          bg={"var(--Neutral-Colors-White, #FFF)"}
          borderRadius={"0.625rem"}
          // mb={2.5}
          mb={{ base: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "2rem" }}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            // pl={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            // pt={{
            //   base: "1rem",
            //   sm: "3.30rem",
            //   md: "3.30rem",
            //   lg: "3.30rem",
            //   xl: "3.30rem",
            // }}
            // pr={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            pb={{
              base: "1rem",
              sm: "2rem",
              md: "2rem",
              lg: "2rem",
              xl: "2rem",
            }}
            gap={"0rem"}
          >
            <Flex
              direction="row"
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              // flexWrap={"wrap"}
              pl={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pt={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              }}
              pr={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              // pb={{
              //   base: "1rem",
              //   sm: "3.30rem",
              //   md: "3.30rem",
              //   lg: "3.30rem",
              //   xl: "3.30rem",
              // }}
            >
              <Box>
                <Text
                  fontSize={"2xl"}
                  color={"#615E83"}
                  fontWeight={"semibold"}
                >
                  Loyalty Program
                </Text>
                {barRewardLoyaltyChartLoading ? (
                  <Box color={"#00126833"} margin={30}>
                    <Stack>
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Text
                      // paddingTop={"1.38rem"}
                      fontSize={"0.94rem"}
                      color={"#9291A5"}
                      fontWeight={"light"}
                    >
                      {rewardCustomerReportType}
                    </Text>
                    <Text
                      pt={3}
                      fontSize={"1.5rem"}
                      color={"#1E1B39"}
                      fontWeight={"semibold"}
                    >
                      {/* {finalDayRewardLotyaltyTotal} */}
                      {finalDayRewardLotyaltyTotal > 0
                        ? Number(finalDayRewardLotyaltyTotal).toLocaleString(
                            "en"
                          )
                        : "0"}
                    </Text>
                    {lastPeriodTotalRewardCustomerColor == "green" ? (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          <Tag
                            // size="lg"
                            bg="#E9FFE8"
                            borderRadius="full"
                            w={"fit-content"}
                            // ml={1.5}
                          >
                            <TrendingUpIcon style={{ color: "#28B625" }} />
                            <TagLabel
                              color={"#28B625"}
                              fontSize={"0.93rem"}
                              fontWeight={"light"}
                            >
                              {lastPeriodTotalRewardCustomerPercentage}
                            </TagLabel>
                          </Tag>
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalRewardCustomer}
                            </span>
                          </span>
                        </Text>
                      </>
                    ) : lastPeriodTotalRewardCustomerColor == "red" ? (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          <Tag
                            // size="lg"
                            bg="#FFE2E2"
                            borderRadius="full"
                            w={"fit-content"}
                            // ml={1.5}
                          >
                            <TrendingDownIcon style={{ color: "#B62525" }} />
                            <TagLabel
                              color={"#B62525"}
                              fontSize={"0.93rem"}
                              fontWeight={"light"}
                            >
                              {lastPeriodTotalRewardCustomerPercentage}
                            </TagLabel>
                          </Tag>
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalRewardCustomer}
                            </span>
                          </span>
                        </Text>
                      </>
                    ) : lastPeriodTotalRewardCustomerColor == "yellow" ? (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          <Tag
                            // size="lg"
                            bg="#feebc8"
                            borderRadius="full"
                            w={"fit-content"}
                            // ml={1.5}
                          >
                            <TrendingFlatIcon style={{ color: "#F59638" }} />
                            <TagLabel
                              color={"#F59638"}
                              fontSize={"0.93rem"}
                              fontWeight={"light"}
                            >
                              {lastPeriodTotalRewardCustomerPercentage}
                            </TagLabel>
                          </Tag>
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalRewardCustomer}
                            </span>
                          </span>
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          {lastPeriodTotalRewardCustomerPercentage}
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalRewardCustomer}
                            </span>
                          </span>
                        </Text>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Flex>

            <Box pt={"1.66rem"} ml={"2rem"} mr={"2rem"}>
              <Divider />
            </Box>
            <Box
              align={"stretch"}
              pl={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              pt={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              pr={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              pb={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              gap={"0rem"}
            >
              {barRewardLoyaltyChartLoading ? (
                <Box color={"#00126833"} margin={30}>
                  <Stack>
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="180" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="280" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="180" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="150" />
                  </Stack>
                </Box>
              ) : (
                <BarChart
                  grid={{ horizontal: true }}
                  // sx={{
                  //   [`& .${chartsGridClasses.line}`]: {
                  //     strokeDasharray: "8 4",
                  //     strokeWidth: 1,
                  //   },
                  // }}
                  sx={{
                    //bottom label color.
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      fill: "#615E83",
                      fontSize: "12px",
                      // lineHeight: "20px",
                      fontWeight: 500,
                    },
                    //side label color.
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      fill: "#615E83",
                      fontSize: "12px",
                      // lineHeight: "20px",
                      fontWeight: 500,
                    },
                    [`& .${chartsGridClasses.line}`]: {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1.5,
                      strokeDasharray: "8 4", // This makes the line dashed
                    },
                    //both side label stroke black color remove.
                    "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                      stroke: "none",
                    },
                    // //graph both dashed line color.
                    // "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                    //   stroke: "#E5E5EF",
                    //   shapeRendering: "crispEdges",
                    //   strokeWidth: 1
                    // },
                    // //vertical line color and width.
                    // "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                    //   stroke: "#E5E5EF",
                    //   shapeRendering: "crispEdges",
                    //   strokeWidth: 1.5,
                    //   strokeDasharray: "8 4", // This makes the line dashed
                    // },
                    //bottom and side axis line color change.
                    "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                      stroke: "#9291A5",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1,
                    },
                    // //graph line color.
                    // [`& .${lineElementClasses.root}`]: {
                    //   stroke: "#6585F7",
                    //   strokeWidth: 2,
                    // }
                  }}
                  height={305}
                  series={[
                    {
                      data: rewardLotyaltyChartData,
                      label: "Reward Customers",
                      id: "pvId",
                      color: "#4A3AFF",
                    },
                  ]}
                  xAxis={[
                    {
                      data: dayRewardLotyalty,
                      scaleType: "band",
                      categoryGapRatio: 0.58,
                      // barGapRatio: 0.448,
                      tickPlacement: "middle",
                      tickLabelStyle: {
                        angle: -32.0,
                        dominantBaseline: "hanging",
                        textAnchor: "end",
                      },
                      valueFormatter: (dayRewardLotyalty, context) => {
                        if (context.location === "tick") {
                          let comma = true;
                          let parts = dayRewardLotyalty.split(", ");
                          if (parts.length === 1) {
                            comma = false;
                            parts = dayRewardLotyalty.split(" - ");
                          }
                          const dayOfWeek = comma
                            ? parts[0] + ","
                            : parts.length == 2
                            ? parts[0] + " - "
                            : parts[0];
                          const date = parts[1] ? parts[1] : "";
                          return `${dayOfWeek}\n${date}`;
                        }
                        return `${dayRewardLotyalty}`;
                      },
                    },
                  ]}
                  yAxis={[
                    {
                      min: 0, // Set your desired minimum value
                      max:
                        ordersAvailable == 1
                          ? allZeroRewardLotyaltyChartData
                            ? 10
                            : null
                          : 10, // Set your desired maximum value
                      valueFormatter: (value) =>
                        `${value.toLocaleString("en")}`,
                    },
                  ]}
                  slotProps={{
                    legend: { hidden: true },
                    bar: {
                      clipPath: `inset(0px round 11px 11px 0px 0px)`,
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: displayOnlineGiftCardSalesLineChart == 0 ? "none" : "",
          }}
          flex={1}
          flexShrink={"0"}
          // bg={Colors.posPageDataBackground}
          bg={"var(--Neutral-Colors-White, #FFF)"}
          borderRadius={"0.625rem"}
          mb={{ base: "1rem", sm: "1rem", md: "1rem", lg: "1rem", xl: "2rem" }}
          boxShadow={"0px 2px 6px 0px rgba(13, 10, 44, 0.08)"}
        >
          <Box
            align={"stretch"}
            // pl={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            // pt={{
            //   base: "1rem",
            //   sm: "3.30rem",
            //   md: "3.30rem",
            //   lg: "3.30rem",
            //   xl: "3.30rem",
            // }}
            // pr={{
            //   base: "1rem",
            //   sm: "2rem",
            //   md: "2rem",
            //   lg: "1rem",
            //   xl: "2rem",
            // }}
            pb={{
              base: "1rem",
              sm: "2rem",
              md: "2rem",
              lg: "2rem",
              xl: "2rem",
            }}
            gap={"0rem"}
          >
            <Flex
              direction="row"
              alignItems={{ base: "flex-start", md: "center" }}
              justify="space-between"
              // flexWrap={"wrap"}
              pl={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              pt={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "2rem",
                xl: "2rem",
              }}
              pr={{
                base: "1rem",
                sm: "2rem",
                md: "2rem",
                lg: "1rem",
                xl: "2rem",
              }}
              // pb={{
              //   base: "1rem",
              //   sm: "3.30rem",
              //   md: "3.30rem",
              //   lg: "3.30rem",
              //   xl: "3.30rem",
              // }}
            >
              <Box>
                <Text
                  fontSize={"2xl"}
                  color={"#615E83"}
                  fontWeight={"semibold"}
                >
                  Online Gift Card
                </Text>
                {barOnlineGiftCardChartLoading ? (
                  <Box color={"#00126833"} margin={30}>
                    <Stack>
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="120" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="180" />
                      <Skeleton height="10px" width="200" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="150" />
                      <Skeleton height="10px" width="200" />
                    </Stack>
                  </Box>
                ) : (
                  <>
                    <Text
                      // paddingTop={"1.38rem"}
                      fontSize={"0.94rem"}
                      color={"#9291A5"}
                      fontWeight={"light"}
                    >
                      {onlineGiftCardSalesReportType}
                    </Text>
                    <Text
                      pt={3}
                      fontSize={"1.5rem"}
                      color={"#1E1B39"}
                      fontWeight={"semibold"}
                    >
                      {/* ${finalOnlineGiftCardSalesTotalPayment} */}$
                      {parseFloat(finalOnlineGiftCardSalesTotalPayment).toFixed(
                        2
                      ) > 0.0
                        ? Number(
                            parseFloat(
                              finalOnlineGiftCardSalesTotalPayment
                            ).toFixed(2)
                          ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "0.00"}
                    </Text>
                    {lastPeriodTotalGifyCardSalesColor == "green" ? (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          <Tag
                            // size="lg"
                            bg="#E9FFE8"
                            borderRadius="full"
                            w={"fit-content"}
                            // ml={1.5}
                          >
                            <TrendingUpIcon style={{ color: "#28B625" }} />
                            <TagLabel
                              color={"#28B625"}
                              fontSize={"0.93rem"}
                              fontWeight={"light"}
                            >
                              {lastPeriodTotalGiftCardSalesPercentage}
                            </TagLabel>
                          </Tag>
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalGiftCardSalesAmount}
                            </span>
                          </span>
                        </Text>
                      </>
                    ) : lastPeriodTotalGifyCardSalesColor == "red" ? (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          <Tag
                            // size="lg"
                            bg="#FFE2E2"
                            borderRadius="full"
                            w={"fit-content"}
                            // ml={1.5}
                          >
                            <TrendingDownIcon style={{ color: "#B62525" }} />
                            <TagLabel
                              color={"#B62525"}
                              fontSize={"0.93rem"}
                              fontWeight={"light"}
                            >
                              {lastPeriodTotalGiftCardSalesPercentage}
                            </TagLabel>
                          </Tag>
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalGiftCardSalesAmount}
                            </span>
                          </span>
                        </Text>
                      </>
                    ) : lastPeriodTotalGifyCardSalesColor == "yellow" ? (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          <Tag
                            // size="lg"
                            bg="#feebc8"
                            borderRadius="full"
                            w={"fit-content"}
                            // ml={1.5}
                          >
                            <TrendingFlatIcon style={{ color: "#F59638" }} />
                            <TagLabel
                              color={"#F59638"}
                              fontSize={"0.93rem"}
                              fontWeight={"light"}
                            >
                              {lastPeriodTotalGiftCardSalesPercentage}
                            </TagLabel>
                          </Tag>
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalGiftCardSalesAmount}
                            </span>
                          </span>
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text
                          fontSize={"0.93rem"}
                          color={"rgba(1, 0, 72, 0.70)"}
                          fontWeight={"light"}
                          display={"flex"}
                          flexWrap={"wrap"}
                          alignItems={"center"}
                        >
                          {lastPeriodTotalGiftCardSalesPercentage}
                          <span>
                            &nbsp;vs Last Period{" "}
                            <span style={{ fontWeight: "500" }}>
                              {lastPeriodTotalGiftCardSalesAmount}
                            </span>
                          </span>
                        </Text>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Flex>
            <Box pt={"1.66rem"} ml={"2rem"} mr={"2rem"}>
              <Divider />
            </Box>
            <Box
              align={"stretch"}
              pl={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              pt={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              pr={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              pb={{
                base: "0rem",
                sm: "0rem",
                md: "0.50rem",
                lg: "0.50rem",
                xl: "0.50rem",
              }}
              gap={"0rem"}
            >
              {barOnlineGiftCardChartLoading ? (
                <Box color={"#00126833"} margin={30}>
                  <Stack>
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="180" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="280" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="120" />
                    <Skeleton height="10px" width="180" />
                    <Skeleton height="10px" width="200" />
                    <Skeleton height="10px" width="150" />
                    <Skeleton height="10px" width="150" />
                  </Stack>
                </Box>
              ) : (
                <BarChart
                  grid={{ horizontal: true }}
                  sx={{
                    //bottom label color.
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      fill: "#615E83",
                      fontSize: "12px",
                      // lineHeight: "20px",
                      fontWeight: 500,
                    },
                    //side label color.
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      fill: "#615E83",
                      fontSize: "12px",
                      // lineHeight: "20px",
                      fontWeight: 500,
                    },
                    [`& .${chartsGridClasses.line}`]: {
                      stroke: "#E5E5EF",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1.5,
                      strokeDasharray: "8 4", // This makes the line dashed
                    },
                    //both side label stroke black color remove.
                    "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                      stroke: "none",
                    },
                    // //graph both dashed line color.
                    // "& .MuiChartsGrid-root .MuiChartsGrid-line": {
                    //   stroke: "#E5E5EF",
                    //   shapeRendering: "crispEdges",
                    //   strokeWidth: 1
                    // },
                    // //vertical line color and width.
                    // "& .MuiChartsGrid-line.MuiChartsGrid-verticalLine": {
                    //   stroke: "#E5E5EF",
                    //   shapeRendering: "crispEdges",
                    //   strokeWidth: 1.5,
                    //   strokeDasharray: "8 4", // This makes the line dashed
                    // },
                    //bottom and side axis line color change.
                    "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                      stroke: "#9291A5",
                      shapeRendering: "crispEdges",
                      strokeWidth: 1,
                    },
                    // //graph line color.
                    // [`& .${lineElementClasses.root}`]: {
                    //   stroke: "#6585F7",
                    //   strokeWidth: 2,
                    // }
                  }}
                  height={305}
                  series={[
                    {
                      data: onlineGiftCardChartData,
                      label: "Gift Card Sales",
                      id: "pvId",
                      color: "#4A3AFF",
                      valueFormatter: (value) =>
                        // `$${parseFloat(value).toFixed(2)}`,
                        value > 0.0
                          ? `$${Number(
                              parseFloat(value).toFixed(2)
                            ).toLocaleString("en", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                          : "$0.00",
                    },
                  ]}
                  xAxis={[
                    {
                      data: dayOnlineGiftCard,
                      scaleType: "band",
                      categoryGapRatio: 0.58,
                      // barGapRatio: 0.448,
                      tickPlacement: "middle",
                      tickLabelStyle: {
                        angle: -32.0,
                        dominantBaseline: "hanging",
                        textAnchor: "end",
                      },
                      valueFormatter: (dayOnlineGiftCard, context) => {
                        if (context.location === "tick") {
                          let comma = true;
                          let parts = dayOnlineGiftCard.split(", ");
                          if (parts.length === 1) {
                            comma = false;
                            parts = dayOnlineGiftCard.split(" - ");
                          }
                          const dayOfWeek = comma
                            ? parts[0] + ","
                            : parts.length == 2
                            ? parts[0] + " - "
                            : parts[0];
                          const date = parts[1] ? parts[1] : "";
                          return `${dayOfWeek}\n${date}`;
                        }
                        return `${dayOnlineGiftCard}`;
                      },
                    },
                  ]}
                  yAxis={[
                    {
                      min: 0, // Set your desired minimum value
                      max:
                        ordersAvailable == 1
                          ? allZeroOnlineGiftCardChartData
                            ? 10
                            : null
                          : 10, // Set your desired maximum value
                      valueFormatter: (value) =>
                        `$${value.toLocaleString("en")}`,
                    },
                  ]}
                  slotProps={{
                    legend: { hidden: true },
                    bar: {
                      clipPath: `inset(0px round 11px 11px 0px 0px)`,
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default WithRouter(DashBoard);
