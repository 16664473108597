import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import Colors from "../../assets/colors/Colors";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const customVariant = definePartsStyle({
  container: {
    borderRadius: "0.625rem",
    // Let's also provide dark mode alternatives
  },
  title: {
    fontSize: "sm",
    fontWeight: 500,
    color: Colors.mainHeader,
    lineHeight: "1.25rem",
  },
  description: {
    fontSize: "sm",
    fontWeight: 400,
    color: Colors.formLable,
    lineHeight: "1.25rem",
  },
});

export const alertTheme = defineMultiStyleConfig({
  variants: { sanposAlert: customVariant },
});
