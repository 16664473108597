import { Tab, TabList, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";

const PosTab = (props) => {
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");
  const [isScreenSize918] = useMediaQuery("(max-width: 918px)");
  const { tabNames } = props;
  const tabStyle = {
    fontWeight: props.isdrawer ? "300" : "500",
    fontSize: "0.94rem",
    lineHeight: "1.17rem",
    letterSpacing: "-1.5%",
    color: Colors.posTextInfo,
    width: "10rem",
  };

  return (
    <TabList
      sx={{
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
      overflowX={isScreenSize480 && tabNames.length > 2  || (isScreenSize918 && tabNames.length === 5 )? "scroll" : "none"}
    >
      {tabNames.map((name, index) => (
        <Tab key={index} style={tabStyle} flexShrink={0}>
          {name}
        </Tab>
      ))}
    </TabList>
  );
};

export default PosTab;
