import { Text } from "@chakra-ui/react";
import React from "react";
import Colors from "../../assets/colors/Colors";

const PosLable = (props) => {
  if (props.requiredLabel) {
    return (
      <Text
        // mt="2rem"
        mt={undefined !== props.marginTop ? props.marginTop : "2rem"}
        fontSize={"0.94rem"}
        fontWeight={undefined !== props.fontWeight ? props.fontWeight : 300}
      >
        {props.name}
        <Text as="span" color={Colors.errorColor}>
          *
        </Text>
      </Text>
    );
  } else if (props.label) {
    return (
      <Text
        color={props.color}
        fontSize={"0.94rem"}
        fontWeight={undefined !== props.fontWeight ? props.fontWeight : 300}
        // mt="2rem"
        mt={undefined !== props.marginTop ? props.marginTop : "2rem"}
      >
        {props.name}
      </Text>
    );
  }
};

export default PosLable;
