import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
// import { IconButton } from '@chakra-ui/react'
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useId, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  PosDialogAlert,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { SimpleReportLayout } from "../../../../../layouts/index";
import { WithRouter } from "../../../../../navigators/WithRouter";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { InputMask } from "primereact/inputmask";
import { DeleteIcon, SmallCloseIcon, CheckIcon } from "@chakra-ui/icons";
import { updateEmployeesWorkTime } from "./EmployeesWorkTimeService";
const EmployeesWorkTime = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [viewData, setViewData] = useState([]);
  const [initialViewData, setInitialViewData] = useState([]); // For storing the original values
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [zone, setZone] = useState("");
  const [emp, setEmp] = useState("");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [deletedRowData, setDeletedRowData] = useState([]);
  const [editWorkTimePermission, setEditWorkTimePermission] = useState(false);
  const [editingRowId, setEditingRowId] = useState(null);
  const [editClick, setEditClick] = useState(false);
  const [getLatestData, setGetLatestData] = useState(false);
  const cancelRef = React.useRef();
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmDialogOpen,
    onOpen: onConfirmDialogOpen,
    onClose: onConfirmDialogClose,
  } = useDisclosure();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [addButtonDisable, setAddButtonDisable] = useState(false);
  const [allRowDisable, setAllRowDisable] = useState(false);
  const [updateTimeButtonDisable, setUpdateTimeButtonDisable] = useState(true);
  const [updateNewRowTimeButtonDisable, setUpdateNewRowTimeButtonDisable] =
    useState(false);
  const [userId, setUserId] = useState(null);
  const [workShiftDate, setWorkShiftDate] = useState(null);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const columnNames = [
    { field: "id", headerName: "#", sortable: false, width: 90 },
    {
      field: "date",
      headerName: "Date",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "totalcount",
      headerName: "Total Hours [H:M:S]",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "order",
      headerName: editWorkTimePermission ? "Edit Details" : "View Details",
      sortable: false,
      filterable: false,
      resizable: false,
      ...actionColumnFlexDouble,
      disableExport: true,
      renderCell: (params) => (
        <>
          {editWorkTimePermission &&
          (params.row.time === undefined || params.row.time.length === 0) ? (
            <IconButton
              style={{
                backgroundColor: "#F4F4F4",
                color: "#010048",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onClick={() => {
                handleViewDetailsClick(params.row);
              }}
            >
              <ModeEditIcon />
            </IconButton>
          ) : params.row.time !== undefined && params.row.time.length > 0 ? (
            <IconButton
              style={{
                backgroundColor: "#F4F4F4",
                color: "#010048",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onClick={() => {
                handleViewDetailsClick(params.row);
              }}
            >
              {editWorkTimePermission ? <ModeEditIcon /> : <RemoveRedEyeIcon />}
            </IconButton>
          ) : (
            <span>N.A.</span>
          )}
        </>
      ),
    },
  ];
  const handleViewDetailsClick = (data) => {
    if (data.time.length === 0) {
      const rowData = {
        count: 1,
        clock_in: "",
        clock_out: "",
        diff: "",
        newWorkShiftEntry: true,
        location_id: selectedLocation.id,
        user_id: data.user_id,
        date: data.date,
      };
      const staticRow = {
        count: "Total Duration",
        clock_in: "--",
        clock_out: "--",
        diff: data.totalcount,
      };
      const modifiedRowData = [rowData, staticRow];
      setViewData(modifiedRowData);
      setInitialViewData(modifiedRowData);
      setEmp(`${data.name}'s work report on ${data.date}`);
      setWorkShiftDate(data.date);
      onOpen();
      setAddButtonDisable(true);
    } else {
      const rowData = data.time.map((entry, index) => ({
        count: index + 1,
        // clock_in: timeStringToDate(entry.clock_in_time),
        clock_in: entry.clock_in_time,
        // clock_out: timeStringToDate(entry.clock_out_time) || "N.A",
        clock_out: entry.clock_out_time || "N.A",
        diff: entry.diff || "N.A",
        workedShiftId: entry.id,
        location_id: entry.location_id,
        user_id: entry.user_id,
      }));
      const staticRow = {
        count: "Total Duration",
        clock_in: "--",
        clock_out: "--",
        diff: data.totalcount,
      };
      const hasMissingClockOutTime = data.time.some(
        (entry) => entry.clock_out_time === null || entry.clock_out_time === ""
      );
      setAddButtonDisable(hasMissingClockOutTime);
      setUserId(data.time[0].user_id);
      setWorkShiftDate(data.date);
      const modifiedRowData = [...rowData, staticRow];
      setViewData(modifiedRowData);
      setInitialViewData(modifiedRowData);
      setEmp(`${data.name}'s work report on ${data.date}`);
      onOpen();
    }
  };
  const viewColumnNames = [
    {
      columnNames: "#",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Clock-In",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Clock-Out",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Duration",
      sortName: "",
      width: "",
    },
    ...(editWorkTimePermission
      ? [
          {
            columnNames: "Action",
            sortName: "",
            width: "",
          },
        ]
      : []),
  ];

  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    getEmployeesWorkTimeData();
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Employee Work Time Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.EMP_WORK_TIME_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setEditWorkTimePermission(
            actionResponse.data.data.editWorkTimePermission
          );
          setZone(actionResponse.data.data.zone);
          setGetLatestData(false);
          setLoading(false);
          setButtonDisable(false);
          setTotalCount(1);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          const formattedLocation = {
            label: selectedLocation.name,
            value: { id: selectedLocation.id },
          };
          myContext.handleLoading(true);
          let data = {
            getreport: false,
            location: JSON.stringify(formattedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            type: "xls",
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.EMPLOYEES_WORK_TIME_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getEmployeesWorkTimeData = () => {
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          const formattedLocation = {
            label: selectedLocation.name,
            value: { id: selectedLocation.id },
          };

          let data = {
            getreport: true,
            location: JSON.stringify(formattedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            //   query: JSON.stringify({
            //     limit: paginationModel.pageSize,
            //     page: paginationModel.page + 1,
            //     order:
            //       sortModel.length > 0
            //         ? sortModel[0].field
            //         : sortRef.current[0].field,
            //     order_type:
            //       sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
            //   }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.EMPLOYEES_WORK_TIME_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      //   formik.setFieldValue("selectedLoactions", e.value);
    }
  };
  const handleResetAllData = () => {
    setSelectedLocation([]);
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card flex justify-content-center">
          <PosDropDown
            id="selectedLoactions"
            options={locations}
            value={selectedLocation}
            resetFilterOnHide={true}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            // multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            // maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  const [columnVisibilityModels, setColumnVisibilityModels] = useState({});
  const handleColumnVisibilityModelChange = (key, newModel) => {
    setColumnVisibilityModels((prevModels) => ({
      ...prevModels,
      [key]: newModel,
    }));
  };

  const modifiedData = Object.keys(tableData).flatMap((key) =>
    tableData[key].map((item, i) => ({
      name: key,
      id: item.date === undefined ? "" : i + 1,
      date: item.date === undefined ? "Total" : item.date,
      totalcount:
        item.totalcount === undefined
          ? `${item.total} (${item.totalWorkedHours} Hours)`
          : item.totalcount === "23:59:59"
          ? "24:00:00 (24 Hours)"
          : `${item.totalcount} (${item.lineWiseWorkedHours} Hours)`,
      time: item.time,
      user_id: item.user_id,
    }))
  );
  // This method Will calculate Difference of time
  const calculateDiff = (clockIn, clockOut) => {
    const start = new Date(`1970-01-01T${clockIn}`);
    const end = new Date(`1970-01-01T${clockOut}`);
    let diff = (end - start) / 1000;
    if (diff < 0) {
      diff += 24 * 3600;
    }

    const hours = Math.floor(diff / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((diff % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (diff % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleClockInChange = (e, shiftId) => {
    const newClockIn = e.target.value;

    setViewData((prevData) => {
      return prevData.map((row, index) => {
        if (row.workedShiftId === shiftId) {
          const newDiff = calculateDiff(newClockIn, row.clock_out);
          const initialRow = initialViewData.find(
            (initRow) => initRow.workedShiftId === shiftId
          );
          const isClockInSame = initialRow.clock_in === newClockIn;
          const isClockOutSame = initialRow.clock_out === row.clock_out;

          if (isClockInSame && isClockOutSame) {
            setUpdateTimeButtonDisable(true);
          } else {
            setUpdateTimeButtonDisable(false);
          }
          return { ...row, clock_in: newClockIn, diff: newDiff };
        }
        return row;
      });
    });
  };

  const handleClockOutChange = (e, shiftId) => {
    const newClockOut = e.target.value;

    setViewData((prevData) => {
      return prevData.map((row, index) => {
        if (row.workedShiftId === shiftId) {
          const newDiff = calculateDiff(row.clock_in, newClockOut);
          const initialRow = initialViewData.find(
            (initRow) => initRow.workedShiftId === shiftId
          );
          const isClockInSame = initialRow.clock_in === row.clock_in;
          const isClockOutSame = initialRow.clock_out === newClockOut;

          if (isClockInSame && isClockOutSame) {
            setUpdateTimeButtonDisable(true);
          } else {
            setUpdateTimeButtonDisable(false);
          }
          return { ...row, clock_out: newClockOut, diff: newDiff };
        }
        return row;
      });
    });
  };
  const handleDeleteDialogOpen = (rowData) => {
    const updatedRowData = { ...rowData, deleteShiftData: true };
    setDeletedRowData(updatedRowData);
    onConfirmDialogOpen();
  };

  const onWorkTimeChange = (payload) => {
    const formattedViewData = viewData.slice(0, -1);
    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    if (!timeFormatRegex.test(payload.clock_in)) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: "Please enter valid clock-in time.",
      });
      return;
    }
    if (!timeFormatRegex.test(payload.clock_out)) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: "Please enter valid clock-out time.",
      });
      return;
    }

    for (let i = 0; i < formattedViewData.length; i++) {
      const entry1 = formattedViewData[i];
      const clockInTime1 = new Date(`1970-01-01T${entry1.clock_in}Z`);
      const clockOutTime1 = new Date(`1970-01-01T${entry1.clock_out}Z`);
      // Compare entry1 with every other entry
      for (let j = i + 1; j < formattedViewData.length; j++) {
        const entry2 = formattedViewData[j];
        const clockInTime2 = new Date(`1970-01-01T${entry2.clock_in}Z`);
        const clockOutTime2 = new Date(`1970-01-01T${entry2.clock_out}Z`);
        // Check for overlap between entry1 and entry2
        const overlap =
          (clockInTime1 <= clockInTime2 && clockOutTime1 >= clockInTime2) ||
          (clockInTime2 <= clockInTime1 && clockOutTime2 >= clockInTime1) ||
          (clockInTime1 <= clockInTime2 && clockOutTime1 >= clockOutTime2) ||
          (clockInTime2 <= clockInTime1 && clockOutTime2 >= clockOutTime1);

        if (overlap) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: "Please enter valid time slot",
          });
          return; // Stop further execution if overlap is found
        }
      }
    }
    const clockInTime = new Date(`1970-01-01T${payload.clock_in}Z`);
    const clockOutTime = new Date(`1970-01-01T${payload.clock_out}Z`);
    const timeDifferenceInMs = clockOutTime - clockInTime;
    // Time difference in minutes

    if (timeDifferenceInMs <= 0) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: "Clock-out time must be after clock-in time.",
      });
      return;
    }
    const totalSeconds = Math.floor(timeDifferenceInMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedDuration = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    // Find and update the corresponding row in viewData

    // Update the state with the updated viewData

    try {
      updateEmployeesWorkTime([payload])
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.REPORT_MODULE,
              alertDescription: response.data.message,
            });
            // onClose();
            setEditClick(true);
            setEditingRowId(null);
            // getEmployeesWorkTimeData();
            setAllRowDisable(false);
            setGetLatestData(true);
            setAddButtonDisable(false);
            setUpdateTimeButtonDisable(true);
            myContext.handleLoading(false);
            if (
              response.data.data.createdWorkShiftData !== undefined &&
              response.data.data.createdWorkShiftData !== null &&
              response.data.data.createdWorkShiftData !== ""
            ) {
              const updatedViewData = viewData.map((row) => {
                if (row.count === payload.count) {
                  // Create a copy of the matched row
                  const updatedRow = {
                    ...row,
                    diff: formattedDuration,
                    workedShiftId: response.data.data.createdWorkShiftData.id,
                  };
                  // Remove the keys `newWorkShiftEntry` and `date`
                  delete updatedRow.newWorkShiftEntry;
                  delete updatedRow.date;
                  return updatedRow;
                }
                return row;
              });
              setViewData(updatedViewData);
              calculateAndUpdateTotalDuration(updatedViewData);
              const updateInitialViewData = {
                clock_in: payload.clock_in,
                clock_out: payload.clock_out,
                count: payload.count,
                diff: formattedDuration,
                location_id: payload.location_id,
                workedShiftId: response.data.data.createdWorkShiftData.id,
                user_id: response.data.data.createdWorkShiftData.user_id,
              };
              const updatedInitialViewData = [
                ...initialViewData.slice(0, -1),
                updateInitialViewData,
                initialViewData[initialViewData.length - 1],
              ];
              setInitialViewData(updatedInitialViewData);
            } else {
              const updatedInitialViewData = initialViewData.map((item) => {
                if (item.workedShiftId === payload.workedShiftId) {
                    return {
                        ...item,
                        clock_out: payload.clock_out,  
                        diff: payload.diff             
                    };
                }
                return item; 
            });
            setInitialViewData(updatedInitialViewData);
            calculateAndUpdateTotalDuration(viewData);
            }
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            setUpdateTimeButtonDisable(true);
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          setUpdateTimeButtonDisable(true);
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setUpdateTimeButtonDisable(true);
      myContext.handleLoading(false);
    }
  };
  const onCloseDialog = () => {
    setUpdateTimeButtonDisable(true);
    onClose();
  };
  const handleDelete = () => {
    const updatedViewData = viewData.filter(
      (row) => row.workedShiftId !== deletedRowData.workedShiftId
    );
    // Update the state with the new viewData after removing the row
    setViewData(updatedViewData);
    calculateAndUpdateTotalDuration(updatedViewData);
    try {
      updateEmployeesWorkTime([deletedRowData])
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.REPORT_MODULE,
              alertDescription: response.data.message,
            });
            onConfirmDialogClose();
            // onClose();
            // getEmployeesWorkTimeData();
            setAddButtonDisable(false);
            setGetLatestData(true);
            setUpdateTimeButtonDisable(true);
            myContext.handleLoading(false);
            // calculateAndUpdateTotalDuration();
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            setUpdateTimeButtonDisable(true);
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          setUpdateTimeButtonDisable(true);
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setUpdateTimeButtonDisable(true);
      myContext.handleLoading(false);
    }
  };
  const confirmDialogClose = () => {
    onConfirmDialogClose();
    setDeletedRowData([]);
  };
  const handleEditClick = (rowData) => {
    setEditingRowId(rowData.workedShiftId);
    setEditClick(true);
    setAllRowDisable(true);
    setAddButtonDisable(true);
  };
  const handleSaveClick = (rowData) => {
    onWorkTimeChange(rowData);
  };
  const handleCancelClick = (rowData) => {
    if (
      rowData.newWorkShiftEntry !== undefined &&
      rowData.newWorkShiftEntry === true
    ) {
      setViewData((prevData) => {
        // Filter out the row with the newWorkShiftEntry set to true
        return prevData.filter((row) => row.count !== rowData.count);
      });
      setAddButtonDisable(false);
      setAllRowDisable(false);
    } else {
      // If it's not a newly added row, restore from initialViewData
      setViewData((prevData) => {
        return prevData.map((row) => {
          if (row.workedShiftId === rowData.workedShiftId) {
            // Find the corresponding row from initialViewData
            const initialRow = initialViewData.find(
              (initialRow) => initialRow.workedShiftId === row.workedShiftId
            );
            return initialRow ? { ...initialRow } : row;
          }
          return row;
        });
      });
      const hasMissingClockOutTime = viewData.some(
        (entry) => entry.clock_out == "N.A"
      );
      setAddButtonDisable(hasMissingClockOutTime);
      setAllRowDisable(false);
    }
    setEditClick(false);
    setEditingRowId(null);
  };
  const handleDialogClose = () => {
    onCloseDialog();
    setEditClick(false);
    setEditingRowId(null);
    if (getLatestData) {
      getEmployeesWorkTimeData();
    }
    setAddButtonDisable(false);
    setAllRowDisable(false);
  };
  const addRowInViewData = () => {
    const lastSecondRowCount =
      viewData.length > 1 ? viewData[viewData.length - 2].count + 1 : 1;

    const addedRowData = {
      count: lastSecondRowCount,
      clock_in: "",
      clock_out: "",
      diff: "",
      newWorkShiftEntry: true,
      location_id: selectedLocation.id,
      user_id: userId,
      date: workShiftDate,
    };
    // setViewData((prevData) => {
    //   if (prevData.length > 1) {
    //     const newData = [...prevData];
    //     newData.splice(prevData.length - 1, 0, addedRowData);
    //     return newData;
    //   } else {
    //     return [...prevData, addedRowData];
    //   }
    // });
    setViewData((prevData) => {
      const newData = [...prevData];

      if (prevData.length > 1) {
        // If more than 1 row, insert before the last row
        newData.splice(prevData.length - 1, 0, addedRowData);
      } else if (prevData.length === 1) {
        // If exactly 1 row, insert the new row at the first position (before the last)
        newData.splice(0, 0, addedRowData);
      } else {
        // If no rows, just append the new row
        newData.push(addedRowData);
      }

      return newData;
    });
    setAddButtonDisable(true);
    setAllRowDisable(true);
  };

  const handleClockInChangeInNewRow = (e, row) => {
    const newClockIn = e.target.value;

    setViewData((prevData) => {
      return prevData.map((currentRow, index) => {
        if (currentRow.count === row.count) {
          setUpdateNewRowTimeButtonDisable(true);
          return { ...currentRow, clock_in: newClockIn };
        }
        return currentRow;
      });
    });
  };

  const handleClockOutChangeInNewRow = (e, row) => {
    const newClockOut = e.target.value;

    setViewData((prevData) => {
      return prevData.map((currentRow) => {
        if (currentRow.count === row.count) {
          setUpdateNewRowTimeButtonDisable(true);
          return { ...currentRow, clock_out: newClockOut };
        }
        return currentRow;
      });
    });
  };

  const calculateAndUpdateTotalDuration = (data) => {
    const dataWithoutTotalRow = data.slice(0, -1);
    let totalSeconds = 0;

    dataWithoutTotalRow.forEach((row) => {
      const [inHours, inMinutes, inSeconds] = row.clock_in
        .split(":")
        .map(Number);
      const [outHours, outMinutes, outSeconds] = row.clock_out
        .split(":")
        .map(Number);

      const clockInSeconds = inHours * 3600 + inMinutes * 60 + inSeconds;
      const clockOutSeconds = outHours * 3600 + outMinutes * 60 + outSeconds;

      const rowDuration = clockOutSeconds - clockInSeconds;
      if (rowDuration > 0) {
        totalSeconds += rowDuration;
      }
    });

    const totalHours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, "0");
    totalSeconds %= 3600;
    const totalMinutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, "0");
    const totalFinalSeconds = (totalSeconds % 60).toString().padStart(2, "0");

    const totalDecimalHours = (
      parseInt(totalHours) +
      totalMinutes / 60
    ).toFixed(1);

    const formattedTotalDuration = `${totalHours}:${totalMinutes}:${totalFinalSeconds} (${totalDecimalHours} Hours)`;

    const updatedViewData = [
      ...dataWithoutTotalRow,
      { ...viewData[viewData.length - 1], diff: formattedTotalDuration },
    ];

    setViewData(updatedViewData);
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <PosDialogAlert
        leastDestructiveRef={cancelRef}
        onClose={confirmDialogClose}
        isOpen={isConfirmDialogOpen}
        title={Constants.EMPLOYEES_WORK_TIME_REPORT_DIALOG_TITLE}
        discription={Constants.EMPLOYEES_WORK_TIME_REPORT_DIALOG_DISC}
        onClick={handleDelete}
        buttonNames={["Cancel", "Delete"]}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Employees Work-time"]}
          breadCrumTitle={"Employees Work-time"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Dialog
        draggable={false}
        blockScroll={true}
        focusOnShow={false}
        visible={isOpen}
        closable={false}
        closeOnEscape={false}
        style={{ width: isMobileDialog ? "80%" : "70%" }}
      >
        <Flex
          gap="2"
          mb={4}
          mt={2}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Flex fontSize="1.5rem" fontWeight={600} color="rgb(1, 0, 72)">
            {emp}
          </Flex>
          <Spacer></Spacer>
          <ButtonGroup>
            {/* <PosFormButton
              onClick={onWorkTimeChange}
              buttonsubmit={"Submit"}
              isDisabled={updateTimeButtonDisable}
            /> */}
            <PosFormButton
              onClick={handleDialogClose}
              buttonText={"Close"}
              CancelButton={true}
            />
          </ButtonGroup>
        </Flex>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: Colors.modifierTable,
                alignItems: "start",
              }}
            >
              {viewData.length > 1 ? (
                <TableRow>
                  {viewColumnNames.map((column, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontWeight: 500,
                        fontSize: "0.94rem",
                        lineHeight: "1.18rem",
                        letterSpacing: "-0.01rem",
                        fontStyle: "normal",
                        textAlign: column.textAlign || "center",
                        maxHeight: "42rem",
                        color: "#010048",
                      }}
                    >
                      <Flex flexDirection={"row"}>{column.columnNames}</Flex>
                    </TableCell>
                  ))}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {viewData.length > 1 ? (
                viewData.map((row) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={row.workedShiftId}
                  >
                    <TableCell
                      align="left"
                      style={{
                        color: "#010048",
                        fontSize: "0.94rem",
                        fontStyle: "normal",
                        fontWeight: 300,
                        lineHeight: "normal",
                        letterSpacing: "-0.01rem",
                      }}
                    >
                      {row.count}
                    </TableCell>
                    {editWorkTimePermission ? (
                      row.newWorkShiftEntry !== undefined &&
                      row.newWorkShiftEntry === true ? (
                        <TableCell
                          align="left"
                          style={{
                            color: "#010048",
                            fontSize: "0.94rem",
                            fontStyle: "normal",
                            fontWeight: 300,
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          <InputMask
                            value={row.clock_in}
                            style={{
                              width: "100px",
                              padding: "8px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              fontSize: "0.94rem",
                              color: "#010048",
                              backgroundColor: "#fff",
                              outline: "none",
                              transition: "border-color 0.3s ease-in-out",
                            }}
                            placeholder="HH:mm:ss"
                            mask="99:99:99"
                            onChange={(e) =>
                              handleClockInChangeInNewRow(e, row)
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          style={{
                            color: "#010048",
                            fontSize: "0.94rem",
                            fontStyle: "normal",
                            fontWeight: 300,
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          {row.clock_in === "--" ? (
                            "--"
                          ) : (
                            <InputMask
                              disabled={editingRowId !== row.workedShiftId}
                              value={row.clock_in}
                              style={{
                                width: "100px",
                                padding: "8px",
                                border:
                                  editingRowId !== row.workedShiftId
                                    ? "none"
                                    : "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "0.94rem",
                                color: "#010048",
                                backgroundColor: "#fff",
                                outline: "none",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              placeholder="HH:mm:ss"
                              mask="99:99:99"
                              onChange={(e) =>
                                handleClockInChange(e, row.workedShiftId)
                              }
                            />
                          )}
                        </TableCell>
                      )
                    ) : (
                      <TableCell
                        align="left"
                        style={{
                          color: "#010048",
                          fontSize: "0.94rem",
                          fontStyle: "normal",
                          fontWeight: 300,
                          lineHeight: "normal",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        {row.clock_in}
                      </TableCell>
                    )}

                    {editWorkTimePermission ? (
                      row.newWorkShiftEntry != undefined &&
                      row.newWorkShiftEntry == true ? (
                        <TableCell
                          align="left"
                          style={{
                            color: "#010048",
                            fontSize: "0.94rem",
                            fontStyle: "normal",
                            fontWeight: 300,
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          <InputMask
                            value={row.clock_out}
                            style={{
                              width: "100px",
                              padding: "8px",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              fontSize: "0.94rem",
                              color: "#010048",
                              backgroundColor: "#fff",
                              outline: "none",
                              transition: "border-color 0.3s ease-in-out",
                            }}
                            placeholder="HH:mm:ss"
                            mask="99:99:99"
                            onChange={(e) =>
                              handleClockOutChangeInNewRow(e, row)
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          style={{
                            color: "#010048",
                            fontSize: "0.94rem",
                            fontStyle: "normal",
                            fontWeight: 300,
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          {row.clock_out === "--" ? (
                            "--"
                          ) : (
                            <InputMask
                              disabled={editingRowId !== row.workedShiftId}
                              value={row.clock_out}
                              style={{
                                width: "100px",
                                padding: "8px",
                                border:
                                  editingRowId !== row.workedShiftId
                                    ? "none"
                                    : "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "0.94rem",
                                color: "#010048",
                                backgroundColor: "#fff",
                                outline: "none",
                                transition: "border-color 0.3s ease-in-out",
                              }}
                              placeholder="HH:mm:ss"
                              mask="99:99:99"
                              onChange={(e) =>
                                handleClockOutChange(e, row.workedShiftId)
                              }
                            />
                          )}
                        </TableCell>
                      )
                    ) : (
                      <TableCell
                        align="left"
                        style={{
                          color: "#010048",
                          fontSize: "0.94rem",
                          fontStyle: "normal",
                          fontWeight: 300,
                          lineHeight: "normal",
                          letterSpacing: "-0.01rem",
                        }}
                      >
                        {row.clock_out}
                      </TableCell>
                    )}

                    <TableCell
                      align="left"
                      style={{
                        color: "#010048",
                        fontSize: "0.94rem",
                        fontStyle: "normal",
                        fontWeight: 300,
                        lineHeight: "normal",
                        letterSpacing: "-0.01rem",
                      }}
                    >
                      {row.diff == "NaN:NaN:NaN"
                        ? ""
                        : row.diff === "23:59:59 (23.98 Hours)"
                        ? "24:00:00 (24 Hours)"
                        : row.diff === "23:59:59"
                        ? "24:00:00"
                        : row.diff}
                    </TableCell>

                    {editWorkTimePermission &&
                      (row.newWorkShiftEntry != undefined &&
                      row.newWorkShiftEntry == true &&
                      row.diff == "" ? (
                        <TableCell
                          align="left"
                          style={{
                            color: "#010048",
                            fontSize: "0.94rem",
                            fontStyle: "normal",
                            fontWeight: 300,
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                        >
                          <Flex gap={1}>
                            <IconButton
                              style={{
                                backgroundColor: "#F4F4F4",
                                color: updateTimeButtonDisable
                                  ? "#A0A0A0"
                                  : "#010048",
                                transition: "background-color 0.3s, color 0.3s",
                              }}
                              // disabled={!updateNewRowTimeButtonDisable}
                              onClick={() => handleSaveClick(row)}
                            >
                              <CheckIcon color={"green.400"} />
                            </IconButton>
                            <IconButton
                              style={{
                                backgroundColor: "#F4F4F4",
                                color: "#010048",
                                transition: "background-color 0.3s, color 0.3s",
                              }}
                              onClick={() => handleCancelClick(row)}
                            >
                              <SmallCloseIcon color={Colors.errorColor} />
                            </IconButton>
                          </Flex>
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          style={{
                            width: "200px",
                          }}
                        >
                          {row.count === "Total Duration" ? (
                            "--"
                          ) : (
                            <Flex gap={1}>
                              {editingRowId !== row.workedShiftId ? (
                                <IconButton
                                  disabled={allRowDisable}
                                  style={{
                                    backgroundColor: "#F4F4F4",
                                    color: allRowDisable
                                      ? "#7A7AB3"
                                      : "#010048",
                                    transition:
                                      "background-color 0.3s, color 0.3s",
                                  }}
                                  onClick={() => handleEditClick(row)}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              ) : (
                                <>
                                  <IconButton
                                    style={{
                                      backgroundColor: "#F4F4F4",
                                      color: updateTimeButtonDisable
                                        ? "#A0A0A0"
                                        : "#010048",
                                      transition:
                                        "background-color 0.3s, color 0.3s",
                                    }}
                                    disabled={updateTimeButtonDisable}
                                    onClick={() => handleSaveClick(row)}
                                  >
                                    <CheckIcon
                                      color={
                                        updateTimeButtonDisable
                                          ? "green.800"
                                          : "green.400"
                                      }
                                    />
                                  </IconButton>
                                  <IconButton
                                    style={{
                                      backgroundColor: "#F4F4F4",
                                      color: "#010048",
                                      transition:
                                        "background-color 0.3s, color 0.3s",
                                    }}
                                    onClick={() => handleCancelClick(row)}
                                  >
                                    <SmallCloseIcon color={Colors.errorColor} />
                                  </IconButton>
                                </>
                              )}
                              <IconButton
                                disabled={allRowDisable}
                                style={{
                                  backgroundColor: "#F4F4F4",
                                  color: "#010048",
                                  transition:
                                    "background-color 0.3s, color 0.3s",
                                }}
                                onClick={() => handleDeleteDialogOpen(row)}
                              >
                                <DeleteIcon
                                  color={
                                    allRowDisable
                                      ? "#FFCCCC"
                                      : Colors.errorColor
                                  }
                                />
                              </IconButton>
                            </Flex>
                          )}
                        </TableCell>
                      ))}
                  </TableRow>
                ))
              ) : (
                // Show a message when viewData length is 1 or less
                <PosNoDataFound title={"There are no Employees Work Time."} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {editWorkTimePermission ? (
          <PosFormButton
            onClick={addRowInViewData}
            buttonsubmit={"Add"}
            isDisabled={addButtonDisable}
          />
        ) : null}
      </Dialog>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getEmployeesWorkTimeData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            Object.keys(tableData).map((key) => {
              let tableDetails = modifiedData.filter(
                (data) => data.name === key && data.date != "Total"
              );
              return (
                <Box
                  mb={{ base: 4, md: 4 }}
                  key={key}
                  borderRadius="0.63rem"
                  bg={Colors.posPageDataBackground}
                  p="2"
                  boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
                >
                  <PosListHeader
                    header={`Employee Name : ${key}`}
                  ></PosListHeader>
                  <Box mt={8} maxHeight="31rem" overflowY="auto">
                    <PosDataGridTable
                      columns={columnNames}
                      rows={tableDetails}
                      columnVisibilityModel={columnVisibilityModels[key] || {}}
                      onColumnVisibilityModelChange={(newModel) =>
                        handleColumnVisibilityModelChange(key, newModel)
                      }
                      hideFooter={true}
                      autoHeight={true}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.EMPLOYEES_WORK_TIME_REPORT_EXPORT_CSV}
                    />
                  </Box>
                  <Box>
                    <PosDataGridTable
                      columns={columnNames.map((column) => ({
                        ...column,
                        renderCell: (params) => (
                          <div style={{ fontWeight: 500 }}>{params.value}</div>
                        ),
                      }))}
                      rows={modifiedData.filter(
                        (data) => data.name === key && data.date == "Total"
                      )}
                      columnHeaderHeight={0}
                      hideFooter={true}
                    />
                  </Box>
                </Box>
              );
            })
          ) : (
            <PosNoDataFound title={"There are no Employees Work Time."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(EmployeesWorkTime);
