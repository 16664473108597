import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import DriveFileRenameOutlineSharpIcon from "@mui/icons-material/DriveFileRenameOutlineSharp";
import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const EmployeesVIew = (props) => {
  const { id } = useParams();
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [clockInFlag, setClockInFlag] = useState(true); // Initialize flag state with null
  const [buttonDisable, setButtonDisable] = useState(false);
  const flagRef = useRef(true);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocation(loaderResponse.data?.data?.locations);
          myContext.handleLoading(false);
          setSelectedRoles(loaderResponse.data.data.role_list);
          setUserData(loaderResponse.data.data.user);
          let flag =
            loaderResponse.data.data.user_clock_in_flag === 1 ? true : false;
          setClockInFlag(flag);
          if (loaderResponse.data.data.user_clock_in_flag === 1) {
            setSelectedLocation([]);
            setButtonDisable(true);
          } else {
            setButtonDisable(false);
          }
          if (
            loaderResponse.data.data.work_shift_data &&
            loaderResponse.data.data.work_shift_data.location_id
          ) {
            const selectedLocationId =
              loaderResponse.data.data.work_shift_data.location_id;
            const foundLocation = loaderResponse.data?.data?.locations.find(
              (loc) => loc.id === selectedLocationId
            );
            if (foundLocation) {
              setSelectedLocation(foundLocation);
              //   formik.setFieldValue("selectedLocation", foundLocation);
            }
          } else {
            setSelectedLocation([]);
          }
        }
        // return () => {
        effectRun.current = false;
        // };
      }
    }
    if (actionRun.current === true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          let actionData = actionResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          actionResponse.data[Constants.FLAGE] !== null &&
          actionResponse.data[Constants.FLAGE] === true
        ) {
          effectRun.current = true;
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.EMPLOYEES_MODULE,
            alertDescription: actionResponse.data.message,
          });
          myContext.handleLoading(false);
          setButtonDisable(false);
          // setLocation([]);
          // setSelectedRoles([]);
          // setUserData([]);
          // setClockInFlag(true);
          // setSelectedLocation([]);
          // effectRun.current(true);
        }
      }
      actionRun.current = false;
    }
  }, [loaderResponse, actionResponse]);

  // const handlesubmitButton = () => {
  //   let data = clockInFlag == 0 ? true : false;
  //   setClockInFlag(data);
  // };
  const formik = useFormik({
    initialValues: {
      selectedLocation: selectedLocation,
    },
    validationSchema: Yup.object({
      //   selectedLocations: Yup.array().required(
      //     Constants.PRODUCT_LOCATION_REQUIRED
      //   ),
    }),
    onSubmit: (values) => {
      formikHandleSubmit(values);
    },
  });

  const formikHandleSubmit = (values) => {
    if (flagRef.current) {
      flagRef.current = false;
      setTimeout(() => {
        flagRef.current = true;
      }, 3000);
      setButtonDisable(true);
      myContext.handleLoading(true);
      actionRun.current = true;
      values.id = id;
      values.locId = selectedLocation.id;
      values.flag = clockInFlag === true ? 1 : 0;
      let path = Constants.EMPLOYEES_VIEW + id;
      submit(values, {
        method: Constants.POST,
        path: path,
      });
    }
  };

  const handleCancelClick = () => {
    if (
      paramData.reportFlag !== undefined &&
      paramData.reportFlag === "extra_item_report"
    ) {
      props.navigate(Constants.EXTRA_ITEM_ROUTE, {
        state: paramData,
      });
    } else if (
      paramData.reportFlag !== undefined &&
      paramData.reportFlag === "variable_item_report"
    ) {
      props.navigate(Constants.VARIABLE_ITEM_ROUTE, {
        state: paramData,
      });
    } else {
      props.navigate(Constants.EMPOYEES_LIST_ROUTE, {
        state: paramData,
      });
    }
  };
  return (
    <Box padding={4}>
      <Card
        sx={{
          boxShadow: "none",
        }}
      >
        <CardHeader
          alignItems={{ base: "flex-start" }}
          mt={{ base: 0, md: 10 }}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(
                Constants.EMPOYEES_LIST_ROUTE
                //   , {
                //   state: stateData.state,
                // }
              );
            }}
            breadCrumNames={["Employees", "View Employees"]}
            breadCrumTitle={"View Employees"}
          />
        </CardHeader>
        <Divider borderColor="#E6E6E6" mt={{ base: 4, md: 8 }} />
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color={Colors.posTextInfo}
                  >
                    Employee Information
                  </Heading>
                  {/* <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Add contact information to complete your employee details
                  </Text> */}
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Text color={Colors.posTextInfo} fontWeight="500">
                    Name
                  </Text>
                  <Text
                    display="inline-flex"
                    alignItems="center"
                    color={Colors.posTextInfo}
                    fontWeight="300"
                  >
                    <DriveFileRenameOutlineSharpIcon
                      style={{
                        marginRight: "0.62rem",
                        fill: Colors.primeTaxInfo,
                      }}
                    />
                    {userData.name}
                  </Text>
                  <PosLable
                    name={"Email Address"}
                    label={true}
                    color={Colors.posTextInfo}
                    fontWeight={500}
                  ></PosLable>
                  <Text
                    display="inline-flex"
                    alignItems="center"
                    color={Colors.posTextInfo}
                    fontWeight="300"
                  >
                    <DriveFileRenameOutlineSharpIcon
                      style={{
                        marginRight: "0.62rem",
                        fill: Colors.primeTaxInfo,
                      }}
                    />
                    {userData.email}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.41rem"
                    letterSpacing="-0.017rem"
                    color={Colors.posTextInfo}
                  >
                    Role and Rates
                  </Heading>
                  {/* <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Assign roles to define what members can do and include their
                    compensation
                  </Text> */}
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Text color={Colors.posTextInfo} fontWeight={500}>
                    Roles
                  </Text>
                  <Text color={Colors.posTextInfo} fontWeight="300">
                    {/* {selectedRoles.map((role, index) => (
                      <span key={index}>{role}</span>
                    ))} */}
                    {selectedRoles.join(",")}
                  </Text>
                  <PosLable
                    name={"Wages per hour"}
                    label={true}
                    color={Colors.posTextInfo}
                    fontWeight={500}
                  ></PosLable>
                  <Text color={Colors.posTextInfo} fontWeight="300">
                    {userData.wage_per_hour || "N.A."}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.41rem"
                    letterSpacing="-0.016875rem"
                    color={Colors.posTextInfo}
                  >
                    Security
                  </Heading>
                  {/* <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                    Set a pin to enable your employees log into the POS and
                    manage clock in and clock out functions
                  </Text> */}
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Text color={Colors.posTextInfo} fontWeight={500}>
                    Pin Code
                  </Text>
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    {userData.pin}
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box w={{ base: "100%", md: "35%" }} ml={{ base: 0, md: 6 }}>
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.41rem"
                    letterSpacing="-0.016875rem"
                    color={Colors.posTextInfo}
                  >
                    Clock In/Out
                  </Heading>
                </Box>
                <Box w={{ base: "100%", md: "55%" }} mt={{ base: 2, md: 0 }}>
                  <Text color={Colors.posTextInfo} fontWeight="500">
                    Location
                  </Text>
                  <PosDropDown
                    value={selectedLocation}
                    filter
                    onChange={(e) => {
                      if (undefined !== e.value) {
                        formik.setFieldValue("selectedLocations", e.value);
                        setSelectedLocation(e.value);
                        setButtonDisable(false);
                        // setClockInFlag(false)
                      }
                    }}
                    onBlur={() => formik.handleBlur("selectedLocation")}
                    options={location}
                    optionLabel={"name"}
                    className="w-full md:w-20rem"
                    width={"100%"}
                    // style={{ width: "100%" }}
                    placeholder={"Make a selection"}
                    disabled={clockInFlag == 0 ? true : false}
                  ></PosDropDown>
                  <Text color={Colors.errorColor}>
                    {formik.errors.selectedLocations &&
                    formik.errors.selectedLocations ? (
                      <span>{formik.errors.selectedLocations}</span>
                    ) : null}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Stack>
          <Flex
            minWidth="max-content"
            gap="2"
            mt={{ base: "2.36rem", md: "4.72rem" }}
          >
            <Spacer />
            <ButtonGroup>
              <PosFormButton
                onClick={handleCancelClick}
                buttonText={"Cancel"} 
                CancelButton={true}
                // isDisabled={buttonDisable}/
              />
              <PosFormButton
                isDisabled={buttonDisable}
                buttonsubmit={
                  loaderResponse.data.data.user_clock_in_flag === 1
                    ? "Clock In"
                    : "Clock Out"
                }
                width={"7.37rem"}
                onClick={formik.handleSubmit}
              />
            </ButtonGroup>
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};
export default WithRouter(EmployeesVIew);
