import { AddIcon } from "@chakra-ui/icons";
import { Button, Image } from "@chakra-ui/react";
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import React from "react";
import Export from "../../assets/images/export.svg";
import Pdf from "../../assets/images/pdf.svg"
import send from "../../assets/images/mingcute_send-line.svg";
import print from "../../assets/images/print.svg";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const PosIconButton = (props) => {
  if (props.exportIcon) {
    return (
      <Button
        leftIcon={<Image src={Export} boxSize={4} />}
        variant="solid"
        onClick={props.onClick}
        w={props.width}
        borderRadius="0.625rem"
      >
        {props.name}
      </Button>
    );
  } else if (props.printIcon) {
    return (
      <Button
        leftIcon={<Image src={print} boxSize={4} />}
        variant="ghost"
        onClick={props.onClick}
        w={props.width}
        borderRadius="0.625rem"
      >
        {props.name}
      </Button>
    );
  } else if (props.sendIcon) {
    return (
      <Button
        leftIcon={<Image src={send} boxSize={6} />}
        variant="unstyled"
        onClick={props.onClick}
        w={props.width}
        display={"flex"}
        padding={"0.63rem"}
        justifyContent={"center"}
        alignItems={"center"}
        flexShrink={"0"}
        borderRadius="0.63rem"
        border={"0.6px solid rgba(5, 5, 18, 0.48)"}
        fontSize={"0.94rem"}
        fontStyle={"normal"}
        fontWeight={"300"}
        lineHeight={"normal"}
        letterSpacing={"0.01rem"}
        isDisabled={props.disabled}
      >
        {props.name}
      </Button>
    );
  } else if (props.refreshIcon) {
    return (
      <Button
        leftIcon={<RefreshIcon />}
        variant="solid"
        onClick={props.onClick}
        w={props.width}
        borderRadius="0.625rem"
      >
        {props.name}
      </Button>
    );
  } else if (props.viewIcon) {
    return (
      <Button
        fontSize={"0.75rem"}
        leftIcon={<RemoveRedEyeOutlinedIcon fontSize="small" />}
        variant="outline"
        onClick={props.onClick}
        h={"1.9rem"}
        p={"0.625rem"}
        w={props.width}
        borderRadius="0.625rem"
        border="0.3px solid rgba(1, 0, 72, 0.30)"
        colorScheme='messenger'
      >
        {props.name}
      </Button>
    );
  } else if (props.pdfIcon) {
    return (
      <Button
        leftIcon={<PictureAsPdfIcon />}
        variant="solid"
        onClick={props.onClick}
        w={props.width}
        borderRadius="0.625rem"
      >
        {props.name}
      </Button>
    );
  } else {
    return (
      <Button
        leftIcon={<AddIcon />}
        variant="solid"
        onClick={props.onClick}
        borderRadius="0.625rem"
      >
        {props.name}
      </Button>
    );
  }
};

export default PosIconButton;
