import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const variants = {
  invomenu: definePartsStyle({
    list: {
        w: "9rem",
      },
      
  }),
};

// export the component theme
export const menuTheme = defineMultiStyleConfig({
//   baseStyle,
//   sizes,
  variants,
});