import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getApiData({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.USER_AUTH_API_KEY_GET_API_DATA_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.USER_AUTH_API_KEY_GET_API_DATA_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function apiTokenGenerateRevoke({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == "Revoke") {
      let payload = Object.fromEntries(formData).data;
      try {
        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": Constants.CONTANT_TYPE,
          Authorization: `Bearer ${token}`,
          Permission: Constants.USER_AUTH_API_KEY_REVOKE_TOKEN_API_PERMISSION,
        };
        const response = await axios.delete(
          `${Constants.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}${Constants.USER_AUTH_API_KEY_REVOKE_TOKEN_API_ROUTE}`,
          {
            headers,
            data: payload,
          }
        );
        resolve(response);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    } else if (Object.fromEntries(formData).type == "Generate") {
      let token = localStorage.getItem("token");
      let payload = Object.fromEntries(formData).data;
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.USER_AUTH_API_KEY_GENERATE_TOKEN_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.USER_AUTH_API_KEY_REVOKE_TOKEN_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

