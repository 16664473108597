import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  SingleOrderView,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const OrderDiscount = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const stateData = useLocation(); // get backraking data as param
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [locations, setLocations] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  // const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  // const [isLarge] = useMediaQuery("(min-width: 1435px)");
  // const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  // const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  // const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  // const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1710px)");
  const [isLarge] = useMediaQuery("(max-width: 1435px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const pageRef = useRef(false);
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const discountType = [
    {
      label: "Order",
      value: "order",
    },
    {
      label: "Item",
      value: "item",
    },
    {
      label: "Both",
      value: "both",
    },
  ];
  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "order_id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    id: false,
    order_discount_reason: false,
  });

  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const columnNames = [
    {
      field: "id",
      headerName: "Id",
      sortable: true,
      width: 90,
    },
    {
      field: "location_name",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_date",
      headerName: "Order Date",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_discount_amount",
      headerName: "Order Discount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_line_discount_amount",
      headerName: "Item Discount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_discount_reason",
      headerName: "Order Discount Reason",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "discount_type",
      headerName: "Discount Type",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "order_id",
      headerName: "Order Id",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        const rowData = params?.row;
        const handleViewOrder = () => {
          myContext.handleLoading(true);
          const id = rowData?.order_id;
          // const handleOrderDetailsClick = () => {
          // myContext.handleLoading(true);
          handleDisplayOrderData(id);
          // };
        };
        return (
          <Text
            onClick={handleViewOrder}
            cursor="pointer"
            color={Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration="underline"
            fontWeight={600}
          >
            {rowData.order_id}
          </Text>
        );
      },
    },
  ];

  const handleDisplayOrderData = useCallback(
    (id) => {
      setOrderId(id);
      onDrawerOpen();
    },
    [orderId]
  );
  const [loading, setLoading] = useState(true);
  const [onlySingleLocationFlag, setOnlySingleLocationFlag] = useState(false);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLocations:
        undefined !== stateData?.state?.location
          ? JSON.parse(stateData?.state?.location)
          : undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ? loaderResponse?.data?.data?.location
          : [],
      selectedDiscountType: [],
      fromDate:
        undefined !== stateData?.state?.dateFrom
          ? stateData?.state?.dateFrom
          : "",
      toDate:
        undefined !== stateData?.state?.dateTo ? stateData?.state?.dateTo : "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLocations: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      // fromDate: Yup.string(),
      // .required(Constants.FORM_DATE_IS_REQUIRED),
      // toDate: Yup.string(),
      // .required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setOlColumnVisibilityModel((prev) => ({
              ...prev,
              location_name: false,
            }));
          }
          setLocations(loaderResponse?.data?.data?.location);
          if (loaderResponse?.data?.data?.location?.length == 1) {
            setOnlySingleLocationFlag(true);
            formik.setFieldValue(
              "selectedLocations",
              loaderResponse?.data?.data?.location
            );
            setButtonDisable(true);
            setLoading(true);
            setShowTable(false);
            setApplyClicked(true);
            let data = {
              getreport: true,
              location: JSON.stringify(loaderResponse?.data?.data?.location),
              discount_type: JSON.stringify(formik.values.selectedDiscountType),
              dateFrom: "",
              dateTo: "",
              zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              query: JSON.stringify({
                limit: paginationModel.pageSize,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
              }),
            };
            submit(data, {
              method: Constants.POST,
              path: Constants.DISCOUNT_REPORT_ROUTE,
            });
          }
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    // getOrderDiscountData();
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getOrderDiscountData();
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          Constants.ORDER_DISCOUNT_REPORT_EXPORT_SUCCESS
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.ORDER_DISCOUNT_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.orderDiscountData);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  const handleDiscountTypeOptionSelect = (e) => {
    formik.setFieldValue("selectedDiscountType", e.value);
  };

  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isLarge ? "column" : "row"}
          spacing={4}
          width={isLarge ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDropDown
                options={locations}
                value={formik.values.selectedLocations}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLocations");
                  formik.handleBlur(e);
                }}
                multiSelect={true}
                resetFilterOnHide={true}
                optionLabel="name"
                placeholder="Select Locations*"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLocations &&
                formik.errors.selectedLocations ? (
                  <span>{formik.errors.selectedLocations}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDropDown
                options={discountType}
                value={formik.values.selectedDiscountType}
                resetFilterOnHide={true}
                onChange={handleDiscountTypeOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedDiscountType");
                  formik.handleBlur(e);
                }}
                // multiSelect={true}
                optionLabel="label"
                placeholder="Select Discount Type"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                // maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedDiscountType &&
                formik.errors.selectedDiscountType ? (
                  <span>{formik.errors.selectedDiscountType}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date"}
                readOnlyInput={true}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date"}
                readOnlyInput={true}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };

  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    setShowTable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };

  const handleExportReport = () => {
    if (
      formik.values.selectedLocations.length > 0
      // && null != formik.values.fromDate &&
      // "" != formik.values.fromDate &&
      // null != formik.values.toDate &&
      // "" != formik.values.toDate
    ) {
      myContext.handleLoading(true);
      let data = {
        getreport: false,
        location: JSON.stringify(formik.values.selectedLocations),
        discount_type: JSON.stringify(formik.values.selectedDiscountType),
        dateFrom: formik.values.fromDate
          ? reportDateFormate(formik.values.fromDate)
          : "",
        dateTo: formik.values.toDate
          ? reportDateFormate(formik.values.toDate)
          : "",
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        type: "xls",
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.DISCOUNT_REPORT_ROUTE,
      });
    }
  };
  const getOrderDiscountData = () => {
    if (formik.values.selectedLocations.length > 0) {
      if (
        undefined !== formik.values.selectedLocations &&
        null !== formik.values.selectedLocations &&
        formik.values.selectedLocations?.length === 1
      ) {
        setOlColumnVisibilityModel((prev) => ({
          ...prev,
          location_name: false,
        }));
      } else {
        setOlColumnVisibilityModel((prev) => ({
          ...prev,
          location_name: true,
        }));
      }

      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      setApplyClicked(true);
      let data = {
        getreport: true,
        location: JSON.stringify(formik.values.selectedLocations),
        discount_type: JSON.stringify(formik.values.selectedDiscountType),
        dateFrom: formik.values.fromDate
          ? reportDateFormate(formik.values.fromDate)
          : "",
        dateTo: formik.values.toDate
          ? reportDateFormate(formik.values.toDate)
          : "",
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.DISCOUNT_REPORT_ROUTE,
      });
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLocations", e.value);
  };
  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "order_id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setApplyClicked(false);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const modifiedData = tableData?.map((data) => ({
    id: data.order_id,
    location_name: data.location_name,
    // order_date: moment(data.order_date).format("MM/DD/YYYY HH:mm:ss"),
    order_date: commonDateFormate(data.order_date),
    order_discount_amount: data.order_discount_amount,
    order_line_discount_amount: data.order_line_discount_amount,
    order_discount_reason:
      data.order_discount_reason != null && data.order_discount_reason != ""
        ? data.order_discount_reason
        : "N.A.",
    employee_name: data.employee_name,
    discount_type: data.discount_type,
    order_id: data.order_id,
  }));
  const onOrderDrawerClose = () => {
    onDrawerClose();
    setOrderId("");
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <SingleOrderView
        visible={isDrawerOpen}
        onHide={onOrderDrawerClose}
        position="right"
        showCloseIcon={true}
        orderId={orderId}
        breadCrumNames={[]}
        handleClick={(i) => {
          // if (i == 0) {
          //   props.navigate(Constants.REPORT_PATH);
          // }
        }}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Report", "Discount Report"]}
          breadCrumTitle={"Discount Report"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isLarge ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          {/* <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout> */}
          <Stack
            direction={"row"}
            justifyContent={isLarge ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              isDisabled={applyClicked ? false : true}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLocations?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                formik.errors.selectedLocations ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
                buttonDisable
              }
              onClick={getOrderDiscountData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.ORDER_DISCOUNT_REPORT_EXPORT_FILENAME}
              />
            </Flex>
          ) : (
            <PosNoDataFound title={Constants.ORDER_DISCOUNT_REPORT_NOT_FOUND} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(OrderDiscount);
