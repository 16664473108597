import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useClipboard,
  useRadioGroup,
} from "@chakra-ui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { useFormik } from "formik";
import { SelectButton } from "primereact/selectbutton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit, useLocation } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { sendingCdsCode } from "./CdsService";

const CdsCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [locations, setLocations] = useState([]);
  const [stations, setStations] = useState([]);
  
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedStation, setSelectedStation] = useState("");
  
  const [cdsCode, setCdsCode] = useState("");
  const [selectedCouponType, setSelectedCouponType] = useState("");
  // const cancelRef = React.useRef();
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state

  const {
    isOpen: isRewardModalOpen,
    onOpen: onRewardModalOpen,
    onClose: onRewardModalClose,
  } = useDisclosure();
  const CardRadio = ({ children, isChecked, ...props }) => {
    return (
      <Box>
        {/* <input type="radio" {...props} style={{ display: "none" }} /> */}
        <Box
          cursor="pointer"
          // borderWidth="2px"
          // borderRadius="100rem"
          h={"2.125rem"}
          w={"5.125rem"}
          // boxShadow="md"
          // color={"#5881FE"}
          // borderColor={ "gray.300"}
          _hover={{
            bg: isChecked ? "messenger" : "gray.100",
          }}
          _checked={{
            bg: "teal.600",
            // color: "white",
            borderColor: "#5881FE",
          }}
          px={1}
          py={1}
        >
          {children}
        </Box>
      </Box>
    );
  };
  const handleCouponTypeChange = (value) => {
    setSelectedCouponType(value);
    formikModal.setFieldValue("send_type", value);
  };
  const {
    getRootProps: getCouponTypeRootProps,
    getRadioProps: getCouponTypeRadioProps,
  } = useRadioGroup({
    name: "value",
    defaultValue: "",
    onChange: handleCouponTypeChange,
  });

  const couponTypeOptions = [
    {
      title: "Email",
      value: "email",
      icon: (
        <EmailIcon color={Colors.taxInfo} style={{ fill: Colors.taxInfo }} />
      ),
    },
    {
      title: "SMS",
      value: "sms",
      icon: <TextsmsIcon style={{ fill: Colors.taxInfo }} />,
    },
  ];
  const itemTemplate = (option, index) => {
    const radio = getCouponTypeRadioProps({
      value: option.value,
    });
    return (
      <CardRadio
        key={index}
        {...radio}
        isChecked={formikModal.values.send_type.includes(option.value)}
      >
        <Flex
          // color={
          //   formikModal.values.send_type.includes(option.value)
          //     ? "white"
          //     : Colors.posTextInfo
          // }
          flexDirection="row"
          alignItems="left"
          gap={{ base: "1rem", md: 1 }}
        >
          {option.icon}
          <Text
            fontFamily={"Lexend, sans-serif;"}
            fontWeight="bold"
            // color={
            //   formikModal.values.send_type.includes(option.value)
            //     ? "white"
            //     : Colors.posTextInfo
            // }
          >
            {option.title}
          </Text>
        </Flex>
        <Text
          fontFamily={"Lexend, sans-serif;"}
          textAlign={"left"}
          mt={"0.63rem"}
          // color={
          //   formikModal.values.send_type.includes(option.value)
          //     ? "white"
          //     : Colors.posTextInfo
          // }
        ></Text>
      </CardRadio>
    );
  };
  const handleCloseDialog = () => {
    onRewardModalClose();
    props.navigate(Constants.CDS_LIST);
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          const locArray = [];
          const stateArray = [];
          loaderResponse.data.data?.locations.map((dt,i)=>{
            if(dt.stations.length > 0){
              locArray.push(dt);
            }
          })
          setLocations(locArray);
        }
      }
      // return () => {
        effectRun.current = false;
      // };
    }

    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      myContext.handleLoading(false);
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        myContext.handleLoading(false);
        let actionData = actionResponse;
        error({ actionData });
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        myContext.handleLoading(false);
        setCdsCode(
          actionResponse.data?.data?.code
            .replace(/(.{4})/g, "$1 ")
            .toUpperCase()
            .trim()
        );
        onRewardModalOpen();
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.CDS_MODULE,
          alertDescription: actionResponse.data.message,
        });
      } else {
        myContext.handleLoading(false);
        let actionData = actionResponse;
        error({ actionData });
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
    setStations(value.stations);
  };

  const handleSelectedStation= (value) => {
    setSelectedStation(value);
  };

  const handleClose = () => {
    formikModal.resetForm();
    if (props.onRewardClose) {
      props.onRewardClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      location_id: "",
      pos_station_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().trim()
        .required(Constants.POS_NAME_REQUIRED)
        .max(191, Constants.POS_NAME_LESS_OR_EQUAL_191_CHARACTER),
      // location_id: Yup.string()
      //   .required(Constants.POS_LOCATION_REQUIRED),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        location_id: JSON.stringify(selectedLocation),
        pos_station_id:JSON.stringify(selectedStation)
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.CDS_CREATE_API_ROUTE,
      });
    },
  });
  const formikModal = useFormik({
    initialValues: {
      cds_code: cdsCode,
      send_type: [],
      emailSelected: false,
      smsSelected: false,
      email: "",
      sms: "",
      modalOpen: isRewardModalOpen,
    },
    validationSchema: Yup.object({
      modalOpen: Yup.boolean(),
      cds_code: Yup.string().when("modalOpen", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.FIELD_NAME_REQUIRED)
            .max(191, Constants.FIELD_NAME_LENGTH_GREATER_THAN_191),
        otherwise: (schema) => schema.optional(),
      }),
      send_type: Yup.array().when("modalOpen", {
        is: true,
        then: (schema) => schema.min(1, Constants.SEND_TYPE_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
      email: Yup.string().trim().when("emailSelected", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.VENDOR_EMAIL_REQUIRED)
            .email(Constants.VENDOR_EMAIL_NOT_VALID)
            .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID)
            .max(191, Constants.VENDOR_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
        otherwise: (schema) => schema.optional(),
      }),
      sms: Yup.number().when("smsSelected", {
        is: true,
        then: (schema) =>
          schema
            .required(Constants.CDS_PHONE_REQUIRED)
            .integer(Constants.VENDOR_PHONE_NOT_VALID)
            .typeError(Constants.VENDOR_PHONE_NOT_VALID)
            .min(10000, Constants.CDS_PHONE_MUST_BE_5_DIGITS)
            .max(99999999999999, Constants.CDS_PHONE_LESS_THAN_15_DIGITS),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      let payload = {
        ...values,
        code: formikModal.values.cds_code,
      };
      handleCloseDialog();
      setButtonDisable(true);
      myContext.handleLoading(true);
      sendingCdsCode(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.CDS_MODULE,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
            onRewardModalClose();
            props.navigate(Constants.CDS_LIST, {
              state: paramData,
            });
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        selectedLocation == null ||
        selectedLocation == "" ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  // const handleCopyClick = () => {
  //   navigator.clipboard.writeText(cdsCode);
  //   addToast({
  //     alertStatus: Constants.TOAST_TYPE_SUCESS,
  //     alertTitle: Constants.CDS_MODULE,
  //     alertDescription: Constants.CDS_COPIED,
  //   });
  // };
  // const handleCopyClick = () => {
  //   const textArea = document.createElement('textarea');
  //   textArea.value = cdsCode;
  //   textArea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge
  //   document.body.appendChild(textArea);
  //   textArea.focus();
  //   textArea.select();
  //   try {
  //     document.execCommand('copy');
  //     addToast({
  //       alertStatus: Constants.TOAST_TYPE_SUCESS,
  //       alertTitle: Constants.CDS_MODULE,
  //       alertDescription: Constants.CDS_COPIED,
  //     });
  //   } catch (err) {
  //     addToast({
  //       alertStatus: Constants.TOAST_TYPE_WARNING,
  //       alertTitle: Constants.CDS_MODULE,
  //       alertDescription: 'Failed to copy text to clipboard.',
  //     });
  //   }
  // };
  const { onCopy, hasCopied } = useClipboard(cdsCode);
  const copyClick = () => {
    onCopy();
    addToast({
      alertStatus: Constants.TOAST_TYPE_SUCESS,
      alertTitle: Constants.CDS_MODULE,
      alertDescription: Constants.CDS_COPIED,
    });
  }
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" onKeyDown={handleKeyDown}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.CDS_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["CDS", "Create Customer Display Screen"]}
            breadCrumTitle={"Create Customer Display Screen"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.CDS_LIST, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
            <PosFormButton
              isDisabled={
                formik.values.name.length <= 0 ||
                formik.errors.name ||
                selectedLocation == null ||
                selectedLocation == "" ||
                selectedStation == null ||
                selectedStation == "" ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            fontSize="1.125rem"
            color={Colors.taxShow}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={3} mt="0.31rem">
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name={"Name"}
              mt="0.31rem"
            />
            <PosInput
              posInputGroup={true}
              id={"name"}
              name={`name`}
              inputType={"text"}
              placeholder={"Enter CDS Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={formik.handleBlur}
              inputError={formik.touched.name && formik.errors.name}
            >
              <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={1}>
            <Box className="card flex justify-content-center">
              <PosLable
                name={"Location"}
                requiredLabel={true}
                label={true}
                fontWeight={"500"}
              ></PosLable>
              {/* {locations.length ? (
                <Select
                  color="#010048"
                  id="location_id"
                  onChange={(e) => {
                    handleSelectedLocation(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Select a Location
                  </option>
                  {locations.map((data) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
                </Select>
              ) : (
                <Select id="state">
                  <option selected hidden disabled value={"N.A."}>
                    {"N.A."}
                  </option>
                </Select>
              )} */}
              <PosDropDown
                value={selectedLocation}
                lableAvailable={true}
                width={"100%"}
                // filter
                onChange={(e) => {
                  if(undefined != e.target.value && null != e.target.value && '' != e.target.value){
                    setStations([]);
                    setSelectedStation("");
                    handleSelectedLocation(e.target.value);
                  }
                }}
                // onBlur={() => formik.handleBlur("selectedLocation")}
                options={locations}
                optionLabel={"name"}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select Location"}
              ></PosDropDown>
            </Box>
          </Stack>
          {stations.length > 0 && (
            <Stack spacing={1}>
            <Box className="card flex justify-content-center">
              <PosLable
                name={"POS Station"}
                requiredLabel={true}
                label={true}
                fontWeight={"500"}
              ></PosLable>
              <PosDropDown
                value={selectedStation}
                lableAvailable={true}
                width={"100%"}
                onChange={(e) => {
                  if(undefined != e.target.value && null != e.target.value && '' != e.target.value){
                    handleSelectedStation(e.target.value);
                  }
                }}
                options={stations}
                optionLabel={"name"}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
                placeholder={"Select Location"}
              ></PosDropDown>
            </Box>
          </Stack>
          )}
        </Box>
      </Box>
      <>
        <Modal
          isOpen={isRewardModalOpen}
          // onClose={onRewardModalClose}
          size={"lg"}
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent backgroundColor={Colors.loginAuthBackground}>
            <ModalHeader>Send Your CDS Code</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody>
              <PosLable
                fontWeight={500}
                name={"Your Customer Display Code"}
                label={true}
              />
              <InputGroup size="md">
                <Input
                  disabled
                  variant="filled"
                  pr="6rem"
                  value={formikModal.values.cds_code}
                  type={"text"}
                />
                <InputRightElement width="3rem" gap={1}>
                  <Button h="2.1rem" size="md" onClick={copyClick}>
                    <ContentCopyIcon />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <PosLable
                label={true}
                fontWeight={500}
                requiredLabel={true}
                name={"Send Code To"}
              />
              <Stack mt={2} direction={{ base: "column", md: "row" }}>
                <SelectButton
                  id="send_type"
                  name="send_type"
                  value={formikModal.values.send_type}
                  options={couponTypeOptions}
                  onChange={(e) => {
                    formikModal.handleChange(e);
                    if (e.value.includes("email") && e.value.includes("sms")) {
                      if (!formikModal.values.emailSelected) {
                        formikModal.setFieldValue("emailSelected", true);
                        formikModal.setFieldValue('email','');
                      } else if (!formikModal.values.smsSelected) {
                        formikModal.setFieldValue("smsSelected", true);
                        formikModal.setFieldValue('sms','');
                      }
                    } else if (e.value.includes("email")) {
                      if (!formikModal.values.emailSelected) {
                        formikModal.setFieldValue("emailSelected", true);
                        formikModal.setFieldValue('email','');
                      } else if (formikModal.values.smsSelected) {
                        formikModal.setFieldValue("smsSelected", false);
                        formikModal.setFieldValue('sms','');
                      }
                    } else if (e.value.includes("sms")) {
                      if (formikModal.values.emailSelected) {
                        formikModal.setFieldValue("emailSelected", false);
                        formikModal.setFieldValue('email','');
                      } else if (!formikModal.values.smsSelected) {
                        formikModal.setFieldValue("smsSelected", true);
                        formikModal.setFieldValue('sms','');
                      }
                    } else {
                      if (formikModal.values.emailSelected) {
                        formikModal.setFieldValue("emailSelected", false);
                        formikModal.setFieldValue('email','');
                      } else if (formikModal.values.smsSelected) {
                        formikModal.setFieldValue("smsSelected", false);
                        formikModal.setFieldValue('sms','');
                      }
                    }
                  }}
                  optionLabel="title"
                  optionValue="value"
                  multiple={true}
                  itemTemplate={itemTemplate}
                />
              </Stack>

              {formikModal.values.send_type.includes("email") && (
                <Stack spacing={1} mt="0.31rem">
                  <PosLable
                    fontWeight={"500"}
                    requiredLabel={true}
                    name={"Email Address"}
                  />
                  <PosInput
                    id="email"
                    placeholder={"Enter Email Address"}
                    handleInputChange={formikModal.handleChange}
                    inputValue={formikModal.values.email}
                    handleBlur={formikModal.handleBlur}
                    posInput={true}
                    inputType={"text"}
                    inputError={
                      formikModal.touched.email && formikModal.errors.email
                    }
                  />
                  <Text color={Colors.errorColor}>
                    {formikModal.touched.email && formikModal.errors.email ? (
                      <span>{formikModal.errors.email}</span>
                    ) : null}
                  </Text>
                </Stack>
              )}
              {formikModal.values.send_type.includes("sms") && (
                <Stack spacing={1} mt="0.31rem">
                  <PosLable
                    fontWeight={"500"}
                    requiredLabel={true}
                    name={"Phone Number"}
                    label={true}
                  ></PosLable>
                  <PosInput
                    id="sms"
                    posNumberInput={true}
                    // posInput={true}
                    inputType={"number"}
                    placeholder={"Enter Phone Number"}
                    handleInputChange={formikModal.handleChange}
                    inputValue={formikModal.values.sms}
                    handleBlur={formikModal.handleBlur}
                    inputError={
                      formikModal.touched.sms && formikModal.errors.sms
                    }
                  />
                  <Text color={Colors.errorColor}>
                    {formikModal.touched.sms && formikModal.errors.sms ? (
                      <span>{formikModal.errors.sms}</span>
                    ) : null}
                  </Text>
                </Stack>
              )}
            </ModalBody>
            <ModalFooter>
              <Flex mb={"2rem"}>
                <PosFormButton
                  buttonText={"Cancel"}
                  CancelButton={true}
                  // ref={cancelRef}
                  onClick={handleCloseDialog}
                />
                <Button
                  colorScheme="messenger"
                  ml={3}
                  onClick={formikModal.handleSubmit}
                  isDisabled={
                    formikModal.values.cds_code.length <= 0 ||
                    formikModal.errors.cds_code ||
                    formikModal.errors.send_type ||
                    formikModal.values.send_type.length <= 0 ||
                    buttonDisable ||
                    (formikModal.values.emailSelected == true &&
                      (formikModal.values.email == null || formikModal.values.email == "" || formikModal.errors.email)) ||
                      (formikModal.values.smsSelected == true &&
                      (formikModal.values.sms == null || formikModal.values.sms == "" || formikModal.errors.sms))
                      ? true
                      : false
                  }
                >
                  {"Send Code"}
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Flex>
  );
};

export default WithRouter(CdsCreate);
