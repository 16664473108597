import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Image,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
  useRadioGroup,
  VStack
} from "@chakra-ui/react";
import CreateIcon from "@mui/icons-material/Create";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Checkbox, IconButton } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import moment from "moment";
import { Checkbox as PrimeReactCheckbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import AmountIcon from "../../../assets/images/coupon_amount.svg";
import ProductIcon from "../../../assets/images/coupon_product.svg";
import PercentageIcon from "../../../assets/images/icon_percentage.svg";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosImageCropper,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSwitchButton,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { DeleteIcon } from "@chakra-ui/icons";
import { Search2Icon } from "@chakra-ui/icons";
import { Mtheme, theme } from "../../../theme/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  getAllLocationOsSelectedProduct,
  getProductsForSelectedLocation,
} from "./CouponService";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
const CardRadio = ({ children, isChecked, ...props }) => {
  return (
    <Box as="label">
      <input type="radio" {...props} style={{ display: "none" }} />
      <Box
        cursor="pointer"
        borderWidth="2px"
        borderRadius="0.625rem"
        // h={"7.25rem"}
        // w={"22.125rem"}
        maxWidth={"22.125rem"}
        boxShadow="md"
        // bg={isChecked ? "teal.600" : "white"}
        color={isChecked ? "white" : "black"}
        borderColor={isChecked ? Colors.primeTaxInfo : "rgba(109, 145, 254, 0.08)"}
        _hover={{
          bg: isChecked ? "messenger" : "gray.100",
        }}
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "#5881FE",
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
};
const CouponCreate = (props) => {
  const [selectedCouponType, setSelectedCouponType] = useState("");
  const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const [selectedAudienceType, setSelectedAudienceType] = useState("");
  const [startDateValue, setStartDateValue] = useState("");
  const stateData = useLocation();
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [endDateValue, setEndDateValue] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const direction = useBreakpointValue({ base: "column", md: "row" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialCheckedProducts, setInitialCheckedProducts] = useState([]);
  const autoCompleteActive = useRef(true);

  const couponTypeOptions = [
    {
      title: "Product",
      body: "This coupon can only be applied to selected products",
      coupon_type: "prod",
      // icon: <ShoppingCartIcon style={{ fill: Colors.primeTaxInfo }} />,
      icon: <Image src={ProductIcon} />,
    },
    {
      title: "Order",
      body: "This coupon can only be applied to the entire order",
      coupon_type: "ord",
      icon: <ShoppingCartIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
  ];
  const discountTypeOptions = [
    {
      title: "Amount",
      body: "The coupon discount is calculated based on specific amounts.",
      discount_type: "amou",
      icon: <Image src={AmountIcon} />,
    },
    {
      title: "Percentage",
      body: "The coupon discount is calculated based on specific percentages",
      discount_type: "per",
      icon: <Image src={PercentageIcon} />,
    },
  ];
  const audienceTypeOptions = [
    {
      title: "Generic",
      body: "The coupon discount can be applied by all customers",
      audiance_type: "GEN",
      icon: <CreateIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
    {
      title: "Targeted",
      body: "The coupon discount can be applied by specific customers",
      audiance_type: "TAR",
      icon: <PersonIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
  ];
  // value: "TAR" value: "GEN"
  const handleCouponTypeChange = (value) => {
    setSelectedCouponType(value);
    formik.setFieldValue("selectedLoactions", "");
    // formik.setFieldTouched("selectedLoactions", false);
    formik.setFieldValue("coupon_type", value);
    formik.setFieldValue("min_amount", "");
    formik.setFieldTouched("min_amount", "");
    formik.setFieldValue("max_qty_per_coupon", "");
    formik.setFieldTouched("max_qty_per_coupon", "");
    formik.setFieldValue("apply_on_all_prods", false);
    setSelectedLocation([]);
    setViewSelectedProducts([]);
    setCheckedProducts([]);
    setInitialCheckedProducts([]);
    setProductLocation([]);
    setCheckedItems([]);
  };
  const handleDiscountTypeChange = (value) => {
    setSelectedDiscountType(value);
    formik.setFieldValue("discount_type", value);
  };
  const handleAudienceTypeChange = (value) => {
    // setSelectedAudienceType(value);
    setStartDateValue("");
    setEndDateValue("");
    formik.values.is_print_on_ord_rcpt = false;
    formik.setFieldValue("audiance_type", value);
    if (value == "GEN") {
      formik.setFieldValue("start_date", dateTimeValue);
      formik.setFieldValue("end_date", "");
    } else {
      formik.setFieldValue("start_date", "");
      formik.setFieldValue("end_date", "");
    }
  };
  const {
    getRootProps: getCouponTypeRootProps,
    getRadioProps: getCouponTypeRadioProps,
  } = useRadioGroup({
    name: "coupon_type",
    defaultValue: "",
    onChange: handleCouponTypeChange,
  });
  const {
    getRootProps: getDiscountTypeRootProps,
    getRadioProps: getDiscountTypeRadioProps,
  } = useRadioGroup({
    name: "discount_type",
    defaultValue: "",
    onChange: handleDiscountTypeChange,
  });
  const {
    getRootProps: getAudienceTypeRootProps,
    getRadioProps: getAudienceTypeRadioProps,
    // setValue: setAudienceValue,
  } = useRadioGroup({
    name: "audiance_type",
    defaultValue: "",
    onChange: handleAudienceTypeChange,
  });
  // const group = getRootProps();
  const couponTypeGroup = getCouponTypeRootProps();
  const discountTypeGroup = getDiscountTypeRootProps();
  const audienceTypeGroup = getAudienceTypeRootProps();
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [category, setCategory] = useState([]);
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading1 = open && options.length === 0;
  const [autoSelectedProducts, setAutoSelectedProducts] = useState(null);
  const [inputValueAuto, setInputValueAuto] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [viewSelectedProducts, setViewSelectedProducts] = useState([]);
  const [productLocation, setProductLocation] = useState([]);
  const [allProductId, setAllProductId] = useState([]);
  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const sleep = (duration) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocation(loaderResponse.data.data.location);
          setCategory(loaderResponse.data.data.category.slice(0, -2));
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse?.response?.data;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.COUPON_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
        props.navigate(Constants.COUPON_LIST);
      }
    }
  }, [actionResponse, loaderResponse]);

  useEffect(() => {
    setAllChecked(false);
    handleProductDetails();
  }, [paginationModel, sortModel]);
  const formik = useFormik({
    initialValues: {
      name: "",
      discount_value: "",
      min_amount: "",
      discount_type: "",
      coupon_type: "",
      audiance_type: "",
      max_qty_per_coupon: "",
      is_order_dropdown: false,
      apply_on_all_prods: false,
      location: selectedLocation,
      selectedLoactions: "",
      start_date: "",
      end_date: "",
      is_print_on_ord_rcpt: false,
    },

    validationSchema: Yup.object({
      // need to put condtion on location if coupon type prod
      // selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().when("coupon_type", {
        is: "prod",
        then: (schema) => schema.required(Constants.LOCATION_IS_REQUIRED),
      }),
      // is_print_on_ord_rcpt: Yup.number().when(
      //   ["coupon_type", "audiance_type", "is_order_dropdown"],
      //   {
      //     is: (coupon_type, audiance_type, is_order_dropdown) =>
      //       coupon_type === "ord" &&
      //       audiance_type === "GEN" &&
      //       (is_order_dropdown === null || is_order_dropdown === ""),
      //     then: (schema) =>
      //       schema.required("Add on Every Transaction Receipts is Required"),
      //   }
      // ),
      name: Yup.string()
        .required(Constants.TAX_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
      discount_type: Yup.string().required(Constants.DISCOUNT_TYPE_REQUIRED),
      coupon_type: Yup.string().required(Constants.COUPON_TYPE_REQUIRED),
      // audiance_type: Yup.string().when("is_order_dropdown", {
      //   is: false,
      //   then: (schema) => schema.required(Constants.AUDIENCE_TYPE__REQUIRED),
      // }),
      discount_value: Yup.number()
        .required(Constants.DISCOUNT_VALUE_REQUIRED)
        .typeError(Constants.DISCOUNT_VALUE_NOT_VALID)
        .when("discount_type", {
          is: "amou",
          then: (schema) =>
            schema
              .min(0.01, Constants.DISCOUNT_VALUE_MIN_VALUE)
              .max(999999.99, Constants.DISCOUNT_VALUE_MAX_VALUE),
          otherwise: (schema) =>
            schema
              .min(0.01, Constants.DISCOUNT_VALUE_MIN_VALUE)
              .max(100, Constants.DISCOUNT_VALUE_MORE_THEN_100),
        }),
      max_qty_per_coupon: Yup.number().when("coupon_type", {
        is: "prod",
        then: (schema) =>
          schema
            .typeError(Constants.MIN_AMOUNT_MUST_BE_NUMBER)
            .required(Constants.MAX_QTY_PER_COP_REQ)
            .integer(Constants.MAX_QTY_PER_COP_IS_INT)
            .min(1, Constants.MIN_MAX_QTY_PER_COP)
            .max(999999, Constants.MAX_MAX_QTY_PER_COP),
      }),
      min_amount: Yup.number().when("coupon_type", {
        is: "ord",
        then: (schema) =>
          schema
            .required(Constants.MIN_AMOUNT_REQ)
            .typeError(Constants.MIN_AMOUNT_MUST_BE_NUMBER)
            .min(0.01, Constants.MIN_VAL_OF_MIN_AMOU)
            .max(999999.99, Constants.MAX_VAL_OF_MIN_AMOU),
      }),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      const inValidProduct = viewSelectedProducts.filter(
        (product) => parseFloat(product.sale_price) < values.discount_value
      );
      if (
        formik.values.coupon_type == "prod" &&
        formik.values.discount_type == "amou" &&
        inValidProduct.length > 0
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.COUPON_MODULE,
          alertDescription:
            Constants.DISC_VAL_GREATER_THAN_PROD_SALE_PRICE_TOAST,
        });
        myContext.handleLoading(false);
        setButtonDisable(false);
      } else {
        let data = {};
        data.name = values.name;
        data.coupon_type = values.coupon_type;
        data.discount_type = values.discount_type;
        data.audiance_type = values.audiance_type;
        data.discount_value = values.discount_value;
        data.max_qty_per_coupon = values.max_qty_per_coupon;
        data.min_amount = values.min_amount;
        data.start_date = values.start_date
          ? values.start_date instanceof Date
            ? values.start_date.toISOString()
            : values.start_date
          : "";
        data.end_date = values.end_date
          ? values.end_date instanceof Date
            ? values.end_date.toISOString()
            : values.end_date
          : "";
        const selectedProducts = checkedProducts.filter((product) =>
          checkedItems.includes(product.id)
        );
        const allProductIds = viewSelectedProducts.map((product) => product.id);
        data.is_print_on_ord_rcpt = values.is_print_on_ord_rcpt ? 1 : 0;
        data.apply_on_all_prods = values.apply_on_all_prods ? 1 : 0;
        data.is_order_dropdown = values.is_order_dropdown ? 1 : 0;
        data.location = selectedLocation.id;
        if (values.coupon_type === "prod") {
          data.location_id = parseInt(selectedLocation.id, 10);
        } else {
          data.location_id = "";
        }
        data.products = JSON.stringify(selectedProducts);
        data.allProductBarcodes = JSON.stringify(allProductId);
        var imageDetails = {
          profilepic: croppedImgDetails,
        };
        data.couponImage = JSON.stringify(imageDetails);
        submit(data, {
          method: Constants.POST,
          path: Constants.COUPON_CREATE_API_ROUTE,
        });
      }
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.name.length <= 0 ||
        formik.errors.name ||
        formik.errors.discount_type ||
        formik.errors.discount_value ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  const handleCallback = (base64Details, fileDetails) => {
    if (
      null != base64Details &&
      "" != base64Details &&
      null != fileDetails &&
      "" != fileDetails
    ) {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: fileDetails["name"],
          filetype: fileDetails["type"],
          filesize: fileDetails["size"],
          base64: base64Details.toDataURL().split(",")[1],
        };
      });
      setBase64OfUploadedImg(base64Details.toDataURL());
      setImageUploadedFlag(1);
    } else {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: "",
          filetype: "",
          filesize: "",
          base64: "",
        };
      });
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    }
  };
  const [isScreenSize480] = useMediaQuery("(max-width: 480px)");
  const tabStyle = {
    fontWeight: "500",
    fontSize: "0.94rem",
    lineHeight: "1.17rem",
    letterSpacing: "-1.5%",
    color: Colors.posTextInfo,
    // width: "10rem",
  };
  const [curruntTab, setCurruntTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [allProduct, setAllProduct] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [productListAfterClearSearch, setProductListAfterClearSearch] =
    useState(false);

  const handleProductDetails = async () => {
    if (formik.values.coupon_type == "prod") {
      setLoading(true);
      let data = {
        location: selectedLocation.id,
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: productListAfterClearSearch ? "" : searchQuery,
        categories: selectedCategories,
      };
      try {
        getProductsForSelectedLocation(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setLoading(false);
              setAllProduct(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              setProductListAfterClearSearch(false);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              let actionData = response?.data;
              error({ actionData });
              setTotalCount(0);
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err?.response?.data;
            setTotalCount(0);
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setTotalCount(0);
        myContext.handleLoading(false);
      }
    }
  };
  const handleProductDetailsAfterClearSearch = async () => {
    if (formik.values.coupon_type == "prod") {
      setLoading(true);
      let data = {
        location: selectedLocation.id,
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: "",
        categories: selectedCategories,
      };
      try {
        getProductsForSelectedLocation(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setLoading(false);
              setAllProduct(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              setProductListAfterClearSearch(false);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              let actionData = response?.data;
              error({ actionData });
              setTotalCount(0);
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err?.response?.data;
            setTotalCount(0);
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        setTotalCount(0);
        myContext.handleLoading(false);
      }
    }
  };
  const handleTabChange = (index) => {
    setCurruntTab(index);
    setTabIndex(index);
    if (index == 0) {
      effectRun.current = true;
      setLoading(true);
    } else {
      // effectRun.current = true;
      setLoading(true);
      if (selectedCouponType === "prod") {
        handleProductDetails();
      }
    }
  };

  const columnProductViewNames = [
    // {
    //   columnNames: "Id",
    //   sortName: "",
    //   width: "",
    // },
    {
      columnNames: "Product name",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Categories",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "SKU",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Action",
      sortName: "",
      width: "",
    },
  ];

  const handleCheckedItemsChange = (checkedItems) => {
    setCheckedItems(checkedItems);
  };
  // const modifiedViewData = viewSelectedProducts.map((products) => ({
  //   id: products.id,
  //   name: products.name,
  //   categories: products.categories,
  //   barcode: products.barcode,
  //   sku: products.sku || "N.A.",
  //   sale_price: products.sale_price,
  // }));
  const modifiedViewData = viewSelectedProducts.map((products) => {
    // Check if categories is an array
    const categoryNames = Array.isArray(products.categories)
      ? products.categories.map((category) => category.name).join(", ")
      : products.categories; // If it's already a string, just use it directly

    return {
      id: products.id,
      name: products.name,
      categories: categoryNames,
      barcode: products.barcode,
      sku: products.sku || "N.A.",
      sale_price: products.sale_price,
    };
  });
  const addRowInModifiedViewData = {
    id: modifiedViewData.length + 1,
    name: "New Product",
    categories: "-",
    barcode: "",
    sku: "",
    sale_price: "",
  };
  modifiedViewData.push(addRowInModifiedViewData);

  const handleSearchList = () => {
    setAllChecked(false);
    paginationModel.page = 0;
    handleProductDetails();
  };
  const handleSingleDelete = (id) => {
    const updatedViewItems = viewSelectedProducts.filter(
      (itemId) => itemId.id !== id
    );
    const updatedCheckedItems = checkedItems.filter((itemId) => itemId !== id);
    setCheckedItems(updatedCheckedItems);
    setViewSelectedProducts(updatedViewItems);
    const updatedCheckedProducts = checkedProducts.filter((p) => p.id !== id);
    setCheckedProducts(updatedCheckedProducts);
    setInitialCheckedProducts(updatedCheckedProducts);
    fetchProductLocation(updatedCheckedProducts);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  const [checkedProducts, setCheckedProducts] = useState([]);
  function handleCheckboxChange(params) {
    const rowId = params.row.id;
    const checkedRow = params.row;
    let data = [];
    let productData = [];
    if (checkedItems.includes(rowId)) {
      let index = checkedItems.findIndex((x) => x === rowId);
      if (index > -1) {
        data = [
          ...checkedItems.slice(0, index),
          ...checkedItems.slice(index + 1),
        ];
        setCheckedItems(data);
        productData = checkedProducts.filter((product) => product.id !== rowId);
        setCheckedProducts(productData);
        setInitialCheckedProducts(productData);
      }
    } else {
      data = [...checkedItems, rowId];
      setCheckedItems(data);
      productData = [...checkedProducts, checkedRow];
      setCheckedProducts(productData);
      setInitialCheckedProducts(productData);
    }
  }
  const handleSelectedViewProduct = () => {
    onClose();
    setSelectedCategories([]);
    const selectedProductss = checkedProducts.filter((product) =>
      checkedItems.includes(product.id)
    );
    setViewSelectedProducts(selectedProductss);
    if (selectedProductss.length > 0) {
      fetchProductLocation(selectedProductss);
    }

    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "name",
        sort: "asc",
      },
    ]);
  };
  const actionColumn = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const onCheckboxSelectionChange = (e) => {
    const isChecked = e.target.checked;
    setAllChecked(isChecked);
    const selectedAllProductIds = allProduct.map((product) => product.id);
    if (isChecked == true) {
      const checkAllProductsIdOfCurrantPage = selectedAllProductIds.filter(
        (Id) => !checkedItems.includes(Id)
      );

      setCheckedItems([...checkedItems, ...checkAllProductsIdOfCurrantPage]);
      const checkAllProductsOfCurrantPage = allProduct.filter(
        (product) =>
          !checkedProducts.find(
            (checkedProduct) => checkedProduct.id === product.id
          )
      );

      // const setCheckAllProductsOfCurrantPage =
      //   checkAllProductsOfCurrantPage.map((product) => {
      //     const categoryNames = product.categories
      //       .map((category) => category.name)
      //       .join(", ");
      //     return {
      //       ...product,
      //       categories: categoryNames,
      //     };
      //   });

      setCheckedProducts([
        ...checkedProducts,
        ...checkAllProductsOfCurrantPage,
      ]);
      setInitialCheckedProducts([...checkedProducts,
        ...checkAllProductsOfCurrantPage,])
    } else {
      const uncheckedItems = checkedItems.filter(
        (Id) => !selectedAllProductIds.includes(Id)
      );
      setCheckedItems(uncheckedItems);

      const remainingProducts = checkedProducts.filter(
        (product) => !selectedAllProductIds.includes(product.id)
      );
      setCheckedProducts(remainingProducts);
      setInitialCheckedProducts(remainingProducts);
    }
  };
  const productColumnNames = [
    {
      field: "selection",
      headerName: (
        <Checkbox onChange={onCheckboxSelectionChange} checked={allChecked}/>
      ),
      filterable: false,
      sortable: false,
      resizable: false,
      renderCell: (params) => (
        <IconButton
          aria-label="select"
          onClick={() => handleCheckboxChange(params)}
          sx={{ color: "#5881FE" }}
        >
          <Checkbox
            checked={checkedItems.includes(params.row.id)}
            onChange={() => handleCheckboxChange(params)}
          />
        </IconButton>
      ),
    },
    // { field: "id", headerName: "Id", sortable: true, flex: 1 },s
    {
      field: "name",
      headerName: "Product name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "categories",
      headerName: "Categories",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "sku",
      headerName: "SKU",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "sale_price",
      headerName: "Sales Price($)",
      sortable: true,
      ...actionColumn,
    },
  ];
  const modifiedDeactiveData = allProduct.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    // sale_price: Number(data.sale_price).toFixed(2),
    sale_price: data.sale_price,
    sku:
      data.sku === null || data.sku === "" || data.sku === undefined
        ? "N.A."
        : data.sku,
    location: data.location,
    location_id: data.location_id,
    productbarcode: data.productbarcode,
    categories: data.categories.map((cat) => cat.name).join(", "),
  }));
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });

  const fetchProductLocation = async (selectedProductss) => {
    if (selectedProductss.length > 0) {
      setButtonDisable(true);
      try {
        getAllLocationOsSelectedProduct(selectedProductss)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              const data = response.data.data.data;
              const allProductId = data.map((item) => item.id);
              setAllProductId(allProductId);
              const locationNames = data.map((item) => item.location.name);
              const uniqueLocationNames = [...new Set(locationNames)];
              setProductLocation(uniqueLocationNames);
              setLoading(false);
              myContext.handleLoading(false);
              setButtonDisable(false);
            } else {
              let actionData = response;
              error({ actionData });
              setLoading(false);
              myContext.handleLoading(false);
              setButtonDisable(false);
            }
          })
          .catch((err) => {
            let actionData = err;
            error({ actionData });
            setLoading(false);
            myContext.handleLoading(false);
            setButtonDisable(false);
          });
      } catch (error) {
        setLoading(false);
        myContext.handleLoading(false);
        setButtonDisable(false);
      }
    }
  };
  const sortRef = useRef(sortModel);
  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      if (
        e.value.name !== undefined &&
        e.value.name !== null &&
        e.value.name !== ""
      ) {
        formik.setFieldTouched("selectedLoactions", false);
      }
      formik.setFieldValue("apply_on_all_prods", false);
      // setSelectedLocation([]);
      setViewSelectedProducts([]);
      setProductLocation([]);
      setInitialCheckedProducts([]);
      setCheckedItems([]);
    }
  };
  const handleCategorySelect = (e) => {
    setSelectedCategories(e.value);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };
  const handleCloseModal = () => {
    onClose();
    setSelectedCategories([]);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.id
    );
    setCheckedItems(selectedProductIds);
    const selectedProducts = viewSelectedProducts.map((p) => p);
    setCheckedProducts(selectedProducts);
    setSortModel([
      {
        field: "name",
        sort: "asc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSearchQuery("");
  };
  const handleClear = () => {
    setAllChecked(false);
    setProductListAfterClearSearch(true);
    setSearchQuery("");
    handleProductDetailsAfterClearSearch();
  };
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "md",
    xl: "lg",
  });
  

  const autoCompleteSelectedProducts = (pro) => {
    if (undefined != pro && null != pro && "" != pro) {
      if (!checkedItems.includes(pro.id)) {
        const productForFatchLocation = initialCheckedProducts;
        productForFatchLocation.push(pro);
        setCheckedItems([...checkedItems, pro.id]);
        if (!checkedProducts.includes(pro)) {
          setCheckedProducts([...checkedProducts, pro]);
        }
        // setViewSelectedProducts([...viewSelectedProducts,pro]);
        fetchProductLocation(productForFatchLocation);
        setViewSelectedProducts(productForFatchLocation);
        
        // var proAutoData = {
        //   id: pro.id,
        //   name: pro.name,
        //   barcode: pro.barcode,
        //   sku: pro.sku || "N.A.",
        //   in_stock_qty: pro.in_stock_qty,
        //   sale_price: pro.sale_price,
        //   location: pro.location.name,
        //   threshold: pro.threshold || "N.A.",
        //   unit_case: pro.no_of_eaches || "",
        //   autoCompleteProduct: true,
        //   categories: pro.categories,
        // };
        // handleCheckboxChange({ row: proAutoData });
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      } else {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.COUPON_MODULE,
          alertDescription: "Product already exists.",
        });
        // setAutoSelectedProducts("");
        setInputValueAuto("");
      }
      // setAutoSelectedProducts('');
    }
  };

  useEffect(() => {
    // let active = true;
    let isMounted = true; // flag to track component mount state
    autoCompleteActive.current = true;
  
    if (!loading1) {
      return undefined;
    }
  
    const handler = setTimeout(() => {
      if (autoCompleteActive.current && isMounted) {
        search();
      }
    }, 450); // Adjust the debounce time as needed (e.g., 300ms)
  
    return () => {
      autoCompleteActive.current = false;
      isMounted = false;
      clearTimeout(handler);
    };
  }, [loading1,inputValueAuto]);
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const search = () => {
    // let data = {
    //   location: selectedLocation.id,
    //   filter: inputValueAuto,
    //   meilisearch: true,
    //   // query: {
    //     limit: 1000,
    //     page: 0,
    //     order:
    //       sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
    //     order_type:
    //       sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
    //     // filter: inputValueAuto,
    //   // },
    // };
    let data = {
      location: selectedLocation.id,
      meilisearch: true,
      limit: 1000,
      page:  0,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: inputValueAuto,
      categories: selectedCategories,
    };
    try {
      getProductsForSelectedLocation(data)
        .then((response) => {
          if (response.data.flag === true) {
            // setProducts(response.data.data.data);
            // setProductData(response.data.data.data);
            setOptions(response.data.data.data);
           
            // setTimeout(() => {
            //   let _filteredProducts;
            //   if (!event.query.trim().length) {
            //     _filteredProducts = [...response.data.data.data];
            //   } else {
            //     _filteredProducts = response.data.data.data.filter((product) => {
            //       return (
            //         (product.name.toLowerCase().includes(event.query.toLowerCase()) ||
            //         product.barcode.toLowerCase().startsWith(event.query.toLowerCase()))
            //       );
            //     });
            //   }
            //   setFilteredProducts(_filteredProducts);
            // }, 350);
            myContext.handleLoading(false);
          } else {
            setTotalCount(0);
            setLoading(false);
            setOptions([]);
            setOpen(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setTotalCount(0);
          setOptions([]);
          setOpen(false);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setTotalCount(0);
      setLoading(false);
      setOptions([]);
      setOpen(false);
      myContext.handleLoading(false);
    }
};
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.COUPON_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Coupons", "Create Coupons"]}
            breadCrumTitle={"Create Coupons"}
          />
        </Flex>
        <Tabs
          mt={{ base: 4, lg: 8 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          mb={{ base: 4, lg: 14 }}
          index={tabIndex}
          onChange={(index) => handleTabChange(index)}
        >
          <TabList
            sx={{
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
            overflow={isScreenSize480 ? "scroll" : "none"}
          >
            <Tab
              w={{ base: "100%", md: "100%", lg: "100%", xl: "17.34rem" }}
              style={tabStyle}
              // _selected={{ color: Colors.taxInfo }}/
            >
              Coupon Identity
            </Tab>
            <Tab
              w={{ base: "100%", md: "100%", lg: "100%", xl: "17.34rem" }}
              style={tabStyle}
              isDisabled={
                selectedCouponType === "ord" ||
                // !selectedCouponType ||
                // selectedLocation.length !== 0 ||
                !formik.isValid ||
                !formik.dirty ||
                buttonDisable ||
                (formik.values.is_order_dropdown == false &&
                  (undefined == formik.values.audiance_type ||
                    null == formik.values.audiance_type ||
                    "" == formik.values.audiance_type)) ||
                (formik.values.coupon_type == "prod" &&
                  (null == formik.values.max_qty_per_coupon ||
                    "" == formik.values.max_qty_per_coupon ||
                    null == formik.values.selectedLoactions ||
                    "" == formik.values.selectedLoactions)) ||
                (formik.values.coupon_type == "ord" &&
                  (null == formik.values.min_amount ||
                    "" == formik.values.min_amount))
              }
              // _selected={{ color: Colors.taxInfo }}/
            >
              Coupon Attributes
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
                p="5"
              >
                <Flex flexDirection={"row"}>
                  <PosLable
                    label={true}
                    color={Colors.posTextInfo}
                    name={"Is this a Predefined Coupon?*"}
                  />
                  <Tippy
                    content="By saving it in Pre-Defined Discounts, it can easily be accessed from the POS tablet whenever necessary."
                    interactive={true}
                    maxWidth={350}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={{ base: "0rem", md: "0.3rem" }}
                    />
                  </Tippy>
                </Flex>
                <PosSwitchButton
                  id="is_order_dropdown"
                  switchValue={formik.values.is_order_dropdown}
                  // onChange={formik.handleChange}
                  onChange={(value) => {
                    formik.handleChange(value);
                    // setAudienceValue("");
                    formik.setFieldValue("start_date", "");
                    formik.setFieldValue("end_date", "");
                    formik.setFieldValue("is_print_on_ord_rcpt", false);
                    formik.setFieldValue("audiance_type", "");
                  }}
                />
                {/* <Text
                  mt={"0.88rem"}
                  h={"1.44rem"}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "0.75rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                  }}
                >
                  Turn on, to select as a predefined coupon. Predefined Coupons
                  can be accessed from the POS tablet when necessary
                </Text> */}
                <Stack spacing={1}>
                  <PosLable
                    label={true}
                    color={Colors.posTextInfo}
                    name={"Coupon name*"}
                  />
                  <PosInput
                    id="name"
                    width={{
                      base: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "46.31rem",
                    }}
                    placeholder={"$5 OFF on $10 order"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.name}
                    handleBlur={(e) => {
                      formik.setFieldValue("name", formik.values.name.trim());
                      formik.handleBlur(e);
                    }}
                    onKeyDown={handleKeyDown}
                    posInputGroup={true}
                    background={Colors.posviewbgcolor}
                    inputType={"text"}
                    inputError={formik.touched.name && formik.errors.name}
                  >
                    <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
                  </PosInput>
                  <Text color={Colors.errorColor}>
                    {formik.touched.name && formik.errors.name ? (
                      <span>{formik.errors.name}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Box mt={{ base: "2rem", md: "2rem" }}>
                  <PosImageCropper
                    parentCallback={handleCallback}
                    imgUploadLabel="Upload Image"
                    canvasWidth="14.75rem"
                    canvasHeight="14.75rem"
                    // cropperWidth="136"
                    // cropperHeight="115"
                    cropperWidth={236}
                    cropperHeight={193}
                    cropperMinWidth={96}
                    cropperMaxWidth={236}
                    cropperMinHeight={79}
                    cropperMaxHeight={193}
                  />
                </Box>
                <Divider mt={8} mb={8} />
                <Heading fontWeight="500" fontSize="0.94rem" color="#010048">
                  Coupon Type
                </Heading>
                <Text color={Colors.posViewTextColor} fontSize="0.94rem">
                  Determines if coupon is made for an entire order or for a
                  specific product
                </Text>
                <Flex
                  justifyContent={{ base: "center", md: "revert-layer" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                  mt={4}
                  spacing={"8.13rem"}
                  mb={8}
                  gap={"2.12rem"}
                  {...couponTypeGroup}
                  // w={{ md: "44.12rem" }}
                >
                  {couponTypeOptions.map((option, index) => {
                    const radio = getCouponTypeRadioProps({
                      value: option.coupon_type,
                    });
                    return (
                      <CardRadio
                        key={index}
                        {...radio}
                        isChecked={selectedCouponType === option.coupon_type}
                      >
                        <Flex flexDirection="row" alignItems="center" gap={2}>
                          {option.icon}
                          <Text
                            fontWeight="bold"
                            color={Colors.posTextInfo}
                            textAlign="center"
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text mt={"0.63rem"} color={Colors.posViewTextColor}>
                          {option.body}
                        </Text>
                      </CardRadio>
                    );
                  })}
                </Flex>
                {selectedCouponType === "prod" && (
                  <>
                    <PosLable
                      label={true}
                      color={Colors.posTextInfo}
                      name={"Location*"}
                    />
                    <PosDropDown
                      id="selectedLoactions"
                      options={location}
                      value={selectedLocation}
                      onChange={handleOptionSelect}
                      onBlur={(e) => {
                        formik.setFieldTouched("selectedLoactions");
                        formik.handleBlur(e);
                      }}
                      // multiSelect={true}
                      optionLabel="name"
                      placeholder="Select Locations*"
                      width={"100%"}
                      maxWidth={"46.31rem"}
                      height={"2.5rem"}
                      className="w-full md:w-20rem"
                      // maxSelectedLabels={2}
                    />
                    <Text color={Colors.errorColor}>
                      {formik.touched.selectedLoactions &&
                      formik.errors.selectedLoactions ? (
                        <span>{formik.errors.selectedLoactions}</span>
                      ) : null}
                    </Text>
                    <Stack spacing={1}>
                      <Flex flexDirection={"row"}>
                        <PosLable
                          label={true}
                          color={Colors.posTextInfo}
                          name={
                            "Maximum number of products on which this discount coupon can be used*"
                          }
                        />
                        <Tippy
                          content="With this, you can set a limit on the number of uses for that specific coupon by a user."
                          interactive={true}
                          maxWidth={350}
                          placement="top"
                          animation="fade"
                          inertia={true}
                          moveTransition="transform 0.2s ease-out"
                          theme="tomato"
                        >
                          <Image
                            src={toolTip}
                            alt="Tooltip"
                            mt={"2rem"}
                            ml={{ base: "0rem", md: "0.3rem" }}
                          />
                        </Tippy>
                      </Flex>
                      <PosInput
                        id="max_qty_per_coupon"
                        placeholder={
                          "Enter a Max Quantity of Product Per  Coupon"
                        }
                        background={Colors.posviewbgcolor}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.max_qty_per_coupon}
                        handleBlur={(e) => {
                          formik.setFieldValue(
                            "max_qty_per_coupon",
                            twofix(formik.values.max_qty_per_coupon)
                          );
                          formik.handleBlur(e);
                        }}
                        width={{
                          base: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "46.17rem",
                        }}
                        onKeyDown={handleKeyDown}
                        posNumberInput={true}
                        inputType={"number"}
                        inputError={
                          formik.touched.max_qty_per_coupon &&
                          formik.errors.max_qty_per_coupon
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.max_qty_per_coupon &&
                        formik.errors.max_qty_per_coupon ? (
                          <span>{formik.errors.max_qty_per_coupon}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </>
                )}
                {selectedCouponType === "ord" && (
                  <>
                    <Stack spacing={1}>
                      <PosLable
                        label={true}
                        color={Colors.posTextInfo}
                        name={"Minimum Amount*"}
                      />
                      <PosInput
                        id="min_amount"
                        placeholder={"Enter a Minimum Amount"}
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.min_amount}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "min_amount",
                            twofix(formik.values.min_amount)
                          );
                        }}
                        onKeyDown={handleKeyDown}
                        width={{
                          base: "100%",
                          md: "100%",
                          lg: "100%",
                          xl: "46.17rem",
                        }}
                        posNumberInput={true}
                        background={Colors.posviewbgcolor}
                        inputType={"number"}
                        inputError={
                          formik.touched.min_amount && formik.errors.min_amount
                        }
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.min_amount &&
                        formik.errors.min_amount ? (
                          <span>{formik.errors.min_amount}</span>
                        ) : null}
                      </Text>
                    </Stack>
                  </>
                )}
                <Divider mt={8} mb={8} />
                <Heading fontWeight="500" fontSize="0.94rem" color="#010048">
                  Discount Type
                </Heading>
                <Text color={Colors.posViewTextColor} fontSize="0.94rem">
                  Determines the type of coupon discount value applied
                </Text>
                <Flex
                  justifyContent={{ base: "center", md: "revert-layer" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                  mt={4}
                  spacing={"8.13rem"}
                  mb={8}
                  gap={"2.12rem"}
                  {...discountTypeGroup}
                  // maxWidth={"44.12rem"}
                  // w={{ md: "44.12rem" }}
                >
                  {discountTypeOptions.map((option, index) => {
                    const radio = getDiscountTypeRadioProps({
                      value: option.discount_type,
                    });
                    return (
                      <CardRadio
                        key={index}
                        {...radio}
                        isChecked={
                          selectedDiscountType === option.discount_type
                        }
                      >
                        <Flex flexDirection="row" alignItems="center" gap={2}>
                          {option.icon}
                          <Text
                            fontWeight="bold"
                            color={Colors.posTextInfo}
                            textAlign="center"
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text mt={"0.63rem"} color={Colors.posViewTextColor}>
                          {option.body}
                        </Text>
                      </CardRadio>
                    );
                  })}
                </Flex>
                <Stack spacing={1}>
                  <PosLable
                    label={true}
                    color={Colors.posTextInfo}
                    name={"Discount Value*"}
                  />
                  <PosInput
                    id="discount_value"
                    placeholder={"Enter discount amount"}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.discount_value}
                    handleBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue(
                        "discount_value",
                        twofix(formik.values.discount_value)
                      );
                    }}
                    background={Colors.posviewbgcolor}
                    onKeyDown={handleKeyDown}
                    posInput={true}
                    width={{
                      base: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "46.17rem",
                    }}
                    inputType={"text"}
                    inputError={
                      formik.touched.discount_value &&
                      formik.errors.discount_value
                    }
                  />
                  <Text color={Colors.errorColor}>
                    {formik.touched.discount_value &&
                    formik.errors.discount_value ? (
                      <span>{formik.errors.discount_value}</span>
                    ) : null}
                  </Text>
                </Stack>
                {(selectedCouponType === "prod" ||
                  selectedCouponType === "ord") &&
                  !formik.values.is_order_dropdown && (
                    <>
                      <Divider mt={8} mb={8} />
                      <Heading
                        fontWeight="500"
                        fontSize="0.94rem"
                        color="#010048"
                      >
                        Audience Type
                      </Heading>
                      <Text color={Colors.posViewTextColor} fontSize="0.94rem">
                        Determines who can apply the coupon discount
                      </Text>
                      <Flex
                        justifyContent={{ base: "center", md: "revert-layer" }}
                        alignItems={{ base: "flex-start" }}
                        flexDirection={{ base: "column", md: "row" }}
                        mt={4}
                        spacing={"8.13rem"}
                        mb={8}
                        gap={"2.12rem"}
                        {...audienceTypeGroup}
                        // w={{ md: "44.12rem" }}
                      >
                        {audienceTypeOptions.map((option, index) => {
                          const radio = getAudienceTypeRadioProps({
                            value: option.audiance_type,
                          });
                          return (
                            <CardRadio
                              key={index}
                              {...radio}
                              isChecked={
                                formik.values.audiance_type ===
                                option.audiance_type
                              }
                            >
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                gap={2}
                              >
                                {option.icon}
                                <Text
                                  fontWeight="500"
                                  color={Colors.posTextInfo}
                                  textAlign="center"
                                  fontSize={"0.94rem"}
                                >
                                  {option.title}
                                </Text>
                              </Flex>
                              <Text
                                fontWeight={300}
                                mt={"0.63rem"}
                                fontSize={"0.94rem"}
                                color={Colors.posViewTextColor}
                              >
                                {option.body}
                              </Text>
                            </CardRadio>
                          );
                        })}
                      </Flex>
                    </>
                  )}
                {formik.values.audiance_type === "GEN" &&
                  !formik.values.is_order_dropdown && (
                    <>
                      <PosLable
                        label={true}
                        color={Colors.posTextInfo}
                        name={"Generic Coupon Duration"}
                      />
                      <Flex
                        gap={{ base: 3, md: 6 }}
                        mt={4}
                        flexDirection={{ base: "column", md: "row" }}
                      >
                        {/* <PosDatePicker
                          showTopLable={true}
                          value={startDateValue}
                          label={"Start Date"}
                          selectOtherMonths={true}
                          minDate={dateTimeValue}
                          showButtonBar={true}
                          readOnlyInput={true}
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2000:2030"}
                          // handleSelectData={(date) => {
                          //   effectRun.current = true;
                          //   setStartDateValue(date);
                          // }}
                          handleSelectData={handleSelecStartData}
                        /> */}
                        <PosDatePicker
                          showTopLable={true}
                          labelColor={Colors.posShowFields}
                          inputPlaceholder={"Select Start Date"}
                          value={formik.values.start_date}
                          label={"Start Date"}
                          selectOtherMonths={true}
                          minDate={dateTimeValue}
                          // maxDate={formik.values.end_date}
                          showButtonBar={true}
                          readOnlyInput={true}
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2000:2030"}
                          showTime={true}
                          handleSelectData={(e) => {
                            effectRun.current = true;
                            if (e.value !== null && e.value !== "") {
                              const selectedStartDate = moment(e.value);
                              const currentEndDate = formik.values.end_date
                                ? moment(formik.values.end_date)
                                : null;
                              formik.setFieldValue(
                                "start_date",
                                selectedStartDate.toDate()
                              );
                              if (
                                currentEndDate &&
                                selectedStartDate.isAfter(currentEndDate)
                              ) {
                                formik.setFieldValue(
                                  "end_date",
                                  selectedStartDate.toDate()
                                );
                              } else if (!currentEndDate) {
                                formik.setFieldValue(
                                  "end_date",
                                  selectedStartDate.toDate()
                                );
                              }
                            } else {
                              formik.setFieldValue("start_date", null);
                            }
                          }}
                          onFocus={() => {
                            formik.setFieldTouched("start_date", true);
                          }}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.start_date &&
                          formik.errors.start_date ? (
                            <span>{formik.errors.start_date}</span>
                          ) : null}
                        </Text>
                        {/* <PosDatePicker
                          showTopLable={true}
                          value={endDateValue}
                          label={"End Date"}
                          selectOtherMonths={true}
                          minDate={startDateValue}
                          showButtonBar={true}
                          readOnlyInput={true}
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2000:2030"}
                          handleSelectData={(date) => {
                            effectRun.current = true;
                            setEndDateValue(date);
                          }}
                        /> */}
                        <PosDatePicker
                          showTopLable={true}
                          labelColor={Colors.posShowFields}
                          inputPlaceholder={"Select End Date"}
                          value={formik.values.end_date}
                          label={"End Date"}
                          selectOtherMonths={true}
                          minDate={
                            null != formik.values.start_date &&
                            "" != formik.values.start_date
                              ? formik.values.start_date
                              : dateTimeValue
                          }
                          showButtonBar={true}
                          readOnlyInput={true}
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2000:2030"}
                          showTime={true}
                          handleSelectData={(e) => {
                            effectRun.current = true;
                            formik.setFieldValue("end_date", e.value);
                          }}
                          onFocus={() => {
                            formik.setFieldTouched("end_date", true);
                          }}
                        />
                        <Text color={Colors.errorColor}>
                          {formik.touched.end_date && formik.errors.end_date ? (
                            <span>{formik.errors.end_date}</span>
                          ) : null}
                        </Text>
                      </Flex>
                    </>
                  )}
                {
                  // formik.values.coupon_type === "ord" &&
                  formik.values.audiance_type === "GEN" &&
                    !formik.values.is_order_dropdown && (
                      <>
                        <PosLable
                          label={true}
                          color={Colors.posTextInfo}
                          name={"* Add on Every Transaction Receipts"}
                        />
                        <PosSwitchButton
                          id="is_print_on_ord_rcpt"
                          switchValue={formik.values.is_print_on_ord_rcpt}
                          onChange={formik.handleChange}
                        />
                      </>
                    )
                }
              </Box>
              <Flex
                minWidth="max-content"
                gap="2"
                mt={{ base: "2.15rem", md: "4.15rem" }}
              >
                <Spacer />
                <ButtonGroup>
                  <PosFormButton
                    onClick={() => {
                      props.navigate(Constants.COUPON_LIST, {
                        state: stateData.state,
                      });
                    }}
                    buttonText={"Cancel"}
                    isDisabled={buttonDisable}
                    CancelButton={true}
                  />
                  <PosFormButton
                    isDisabled={
                      !formik.isValid ||
                      !formik.dirty ||
                      buttonDisable ||
                      (formik.values.is_order_dropdown == false &&
                        (undefined == formik.values.audiance_type ||
                          null == formik.values.audiance_type ||
                          "" == formik.values.audiance_type)) ||
                      (formik.values.coupon_type == "prod" &&
                        (null == formik.values.max_qty_per_coupon ||
                          "" == formik.values.max_qty_per_coupon ||
                          null == formik.values.selectedLoactions ||
                          "" == formik.values.selectedLoactions)) ||
                      (formik.values.coupon_type == "ord" &&
                        (null == formik.values.min_amount ||
                          "" == formik.values.min_amount))
                        ? true
                        : false
                    }
                    buttonsubmit={
                      formik.values.coupon_type === "prod" ? "Next" : "Save"
                    }
                    width={"7.37rem"}
                    onClick={() => {
                      if (formik.values.coupon_type === "prod") {
                        setTabIndex(1);
                        handleProductDetails();
                      } else {
                        if (
                          Number(formik.values.min_amount) <
                            Number(formik.values.discount_value) &&
                          formik.values.discount_type == "amou"
                        ) {
                          addToast({
                            alertStatus: Constants.TOAST_TYPE_WARNING,
                            alertTitle: Constants.COUPON_MODULE,
                            alertDescription: Constants.MIN_MAX_VAL_TOAST,
                          });
                        } else {
                          formik.handleSubmit();
                        }
                        // formik.handleSubmit();
                      }
                    }}
                  />
                </ButtonGroup>
              </Flex>
            </TabPanel>
            <TabPanel>
              <Box
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
                p="5"
              >
                <PosLable
                  label={true}
                  color={Colors.posTextInfo}
                  name={"Apply on all products*"}
                />
                <Text
                  h={"1.44rem"}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "0.75rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                  }}
                >
                  {formik.values.apply_on_all_prods
                    ? "Turn off, to apply coupon on selected products"
                    : "Turn on, to apply coupon on all products"}
                </Text>
                <PosSwitchButton
                  mb={3.5}
                  id="apply_on_all_prods"
                  switchValue={formik.values.apply_on_all_prods}
                  // onChange={formik.handleChange}
                  onChange={(value) => {
                    formik.handleChange(value);
                    setProductLocation([]);
                    setViewSelectedProducts([]);
                    setCheckedItems([]);
                    setCheckedProducts([]);
                    setInitialCheckedProducts([]);
                  }}
                />
                {!formik.values.apply_on_all_prods &&
                  viewSelectedProducts.length > 0 && (
                    <Text mb={8} color={Colors.taxInfo}>
                      Location: {productLocation.join(", ")}
                    </Text>
                  )}
                {!formik.values.apply_on_all_prods && (
                  <PosFormButton
                    buttonsubmit={
                      viewSelectedProducts.length === 0
                        ? "Add Products to Coupon"
                        : "Add More Products"
                    }
                    width={"13.37rem"}
                    onClick={onOpen}
                  />
                )}
              </Box>
              {!formik.values.apply_on_all_prods &&
                modifiedViewData.length > 0 && (
                  <Box
                  borderRadius="0.63rem"
                  mt={3}
                  bg={Colors.posPageDataBackground}
                  boxShadow={
                    "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  >
                    {/* <PosTable
                      columnNames={columnProductViewNames}
                      rawdata={modifiedViewData}
                      footerHide={true}
                      singleDeleteButton={true}
                      goSingleDelete={(id) => handleSingleDelete(id)}
                      handleCheckboxChange={handleCheckedItemsChange}
                    /> */}
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead
                          sx={{
                            backgroundColor: Colors.modifierTable,
                            alignItems: "start",
                          }}
                        >
                          <TableRow>
                            {columnProductViewNames.map((column, index) => (
                              <TableCell
                                key={index}
                                // color={Colors.primeposTextInfo}
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "0.94rem",
                                  lineHeight: "1.18rem",
                                  letterSpacing: "-0.01rem",
                                  fontStyle: "normal",
                                  textAlign: column.textAlign || "center",
                                  maxHeight: "42rem",
                                  color: "#010048",
                                }}
                              >
                                <Flex flexDirection={"row"}>
                                  {column.columnNames}
                                </Flex>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {modifiedViewData.map((row, i) => {
                            let index = checkedProducts.findIndex(
                              (x) => x.id === row.id
                            );
                            return (
                              <TableRow key={i}>
                                <TableCell>
                                  {row.name == 'New Product' ? (
                                    <Flex flexDirection={"row"}>
                                      <Search2Icon mt={3} mr={3} />
                                      <ThemeProvider theme={Mtheme}>
                                        <Autocomplete
                                          size="small"
                                          filterOptions={(x) => x}
                                          id="autoselect"
                                          disabled={
                                            buttonDisable ? true : false
                                          }
                                          sx={{ width: 300 }}
                                          open={open}
                                          onOpen={() => {
                                            setOpen(true);  
                                          }}
                                          onClose={() => {
                                            setOpen(false);
                                          }}
                                          value={autoSelectedProducts}
                                          onChange={(event, newValue) => {
                                            autoCompleteSelectedProducts(
                                              newValue
                                            );
                                          }}
                                          inputValue={inputValueAuto || ""}
                                          onInputChange={(
                                            event,
                                            newInputValue
                                          ) => {
                                            setOptions([]);
                                            setInputValueAuto(newInputValue);
                                          }}
                                          getOptionLabel={(option) =>
                                            option?.name || ""
                                          }
                                          options={options}
                                          loading={loading1}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Search"
                                              InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                  <React.Fragment>
                                                    {loading1 ? (
                                                      <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                      />
                                                    ) : null}
                                                    {
                                                      params.InputProps
                                                        .endAdornment
                                                    }
                                                  </React.Fragment>
                                                ),
                                              }}
                                            />
                                          )}
                                        />
                                      </ThemeProvider>
                                    </Flex>
                                  ) : (
                                    <VStack align="start">
                                      <Box
                                        style={{
                                          fontSize: "0.94rem",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          lineHeight: "normal",
                                          letterSpacing: "-0.01rem",
                                          color: "#010048",
                                        }}
                                      >
                                        {row.name}
                                      </Box>
                                    </VStack>
                                  )}
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{
                                    color: "#111928",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {row.categories}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    color: "#111928",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {row.barcode || "N.A"}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    color: "#111928",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {null != row.sku && "" != row.sku
                                    ? row.sku
                                    : "N.A."}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  style={{
                                    color: "#111928",
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  {null != row.sale_price &&
                                  "" != row.sale_price
                                    ? "$" + row.sale_price
                                    : "N.A."}
                                </TableCell>
                                <TableCell align="center">
                                  {row.name == 'New Product' ? null : (
                                    <DeleteIcon
                                      color={Colors.posCancleButtonMuiRed}
                                      style={{
                                        width: "1.13rem",
                                        height: "1.13rem",
                                        flexShrink: 0,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleSingleDelete(row.id)}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              <Dialog
                visible={isOpen}
                closable={false}
                closeOnEscape={false}
                style={{ width: isMobileDialog ? "80%" : "70%" }}
                header={<div style={{ padding: 0, margin: 0 }}> </div>}
              >
                <Box
                  position="sticky"
                  top="0"
                  zIndex="1000"
                  backgroundColor="white"
                  pb={2}
                >
                  <Flex
                    minWidth="max-content"
                    gap="2"
                    mt={4}
                    mb={4}
                    alignItems="center"
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    <Flex
                      alignSelf="flex-start"
                      fontSize="1.5rem"
                      fontWeight={600}
                      color="rgb(1, 0, 72)"
                    >
                      Select Products
                    </Flex>
                    <Spacer />
                    <ButtonGroup>
                      <PosFormButton
                        onClick={handleCloseModal}
                        buttonText={"Cancel"}
                        CancelButton={true}
                      />
                      <PosFormButton
                        buttonsubmit={"Add Products"}
                        SubmitButton={true}
                        isDisabled={checkedItems.length == 0}
                        onClick={handleSelectedViewProduct}
                      />
                    </ButtonGroup>
                  </Flex>
                  <Flex
                    gap="2"
                    mt={4}
                    mb={4}
                    flexDirection={{ base: "column", sm: "column", md: "row" }}
                  >
                    <PosSearch
                      mb={3.5}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onSearch={handleSearchList}
                      handleClear={handleClear}
                    />
                    <PosDropDown
                      options={category}
                      value={selectedCategories}
                      onChange={handleCategorySelect}
                      // onBlur={(e) => {
                      //   formik.setFieldTouched("selectedCategories");
                      //   formik.handleBlur(e);
                      // }}
                      multiSelect={true}
                      optionLabel="name"
                      placeholder="Select Categories"
                      // width={isExtraLarge ? "17rem" : "100%"}
                      width="100%"
                      height="2.5rem"
                      className="w-full md:w-20rem"
                      maxSelectedLabels={2}
                    />
                  </Flex>
                  {/* {totalcount > 0 ? (
                    <ButtonGroup>
                      <PrimeReactCheckbox
                        onChange={onCheckboxSelectionChange}
                        checked={allChecked}
                      ></PrimeReactCheckbox>
                      <label htmlFor="ingredient1" className="ml-2">
                        {allChecked ? "Unselect All" : "Select All"}
                      </label>
                    </ButtonGroup>
                  ) : null} */}
                </Box>
                {loading ? (
                  <PosProgress />
                ) : totalcount > 0 ? (
                  <Box maxHeight={"32rem"} overflow={"auto"}>
                    <PosDataGridTable
                      columns={productColumnNames}
                      rows={modifiedDeactiveData}
                      totalcount={totalcount}
                      columnVisibilityModel={olColumnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setOlColumnVisibilityModel(newModel)
                      }
                      sortingMode={Constants.SORTING_AND_PAGINATION_MODE}
                      paginationModel={paginationModel}
                      paginationMode="server"
                      onPaginationModelChange={setPaginationModel}
                      sortModel={sortModel}
                      onSortModelChange={(newSortModel) => {
                        sortRef.current = sortModel;
                        setSortModel(newSortModel);
                      }}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      fileName={Constants.PRODUCT_CSV_EXPORT}
                    />
                  </Box>
                ) : (
                  <PosNoDataFound title={Constants.COUPON_PRODUCT_NOT_FOUND} />
                )}
              </Dialog>
              <Flex gap="2" mt={{ base: "2.15rem", md: "2.15rem" }}>
                <Spacer />
                <ButtonGroup>
                  <PosFormButton
                    onClick={() => {
                      props.navigate(Constants.COUPON_LIST, {
                        state: stateData.state,
                      });
                    }}
                    buttonText={"Cancel"}
                    isDisabled={buttonDisable}
                    CancelButton={true}
                  />
                  <PosFormButton
                    isDisabled={
                      // !formik.isValid || !formik.dirty ||
                      !formik.isValid ||
                      !formik.dirty ||
                      (formik.values.is_order_dropdown == false &&
                        (undefined == formik.values.audiance_type ||
                          null == formik.values.audiance_type ||
                          "" == formik.values.audiance_type)) ||
                      (formik.values.coupon_type == "prod" &&
                        (null == formik.values.max_qty_per_coupon ||
                          "" == formik.values.max_qty_per_coupon ||
                          null == formik.values.selectedLoactions ||
                          "" == formik.values.selectedLoactions)) ||
                      (formik.values.coupon_type == "ord" &&
                        (null == formik.values.min_amount ||
                          "" == formik.values.min_amount)) ||
                      buttonDisable ||
                      (!formik.values.apply_on_all_prods &&
                        viewSelectedProducts.length <= 0)
                        ? true
                        : false
                    }
                    buttonsubmit={"Save"}
                    // SubmitButton={true}
                    width={"7.37rem"}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  />
                </ButtonGroup>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};
export default WithRouter(CouponCreate);
