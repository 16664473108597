import {
  Box,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse21.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getCustomerDetailsForView } from "./CustomerService";

const CustomerView = (props) => {
  const [isScreenSize480] = useMediaQuery("(max-width: 3rem)");
  const stateData = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [curruntBalence, setCurruntBalence] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [totalcount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const [CustomerBalanceData, setCustomerBalanceData] = useState([]);
  const effectRun = useRef(true);
  const { id } = useParams();
  const { addToast } = PosTostMessage();

  const closepop = () => {
    onClose();
    setCurruntBalence(null);
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
  };

  const handleaddPage = () => {
    myContext.handleLoading(true);
    onOpen();
    formik.setFieldValue("amount", "");
    formik.setFieldValue("external_reference", "");
    myContext.handleLoading(false);
    formik.setFieldTouched("amount", false);
    formik.setFieldTouched("external_reference", false);
  };

  const fetchData = async (id) => {
    let data = id;
    try {
      getCustomerDetailsForView(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setCustomerData(response.data.data.customerData);
            setCustomerBalanceData(response.data.data.available_credit);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCustomerData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setCustomerData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCustomerData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const [customerDataBalance, setCustomerDataBalance] = useState([]);

  useEffect(() => {
    if (effectRun.current === true) {
      fetchData(id);
      effectRun.current = false;
    }
  }, [id]);
  
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (loaderResponse && loaderResponse.data && loaderResponse.data.data) {
            const newBalance = loaderResponse.data.data.available_credit;
            setCustomerDataBalance((prevState) => ({
              ...prevState,
              balance: newBalance,
            }));
          }
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);

      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.CUSTOMER_VIEW_PATH + id);
        fetchData(id);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.CUSTOMERS_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await formik.handleSubmit();
        onClose();
        setLoading(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      id: id,
      amount: "",
      external_reference: "",
      selectedInvoices: [],
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required(Constants.CUSTOMER_PLEASE_ENTER_A_AMOUNT)
        .max(999999.99, Constants.CUSTOMER_AMOUNT_MAX_VALUE)
        .min(0.01, Constants.CUSTOMER_CREDIT_LIMIT_MIN_ERROR),
      external_reference: Yup.string().max(
        191,
        Constants.CUSTOMER_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191
      ),
    }),
    onSubmit: async (values) => {
      try {
        myContext.handleLoading(true);
        const payload = {
          ...values,
        };
        const { amount, external_reference } = payload;

        let data = {
          id: id,
          amount: amount,
          external_reference: external_reference,
          type: Constants.ADDCREDIT,
        };

        submit(data, {
          method: Constants.POST,
          path: Constants.CUSTOMER_VIEW_PATH,
        });
        myContext.handleLoading(false);
      } catch (error) {
        myContext.handleLoading(false);
      }
    },
  });
  return (
    <Box >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", lg: "3.06rem" }}
        pb={{ base: "1.5rem", lg: "2rem" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", lg: "2.75rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.CUSTOMERS_PATH, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Customer", "View"]}
            // breadCrumTitle={"#1"}
          />
        </Box>
        <Spacer />
      </Flex>
      <Box
        mt={{ base: 4, lg: "0.63rem" }}
        ml={{ base: 4, lg: "3.44rem" }}
        mr={{ base: 4, lg: "3.19rem" }}
        // mt={{ base: 4, lg: 8 }}
        // ml={{ base: 4, lg: 14 }}
        // mr={{ base: 4, lg: 14 }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
      >
        <Flex
          pt={{ base: "1.5rem", lg: "2.13rem" }}
          pb={{ base: "1.5rem", lg: "0.88rem" }}
          pl={{ base: "1rem", lg: "2.06rem" }}
          pr={{ base: "0.5rem", lg: "1.75rem" }}

        >
            <Text
              fontSize="1.13rem"
              mt={{base:"0.5rem"}}
              color={Colors.posTextInfo}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
            >
              View a Customer
            </Text>
          <Spacer />
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              props.navigate(Constants.CUSTOMERS_PATH, {
                state: stateData.state,
              });
            }}
          />
        </Flex>
        <>
          <Modal
            isOpen={isOpen}
            onClose={() => {
              //   formik.setFieldValue("amount", "");
              setUserBalance(0);
              onClose();
            }}
            isCentered
            size="2xl"
            width="35.63rem"
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                mt={{ base: "0", md: "1.56rem" }}
                style={{ display: "inline-flex", justifyContent: "center" }}
              >
                Add Customer Credit
              </ModalHeader>
              <Divider />
              <ModalBody
                pb={{ base: 4, md: "1.38rem" }}
                pl={{ base: 4, md: "1.06rem" }}
                pr={{ base: 4, md: "1.75rem" }}
              >
                <Stack mt="0.87rem">
                  {/* <Text  > */}
                  <Flex
                    bg={Colors.modifierTable}
                    style={{
                      display: "flex",
                      padding: "0.63rem",
                      alignItems: "center",
                      gap: "0.63rem",
                      flexShrink: 0,
                      borderRadius: "0.63rem",
                    }}
                  >
                    <Image
                      src={warnignIcon}
                      alt="Warning Icon"
                      boxSize="0.81rem"
                      mr="0.5rem"
                    />
                    <Text>
                      Your Available Credit Balance is: 
                      <Text as="span" fontWeight="bold">
                {CustomerBalanceData !== null &&
                CustomerBalanceData !== undefined &&
                CustomerBalanceData.length !== 0 &&
                CustomerBalanceData !== ""
                  ? `$${CustomerBalanceData}`
                  : ""}
              </Text>
                    </Text>
                  </Flex>
                  <Text mt="2rem">* Amount:</Text>
                  <PosInput
                    id={"amount"}
                    placeholder={"Enter an amount"}
                    // handleInputChange={formik.handleChange}
                    handleInputChange={(e) => {
                      let newBalance = 0;
                      if (
                        undefined != e.target.value &&
                        null != e.target.value &&
                        "" != e.target.value
                      ) {
                        newBalance =
                          parseFloat(e.target.value) +
                          parseFloat(CustomerBalanceData);
                        setUserBalance(newBalance);
                        formik.handleChange(e);
                      } else {
                        formik.handleChange(e);
                        setUserBalance(parseFloat(customerDataBalance.balance));
                      }
                    }}
                    inputValue={formik.values.amount}
                    // handleBlur={formik.handleBlur}
                    handleBlur={(e) => {
                      formik.handleBlur(e);
                      formik.setFieldValue(
                        "amount",
                        twofix(formik.values.amount) || ""
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                      }
                    }}
                    inputType={"number"}
                    posNumberInput={true}
                  />
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.amount && formik.errors.amount ? (
                      <span>{formik.errors.amount}</span>
                    ) : null}
                  </Text>
                </Stack>
                <Stack pt={"0.88rem"}>
                  <Text>Reference/ Reason:</Text>
                  <PosInput
                    id="external_reference"
                    // mt={"1rem"}
                    placeholder={"Enter a reason"}
                    color={Colors.posTextInfo}
                    handleInputChange={formik.handleChange}
                    inputValue={formik.values.external_reference}
                    handleBlur={formik.handleBlur}
                    posInput={true}
                    inputType={"text"}
                  />
                  <Text color={Colors.posCancleButtonRed}>
                    {formik.touched.external_reference &&
                    formik.errors.external_reference ? (
                      <span>{formik.errors.external_reference}</span>
                    ) : null}
                  </Text>
                  {formik.values.amount > 0 && (
                    <Text style={{ color: "#008000" }}>
                      After this transaction new Credit Balance for this
                      customer will be $
                      <Text as="span" fontWeight="bold">
                        {twofix(userBalance)}
                      </Text>
                      .
                    </Text>
                  )}
                </Stack>
              </ModalBody>

              <ModalFooter
                flexDirection={"column"}
                alignItems={"end"}
                style={{ paddingInlineEnd: "var(--chakra-space-4" }}
              >
                <Flex mb={"1.69rem"}>
                  <PosFormButton
                    buttonText={"Cancel"}
                    CancelButton={true}
                    onClick={() => {
                      closepop();
                    }}
                    isDisabled={buttonDisable || isSubmitting}
                  />
                  <Text mr={{ base: "0.5rem"}}></Text>
                  <PosFormButton
                    isDisabled={
                      !formik.values.amount ||
                      formik.values.amount?.length <= 0 ||
                      formik.errors.amount ||
                      formik.errors.external_reference ||
                      buttonDisable
                        ? true
                        : false||
                        isSubmitting
                    }
                    buttonsubmit={"Submit"}
                    SubmitButton={true}
                    onClick={handleSubmit}
                  />
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
        <Divider />
        <Flex
          gap="9"
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
          pl={{ base: 4, lg: "2.06rem" }}
          pr={{ base: 4, lg: "1.75rem" }}
          pt={{ base: 4, lg: "2.31rem" }}
          // p="9"
        >
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
            // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          >
            <Text
              style={{
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
              mb={"0.31rem"}
            >
              Customer Details
            </Text>
            <Flex
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Id
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{customerData.id}</Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Name
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{customerData.name}</Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
              style={{
                overflowX: "auto",
                maxHeight: "5rem",
              }}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                  position:"sticky",
                  top:"0" 
                }}
              />
              <Text ml={{base:2.5,md:2.5}} position="sticky" top="0" mr={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Address1
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo} whiteSpace="normal"
          wordBreak="break-word"
          textAlign={{ base: 'center', md: 'left' }}>
                {customerData.address1 !== null && customerData.address1 !== ""
                  ? customerData.address1
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
              style={{
                overflowX: "auto",
                maxHeight: "5rem",
              }}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                  position:"sticky",
                  top:"0" 
                }}
              />
              <Text ml={{base:2.5,md:2.5}} position="sticky" top="0"  mr={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Address2
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo} whiteSpace="normal"
          wordBreak="break-word"
          textAlign={{ base: 'center', md: 'left' }}>
                {customerData.address2 !== null && customerData.address2 !== ""
                  ? customerData.address2
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                City
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{customerData.city}</Text>
            </Flex>
          </Box>
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
            // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          >
            <Flex
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                State
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{customerData.state}</Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Country
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {customerData.country !== null && customerData.country !== ""
                  ? customerData.country
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <LocalPhoneIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Phone
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {customerData.phone !== null && customerData.phone !== ""
                  ? customerData.phone
                  : "N.A."}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Email
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>{customerData.email}</Text>
            </Flex>
            <Flex p={2.5}>
              <Spacer />
            </Flex>
          </Box>
        </Flex>
        <Flex
          gap="9"
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center" }}
          pt={{ base: 6, md: "2rem" }}
          pb={{ base: 9, md: "2rem" }}
          pl={{ base: 4, lg: "2.06rem" }}
          pr={{ base: 4, md: "1.75rem" }}
        >
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
            // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          >
            <Text
              style={{
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
              mb={"0.31rem"}
            >
              Credit Details
            </Text>
            <Flex
              // height={"4.88rem"}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
              alignItems={"center"}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Credit Limit
              </Text>
              <Spacer />
              <Text color={Colors.taxInfo}>
                {customerData.credit_limit !== null &&
                customerData.credit_limit !== undefined &&
                customerData.credit_limit !== ""
                  ? `$${customerData.credit_limit}`
                  : "N.A"}
              </Text>
            </Flex>
          </Box>
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
            // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          >
            <Text
              style={{
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
              mb={"0.31rem"}
            >
              Additional Details
            </Text>
            <Flex
              // height={"4.88rem"}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
              alignItems={"center"}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Tax Exempt
              </Text>
              <Spacer />
              <Text color={Colors.taxInfo}>
                {customerData.tax_exempt === 0 ? "No" : "Yes"}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex
          gap="9"
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "end" }}
          pb={{ base: 4, md: "2rem" }}
          pl={{ base: 4, lg: "2.06rem" }}
          pr={{ base: 4, md: "1.75rem" }}
        >
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            width="100%"
          >
            <Text
              style={{
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
              }}
              mb={"0.31rem"}
            >
              Credit Balance
            </Text>
            <Flex
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid #b7b7b71f"}
              bg={Colors.posviewbgcolor}
              alignItems={"center"}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Balance
              </Text>
              <Spacer />
              <Text color={Colors.taxInfo}>
                {CustomerBalanceData !== null &&
                CustomerBalanceData !== undefined &&
                CustomerBalanceData.length !== 0 &&
                CustomerBalanceData !== ""
                  ? `$${CustomerBalanceData}`
                  : "N.A"}
              </Text>
            </Flex>
          </Box>
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            alignItems={"center"}
            pb={{ base: "2rem", md: "0" }}
          >
            <PosFormButton
              buttonsubmit={"Add Credit"}
              bg={Colors.posNavbarLink}
              width={!isScreenSize480 ? "11.81rem" : "2.38rem"}
              style={{
                display: "flex",
                padding: "0.63rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.63rem",
                borderRadius: "0.31rem",
                border: "1px solid #E3E3E3",
                boxShadow: "0px 0.25rem 0.25rem 0px #e0e0e01a",
              }}
              onClick={() => handleaddPage()}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default WithRouter(CustomerView);
