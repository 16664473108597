import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Colors from "../../../assets/colors/Colors";
import noPageFound from "../../../assets/images/404.svg";
import eye from "../../../assets/images/eye.svg";
import mouth from "../../../assets/images/mouth.svg";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";

const ForbbidenPage = (props) => {
  return (
    <Flex
      // padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      // bg={Colors.loginAuthBackground}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      minHeight={"100vh"}
    >
      <Flex
        width={{ base: "30rem", lg: "40rem" }}
        height={{ base: "30rem", lg: "40rem" }}
        flexShrink={0}
        flexDirection={"column"}
        alignItems={"center"}
        backgroundImage={noPageFound}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"contain"}
      >
        <VStack
          width={"9.92rem"}
          height={"7.93rem"}
          transform={"rotate(-5.463deg)"}
          flexShrink={0}
          mt={"3.32rem"}
          mb={"3rem"}
          border={"0.7px solid rgba(197, 197, 197, 0.14)"}
          background={Colors.buttonOutlineFocus}
          // bg={"red"}
          boxShadow={"0px 10px 10px 0px #E9EFFE"}
          // alignItems={"center"}
        >
          <HStack
            transform={"rotate(-5.463deg)"}
            mt={"1.69rem"}
            spacing={"2.13rem"}
          >
            <Image src={eye} boxSize={"0.375rem"} />
            <Image src={eye} boxSize={"0.375rem"} />
          </HStack>
          <Image
            src={mouth}
            width={"4.125rem"}
            height={"1rem"}
            mt={"2.813rem"}
          />
        </VStack>
        <Flex
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
          mt={{ base: "0.5rem", lg: "2rem" }}
        >
          <Box w={{ base: "70%", lg: "60%" }} pb="0.225rem">
            <Text
              fontSize={{ base: "1rem", lg: "1.5rem" }}
              fontWeight={"600"}
              color={Colors.posTextInfo}
              letterSpacing={"-0.36px"}
              textAlign={"center"}
            >
              {"Your account has been suspended."}
            </Text>
          </Box>
          <Box w={{ base: "90%", lg: "100%" }} pb="0.225rem">
            <Text
              fontSize={"0.94rem"}
              fontWeight={"300"}
              color={Colors.posTextInfo}
              letterSpacing={"-0.225px"}
              textAlign={"center"}
            >
              {"For more information, Please contact to system administrator."}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RouteWithLayout(ForbbidenPage);
