import {
  Box,
  Flex,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit, useLocation } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosLable,
  PosTostMessage,
  PosIconButton,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { generateTillDetailsPdf } from "./TillsService";
const TillView = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const stateData = useLocation();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [posStation, setposStationData] = useState([]);
  const [station, setStationData] = useState([]);
  const [tillData, setTillData] = useState([]);
  const [selectedPosStation, setSelectedPosStation] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setposStationData(loaderResponse?.data?.data?.till);
          setTillData(loaderResponse?.data?.data?.calculate_difference);
          setStationData(loaderResponse?.data?.data?.payInOutDtls);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.TILL_REPORT_ROUTE, {
          state: stateData.state,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.TILLS_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const formik = useFormik({
    initialValues: {
      selectedPosStation: "",
      selectedUsers: "",
      starting_amount: "",
    },
    validationSchema: Yup.object({
      selectedPosStation: Yup.string().required(
        Constants.CUSTOMER_NAME_REQUIRED
      ),
      selectedUsers: Yup.string().required(
        Constants.CUSTOMER_ADDRESS_NAME_REQUIRED
      ),
    }),
    onSubmit: async () => {
      // myContext.handleLoading(true);
      // setButtonDisable(true);
      // actionRun.current = true;
      // submit(payload, {
      //   method: Constants.POST,
      //   path: Constants.TILL_REPORT_ROUTE,
      // });
    },
  });
  const columnName = [
    {
      columnNames: "Amount Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Amount",
      sortName: "",
      width: "",
    },
  ];

  const [loading, setLoading] = useState(true);

  const handlePdfReport = () => {
    myContext.handleLoading(true);
    let data = posStation.id;
    try {
      generateTillDetailsPdf(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var url = Constants.REACT_APP_API_URL + response.data.data;
            // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  return (
    <Box flex="1" pb="3.31rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              if (i === 0) {
                props.navigate(Constants.REPORT_PATH);
              } else if (i === 1) {
                props.navigate(Constants.TILL_REPORT_ROUTE, {
                  state: stateData.state,
                });
              }
            }}
            breadCrumNames={["Report", "Tills", "View"]}
            breadCrumTitle={"View"}
          />
        </Box>
      </Flex>
      <Box
        mt={{ base: 2, md: "2rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="4"
      >
        <Flex 
        alignItems={"flex-start"} pt={{ base: "1.5rem", md: "1rem" }} pb={{ base: "1.5rem", md: "2rem" }}>
        <Text
          pl={{ base: "0.5rem", lg: "0.09rem" }}
          pr={{ base: "0.5rem", md: "1rem" }}
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontSize: "1.23rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          View a Till
        </Text>
          <Spacer />
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              props.navigate(Constants.TILL_REPORT_ROUTE, {
                state: stateData.state,
              });
            }}
          />
        </Flex>
        <Flex
          direction={{ base: "column", md: "row" }}
          // alignItems={{ base: "center" }}
        >
          <Box
            flex="1"
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          >
            <Flex
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid rgba(183, 183, 183, 0.12)"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Location
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {posStation?.station?.location?.name}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid rgba(183, 183, 183, 0.12)"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Pos Station
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {posStation?.station?.name}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid rgba(183, 183, 183, 0.12)"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Opened By
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {posStation?.opened_by?.name}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid rgba(183, 183, 183, 0.12)"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Starting Amount
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {posStation?.starting_amount}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid rgba(183, 183, 183, 0.12)"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Closed By
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {posStation?.closed_by?.name || "N.A"}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid rgba(183, 183, 183, 0.12)"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Closing Amount
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {posStation?.closing_amount || "N.A"}
              </Text>
            </Flex>
            <Flex
              mt={1.5}
              p={2.5}
              borderRadius={"0.63rem"}
              border={"1px solid rgba(183, 183, 183, 0.12)"}
              bg={Colors.posviewbgcolor}
            >
              <DriveFileRenameOutlineRoundedIcon
                sx={{
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
              <Text ml={2.5} color={Colors.posViewTextColor} fontWeight={300}>
                Status
              </Text>
              <Spacer />
              <Text color={Colors.posTextInfo}>
                {posStation?.is_closed === 0 ? "Opened" : "Closed"}
              </Text>
            </Flex>
            <Flex
              alignItems={"center"}
              mt={{ base: "1rem", md: "2rem" }}
              pb="1rem"
            >
              <Text
                color={Colors.posTextInfo}
                style={{
                  fontSize: "1.12rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "-0.02rem",
                }}
              >
                Calculate Difference:
              </Text>
              <Spacer />

              {tillData ? (
                <PosIconButton
                  name={Constants.EXPORT_PDF_BUTTON}
                  onClick={handlePdfReport}
                  pdfIcon={true}
                  width={"8.5rem"}
                />
              ) : (
                <Text mr={{ base: 0, md: "1rem" }} color={Colors.posTextInfo}>
                  {posStation?.difference || "N.A"}
                </Text>
              )}
            </Flex>
            {tillData && (
              <Flex direction={{ base: "column", md: "row" }}>
                <Flex
                  direction="column"
                  flex="1"
                  mr={{ md: "2rem" }}
                  mb={{ base: "1rem" }}
                >
                  <Text
                    pb={{ base: "1rem", md: "1rem" }}
                    fontSize="1.12rem"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  >
                    Adding Amount
                  </Text>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead bg={Colors.modifierTable}>
                        <Tr>
                          <Th>Amount Type</Th>
                          <Th>Amount</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Starting Amount</Td>
                          <Td>${tillData.starting_amount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Pay In Amount</Td>
                          <Td>${tillData.pay_in_amount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Order Payment Amount</Td>
                          <Td>${tillData.net_amount}</Td>
                        </Tr>
                        {/* <Tr>
                          <Td>Credit Account Payment</Td>
                          <Td>{tillData.cash_credit_account_payment}</Td>
                        </Tr> */}
                        <Tr>
                          <Td>Credit Account Payments</Td>
                          <Td>${tillData.pay_invoices_payment}</Td>
                        </Tr>
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Total</Th>
                          <Td>${tillData.add_amount}</Td>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                </Flex>
                <Flex direction="column" flex="1">
                  <Text
                    pb={{ base: "1rem", md: "1rem" }}
                    fontSize="1.12rem"
                    fontStyle="normal"
                    fontWeight={500}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  >
                    Subtracting Amount
                  </Text>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead bg={Colors.modifierTable}>
                        <Tr>
                          <Th>Amount Type</Th>
                          <Th>Amount</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Pay Out Amount</Td>
                          <Td>${tillData.pay_out_amount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Cash Refunded Amount With Order</Td>
                          <Td>${tillData.cash_refund_amount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Cash Refunded Amount Without Order</Td>
                          <Td>${tillData.refund_without_order}</Td>
                        </Tr>
                        <Tr>
                          <Td>Closing Amount</Td>
                          <Td>${tillData.closing_amount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Bank Drop Amount</Td>
                          <Td>${tillData.bank_drop_amount}</Td>
                        </Tr>
                        <Tr>
                          <Td>Cashback</Td>
                          <Td>${tillData.cashback}</Td>
                        </Tr>
                        <Tr>
                          <Td>Bottle Deposit Refund</Td>
                          <Td>${tillData.bottle_deposit_refund}</Td>
                        </Tr>
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Total</Th>
                          <Td>${tillData.minus_amount}</Td>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </TableContainer>
                </Flex>
              </Flex>
            )}
            {tillData && (
              <Flex>
                <Text
                  fontSize="1.12rem"
                  fontStyle="normal"
                  fontWeight={500}
                  lineHeight="normal"
                  letterSpacing="-0.02rem"
                >
                  Difference($):
                </Text>
                {tillData.add_amount} - {tillData.minus_amount} ={" "}
                {posStation.difference}
              </Flex>
            )}
            {station && station.length > 0 && (
              <Flex direction="column" flex="1" mt="2rem">
                <TableContainer>
                  <Table variant="simple">
                    <Thead bg={Colors.modifierTable}>
                      <Tr>
                        <Th>Paid In/Out Details</Th>
                        <Th>Amount</Th>
                        <Th>Reason</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {station.map((checkresult, i) => (
                        <Tr>
                          <Td>
                            {checkresult.type}
                            {checkresult.date}
                            <Text>{checkresult.desc}</Text>
                          </Td>
                          <Td>
                            {checkresult.type === "Paid out at "
                              ? `-$${checkresult.amount}`
                              : checkresult.type === "Paid in at "
                              ? `$${checkresult.amount}`
                              : `$${checkresult.amount}`}
                          </Td>
                          <Td>{checkresult.reason}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <Tfoot>
                      {station && station.length > 0 && (
                        <Tr>
                          <Th>Total paid In/Out($)</Th>
                          <Td>{tillData.pay_in_out_calculation}</Td>
                        </Tr>
                      )}
                    </Tfoot>
                  </Table>
                </TableContainer>
              </Flex>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default WithRouter(TillView);
