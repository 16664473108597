import {
  Alert,
  AlertDescription,
  Box,
  Divider,
  Flex,
  Image,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useRadioGroup,
  ChakraProvider,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Mtheme, theme } from "../../../theme/index";
import { ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse3.svg";
import coins from "../../../assets/images/game-icons.svg";
import home from "../../../assets/images/store.svg";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosFormButton,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getCouponUpdateList } from "./RewardService";
const CardRadio = ({ children, isChecked, style, ...props }) => {
  return (
    <Box as="label" flex="1">
      <input type="radio" {...props} style={{ display: "none" }} />
      <Box
        cursor="pointer"
        borderWidth="0.19rem"
        borderRadius="md"
        boxShadow="md"
        color={isChecked ? "white" : "black"}
        // borderColor={isChecked ? "messenger.300" : "gray.300"}
        borderColor={isChecked ? Colors.primeTaxInfo : "rgba(109, 145, 254, 0.08)"}
        _hover={{
          bg: isChecked ? "messenger" : "gray.100",
        }}
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "#5881FE",
        }}
        height="100%"
        px={{ base: 2, md: 5 }}
        py={3}
        {...style}
      >
        {children}
      </Box>
    </Box>
  );
};
const RewardEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [rewardData, setRewardData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("id");
  const [loading, setLoading] = useState(true);
  const [rewardCardData, setRewardCardSetData] = useState([]);
  const [rewardCardId, setRewardCardSetId] = useState([]);
  const [selectedRewardType, setSelectedRewardType] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [rewardCardProducts, setRewardCardSetProducts] = useState([]);
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setRewardCardSetData(loaderResponse.data.data.data);
          setRewardCardSetId(loaderResponse.data.data.rewardData.id);
          setRewardCardSetProducts(
            loaderResponse.data.data.rewardData.coupon.products
          );
          setSelectedItemId(loaderResponse.data.data.rewardData.coupon);
          fetchData();
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.REWARDS_LIST_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.REWARD_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);
  const modalSize = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "4xl",
    xl: "6xl",
  });
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "sm",
    lg: "md",
    xl: "lg",
  });
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionExtraColumn = isMobile ? { width: 235 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    {
      field: "selection",
      headerName: "",
      sortable: false,
      flex: 1,
      width: 90,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton
          aria-label="view"
          onClick={() => handleRadioItemsChange(params)}
          sx={{ color: "#5881FE" }}
        >
          <Radio />
        </IconButton>
      ),
    },
    { field: "id", headerName: "Coupon Id", sortable: true, ...actionColumn },
    {
      field: "title",
      headerName: "Coupon Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "coupon_type",
      headerName: "Coupon Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "discount_type",
      headerName: "Discount Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "discount_value",
      headerName: "Discount Value",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "min_amount",
      headerName: "Minimum Amount",
      sortable: true,
      ...actionColumn,
    },
  ];
  const columnNamesProduct = [
    {
      field: "product_id",
      headerName: "Id",
      disableColumnMenu: true,
      sortable: false,
      width: 90,
    },
    {
      field: "name",
      headerName: "Name",
      disableColumnMenu: true,
      sortable: false,
      ...actionExtraColumn,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      disableColumnMenu: true,
      sortable: false,
      ...actionExtraColumn,
    },
  ];
  const columnProductViewNames = [
    {
      columnNames: "Coupon Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Coupon Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Coupon Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "	Discount Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Discount Value",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Minimum Amount",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product Details",
      sortName: "",
      width: "",
      renderHeader: () => <RemoveRedEyeIcon />,
      renderCell: () => (
        <IconButton onClick={handleProductDetailsClick}>
          <RemoveRedEyeIcon />
        </IconButton>
      ),
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];
  const handleClear = () => {
    // clear input value and get all data
    setSearchQuery("");
    effectRun.current = true;
  };
  const handleRewardTypeChange = (value) => {
    setSelectedRewardType(value);
    if (
      selectedRewardType === "Points Earned on Payments Amounts" ||
      selectedRewardType === "Points Earned on Product Point"
    ) {
      formik.values.minimum_order_amount = "";
    } else {
      formik.values.minimum_order_amount =
        loaderResponse?.data?.data?.minimum_order_amount;
    }
    formik.setFieldValue("reward_point_calculation_type_id", value);
  };
  const handleModelOpen = (item) => {
    onOpen();
    effectRun.current=false;
    fetchData();
  };
  const handleSingleDelete = (id) => {
    setSelectedItemId(null);
  };
  const [ProductModels, setProductModels] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);

  const handleRadioItemsChange = (params) => {
    const selectedRowData = params.row;
    onClose();
    setSelectedItemId(selectedRowData);
    const mergedProducts = Array.from(
      selectedRowData.products
        .reduce((map, product) => {
          const existingProduct = map.get(product.barcode);
          if (existingProduct) {
            existingProduct.quantity += product.quantity;
          } else {
            map.set(product.barcode, { ...product });
          }
          return map;
        }, new Map())
        .values()
    );
    setSelectedItemData(mergedProducts);
    setProductModels(selectedRowData);
  };
  const handleProductDetailsClick = (item, params) => {
    setIsModalOpen(true);
    setSelectedItemDetails(item);
    if (selectedItemId) {
      const selectedItem = selectedItemId.products;
      const mergedProducts = Array.from(
        selectedItem
          .reduce((map, product) => {
            const existingProduct = map.get(product.barcode);
            if (existingProduct) {
              existingProduct.quantity += product.quantity;
            } else {
              map.set(product.barcode, { ...product });
            }
            return map;
          }, new Map())
          .values()
      );
      setSelectedItemData(mergedProducts);
    }
  };
  const onCloseOpenModal = () => {
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    onClose();
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemDetails(null);
  };
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    products: false,
    id: false,
  });
  
  const handleSearchList = () => {
    fetchData();
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      coupon: "[]",
    };
    try {
      getCouponUpdateList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRewardData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setRewardData([]);
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setRewardData([]);
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setRewardData([]);
      setTotalCount(0);
      setLoading(false);
      setShowTable(false);
      myContext.handleLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      name:
        undefined !== loaderResponse?.data?.data?.rewardData?.name &&
        null != loaderResponse?.data?.data?.rewardData?.name
          ? loaderResponse?.data?.data?.rewardData?.name
          : "",
      reward_point_calculation_type_id:
        undefined !==
          loaderResponse?.data?.data?.rewardData
            ?.reward_points_calculation_type_id &&
        null !=
          loaderResponse?.data?.data?.rewardData
            ?.reward_points_calculation_type_id
          ? loaderResponse?.data?.data?.rewardData
              ?.reward_points_calculation_type_id
          : "",
      visit_type_reward_exist: 1,
      // undefined !==
      //   loaderResponse?.data?.data?.rewardData?.visit_type_reward_exist &&
      // null != loaderResponse?.data?.data?.rewardData?.visit_type_reward_exist
      //   ? loaderResponse?.data?.data?.rewardData?.visit_type_reward_exist
      //   : 1,
      required_reward_points:
        undefined !==
          loaderResponse?.data?.data?.rewardData?.required_reward_points &&
        null != loaderResponse?.data?.data?.rewardData?.required_reward_points
          ? loaderResponse?.data?.data?.rewardData?.required_reward_points
          : "",
      minimum_order_amount:
        undefined !== loaderResponse?.data?.data?.minimum_order_amount &&
        null != loaderResponse?.data?.data?.minimum_order_amount
          ? loaderResponse?.data?.data?.minimum_order_amount
          : "",
      type:
        undefined !== loaderResponse?.data?.data?.rewardData?.type &&
        null != loaderResponse?.data?.data?.rewardData?.type
          ? loaderResponse?.data?.data?.rewardData?.type
          : "",
      coupon:
        undefined !== loaderResponse?.data?.data?.rewardData?.coupon &&
        null != loaderResponse?.data?.data?.rewardData?.coupon
          ? loaderResponse?.data?.data?.rewardData?.coupon
          : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.REWARD_NAME_REQUIRED)
        .max(255, Constants.REWARD_NAME_LENGTH_GREATER_THAN_255),
      reward_point_calculation_type_id: Yup.string().required(
        Constants.REWARD_POINT_CALCULATION_TYPE
      ),
      required_reward_points: Yup.number()
        .required(Constants.REWARD_REQUIRED_REWARD_POINT)
        .min(0, Constants.REWARD_VALID_REQUIRED_REWARD_POINTS)
        .max(2147483647, Constants.REWARD_REQUIRED_REWARD_POINTS_MAX_VALUE)
        .when("reward_point_calculation_type_id", {
          is: "Points Earned on Visits",
          then: (schema) =>
            schema.min(1, Constants.REWARD_REQUIRED_REWARD_POINT_VISIT),
        }),
      minimum_order_amount: Yup.number()
        .typeError("The minimum order amount must be a integer")
        .min(0.01, Constants.REWARD_VALID_AMOUNT_ORDER)
        .max(999999.99, Constants.REWARD_ORDER_AMOUNT_MAX_VALUE)
        .when("reward_point_calculation_type_id", {
          is: (value) => value === "Points Earned on Visits" || value === 3,
          then: (schema) =>
            schema
              .required(Constants.REWARD_MINIMUM_ORDER_AMOUNT)
              .min(0.01, Constants.REWARD_ORDER_AMOUNT_MIN_VALUE),
        }),
    }),
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const couponIdArray =
        typeof selectedItemId === "object" ? [selectedItemId] : [];
      const couponId = JSON.stringify(couponIdArray);
      let minimumOrderAmount = "";
      if (
        selectedRewardType === "Points Earned on Visits" ||
        values.reward_point_calculation_type_id === 3
      ) {
        minimumOrderAmount = twofix(formik.values.minimum_order_amount);
      } else {
        minimumOrderAmount = "";
      }
      const payload = {
        id: rewardCardId,
        name: formik.values.name,
        reward_point_calculation_type_id: mapTypeToBackendValue(
          selectedRewardType
        ),
        visit_type_reward_exist: parseInt(
          formik.values.visit_type_reward_exist
        ),
        required_reward_points: parseInt(formik.values.required_reward_points),
        minimum_order_amount: minimumOrderAmount,
        coupon_id: couponId,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.REWARD_CREATE_API_ROUTE,
      });
    },
  });
  const handleChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      formik.handleChange(e);
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
      effectRun.current = false;
    }
  }, [searchQuery, paginationModel, sortModel, loading]);

  // useEffect(() => {
  //   fetchData();
  // }, [paginationModel, sortModel]);
  const mapTypeToBackendValue = (type) => {
    const responseValue = formik.values.reward_point_calculation_type_id;
    if (type) {
      switch (type) {
        case "Points Earned on Product Points":
          return 1;
        case "Points Earned on Payment Amounts":
          return 2;
        case "Points Earned on Visits":
          return 3;
        default:
          return 1;
      }
    } else {
      switch (responseValue) {
        case 1:
          return 1;
        case 2:
          return 2;
        case 3:
          return 3;
        default:
          return 1;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.name?.length <= 0 ||
      !selectedItemId ||
      selectedItemId?.id === 0 ||
      (selectedRewardType === "Points Earned on Visits" &&
        !formik.values.minimum_order_amount) ||
      formik.values.reward_point_calculation_type_id === "" ||
      formik.values.required_reward_points === "" ||
      formik.errors.reward_point_calculation_type_id ||
      formik.errors.required_reward_points ||
      formik.errors.name ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };
  const rewardTypeOptions = [
    {
      title: "Points Earned on Product Points",
      body:
        "If selected, customers will earn points that are assigned to each product. You can assign points to a product when you create or edit it.",
      type: "Points Earned on Product Points",
      value: 1,
      icon: <ShoppingCartIcon sx={{ fill: Colors.taxInfo }} />,
    },
    {
      title: "Points Earned on Payment Amounts",
      body: "If selected, customers will earn one point for each dollar spent.",
      type: "Points Earned on Payment Amounts",
      value: 2,
      icon: (
        <Img src={coins} alt="Shopping Cart" style={{ width: "1.56rem" }} />
      ),
    },
    {
      title: "Points Earned on Visits",
      body:
        "If selected, customers will earn one point for each visit to the business. You can set the minimum spend per visit.",
      type: "Points Earned on Visits",
      value: 3,
      icon: <Img src={home} alt="Home" style={{ width: "1.25rem" }} />,
    },
  ];
  const {
    getRootProps: getRewardTypeRootProps,
    getRadioProps: getRewardTypeRadioProps,
  } = useRadioGroup({
    name: "coupon_type",
    defaultValue: "",
    onChange: handleRewardTypeChange,
  });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const modifiedDatas = selectedItemData.map((data) => ({
    id: data.id || "N.A.",
    product_id: data.id || "N.A.",
    name: data.name || "N.A.",
    barcode: data.barcode || "N.A.",
  }));
  const modifiedViewData = selectedItemId
    ? [selectedItemId].map((item) => ({
        id: item.id,
        title: item.title,
        coupon_type: item.coupon_type === "prod" ? "Product" : "Order",
        discount_type: item.discount_type === "amou" ? "Amount" : "Percentage",
        discount_value: item.discount_value,
        min_amount: item.min_amount || "N.A.",
        productDetailsIcon:
          item.products && item.products.length > 0 ? (
            <IconButton
              style={{
                backgroundColor: isHovered ? "#5881fe1a" : "#F4F4F4",
                color: isHovered ? "#010048" : "#010048",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleProductDetailsClick(item)}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          ) : (
            "N.A."
          ),
      }))
    : [];

  const modifiedData = rewardData.map((data) => ({
    id: data.id,
    title: data.title,
    coupon_type: data.coupon_type === "prod" ? "Product" : "Order",
    discount_type: data.discount_type === "amou" ? "Amount" : "Percentage",
    discount_value: data.discount_value,
    min_amount: data.min_amount || "N.A.",
    products: data.products,
  }));
  const sortRef = useRef(sortModel);
  const rewardTypeGroup = getRewardTypeRootProps();
  return (
    <Box flex="1" pb="3.31rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.REWARD_PATH);
            }}
            breadCrumNames={["Reward ", "Edit a Reward"]}
            breadCrumTitle={"Edit a Reward"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              // pass param data in modifier set list page
              props.navigate(Constants.REWARD_PATH, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={
              formik.values.name?.length === 0 ||
              !selectedItemId ||
              selectedItemId?.id === 0 ||
              ((selectedRewardType === "Points Earned on Visits" ||
                formik.values.reward_point_calculation_type_id === 3) &&
                (formik.errors.reward_point_calculation_type_id ||
                  formik.values.minimum_order_amount == null ||
                  formik.values.minimum_order_amount === "" ||
                  formik.errors.minimum_order_amount)) ||
              formik.values.reward_point_calculation_type_id === "" ||
              formik.values.required_reward_points === "" ||
              formik.errors.name ||
              formik.errors.reward_point_calculation_type_id ||
              formik.errors.required_reward_points ||
              buttonDisable
                ? true
                : false
            }
            buttonsubmit={"Submit"}
            SubmitButton={true}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Flex>
      <Box
        mt={{ base: 2, md: "2rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "2.75rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="8"
      >
        <Text
          fontSize="1.23rem"
          w="10.5rem"
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Basic Details
        </Text>
        <Box>
          <Stack spacing={2}>
            <PosLable
              name={"Reward name"}
              requiredLabel={true}
              fontSize="0.94rem"
              fontStyle="normal"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <PosInput
              id="name"
              mt={"1rem"}
              placeholder={"Enter a Reward name"}
              color={Colors.posCommon}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={formik.handleBlur}
              onKeyDown={handleKeyDown}
              posInputGroup={true}
              inputType={"text"}
              inputError={formik.touched.name && formik.errors.name}
            >
              <DriveFileRenameOutlineIcon
                sx={{
                  height: "1.21rem",
                  width: "1.21rem",
                  background: Colors.posInputGroupBackground,
                  borderRadius: "0.31rem",
                  color: Colors.posNavbarLink,
                }}
              />
            </PosInput>
            <Text color={Colors.posCancleButtonRed}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
        </Box>
        <Flex flexDirection={"row"}>
          <PosLable
            name={"Reward Point Calculation Type"}
            requiredLabel={true}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <Tippy
            content="Choose from multiple types of reward point calculations, Either on the Total amount of the order or on the number of visits, or by specific product. Points on products bought are the points mentioned while creating the product."
            interactive={true}
            maxWidth={390}
            placement="top"
            animation="fade"
            inertia={true}
            moveTransition="transform 0.2s ease-out"
            theme="tomato"
          >
            <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
          </Tippy>
        </Flex>
        {/* <Text
          color={"#010048b3"}
          fontSize="0.94rem"
          fontStyle="normal"
          fontWeight={300}
          lineHeight="normal"
          letterSpacing="-0.02rem"
        >
          Choose from the multiple types of reward point calculations
        </Text> */}
        <Flex
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems={{ base: "flex-start", md: "stretch" }}
          flexDirection={{ base: "column", md: "row" }}
          mt={4}
          mb={8}
          gap={4}
          {...rewardTypeGroup}
        >
          {rewardTypeOptions.map((option, index) => {
            const radio = getRewardTypeRadioProps({ value: option.type });
            let isChecked; // Declare isChecked here

            if (selectedRewardType) {
              isChecked =
                formik.values.reward_point_calculation_type_id === option.type;
            } else {
              isChecked =
                formik.values.reward_point_calculation_type_id === option.value;
            }
            return (
              <CardRadio key={index} {...radio} isChecked={isChecked}>
                <Flex flexDirection="row" alignItems="center" gap={2}>
                  {option.icon}
                  <Text
                    fontWeight="bold"
                    color={Colors.posTextInfo}
                    textAlign="start"
                  >
                    {option.title}
                  </Text>
                </Flex>
                <Text
                  mt={"0.63rem"}
                  color={Colors.posTextInfo}
                  style={{
                    fontSize: "0.94rem",
                    fontStyle: "normal",
                    fontWeight: 300,
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  {option.body}
                </Text>
              </CardRadio>
            );
          })}
        </Flex>
        <Divider mt={"2rem"} />
        <PosLable
          name={"Required Reward Points"}
          requiredLabel={true}
          fontSize="0.94rem"
          fontStyle="normal"
          fontWeight={500}
          lineHeight="normal"
          letterSpacing="-0.02rem"
        />
        <Text
          color={"#010048b3"}
          fontSize="0.94rem"
          fontStyle="normal"
          fontWeight={300}
          lineHeight="normal"
          letterSpacing="-0.02rem"
        >
          Set points customers will have to earn to receive this reward
        </Text>
        <Stack mt="0.87rem">
          <PosInput
            id={"required_reward_points"}
            placeholder={"Enter a Required Reward Points"}
            handleInputChange={handleChange}
            inputValue={formik.values.required_reward_points}
            handleBlur={formik.handleBlur}
            onKeyDown={handleKeyDown}
            inputType={"number"}
            posNumberInput={true}
            inputError={
              formik.touched.required_reward_points &&
              formik.errors.required_reward_points
            }
          />
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.required_reward_points &&
            formik.errors.required_reward_points ? (
              <span>{formik.errors.required_reward_points}</span>
            ) : null}
          </Text>
        </Stack>
      </Box>
      {selectedRewardType === "Points Earned on Visits" ||
      formik.values.reward_point_calculation_type_id === 3 ? (
        <Box
          mt={{ base: "0", md: "0.63rem" }}
          ml={{ base: "0", md: "3.44rem" }}
          mr={{ base: "0", md: "2.75rem" }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          pl={{ base: "8", md: "1.75rem" }}
          pr={{ base: "8", md: "1.81rem" }}
          pb={{ base: "8", md: "2.13rem" }}
        >
          <Stack>
            <PosLable
              name={"Edit Minimum Order Amount"}
              requiredLabel={true}
              fontSize="0.94rem"
              fontStyle="normal"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <Alert
              // width={{ base: "0", md: "1009px" }}
              h={{ base: "7rem", md: 16 }}
              bg={Colors.errorsign} //props
              status={"error"}
              borderRadius={"0.63rem"}
            >
              <Image boxSize={4} src={warnignIcon} alt="Octopos" />
              <AlertDescription
                fontSize={"1rem"}
                fontWeight={300}
                color={Colors.errorColor}
                pl={3}
              >
                Changing the minimum order amount will be applicable to all
                other previously created "Points Earned on Visits" type rewards.
              </AlertDescription>
            </Alert>
            <PosInput
              id="minimum_order_amount"
              mt={"1rem"}
              placeholder={"Enter a Minimum Order Amount"}
              color={Colors.posCommon}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.minimum_order_amount || ""}
              // handleBlur={formik.handleBlur}
              handleBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue(
                  "minimum_order_amount",
                  formik.values.minimum_order_amount == "" ? "" : twofix(formik.values.minimum_order_amount)
                );
              }}
              onKeyDown={handleKeyDown}
              posNumberInput={true}
              inputType={"text"}
              inputError={
                formik.touched.minimum_order_amount &&
                formik.errors.minimum_order_amount
              }
            />
            <Text color={Colors.posCancleButtonRed}>
              {formik.touched.minimum_order_amount &&
              formik.errors.minimum_order_amount ? (
                <span>{formik.errors.minimum_order_amount}</span>
              ) : null}
            </Text>
          </Stack>
        </Box>
      ) : null}
      <Box
        mt={{ base: 2, md: "0.63rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "2.75rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="2.5"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "satrt" }}
          pt={{ base: "1.5rem", md: "2.81rem" }}
          pb={{ base: "1.5rem", md: "1.56rem" }}
          pl={{ base: "1rem", md: "1rem" }}
          pr={{ base: "0.5rem", md: "1.56rem" }}
          gap={2}
        >
          <Box>
            <Flex flexDirection={"row"}>
              <Text
                fontSize="1.13rem"
                color={Colors.posTextInfo}
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                }}
              >
                Add Coupon to Reward
              </Text>
              <Tippy
                content="Add a coupon from the coupons created. Make sure that the coupon made is Targeted. This coupon is what will be redeemed against the Points when it reaches that amount."
                interactive={true}
                maxWidth={390}
                placement="top"
                animation="fade"
                inertia={true}
                moveTransition="transform 0.2s ease-out"
                theme="tomato"
              >
                <Image
                  src={toolTip}
                  alt="Tooltip"
                  ml={{ base: "0.3rem", md: "0.3rem" }}
                />
              </Tippy>
            </Flex>
            {/* <Text
              color={"#010048"}
              fontSize="0.94rem"
              fontStyle="normal"
              fontWeight={300}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            >
              Create targeted coupons and redeem them against accumulated points
            </Text> */}
          </Box>
          <Spacer />
          <PosFormButton
            buttonsubmit={"Add Coupon"}
            width={"9.25rem"}
            onClick={handleModelOpen}
            isDisabled={
              (modifiedViewData.length > 0 &&
                !formik.values.apply_on_all_prods) ||
              buttonDisable
                ? true
                : false
            }
          />
        </Flex>
        <>
          <ThemeProvider theme={Mtheme}>
            <Dialog
              open={isOpen}
              // onClose={() => setModal(false)}
              fullWidth={true}
              maxWidth={modalSizes}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  color: Colors.posTextInfo,
                }}
              >
                <Text
                  pt={"2.31rem"}
                  pr={{ base: "2rem", md: "10rem" }}
                  style={{ flex: 1 }}
                >
                  Add Coupon
                </Text>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    // onClose();
                    onCloseOpenModal();
                  }}
                  sx={{
                    position: "absolute",
                    right: 14,
                    top: 14,
                  }}
                >
                  <CloseIcon top="2.5rem" right="2.5rem" />
                </IconButton>

                <ChakraProvider theme={theme}>
                  <Box mt={2}>
                    <PosSearch
                      // mb={3.5}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onSearch={handleSearchList}
                      handleClear={handleClear}
                    />
                  </Box>
                </ChakraProvider>
              </DialogTitle>
              <DialogContent>
                {showTable ? null : loading ? (
                  <PosProgress />
                ) : modifiedData.length > 0 ? (
                  <Box
                    style={{
                      overflowX: "auto",
                      maxHeight: "38.91rem",
                      maxWidth: "70.75rem",
                    }}
                  >
                    <PosDataGridTable
                      columns={columnNames}
                      rows={modifiedData}
                      totalcount={totalcount}
                      // noAction={true}
                      columnVisibilityModel={olColumnVisibilityModel}
                      onColumnVisibilityModelChange={(newModel) =>
                        setOlColumnVisibilityModel(newModel)
                      }
                      paginationModel={paginationModel}
                      paginationMode="server"
                      radioTable={true}
                      slots={{
                        toolbar: GridToolbar,
                      }}
                      // radioItems={radioItems}
                      // handleRadioChange={handleRadioItemsChange}
                      onPaginationModelChange={(newPaginationModel) => {
                        effectRun.current = true;
                        setPaginationModel(newPaginationModel);
                      }}
                      sortModel={sortModel}
                      onSortModelChange={(newSortModel) => {
                        sortRef.current = sortModel;
                        setSortModel(newSortModel);
                      }}
                      fileName={Constants.REWARD_CSV}
                    />
                  </Box>
                ) : (
                  <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                )}
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </ThemeProvider>
        </>
        <>
          {isModalOpen && selectedItemDetails && (
            <ThemeProvider theme={Mtheme}>
              <Dialog
                open={isModalOpen}
                // onClose={() => setModal(false)}
                fullWidth={true}
                maxWidth={modalSizes}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    color: Colors.posTextInfo,
                  }}
                >
                  <Text
                    pt={"2.31rem"}
                    pr={{ base: "2rem", md: "10rem" }}
                    style={{ flex: 1 }}
                  >
                    Product Details
                  </Text>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      // onClose();
                      handleCloseModal();
                    }}
                    sx={{
                      position: "absolute",
                      right: 14,
                      top: 14,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Box mb={{ base: "0.88rem", md: "0.88rem" }}></Box>
                  {showTable ? null : loading ? (
                    <PosProgress />
                  ) : modifiedDatas.length > 0 ? (
                    <Box
                      style={{
                        overflowX: "auto",
                        maxHeight: "38.91rem",
                        maxWidth: "70.75rem",
                      }}
                    >
                      <PosDataGridTable
                        columns={columnNamesProduct}
                        rows={modifiedDatas}
                        totalcount={totalcount}
                        // noAction={true}
                        columnVisibilityModel={olColumnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                          setOlColumnVisibilityModel(newModel)
                        }
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        hideFooter={true}
                        // paginationModel={paginationModel}
                        paginationMode="server"
                        // onPaginationModelChange={setPaginationModel}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => {
                          sortRef.current = sortModel;
                          setSortModel(newSortModel);
                        }}
                        fileName={Constants.REWARD_PRODUCT_CSV}
                      />
                    </Box>
                  ) : (
                    <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                  )}
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </ThemeProvider>
          )}
        </>
        {!formik.values.apply_on_all_prods && modifiedViewData.length > 0 && (
          <Box
            borderRadius="0.63rem"
            mt={3}
            bg={Colors.posPageDataBackground}
            boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          >
            <PosTable
              columnNames={columnProductViewNames}
              rawdata={modifiedViewData}
              totalcount={totalcount}
              // noAction={true}
              footerHide={true}
              singleDeleteButton={true}
              goSingleDelete={(id) => handleSingleDelete(id)}
              // sortColumnName={sortColumn}
              // page={page}
              // rowsPerPage={rowsPerPage}
              // order={sortType}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WithRouter(RewardEdit);
