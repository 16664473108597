import { Search2Icon,CloseIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement,InputRightElement } from "@chakra-ui/react";

import React from "react";
import Colors from "../../assets/colors/Colors";
const PosSearch = (props) => {
  const { value, onChange, onSearch } = props;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <InputGroup
      // maxWidth={{ base: "100%", md: "458px" }}
      width={{ base: "100%", md: "458px" }}
      mb={props.mb}
    >
      <InputLeftElement>
        <Search2Icon color={Colors.posTextInfo} />
      </InputLeftElement>
      <Input
        type="text"
        placeholder="Search"
        variant={"posSearchInput"}
        // color={Colors.posTextInfo}
        // bg={Colors.searchInput}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
      {value.trim().length > 0 ? (
        <InputRightElement onClick={props.handleClear}>
        <CloseIcon color={Colors.posTextInfo} boxSize={3} cursor={"pointer"}/>
      </InputRightElement>
      ) : null}
    </InputGroup>
  );
};

export default PosSearch;
